import {IsHRWeb} from './displayTools';

export function DefineWebStyles() {
  if (IsHRWeb()) {
    const styles = `
      input[autocomplete='offnn']::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display:none;
        position: absolute;
        right: 0;
      }
    `;
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = styles;
    } else {
      style.appendChild(document.createTextNode(styles));
    }
    document.head.appendChild(style);
  }
}
