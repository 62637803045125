import React from 'react';
import {BrowseColumn} from '@components/screens/types';
import {isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import {
  MinimizedRecordList,
  MinimizedRecordListDefinitionProps,
} from '@components/screens/minimizedRecordList/MinimizedRecordList';
import {
  displayMinimizedSumwithCurrency,
  displayMobileSerNumberAndDate,
  displayOrdDate,
  displayOrderStatus,
  getMobileDisplayPlannedDelivery,
  getMobileDisplaySumWithCurrency,
} from '@src/tools/recordListDisplayTools';
import {MobileBrowseColumnDisplayType} from '@src/types/Record/windowDefinitions';
import {OverwriteBrowseColumnProps} from '@utils/types';

export const ContactsSalesOrdersList: React.FC<MinimizedRecordListDefinitionProps> = ({window, recordData}) => {
  const tableName = 'ORVc';
  const windowName = 'SalesOrders';
  const sortKey = 'Date';
  const rowID = 'SerNr';

  const desktopBrowseColumns: BrowseColumn[] = [
    {
      name: translate('SerNr'),
      key: 'SerNr',
      sortKey: 'SerNr',
      alignment: 'left',
      width: '124px',
    },
    {
      name: translate('OrdDate'),
      key: 'Date',
      sortKey: 'Date',
      alignment: 'left',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
      overwrite: displayOrdDate,
    },
    {
      name: translate('ExcludingVAT'),
      key: 'Sum',
      sortKey: 'Sum',
      alignment: 'left',
      width: '130px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: displayMinimizedSumwithCurrency,
    },
    {
      name: translate('PlanShip'),
      key: 'Date2',
      sortKey: 'Date2',
      alignment: 'right',
      width: '160px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('Class'),
      key: 'Class',
      sortKey: 'Class',
      alignment: 'center',
      width: '80px',
      windowElementType: WindowElementType.kInputTypeText,
    },
    {
      name: translate('OrderStatus'),
      key: 'Status',
      sortKey: 'status',
      alignment: 'center',
      width: '120px',
      overwrite: displayOrderStatus,
    },
  ];

  const mobileBrowseColumns: BrowseColumn[] = [
    {
      name: translate('Name'),
      key: 'SerNr',
      sortKey: 'SerNr',
      alignment: 'left',
      width: '151px',
      overwrite: displayMobileSerNumberAndDate,
      mobileDisplayType: MobileBrowseColumnDisplayType.title,
    },
    {
      name: translate('PlannedDelivery'),
      key: 'Date2',
      sortKey: 'Date2',
      alignment: 'left',
      width: '140px',
      windowElementType: WindowElementType.kInputTypeDate,
      overwrite: getMobileDisplayPlannedDelivery,
      mobileDisplayType: MobileBrowseColumnDisplayType.underTitle,
    },
    {
      name: translate('Total'),
      key: 'Sum',
      sortKey: 'Sum',
      alignment: 'right',
      width: '111px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: (props: OverwriteBrowseColumnProps) =>
        getMobileDisplaySumWithCurrency(props, 'paydate', 'Currency', 'paid'),
      mobileDisplayType: MobileBrowseColumnDisplayType.rightTitle,
    },
    {
      name: translate('Status'),
      key: 'Status',
      sortKey: 'Status',
      alignment: 'right',
      width: '111px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: displayOrderStatus,
      mobileDisplayType: MobileBrowseColumnDisplayType.rightUnderTitle,
    },
  ];

  function getBrowseColumns(): BrowseColumn[] {
    if (isMobileOrTabletScreen()) {
      return mobileBrowseColumns;
    }

    return desktopBrowseColumns;
  }

  return (
    <MinimizedRecordList
      window={window}
      recordData={recordData}
      hasRecordSummary={false}
      tableName={tableName}
      windowName={windowName}
      sortKey={sortKey}
      rowID={rowID}
      browseColumns={getBrowseColumns()}
    />
  );
};
