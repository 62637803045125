import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import EditField from '../EditField';
import CheckboxButton from '@components/editElements/CheckboxButton';
import {ActionButton} from '../../button/ActionButton';
import {isApproved} from '../../../utils/helpers';
import {objectHasProperty} from '@tools/objectTools';
import {ButtonField, CheckboxField, FieldSection, FieldSectionGroup, TextField} from '@utils/types';
import {Header6} from '@src/styles/primitiveComponents';
import {colours, iconSize, spacing} from '@src/styles/Style';
import {GetGlobalTheme, isMobileOrTabletScreen, IsHRWeb, WindowElementType} from '@src/tools/displayTools';
import {ViewMode} from '../../screens/types';
import {MatrixContainer} from '@components/editElements/Matrix';
import {Animated} from 'react-native';
import {AttachmentWindow} from '@src/components/attachments/attachmentWindow';
import {InspectWindow} from './InspectWindow';
import {fieldIsEditable} from '@src/tools/windowTools';

const HeaderFields = styled.View`
  background: ${colours[GetGlobalTheme()].white};

  border-radius: ${spacing.space12}px;
  padding: ${spacing.space16}px;
  flex-direction: column;
  ${IsHRWeb() &&
  css`
    /*flex-direction: row;
    flex-wrap: wrap;*/
  `}
`;

const HeaderFieldList = styled.View`
  flex-grow: 0;
  gap: ${spacing.space16}px;
  flex-direction: row;
`;

const SectionTop = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${spacing.space16}px;
`;

const WindowTileWrap = styled.View`
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  gap: ${spacing.space12}px;
`;
//  width: calc(100% - ${300 + (spacing.space12 * 2 + spacing.space12)}px); /*action pane width + padding on sides + gap*/

const HeaderTitle = styled.View``;
const HeaderTitleText = styled(Header6)``;
const GroupTitle = styled.View``;
const GroupTitleText = styled(Header6)``;

export const Divider = styled.View`
  border-color: ${colours[GetGlobalTheme()].borderDefault};
  border-style: solid;
  border-left-width: ${isMobileOrTabletScreen() ? 0 : 1};
  border-bottom-width: ${isMobileOrTabletScreen() ? 1 : 0};
`;
const FieldGroup = styled.View`
  flex-direction: column;
  flex-grow: 1;
  flex-basis: ${isMobileOrTabletScreen() ? '35%' : '0%'};

  gap: ${(props) => (props.viewMode == ViewMode.Edit ? spacing.space12 : spacing.space8)};
  border-radius: ${spacing.space12}px;
`;

const MultiFieldLine = styled.View`
  ${(props) =>
    props.viewMode === ViewMode.Edit
      ? 'flex-direction: row; gap: ' + spacing.space12 + 'px;'
      : 'flex-direction: column; gap: ' + spacing.space8 + 'px;'};
`;

export function WindowElementTypeMatch(fieldtype: WindowElementType | undefined, check_against: WindowElementType) {
  return fieldtype !== undefined && fieldtype === check_against;
}

export function isCheckboxField(field: CheckboxField | TextField | ButtonField): field is CheckboxField {
  return !!field && WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeCheckbox);
}

export function isTextField(field: CheckboxField | TextField | ButtonField): field is TextField {
  return (
    !!field &&
    WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeButton) === false &&
    WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeCheckbox) === false
  );
}

export function isButtonField(field: CheckboxField | TextField | ButtonField): field is ButtonField {
  return !!field && WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeButton);
}

function ShowSingleEditField(
  field: CheckboxField | TextField | ButtonField,
  index: number,
  props,
  wrapperStyle,
  inspectWindow: InspectWindow
) {
  let val: string | JSX.Element;

  if (isButtonField(field) === false) {
    val = props.record.headerFields[field.dataKey];
  }
  if (isTextField(field)) {
    //TODO: we should do the check against interface somehow
    val = field.fixedValue || props.record.headerFields[field.dataKey];

    if (field.overwrite) {
      val = field.overwrite({
        value: val,
        record: props.record
      });
    }
  }
  if ('matrixField' in field && field.matrixField) {
    if (props.record.matrixRows && props.record.matrixRows.length > 0) {
      let tval = [];
      for (let i = 0; i < props.record.matrixRows.length; i++) {
        tval.push(props.record.matrixRows[i][field.dataKey]);
      }
      val = tval.join(',');
    }
  }

  const elementType = typeof field.windowElementType === 'function' 
    ? field.windowElementType(props.viewMode)
    : field.windowElementType;

  switch (true) {
    case isCheckboxField(field):
      return (
        <CheckboxButton
          key={field.dataKey}
          name={field.dataKey}
          label={field.label}
          title2={field.checkboxInactiveLabel}
          tooltip={field.checkboxTooltip}
          inspectWindow={inspectWindow}
          value={val}
          editable={
            !field.viewOnly &&
            isApproved(props.record, props.okcheck, field.dataKey) === false &&
            props.viewMode === ViewMode.Edit
          }
          onChange={field.onChange}
        />
      );
    case isButtonField(field): {
      let w = field.width;
      if (w) {
        if (w.indexOf('px') > 0) {
          w = parseInt(w);
        }
        if (IsHRWeb() === false) {
          w = '100%';
        }
      }
      return (
        <ActionButton
          onPress={() => {
            if (field.onPress) {
              field.onPress();
            }
          }}
          style={{
            width: w,
            ...field.style,
            textAlign: 'center',
          }}
          title={field.label}
        />
      );
    }
    default:
      if (elementType === WindowElementType.kInputTypeNone) {
        return;
      }
      
      if (isTextField(field)) {
        let label = field.label;
        if (field.hideLabelWhileView && props.viewMode == ViewMode.View) {
          label = undefined;
        }
        let onChangeText = () => {
          global.activeEditMode = true;
        };
        const isEditable = fieldIsEditable(
          field.viewOnly,
          field.dataKey,
          props.record,
          props.okcheck,
          props.viewMode,
          props.window.activeEditFields
        );
        return (
          // can just pass field as prop, so then won't have to create so many props
          <EditField
            wrapperStyle={{...wrapperStyle, paddingLeft: 0}}
            key={field.dataKey}
            title={label}
            onChangeText={onChangeText}
            value={val}
            record={props.record}
            dateFormatOrigin={field.dateFormatOrigin}
            allowManualEntry={field.allowManualEntry}
            pasteSpecial={field.pasteSpecial}
            inspectWindow={inspectWindow}
            reference={field.reference}
            name={field.dataKey}
            matrixfield={field.matrixField}
            linkedfield={field.linkedField}
            onChange={field.onChange}
            onFocus={field.onFocus}
            labelStyle={field.labelStyle}
            width={field.width}
            barcode={elementType === WindowElementType.kInputTypeBarCode}
            /*viewOnly={field.viewOnly} we have editable and will be using it*/
            row={field.row}
            allowClearing={field.allowClearing}
            editable={isEditable}
            type={elementType || WindowElementType.kInputTypeText}
            onClick={() => props.onClick()}
            inputStyle={field.style || (field.styleFunc && field.styleFunc(isEditable))}
          />
        );
      }
  }
}

//KSZ: bad name... can't figure out how to call it

type FieldSectionComponentProps = {
  viewMode: ViewMode;
  inspectWindow: InspectWindow;
  style: React.CSSProperties;
  section: FieldSection;
};

type FieldSectionComponentState = {
  sectionWidth?: number;
  initialHeight?: number;
  sectionHeight?: number;
  animatedOpacity?: Animated.Value;
  animatedHeight?: Animated.Value;
  showSection?: boolean;
};

class FieldSectionComponent extends Component<FieldSectionComponentProps, FieldSectionComponentState> {
  collapseSection: any;
  localViewMode: ViewMode;

  constructor(props) {
    super(props);
    this.localViewMode = props.viewMode;
    this.state = {
      sectionWidth: 0,
      initialHeight: 0,
      sectionHeight: 0,
      animatedOpacity: new Animated.Value(1),
      showSection: true,
    };
  }

  getSectionTop(section) {
    return (
      <SectionTop>
        {section.label && (
          <HeaderTitle>
            <HeaderTitleText>{section.label}</HeaderTitleText>
          </HeaderTitle>
        )}
        {section.collapse && (
          <ActionButton
            title={''}
            onPress={() => {
              Animated.parallel([
                Animated.timing(this.state.animatedHeight, {
                  toValue: this.state.showSection ? 0 : this.state.sectionHeight,
                  duration: 300,
                  useNativeDriver: true,
                }),
                Animated.timing(this.state.animatedOpacity, {
                  toValue: this.state.showSection ? 0 : 1,
                  duration: 300,
                  useNativeDriver: true,
                }),
              ]).start(() => {
                this.setState({showSection: !this.state.showSection});
              });
            }}
            iconName={'DropdownIcon'}
            iconSize={iconSize.iconSize}
            hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
            iconStyle={{
              transform: this.state.showSection ? 'rotate(180deg)' : 'rotate(0)',
            }}
            iconColor={colours[GetGlobalTheme()].primary}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              transition: 'all 2s',
              padding: '0px',
              height: 'auto',
            }}
          />
        )}
      </SectionTop>
    );
  }

  getAnimatedStyle() {
    let animatedStyle = {
      overflow: this.state.showSection ? undefined : 'hidden',
    };
    if (this.props.section.collapse && this.state.animatedHeight) {
      animatedStyle.opacity = this.state.animatedOpacity;
      animatedStyle.height = this.state.animatedHeight;
    }
    return animatedStyle;
  }

  windowIsClosing(event) {
    let isClosing: boolean;

    if (event.nativeEvent.layout.top == 0 && event.nativeEvent.layout.left == 0) {
      isClosing = true;
    }

    return isClosing;
  }

  render() {
    let wrapperStyle = {flexGrow: 1, zIndex: 0};

    // let wrapperStyle = {zIndex: 0};

    let section = this.props.section;
    if (isMobileOrTabletScreen()) {
      section.listStyle = {...section.listStyle, ...{flexDirection: 'column'}};
    }
    const self = this;
    if (section.collapse) {
      if (
        this.localViewMode != this.props.viewMode &&
        this.state.showSection &&
        this.state.initialHeight < this.state.sectionHeight
      ) {
        this.state.animatedHeight = new Animated.Value(this.state.initialHeight); //KSZ: I need this to be in state I think because of Animated, but I have to change the value here otherwise with viewmode changing the height needs to be reset and I don't want to do it with rerender (but maybe we can/should)
        this.localViewMode = this.props.viewMode;
      }
    }
    return (
      <HeaderFields style={{...self.props.style, ...section.style}}>
        {section.label || section.collapse ? this.getSectionTop(section) : <></>}

        <Animated.View style={this.getAnimatedStyle()}>
          <HeaderFieldList
            key={section.overrideKey || self.props.viewMode + ':' + self.state.sectionHeight}
            style={section.listStyle}
            onLayout={(event) => {
              if (section.collapse && !this.windowIsClosing(event)) {
                let st: FieldSectionComponentState = {
                  sectionHeight: event.nativeEvent.layout.height,
                  sectionWidth: event.nativeEvent.layout.width,
                };
                const widthHasChanged = st.sectionWidth !== this.state.sectionWidth;
                if (self.state.showSection) {
                  st.animatedHeight = new Animated.Value(event.nativeEvent.layout.height);
                }
                if (this.state.initialHeight === 0 || widthHasChanged) {
                  st.initialHeight = st.sectionHeight;
                }
                self.setState(st);
              }
            }}>
            {section.groups.map(function (fieldgroup: FieldSectionGroup, groupindex: number) {
              let styles: React.CSSProperties = {};
              if (objectHasProperty(fieldgroup, 'style')) {
                styles = fieldgroup.style;
              }
              if (objectHasProperty(fieldgroup, 'fields')) {
                styles.zIndex = fieldgroup.fields.length - groupindex;
              }
              if (self.props.viewMode === ViewMode.View && objectHasProperty(fieldgroup, 'viewStyle')) {
                styles = {...styles, ...fieldgroup.viewStyle};
              }
              if (isMobileOrTabletScreen()) {
                styles.flexBasis = 'auto';
              }
              if (objectHasProperty(fieldgroup, 'matrix')) {
                return (
                  <MatrixContainer
                    style={{zIndex: section.groups.length - groupindex}}
                    data={self.props.record.matrixRows}
                    columns={fieldgroup.matrix}
                    record={self.props.record}
                    okcheck={self.props.okcheck}
                    viewMode={self.props.viewMode}
                    activeEditFields={self.props.window.activeEditFields}
                    title={self.props.title}
                    table={self.props.window}
                    width={self.props.width}
                    minwidth={self.props.minwidth}
                    showrow={self.props.showrow}
                    splitrow={self.props.splitrow}
                    GetMatrixRowStyle={self.props.GetMatrixRowStyle}
                    splitrowend={self.props.splitrowend}
                    showemptymatrix={self.props.showemptymatrix}
                    afterrow={self.props.afterrow}
                    pagination={self.props.record.pagination}
                    rowactions={self.props.rowactions}
                    custommatrixdata={self.props.custommatrixdata}
                    matrixFooterFields={self.props.matrixFooterFields}
                    inspectWindow={self.props.inspectWindow}
                  />
                );
              }
              if (objectHasProperty(fieldgroup, 'attachments')) {
                return (
                  <AttachmentWindow
                    key={
                      self.props.inspectWindow.state.attachments.showAttachments +
                      ':' +
                      self.props.inspectWindow.state.id
                    }
                    visible={true}
                    viewMode={self.props.inspectWindow.state.viewMode}
                    table={self.props.inspectWindow.state.table}
                    window={self.props.inspectWindow.props.window}
                    id={self.props.inspectWindow.state.id}
                    action={() => self.props.inspectWindow.openAttachments()}
                    wideView={fieldgroup.attachments.wideView}
                    showTitle={fieldgroup.attachments.showTitle}
                  />
                );
              }
              if (objectHasProperty(fieldgroup, 'component')) {
                return fieldgroup.component(self.props.inspectWindow);
              }
              if (objectHasProperty(fieldgroup, 'divider')) {
                return <Divider />;
              }
              return (
                <FieldGroup
                  key={groupindex}
                  style={{...styles, zIndex: section.groups.length - groupindex}}
                  viewMode={self.props.viewMode}>
                  {fieldgroup.label && (
                    <GroupTitle>
                      <GroupTitleText>{fieldgroup.label}</GroupTitleText>
                    </GroupTitle>
                  )}

                  {fieldgroup.fields.map(function (field, fieldindex) {
                    wrapperStyle.zIndex = fieldgroup.fields.length - fieldindex;

                    if (objectHasProperty(field, 'line')) {
                      let lineWrapperStyle = {...wrapperStyle, flexGrow: 1, flexShrink: 1};
                      return (
                        <MultiFieldLine key={groupindex} viewMode={self.props.viewMode}>
                          {field.line.map(function (field2, fieldindex2) {
                            field2.wrapperStyle = {
                              ...field2.wrapperStyle,
                              ...lineWrapperStyle,
                            };
                            return ShowSingleEditField(
                              field2,
                              fieldindex2,
                              self.props,
                              field2.wrapperStyle,
                              self.props.inspectWindow
                            );
                          })}
                        </MultiFieldLine>
                      );
                    } else {
                      return ShowSingleEditField(field, fieldindex, self.props, wrapperStyle, self.props.inspectWindow);
                    }
                  })}
                </FieldGroup>
              );
            })}
          </HeaderFieldList>
        </Animated.View>
      </HeaderFields>
    );
  }
}

//KSZ: should we chnage the function name??
const WindowTile = (props) => {
  let style: React.CSSProperties = {};
  if (!props.visible) {
    style.display = 'none';
  }
  return (
    <WindowTileWrap style={style}>
      {props.sections.map(function (section: FieldSection, section_index: number) {
        return (
          <FieldSectionComponent
            {...props}
            section={section}
            style={{zIndex: props.sections.length - section_index}}
            inspectWindow={props.inspectWindow}
          />
        );
      })}
    </WindowTileWrap>
  );
};

export default WindowTile;
