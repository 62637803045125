import React from 'react';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {KanbanColumn, ListScreenState, RecordScreenState} from '../types';
import {LangColorContext} from 'src/App';
import {goBack, isAdmin, IsHRWeb, Supported, WindowWrap} from '@src/tools/displayTools';
import {AddDateFilter, SetCurrentWindow2} from '@src/tools/windowTools';
import {translate} from '@src/utils/languageTools';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {TasksRecordScreenDefinition} from './tasksRecordScreenDefinition';
import {InspectWindow} from '@src/components/editElements/inspectWindow/InspectWindow';
import {GetDefaultBackBehaviour} from 'src/Tools/DisplayTools';
import {WindowRecord} from '@src/utils/types';
import {KanbanBoard} from '@src/components/kanbanBoard/KanbanBoard';
import {ListScreen} from '@src/types/Record/listScreen';
import Tasks_old from 'src/components/screens/Tasks';

const Drawer = createDrawerNavigator();

interface TasksListState extends ListScreenState {}

export class TasksList extends ListScreen<{}, TasksListState> {
  constructor(props) {
    super(props);
    this.rowClick = this.rowClick.bind(this);
    this.table = 'ActVc';
    this.ScreenName = 'Tasks';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
      Department: global.userData.deplist.split(',').pop(),
    } as TasksListState;
    SetCurrentWindow2('Tasks', 'TransDate', 'descending');
    AddDateFilter('Tasks');
  }

  getDefaultKanbanColumns = (): KanbanColumn[] => {
    return [
      {
        title: translate('TasksToDo'),
        key: 'ToDo',
      },
      {
        title: translate('TasksInProgress'),
        key: 'InProgress',
      },
      {
        title: translate('TasksDone'),
        key: 'Done',
      },
    ];
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <KanbanBoard
                table={'Kanban'}
                windowName={'Tasks'}
                update={this.state.update}
                data={this.state.data}
                hasDateFilter={true}
                filters={[
                  {
                    name: translate('FilterMyTasks'),
                    id: 'myuser',
                    selected: true,
                    changeable: isAdmin() ? true : false,
                  },
                  {name: translate('FilterApproval'), id: 'approval'},
                  {name: translate('FilterOther'), id: 'other'},
                ]}
                defaultColumns={this.getDefaultKanbanColumns()}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                addNewTitle={translate('AddNewTask')}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface TasksRecordState extends RecordScreenState {
  activeRow: number;
}

export class TasksRecord extends RecordScreen<{}, TasksRecordState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new TasksRecordScreenDefinition();

    this.table = 'ActVc';
    this.ScreenName = 'Tasks';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      Department: global.userData.deplist.split(',').pop(),
    } as TasksRecordState;
    this.record = this.createNewRecord();
  }

  isNewRecord = () => this.id === 'new';

  allowsEditAndDelete() {
    return this.record.headerFields.OKFlag !== '1';
  }

  createNewRecord = () => {
    return new WindowRecord(
      this.table,
      true,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true,
      this.recordWindowDefinitions.getSideSectionFields(this),
      this.recordWindowDefinitions.getHiddenFields()
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                key={'task' + this.id}
                title={translate('Tasks')}
                window={this}
                headerFields={self.recordWindowDefinitions.getHeaderFields(this)}
                sideSectionFields={self.recordWindowDefinitions.getSideSectionFields(this)}
                forceEditMode={this.isNewRecord()}
                record={this.record}
                okcheck={'OKFlag'}
                allowDelete={self.allowsEditAndDelete()}
                width={'840px'}
                hasBackButton={true}
                backButton={() => goBack(self, self.ScreenName)}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function Tasks() {
  if (Supported('activities_v2')) {
    return (
      <Drawer.Navigator
        style={{background: 'white'}}
        //@ts-ignore
        screenOptions={{headerShown: false}}
        backBehavior={GetDefaultBackBehaviour()}
        drawerStyle={{display: 'none'}}>
        <Drawer.Screen
          name="TasksList"
          component={TasksList}
          options={{
            title: translate('Tasks'),
          }}
        />
        <Drawer.Screen
          name="TasksRecord"
          component={TasksRecord}
          //@ts-ignore
          backBehavior={GetDefaultBackBehaviour()}
          options={{
            title: translate('Task'),
          }}
          //@ts-ignore
          getId={({params}) => params.id}
        />
      </Drawer.Navigator>
    );
  } else {
    return Tasks_old();
  }
}

export default Tasks;
