import styled from 'styled-components/native';
import {ColumnContainer} from '@styles/primitiveComponents';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import {isMobileOrTabletScreen} from '@tools/displayTools';

export const CompanySection = styled(ColumnContainer)`
  gap: ${spacing.space12}px;
  border-radius: ${isMobileOrTabletScreen() ? spacing.space12 : 0}px;
  background-color: ${colours[GetGlobalTheme()].white};
  padding: ${isMobileOrTabletScreen() ? spacing.space24 : 0}px;
`;

export const CompanyContainer = styled(ColumnContainer)``;

export const SearchBarWrapper = {
  padding: 0,
};

export const SearchIconStyle = {
  order: -1,
  marginRight: spacing.space8,
};

export const SearchBar = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  borderRadius: 8,
  borderWidth: 1,
  margin: 0,
  padding: spacing.space12,
  paddingLeft: spacing.space32,
  width: '100%',
};
