function fixupString(str: string) {
  let string = str.toString();
  if (string.length < 2) {
    string = '0' + string;
  }
  return string;
}

export function constructDateString(year, month, day) {
  return year + '-' + fixupString(month) + '-' + fixupString(day);
}

export function constructDateString2(date) {
  if (date && typeof date === 'object') {
    return date.getFullYear() + '-' + fixupString(date.getMonth() + 1) + '-' + fixupString(date.getDate());
  }
  return '';
}
export function getTimeSeconds(time: number) {
  const date = new Date('01/01/2007 ' + time);

  let seconds = date.getHours() * 3600;
  seconds += date.getMinutes() * 60;
  seconds += date.getSeconds();
  return seconds;
}

export function extractDateFromString(str: string) {
  if (!str) {
    return null;
  }
  return new Date(parseInt(str.substring(0, 4)), parseInt(str.substring(5, 7)) - 1, parseInt(str.substring(8, 10)));
}

function checkReplaceDateString(dateString, str: string, search, errorFlag) {
  const position = global.userData.dateformat.indexOf(search);
  if (position > -1) {
    const numstr = str.substring(position, position + search.length);
    const num = parseInt(numstr);
    if (isNaN(num)) {
      errorFlag.val = true;
    } else {
      dateString = dateString + numstr;
    }
  }
  return dateString;
}

export function convertDateStringToISODate(dateString: string) {
  const errorFlag = {val: false};
  if (!dateString) {
    return '';
  }
  let covertedDate = checkReplaceDateString('', dateString, 'YYYY', errorFlag) + '-';
  covertedDate = checkReplaceDateString(covertedDate, dateString, 'MM', errorFlag) + '-';
  covertedDate = checkReplaceDateString(covertedDate, dateString, 'DD', errorFlag);
  if (errorFlag.val === false) {
    return covertedDate;
  }
}

export function getCurrentDate() {
  return new Date();
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getDaysInMonth2(td: Date) {
  return new Date(td.getFullYear(), td.getMonth() + 1, 0).getDate();
}

export function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function addMonth(date: Date, diff) {
  let year = date.getFullYear();
  let month = date.getMonth();
  const day = date.getDate();
  month += diff;
  if (diff > 0 && month > 11) {
    month = month - 12;
    year = year + 1;
  } else if (diff < 0 && month < 0) {
    month = 12 + month;
    year = year - 1;
  }
  return new Date(year, month, day);
}

export function addYear(date: Date, diff) {
  let year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  year += diff;
  return new Date(year, month, day);
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getMonthName(monthIndex: number) {
  let monthName = '';
  if (monthIndex <= 0 || monthIndex >= 13) {
    return;
  }
  monthName = months[monthIndex - 1];
  return monthName;
}

export function dateStringToTimestamp(dateString: string): number {
  const [day, month, year] = dateString.split('.');
  return new Date(+year, +month - 1, +day).setHours(0, 0, 0, 0);
}
