import React, {Component, useRef, useState} from 'react';
import {View} from 'react-native';
import {DataTable} from 'react-native-paper';
import Requests from '../../api/Requests';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import * as myDate from '../../Tools/DateTools';
import {ConvertDisplayValue, DoNavigate, GetGlobalTheme, IsHRWeb, WindowElementType} from '../../Tools/DisplayTools';
import EditField from '../edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {ActionButton} from '../button/ActionButton';
import {GetWebColor} from '../../Tools/ShiftTools';
import {DefaultIconButton} from '../button/DefaultIconButton';
import InlineProfileIcon from './InlineProfileIcon';
import {useHover} from 'react-native-web-hooks';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';

const TableWrap = styled.View`
  position: absolute;
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
`;
const TableFilter = styled.View`
  flex-direction: row;
`;

const TableRow = styled(DataTable.Row)`
  background-color: ${(props) => (props.rowIndex % 2 ? 'transparent' : colours[GetGlobalTheme()].inputBackground)}

  font-size: ${fontSize.medium};
  min-height: 32px;
  border-bottom-width: 0px;
  border-radius: 6px;
  margin-vertical: 0px;
  ${
    IsHRWeb() &&
    css`
      margin-horizontal: ${spacing.medium};
      padding-horizontal: ${spacing.medium200};
    `
  }
  padding-vertical: 0px;
  margin-bottom: ${(props) =>
    !(props.arrayLength % 2) && props.rowIndex === props.arrayLength - 1 ? spacing.medium200 : 0};
`;

const TableHeaderRow = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].tableHeader};
  padding-vertical: 0px;
  font-size: ${fontSize.medium};
  border-bottom-width: 0px;
  height: 32px;
  line-height: 32px;
  ${IsHRWeb() &&
  css`
    margin-horizontal: ${spacing.medium};
    padding-horizontal: ${spacing.medium200};
  `}
`;

const Cell = styled(DataTable.Cell)`
  max-width: 30px;
  min-width: 30px;
  text-align: center;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 1;
`;
const HeaderCell = styled(DataTable.Title)`
  max-width: 30px;
  min-width: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
`;
const EmpCell = styled(DataTable.Cell)`
  width: 150px;
  border-right: 1px solid #ddd;
  justify-content: flex-start;
  flex-shrink: ${(props) => (props.weekf ? 1 : 0)};
  flex-grow: 1; /*${(props) => (props.weekf ? 0 : 1)}*/
`;
const HeaderEmpCell = styled(DataTable.Title)`
  width: 150px;
  border-right: 1px solid #ddd;
  justify-content: flex-start;
  flex-shrink: ${(props) => (props.weekf ? 1 : 0)};
  flex-grow: 1; /*${(props) => (props.weekf ? 0 : 1)}*/
  align-items: center;
`;
const CellText = styled.View`
  height: 100%;
  width: 100%;
  line-height: 32px;
  justify-content: center;
  align-items: center;
`;

const CellTextSimple = styled.View`
  height: 100%;
  line-height: 32px;
  justify-content: center;
  align-items: center;
`;

const CellTextWrap = styled.View`
  height: 32px;
  width: 30px;
  justify-content: center;
  align-items: center;
`;

const FilterView = styled.View`
  flex-direction: row;
`;

const FilterFields = styled.View`
  flex-direction: row;
`;
const FilterField = styled.View``;
const FilterActions = styled.View``;

const Navigation = styled.View`
  flex-direction: row;
  justify-content: center;
`;
const NavButton = styled.View`
  margin: 0px 20px;
`;
const TablePeriod = styled.Text``;
const TableNav = styled.View`
  padding: 20px;
`;

function HoverCell({self, data, info, cellstyle}) {
  const [pos, setPos] = useState({posx: 0, posy: 0});
  let res = [];
  let tmp = data.split2(':');
  let group = tmp[0];
  let type = tmp[1];
  let sernr = tmp[2];
  let okf = tmp[3];
  let color = '';
  let code = '';
  let ps = global.pasteSpecialsVector['19_2'];
  if (objectHasProperty(ps, group)) {
    color = GetWebColor(ps[group].ShiftColor, type === 'plan' ? 0.3 : 0.8);
    if (type === 'plan' && okf === '0') {
      color = 'rgba(202, 201, 203, 0.4)';
    }
    code = ps[group].TimeStatName;
  }
  let typestr = translate('AbsenceCalendar');
  if (type === 'plan' && sernr !== '-1' && sernr !== '') {
    typestr = translate('VacPlan');
  }
  let ref = useRef(null);
  const isHovered = useHover(ref);

  if (okf === '1') {
    res.push(
      <Cell style={cellstyle} onPress={() => self.OpenVacationRec(type, sernr)}>
        <CellTextWrap
          ref={ref}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            let top = layout.top;
            if (window.scrollY) {
              top = top + window.scrollY;
            }
            setPos({posx: layout.left, posy: top});
          }}>
          <CellText style={{backgroundColor: color}}>{code}</CellText>
        </CellTextWrap>
      </Cell>
    );
  } else {
    res.push(
      <Cell style={cellstyle} onPress={() => self.OpenVacationRec(type, sernr)}>
        <CellTextWrap
          ref={ref}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            let top = layout.top;
            if (window.scrollY) {
              top = top + window.scrollY;
            }
            setPos({posx: layout.left, posy: top});
          }}>
          <CellText style={{backgroundColor: color, cursor: 'pointer'}}>{code}</CellText>
        </CellTextWrap>
      </Cell>
    );
  }
  if (info) {
    info = info.replace(/\\n/g, '\n');
    info = info + '\n' + typestr;
    if (type === 'plan') {
      if (okf === '1') {
        info = info + ' (' + translate('Approved') + ')';
      } else {
        info = info + ' (' + translate('NotApproved') + ')';
      }
    }
    if (isHovered) {
      global.setToolTipInfo({show: true, text: info, ...pos});
    } else {
      global.setToolTipInfo({show: false});
    }
  }

  return res;
}

export default class EmpTimeTable extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.PeriodSD = myDate.getCurrentDate();
    this.state = {
      depcode: props.depcode,
      postcode: this.GetEmpPostCode(props.empcode),
      sd: {},
      ed: {},
      PeriodType: 0, //0 - month, 1 - week
      records: [],
    };
    this.GetEmpTimePlan();
  }

  CalculateSD = () => {
    let res;
    switch (this.state.PeriodType) {
      case 0: //month
        res = new Date(+this.PeriodSD);
        res.setDate(1);
        break;
      case 1: {
        //week
        let day = this.PeriodSD.getDay();
        res = myDate.AddDays(this.PeriodSD, day - 2);
        break;
      }
    }
    return res;
  };

  CalculateED = () => {
    let res;
    let tmp;
    switch (this.state.PeriodType) {
      case 0: //month
        tmp = myDate.AddMonth(this.state.sd, 1);
        res = myDate.AddDays(tmp, -1);
        break;
      case 1: //week
        tmp = new Date(+this.state.sd);
        res = myDate.AddDays(tmp, 6);
        break;
    }
    return res;
  };

  GetEmpPostCode = (empcode) => {
    let res = '';
    if (empcode !== '' && global.pasteSpecialsVector[0][empcode]) {
      //res = global.pasteSpecialsVector[0][empcode].PostCode;
    }
    return res;
  };

  GetEmpTimePlan = () => {
    let self = this;
    this.state.sd = this.CalculateSD();
    this.state.ed = this.CalculateED();

    Requests.doAction(
      'getemptimeplan',
      {},
      {
        depcode: this.state.depcode,
        postcode: this.state.postcode,
        sd: myDate.constructDateString2(this.state.sd),
        ed: myDate.constructDateString2(this.state.ed),
      }
    )
      .then((response) => {
        self.setState({records: response.records});
      })
      .catch((error) => {
        logError(error);
      });
  };

  OpenVacationRec = (type, sernr) => {
    switch (type) {
      case 'off':
        break;
      case 'plan':
        this.props.table.setState({showempplan: false});
        DoNavigate(this.props.table, 'VacPlans', true, sernr);
        break;
    }
  };

  DisplaySingleEmployee = (emp, index) => {
    let self = this;
    let res = [];
    let cells = [];
    cells.push(
      <EmpCell weekf={this.state.PeriodType === 1}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <InlineProfileIcon empcode={emp.empcode} />
          <CellTextSimple>{emp.empname}</CellTextSimple>
        </View>
      </EmpCell>
    );

    let days = 7;
    if (this.state.PeriodType === 0) {
      days = myDate.getDaysInMonth2(this.state.sd);
    }
    for (let i = 0; i < days; i++) {
      let td = myDate.AddDays(this.state.sd, i);
      let datestr = myDate.constructDateString2(td);
      let style = {};
      let day = td.getDay();
      let tt = myDate.constructDateString2(td);
      if (global.transferredDays2[tt]) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
      }
      if (day === 6 || day === 0) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
      }
      if (global.holidaysvector[tt]) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorHoliday);
      }

      let data;
      let info;
      if (objectHasProperty(emp, datestr)) {
        data = emp[datestr];
      }
      if (data === undefined && objectHasProperty(emp, datestr + 'data')) {
        data = emp[datestr + 'data'];
        info = emp[datestr + 'info'];
      }
      if (data !== undefined) {
        cells.push(<HoverCell self={self} data={data} info={info} cellstyle={style} />);
      } else {
        cells.push(<Cell style={style}></Cell>);
      }
    }
    res.push(<TableRow rowIndex={index}>{cells}</TableRow>);

    return res;
  };

  DisplayTableHeader = () => {
    let days = 7;
    if (this.state.PeriodType === 0) {
      days = myDate.getDaysInMonth2(this.state.sd);
    }
    let cells = [];
    let res = [];
    cells.push(<HeaderEmpCell weekf={this.state.PeriodType === 1}>{translate('Name')}</HeaderEmpCell>);
    for (let i = 0; i < days; i++) {
      let td = myDate.AddDays(this.state.sd, i);
      let day = td.getDay();
      let style = {};
      let tt = myDate.constructDateString2(td);
      if (global.transferredDays2[tt]) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
      }
      if (day === 6 || day === 0) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
      }
      if (global.holidaysvector[tt]) {
        style.backgroundColor = GetWebColor(global.shiftBlock.ColorHoliday);
      }
      cells.push(<HeaderCell style={style}>{td.getDate()}</HeaderCell>);
    }
    res.push(<TableHeaderRow>{cells}</TableHeaderRow>);

    return res;
  };

  DoNavigatePeriod = (dir) => {
    switch (this.state.PeriodType) {
      case 0:
        this.PeriodSD = myDate.AddMonth(this.PeriodSD, dir * 1);
        break;
      case 1:
        this.PeriodSD = myDate.AddDays(this.PeriodSD, dir * 7);
        break;
    }
    this.GetEmpTimePlan();
  };

  DisplayTableNavigation = () => {
    let res = [];
    let self = this;
    res.push(
      <Navigation>
        <NavButton>
          <DefaultIconButton
            style={{height: 'auto', width: 'auto', padding: 0, margin: 0}}
            iconName={'angle-double-left'}
            mode="text"
            size={IsHRWeb() ? 17 : 36}
            iconType="FA5"
            action={() => self.DoNavigatePeriod(-1)}
          />
        </NavButton>
        <TablePeriod>
          {ConvertDisplayValue(WindowElementType.kInputTypeDate, myDate.constructDateString2(this.state.sd)) +
            ':' +
            ConvertDisplayValue(WindowElementType.kInputTypeDate, myDate.constructDateString2(this.state.ed))}
        </TablePeriod>
        <NavButton>
          <DefaultIconButton
            style={{height: 'auto', width: 'auto', padding: 0, margin: 0}}
            iconName={'angle-double-right'}
            mode="text"
            size={IsHRWeb() ? 17 : 36}
            iconType="FA5"
            action={() => self.DoNavigatePeriod(1)}
          />
        </NavButton>
      </Navigation>
    );
    return res;
  };

  render() {
    let self = this;
    return (
      <TableWrap style={{minWidth: '1050px', maxWidth: '1500px'}}>
        <TableFilter>
          <FilterView>
            <FilterFields>
              <FilterField>
                <EditField
                  title={translate('Department')}
                  value={this.state.depcode}
                  ps={7}
                  psfields={['depcode', 'name']}
                  psheader={[
                    {
                      name: translate('Code'),
                      width: '35%',
                      key: 'DepCode',
                      mainkey: 'DepCode',
                    },
                    {
                      width: '65%',
                      name: translate('Name'),
                      key: 'Name',
                    },
                  ]}
                  onChange={({value}: InputChangeEventProps) => {
                    self.setState({depcode: value}, () => {
                      self.GetEmpTimePlan();
                    });
                  }}
                />
              </FilterField>
              <FilterField>
                <EditField
                  title={translate('Post')}
                  value={this.state.postcode}
                  ps={11}
                  psheader={[
                    {
                      name: translate('Code'),
                      width: '35%',
                      key: 'PostCode',
                      mainkey: 'PostCode',
                    },
                    {
                      width: '65%',
                      name: translate('Name'),
                      key: 'PostName',
                    },
                  ]}
                  onChange={({value}: InputChangeEventProps) => {
                    self.setState({postcode: value}, () => {
                      self.GetEmpTimePlan();
                    });
                  }}
                />
              </FilterField>
              {/* <FilterField>
                <EditField
                  title={translate('PeriodType')}
                  value={this.state.PeriodType}
                  editable={true}
                  width={'150px'}
                  ps={9991}
                  psfields={['code', 'comment']}
                  onChange={({value}: InputChangeEventProps) => {
                    self.setState({PeriodType: parseInt(value)}, () => {
                      self.GetEmpTimePlan();
                    });
                  }}
                />
              </FilterField> */}
            </FilterFields>
            <FilterActions>
              <ActionButton
                style={{marginTop: '32px', marginRight: '20px'}}
                onPress={() => self.props.table.navigation.goBack()}
                title={translate('Close')}
              />
            </FilterActions>
          </FilterView>
        </TableFilter>
        <TableNav>{this.DisplayTableNavigation()}</TableNav>
        <DataTable style={{width: this.state.PeriodType === 1 ? 550 : ''}}>
          {this.DisplayTableHeader()}
          {this.state.records.map(function (emp, index) {
            return self.DisplaySingleEmployee(emp, index);
          })}
        </DataTable>
      </TableWrap>
    );
  }
}
