import React, {useState} from 'react';
import styled from 'styled-components';
import {GetGlobalTheme} from '@src/tools/displayTools';
import {colours} from '@src/styles/Style';
import {TextSmall} from '@src/styles/primitiveComponents';

const TooltipArrow = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: ${colours[GetGlobalTheme()].primary} transparent transparent transparent;
`;

export type ToolTipObj = {
  show: boolean;
  text: string;
  positionX: number;
  positionY: number;
  fieldWidth: number;
  maxCharacters: number;
};

const toolTipTopOffset = 45;
const toolTipShortenedSuffix = '...';

const ToolTip: React.FC = () => {
  const [toolTip, setToolTip] = useState<ToolTipObj>({
    show: false,
    text: '',
    positionX: 0,
    positionY: 0,
    fieldWidth: 0,
    maxCharacters: 40,
  });
  const [toolTipWidth, setToolTipWidth] = useState<number>(0);

  const TooltipContent = styled.span`
    visibility: ${toolTip.show ? (toolTipWidth === 0 ? 'hidden' : 'visible') : 'hidden'};
    width: auto;
    background-color: ${colours[GetGlobalTheme()].primary};
    color: ${colours[GetGlobalTheme()].white};
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 999;
    left: ${toolTip.positionX - toolTipWidth / 2 + toolTip.fieldWidth / 2.5}px;
    top: ${toolTip.positionY - toolTipTopOffset}px;
    white-space: nowrap;
    height: 18px;
  `;

  global.setToolTip = (toolTip: ToolTipObj) => {
    setToolTip(toolTip);
  };

  function getToolTipText() {
    if (toolTip.text.length > toolTip.maxCharacters) {
      return toolTip.text.slice(0, toolTip.maxCharacters) + toolTipShortenedSuffix;
    }
    return toolTip.text;
  }

  return (
    <TooltipContent>
      <TextSmall
        style={{color: colours[GetGlobalTheme()].white, whiteSpace: 'nowrap', bottom: 2}}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          setToolTipWidth(layout.width);
        }}>
        {getToolTipText()}
      </TextSmall>
      <TooltipArrow />
    </TooltipContent>
  );
};

export default ToolTip;
