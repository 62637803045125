import React from 'react';
import styled from 'styled-components/native';
import {colours} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';

const Background = styled.View`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Wrap = styled.View`
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  margin-top: 40px;
`;

export const ModalWindow_Old = (props) => {
  return (
    <Background>
      <Wrap>{props.children}</Wrap>
    </Background>
  );
};
