import {getEditFieldDataType, WindowElementType} from '@tools/displayTools';
import {objectHasProperty} from '@tools/objectTools';
import {isButtonField} from '@src/components/editElements/inspectWindow/WindowTile';
import {ReactElement} from 'react';
import {BrowseColumn, DateFormatOrigin, MobileBrowseColumnDisplayType, ViewMode} from '@components/screens/types';
import {InspectWindow} from '@src/components/editElements/inspectWindow/InspectWindow';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {AlertType} from '@src/components/design/AlertWindow';
import {MultiDropDownField} from '@components/editElements/pasteSpecial/ClassificationPasteSpecial';

export enum ModuleType {
  none = 'none',
  tasks = 'tasks',
  hr = 'hr',
  sales = 'sales',
  purchases = 'purchases',
  stock = 'stock',
  reports = 'reports',
}

export const enum FieldSectionType {
  Fields,
  RecordList,
  Matrix,
}
export const enum PasteSpecialWindowLayout {
  SingleColumnWithDescription,
  Default,
}

export const enum PasteSpecialDisplayType {
  Name,
  Description,
}

interface OverwriteRecordScreenProps {
  value: any;
  record: WindowRecord; 
}

export type OverwriteMatrixProps = {
  value: string;
  rowindex: number;
  colindex: number;
  value2: string;
  datakey: string;
};

export type UseMultiDropDownWithFiltersResult = {
  useMultiDropDowns: boolean;
  filterField?: string;
  filterValue?: string;
  table: string;
};

export type OverwriteBrowseColumnProps = {
  id: string;
  value: string;
  rowindex: number;
  colindex: number;
  row: object;
  column?: BrowseColumn;
};

export type InputChangeEventProps = {
  rowindex: number;
  fieldname: string;
  value: string;
  inspectWindow?: InspectWindow;
};

export type ListWindowColumn = {
  name: string;
  width: string | number;
  maxWidth?: string;
  alignment?: string;
  key: string;
  key2?: string;
  tagKey?: string;
  mainKey?: string;
  sortKey?: string;
  windowElementType?: WindowElementType;
  overwrite?: (overwriteBrowseColumnProps: OverwriteBrowseColumnProps, additionalData?: string) => string | JSX.Element;
  displayType?: PasteSpecialDisplayType;
  displayFormat?: string;
  mobileDisplayType?: MobileBrowseColumnDisplayType;
  additionalDataKey?: string;
};

export interface PasteSpecial {
  vcName: string | number;
  columns?: any;
  hideComment?: boolean;
  newRecord?: boolean;
  filter?: any; //TODO: fix >> should be an object. search for psfilter
  windowLayout?: PasteSpecialWindowLayout;
  multiValue?: boolean;
  useAlternateDisplay?: boolean; // If true, it will display differently in view mode, depending on your settings
  recordLinkFunction?: () => void;
  viewModeDisplayFormat?: string;
  mainKey?: string; // Key used to find the correct record in a list of records
  toolTip?: {hasToolTip: boolean; key: string; maxCharacters: number};
  nameDisplayFormat?: string;
  details?: {fields: any; titleKey: string};
  pasteSpecialValueFields?: string;
  keyToDisplayInViewMode?: Array<string>;
  hideRemoveOption?: boolean;
  useMultiDropDownWithFilters?: () => UseMultiDropDownWithFiltersResult;
  multiDropDownField?: MultiDropDownField;
  isDropDownField?: boolean;
}

export interface InlinePasteSpecial extends PasteSpecial {
  fields: string[];
}

export interface ExternalPasteSpecial extends PasteSpecial {
  multiValue?: boolean;
  columns: ListWindowColumn[];
}

export type WindowDefinition = WindowFieldLine[];

export type WindowFieldLine = {
  fields: (CheckboxField | TextField | ButtonField)[];
  style?: React.CSSProperties;
};

export type TileListItem = {
  name: string;
  tileNumber: number;
};

export type TileButtons = {
  component?: any;
  tiles: TileListItem[];
};

export type FieldLine = {
  line: (CheckboxField | TextField | ButtonField)[];
  style?: React.CSSProperties;
};

export type AttachmentField = {
  wideView?: boolean;
  showTitle?: boolean;
};

export type FieldSectionGroup = {
  fields?: (CheckboxField | TextField | ButtonField | FieldLine)[];
  style?: React.CSSProperties;
  matrix?: WindowMatrix;
  attachments?: AttachmentField;
  component?: (window?: InspectWindow) => React.ReactElement;
  label?: string;
  divider?: boolean;
  viewStyle?: React.CSSProperties;
};

export type FieldSection = {
  label?: string;
  groups?: FieldSectionGroup[];
  collapse?: boolean;
  recordList?: any /*should be some recordList type or class*/;
  style?: React.CSSProperties;
  listStyle?: React.CSSProperties;
  overrideKey?: string;
};

export type SectionTiles = {
  fieldSections: FieldSection[];
};

export type OperationMenuPressProps = {
  inspectWindow?: InspectWindow;
};

export type DeleteRecordProps = {
  inspectWindow?: InspectWindow;
  redirect?: boolean;
  onDeleteRefreshList?: () => void;
};

export type UnOKRecordProps = {
  inspectWindow?: InspectWindow;
};

export type OperationsMenu = {
  label: string;
  onPress: (operationMenuPressProps: OperationMenuPressProps) => void;
};

export type WindowDefinition2 = {
  sectionList?: TileButtons; //side menu for subsection - contact window >> contact info, accounts etc
  sectionTiles: SectionTiles[];
  operationMenu?: OperationsMenu[];
  editable: boolean;
  startViewComponent?: (inspectWindow: InspectWindow) => React.ReactElement;
  endViewComponent?: (inspectWindow: InspectWindow) => React.ReactElement;
};

interface WindowField {
  label: string;
  hideLabelWhileView?: boolean;
  windowElementType?: WindowElementType | ((viewMode: ViewMode) => WindowElementType);
  sortKey?: string;
  width?: string | number;
  style?: React.CSSProperties;
  styleFunc?: (isEditable: boolean) => void;
  row?: boolean;
  allowManualEntry?: boolean;
}

export interface CheckboxField extends WindowField {
  checkboxInactiveLabel: string;
  checkboxTooltip?: string;
  linkedField?: string;
  dataKey: string;
  viewOnly: boolean;
  onChange?: (inputChangeEventProps: InputChangeEventProps) => void;
}

export interface TextField extends WindowField {
  pasteSpecial?: InlinePasteSpecial | ExternalPasteSpecial;
  fixedValue?: string;
  dataKey?: string;
  reference?: any;
  labelStyle?: React.CSSProperties;
  allowClearing?: boolean;
  matrixField?: boolean;
  viewOnly?: boolean;
  onChange?: (inputChangeEventProps: InputChangeEventProps) => void;
  onFocus?: () => void;
  overwrite?: (props: OverwriteRecordScreenProps) => string | JSX.Element;
  dateFormatOrigin?: DateFormatOrigin;
}

export interface ButtonField extends WindowField {
  onPress: () => void;
}

export type WindowMatrix = (MatrixField | SingleMatrixColumn)[];

export interface SingleMatrixColumn {
  singleColumn: MatrixField[];
  label: string;
  width?: string | number;
  cellStyle: React.CSSProperties;
  style?: React.CSSProperties;
}

export interface MatrixField {
  label: string;
  windowElementType?: WindowElementType;
  dataKey?: string;
  dataKey2?: string;
  width?: string | number;
  viewOnly?: boolean;
  cellstyle?: React.CSSProperties;
  style?: (viewMode: ViewMode) => React.CSSProperties | React.CSSProperties;
  showProfile?: string;
  pasteSpecial?: InlinePasteSpecial | ExternalPasteSpecial;
  overwrite?: (overwriteMatrixProps: OverwriteMatrixProps) => Element;
  onChange?: (inputChangeEventProps: InputChangeEventProps) => void;
  onFocus?: (index: number, datakey: string) => void;
  display?: boolean;
}

export type BrowseFilter = {
  name: string;
  id: string;
  selected?: boolean;
  changeable?: boolean;
};

export interface TableContainerProps {
  data: any;
  table: string | number;
  records?: RecordScreen<any, any>[];
  update: boolean;
  title: string;
  width?: number | string;
  rowID?: string;
  filters?: any;
  doClick?: (id: string) => void;
  extraContent?: () => ReactElement;
  browseColumns: ListWindowColumn[];
  windowName: string;
  hasDateFilter?: boolean;
  hasSearch?: boolean;
  hasPagination?: boolean;
  addNewTitle?: string;
  wrapperStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  perPage?: number;
  onlyAllowMainColumnClick?: boolean;
  navigation?: any;
  disableSorting?: boolean;
}

/*
//TODO: implement customer fields with PopulateFields and PopulateColumns
    this.props.record = PopulateRecordFields(
      this.props.record,
      this.props.window.table
    );

*/
export class WindowRecord {
  vcName: string | number;
  hasMatrix: boolean;
  headerFields: any;
  matrixFields: any;
  matrixRows?: Array<any>;
  updateFields: Array<any>;
  removedRows: Array<any>;
  orig: {headerFields: any; matrixRows: Array<any>};
  pagination: {
    page: number;
    perpage: number;
    pages: number;
    changeMatrixPage: (page: number) => void;
    updateMatrixPages: () => void;
    changePagePeriod: (value: number) => void;
  };
  WindowDef: {
    headerFields: WindowDefinition | WindowDefinition2;
    matrixFields?: WindowMatrix;
  };

  constructor(
    vcName: string | number,
    hasMatrix: boolean,
    headerFields: WindowDefinition | WindowDefinition2,
    matrixFields?: WindowMatrix,
    footerFields?: WindowDefinition,
    newWindowDef?: boolean,
    sideSectionFields?: FieldSection[],
    hiddenFields?: Array<string>
  ) {
    this.headerFields = {};
    this.matrixFields = [];
    this.updateFields = [];
    this.removedRows = [];
    this.orig = {headerFields: {}, matrixRows: []};
    this.vcName = vcName;
    this.hasMatrix = hasMatrix || false;
    if (newWindowDef) {
      this.WindowDef = this.ExtractNewWindowDefHeaderFields(headerFields, sideSectionFields, hiddenFields);
    } else {
      let fields = (headerFields ?? []).concat(footerFields ?? []);
      this.WindowDef = {
        headerFields: fields,
        matrixFields: matrixFields,
      };
    }
    let self = this;
    this.pagination = {
      page: 0,
      perpage: 20,
      pages: 0,
      changeMatrixPage: (page) => self.changeMatrixPage(page),
      updateMatrixPages: () => self.updateMatrixPages(),
      changePagePeriod: (value) => self.changePagePeriod(value),
    };
    this.createRecordFields();
  }

  getFieldDataType(field) {
    return getEditFieldDataType(field.windowElementType, {dateFormatOrigin: field.dateFormatOrigin});
  }

  getHeaderFieldList() {
    return this.WindowDef.headerFields[0].fields
      .map((field) => field.dataKey + ':' + this.getFieldDataType(field))
      .join(',');
  }

  getMatrixFieldList() {
    return this.WindowDef.matrixFields.map((u) => u.dataKey + ':' + u.windowElementType).join(',');
  }

  ExtractNewWindowDefHeaderFields(headerFields, sideSectionFields, hiddenFields) {
    let resFields = {headerFields: [{fields: []}], matrixFields: []};
    if (objectHasProperty(headerFields, 'sectionTiles')) {
      for (let t = 0; t < headerFields.sectionTiles.length; t++) {
        let tile = headerFields.sectionTiles[t];
        if (objectHasProperty(tile, 'fieldSections')) {
          for (let s = 0; s < tile.fieldSections.length; s++) {
            let section = tile.fieldSections[s];
            if (objectHasProperty(section, 'groups')) {
              for (let g = 0; g < section.groups.length; g++) {
                let group = section.groups[g];
                if (objectHasProperty(group, 'fields')) {
                  let fields = group.fields;
                  resFields.headerFields[0].fields = resFields.headerFields[0].fields.concat(fields);
                }
                if (objectHasProperty(group, 'matrix')) {
                  resFields.matrixFields = resFields.matrixFields.concat(group.matrix);
                }
              }
            }
          }
        }
      }
    }
    for (let i = 0; i < resFields.headerFields[0].fields.length; i++) {
      if (objectHasProperty(resFields.headerFields[0].fields[i], 'line')) {
        resFields.headerFields[0].fields.splice(i, 1, ...resFields.headerFields[0].fields[i].line);
      }
    }
    for (let i = 0; i < resFields.matrixFields.length; i++) {
      if (objectHasProperty(resFields.matrixFields[i], 'singleColumn')) {
        resFields.matrixFields.splice(i, 1, ...resFields.matrixFields[i].singleColumn);
      }
    }

    if (sideSectionFields) {
      for (let s = 0; s < sideSectionFields.length; s++) {
        let section = sideSectionFields[s];
        if (objectHasProperty(section, 'groups')) {
          for (let g = 0; g < section.groups.length; g++) {
            let group = section.groups[g];
            let fields = group.fields;
            if (objectHasProperty(group, 'fields')) {
              let fields = group.fields;
              resFields.headerFields[0].fields = resFields.headerFields[0].fields.concat(fields);
            }
          }
        }
      }
    }
    if (hiddenFields) {
      hiddenFields.forEach((field) => {
        resFields.headerFields[0].fields.push({
          title: field,
          dataKey: field,
          windowElementType: WindowElementType.kInputTypeNone,
        });
      });
    }

    return resFields;
  }

  createRecordFields(): void {
    if (this.WindowDef.headerFields) {
      for (let i = 0; i < this.WindowDef.headerFields.length; i++) {
        for (let s in this.WindowDef.headerFields[i].fields) {
          let field = this.WindowDef.headerFields[i].fields[s];
          if (isButtonField(field) === false && objectHasProperty(field, 'dataKey')) {
            this.headerFields[field.dataKey] = '';
          }
        }
      }
    }
    if (this.hasMatrix && this.WindowDef.matrixFields) {
      this.matrixRows = [];
    }
  }

  removeExistingUpdateField(rownr, field) {
    for (let i = 0; i < this.updateFields.length; i++) {
      let f = this.updateFields[i];
      if (f.field === field && f.rownr === rownr) {
        this.updateFields.splice(i, 1);
        break;
      }
    }
  }

  fieldValueHasChanged(rownr, field, value): boolean {
    if (this.orig) {
      if (rownr > 0) {
        return this.orig.matrixRows?.[rownr]?.[field] !== value;
      } else {
        return this.orig.headerFields?.[field] !== value;
      }
    }
  }

  addUpdateField(rownr, field, value): void {
    if (this.fieldValueHasChanged(rownr, field, value)) {
      this.removeExistingUpdateField(rownr, field);
      this.updateFields.push({
        rownr: rownr,
        field: field,
        value: value,
      });
    } else {
      this.removeExistingUpdateField(rownr, field);
    }
  }

  clearUpdateFields() {
    this.updateFields = [];
    this.removedRows = [];
  }

  addMatrixRow(): void {
    if (this.hasMatrix) {
      let row: any = {};
      for (let i in this.WindowDef.matrixFields) {
        row[this.WindowDef.matrixFields[i].dataKey] = '';
      }
      this.matrixRows.push(row);
    }
  }

  updateAllUpdatedRows(pos) {
    for (let i = 0; i < this.updateFields.length; i++) {
      let f = this.updateFields[i];
      if (f.rownr >= pos) {
        f.rownr--;
      }
    }
  }

  deleteMatrixRow(pos: number): void {
    if (this.hasMatrix) {
      if (objectHasProperty(this.matrixRows[pos], '_orig')) {
        this.removedRows.push(this.matrixRows[pos]._orig);
      }
      this.matrixRows.splice(pos, 1);
      this.updateAllUpdatedRows(pos);
    }
  }

  applyNewValues2({newRecord, setOrig = true, reset = true, applyWActions = false}): void {
    if (reset) {
      this.createRecordFields();
    }
    for (let s in newRecord) {
      if (s !== 'rows') {
        this.headerFields[s] = newRecord[s];
        if (applyWActions) {
          this.addUpdateField(-1, s, newRecord[s]);
        }
      }
    }
    if (this.hasMatrix) {
      if (objectHasProperty(newRecord, 'rows')) {
        //
        this.matrixRows = [];
        for (let i = 0; i < newRecord.rows.length; i++) {
          let num = this.matrixRows.length;
          this.addMatrixRow();
          for (let s in newRecord.rows[i]) {
            this.matrixRows[num][s] = newRecord.rows[i][s];
            if (applyWActions) {
              this.addUpdateField(num, s, newRecord.rows[i][s]);
            }
          }
        }
        this.updateMatrixPages();
      }
    }
    if (setOrig) {
      if (this.hasMatrix) {
        for (let i = 0; i < this.matrixRows.length; i++) {
          this.matrixRows[i]._orig = i;
        }
      }
      this.orig.headerFields = {...this.headerFields};
      if (this.hasMatrix) {
        this.orig.matrixRows = [...this.matrixRows];
      }
    }
  }

  applyNewValues(newRecord: any): void {
    this.applyNewValues2({newRecord});
  }

  resetRecord(): void {
    this.headerFields = this.orig.headerFields;
    this.matrixRows = this.orig.matrixRows;
  }

  changeMatrixPage(page: number): void {
    this.pagination.page = page;
  }

  updateMatrixPages(): void {
    this.pagination.page = 0;
    this.pagination.pages = parseInt(this.matrixRows.length / this.pagination.perpage) + 1;
  }

  changePagePeriod(value: number): void {
    this.pagination.perpage = value;
    this.updateMatrixPages();
  }
}

type MessageBoxProps = {
  showMessage: boolean;
  statusMessage: string;
};

type WindowDefList = {list: any; curwindow: string};

export type CompanyData = {
  name: string;
  uuid: string;
};

type UserData = {
  deplist: string;
  complist: Array<CompanyData>;
  selectedcomp: string;
  empname: string;
  provider: number;
  supported: string[];
  apiversion: number;
  usercode: string;
  initials: string;
  iconcolour: string;
  contractnr?: string;
  dateformat: string;
  version: string;
  forceFeatures: string[];
  restrictFeatures: string[];
  admin: string;
};

type DelayedStatusMessage = {
  statusMessage: string;
  statusMessageType: AlertType;
};

declare global {
  var messageBox: (messageBoxProps: MessageBoxProps) => void;
  var PopEscapeActions: () => void;
  var PushEscapeActions: (action: any) => void;
  var pasteSpecials: any;
  var duringOpen: boolean;
  var pasteSpecialsVector: any;
  var holidays: any;
  var resetScreen: boolean;
  var shiftBlock: any;
  var holidaysvector: any;
  var transferredDays: any;
  var escapeactions: Array<any>;
  var keyactions: any;
  var scrollactions: Array<any>;
  var transferredDays2: any;
  var empInfoList: any;
  var customfields: any;
  var custompastespecials: any;
  var userData: UserData;
  var windowTile: string;
  var windowNewButton: Array<any>;
  var setUserDetails: any;
  var testLoginCredentials: boolean;
  var windows: WindowDefList;
  var windowStates: any;
  var loadingspec: number;
  var loadedspec: number;
  var activeEditMode: boolean;
  var updateStatusText: (data: string) => void;
  var redirectTo: string;
  var sessionEnded: boolean;
  var delayedStatusMesssage: DelayedStatusMessage;
}
