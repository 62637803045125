import React, {Component} from 'react';
import {Platform} from 'react-native';
import styled from 'styled-components/native';

import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import Requests from '../../../api/Requests';
import {b64toBlob} from '../../../Tools/Base64Decode';
import SafeView from '../../../components/status/SafeView';
import {logError} from '@utils/debug';
import {objectHasProperty} from '@tools/objectTools';
import FileUploader from '@components/fileUploader/FileUploader';
import {Header6} from '@styles/primitiveComponents';
import {Attachment} from '@components/attachments/Attachment';
import {ViewMode} from '../screens/types';
import FilePreview from '@src/components/FilePreview/FilePreview';
import {RecordLink} from '@components/recordLink/RecordLink';
import {DoNavigate, isMobileOrTabletScreen} from '@tools/displayTools';
import {getDrawers} from '@utils/drawersTools';
import {NotSupportedFile} from './fileTypes/NotSupportedFile';

const AttachmentHeader = styled.View``;
const AttachmentList = styled.View`
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

const AttachmentTitle = styled(Header6)`
  margin-top: ${spacing.space12}px;
`;

const AttachmentBackground = styled.View`
  width: 100%;
  border-radius: ${isMobileOrTabletScreen() ? 12 : 0};
  padding: ${isMobileOrTabletScreen() ? spacing.space16 : 0}px;
  padding-bottom: ${isMobileOrTabletScreen() ? spacing.space8 : 0}px;
  padding-top: ${isMobileOrTabletScreen() ? spacing.space8 : 0}px;
  margin: 0;
  right: 0;
  background-color: ${colours[GetGlobalTheme()].white};
  align-items: center;
`;

const recordsToNotDisplay = ['EMailQueVc'];

type RecordObj = {
  ID: string;
  VcName: string;
  Comment: string;
  Users: string;
  Size: string;
  canBeOpened: boolean;
  displayName: string;
  moduleName?: string;
};

type AttachmentWindowProps = {
  action: () => void;
  key: string;
  viewMode: ViewMode;
  visible: boolean;
  id: string;
  table: any;
  window: any;
  wideView?: boolean;
  showTitle?: boolean;
};

type AttachmentWindowState = {
  filesToDelete: any[];
  filesToUpload: any[];
  attachmentList: RecordObj[];
  recordLinkList: RecordObj[];
  notSupportedFile: RecordObj[];
  showPreview: boolean;
  activeFile: {};
  fileList: {};
  visible: boolean;
  loading: boolean;
  uploading: boolean;
  loaded: boolean;
  appliedEditChanges: boolean;
  update: boolean;
};

export class AttachmentWindow extends Component<AttachmentWindowProps, AttachmentWindowState> {
  minFileSizeInMB = 0.0001;
  maxFileSizeInMB = 20;
  recordID: string;
  toProcessFiles: number;
  processedFiles: number;
  callBack: any;

  constructor(props: AttachmentWindowProps) {
    super(props);
    this.state = {
      filesToDelete: [],
      filesToUpload: [],
      attachmentList: [],
      recordLinkList: [],
      notSupportedFile: [],
      showPreview: false,
      activeFile: {},
      fileList: {},
      visible: props.visible,
      loading: false,
      uploading: false,
      loaded: false,
      appliedEditChanges: false,
      update: false,
    };
    this.recordID = props.id;
    this.loadAttachments();
    global.reloadAttachmentList = () => {
      this.loadAttachments();
    };
    global.updateAttachmentsOnSave = (id: string, callBack) => {
      if (id) {
        this.recordID = id;
      }
      this.callBack = callBack;
      this.applyEditChanges();
    };
  }

  loadAttachments = () => {
    let self = this;
    const notSupportedRegisters = ['NotepadVc', 'ArchiveVc'];

    if (this.recordID !== undefined) {
      Requests.doAction('getrecordlinks', {
        id: this.recordID,
        regname: this.props.table,
      })
        .then((response) => {
          const recordsWithAttachments = response.records.filter((record: RecordObj) => record.VcName === 'Attach2Vc');
          const notSupportedFile = response.records.filter((record: RecordObj) =>
            notSupportedRegisters.includes(record.VcName)
          );
          const recordLinks = response.records.filter(
            (record: RecordObj) => !['Attach2Vc', 'ArchiveVc', 'NotepadVc'].includes(record.VcName)
          );
          this.processFiles(recordsWithAttachments, recordLinks, notSupportedFile);
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  processFiles(recordsWithAttachments: RecordObj[], recordLinks: RecordObj[], notSupportedFile: RecordObj[]) {
    /*
    I have added update to state here, because it seems that react doesn't update state 
    if the array is the same length as before, even if the contents of the array have 
    changed and it's not the same array. It only checks the length
    */
    this.setState({
      loaded: true,
      attachmentList: recordsWithAttachments,
      showPreview: false,
      update: !this.state.update,
      recordLinkList: this.getValidModules(recordLinks),
      notSupportedFile: notSupportedFile,
    });
  }

  getMatchingSupportedLink(record) {
    const supportedLinks: any[] = getDrawers(1);
    return supportedLinks.find((link) => Object.keys(link).includes('vcName') && link.vcName.includes(record.VcName));
  }

  getValidModuleDisplayName(record) {
    switch (record.VcName) {
      case 'ActVc':
        return `${translate(record.VcName)} ${record.Comment}`;
      case 'MailVc':
        return `${translate(record.VcName)}`;
      default:
        return `${translate(record.VcName)} ${record.ID}`;
    }
  }

  getValidModules(recordLinksArr: RecordObj[]) {
    if (recordLinksArr.length === 0) {
      return [];
    }
    let recordLinksCopy: RecordObj[] = [...recordLinksArr];

    recordLinksCopy = recordLinksCopy.map((record) => {
      const matchingLink = this.getMatchingSupportedLink(record);
      let linkIsOpenable = !!matchingLink;
      let moduleName = matchingLink ? matchingLink.screen : '';

      const displayName = this.getValidModuleDisplayName(record);
      return {
        ...record,
        canBeOpened: linkIsOpenable,
        displayName: displayName,
        moduleName: moduleName,
      };
    });

    recordLinksCopy = recordLinksCopy.filter((record) => !recordsToNotDisplay.includes(record.VcName));

    return recordLinksCopy;
  }

  initProcessedFiles() {
    this.toProcessFiles = this.state.filesToUpload.length + this.state.filesToDelete.length;
    this.processedFiles = 0;
  }

  checkProcessedFiles() {
    if (this.toProcessFiles === this.processedFiles) {
      if (this.callBack) {
        this.callBack();
      }
    }
  }

  applyEditChanges() {
    this.initProcessedFiles();
    if (this.state.filesToUpload.length > 0) {
      this.state.filesToUpload.forEach((file) => {
        if (!this.state.filesToDelete.includes(file[0].lastModified)) {
          this.uploadFile(file);
        }
      });
      this.setState({filesToUpload: []});
    }

    if (this.state.filesToDelete.length > 0) {
      this.state.filesToDelete.forEach((id) => {
        this.deleteFile(id);
      });
      this.setState({filesToDelete: []});
    }
    this.checkProcessedFiles();
  }

  getFile = async (fileToGet) => {
    let file = null;
    if (objectHasProperty(this.state.fileList, fileToGet.ID)) {
      file = this.state.fileList[fileToGet.ID];
    }
    if (!file) {
      let response = await Requests.doAction('getfile', {
        id: this.recordID + '&file=' + fileToGet.ID,
        regname: this.props.table,
      });
      file = response.file;
      if (file) {
        this.state.fileList[fileToGet.ID] = file;
      }
    }

    // Get the current 'global' time from an API using Promise
    return new Promise((resolve, reject) => {
      if (file) {
        resolve(file);
      } else {
        reject('Error');
      }
    });
  };

  handleDeleteFile(file) {
    const self = this;
    if (this.props.viewMode === ViewMode.Edit) {
      self.queueFileForDeletion(file.ID);
      self.removeFileVisually(file);
    }
  }

  queueFileForDeletion(id) {
    const self = this;
    const filesToDeleteArr = this.state.filesToDelete;
    filesToDeleteArr.push(id);
    self.setState({filesToDelete: filesToDeleteArr});
    global.activeEditMode = true;
  }

  deleteFile(id) {
    let self = this;

    Requests.doAction('deleteattachment', {
      id: this.recordID + '&file=' + id,
      regname: this.props.table,
    })
      .then(() => {
        this.processedFiles++;
        this.checkProcessedFiles();
      })
      .catch((error) => {
        logError(error);
      });
  }

  downloadFile = async (fileToDownload) => {
    switch (Platform.OS) {
      default:
        //download web file
        this.getFile(fileToDownload)
          .then((file) => {
            let blob = b64toBlob(file, 'application/octet-stream');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileToDownload.Comment;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            logError(error);
          });
    }
  };

  selectFile = (fileToSelect, hasPreview) => {
    let self = this;

    if (!hasPreview) {
      self.downloadFile(fileToSelect);
      return;
    }

    this.setState({loading: true});
    this.getFile(fileToSelect)
      .then((file) => {
        self.setState({
          loading: false,
          showPreview: true,
          activeFile: {base64: file, Comment: fileToSelect.Comment, ID: fileToSelect.ID},
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  findDimensions(layout) {
    // TODO: Create x y width height Type
    const {width} = layout;
    this.state.width = width;
  }

  handleUploadFile(files) {
    const self = this;
    if (this.props.viewMode === ViewMode.Edit) {
      self.addFileToUploadQueue(files);
      self.addFilesVisually(files);
      global.activeEditMode = true;
    }
  }

  addFileToUploadQueue(files) {
    const self = this;
    const filesToUploadArr = this.state.filesToUpload;
    filesToUploadArr.push(files);
    self.setState({filesToUpload: filesToUploadArr});
  }

  uploadFile = (files) => {
    let self = this;
    this.setState({uploading: true});
    if (files.length > 0) {
      let file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        Requests.doAction('doupload', {
          id: self.recordID,
          regname: self.props.table,
          base64: reader.result.split2(',').pop(),
          filename: file.name,
        })
          .then(() => {
            self.setState({uploading: false});
            self.loadAttachments();
            this.processedFiles++;
            this.checkProcessedFiles();
          })
          .catch((error) => {
            logError(error);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  hidePreview = () => {
    this.setState({showPreview: false});
  };

  removeFileVisually(file) {
    const self = this;
    let attachmentListArr = this.state.attachmentList;
    attachmentListArr = attachmentListArr.filter((fileToCompare) => fileToCompare !== file);
    self.setState({attachmentList: attachmentListArr});
  }

  addFilesVisually(files) {
    const self = this;
    if (files.length > 0) {
      const file = files[0];
      const fileToPush = {
        ID: file.lastModified,
        VcName: 'Attach2Vc',
        Comment: file.name,
        Users: '',
        Size: file.Size,
      };
      const attachmentListArr = [...this.state.attachmentList];
      attachmentListArr.push(fileToPush);
      self.setState({attachmentList: attachmentListArr});
    }
  }

  render() {
    let self = this;
    if (this.state.visible) {
      return (
        <AttachmentBackground>
          <SafeView style={{width: '100%'}}>
            {(IsHRWeb() || self.state.showPreview === false) && (
              <>
                <AttachmentHeader>
                  {self.state.uploading && (
                    <>
                      <AttachmentTitle>{translate('Attachments')}</AttachmentTitle>
                      {this.props.viewMode === ViewMode.Edit && (
                        <FileUploader handleChange={(files) => self.handleUploadFile(files)} isLoading={true} />
                      )}
                    </>
                  )}
                  {!self.state.uploading && (
                    <>
                      {this.props.showTitle !== false && <AttachmentTitle>{translate('Attachments')}</AttachmentTitle>}
                      {this.props.viewMode === ViewMode.Edit && (
                        <FileUploader
                          minSize={this.minFileSizeInMB}
                          maxSize={this.maxFileSizeInMB}
                          handleChange={(files) => self.handleUploadFile(files)}
                          isLoading={false}
                        />
                      )}
                    </>
                  )}
                </AttachmentHeader>
                <AttachmentList
                  onLayout={(event) => {
                    this.findDimensions(event.nativeEvent.layout);
                  }}>
                  {this.state.attachmentList.map((file) => (
                    <Attachment
                      attachmentName={file.Comment}
                      onFilePress={(hasPreview) => self.selectFile(file, hasPreview)}
                      onCrossPress={() => self.handleDeleteFile(file)}
                      onDownloadPress={() => self.downloadFile(file)}
                      canBeDeleted={this.props.viewMode === ViewMode.Edit}
                      wideView={this.props.wideView}
                      viewMode={this.props.viewMode}
                    />
                  ))}
                  {this.state.recordLinkList.map((recordLink) => (
                    <RecordLink
                      name={recordLink.displayName}
                      canBeOpened={recordLink.canBeOpened}
                      onNamePress={() => DoNavigate(this.props.window, recordLink.moduleName, true, recordLink.ID)}
                      wideView={this.props.wideView}
                      viewMode={this.props.viewMode}
                    />
                  ))}
                  {this.state.notSupportedFile.map((notSupportedFile) => (
                    <NotSupportedFile
                      name={notSupportedFile.Comment}
                      wideView={this.props.wideView}
                      viewMode={this.props.viewMode}
                      vcName={notSupportedFile.VcName}
                    />
                  ))}
                </AttachmentList>
                {this.state.showPreview && (
                  <FilePreview
                    fileName={this.state.activeFile.Comment}
                    title={this.state.activeFile.Comment}
                    base64File={this.state.activeFile.base64}
                    onPressPreviewClose={this.hidePreview}
                    allowDownload={true}
                  />
                )}
              </>
            )}
          </SafeView>
        </AttachmentBackground>
      );
    } else {
      return <></>;
    }
  }
}
