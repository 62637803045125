import React from 'react';
import SalesOrders_old from 'src/components/screens/SalesOrders';
import {DoNavigate, getDefaultBackBehaviour, goBack, IsHRWeb, Supported, WindowWrap} from '@tools/displayTools';
import {InspectWindow} from '@src/components/editElements/inspectWindow/InspectWindow';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '@tools/windowTools';
import {LangColorContext} from 'src/App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import * as myDate from '@tools/dateTools';
import {logError} from '@utils/debug';
import {WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import Requests from 'src/api/Requests';
import {RecordList} from '@components/RecordList/RecordList';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {SalesOrdersRecordListDefinition} from './salesOrdersRecordListDefinition';
import {SalesOrdersRecordScreenDefinition} from './salesOrdersRecordScreenDefinition';

const Drawer = createDrawerNavigator();

interface SalesOrderListState extends ListScreenState {
  px: number;
  py: number;
}

export class SalesOrdersList extends ListScreen<{}, SalesOrderListState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinition = new SalesOrdersRecordListDefinition(this);
    this.ScreenName = 'SalesOrders';
    this.table = 'ORVc';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
      px: 0,
      py: 0,
    } as SalesOrderListState;
    SetCurrentWindow2('SalesOrders', 'SerNr', 'descending');
    AddDateFilter('SalesOrders', myDate.addMonth(myDate.getCurrentDate(), -2), myDate.getCurrentDate());
  }

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          const browseColumns = self.recordWindowDefinition.getBrowseColumns(this);
          return (
            <WindowWrap>
              <RecordList
                table={this.table}
                windowName={'SalesOrders'}
                update={this.state.update}
                data={this.state.data}
                hasDateFilter={true}
                filters={[
                  {name: translate('Confirmed'), id: 'isok'},
                  {name: translate('NotConfirmed'), id: 'notok'},
                  {name: translate('StatusOpen'), id: 'open'},
                  {name: translate('StatusToDeliver'), id: 'todeliver'},
                  {name: translate('StatusToInvoice'), id: 'toinvoice'},
                  {name: translate('Done'), id: 'done'},
                  {name: translate('Closed'), id: 'closed'},
                ]}
                browseColumns={browseColumns}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                addNewTitle={translate('AddNewSalesOrder')}
                navigation={this.props.navigation}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface SalesOrderRecordState extends RecordScreenState {
  activeRow: number;
}

export class SalesOrdersRecord extends RecordScreen<{}, SalesOrderRecordState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new SalesOrdersRecordScreenDefinition();
    this.createSigningActivity = this.createSigningActivity.bind(this);
    this.createInvoice = this.createInvoice.bind(this);

    this.ScreenName = 'SalesOrders';
    this.table = 'ORVc';
    this.state = {
      ...this.state,
    };
    this.record = this.createNewRecord();
    this.useActiveEditFields = true;
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  allowsEditAndDelete() {
    return this.record.headerFields.OKFlag !== '1' && this.record.headerFields.Closed !== '1';
  }

  isNewRecordFieldFilled = () => this.record && this.record.headerFields[this.newRecordFieldKey] !== '';

  isNewRecord = () => this.id === 'new';

  createNewRecord = () => {
    return new WindowRecord(
      this.table,
      true,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true,
      this.recordWindowDefinitions.getSideSectionFields(this),
      this.recordWindowDefinitions.getHiddenFields()
    );
  };

  createSigningActivity() {
    Requests.doAction('createsigningactivity', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        DoNavigate(this, 'Tasks', true, response.newid);
      })
      .catch((error) => {
        logError(error);
      });
  }

  createInvoice() {
    Requests.doAction('createslinvoicefromorder', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        if (response.newid !== '') {
          global.reloadAttachmentList();
          DoNavigate(this, 'Invoices', true, response.newid);
        }
      })
      .catch((error) => {
        logError(error);
      });
  }

  render() {
    const self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                key={'salesorder' + this.id}
                title={translate('SalesOrder')}
                window={this}
                headerFields={this.recordWindowDefinitions.getHeaderFields(this)}
                sideSectionFields={this.recordWindowDefinitions.getSideSectionFields(this)}
                forceEditMode={this.isNewRecord()}
                hideActionPane={this.isNewRecord() && this.recordFieldFilled('CustCode') === false}
                record={this.record}
                okcheck={'OKFlag'}
                allowDelete={this.allowsEditAndDelete()}
                allowPrint={IsHRWeb()}
                width={'840px'}
                hasBackButton={true}
                backButton={() => goBack(self, self.ScreenName)}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function SalesOrders() {
  if (Supported('salesorders_v2')) {
    return (
      <Drawer.Navigator
        // @ts-ignore
        style={{background: 'white'}}
        backBehavior={getDefaultBackBehaviour()}
        drawerStyle={{display: 'none'}}>
        <Drawer.Screen
          name="SalesOrdersList"
          component={SalesOrdersList}
          options={{
            title: translate('SalesOrders'),
          }}
        />
        <Drawer.Screen
          name="SalesOrdersRecord"
          component={SalesOrdersRecord}
          // @ts-ignore
          backBehavior={getDefaultBackBehaviour()}
          options={{
            title: translate('SalesOrder'),
          }}
          // @ts-ignore
          getId={({params}) => params.id}
        />
      </Drawer.Navigator>
    );
  } else {
    return SalesOrders_old();
  }
}

export default SalesOrders;
