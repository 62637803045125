import React, {useState} from 'react';
import styled from 'styled-components/native';
import {LangColorContext} from '../../../App';
import {translate} from '@utils/languageTools';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import {colours, fontSize, fontWeight, lineHeight, spacing} from '@styles/Style';
import {Header6, RowContainer} from '@styles/primitiveComponents';
import {ActionButton} from '@components/button/ActionButton';
import {checkUserLeavingEditMode} from '@components/messageBox/Tools';

const LanguageWrap = styled(RowContainer)`
  align-items: center;
  margin-left: auto;
  padding: 0;
`;

const LanguageAbbreviationSeperator = styled(Header6)`
  color: ${colours[GetGlobalTheme()].grey800};
`;

const LanguageAbbreviationButton = {
  background: 'none',
  padding: spacing.space4,
};

const languages = [
  {code: 'lv', name: translate('latvian')},
  {code: 'en', name: translate('english')},
  {code: 'ee', name: translate('estonian')},
];

const LanguageSelect = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(global.language);

  function handleLanguageSelect(language: string) {
    const action = () => {
      setCurrentLanguage(language);
      global.ToggleLanguage(language);
    };

    checkUserLeavingEditMode(action);
  }

  const baseAbbreviation = {
    fontSize: fontSize.h6,
    lineHeight: lineHeight.h6,
    fontWeight: fontWeight.h6,
    margin: 0,
  };

  const ESTAbbreviation = {
    ...baseAbbreviation,
    color: currentLanguage === 'ee' ? colours[GetGlobalTheme()].lightBlue : colours[GetGlobalTheme()].grey800,
  };

  const ENGAbbreviation = {
    ...baseAbbreviation,
    color: currentLanguage === 'en' ? colours[GetGlobalTheme()].lightBlue : colours[GetGlobalTheme()].grey800,
  };

  const LATAbbreviation = {
    ...baseAbbreviation,
    color: currentLanguage === 'lv' ? colours[GetGlobalTheme()].lightBlue : colours[GetGlobalTheme()].grey800,
  };

  return (
    <LangColorContext.Consumer>
      {() => (
        <LanguageWrap style={props.style}>
          <ActionButton
            onPress={() => handleLanguageSelect('ee')}
            title={'EST'}
            textStyle={ESTAbbreviation}
            style={LanguageAbbreviationButton}
          />
          <LanguageAbbreviationSeperator> / </LanguageAbbreviationSeperator>
          <ActionButton
            onPress={() => handleLanguageSelect('en')}
            title={'ENG'}
            textStyle={ENGAbbreviation}
            style={LanguageAbbreviationButton}
          />
          <LanguageAbbreviationSeperator> / </LanguageAbbreviationSeperator>
          <ActionButton
            onPress={() => handleLanguageSelect('lv')}
            title={'LAT'}
            textStyle={LATAbbreviation}
            style={LanguageAbbreviationButton}
          />
        </LanguageWrap>
      )}
    </LangColorContext.Consumer>
  );
};

export default LanguageSelect;
