import {translate} from '@utils/languageTools';

export enum StaticPasteSpecial {
  ContactType = 'ContactType',
  VatZone = 'VatZone',
  ReceivingPreference = 'ReceivingPreference',
  AlternativeChannel = 'AlternativeChannel',
}
export function InitializeStaticPasteSpecialDefinitions() {
  setContactType();
  setVatZone();
  setReceivingPreference();
  setAlternativeChannel();
}

function setContactType() {
  global.pasteSpecials[StaticPasteSpecial.ContactType] = [
    {Code: '0', Comment: translate('Company')},
    {Code: '1', Comment: translate('Person')},
  ];
}

function setVatZone() {
  global.pasteSpecials[StaticPasteSpecial.VatZone] = [
    {Code: '0', Comment: translate('Domestic')},
    {Code: '1', Comment: translate('InsideEU')},
    {Code: '2', Comment: translate('InsideEUPostVat')},
    {Code: '3', Comment: translate('OutsideEU')},
    {Code: '4', Comment: translate('OutsideEUPostVat')},
  ];
}

function setReceivingPreference() {
  if (global.userData.provider === 0) {
    global.pasteSpecials[StaticPasteSpecial.ReceivingPreference] = [
      {Code: '0', Comment: translate('Default')},
      {Code: '1', Comment: translate('EInvoice')},
      {Code: '2', Comment: translate('Email')},
      {Code: '3', Comment: translate('PaperInvoice')},
      {Code: '4', Comment: translate('NoReceiving')},
      {Code: '5', Comment: translate('Telema')},
      {Code: '6', Comment: translate('Edisoft')},
      {Code: '7', Comment: translate('APE')},
    ];
    return;
  }

  global.pasteSpecials[StaticPasteSpecial.ReceivingPreference] = [
    {Code: '0', Comment: translate('Default')},
    {Code: '1', Comment: translate('EInvoice')},
    {Code: '2', Comment: translate('InternetBank')},
    {Code: '3', Comment: translate('PreferenceFromPortal')},
    {Code: '4', Comment: translate('Email')},
    {Code: '5', Comment: translate('PaperInvoice')},
    {Code: '6', Comment: translate('NoReceiving')},
    {Code: '7', Comment: translate('Telema')},
    {Code: '8', Comment: translate('Unifiedpost')},
    {Code: '9', Comment: translate('Docura')},
  ];
}

function setAlternativeChannel() {
  if (global.userData.provider === 0) {
    global.pasteSpecials[StaticPasteSpecial.AlternativeChannel] = [
      {Code: '0', Comment: translate('NotUsed')},
      {Code: '1', Comment: translate('EInvoice')},
      {Code: '2', Comment: translate('PaperInvoice')},
      {Code: '3', Comment: translate('Email')},
      {Code: '2', Comment: translate('InternetBank')},
    ];
    return;
  }

  global.pasteSpecials[StaticPasteSpecial.AlternativeChannel] = [
    {Code: '0', Comment: translate('NotUsed')},
    {Code: '1', Comment: translate('EInvoice')},
    {Code: '2', Comment: translate('InternetBank')},
    {Code: '3', Comment: translate('Email')},
    {Code: '4', Comment: translate('PaperInvoice')},
  ];
}
