import React, {Component} from 'react';
import Requests from '../../api/Requests';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {
  ConvertDisplayValue,
  GetGlobalTheme,
  GetPasteSpecialValue,
  IsHRAndroid,
  IsHRiOS,
  isHRiOSWeb,
  IsHRWeb,
  SetPasteSpecialValue,
  WindowElementType,
} from '../../Tools/DisplayTools';
import InputSelect from './InputSelect';
import PasteCurDate from './Calendar';
import {DefaultIconButton} from '../button/DefaultIconButton';
import * as myDate from '../../Tools/DateTools';
import KeyEvent from 'react-native-keyevent';
import {DateTimePickerEXC as DateTimePicker} from '../../Tools/Stubs';
import {logError} from '@utils/debug';
import {ExternalPasteSpecial, InlinePasteSpecial, InputChangeEventProps} from '@utils/types';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const InputWrapper = styled.View`
  padding-vertical: ${spacing.small300}px;
  padding-horizontal: ${spacing.small300}px;
  ${(props) =>
    (props.row || props.singleline) &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}
`;

const InputLabel = styled.View`
  justify-content: center;
  margin-vertical: ${spacing.small200}px;
  ${(props) =>
    props.singleline &&
    css`
      width: 150px;
    `}
`;

const ClearButtonWrap = styled.View`
  position: absolute;
  right: 7px;
  top: 9px;
  ${IsHRWeb() === false &&
  css`
    top: 3px;
  `}
`;

const KeyboardButtonWrap = styled.View`
  position: absolute;
  right: 50px;
  top: 9px;
  ${IsHRWeb() === false &&
  css`
    top: 3px;
  `}
`;

const InputLabelText = styled.Text`
  color: ${colours[GetGlobalTheme()].inputLabelText};
`;

const PasteSpecialNameWrap = styled.Text`
  color: ${colours[GetGlobalTheme()].inputLabelText};
  position: ${(props) => (props.position ? props.position : 'absolute')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : fontSize.small100)}px;

  ${IsHRWeb() &&
  css`
    text-overflow: ellipsis;
  `}
  overflow: hidden;
  width: 100%;
  ${IsHRWeb() &&
  css`
    white-space: nowrap;
  `}
`;

const InputContainer = styled.TouchableOpacity`
  width: ${(props) => (props.row ? '50%' : '100%')};
`;

const InputField = styled.TextInput`
  font-family: 'Open Sans';
  border-radius: 4px;
  border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
  width: 100%;
  background-color: ${colours[GetGlobalTheme()].inputBackground};
  padding-vertical: ${spacing.small200}px;
  color: ${colours[GetGlobalTheme()].inputText};
  ${isDesktopScreen() &&
  css`
    ${(props) => (props.style && props.style.height ? 'height: ' + props.style.height : 'height: 35px;')}

    cursor: ${(props) => (props.editable ? 'auto' : 'default')};
    padding-horizontal: ${(props) => (props.editable ? spacing.small300 : 0)};
  `}
  ${isMobileOrTabletScreen() &&
  css`
    ${(props) => (props.style && props.style.height ? 'height: ' + props.style.height + 'px' : 'height: 45px;')}
    font-size: 18px;
  `}

  border-width: 0;
  ${(props) =>
    props.editable &&
    css`
      border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
    `};
`;

function ShowPasteSpecial(props) {
  let res = [];
  res.push(
    <InputSelect
      key={props.name}
      pasteSpecial={props.pasteSpecial}
      name={props.name}
      title={props.title}
      value={props.value}
      style={props.style}
      editable={props.editable}
      onChange={({rowindex, fieldname, value}: InputChangeEventProps) => {
        if (props.onChange !== undefined) {
          props.onChange({
            rowindex: rowindex,
            fieldname: fieldname,
            value: value,
          });
        }
      }}
    />
  );
  return res;
}

export class PasteSpecialName extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      name: [],
    };
    if (
      this.props.pasteSpecial.hideComment === undefined &&
      this.props.value !== '' &&
      this.props.value !== undefined
    ) {
      let self = this;
      let fields = [];
      for (let i = 1; i < this.props.pasteSpecial.columns.length; i++) {
        fields.push(this.props.pasteSpecial.columns[i].key.toLowerCase());
      }
      this.props.value.split2(',').map((single_value) => {
        let psvalue = GetPasteSpecialValue(this.props.pasteSpecial.vcName, single_value);
        if (psvalue === undefined) {
          let filter = {};
          filter[this.props.pasteSpecial.columns[0].mainKey] = single_value;
          Requests.getTableData(this.props.pasteSpecial.vcName, filter, fields.join(','))
            .then((response) => {
              let arr = self.state.name;
              let val = [];
              let record = {};
              if (response.records.length > 0) {
                record = response.records[0];
                Object.keys(response.records[0]).forEach(function (key) {
                  let obj = response.records[0][key];
                  if (typeof obj !== 'object') {
                    val.push(response.records[0][key]);
                  }
                });
              }
              arr.push(val.join(' '));
              self.setState({name: arr});
              if (Object.keys(record).length > 0) {
                SetPasteSpecialValue(self.props.pasteSpecial.vcName, single_value, record);
              }
            })
            .catch((error) => {
              logError(error);
            });
        } else {
          let val = [];
          Object.keys(psvalue).forEach(function (key) {
            let lowerKey = key.toLowerCase();
            if (fields.indexOf(lowerKey) > -1) {
              let obj = psvalue[key];
              if (typeof obj !== 'object') {
                val.push(psvalue[key]);
              }
            }
          });
          this.state.name.push(val);
        }
      });
    }
  }

  render() {
    let style = {
      top: 37,
      left: 9,
    };

    if (this.props.editable === false) {
      style.left = 0;
    }

    if (isMobileOrTabletScreen()) {
      style.top = 44;
    }
    if (this.props.pasteSpecial.hideComment) {
      return <></>;
    } else {
      return (
        <PasteSpecialNameWrap style={{...style, ...this.props.style}}>
          {this.state.name.join(', ')}
        </PasteSpecialNameWrap>
      );
    }
  }
}

function isInlinePasteSpecial(ps: InlinePasteSpecial | ExternalPasteSpecial): ps is InlinePasteSpecial {
  return !!ps && 'fields' in ps;
}

function isExternalPasteSpecial(ps: InlinePasteSpecial | ExternalPasteSpecial): ps is ExternalPasteSpecial {
  return !!ps && 'columns' in ps;
}

type EditFieldProps = {
  title: string;
  name: string;
  value: string | number;
  onChange: (inputChangeEventProps: InputChangeEventProps) => void;
};

class EditField extends Component<EditFieldProps, {}> {
  isExternalPasteSpecial: boolean;
  isInlinePasteSpecial: boolean;
  usePasteSpecial: boolean;

  constructor(props) {
    super(props);
    this.props = props;
    this.isExternalPasteSpecial = false;
    this.isInlinePasteSpecial = false;
    this.usePasteSpecial = props.pasteSpecial !== undefined;
    if (this.usePasteSpecial) {
      this.isExternalPasteSpecial = isExternalPasteSpecial(this.props.pasteSpecial);
      this.isInlinePasteSpecial = isInlinePasteSpecial(this.props.pasteSpecial);
    }
    this.state = {
      showCalendar: false,
      px: 0,
      py: 0,
      showBarcodeScanner: false,
      value: this.props.value,
      keyboard: true,
    };
    if (this.props.type === WindowElementType.kInputTypeBarCode && isMobileOrTabletScreen()) {
      this.state.keyboard = false;
    }
  }

  OpenCalendar = () => {
    if (this.props.type === WindowElementType.kInputTypeDate && this.props.editable) {
      if (this.state.showCalendar === false) {
        this.setState({showCalendar: true});
      }
    }
  };

  CloseCalendar = () => {
    if (this.props.type === WindowElementType.kInputTypeDate) {
      if (this.state.showCalendar === true) {
        this.setState({showCalendar: false});
      }
    }
  };
  onBarcodeRead = (barcode) => {
    if (barcode !== undefined && barcode !== '') {
      this.props.onChange({
        rowindex: -1,
        fieldname: this.props.name,
        value: barcode,
      });
    }
  };

  OpenBarcodeReader = () => {
    /*
            const ScannerModule = NativeModules.ScannerModule;
            let barcodeTypes = [
              'QR_CODE',
              'DATA_MATRIX',
              'UPC_A',
              'UPC_E',
              'EAN_8',
              'EAN_13',
              'RSS_14',
              'CODE_39',
              'CODE_93',
              'CODE_128',
              'ITF',
              'RSS_EXPANDED',
              'QR_CODE',
              'DATA_MATRIX',
              'PDF_417',
            ];
            ScannerModule.openScanner(true, null, this.onBarcodeRead);
            */
    let self = this;
    global.showBarcodeReader({
      show: true,
      onChange: (event, value) => {
        if (self.props.onChange !== undefined) {
          self.props.onChange({
            rowindex: -1,
            fieldname: self.props.name,
            value: value,
          });
        }
        global.showBarcodeReader({show: false});
      },
    });
  };
  OpenKeyboard = () => {
    let self = this;
    this.setState({keyboard: this.state.keyboard ? false : true}, function () {
      self.props.reference.current.focus();
    });
  };

  SetNumValue = (diff) => {
    let nval = parseInt(this.state.value);
    nval = nval + diff;
    this.props.onChange({
      rowindex: -1,
      fieldname: this.props.name,
      value: nval,
    });
  };

  render() {
    let self = this;
    let keys = {
      TestId: this.props.key,
      key: this.props.value,
      ref: this.props.reference,
      name: this.props.name,
      placeholder: this.props.placeholder,
      placeholderTextColor: this.props.placeholdercolor,
      secureTextEntry: this.props.secure,
      autoCompleteType: this.props.autoCompleteType,
      style: this.props.inputStyle,
      viewOnly: this.props.editable,
      row: this.props.row,
      editable: this.props.editable === undefined || this.props.editable === true,
    };
    if (!keys.style) {
      keys.style = {};
    }
    keys.onChangeText = (text) => {
      this.state.value = text;
      if (this.props.onChangeText) {
        this.props.onChangeText(text);
      }
    };
    if (this.props.onKeyPress !== undefined) {
      keys.onKeyPress = (e) => this.props.onKeyPress(e);
    }
    if (this.props.onSubmitEditing !== undefined) {
      keys.onSubmitEditing = () => this.props.onSubmitEditing();
    }
    if (this.props.onFocus !== undefined) {
      keys.onFocus = () => this.props.onFocus();
    }

    keys.onBlur = () => {
      if (this.props.onChange !== undefined && this.props.value !== this.state.value) {
        let value = this.state.value;
        if (this.props.type === WindowElementType.kInputTypeDate) {
          value = myDate.convertDateStringToISODate(value);
        }
        if (self.props.matrixfield) {
          self.props.onChange({
            rowindex: 0,
            fieldname: self.props.name,
            value: value,
          });
        } else {
          self.props.onChange({
            rowindex: -1,
            fieldname: self.props.name,
            value: value,
          });
        }
      }
    };
    if (IsHRAndroid() || IsHRiOS()) {
      keys.onFocus = (e) => {
        self.OpenCalendar(e);
      };
    }

    if (this.props.type === WindowElementType.kInputTypeBarCode && isMobileOrTabletScreen()) {
      KeyEvent.onKeyUpListener((keyEvent) => {
        if (keyEvent.keyCode === 25) {
          self.OpenBarcodeReader();
        }
      });

      //keys.showSoftInputOnFocus = this.state.keyboard;
    }
    if (this.props.type === WindowElementType.kInputTypeQty) {
      keys.style.textAlign = 'center';
    }
    if (this.props.type === WindowElementType.kInputTypeLongText) {
      //keys.style.textAlignVertical = 'top';
      keys.multiline = true;
      //keys.numberOfLines = 5;
    }
    if (this.props.type === WindowElementType.kInputTypeNumber) {
      keys.keyboardType = 'number-pad';
    }
    if (this.props.type === WindowElementType.kInputTypeNumber2) {
      if (IsHRiOS() || isHRiOSWeb()) {
        keys.keyboardType = 'numbers-and-punctuation';
      } else {
        keys.keyboardType = 'number-pad';
      }
    }

    if (this.props.type === WindowElementType.kInputTypeDate) {
      /*
                  keys.value = ConvertDisplayValue(
                    DataTypes.kInputTypeDate,
                    this.props.value,
                  );
                  */
      keys.defaultValue = ConvertDisplayValue(WindowElementType.kInputTypeDate, this.props.value);
    } else {
      keys.defaultValue = this.props.value;
    }
    if (this.props.type === WindowElementType.kInputTypeLongText) {
      if (this.props.value !== undefined) {
        let res = this.props.value;
        while (res.slice(-5) === '&#13;') {
          res = res.slice(0, res.length - 5);
        }
        res = res.replace(/&#10;/g, String.fromCharCode(10));
        keys.defaultValue = res.replace(/&#13;/g, String.fromCharCode(13));
      }
    }
    if (this.isExternalPasteSpecial) {
      if (isMobileOrTabletScreen()) {
        keys.style.paddingRight = 40;
      } else {
        keys.style.paddingRight = 30;
      }
    }

    let w = self.props.width;
    if (w) {
      if (w.indexOf('px') > 0) {
        w = parseInt(w);
      }
      if (isMobileOrTabletScreen() && self.props.setMobileWidth !== true) {
        w = '100%';
      }
    }
    let linkeddateval = '';
    if (this.props.linkedfield) {
      linkeddateval = myDate.extractDateFromString(this.props.record[this.props.linkedfield]);
    } else {
      linkeddateval = myDate.getCurrentDate();
    }
    this.state.value = this.props.value;
    return (
      <InputWrapper
        style={{...this.props.wrapperStyle, ...{width: w}}}
        key={this.props.value}
        singleline={this.props.singleline}
        row={this.props.row}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.state.py = layout.top + 17;
          self.state.px = layout.left + 7;
        }}>
        {this.props.title !== undefined && (
          <InputLabel row={this.props.row} singleline={this.props.singleline}>
            <InputLabelText>{this.props.title}</InputLabelText>
          </InputLabel>
        )}
        {this.props.type !== WindowElementType.kInputTypeStaticText && (
          <InputContainer row={self.props.row} onPress={(e) => self.OpenCalendar(e)}>
            {(this.usePasteSpecial === false || this.isExternalPasteSpecial) && <InputField {...keys} />}
            {this.isInlinePasteSpecial && ShowPasteSpecial(self.props)}
            {this.props.type === WindowElementType.kInputTypeDate &&
              this.props.allowClearing === true &&
              this.props.value !== '' &&
              this.props.value !== undefined && (
                <ClearButtonWrap>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                    }}
                    iconName={'times-circle'}
                    mode="text"
                    size={isDesktopScreen() ? 17 : 28}
                    iconType="FA5"
                    action={() =>
                      self.props.onChange({
                        rowindex: -1,
                        fieldname: self.props.name,
                        value: '',
                      })
                    }
                  />
                </ClearButtonWrap>
              )}
            {this.props.type === WindowElementType.kInputTypeBarCode && isMobileOrTabletScreen() && (
              <>
                {false && (
                  <KeyboardButtonWrap>
                    <DefaultIconButton
                      style={{
                        height: 'auto',
                        width: 'auto',
                        padding: 0,
                        margin: 0,
                        borderRadius: 0,
                      }}
                      iconName={'keyboard'}
                      mode="text"
                      size={isDesktopScreen() ? 17 : 36}
                      iconType="FA5"
                      action={() => self.OpenKeyboard()}
                    />
                  </KeyboardButtonWrap>
                )}
                <ClearButtonWrap>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                    }}
                    iconName={'qrcode'}
                    mode="text"
                    size={isDesktopScreen() ? 17 : 36}
                    iconType="FA5"
                    action={() => self.OpenBarcodeReader()}
                  />
                </ClearButtonWrap>
              </>
            )}
            {this.props.icon !== undefined && (
              <ClearButtonWrap style={{top: IsHRWeb() ? 9 : 10}}>
                <DefaultIconButton
                  style={{
                    height: 'auto',
                    width: 'auto',
                    padding: 0,
                    margin: 0,
                    borderRadius: 0,
                  }}
                  iconName={this.props.icon}
                  mode="text"
                  size={isDesktopScreen() ? 17 : 26}
                  iconType="FA5"
                  action={this.props.iconClick}
                />
              </ClearButtonWrap>
            )}
            {this.isExternalPasteSpecial && keys.editable && (
              <ClearButtonWrap style={{top: IsHRWeb() ? 9 : 10}}>
                <DefaultIconButton
                  style={{
                    height: 'auto',
                    width: 'auto',
                    padding: 0,
                    margin: 0,
                    borderRadius: 0,
                  }}
                  iconName={'search'}
                  mode="text"
                  size={isDesktopScreen() ? 17 : 26}
                  iconType="FA5"
                  action={() => {
                    global.setPasteSpecialInfo({
                      show: true,
                      table: this.props.pasteSpecial.vcName,
                      onChange: this.props.onChange,
                      psheader: this.props.pasteSpecial.columns,
                      psnewrec: this.props.pasteSpecial.psnewrec,
                      pssort: this.props.pasteSpecial.pssort,
                      psfields: this.props.pasteSpecial.psfields,
                      psmulti: this.props.pasteSpecial.multiValue,
                      psextraParams: this.props.pasteSpecial.extraParams,
                      name: this.props.name,
                      value: this.props.value,
                      row: -1,
                    });
                  }}
                />
              </ClearButtonWrap>
            )}
            {this.isExternalPasteSpecial && <PasteSpecialName {...this.props}></PasteSpecialName>}
            {this.props.type === WindowElementType.kInputTypeQty && isMobileOrTabletScreen() && (
              <>
                <ClearButtonWrap style={{left: 9, right: 'auto'}}>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                    }}
                    iconName={'chevron-down'}
                    mode="text"
                    size={isDesktopScreen() ? 17 : 36}
                    iconType="FA5"
                    action={() => self.SetNumValue(-1)}
                  />
                </ClearButtonWrap>
                <ClearButtonWrap>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                    }}
                    iconName={'chevron-up'}
                    mode="text"
                    size={isDesktopScreen() ? 17 : 36}
                    iconType="FA5"
                    action={() => self.SetNumValue(1)}
                  />
                </ClearButtonWrap>
              </>
            )}
            {this.props.type === WindowElementType.kInputTypeDate && this.state.showCalendar === true && IsHRWeb() && (
              <PasteCurDate
                key={this.state.py + this.state.px}
                value={this.props.value}
                linkeddateval={linkeddateval}
                editfield={this}
                onChoose={(value) => {
                  if (self.props.onChange !== undefined) {
                    self.props.onChange({
                      rowindex: -1,
                      fieldname: self.props.name,
                      value: value,
                    });
                  }
                  self.setState({showCalendar: false});
                }}
              />
            )}
            {this.props.type === WindowElementType.kInputTypeDate &&
              this.state.showCalendar === true &&
              IsHRWeb() === false && (
                <DateTimePicker
                  mode="date"
                  value={
                    myDate.extractDateFromString(this.props.value) === null
                      ? linkeddateval
                      : myDate.extractDateFromString(this.props.value)
                  }
                  onChange={(event, value) => {
                    if (self.props.onChange !== undefined) {
                      self.props.onChange({
                        rowindex: -1,
                        fieldname: self.props.name,
                        value: myDate.constructDateString2(value),
                      });
                    }
                    self.setState({showCalendar: false});
                  }}
                />
              )}
          </InputContainer>
        )}
      </InputWrapper>
    );
  }
}

export default EditField;
