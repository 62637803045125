import React from 'react';
import {DataTable} from 'react-native-paper';
import {Text, View} from 'react-native';
import styled from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {objectHasProperty} from '../../Tools/ObjectTools';

const ReportLine = styled.View`
  margin: 4px 0px 4px 0px;
  min-height: 20px;
`;

const StyledCell = styled(DataTable.Cell)`
  width: ${(props) => props.columnWidth};
  padding-horizontal: ${spacing.medium};
  border-radius: 6px;
`;

const StyledRow = styled(DataTable.Row)`
  background-color: ${(props) => (props.rowIndex % 2 ? colours[GetGlobalTheme()].inputBackground : 'transparent')}
  margin-vertical: 0px;
  padding-horizontal: ${spacing.medium300 - spacing.medium};
  margin-horizontal: ${spacing.medium};
  padding-vertical: 0px;
  border-radius: 6px;
  border-bottom-width: 0px;
  font-size: ${fontSize.medium};
  margin-bottom: ${(props) => (!(props.arrayLength % 2) && props.rowIndex === props.arrayLength - 1 ? '16px' : '0px')}
`;

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].navigationBackground};
  padding-vertical: 0px;
  padding-horizontal: ${spacing.medium300};
  font-size: ${fontSize.medium};
  border-bottom-width: 0px;
`;

let deep_value = function (obj, path) {
  for (let i = 0, len = path.length; i < len; i++) {
    if (obj === undefined || obj[path[i]] === undefined) {
      return undefined;
    } else {
      obj = obj[path[i]];
    }
  }
  return obj;
};

function GetNextAccurance(rep, i, path, value) {
  let trep = rep[i];
  let res = false;
  let res_obj = deep_value(trep, path);
  if (res_obj !== undefined) {
    if (res_obj === value) {
      res = true;
    }
  }
  return res;
}
function EmptyLine() {
  return <ReportLine>{''}</ReportLine>;
}

function ReportTableRow(index, arr) {
  let res = [];

  for (let i in arr) {
    res.push(<StyledCell>{arr[i]}</StyledCell>);
  }
  return <StyledRow rowIndex={index}>{res}</StyledRow>;
}

function ReportTableHeader(arr) {
  let res = [];

  for (let i in arr) {
    res.push(<StyledCell>{arr[i]}</StyledCell>);
  }
  return <StyledHeader>{res}</StyledHeader>;
}

export function DisplayEPOffWorkCalcRn(reportdata) {
  let res = [];
  if (objectHasProperty(reportdata, 'report') && objectHasProperty(reportdata.report, 'table')) {
    let rep = reportdata.report.table;
    //get i=2 for employee info
    let emp = rep[2].tr[0].td;
    let empinfo = 'Darbinieks: ' + emp[0]._ + ' - ' + emp[1]._ + ' (' + emp[2]._ + ')';

    res.push(
      <ReportLine>
        <Text>{empinfo}</Text>
      </ReportLine>
    );

    let info = rep[3].tr[0].td;
    let infotext = 'Prombūtne: ' + info[1]._ + ' ' + info[5]._ + ' (' + info[4]._ + ')';
    let infotext2 = 'Periods: ' + info[2]._.split(' ')[1] + ':' + info[3]._.split(' ')[1];
    res.push(
      <ReportLine>
        <Text>{infotext}</Text>
      </ReportLine>
    );
    res.push(
      <ReportLine>
        <Text>{infotext2}</Text>
      </ReportLine>
    );
    res.push(EmptyLine());

    let i = 7;
    let rw = 0;
    let foundf = false;
    let rows = [];
    while (GetNextAccurance(rep, i, ['tr', 0, 'td', 1, 'hr', 0], '') === false) {
      //show calculations
      let calc = rep[i].tr[0].td;
      let calcnr = '';
      if (objectHasProperty(calc[2], '_')) {
        calcnr = calc[2]._;
      }
      //let infotext = 'Aprēķins: ' + calcnr + ' Periods: ' + calc[3]._ + ':'  + calc[4]._ + ' Summa: ' + calc[5]._ + ' (Dienas: ' + calc[6]._ + ')';
      //res.push(<View><Text>{infotext}</Text></View>);
      rows.push(ReportTableRow(rw, [calcnr, calc[3]._, calc[4]._, calc[5]._, calc[6] ? calc[6]._ : '']));
      i++;
      rw++;
      foundf = true;
    }

    i++; //goto totals
    let totals = rep[i].tr[0].td;

    if (foundf) {
      rows.push(ReportTableRow(rw, ['', '', 'Kopā:', totals[2]._, totals[3]._]));
      let header = ReportTableHeader(['Aprēķins', 'No', 'Līdz', 'Summa', 'Dienas']);
      let table = (
        <DataTable>
          {header}
          {rows}
        </DataTable>
      );
      res.push(table);
    } else {
      res.push(
        <View>
          <Text>{'Nav aprēķinu'}</Text>
        </View>
      );
    }
    res.push(EmptyLine());

    //let totalstext = 'Kopā Summa: ' + totals[2]._ + ' Dienas: '  + totals[3]._;
    //res.push(<View><Text>{totalstext}</Text></View>);
    i++;
    let avsal = rep[i].tr[0].td;
    let type = avsal[1]._;
    if (type === undefined) {
      type = 'Vidējā dienas likme: ';
    }
    let avsaltext = type + ': ' + avsal[2]._;
    res.push(
      <ReportLine>
        <Text>{avsaltext}</Text>
      </ReportLine>
    );
    while (i < rep.length) {
      i++; //goto calculations
      while (
        GetNextAccurance(rep, i, ['tr', 0, 'td', 0, '_'], 'Algu aprēķina periods : ') === false &&
        i < rep.length
      ) {
        i++;
      }
      res.push(EmptyLine());
      if (i < rep.length) {
        //show single calculation
        let totals = rep[i].tr[0].td;
        let totalstext = totals[0]._ + totals[1]._ + ':' + totals[2]._;
        res.push(
          <ReportLine>
            <Text>{totalstext}</Text>
          </ReportLine>
        );

        while (
          GetNextAccurance(rep, i, ['tr', 0, 'td', 1, '_'], 'Sadalījums pa operāciju veidiem') === false &&
          i < rep.length
        ) {
          i++;
        }
        i++;
        i++;
        i++;
        let skipf = false;
        while (
          GetNextAccurance(rep, i, ['tr', 0, 'td', 1, 'hr', 0], '') === false &&
          i < rep.length &&
          skipf === false
        ) {
          let trtype = rep[i].tr[0].td;
          if (trtype && trtype.length > 2) {
            let trtypestext = trtype[1]._ + ': ' + trtype[3]._ + ' - ' + trtype[4]._;
            res.push(
              <ReportLine>
                <Text>{trtypestext}</Text>
              </ReportLine>
            );
          } else {
            skipf = true;
          }
          i++;
        }
      }
    }
  }

  return res;
}
