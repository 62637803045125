/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import 'setimmediate';
import React, {Component, useEffect} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import styled from 'styled-components/native';
import {colours} from './styles/Style';
import {
  getAllData,
  GetGlobalTheme,
  GetUserTheme,
  IsHRAndroid,
  IsHRWeb,
  ScreenHeight,
  storeData,
} from './Tools/DisplayTools';
import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import Requests, {DecodePortalString} from './api/Requests';
import {
  CurrentLanguage,
  MapLanguage,
  MapSystemLanguage,
  setI18nConfig,
  SystemLanguageMatches,
  translate,
} from '@utils/languageTools';
import * as myDate from './Tools/DateTools';
import {SetWindowDefaults} from './Tools/WindowTools';
//import Cookie from 'react-native-cookie';
import {Loading} from './components/status/Loading';
import {ClosedUser} from './components/status/ClosedUser';
import ThemeManager from './Tools/Themes';
import DoubleTapToClose from './components/status/AndroidBackButton';
import {KeychainEXC as Keychain} from './Tools/Stubs';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {logError} from '@utils/debug';
import {objectHasProperty} from './Tools/ObjectTools';
import {DefineFonts} from './utils/typography';
import {APISupportsItem, getDrawers} from '@utils/drawersTools';
import SideNavigator from '@components/navigation/sideNavigator/SideNavigator';
import TopNavigator from '@components/navigation/TopNavigator';
import BackToTopButton from '@src/components/button/BackToTopButton';
import {MessageBox} from '@components/messageBox/MessageBox';
import {Login} from '@src/components/screens/login/LoginWindow';
import {ForgotPassword} from '@src/components/screens/login/ForgotPassword';
import {CreateAccount} from '@src/components/screens/login/CreateAccount';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {ResetPassword} from '@src/components/screens/login/ResetPassword';
import ToolTipGlobal from '@components/design/ToolTipGlobal';
import {CreatePassword} from '@src/components/screens/login/CreatePassword';
import {PasteSpecialDetailedView} from '@src/components/editElements/pasteSpecialDetailedView/PasteSpecialDetailedView';
import {isDesktopScreen} from '@tools/displayTools';
import {MobileBottomButton} from '@components/button/MobileBottomButton';
import {InitializeStaticPasteSpecialDefinitions} from '@components/editElements/pasteSpecial/StaticPasteSpecialDefinition';
import {DefineWebStyles} from '@src/tools/browserTools';
import {DefineStringProrotypes, InitGlobalValues} from '@src/utils/startup';

const Drawer = createDrawerNavigator();

if (!global.setImmediate) {
  global.setImmediate = setTimeout;
}

const kStartupUnknown = 0;
const kStartupLoggedIn = 1;
const kStartupNotLoggedIn = 2;
const kStartupClosed = 3;
const kStartupForgotPassword = 4;
const kStartupCreateAccount = 5;

DefineStringProrotypes();
DefineFonts();
DefineWebStyles();

const theme = {
  ...DefaultTheme,
  // Specify custom property
  myOwnProperty: true,
  // Specify custom property in nested object
  colors: {
    text: colours[GetGlobalTheme()].rowText,
    background: colours[GetGlobalTheme()].siteBackground,
  },
  fonts: {
    default: {
      regular: {
        fontFamily: 'Open Sans',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'Open Sans',
        fontWeight: '600',
      },
      light: {
        fontFamily: 'Open Sans',
        fontWeight: '300',
      },
      thin: {
        fontFamily: 'Open Sans',
        fontWeight: '200',
      },
    },
    regular: {
      fontFamily: 'Open Sans',
      fontWeight: '400',
    },
    medium: {
      fontFamily: 'Open Sans',
      fontWeight: '600',
    },
    light: {
      fontFamily: 'Open Sans',
      fontWeight: '300',
    },
    thin: {
      fontFamily: 'Open Sans',
      fontWeight: '200',
    },
  },
};

if (IsHRWeb()) {
  window.addEventListener('beforeunload', function (event) {
    if (global.activeEditMode) {
      event.preventDefault();
      event.returnValue = '';
    }
  });
}

const BaseContainerView = styled.View`
  font-family: 'Open Sans';
  margin: 0;
  min-height: 100vh;
  background-color: ${colours[GetGlobalTheme()].siteBackground};
`;
/*
  min-height: 100vh;
*/
const HorizontalContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
`;

const OverlayComponents = styled.View`
  z-index: 999;
`;

function BaseContainer(props) {
  useEffect(() => {
    if (typeof global.setInitialNavigationData === 'function') {
      global.setInitialNavigationData();
    }
  }, []);

  if (IsHRWeb()) {
    return <BaseContainerView>{props.children}</BaseContainerView>;
  } else {
    return props.children;
  }
}

export function RequestEmpData(loginf = false, errorCallback = null) {
  Requests.getEmployeeData()
    .then((response) => {
      let skipf = false;
      switch (response.result.loginstatus) {
        case kStartupLoggedIn:
          if (global.setUserDetails && IsHRAndroid()) {
            Keychain.setGenericPassword(global.setUserDetails.username, global.setUserDetails.password);
            global.setUserDetails = null;
          }
          global.userData = response.result;
          global.userData.supported = global.userData.supported.split(',');
          global.PerPage = Math.floor((ScreenHeight() - 220) / 50);
          global.PerPage2 = Math.floor((ScreenHeight() - 330) / 60);
          if (IsHRWeb() === false) {
            global.PerPage = Math.floor((ScreenHeight() - 220) / 47);
          }
          global.userData.apiversion = parseFloat(global.userData.apiversion);
          LoadHolidayInformation();
          LoadPasteSpecials();
          LoadWindowDefs();
          break;
        case kStartupClosed:
          global.updateLoginStatus(response.result.loginstatus);
          break;
        case kStartupForgotPassword:
          global.updateLoginStatus(response.result.loginstatus);
          break;
        case kStartupCreateAccount:
          global.updateLoginStatus(response.result.loginstatus);
          break;
        default:
          if (global.testLoginCredentials === false && IsHRWeb() === false) {
            skipf = true;
            Keychain.getGenericPassword().then((credentials) => {
              if (credentials.username !== undefined && credentials.username !== '') {
                global.testLoginCredentials = true;
                Requests.TestLogin(credentials.username, credentials.password)
                  .then(() => {
                    global.refreshScreen(true);
                  })
                  .catch(() => {});
              } else {
                global.updateLoginStatus(response.result.loginstatus);
              }
            });
          }
          if (global.testLoginCredentials === false) {
            if (loginf && response.result.loginstatus === kStartupNotLoggedIn) {
              errorCallback(translate('LoginFailed'));
              if (IsHRWeb()) {
                removeCookie();
              }
            }
          }
          break;
      }
      if (skipf === false && response.result.loginstatus !== kStartupLoggedIn) {
        global.updateLoginStatus(response.result.loginstatus);
      }
      //global.refreshScreen();
    })
    .catch(() => {
      global.updateLoginStatus(kStartupNotLoggedIn);
    });
}

if (IsHRWeb()) {
  if (process.env.NODE_ENV === 'development') {
    global.baseURL = ''; // implement this later with webpack server and proxy? 'https://localhost:1292';
  } else {
    global.baseURL = '';
  }
} else {
  //global.baseURL = 'http://192.168.43.65:1291';
  //global.baseURL = 'http://192.168.0.104:1291';
  //global.baseURL = 'http://192.168.8.116:1291';
  global.baseURL = 'https://portal.excellent.lv';

  //global.baseURL = 'https://hrportal-test.excellent.lv:8082';
}

InitGlobalValues();

export function LoadSinglePasteSpecial(table, fitlers, fields = '', doLoad = true) {
  if (doLoad) {
    global.loadingspec++;
  }
  Requests.getTableData(table, fitlers, fields)
    .then((response) => {
      switch (table) {
        case 0:
          {
            let deplist = global.userData.deplist.split2(',');
            global.pasteSpecials[table] = [];
            global.pasteSpecialsVector[table] = {};
            response.records.map((rec) => {
              if (rec.EmpCode === global.userData.empcode || deplist.indexOf(rec.DepCode) > -1) {
                global.pasteSpecials[table].push(rec);
              }
              global.pasteSpecialsVector[table][rec.EmpCode] = rec;
            });
          }
          break;
        case 1:
          {
            let CurDate = myDate.getCurrentDate();
            let deplist2 = global.userData.deplist.split2(',');
            global.pasteSpecials[table] = [];
            response.records.map((rec) => {
              let datef = rec.enddate === '' || myDate.extractDateFromString(rec.enddate) > CurDate;
              if ((rec.empcode === global.userData.empcode || deplist2.indexOf(rec.depcode) > -1) && datef) {
                global.pasteSpecials[table].push(rec);
              }
            });
          }
          break;
        case 8:
          if (response.records.length > 0) {
            global.pasteSpecials[table] = response.records[0].rows;
          } else {
            global.pasteSpecials[table] = [];
          }
          break;
        case 11:
          global.pasteSpecials[table] = response.records;
          global.pasteSpecialsVector[table] = {};
          global.pasteSpecialsVector[table][''] = [];
          response.records.map((record) => {
            global.pasteSpecialsVector[table][record.postcode] = record;
          });
          break;
        case 13:
          global.pasteSpecials[table] = response.records;
          global.pasteSpecialsVector[table] = {};
          global.pasteSpecialsVector[table + '_2'] = {};
          global.pasteSpecialsVector[table][''] = '';
          response.records.map((record) => {
            global.pasteSpecialsVector[table][record.Code] = record.Comment;
            global.pasteSpecialsVector[table + '_2'][record.Code] = record.rows;
            /*
                                                            global.pasteSpecialsVector[table + '_2'][record.code].push({
                                                              shiftinterval: '!!MANUAL!!',
                                                              intervalcomment: translate('ManualEntry'),
                                                            });
                                                            */
          });
          break;
        case 14:
          global.pasteSpecials[table] = response.records;
          global.pasteSpecialsVector[table] = {};
          response.records.map((record) => {
            global.pasteSpecialsVector[table][record.Code] = record;
          });
          break;
        case 19:
          global.pasteSpecials[table] = response.records;
          global.pasteSpecialsVector[table] = {};
          global.pasteSpecialsVector[table + '_2'] = {};
          if (objectHasProperty(response.records[0], 'TimeStatName')) {
            response.records.map((record) => {
              global.pasteSpecialsVector[table][record.TimeStatName] = record;
              global.pasteSpecialsVector[table + '_2'][record.Code] = record;
            });
          }
          break;
        case 22:
          {
            let res = [];
            if (response.records.length > 0) {
              for (let i in response.records) {
                res.push({
                  code: response.records[i].Code,
                  comment: response.records[i].Name,
                  timetr: response.records[i].TimeTr,
                });
              }
            }
            global.pasteSpecials[table] = res;
          }
          break;
        case 33: //users
          setPasteSpecialWithRecordCode(table, response);
          break;
        case 'CClassVc':
        case 'CTypeVc':
          setPasteSpecialWithRecordCode(table, response);
          break;
        case 'VATCodeBlock': //vatcodes
          if (response.records.length > 0) {
            global.pasteSpecials[table] = response.records[0].rows;
          } else {
            global.pasteSpecials[table] = [];
          }
          break;
        default:
          global.pasteSpecials[table] = response.records;
      }

      if (doLoad) {
        global.loadedspec++;
        if (global.loadedspec === global.loadingspec) {
          global.updateLoginStatus(kStartupLoggedIn);
          FixPasteSpecials();
        }
      }
    })
    .catch((error) => {
      logError(error);
      global.loadedspec++;
      if (global.loadedspec === global.loadingspec) {
        global.updateLoginStatus(kStartupLoggedIn);
        FixPasteSpecials();
      }
    });
}

function setPasteSpecialWithRecordCode(table: string, response) {
  global.pasteSpecials[table] = response.records;
  global.pasteSpecialsVector[table] = {};
  response.records.map((record) => {
    global.pasteSpecialsVector[table][record.Code] = record;
  });
  global.pasteSpecials[table] = response.records;
}

function FixPasteSpecials() {
  if (global.userData.provider === 1) {
    for (let i in global.pasteSpecials[0]) {
      global.pasteSpecials[0][i].name = global.pasteSpecials[0][i].firstname + ' ' + global.pasteSpecials[0][i].surname;
    }
    for (let i in global.pasteSpecials[1]) {
      global.pasteSpecials[1][i].empname =
        global.pasteSpecials[1][i].firstname + ' ' + global.pasteSpecials[1][i].surname;
    }
    for (let i in global.pasteSpecials[7]) {
      global.pasteSpecials[7][i].depcode = global.pasteSpecials[7][i].code;
    }
    for (let i in global.pasteSpecials[19]) {
      global.pasteSpecials[19][i].description = global.pasteSpecials[19][i].name;
    }
    for (let i in global.pasteSpecials[11]) {
      global.pasteSpecials[11][i].postcode = global.pasteSpecials[11][i].code;
      global.pasteSpecials[11][i].postname = global.pasteSpecials[11][i].name;
    }
  }
  CreateFixedPasteSpecials();
  InitializeStaticPasteSpecialDefinitions();

  /*
          for (let i in global.pasteSpecials[11]) {
            let id = '11_' + global.pasteSpecials[11][i].depcode;
            if (global.pasteSpecials[id] === undefined) {
              global.pasteSpecials[id] = [];
            }
            global.pasteSpecials[id].push(global.pasteSpecials[11][i]);
          }
          for (let i in global.pasteSpecials) {
            if (i.includes('_')) {
              if (i.substring(-1) !== '_') {
                global.pasteSpecials[i].concat(global.pasteSpecials['11_']);
              }
            }
          }
          */
}

function CreateFixedPasteSpecials() {
  global.pasteSpecials[9990] = [];
  global.pasteSpecials[9990].push({code: '0', comment: translate('Created')});
  global.pasteSpecials[9990].push({code: '1', comment: translate('Approved')});
  global.pasteSpecials[9990].push({code: '45', comment: translate('Rejected')});

  global.pasteSpecials[9991] = [];
  global.pasteSpecials[9991].push({code: 0, comment: translate('Month')});
  global.pasteSpecials[9991].push({code: 1, comment: translate('Week')});

  global.pasteSpecials[9992] = [];
  global.pasteSpecials[9992].push({code: 0, comment: translate('Employees')});
  global.pasteSpecials[9992].push({code: 1, comment: translate('Department')});
  global.pasteSpecials[9992].push({code: 2, comment: translate('Post')});

  global.pasteSpecials[9993] = [];
  let curyear = myDate.getCurrentDate().getFullYear();
  for (let i = curyear - 1; i <= curyear + 3; i++) {
    global.pasteSpecials[9993].push({code: i, comment: i});
  }

  global.pasteSpecials[9994] = [];
  global.pasteSpecials[9994].push({code: '01', comment: translate('January')});
  global.pasteSpecials[9994].push({code: '02', comment: translate('February')});
  global.pasteSpecials[9994].push({code: '03', comment: translate('March')});
  global.pasteSpecials[9994].push({code: '04', comment: translate('April')});
  global.pasteSpecials[9994].push({code: '05', comment: translate('May')});
  global.pasteSpecials[9994].push({code: '06', comment: translate('June')});
  global.pasteSpecials[9994].push({code: '07', comment: translate('July')});
  global.pasteSpecials[9994].push({code: '08', comment: translate('August')});
  global.pasteSpecials[9994].push({
    code: '09',
    comment: translate('September'),
  });
  global.pasteSpecials[9994].push({code: '10', comment: translate('October')});
  global.pasteSpecials[9994].push({code: '11', comment: translate('November')});
  global.pasteSpecials[9994].push({code: '12', comment: translate('December')});
}

export function LoadEmployeeInfo(employee, callback) {
  if (objectHasProperty(global.empInfoList, employee) === false) {
    Requests.doAction('getemployeeinfo', {id: employee})
      .then((response) => {
        global.empInfoList[employee] = response.record;
        callback();
      })
      .catch((error) => {
        logError(error);
      });
  } else {
    callback();
  }
}

function LoadPasteSpecials() {
  global.updateLoginStatus(kStartupUnknown);
  global.loadingspec = 0;
  global.loadedspec = 0;
  if (APISupportsItem('absences')) {
    //LoadSinglePasteSpecial(0, {all: true}, 'EmpCode,Name,DepCode,PostCode');
    //LoadSinglePasteSpecial(1, {}, 'ContractNr,Name');
    //LoadSinglePasteSpecial(7, {});
    LoadSinglePasteSpecial(8, {});
    //LoadSinglePasteSpecial(9, {});
    //LoadSinglePasteSpecial(10, {});
    //LoadSinglePasteSpecial(11, {});
    LoadSinglePasteSpecial(19, {});
    if (APISupportsItem('shiftsheets')) {
      LoadSinglePasteSpecial(14, {});
      LoadSinglePasteSpecial(13, {});
    }
    if (APISupportsItem('timesheets')) {
      LoadSinglePasteSpecial(22, {});
    }
    //LoadSinglePasteSpecial(34, {});
    //LoadSinglePasteSpecial(28, {}, 'DefOrderType');
  }
  if (APISupportsItem('activities')) {
    //LoadSinglePasteSpecial(33, {Closed: 0}, 'Code,Name');
    //LoadSinglePasteSpecial(35, {}, 'Code,Comment');
  }

  if (APISupportsItem('stocktakes')) {
    //LoadSinglePasteSpecial(20, {});
  }

  if (APISupportsItem('contacts')) {
    LoadSinglePasteSpecial('CClassVc', {});
    LoadSinglePasteSpecial('CTypeVc', {});
  }

  if (global.loadingspec === 0) {
    global.updateLoginStatus(kStartupLoggedIn);
  }
}

function LoadWindowDefs(refreshf = false) {
  Requests.getWindowDefs(MapLanguage(CurrentLanguage()))
    .then((response) => {
      global.customfields = {};
      response.fields.map(function (el) {
        let vcname = DecodePortalString(el.window);
        global.customfields[vcname] = el.fields;
      });
      global.custompastespecials = {};
      response.pastespecials.map(function (el) {
        global.custompastespecials[el.pastespecial] = el;
      });
      if (refreshf) {
        global.refreshScreen();
      }
    })
    .catch((error) => {
      logError(error);
    });
}

function LoadHolidayInformation() {
  Requests.getTableData(16, {})
    .then((response) => {
      Requests.getTableData(15, {CountryCode: response.records[0].CountryCode})
        .then((response2) => {
          global.holidays = response2.records[0].rows;
          response2.records[0].rows.map((day) => {
            global.holidaysvector[myDate.constructDateString2(myDate.extractDateFromString(day.FreeDay))] = true;
          });
        })
        .catch(() => {});
      if (
        CheckCookie('set_language') !== 'on' &&
        SystemLanguageMatches(
          CheckCookie('language') /*switch this to langcode with time*/,
          response.records[0].LangCode
        ) === false
      ) {
        global.ToggleLanguage(MapSystemLanguage(response.records[0].LangCode));
      }
    })
    .catch((error) => {
      logError(error);
    });

  Requests.getTableData(17, {})
    .then((response) => {
      global.shiftBlock = response.records[0] || {};
    })
    .catch((error) => {
      logError(error);
    });

  Requests.getTableData(27, {})
    .then((response) => {
      response.records.map((rec) => {
        let d1 = myDate.extractDateFromString(rec.WorkDate);
        global.transferredDays[d1] = true;
        let d2 = myDate.extractDateFromString(rec.FromDate);
        global.transferredDays2[d2] = true;
      });
    })
    .catch((error) => {
      logError(error);
    });
}

// function DoSessionCheck() {
//   Requests.testConnection()
//     .then((response) => {
//       setTimeout(() => {
//         DoSessionCheck();
//       }, 10000);
//     })
//     .catch((error) => {
//       logError(error);
//     });
// }

/*
for some reason tablebody.js was the first one to be called and it global.theme is undefined.
for some reason also Platform is undefined at this state. wtf?
*/

function GetUserLanguage() {
  let res = '';
  if (IsHRWeb()) {
    let tmp = document.cookie.split2('; ').find((row) => row.startsWith('language='));
    if (tmp) {
      res = tmp.split2('=')[1];
    }
  } else {
    if (global.language) {
      res = global.language;
    }
  }
  if (res === '') {
    res = 'en';
  }

  return res;
}

function CheckCookie(tstr) {
  let res = '';
  if (IsHRWeb()) {
    let tmp = document.cookie.split2('; ').find((row) => row.startsWith(tstr + '='));
    if (tmp) {
      res = tmp.split2('=')[1];
    }
  }
  return res;
}

function removeCookie() {
  document.cookie = 'air_cookie=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  Requests.ResetLogin();
}

export const LangColorContext = React.createContext({
  lang: GetUserLanguage(),
  color: GetUserTheme(),
  globalupdate: false,
});

type AppState = {
  language: string;
  theme: string;
  update: boolean;
  login: any;
  showf: boolean;
};

type AppProps = {};

export class App extends Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      language: '',
      theme: '',
      update: false,
      login: props.overrideLoginState || kStartupUnknown,
      showf: props.overrideLoginState ? true : false,
      loginState: kStartupNotLoggedIn,
    };
    let self = this;

    if (props.overrideLoginState === undefined) {
      RequestEmpData();
    }
    //DoSessionCheck();
    global.refreshScreen = (loginf = false) => {
      if (loginf) {
        RequestEmpData(true);
      } else {
        self.refreshScreen();
      }
    };
    global.updateLoginStatus = (status) => {
      if (status === kStartupNotLoggedIn) {
        global.activeEditMode = false;
      }
      if (self._isMounted) {
        self.setState({login: status});
      } else {
        self.state.login = status;
      }
    };
    global.ToggleColorMode = () => {
      self.ToggleColorMode();
    };
    global.ToggleLanguage = (langcode) => {
      if (langcode !== '') {
        self.ToggleLanguage(langcode);
      }
    };
    getAllData().then((res) => {
      global.theme = res[1][1];
      global.language = res[0][1];
      let tlanguage = GetUserLanguage();
      let colourTheme = GetUserTheme();
      self.state.language = tlanguage;
      if (res[3][1] === undefined || res[3][1] === '') {
        self.ToggleLanguage(res[3][1]);
      }
      global.language = tlanguage;
      if (IsHRWeb()) {
        if (CheckCookie('windowstates') !== '') {
          global.windowStates = JSON.parse(CheckCookie('windowstates'));
        }
      } else {
        if (res[2] && res[2][1] !== null && res[2][1] !== '') {
          global.windowStates = JSON.parse(res[2][1]);
        }
      }
      SetWindowDefaults();
      self.state.theme = colourTheme;
      global.theme = colourTheme;
      setI18nConfig(tlanguage);
      if (IsHRWeb()) {
        document.title = translate('ExcellentPortal');
      }
      self.setState({showf: true});
    });

    if (IsHRWeb()) {
      document.addEventListener('keydown', (e) => global.HandleKeyDown(e), false);
      //document.addEventListener("scroll", (e) => global.HandleScroll(e), false);
    }
  }

  ToggleColorMode = () => {
    global.theme = global.theme === 'light' ? 'dark' : 'light';
    if (IsHRWeb()) {
      document.cookie = 'colortheme=' + global.theme;
    } else {
      storeData('@colortheme', global.theme);
    }
    //this.setState({theme: data});
    this.refreshScreen();
  };

  ToggleLanguage = (data) => {
    this.setState({language: data});
    setI18nConfig(data);
    if (IsHRWeb()) {
      let now = new Date();
      now.setFullYear(now.getFullYear() + 2);
      document.cookie = 'language=' + data + ';expires=' + now.toUTCString() + ';path=/';
      document.cookie = 'langcode=' + data + ';expires=' + now.toUTCString() + ';path=/';
      document.cookie = 'set_language=on;expires=' + now.toUTCString() + ';path=/';
    } else {
      storeData('@language', data);
      storeData('@langcode', data);
      storeData('@set_language', 'on');
    }
    global.language = data;
    CreateFixedPasteSpecials();
    InitializeStaticPasteSpecialDefinitions();
    LoadWindowDefs(true);
  };
  refreshScreen = () => {
    this.setState({update: this.state.update ? false : true});
  };

  handleLoginSuccess = () => {
    this.setState({loginState: kStartupLoggedIn});
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.state.loginState === kStartupNotLoggedIn) {
      const attemptedURL = window.location.pathname;
      global.redirectTo = attemptedURL;
    }
  }

  ShowBaseContainer = () => {
    let linking = {
      config: {screens: {}},
    };
    let drawers = getDrawers(1);
    if (drawers.length === 0) {
      drawers.push({link: 'hr-portal', screen: ' '});
    }
    drawers.forEach((link) => {
      if (link.recordlink) {
        let obj = {screens: {}};
        obj.screens[link.list] = link.link;
        obj.screens[link.record] = link.recordlink;
        if (link.extra) {
          obj.screens[link.extra] = link.extralink;
        }
        linking.config.screens[link.screen] = obj;
      } else {
        linking.config.screens[link.screen] = link.link;
      }
    });

    setTimeout(function () {
      if (getDrawers().length === 0) {
        if (global.userData.complist.length === 0) {
          global.messageBox({
            showMessage: true,
            statusMessage: translate('NoAccessToAnyComp'),
          });
        } else {
          global.messageBox({
            showMessage: true,
            statusMessage: translate('NoAccessToComp'),
          });
        }
      }
    }, 500);

    if (isNaN(global.userData.apiversion)) {
      return (
        <BaseContainer>
          <NavigationContainer>
            <TopNavigator />
            <HorizontalContainer>
              <SideNavigator />
            </HorizontalContainer>
          </NavigationContainer>
        </BaseContainer>
      );
    }

    return (
      <BaseContainer>
        <NavigationContainer linking={linking}>
          <TopNavigator />
          <OverlayComponents>
            <BackToTopButton />
            <MessageBox />
            <ToolTipGlobal />
            <PasteSpecialDetailedView />
            <MobileBottomButton />
          </OverlayComponents>
          <HorizontalContainer>
            <SideNavigator />
          </HorizontalContainer>
        </NavigationContainer>
      </BaseContainer>
    );
  };

  showAuthorizationScreens() {
    const linking = {
      config: {
        screens: {
          Login: 'hr-portal',
          CreateAccount: 'hr-portal/create-account',
          ForgotPassword: 'hr-portal/forgot-password',
          ResetPassword: 'hr-portal/reset-password',
          CreatePassword: 'hr-portal/create-password',
        },
      },
    };

    return (
      <BaseContainer>
        <NavigationContainer linking={linking}>
          <MessageBox />
          <Drawer.Navigator screenOptions={{headerShown: false}}>
            <Drawer.Screen
              name="Login"
              component={(props) => <Login {...props} onLoginSuccess={this.handleLoginSuccess} />}
              options={{
                title: translate('Login'),
              }}
            />
            <Drawer.Screen
              name="CreateAccount"
              component={CreateAccount}
              options={{
                title: translate('CreateAccount'),
              }}
            />
            <Drawer.Screen
              name="ForgotPassword"
              component={ForgotPassword}
              options={{
                title: translate('ForgotPassword'),
              }}
            />
            <Drawer.Screen
              name="ResetPassword"
              component={ResetPassword}
              options={{
                title: translate('ResetPassword'),
              }}
            />
            <Drawer.Screen
              name="CreatePassword"
              component={CreatePassword}
              options={{
                title: translate('CreatePassword'),
              }}
            />
          </Drawer.Navigator>
        </NavigationContainer>
      </BaseContainer>
    );
  }

  render() {
    if (this.state.showf === false) {
      return <PaperProvider></PaperProvider>;
    } else {
      return (
        <ThemeManager>
          <LangColorContext.Provider
            value={{
              language: this.state.language,
              theme: this.state.theme,
              globalupdate: global.isDrawerOpen ? true : false,
            }}>
            <PaperProvider
              theme={theme}
              style={{
                backgroundColor: colours[GetGlobalTheme()].siteBackground,
              }}
              key={this.state.language + global.theme}>
              <SafeAreaProvider>
                {this.state.login === kStartupLoggedIn && <>{this.ShowBaseContainer()}</>}
                {this.state.login === kStartupClosed && <ClosedUser />}
                {this.state.login === kStartupNotLoggedIn && <>{this.showAuthorizationScreens()}</>}
                {this.state.login === kStartupUnknown && <Loading />}
                <DoubleTapToClose />
              </SafeAreaProvider>
            </PaperProvider>
          </LangColorContext.Provider>
        </ThemeManager>
      );
    }
  }
}

// export default createBrowserApp(App);
export default App;
