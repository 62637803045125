import React, {Component, useEffect, useState} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {ConvertDisplayValue, GetGlobalTheme, IsHRiOS, IsHRWeb, RevertDisplayValue} from '../../Tools/DisplayTools';
import InlineProfileIcon from '../status/InlineProfileIcon';

import {DataTable} from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import {LayoutChangeEvent, Text, View} from 'react-native';
import {isApproved} from '../../utils/helpers';
import FieldSection from '../edit_elements/FieldSection';
import {translate} from '@utils/languageTools';
import {DefaultIconButton} from '../button/DefaultIconButton';
import {MatrixField} from '@utils/types';

const MatrixWrapper = styled.View`
  background-color: ${colours[GetGlobalTheme()].topNavigationBackground};
  display: flex;
  flex-shrink: 1;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  /*margin-horizontal: ${spacing.small300};*/
  margin-top: ${spacing.medium200};
  padding-bottom: ${spacing.small300};
`;

const BodyContainer = styled.ScrollView`
  background-color: ${colours[GetGlobalTheme()].tableBackground};
  font-weight: 600;
  display: flex;
  overflow: visible;

  ${IsHRWeb() &&
  css`
    overflow-x: visible;
    overflow-y: visible;
  `}
  flex-shrink: 1;
  z-index: 1;
  position: relative;
`;

const StyledTitle = styled(DataTable.Title)`
  width: ${(props) => props.columnWidth};
  padding-horizontal: ${(props) => (props.viewOnly ? spacing.small : 0)};
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  ${IsHRWeb() === false &&
  css`
    padding: 0px !important;
  `}
`;

const StyledCell = styled(DataTable.Cell)`
  width: ${(props) => props.columnWidth};
  flex: 1 1 auto;
  /*padding-horizontal: ${(props) => (props.viewOnly ? spacing.small : 0)};*/
`;

const StyledRowContainer = styled(DataTable.Row)`
    background-color: ${(props) => (props.rowIndex % 2 ? colours[GetGlobalTheme()].inputBackground : 'transparent')}
    font-size: ${fontSize.medium};
    min-height: 32px;
    border-bottom-width: 0px;
    border-radius: 6px;
    margin-vertical: 0px;
    ${
      IsHRWeb() &&
      css`
        margin-horizontal: ${spacing.medium};
        padding-horizontal: ${spacing.medium200};
      `
    }
    padding-vertical: 0px;
    margin-bottom: ${(props) =>
      !(props.arrayLength % 2) && props.rowIndex === props.arrayLength - 1 ? /*spacing.medium200*/ 0 : 0};
    z-index: ${(props) => props.arrayLength - props.rowIndex};
`;

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].tableHeader};
  padding-vertical: 0px;
  font-size: ${fontSize.medium};
  border-bottom-width: 0px;
  height: 36px;
  line-height: 36px;
  ${IsHRWeb() &&
  css`
    margin-horizontal: ${spacing.medium};
    padding-horizontal: ${spacing.medium200};
  `}
`;

const InputContainer = styled.View`
  ${(IsHRWeb() &&
    css`
      width: fit-content;
    `) ||
  css`
    width: ${(props) => props.columnWidth};
  `}

  flex: 1 1 100%;
`;

const PageButton = styled.TouchableOpacity``;

const ActivePageText = styled.Text`
  background-color: ${colours[GetGlobalTheme()].inputBackground};
  color: ${colours[GetGlobalTheme()].activeText};
  padding: 2px 8px;
  border-radius: 3px;
  font-size: ${fontSize.small200};
`;
const PageText = styled.Text`
  padding: 2px 8px;
  color: ${colours[GetGlobalTheme()].normalHighligh};
  font-size: ${fontSize.small200};
`;
const PaginationContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-horizontal: ${spacing.medium};
  padding-vertical: ${spacing.small300};
  justify-content: space-between;
`;
const PaginationWrap = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
const PageContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2px;
  background-color: ${colours[GetGlobalTheme()].inputBackground};
  border-color: ${colours[GetGlobalTheme()].inputBorder};
  border-radius: 3px;
`;
const PageList = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2px;
  border-radius: 3px;
  background-color: ${colours[GetGlobalTheme()].lightBackground};
`;

const PaginationPerPage = styled.View`
  margin-horizontal: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;
const PaginationPerPageText = styled.Text`
  margin-right: 15px;
  color: ${colours[GetGlobalTheme()].inputLabelText};
`;
const RowAction = styled.TouchableOpacity`
  padding: 4px;
`;

const InputField = styled.TextInput`
  ${(props) => (props.editable ? colours[GetGlobalTheme()].rowInputBackground : 'transparent')};
  font-family: 'Open Sans';
  ${(props) =>
    props.editable &&
    css`
      border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
    `}
  width: 100%;
  color: ${colours[GetGlobalTheme()].rowText};
  ${IsHRWeb() &&
  css`
    cursor: ${(props) => (props.editable ? 'auto' : 'default')};
    text-overflow: ellipsis;
  `}
  margin: 2px 0;
  padding: 2px 1px;
  border-radius: 4px;
`;

const InputFieldWrapper = styled.View`
  padding: 0 1px;
  position: relative;
  flex-direction: row;
`;
const RowActionWrap = styled.View`
    position: absolute;
    background: ${colours[GetGlobalTheme()].mainBackground}
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 6px;
    border: 1px solid ${colours[GetGlobalTheme()].inputBorder}
    border-radius: 5px;
`;
const RowActionToggle = styled.TouchableOpacity`
  position: absolute;
  top: 10px;
  left: -15px;
`;

const SelectWrap = styled.View`
  ${IsHRWeb() === false &&
  css`
    border-color: ${colours[GetGlobalTheme()].darkHighligh};
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
  `}
`;

const ClearButtonWrap = styled.View`
  position: absolute;
  right: 7px;
  top: 5px;
  ${IsHRWeb() === false &&
  css`
    top: 3px;
  `}
`;

function matrixColumns(columns) {
  let result = [];
  columns.map((column: MatrixField, index: number) => {
    if (column.display === undefined || column.display === true) {
      result.push(
        <StyledTitle
          key={index}
          columnWidth={column.width}
          viewOnly={column.viewOnly}
          style={{...column.style, ...column.cellstyle}}>
          {column.label}
        </StyledTitle>
      );
    }
  });
  return result;
}

export class StyledRow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      px: 0,
      py: 0,
    };
  }

  render() {
    let self = this;
    let rowstyle = {};
    if (this.props.GetMatrixRowStyle !== undefined) {
      rowstyle = this.props.GetMatrixRowStyle(this.props.rowIndex, this.props.data[this.props.rowIndex]);
    }
    return (
      <StyledRowContainer
        key={this.props.rowIndex}
        rowIndex={this.props.rowIndex}
        style={rowstyle}
        onLayout={(event: LayoutChangeEvent) => {
          const layout = event.nativeEvent.layout;
          self.state.py = layout.top;
          if (window.scrollY) {
            self.state.py = layout.top + window.scrollY;
          }
          self.state.px = layout.left;
        }}
        arrayLength={this.props.arrayLength}>
        {this.props.rowactions && (
          <RowActionToggle
            onPress={() =>
              global.setRowActions({
                key: self.props.rowIndex,
                posx: self.state.px,
                posy: self.state.py,
                data: self,
                show: true,
              })
            }>
            <Text>{'...'}</Text>
          </RowActionToggle>
        )}
        {MatrixCell(
          this.props.columns,
          this.props.data[this.props.rowIndex],
          this.props.rowIndex,
          this.props.record,
          this.props.okcheck
        )}
      </StyledRowContainer>
    );
  }
}

// TODO: What to do with 3 unused functions below

export function RowActions(props) {
  return (
    <RowActionWrap style={props.style}>
      {props.matrix.props.rowactions.map((action) => {
        return (
          <RowAction
            onPress={() => {
              action.action(props.matrix.props.rowIndex);
              global.setRowActions({show: false});
            }}>
            <Text>{action.name}</Text>
          </RowAction>
        );
      })}
    </RowActionWrap>
  );
}

function MatrixData(props) {
  let res = [];
  let sp = props.pagination.page * props.pagination.perpage;
  let ep = sp + props.pagination.perpage;
  let showanyf = false;
  //data.map((row, index) => {
  for (let i = sp; props.data && i < ep && i < props.data.length; i++) {
    //if (i > 0) {
    //}
    let showf = true;

    if (props.showrow !== undefined) {
      showf = props.showrow(props.data[i]);
    }

    if (showf) {
      if (props.splitrow !== undefined) {
        res.push(props.splitrow(props.data[i], props.data[i - 1], i, showanyf));
      }
      res.push(
        <StyledRow
          key={JSON.stringify(props.data[i])}
          rowIndex={i}
          arrayLength={props.data.length}
          rowactions={props.rowactions}
          {...props}
        />
      );
      if (props.afterrow !== undefined) {
        res.push(props.afterrow(props.data[i], i, props.data.length, Object.assign({}, props)));
      }
      showanyf = true;
    }
  }
  if (showanyf) {
    if (props.splitrowend !== undefined) {
      res.push(props.splitrowend());
    }
  } else {
    if (props.showemptymatrix !== undefined) {
      res.push(props.showemptymatrix());
    }
  }
  return res;
}

/*, display: 'flex'*/

//textStyle={{flex: '1 1 100%'}}
function MatrixCell(columns, row, rowindex, record, okcheck) {
  let result = [];
  columns.map((column, index) => {
    if (column.display === undefined || column.display === true) {
      let editablef = column.viewOnly === false && isApproved(record, okcheck) === false;
      result.push(
        <StyledCell key={index} columnWidth={column.width} viewOnly={column.viewOnly} style={{...column.cellstyle}}>
          <InputContainer columnWidth={column.width}>
            {column.overwrite?.({
              value: row[column.dataKey],
              rowindex: rowindex,
              colindex: index,
              value2: row[column.dataKey2],
              datakey: column.dataKey,
            }) || (
              <InputFieldWrapper>
                {column.showProfile !== undefined && (
                  <>
                    <InlineProfileIcon empcode={row[column.showProfile]} />
                  </>
                )}
                <InputField
                  key={column.dataKey}
                  name={column.dataKey}
                  defaultValue={ConvertDisplayValue(
                    column.windowElementType,
                    row[column.dataKey],
                    row[column.dataKey2]
                  )}
                  editable={editablef}
                  style={column.style}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    if (column.onChange !== undefined && row[column.dataKey] !== event.target.value) {
                      column.onChange({
                        rowindex: rowindex,
                        fieldname: column.dataKey,
                        value: RevertDisplayValue(column.windowElementType, event.target.value),
                      });
                    }
                    return true;
                  }}
                  onFocus={() => {
                    if (column.onFocus !== undefined) {
                      column.onFocus(rowindex, column.dataKey);
                    }
                    return true;
                  }}
                />
                {column.pasteSpecial !== undefined && column.pasteSpecial.columns && editablef && (
                  <ClearButtonWrap style={{top: IsHRWeb() ? 5 : 6}}>
                    <DefaultIconButton
                      style={{
                        height: 'auto',
                        width: 'auto',
                        padding: 0,
                        margin: 0,
                        borderRadius: 0,
                      }}
                      iconName={'search'}
                      mode="text"
                      size={IsHRWeb() ? 17 : 26}
                      iconType="FA5"
                      action={() => {
                        global.setPasteSpecialInfo({
                          show: true,
                          table: column.pasteSpecial.vcName,
                          psnewrec: false,
                          onChange: column.onChange,
                          psheader: column.pasteSpecial.columns,
                          name: column.dataKey,
                          value: row[column.dataKey],
                          psmulti: column.pasteSpecial.multiValue,
                          row: rowindex,
                        });
                      }}
                    />
                  </ClearButtonWrap>
                )}
              </InputFieldWrapper>
            )}
          </InputContainer>
        </StyledCell>
      );
    }
  });
  return result;
}

const StyledFixedHeader = (props) => {
  const [topScroll, setTopScroll] = useState(0);
  useEffect(() => {
    function handleScroll() {
      setTopScroll(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  let headoffset = parseInt(topScroll) - parseInt(props.initscroll) - parseInt(props.toppos);
  if (headoffset > 0) {
    return (
      <StyledHeader
        key={'Matrix_header ' + headoffset}
        style={{
          position: 'absolute',
          top: headoffset,
          width: props.width,
          zIndex: 99,
        }}>
        {matrixColumns(props.columns)}
      </StyledHeader>
    );
  } else {
    return <></>;
  }
};

const MatrixBody = (props) => {
  const [topPos, setTopOffset] = useState(0);
  const [topScroll, setTopScrollBody] = useState(0);
  const [width, setWidth] = useState(0);

  return (
    <BodyContainer
      key={'Matrix_container'}
      onLayout={(event) => {
        const layout = event.nativeEvent.layout;
        setTopOffset(layout.top);
        setTopScrollBody(window.scrollY);
      }}>
      <DataTable primaryKey={'Matrix_body'}>
        <StyledHeader
          key={'Matrix_header '}
          onLayout={(event: LayoutChangeEvent) => {
            const layout = event.nativeEvent.layout;
            setWidth(layout.width);
          }}>
          {matrixColumns(props.columns)}
        </StyledHeader>
        {IsHRWeb() && (
          <StyledFixedHeader toppos={topPos} width={width} initscroll={topScroll} columns={props.columns} />
        )}
        {MatrixData(props)}
      </DataTable>
    </BodyContainer>
  );
};

function ChangePaginationPage(props, page: number) {
  props.pagination.changeMatrixPage(page);
  if (props.table) {
    props.table.updateScreen();
  }
}

const PaginationList = (props) => {
  let res = [];
  let sp = props.pagination.page - 6;
  let pref = [];
  let post = [];
  if (sp < 0) {
    sp = 0;
  } else {
    pref.push(<PageText key="pg_mid">{'...'}</PageText>);
  }
  let ep = props.pagination.page + 6;
  if (ep > props.pagination.pages) {
    ep = props.pagination.pages;
  } else {
    post.push(<PageText key="pg_mid">{'...'}</PageText>);
  }
  for (let i = sp; i < ep; i++) {
    if (i === props.pagination.page) {
      res.push(
        <PageButton key={i}>
          <ActivePageText>{i + 1}</ActivePageText>
        </PageButton>
      );
    } else {
      res.push(
        <PageButton
          key={i}
          onPress={() => {
            ChangePaginationPage(props, i);
          }}>
          <PageText>{i + 1}</PageText>
        </PageButton>
      );
    }
  }
  let res2 = [];
  res2.push(
    <PageContainer key={res2.length}>
      <PageButton key={'page_first'} onPress={() => ChangePaginationPage(props, 0)}>
        <PageText>{'<<'}</PageText>
      </PageButton>
      {props.pagination.page > 0 && (
        <PageButton key={'page_back'} onPress={() => ChangePaginationPage(props, props.pagination.page - 1)}>
          <PageText>{'<'}</PageText>
        </PageButton>
      )}
      {pref}
      <PageList key={'page_list'}>{res}</PageList>
      {post}
      {props.pagination.page < props.pagination.pages - 1 && (
        <PageButton key={'page_next'} onPress={() => ChangePaginationPage(props, props.pagination.page + 1)}>
          <PageText>{'>'}</PageText>
        </PageButton>
      )}
      <PageButton key={'page_last'} onPress={() => ChangePaginationPage(props, props.pagination.pages - 1)}>
        <PageText>{'>>'}</PageText>
      </PageButton>
    </PageContainer>
  );
  return res2;
};

export const Pagination = (props) => {
  let style = {
    color: colours[GetGlobalTheme()].titleText,
    borderColor: colours[GetGlobalTheme()].inputBorder,
    borderRadius: 3,
    borderWidth: 1,
    fontSize: fontSize.small200,
  };
  if (IsHRWeb() === false) {
    style.width = 90;
    style.height = 30;
  }
  return (
    <View>
      {props.data && props.data.length > 0 && (
        <PaginationContainer noTopSpacing={props.noTopSpacing}>
          <PaginationWrap>
            {props.pagination.pages > 1 && (
              <PaginationList key={'page_list'} pagination={props.pagination} table={props.table} />
            )}
          </PaginationWrap>
          {props.pagination.showperiod !== false && (
            <PaginationPerPage>
              <PaginationPerPageText>{translate('RowsPerPage')}</PaginationPerPageText>
              <SelectWrap>
                <Picker
                  dropdownIconColor={colours[GetGlobalTheme()].titleText}
                  selectedValue={props.pagination.perpage}
                  onValueChange={(itemValue) => {
                    props.pagination.changePagePeriod(itemValue);
                    props.table.updateScreen();
                  }}>
                  <Picker.Item label="10" value="10" />
                  <Picker.Item label="20" value="20" />
                  <Picker.Item label="40" value="40" />
                  <Picker.Item label="60" value="60" />
                  <Picker.Item label="80" value="80" />
                  <Picker.Item label="100" value="100" />
                  <Picker.Item label="150" value="150" />
                  <Picker.Item label="200" value="200" />
                </Picker>
              </SelectWrap>
            </PaginationPerPage>
          )}
        </PaginationContainer>
      )}
    </View>
  );
};

export const MatrixContainer = (props) => {
  let width = '96%';
  if (IsHRWeb()) {
    width = parseInt(props.width, 10) - parseInt(spacing.small300, 10) * 2;
    if (props.minwidth) {
      width = '100%';
    }
  }
  if (props.custommatrixdata) {
    props.data = props.custommatrixdata;
  }
  if (IsHRiOS()) {
    return null;
  }

  return (
    <MatrixWrapper width={width}>
      <MatrixBody
        columns={props.columns}
        data={props.data}
        key={JSON.stringify(props.record.rows)}
        pagination={props.pagination}
        showrow={props.showrow}
        splitrow={props.splitrow}
        GetMatrixRowStyle={props.GetMatrixRowStyle}
        splitrowend={props.splitrowend}
        showemptymatrix={props.showemptymatrix}
        afterrow={props.afterrow}
        table={props.table}
        record={props.record}
        okcheck={props.okcheck}
        rowactions={props.rowactions}
      />
      <Pagination
        noTopSpacing={props.matrixFooterFields && props.matrixFooterFields.length > 0}
        pagination={props.pagination}
        data={props.data}
        table={props.table}
      />
      {props.matrixFooterFields && (
        <FieldSection
          fields={props.matrixFooterFields}
          record={props.record}
          onClick={props.onClick}
          style={{paddingHorizontal: spacing.small200}}
        />
      )}
    </MatrixWrapper>
  );
};
