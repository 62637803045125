import React, {Component} from 'react';
import {Animated, Modal, TouchableOpacity, View} from 'react-native';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb, WindowElementType} from '../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import EditField from '@components/editElements/EditField';
import Requests from '../../api/Requests';
import {ActionButton} from '../button/ActionButton';
import {DisplayEPOffWorkCalcRn} from '../Reports/EPOffWorkCalcRn';
import {WebViewEXC as WebView} from '../../Tools/Stubs';
import {logError} from '@utils/debug';

let parseString = require('react-native-xml2js').parseString;

const ReportBody = styled.ScrollView`
  padding: ${spacing.medium}px;
`;
const ReportWrap = styled.View`
  position: absolute;
  width: 80%;
  height: 90%;
  left: 10%;
  top: 5%;
  background: white;
`;
const ReportTop = styled.View`
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
`;
const ReportTitle = styled.Text`
  padding: ${spacing.medium}px;
  font-size: ${fontSize.large}px;
`;

const ReportSpecificationActions = styled.View`
  flex-direction: row;
  justify-content: center;
`;
const ReportSpecificationFields = styled.View`
  justify-content: center;
`;

const ReportSpecificationContent = styled.View`
  ${(IsHRWeb() &&
    css`
      width: 50%;
      left: 25%;
      padding: 10px 30px 30px 30px;
    `) ||
  css`
    width: 100%;
    heigth: 100%;
    padding: 5px;
  `}
  top: -1px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #ddd;
  background: ${colours[GetGlobalTheme()].mainBackground};
`;

export class ReportWindow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.showspec = false;
    this.state = {
      SearchVal: '',
      curpage: 0,
      reportdata: {},
      showfields: false,
      options: this.props.fields,
      offsetY: new Animated.Value(-400),
    };
    //this.showReportSpecification();
    this.calculateReport();
  }

  showReportSpecification() {
    let self = this;
    Animated.timing(this.state.offsetY, {toValue: 0, duration: 300}).start(() => {
      self.setState({showfields: true});
    });
    this.showspec = true;
  }

  hideReportSpecification() {
    Animated.timing(this.state.offsetY, {toValue: -400, duration: 300}).start();
    this.showspec = false;
  }

  shouldShowReportSpecification() {
    let res = false;
    switch (this.props.repname) {
      case 'APRn':
      case 'ARRn':
      case 'BalRn':
      case 'ResRn':
        res = true;
        break;
      default:
        res = false;
    }
    return res;
  }

  ShowActions = () => {
    let res = [];
    let self = this;
    res.push(<TouchableOpacity></TouchableOpacity>);
    res.push(
      <ActionButton onPress={() => self.calculateReport()} title={translate('Calculate')} />,
      <ActionButton onPress={() => self.hideReportSpecification()} title={translate('Close')} />
    );
    return res;
  };
  ShowFields = () => {
    let res = [];
    let self = this;
    switch (this.props.repname) {
      case 'ARRn':
        res.push(
          <EditField
            title={translate('Customer')}
            value={this.state.options.f1}
            ps={'CUVc'}
            psheader={[
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainkey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ]}
            onChange={({value}: InputChangeEventProps) => {
              self.state.options.f1 = value;
            }}
          />
        );
        break;

      case 'APRn':
        res.push(
          <EditField
            title={translate('Supplier')}
            value={this.state.options.f1}
            ps={'CUVc'}
            psheader={[
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainkey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ]}
            onChange={({value}: InputChangeEventProps) => {
              self.state.options.f1 = value;
            }}
          />
        );
        break;
      case 'BalRn':
        res.push(
          <EditField
            title={translate('StartDate')}
            name={'StartDate'}
            value={this.state.options.sStartDate}
            editable={true}
            width={'150px'}
            ignorescroll={true}
            type={WindowElementType.kInputTypeDate}
            onChange={({value}: InputChangeEventProps) => {
              let options = self.state.options;
              options.sStartDate = value;
              self.setState({options: options});
            }}
          />,
          <EditField
            title={translate('EndDate')}
            name={'EndDate'}
            value={this.state.options.sEndDate}
            editable={true}
            width={'150px'}
            ignorescroll={true}
            record={{StartDate: this.state.options.sEndDate}}
            linkedfield={'StartDate'}
            type={WindowElementType.kInputTypeDate}
            onChange={({value}: InputChangeEventProps) => {
              let options = self.state.options;
              options.sEndDate = value;
              self.setState({options: options});
            }}
          />
        );
    }
    return res;
  };

  componentWillUnmount() {}

  calculateReport = () => {
    let self = this;
    let repreq = {
      lang: 'LAT',
      repname: this.props.repname,
      reportwindow: this.props.reportwindow,
      spec: {...this.state.options},
    };
    Requests.getReport(repreq)
      .then((response) => {
        self.hideReportSpecification();
        let tmp = response.replace(/<p .*?>/g, '');
        tmp = tmp.replace(/<hr>/g, '<hr></hr>');
        let base = tmp.replace(/<lang>(.*?)<\/lang>/, '');
        self.state.reportdatabase = base;
        parseString(tmp, function (err, result) {
          self.setState({reportdata: result});
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowReportData = () => {
    let res = [];

    switch (this.props.repname) {
      case 'EPOffWorkCalcRn':
        res = DisplayEPOffWorkCalcRn(this.state.reportdata);
        break;
      default:
        if (this.state.reportdatabase) {
          let base = this.state.reportdatabase;
          base = base.replace(/<table .*?>(.*?)<\/table>/, '');
          base = base.replace(/<table .*?>(.*?)<\/table>/, '');
          base = "<script src='https://ajax.googleapis.com/ajax/libs/jquery/3.7.0/jquery.min.js'></script>" + base;
          base =
            base +
            "<script>$('report > table:first-child td').each(function(){ $(this).html('<b>' + $(this).html() + '</b>')});</script>";
          if (IsHRWeb()) {
            res.push(
              <iframe
                srcdoc={base}
                style={{
                  border: 'none',
                  height: '100vh',
                  width: '100%',
                }}></iframe>
            );
          } else {
            res.push(<WebView originWhitelist={['*']} source={{html: base}}></WebView>);
          }
        }
    }
    return res;
  };

  render() {
    return (
      <Modal transparent={true}>
        <ReportWrap>
          <ReportTop>
            <View>
              <ReportTitle>{this.props.reptitle}</ReportTitle>
            </View>
            <View style={{flexDirection: 'row'}}>
              {this.shouldShowReportSpecification() && this.showspec === false && (
                <ActionButton onPress={() => this.showReportSpecification()} title={translate('Parameters')} />
              )}
              <ActionButton onPress={() => global.showReport({showf: false})} title={translate('Close')} />
            </View>
          </ReportTop>
          {this.shouldShowReportSpecification() && (
            <Animated.View
              style={{
                transform: [{translateY: this.state.offsetY}],
                position: 'absolute',
                width: '50%',
                zIndex: 999,
                left: '25%',
              }}>
              <ReportSpecificationContent>
                <ReportSpecificationFields>{this.state.showfields && this.ShowFields()}</ReportSpecificationFields>
                <ReportSpecificationActions>{this.ShowActions()}</ReportSpecificationActions>
              </ReportSpecificationContent>
            </Animated.View>
          )}
          <ReportBody>{this.ShowReportData()}</ReportBody>
        </ReportWrap>
      </Modal>
    );
  }
}
