import React, {useState} from 'react';
import {colours, fontWeight, spacing} from '@src/styles/Style';
import {
  ColumnContainer,
  Header,
  HeaderTextContainer,
  TextNormal,
  TextNormalBold,
} from '@src/styles/primitiveComponents';
import {ActionButton} from '../../button/ActionButton';
import {FormatActionButton, GetGlobalTheme, isMobileOrTabletScreen, WindowElementType} from '@src/tools/displayTools';
import styled from 'styled-components/native';
import {OutsideClickWrapper} from '../OutsideClickWrapper';

const Container = styled(ColumnContainer)`
  background-color: ${colours[GetGlobalTheme()].white};
  border-radius: 12px;
  width: ${isMobileOrTabletScreen() ? 300 : 350}px;
  margin: auto auto;
  position: absolute;
  padding: ${spacing.space12}px;
`;

const FieldList = styled.View`
  gap: ${spacing.space12};
`;
const Field = styled.View`
  gap: ${spacing.space4};
`;
const FieldValue = styled.View`
  height: 20px;
`;
const FieldLabel = styled(TextNormal)`
  color: ${colours[GetGlobalTheme()].label};
`;

type PasteSpecialDetailedViewProps = {
  show: boolean;
  style?: React.CSSProperties;
  title?: string;
  fields?: Array<{label: string; value: string; type: WindowElementType}>;
  pos?: any;
};

const desktopRightPositionOffset = 115;

export const PasteSpecialDetailedView: React.FC = () => {
  const [pasteSpecialDetailsProps, setPasteSpecialDetails] = useState<PasteSpecialDetailedViewProps>({
    show: false,
    fields: [],
    title: '',
    style: undefined,
  });

  global.setPasteSpecialDetailedView = (pasteSpecialDetails) => {
    if (pasteSpecialDetails.show === true && pasteSpecialDetailsProps.show === true) {
      setPasteSpecialDetails({show: false});
    } else {
      setPasteSpecialDetails(pasteSpecialDetails);
    }
  };

  const closeButtonStyle = {
    padding: '9px',
    marginLeft: 'auto',
    borderRadius: '8px',
    height: '30px',
  };

  if (pasteSpecialDetailsProps.show) {
    return (
      <OutsideClickWrapper onOutsideClick={() => global.setPasteSpecialDetailedView({show: false})}>
        <Container
          style={{
            right: isMobileOrTabletScreen() ? 40 : pasteSpecialDetailsProps.pos.left + desktopRightPositionOffset,
            top: pasteSpecialDetailsProps.pos.top + 35,
            boxShadow: '0px 0px 16px 0px #D5D7DD',
          }}>
          <Header style={{padding: 0, paddingBottom: spacing.space12, marginBottom: spacing.space12}}>
            <HeaderTextContainer style={{alignItems: 'center'}}>
              <TextNormalBold>{pasteSpecialDetailsProps.title}</TextNormalBold>
              <ActionButton
                onPress={() => global.setPasteSpecialDetailedView({show: false})}
                title={''}
                style={closeButtonStyle}
                iconName={'CrossIcon'}
                iconColor={colours[GetGlobalTheme()].primary}
                iconSize={12}
                backgroundColor={colours[GetGlobalTheme()].iconButtonBG}
                hoverColor={colours[GetGlobalTheme()].grey100}
              />
            </HeaderTextContainer>
          </Header>
          <FieldList>
            {pasteSpecialDetailsProps.fields.map((field) => {
              if (field.value !== '') {
                return (
                  <Field>
                    <FieldLabel>{field.label}</FieldLabel>
                    <FieldValue>
                      {FormatActionButton(field.value, field.type, {fontWeight: fontWeight.bodyBold})}
                    </FieldValue>
                  </Field>
                );
              }
            })}
          </FieldList>
        </Container>
      </OutsideClickWrapper>
    );
  } else {
    return <></>;
  }
};
