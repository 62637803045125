import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {colours} from '@src/styles/Style';
import {GetGlobalTheme, isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';
import {RowContainer, TextNormal} from '@src/styles/primitiveComponents';
import {translate} from '@src/utils/languageTools';
import {PortalIcon} from '@components/portalIcon/PortalIcon';

interface ButtonContainerProps {
  show: boolean;
  width: string;
}

const ToTopButton = styled.div<ButtonContainerProps>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  height: 20px;
  width: ${({width}) => width};
  position: fixed;
  bottom: 10px;
  right: 15px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background-color: ${colours[GetGlobalTheme()].white};
  border: 1px solid ${colours[GetGlobalTheme()].borderDefault};
  border-radius: 12px;
  z-index: 1000;
  cursor: pointer;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
`;

const StyledIconWrapper = styled(RowContainer)`
  width: 20%;
  justify-content: center;
  align-items: center;
`;

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      const shouldShow = window.scrollY > 50;
      if (showButton !== shouldShow) {
        setShowButton(shouldShow);
      }
    };

    window.addEventListener('scroll', checkScrollHeight);
    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <ToTopButton show={showButton} onClick={scrollToTop} width={isMobileOrTabletScreen() ? '12px' : '84px'}>
      <RowContainer style={{alignItems: 'center', gap: '4px'}}>
        <StyledIconWrapper>
          <PortalIcon iconName={'ArrowIcon'} iconHeight={12} iconColor={colours[GetGlobalTheme()].primary} />
        </StyledIconWrapper>
        {isDesktopScreen() && (
          <TextNormal style={{color: colours[GetGlobalTheme()].primary}}>{translate('BackToTop')}</TextNormal>
        )}
      </RowContainer>
    </ToTopButton>
  );
};

export default BackToTopButton;
