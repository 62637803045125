import React from 'react';
import Contacts_old from 'src/components/screens/Contacts';
import {
  getDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsNarrowScreen,
  Supported,
  WindowElementType,
  WindowWrap,
} from '@tools/displayTools';
import {InspectWindow} from '@components/editElements/inspectWindow/InspectWindow';
import {translate} from '@utils/languageTools';
import {SetCurrentWindow2} from '@tools/windowTools';
import {LangColorContext} from '../../../../App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {ActiveEditFields, ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordList} from '@components/RecordList/RecordList';
import {ContactsRecordListDefinition} from './contactsRecordListDefinition';
import {ContactsRecordScreenDefinition} from './contactsRecordScreenDefinition';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {InputChangeEventProps, UseMultiDropDownWithFiltersResult, WindowRecord} from '@src/utils/types';
import {RowContainer, TextNormal, TextNormalBold} from '@src/styles/primitiveComponents';
import styled from 'styled-components/native';
import {colours, fontSize, spacing} from '@src/styles/Style';
import Requests from '../../../../api/Requests';
import {logError} from '@utils/debug';
import {MultiDropDownField} from '@components/editElements/pasteSpecial/ClassificationPasteSpecial';

const Drawer = createDrawerNavigator();

interface ContactsListState extends ListScreenState {}

export class ContactsList extends ListScreen<{}, ContactsListState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinition = new ContactsRecordListDefinition(this);
    this.rowClick = this.rowClick.bind(this);
    this.ScreenName = 'Contacts';
    this.table = 'CUVc';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
    } as ContactsListState;
    SetCurrentWindow2('Contacts', 'Code', 'descending');
  }

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          const browseColumns = self.recordWindowDefinition.getBrowseColumns(this);
          return (
            <WindowWrap>
              <RecordList
                table={this.table}
                windowName={'Contacts'}
                update={this.state.update}
                data={this.state.data}
                browseColumns={browseColumns}
                doClick={self.rowClick}
                rowID={'code'}
                title={translate(this.ScreenName)}
                addNewTitle={translate('AddNewContact')}
                perPage={IsNarrowScreen() ? 7 : null}
                navigation={this.props.navigation}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

const CustomerType = styled.View`
  padding: ${spacing.space2}px ${spacing.space8}px;
  border-radius: 50px;
  font-size: ${fontSize.bodySmall}px;
  background: ${colours[GetGlobalTheme()].greenLight};
`;

const SupplierType = styled.View`
  padding: ${spacing.space2}px ${spacing.space8}px;
  border-radius: 50px;
  font-size: ${fontSize.bodySmall}px;
  background: ${colours[GetGlobalTheme()].purpleLight};
`;
const ContactInfo = styled.View`
  padding: ${spacing.space16}px;
`;

interface ContactsRecordState extends RecordScreenState {
  activeRow: number;
}

export class ContactsRecord extends RecordScreen<{}, ContactsRecordState> {
  origrowcnt: number;

  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new ContactsRecordScreenDefinition(this);

    //this.changePage = this.changePage.bind(this);
    this.origrowcnt = 0;
    this.ScreenName = 'Contacts';
    this.table = 'CUVc';
    this.IDField = 'Code';
    this.state = {
      ...this.state,
    };
    this.useActiveEditFields = true;
    this.record = this.createNewRecord();
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  createNewRecord = () => {
    return new WindowRecord(
      this.table,
      false,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true,
      null,
      this.recordWindowDefinitions.getHiddenFields()
    );
  };

  isNewRecord = () => this.id === 'new';

  getClassificationTypes = () => {
    let result: UseMultiDropDownWithFiltersResult = {useMultiDropDowns: false};
    if (this.editFieldRules && this.editFieldRules.length > 0) {
      result.useMultiDropDowns = true;
      this.editFieldRules.forEach((rule) => {
        if (rule.field === 'Classifiction') {
          result.filterField = 'ClassType';
          result.filterValue = rule.value;
          result.table = 'CTypeVc';
        }
      });
    }
    return result;
  };

  getClassificationDetails = () => {
    let detailsObject = null;
    if (!this.getClassificationTypes().useMultiDropDowns) {
      return detailsObject;
    }
    let fields = [];
    // fix rule value
    if (this.editFieldRules && this.editFieldRules.length > 0) {
      this.editFieldRules.forEach((rule) => {
        fields.push({
          label: global.pasteSpecialsVector['CTypeVc'][rule.value].Comment,
          // value:
          // key:
          type: WindowElementType.kInputTypeText,
        });
      });
    }

    detailsObject = {
      fields: fields,
    };

    return detailsObject;
  };

  getClassificationFields = (): MultiDropDownField => {
    if (this.editFieldRules && this.editFieldRules.length > 0) {
      const editFieldRule = this.editFieldRules[0];
      return {field: editFieldRule.field, value: editFieldRule.value, editFieldRule: editFieldRule.type, key: 'CType'};
    }
  };

  showContactShortInfo = () => {
    let infoComponent = [];
    let CUType = this.record.headerFields.CUType === '1';
    let VEType = this.record.headerFields.VEType === '1';
    infoComponent.push(<TextNormalBold>{this.record.headerFields.Name}</TextNormalBold>);
    infoComponent.push(
      <RowContainer style={{justifyContent: 'space-between'}}>
        <TextNormal>{this.record.headerFields.Code}</TextNormal>
        <RowContainer style={{gap: spacing.space4}}>
          {CUType && <CustomerType>{translate('Client')}</CustomerType>}
          {VEType && <SupplierType>{translate('Supplier')}</SupplierType>}
        </RowContainer>
      </RowContainer>
    );
    return <ContactInfo>{infoComponent}</ContactInfo>;
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                key={'contacts' + this.id}
                title={translate('Contact')}
                window={this}
                headerFields={self.recordWindowDefinitions.getHeaderFields(this)}
                forceEditMode={this.isNewRecord()}
                record={this.record}
                onClick={this.handleInputClick}
                okcheck={'OKFlag'}
                allowDelete={self.allowsEditAndDelete()}
                width={'840px'}
                hasBackButton={true}
                showStatusOnMobile={false}
                preLoadActiveEditFields={true}
                backButton={() => goBack(self, self.ScreenName)}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function Contacts() {
  if (Supported('contacts_v2')) {
    return (
      <Drawer.Navigator
        style={{background: 'white'}}
        screenOptions={{headerShown: false}}
        backBehavior={getDefaultBackBehaviour()}
        drawerStyle={{display: 'none'}}>
        <Drawer.Screen
          name="ContactsList"
          component={ContactsList}
          options={{
            title: translate('Contacts'),
          }}
        />
        <Drawer.Screen
          name="ContactsRecord"
          component={ContactsRecord}
          backBehavior={getDefaultBackBehaviour()}
          options={{
            title: translate('Contact'),
          }}
          getId={({params}) => params.id}
        />
      </Drawer.Navigator>
    );
  } else {
    return Contacts_old();
  }
}

export default Contacts;
