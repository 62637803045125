import React from 'react';
import {Component} from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import {colours, fontSize} from '../../styles/Style';
import * as myDate from '../../Tools/DateTools';
import {GetWebColor} from '../../Tools/ShiftTools';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import {ActionButton} from '../button/ActionButton';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {GetIntervalColor} from '../../Tools/ShiftTools';
import {IsManager, ScreenHeight} from '@src/tools/displayTools';

const ShiftDayCalendar = styled.View`
  position: relative;
  width: 100%;
  flex-grow: 1;
  height: ${ScreenHeight() - 110}px;
`;
const HeaderButtonContainer = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1;
`;

const ShiftMonthCalendarWrapOuter = styled.View`
  flex-grow: 1;
  height: ${ScreenHeight() - 110}px;
`;
const ShiftMonthCalendarWrap = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
`;

const CalLineWrap = styled.View`
  position: absolute;
  left: 60px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;
const NavigationWrap = styled.View`
  height: 60px;
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavigationContent = styled.View`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
`;

const EmptyNavSpace = styled.View`
  display: flex;
  width: 87px;
`;

const HourLine = styled.View`
  position: absolute;
  width: 100%;
  height: 4%;
`;
const HourLineBorder = styled.View`
  border: 0px solid ${colours[GetGlobalTheme()].inputBorder};
  border-top-width: 1px;
  position: absolute;
  width: 100%;
  left: 0px;
  opacity: 0.2;
`;
const HourLineText = styled.Text`
  position: absolute;
  top: 2px;
  left: 5px;
  color: ${colours[GetGlobalTheme()].titleText};
`;

const CalLine = styled.View`
  /*border: 1px solid ${colours[GetGlobalTheme()].inputBorder};*/
  position: absolute;
  border-radius: 2px;
  padding: 8px 8px;
`;

const HourText = styled.Text`
  color: white;
`;
const NavText = styled.Text`
  text-align: center;
  padding: 0px 10px;
  font-size: ${fontSize.medium200};
  color: ${colours[GetGlobalTheme()].titleText};
`;
const NavTextWrap = styled.View`
  text-align: center;
  display: flex;
`;

const ShiftWrapper = styled.View`
  height: 100%;
`;

const ShiftCalSmallDayWrapOuter = styled.View`
  width: 14.285%;
  border: 1px solid ${colours[GetGlobalTheme()].calendarBorder};
  display: flex;
  flex-grow: 1;
`;

const ShiftCalSmallDayWrap = styled.View`
  background-color: ${colours[GetGlobalTheme()].calendarDay};
  height: 100%;
  display: flex;
  flex-grow: 1;
`;

const ShiftCalSmallDay = styled.TouchableOpacity`
  height: 100%;
  display: flex;
  flex-grow: 1;
`;
const ShiftCalSmallDayText = styled.Text`
  font-size: ${fontSize.medium200};
  padding: 4px;
  flex-grow: 1;
  color: ${colours[GetGlobalTheme()].calendarDayText};
  text-align: right;
`;
const ShiftCalSmallDayDotsWrap = styled.View`
  display: flex;
  align-items: flex-end;
`;
const ShiftCalSmallDayDots = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
const SingleDot = styled.View`
  width: 15px;
  height: 15px;
  display: flex;
  border-radius: 10;
  margin: 4px 4px 4px 4px;
`;

class ShiftCalendar extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.display = 0;
    this.state = {
      CurDay: false,
    };
  }

  componentWillUpdate(props) {
    if (this._skipUpdate === false) {
      const descriptor = Object.getOwnPropertyDescriptor(this.props, 'record');
      if (descriptor.writable) {
        this.props.record = props.record;
      }
    } else {
      this._skipUpdate = false;
    }
  }

  intervalExists = (colval) => {
    return objectHasProperty(global.pasteSpecialsVector[14], colval);
  };

  openSingleCalDay = (day) => {
    this.display = 1;
    this.setState({CurDay: day});
  };

  closeSingleCalDay = () => {
    this.display = 0;
    this.setState({CurDay: -1});
  };
  getSingleCellHeight = () => {
    let res = '';
    let w = 1;
    let td = myDate.extractDateFromString(this.props.record.StartDate);
    for (let i = 1; i < 32; i++) {
      let day = td.getDay();
      if (day === 1 && i > 1) {
        w++;
      }
      td.setDate(td.getDate() + 1);
    }
    res = 100 / w + '%';

    return res;
  };

  showShiftMonthCalendar = () => {
    let self = this;
    let res = [];
    let td = myDate.extractDateFromString(this.props.record.StartDate);
    let vDay = {};
    let curuser = global.userData.empcode;
    let adminf = IsManager();
    for (let i = 1; i < 32; i++) {
      for (let r = 0; r < this.props.record.rows.length; r++) {
        let dayInterval = this.props.record.rows[r]['Day' + i];
        if (dayInterval) {
          if (adminf || curuser === this.props.record.rows[r].EmpCode) {
            if (this.intervalExists(dayInterval)) {
              let color = GetIntervalColor(dayInterval);
              if (!vDay[i]) {
                vDay[i] = [];
              }
              if (vDay[i].indexOf(color) === -1) {
                vDay[i].push(color);
              }
            }
          }
        }
      }
      td.setDate(td.getDate() + 1);
    }
    let cellh = this.getSingleCellHeight();
    td = myDate.extractDateFromString(this.props.record.StartDate);
    if (td) {
      let tmpday = td.getDay() - 1;
      if (td.getDay() === 0) {
        tmpday = 6;
      }
      let prevtd = myDate.AddMonth(td, -1);
      let prevdaycnt = myDate.getDaysInMonth2(prevtd);
      for (let i = 0; i < tmpday; i++) {
        let tmpi = prevdaycnt - (tmpday - (i + 1));
        prevtd.setDate(tmpi);
        let tt = myDate.constructDateString2(prevtd);
        let style = {};
        let day = prevtd.getDay();
        if (global.transferredDays2[tt]) {
          style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
        }
        if (day === 6 || day === 0) {
          style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
        }
        if (global.holidaysvector[tt]) {
          style.backgroundColor = GetWebColor(global.shiftBlock.ColorHoliday);
        }
        res.push(
          <ShiftCalSmallDayWrapOuter style={{height: cellh}}>
            <ShiftCalSmallDayWrap style={{...style, opacity: 0.4}}>
              <ShiftCalSmallDay>
                <ShiftCalSmallDayText>{tmpi}</ShiftCalSmallDayText>
              </ShiftCalSmallDay>
            </ShiftCalSmallDayWrap>
          </ShiftCalSmallDayWrapOuter>
        );
      }

      for (let i = 1; i < 32; i++) {
        let day = td.getDay();
        let style = {height: cellh};
        let style2 = {};
        let tt = myDate.constructDateString2(td);
        if (global.transferredDays2[tt]) {
          style2.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
        }
        if (day === 6 || day === 0) {
          style2.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
        }
        if (global.holidaysvector[tt]) {
          style2.backgroundColor = GetWebColor(global.shiftBlock.ColorHoliday);
        }

        res.push(
          <ShiftCalSmallDayWrapOuter style={style}>
            <ShiftCalSmallDayWrap style={style2}>
              <ShiftCalSmallDay onPress={() => self.openSingleCalDay(i)}>
                <ShiftCalSmallDayText>{i}</ShiftCalSmallDayText>
                <ShiftCalSmallDayDotsWrap>
                  <ShiftCalSmallDayDots>{this.displayDayDots(vDay[i])}</ShiftCalSmallDayDots>
                </ShiftCalSmallDayDotsWrap>
              </ShiftCalSmallDay>
            </ShiftCalSmallDayWrap>
          </ShiftCalSmallDayWrapOuter>
        );
        td.setDate(td.getDate() + 1);
      }
      if (td.getDay() !== 1) {
        let tmpday = td.getDay() - 1;
        if (td.getDay() === 0) {
          tmpday = 6;
        }
        let tmpi = 1;
        for (let i = tmpday; i < 7; i++) {
          let style = {};
          if (i === 5 || i === 6) {
            style.backgroundColor = GetWebColor(global.shiftBlock.ColorWeekend);
          }

          res.push(
            <ShiftCalSmallDayWrapOuter style={{height: cellh}}>
              <ShiftCalSmallDayWrap style={{...style, opacity: 0.4}}>
                <ShiftCalSmallDay>
                  <ShiftCalSmallDayText>{tmpi}</ShiftCalSmallDayText>
                </ShiftCalSmallDay>
              </ShiftCalSmallDayWrap>
            </ShiftCalSmallDayWrapOuter>
          );
          tmpi++;
        }
      }
    }
    return res;
  };
  displayDayDots = (colors) => {
    let res = [];
    if (typeof colors === 'object') {
      colors.map(function (color) {
        res.push(<SingleDot style={{backgroundColor: color}}></SingleDot>);
      });
    }
    return res;
  };

  getEmloyeeShortName = (name: string) => {
    let shortname = name.substring(0, 1);
    if (name.split2(' ').length > 1) {
      shortname = shortname + name.split2(' ')[1].substring(0, 1);
    }
    return shortname;
  };
  minTime = (mintime, tt) => {
    let res = mintime;
    if (tt < mintime) {
      res = tt;
    }
    return res;
  };
  MaxTime = (maxtime, tt) => {
    let res = maxtime;
    if (tt > maxtime) {
      res = tt;
    }
    return res;
  };

  ConvertHour = (hour) => {
    let res = hour.toString();
    if (res.length === 1) {
      res = '0' + res;
    }
    res = res + ':00';
    return res;
  };

  ShowShiftDayCalendar = () => {
    let entries = [];
    let mintime = '00:00:00';
    let maxtime = '24:00:00';
    let self = this;
    let pred = '';
    let curd = '';
    for (let i = 0; i < this.props.record.rows.length; i++) {
      pred = '';
      if (global.userData.deplist === '' && global.userData.empcode !== this.props.record.rows[i].EmpCode) {
        continue;
      }
      curd = this.props.record.rows[i]['Day' + this.state.CurDay];
      if (this.state.CurDay > 1) {
        pred = this.props.record.rows[i]['Day' + (this.state.CurDay - 1)];
      }
      let empname = this.getEmloyeeShortName(this.props.record.rows[i].EmpName);
      if (pred !== '') {
        pred.split2(',').map(function (interval) {
          if (self.intervalExists(interval)) {
            let intrec = global.pasteSpecialsVector[14][interval];
            let tmpst = intrec.FromTime;
            let tmpet = intrec.ToTime;
            if (tmpst > tmpet) {
              tmpst = '00:00:00';
              entries.push({
                st: tmpst,
                et: tmpet,
                color: GetWebColor(intrec.color, '0.8'),
                empl: empname,
                empl2: self.props.record.rows[i].EmpName,
              });
              mintime = self.minTime(mintime, tmpst);
              maxtime = self.MaxTime(maxtime, tmpet);
            }
          }
        });
      }
      if (curd !== '') {
        curd.split2(',').map(function (interval) {
          if (self.intervalExists(interval)) {
            let intrec = global.pasteSpecialsVector[14][interval];
            let tmpst = intrec.FromTime;
            let tmpet = intrec.ToTime;
            if (tmpst > tmpet) {
              tmpet = '24:00:00';
            }
            entries.push({
              st: tmpst,
              et: tmpet,
              color: GetWebColor(intrec.Color, '0.8'),
              empl: empname,
              empl2: self.props.record.rows[i].EmpName,
            });
            mintime = self.minTime(mintime, tmpst);
            maxtime = self.MaxTime(maxtime, tmpet);
          }
        });
      }
    }
    let res = [];
    let fr = 0;
    let minh = parseInt(mintime.substring(0, 2));
    if (minh > 1) {
      //  fr = minh - 1;
    }
    let to = 24;
    let maxh = parseInt(maxtime.substring(0, 2));
    if (maxh < 22) {
      //  to = maxh + 1;
    }
    for (let i = fr; i < to; i++) {
      res.push(
        <HourLine style={{top: i * 4 + '%'}}>
          <HourLineBorder />
          <HourLineText>{this.ConvertHour(i)}</HourLineText>
        </HourLine>
      );
    }
    res.push(
      <HourLine style={{top: to * 4 + '%'}}>
        <HourLineBorder />
        <HourLineText></HourLineText>
      </HourLine>
    );
    let ratio = 100 / entries.length;
    let num = 0;
    let tmp = [];
    entries.map(function (entry) {
      let starth = parseInt(entry.st.substring(0, 2) + '.' + parseInt(entry.st.substring(3, 2)) / 60);
      let dur = parseInt(entry.et.substring(0, 2) + '.' + parseInt(entry.et.substring(3, 2)) / 60);
      dur -= starth;
      let empname = entry.empl;
      if (entries.length <= 4) {
        empname = entry.empl2;
      }
      tmp.push(
        <CalLine
          style={{
            backgroundColor: entry.color,
            left: num * ratio + '%',
            width: ratio - 1 + '%',
            top: 4 * starth + '%',
            height: 4 * dur + '%',
          }}>
          <HourText>{empname}</HourText>
        </CalLine>
      );
      num++;
    });

    res.push(<CalLineWrap>{tmp}</CalLineWrap>);

    return res;
  };
  ConvertTimeVal = (t) => {
    let res = t.toString();

    if (res.length === 1) {
      res = '0' + res;
    }
    return res;
  };
  getRecordListPosition = () => {
    let sernr = this.props.record.SerNr;
    for (let i = 0; i < this.props.list.length; i++) {
      if (sernr === this.props.list[i].sernr) {
        return i;
      }
    }
    return -1;
  };
  SwitchCalcMonth = (d) => {
    this.props.window.getLocalRecord(this.props.list[d].sernr);
  };

  ShiftNavigation = () => {
    let self = this;
    let res = [];
    let bstyle = {width: 83, maxWidth: 83, minWidth: 0, flex: '1 0', height: 43, lineHeight: 43, paddingHorizontal: 0};
    if (this.display === 1) {
      res.push(
        <ActionButton
          onPress={() => {
            self.closeSingleCalDay();
          }}
          title={translate('Back')}
          style={bstyle}
        />
      );
    }
    let td = myDate.extractDateFromString(this.props.record.StartDate);
    if (td) {
      let year = td.getFullYear();
      let month = td.getMonth() + 1;
      if (this.display === 0) {
        let d = this.getRecordListPosition();
        if (d > 1) {
          res.push(
            <ActionButton
              onPress={() => {
                self.SwitchCalcMonth(d - 1);
              }}
              title={'<'}
              iconName={'chevron-left'}
              style={bstyle}
              iconColor={'white'}
            />
          );
        } else {
          res.push(<EmptyNavSpace key={d} />);
        }
        res.push(
          <NavTextWrap>
            <NavText>{year + '/' + this.ConvertTimeVal(month)}</NavText>
          </NavTextWrap>
        );
        if (d > this.props.list.length - 2) {
          res.push(<EmptyNavSpace />);
        } else {
          res.push(
            <ActionButton
              onPress={() => {
                self.SwitchCalcMonth(d + 1);
              }}
              title={'>'}
              iconName={'chevron-right'}
              style={bstyle}
              iconSize={15}
              iconColor={'white'}
            />
          );
        }
      } else {
        let d = this.state.CurDay;
        let mtot = myDate.getDaysInMonth2(td);
        if (d < 2) {
          res.push(<EmptyNavSpace />);
        } else {
          res.push(
            <ActionButton
              onPress={() => {
                self.openSingleCalDay(d - 1);
              }}
              title={'<'}
              iconName={'chevron-left'}
              style={bstyle}
              iconColor={'white'}
            />
          );
        }
        res.push(
          <NavTextWrap>
            <NavText>{year + '/' + this.ConvertTimeVal(month) + '/' + this.ConvertTimeVal(d)}</NavText>
          </NavTextWrap>
        );
        if (d < mtot) {
          res.push(
            <ActionButton
              onPress={() => {
                self.openSingleCalDay(d + 1);
              }}
              title={'>'}
              iconName={'chevron-right'}
              style={bstyle}
              iconColor={'white'}
            />
          );
        } else {
          res.push(<EmptyNavSpace />);
        }
      }
    }
    return res;
  };

  render() {
    let self = this;
    if (this.display === 0) {
      global.windowNewButton = <View style={{width: 100}}></View>;
    } else {
      global.windowNewButton = (
        <HeaderButtonContainer style={{width: 100}}>
          <ActionButton
            onPress={() => {
              self.closeSingleCalDay();
            }}
            title={translate('Back')}
          />
        </HeaderButtonContainer>
      );
    }
    global.setWindowTitle(translate('ShiftTable'));
    return (
      <ShiftWrapper>
        <NavigationWrap>
          <NavigationContent>{this.ShiftNavigation()}</NavigationContent>
        </NavigationWrap>
        {this.display === 0 && (
          <ShiftMonthCalendarWrapOuter>
            <ShiftMonthCalendarWrap>{this.showShiftMonthCalendar()}</ShiftMonthCalendarWrap>
          </ShiftMonthCalendarWrapOuter>
        )}
        {this.display === 1 && <ShiftDayCalendar>{this.ShowShiftDayCalendar()}</ShiftDayCalendar>}
      </ShiftWrapper>
    );
  }
}

export default ShiftCalendar;
