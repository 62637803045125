import {DateFormatOrigin, ViewMode} from '@components/screens/types';
import {
  FieldSection,
  PasteSpecialDisplayType,
  PasteSpecialWindowLayout,
  WindowDefinition2,
  WindowMatrix,
} from '@utils/types';
import {translate} from '@utils/languageTools';
import {DoNavigate, GetGlobalTheme, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {colours, fontWeight, spacing} from '@styles/Style';
import {ConfirmButton} from '@components/button/confirmButton';
import React from 'react';
import {getCellStyle} from '@components/editElements/inspectWindow/InspectWindow';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {APISupportsItem} from '@utils/drawersTools';
import {UnConfirmButton} from '@components/button/UnconfirmButton';

export class SalesOrdersRecordScreenDefinition extends RecordScreenWindowDefinition {
  getSideSectionFields(table: any): FieldSection[] {
    let sections: FieldSection[];
    sections = [
      {
        style: {padding: '0px'},
        groups: [
          {
            fields: [
              {
                label: translate('SalesMan'),
                dataKey: 'SalesMan',
                onChange: table.updateRecordField,
                pasteSpecial: {
                  vcName: 'UserVc',
                  windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                  mainKey: 'code',
                  toolTip: {hasToolTip: true, key: 'name', maxCharacters: 60},
                  columns: [
                    {
                      name: translate('Code'),
                      width: '35%',
                      key: 'code',
                      mainKey: 'Code',
                      displayType: PasteSpecialDisplayType.Name,
                    },
                    {
                      width: '65%',
                      name: translate('Name'),
                      key: 'name',
                      displayType: PasteSpecialDisplayType.Description,
                    },
                  ],
                },
              },
              {
                label: translate('Reserved'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'Reserved',
                onChange: table.updateRecordField,
              },
              {
                label: translate('InvBeforeShip'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'InvBeforeShip',
                onChange: table.updateRecordField,
              },
            ],
          },
        ],
      },
    ];
    return sections;
  }

  getHeaderFields(table: any, fullf = false): WindowDefinition2 {
    const self = this;
    let windowDefinition: WindowDefinition2;
    if (fullf == false && table.isNewRecord() && table.recordFieldFilled('CustCode') === false) {
      windowDefinition = {
        sectionTiles: [
          {
            fieldSections: [
              {
                label: translate('CustomerDetails'),
                groups: [
                  {
                    fields: [
                      {
                        label: translate('ChooseCustomer'),
                        dataKey: 'CustCode',
                        pasteSpecial: {
                          vcName: 'CUVc',
                          newRecord: true,
                          windowLayout: PasteSpecialWindowLayout.Default,
                          useAlternateDisplay: true,
                          viewModeDisplayFormat: '{name} ({code})',
                          mainKey: 'code',
                          nameDisplayFormat: '{name} ({code})',
                          columns: [
                            {
                              name: translate('CustomerName') + ' ' + translate('Code'),
                              width: '70%',
                              key: 'name',
                              mainKey: 'Code',
                              displayFormat: '{name} ({code})',
                            },
                            {
                              width: '30%',
                              name: translate('RegNr'),
                              key: 'regnr1',
                            },
                          ],
                        },
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('InvoiceInformation'),
                groups: [
                  {
                    style: {
                      background: colours[GetGlobalTheme()].grey100,
                      height: 90,
                    },
                    fields: [],
                  },
                ],
              },
              {
                label: translate('Items'),
                collapse: true,
                groups: [
                  {
                    style: {
                      background: colours[GetGlobalTheme()].grey100,
                      height: 90,
                    },
                    fields: [],
                  },
                ],
              },
            ],
          },
        ],
      };
    } else {
      windowDefinition = {
        operationMenu: [
          {
            label: translate('CreateInvoice'),
            onPress: table.createInvoice,
          },
          {
            label: translate('SendEmail'),
            onPress: table.sendEmailFromRecord,
          },
          {
            label: translate('CreateSigningActivity'),
            onPress: table.createSigningActivity,
          },
        ],
        startViewComponent: () =>
          table.record && table.allowsEditAndDelete() ? <ConfirmButton table={table} /> : null,
        endViewComponent: (inspectWindow) =>
          table.record && !table.allowsEditAndDelete()
            ? APISupportsItem('unok')
              ? UnConfirmButton(table, inspectWindow)
              : null
            : null,
        editable: table.record ? table.allowsEditAndDelete() : false,
        sectionTiles: [
          {
            fieldSections: [
              {
                label: translate('CustomerDetails'),
                collapse: true,
                groups: [
                  {
                    viewStyle: {
                      flex: '1',
                    },
                    fields: [
                      {
                        label: translate('CustCode'),
                        dataKey: 'CustCode',
                        pasteSpecial: {
                          vcName: 'CUVc',
                          newRecord: true,
                          windowLayout: PasteSpecialWindowLayout.Default,
                          useAlternateDisplay: true,
                          recordLinkFunction: () =>
                            DoNavigate(table, 'Contacts', true, table.record.headerFields.CustCode),
                          viewModeDisplayFormat: '{name} ({code})',
                          mainKey: 'code',
                          nameDisplayFormat: '{name} ({code})',
                          columns: [
                            {
                              name: translate('CustomerName') + ' ' + translate('Code'),
                              width: '70%',
                              key: 'name',
                              mainKey: 'Code',
                              displayFormat: '{name} ({code})',
                            },
                            {
                              width: '30%',
                              name: translate('RegNr'),
                              key: 'regnr1',
                            },
                          ],
                          pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
                          details: {
                            titleKey: 'name',
                            fields: [
                              {
                                label: translate('PrimaryContact'),
                                key: 'person',
                                type: WindowElementType.kInputTypeText,
                              },
                              {label: translate('Mobile'), key: 'mobile', type: WindowElementType.kInputTypeMobile},
                              {label: translate('PhoneNo'), key: 'phone', type: WindowElementType.kInputTypeMobile},
                              {label: translate('Email'), key: 'email', type: WindowElementType.kInputTypeEmail},
                            ],
                          },
                        },
                        onChange: table.updateRecordField,
                      },
                      {
                        line: [
                          {
                            label: translate('VATNr'),
                            dataKey: 'VATNr',
                            onChange: table.updateRecordField,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    divider: true,
                  },
                  {
                    viewStyle: {
                      flex: '1',
                      background: colours[GetGlobalTheme()].contentBG,
                    },
                    fields: [
                      {
                        label: translate('StreetName'),
                        hideLabelWhileView: true,
                        dataKey: 'Addr1',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Village'),
                        hideLabelWhileView: true,
                        dataKey: 'Addr2',
                        onChange: table.updateRecordField,
                      },

                      {
                        label: translate('City'),
                        hideLabelWhileView: true,
                        dataKey: 'Addr3',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('County'),
                        hideLabelWhileView: true,
                        dataKey: 'InvAddr3',
                        onChange: table.updateRecordField,
                      },
                      {
                        line: [
                          {
                            label: translate('PostIndex'),
                            hideLabelWhileView: true,
                            dataKey: 'InvAddr4',
                            onChange: table.updateRecordField,
                          },
                          {
                            label: translate('Country'),
                            hideLabelWhileView: true,
                            dataKey: 'InvCountry',
                            onChange: table.updateRecordField,
                            pasteSpecial: {
                              vcName: 'CountryVc',
                              windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                              useAlternateDisplay: true,
                              viewModeDisplayFormat: '{Comment}',
                              mainKey: 'Code',
                              columns: [
                                {
                                  name: translate('Code'),
                                  width: '35%',
                                  key: 'Code',
                                  mainKey: 'Code',
                                  displayType: PasteSpecialDisplayType.Name,
                                },
                                {
                                  name: translate('Name'),
                                  width: '65%',
                                  key: 'Comment',
                                  displayType: PasteSpecialDisplayType.Description,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('DeliveryAddress'),
                collapse: true,
                groups: [
                  {
                    viewStyle: {
                      flex: '1',
                    },
                    fields: [
                      {
                        label: translate('AddressCode'),
                        dataKey: 'DelAddrCode',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'DelAddrVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'DelCode',
                              mainKey: 'DelCode',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Name'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                          filter: table.record ? {CustCode: table.record.headerFields.CustCode} : {},
                        },
                      },
                      {
                        label: translate('DeliveryAddress'),
                        dataKey: 'ExcDelAddrComment',
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                  {
                    divider: true,
                  },
                  {
                    viewStyle: {
                      flex: '1',
                      background: colours[GetGlobalTheme()].contentBG,
                    },
                    fields: [
                      {
                        label: translate('StreetName'),
                        hideLabelWhileView: true,
                        dataKey: 'ShipAddr1',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Village'),
                        hideLabelWhileView: true,
                        dataKey: 'ShipAddr2',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('City'),
                        hideLabelWhileView: true,
                        dataKey: 'ShipAddr3',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('County'),
                        hideLabelWhileView: true,
                        dataKey: 'DelAddr3',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('PostIndex'),
                        hideLabelWhileView: true,
                        dataKey: 'DelAddr4',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Country'),
                        hideLabelWhileView: true,
                        dataKey: 'DelCountry',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'CountryVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          useAlternateDisplay: true,
                          viewModeDisplayFormat: '{Comment}',
                          mainKey: 'Code',
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              name: translate('Name'),
                              width: '65%',
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('SalesOrderInformation'),
                collapse: true,
                groups: [
                  {
                    fields: [
                      {
                        label: translate('SerNr'),
                        dataKey: 'SerNr',
                        viewOnly: true,
                      },
                      {
                        label: translate('OrdDate'),
                        windowElementType: WindowElementType.kInputTypeDate,
                        dataKey: 'OrdDate',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('PayDeal'),
                        dataKey: 'PayDeal',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'PDVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'pdComment', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Name'),
                              key: 'pdComment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('PlanShip'),
                        windowElementType: WindowElementType.kInputTypeDate,
                        dateFormatOrigin: DateFormatOrigin.Database,
                        allowManualEntry: true,
                        dataKey: 'PlanShip',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('OrderClass'),
                        dataKey: 'OrderClass',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'OrderClassVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    divider: true,
                  },
                  {
                    fields: [
                      {
                        label: translate('Objects'),
                        dataKey: 'Objects',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'ObjVc',
                          multiValue: true,
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          keyToDisplayInViewMode: ['Code', 'Comment'],
                          toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 40},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('Location'),
                        dataKey: 'Location',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'LocationVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'Name', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Name',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('Language'),
                        dataKey: 'LangCode',
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'LangBlock',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('CustOrdNr'),
                        dataKey: 'CustOrdNr',
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Comment'),
                        dataKey: 'Comment',
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('Items'),
                collapse: true,
                listStyle: {alignItems: 'end', flexDirection: 'column'},
                groups: [
                  {
                    matrix: self.getMatrixFields(table),
                  },
                  {
                    style: {
                      width: '230px',
                      background: colours[GetGlobalTheme()].contentBG,
                      padding: spacing.space12,
                    },
                    fields: [
                      {
                        label: translate('Subtotal'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum1',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Currency'),
                        dataKey: 'CurncyCode',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('VAT'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum3',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Total'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum4',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    }

    if (!isMobileOrTabletScreen()) {
      return windowDefinition;
    }

    if (!self.isEmptyNewRecord(fullf, table)) {
      if (windowDefinition?.sectionTiles[0]?.fieldSections[1]?.groups[2]?.fields) {
        windowDefinition.sectionTiles[0].fieldSections[1].groups[2].fields.splice(1, 0, {
          label: translate('SalesMan'),
          dataKey: 'SalesMan',
          pasteSpecial: {
            vcName: 'UserVc',
            newRecord: true,
            hideComment: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        });
      }
    }

    return windowDefinition;
  }

  getMatrixFields(table: any): WindowMatrix {
    let windowMatrix: WindowMatrix = [];
    windowMatrix.push({
      label: translate('Item'),
      width: isMobileOrTabletScreen() ? '100%' : '440px',
      cellStyle: {
        flexDirection: 'column',
        border: isMobileOrTabletScreen() ? 'solid' : 'none',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: colours[GetGlobalTheme()].grey400,
        paddingBottom: isMobileOrTabletScreen() ? spacing.space12 : null,
      },
      singleColumn: [
        {
          label: translate('Item'),
          dataKey: 'ArtCode',
          width: isMobileOrTabletScreen() ? '100%' : '440px',
          style: (viewMode) => (viewMode === ViewMode.View ? {fontWeight: fontWeight.bodyBold} : {}),
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'INVc',
            hideRemoveOption: true,
            windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
                displayType: PasteSpecialDisplayType.Name,
                tagKey: 'uprice1',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
                displayType: PasteSpecialDisplayType.Description,
              },
            ],
          },
        },
        {
          label: translate('Description'),
          style: (viewMode) => {
            let style: React.CSSProperties = {color: colours[GetGlobalTheme()].label};
            if (viewMode === ViewMode.View) {
              style.whiteSpace = 'break-spaces';
            }
            return style;
          },
          dataKey: 'Spec',
          width: isMobileOrTabletScreen() ? '100%' : '440px',
          onChange: table.updateRecordField,
        },
      ],
    });
    windowMatrix.push({
      label: translate('Price'),
      dataKey: 'Price',
      windowElementType: WindowElementType.kInputTypeValFull,
      width: isMobileOrTabletScreen() ? '30%' : '110px',
      onChange: table.updateRecordField,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: 'right'};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });

    windowMatrix.push({
      label: translate('Quant'),
      dataKey: 'Quant',
      windowElementType: WindowElementType.kInputTypeVal,
      width: isMobileOrTabletScreen() ? '25%' : '90px',
      onChange: table.updateRecordField,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: 'right'};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });
    windowMatrix.push({
      label: translate('Discount'),
      dataKey: 'vRebate',
      windowElementType: WindowElementType.kInputTypeVal,
      width: isMobileOrTabletScreen() ? '25%' : '90px',
      onChange: table.updateRecordField,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: 'right'};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });

    windowMatrix.push({
      label: translate('Total'),
      dataKey: 'Sum',
      windowElementType: WindowElementType.kInputTypeValFull,
      width: isMobileOrTabletScreen() ? '30%' : '110px',
      onChange: table.updateRecordField,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: 'right'};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });
    windowMatrix.push({
      label: translate('VATCode'),
      dataKey: 'VATCode',
      display: false,
    });
    return windowMatrix;
  }

  isEmptyNewRecord(fullf, table) {
    return fullf == false && table.isNewRecord() && table.recordFieldFilled('CustCode') === false;
  }
}
