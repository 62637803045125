import React from 'react';
import {translate} from '@src/utils/languageTools';
import AlertWindow, {AlertType} from '@components/design/AlertWindow';
import {RowContainer} from '@src/styles/primitiveComponents';
import {isMobileOrTabletScreen} from '@tools/displayTools';

const AlertContainerStyle = {
  position: 'absolute',
  top: 92,
  left: isMobileOrTabletScreen() ? '25%' : '10%',
  height: '60px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '78%',
};

export const NoAccess = () => {
  return (
    <RowContainer style={AlertContainerStyle}>
      <AlertWindow message={translate('NoPermission')} width={'100%'} alertType={AlertType.Warning} show={true} />
    </RowContainer>
  );
};
