import React, {useState} from 'react';
import styled from 'styled-components/native';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {ColumnContainer, RowContainer} from '@styles/primitiveComponents';
import {translate} from '@src/utils/languageTools';
import {ActionButton} from '@components/button/ActionButton';
import {Modal, Platform, ScrollView, useWindowDimensions} from 'react-native';
import {AccessDenied} from '@src/components/screens/status/AccessDenied';
import {ViewMode} from '@src/components/screens/types';

const Wrapper = styled.View<{columnDisplay: boolean}>`
  background-color: ${colours[GetGlobalTheme()].grey200};
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.space12}px;
  padding: ${spacing.space8}px;
  margin: ${spacing.space4}px 0;
  width: ${(props) => (props.columnDisplay ? 'calc(33% - 4px)' : '100%')};
`;

const IconWrapper = styled.View`
  justify-content: center;
  align-items: center;
  width: 48px;
`;

const RecordLinkButtonStyle = {
  width: '100%',
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'start',
  marginRight: 'auto',
  padding: 0,
};

const RecordLinkButtonTextStyle = {
  color: colours[GetGlobalTheme()].lightBlue,
  marginRight: 48,
  justifyContent: 'start',
};

const ModalBackground = styled(ColumnContainer)`
  background-color: rgba(63, 63, 63, 0.4);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled(ColumnContainer)`
  background-color: ${colours[GetGlobalTheme()].white};
  border-radius: 12px;
  width: 90%;
  max-width: 820px;
  max-height: 90%;
  flex-shrink: 1;
`;

const FileHeaderContainer = styled(RowContainer)`
  padding: ${spacing.space16}px ${spacing.space24}px;
`;

const ClosePreviewButtonStyle = {
  padding: 0,
  margin: 0,
  width: 30,
  height: 30,
  borderRadius: 8,
  marginLeft: 'auto',
  border: 'none',
};

const PreviewContainer = styled.View<{containerHeight: number}>`
  background-color: ${colours[GetGlobalTheme()].contentBG};
  padding: ${spacing.space8}px;
  min-height: ${(props) => props.containerHeight}px;
  max-height: 600px;
  justify-content: center;
  align-items: center;
`;

const FooterContainer = styled(RowContainer)`
  padding: ${spacing.space16}px ${spacing.space24}px;
  width: 100%;
  flex-shrink: 0;
`;

type NotepadProps = {
  name: string;
  wideView: boolean;
  viewMode: ViewMode;
  vcName: string;
};

export const NotSupportedFile = ({name, wideView, viewMode, vcName}: NotepadProps) => {
  const [accessDeniedVisible, setAccessDeniedVisible] = useState(false);
  const {width, height} = useWindowDimensions();

  const isDesktop = Platform.OS === 'web' && (width > 800 || height > 600);

  function getPreviewContainerHeight() {
    return isDesktop ? height * 0.7 : width > height ? 220 : height * 0.5;
  }

  const containerHeight = getPreviewContainerHeight();

  const nameLengthLimit = 25;
  const shortRecordLinkNameSuffix = '...';

  function getIconName() {
    switch (vcName) {
      case 'NotepadVc':
        return 'NotepadIcon';
      case 'ArchiveVc':
        return 'FolderIcon';
      default:
        return 'DefaultFileIcon';
    }
  }

  function getRecordName() {
    return name?.length > nameLengthLimit
      ? `${name.slice(0, nameLengthLimit)}${shortRecordLinkNameSuffix}`
      : name || translate(vcName);
  }

  function toggleAccessDenied() {
    setAccessDeniedVisible(!accessDeniedVisible);
  }

  return (
    <>
      <Wrapper columnDisplay={wideView && viewMode === ViewMode.Edit}>
        <IconWrapper>
          <PortalIcon iconName={getIconName()} iconHeight={24} iconColor={colours[GetGlobalTheme()].lightBlue} />
        </IconWrapper>
        <ActionButton
          style={RecordLinkButtonStyle}
          textStyle={RecordLinkButtonTextStyle}
          title={getRecordName()}
          onPress={toggleAccessDenied}
        />
      </Wrapper>

      {accessDeniedVisible && (
        <Modal transparent>
          <ModalBackground>
            <Container>
              <ScrollView>
                <FileHeaderContainer>
                  <ActionButton
                    style={ClosePreviewButtonStyle}
                    backgroundColor={colours[GetGlobalTheme()].grey200}
                    hoverColor={colours[GetGlobalTheme()].grey400}
                    title=""
                    iconName="CrossIcon"
                    onPress={toggleAccessDenied}
                  />
                </FileHeaderContainer>
                <PreviewContainer containerHeight={containerHeight}>
                  <AccessDenied header="" subtext={translate('NotSupported')} subtext2="" />
                </PreviewContainer>
                <FooterContainer />
              </ScrollView>
            </Container>
          </ModalBackground>
        </Modal>
      )}
    </>
  );
};
