import React, {Component} from 'react';
import {View, ViewStyle} from 'react-native';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, fontWeight, iconSize, spacing} from '@styles/Style';
import {ConvertDisplayValue, GetGlobalTheme, IsHRWeb, UsePagination} from '../../../Tools/DisplayTools';
import Requests from '../../../api/Requests';
import {DataTable} from 'react-native-paper';
import {translate} from '../../../Tools/LanguageTools';
import EditField from '../editElements/EditField';
import {GetBrowseWindowDetails, GetWindowSearch, UpdateWindowDefaults} from '@tools/windowTools';
import {log, logError, LogType} from '@utils/debug';
import {Pagination} from '../../../components/edit_elements/Matrix';
import {OverwriteBrowseColumnProps, TableContainerProps} from '@utils/types';
import {ActionButton} from '../button/ActionButton';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {
  ColumnContainer,
  RowContainer,
  TextNormal,
  TextNormalBold,
  TextSmallBold,
} from '@src/styles/primitiveComponents';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import {IconWrapper} from '../fileUploader/FileUploader.styles';
import {objectHasProperty} from '@tools/objectTools';
import {isDesktopScreen, isMobileOrTabletScreen, ScreenWidth} from '@tools/displayTools';
import {DateRangePicker} from '../datePicker/DateRangePicker';

const FooterContainer = styled.View`
  padding: 0px 22px;
`;

const HeaderContainer = styled(ColumnContainer)`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  z-index: 1;
`;

const FilterWrap = styled(RowContainer)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 30px;
  align-self: stretch;
`;

const MobileFilterWrap = styled(RowContainer)`
  display: flex;
  align-items: center;
  gap: ${spacing.space4}px;
  align-self: stretch;
  padding-top: ${spacing.space4}px;
  padding-bottom: ${spacing.space8}px;
  overflow-x: scroll;
`;

const FilterButton = styled.TouchableOpacity`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid #081d42;
  border-radius: 50px;
  background-color: ${(props) => (props.active ? colours[GetGlobalTheme()].darkBlue : 'transparent')};
  border-color: ${(props) => (props.active ? colours[GetGlobalTheme()].darkBlue : colours[GetGlobalTheme()].darkBlue)};
`;

const SearchWrap = styled(RowContainer)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  z-index: 999;
`;

const MobileSearchWrap = styled(RowContainer)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: ${spacing.space12}px;
  align-self: stretch;
  z-index: 999;
`;

const BodyContainer = styled.View`
  background-color: ${colours[GetGlobalTheme()].tableBackground};
  font-weight: 600;
  overflow-y: auto;
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  width: 100%;
  border-radius: 4px;
  margin-horizontal: ${spacing.space8};
`;

const StyledTitle = styled(DataTable.Cell)`
  min-width: ${(props) => props.columnWidth};
  height: 45px;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 12px;
  background: ${colours[GetGlobalTheme()].grey100};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const StyledCell = styled(DataTable.Cell)`
  min-width: ${(props) => props.columnWidth};
  text-align: ${(props) => (props.alignment ? props.alignment : '')};
  height: 61px;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  // background: ${colours[GetGlobalTheme()].white};
  gap: 4px;
  display: flex;
  border-bottom: 1px solid ${colours[GetGlobalTheme()].shadowDefault};
`;

const MobileRowStyle = {
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: spacing.space4,
  marginBottom: spacing.space4,
  borderStyle: 'solid',
  paddingBottom: spacing.space8,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: colours[GetGlobalTheme()].grey300,
  cursor: 'pointer',
};

const MobileColumnStyle = {width: '50%', gap: spacing.space8, height: '100%', justifyContent: 'space-around'};

const MobileNormalText = {
  color: colours[GetGlobalTheme()].body,
  fontWeight: fontWeight.body,
  fontSize: fontSize.body,
};

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].navigationBackground};
  padding-vertical: 0px;
  ${(IsHRWeb() &&
    css`
      padding-horizontal: ${spacing.space20};
    `) ||
  css`
    padding-horizontal: ${spacing.space12};
  `}
  border-bottom-width: 0px;
`;

const SearchBarWrapper = {
  flexShrink: 1,
  padding: 0,
};

const SearchIconStyle = {
  order: -1,
  marginRight: spacing.space8,
};

const SearchBar = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  borderRadius: 8,
  borderWidth: 1,
  margin: 0,
  padding: spacing.space12,
  paddingLeft: spacing.space32,
  width: '100%',
};

type MobileRowData = {
  title: JSX.Element;
  underTitle: JSX.Element;
  rightTitle: JSX.Element;
  rightUnderTitle: JSX.Element;
  onPress: () => void;
};

function Cell(props) {
  const alignmentStyle = IsHRWeb() ? {textAlign: props.alignment || 'left'} : {};

  return <div style={alignmentStyle}>{props.children}</div>;
}

function CellInner(props) {
  const alignmentStyle = IsHRWeb() ? {textAlign: props.alignment || 'left', overflow: 'hidden'} : {};

  return <div style={alignmentStyle}>{props.children}</div>;
}

export class RecordList extends Component<TableContainerProps> {
  pagination: {
    page: number;
    perpage: number;
    pages: number;
    showperiod: boolean;
    changeMatrixPage: any;
    changePagePeriod: any;
  } = {
    page: 0,
    perpage: this.props.perPage ?? global.PerPage2,
    pages: 0,
    showperiod: false,
  };
  sorting: string;
  curdir: string;
  cursearch: string;
  curstartdate: string;
  curenddate: string;
  activeFilters: Array<string>;

  constructor(props) {
    super(props);
    let self = this;
    let displayprops = GetBrowseWindowDetails(this.props.windowName);
    this.sorting = displayprops.cursort;
    this.curdir = displayprops.curdir;
    this.cursearch = displayprops.search;
    this.curstartdate = displayprops.curstartdate;
    this.curenddate = displayprops.curenddate;
    this.activeFilters = displayprops.filters;
    this.pagination.changeMatrixPage = function (page) {
      self.changePage(page);
    };
    this.pagination.changePagePeriod = function (value) {
      self.changePagePeriod(value);
    };

    this.state = {
      data: props.data || [],
      update: false,
      activeID: '',
      openDatePicker: false,
      mobileCreateInvoicePosition: {left: 0, top: 0, width: 0, height: 0},
    };
  }

  componentDidMount() {
    this.GetTableData();
  }
  changePage = (page) => {
    let npagination = this.pagination;
    npagination.page = page;
    this.GetTableData();
  };
  changePagePeriod = (value) => {
    let npagination = this.pagination;
    npagination.perpage = value;
    npagination.page = 0;
    this.setState({pagination: npagination});
  };

  componentDidUpdate(prevprops) {
    if (prevprops.data !== this.props.data && !this.props.records) {
      this.setState({data: this.props.data});
    }
    if (UsePagination()) {
      if (prevprops.update !== this.props.update) {
        this.GetTableData();
      }
    }
  }

  GetTableData = () => {
    let self = this;
    let search = this.cursearch;
    if (search && search.length < 2) {
      search = '';
    }
    if (this.props.records) {
      const records = this.props.records;
      self.pagination.pages = Math.ceil(records.length / self.pagination.perpage);
      self.setState({
        data: records,
        update: !self.update,
      });
      return;
    }

    Requests.getTableData(
      this.props.table,
      undefined,
      undefined,
      undefined,
      true,
      this.pagination.page,
      this.pagination.perpage,
      this.sorting,
      this.curdir,
      this.cursearch,
      this.curstartdate,
      this.curenddate,
      this.activeFilters.join(',') || ''
    )
      .then((response) => {
        self.pagination.pages = Math.ceil(response.total / self.pagination.perpage);
        log('List: ', response.records, LogType.listWindowLog);
        self.setState({
          data: response.records,
          update: self.update ? false : true,
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  getDesktopFiltersComponent(filters) {
    const self = this;
    return (
      <FilterWrap style={{width: '50%'}}>
        <TextSmallBold style={{color: colours[GetGlobalTheme()].grey800}}>{translate('Filters')}:</TextSmallBold>
        {filters.map((el, index) => {
          return (
            <FilterButton
              active={this.activeFilters.indexOf(el.id) > -1}
              onPress={() => {
                let pos = self.activeFilters.indexOf(el.id);
                if (pos > -1) {
                  self.activeFilters.splice(pos, 1);
                } else {
                  self.activeFilters.push(el.id);
                }
                self.GetTableData();
              }}>
              <RowContainer style={{alignItems: 'center', gap: '2px'}}>
                {this.activeFilters.indexOf(el.id) > -1 && (
                  <IconWrapper style={{flexShrink: 1}}>
                    <PortalIcon iconName={'CheckboxIcon'} iconHeight={16} iconColor={colours[GetGlobalTheme()].white} />
                  </IconWrapper>
                )}
                <TextNormal
                  style={{
                    color:
                      this.activeFilters.indexOf(el.id) > -1
                        ? colours[GetGlobalTheme()].white
                        : colours[GetGlobalTheme()].darkBlue,
                  }}>
                  {el.name}
                </TextNormal>
              </RowContainer>
            </FilterButton>
          );
        })}
      </FilterWrap>
    );
  }

  getMobileFiltersComponent(filters) {
    const self = this;
    return (
      <MobileFilterWrap style={{width: '100%'}}>
        {filters.map((el, index) => {
          return (
            <FilterButton
              active={this.activeFilters.indexOf(el.id) > -1}
              onPress={() => {
                let pos = self.activeFilters.indexOf(el.id);
                if (pos > -1) {
                  self.activeFilters.splice(pos, 1);
                } else {
                  self.activeFilters.push(el.id);
                }
                self.GetTableData();
              }}>
              <RowContainer style={{alignItems: 'center', gap: '2px'}}>
                {this.activeFilters.indexOf(el.id) > -1 && (
                  <IconWrapper style={{flexShrink: 1}}>
                    <PortalIcon iconName={'CheckboxIcon'} iconHeight={16} iconColor={colours[GetGlobalTheme()].white} />
                  </IconWrapper>
                )}
                <TextNormal
                  style={{
                    color:
                      this.activeFilters.indexOf(el.id) > -1
                        ? colours[GetGlobalTheme()].white
                        : colours[GetGlobalTheme()].darkBlue,
                  }}>
                  {el.name}
                </TextNormal>
              </RowContainer>
            </FilterButton>
          );
        })}
      </MobileFilterWrap>
    );
  }

  RecordListFilters = function (filters) {
    const self = this;
    if (isMobileOrTabletScreen()) {
      return self.getMobileFiltersComponent(filters);
    }

    return self.getDesktopFiltersComponent(filters);
  };
  handleDateFilter = (startDate, endDate) => {
    this.curstartdate = startDate;
    this.curenddate = endDate;
    this.GetTableData();
  };

  getDesktopSearchComponent() {
    const self = this;
    return (
      <SearchWrap>
        <EditField
          defaultValue={GetWindowSearch()}
          placeholder={translate('Search')}
          placeholdercolor={colours[GetGlobalTheme()].label}
          width={'100%'}
          onChangeText={(text) => {
            self.cursearch = text.toLowerCase();
            self.pagination.page = 0;
            self.GetTableData();
          }}
          inputStyle={SearchBar}
          wrapperStyle={SearchBarWrapper}
          iconName={'SearchIcon'}
          iconStyle={SearchIconStyle}
          iconHeight={iconSize.iconSize}
        />
        {this.props.extraFilter && this.props.extraFilter(this)}
        {this.props.hasDateFilter && (
          <DateRangePicker
            action={(startDate, endDate) => {
              this.handleDateFilter(startDate, endDate);
            }}
            startDate={this.curstartdate}
            endDate={this.curenddate}
          />
        )}
        {this.props.addNewTitle && isDesktopScreen && (
          <ActionButton
            onPress={() => self.props.doClick('new')}
            title={self.props.addNewTitle}
            style={{
              width: '20%',
              maxWidth: '20%',
              flexShrink: 0,
              height: 34,
            }}
            textStyle={{fontSize: 14}}
          />
        )}
      </SearchWrap>
    );
  }

  getMobileSearchComponent() {
    const self = this;
    return (
      <MobileSearchWrap>
        <EditField
          defaultValue={GetWindowSearch()}
          placeholder={translate('Search')}
          placeholdercolor={colours[GetGlobalTheme()].label}
          width={'100%'}
          onChangeText={(text) => {
            self.cursearch = text.toLowerCase();
            self.pagination.page = 0;
            self.GetTableData();
          }}
          inputStyle={SearchBar}
          wrapperStyle={SearchBarWrapper}
          iconName={'SearchIcon'}
          iconStyle={SearchIconStyle}
          iconHeight={iconSize.iconSize}
        />
        {this.props.extraFilter && this.props.extraFilter(this)}
        {this.props.hasDateFilter && (
          <DateRangePicker
            action={(startDate, endDate) => this.handleDateFilter(startDate, endDate)}
            startDate={this.curstartdate}
            endDate={this.curenddate}
          />
        )}
      </MobileSearchWrap>
    );
  }

  TableHeader() {
    return (
      <View style={{zIndex: 1, width: '100%'}}>
        <HeaderContainer>
          {this.getSearchComponent()}
          {this.props.filters && this.RecordListFilters(this.props.filters)}
        </HeaderContainer>
      </View>
    );
  }

  getSearchComponent() {
    let searchComponent = <></>;
    if (this.props.hasSearch !== false) {
      searchComponent = isMobileOrTabletScreen() ? this.getMobileSearchComponent() : this.getDesktopSearchComponent();
    }

    return searchComponent;
  }

  isColumnDataDefined = (column): boolean => {
    if (column.checkUndefined) {
      if (this.state.data.length > 0) {
        if (objectHasProperty(this.state.data[0], column.key)) {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  TableColumns = () => {
    let res = [];
    let self = this;
    let cursort = self.sorting;
    this.props.browseColumns.map((column, index) => {
      if (self.isColumnDataDefined(column)) {
        let curdir = '';
        let newdir = 'ascending';
        if (cursort === column.sortKey) {
          curdir = self.curdir;
          newdir = curdir === 'ascending' ? 'descending' : 'ascending';
        }
        let alignment;
        if (column.alignment) {
          alignment = 'justify-content: ' + column.alignment;
        }
        let onPress = undefined;
        if (column.sortKey !== undefined) {
          onPress = () => {
            self.sorting = column.sortKey;
            self.curdir = newdir;
            UpdateWindowDefaults(self.props.windowName, self.sorting, self.curdir);
            self.GetTableData();
          };
        }
        res.push(
          <StyledTitle key={index} columnWidth={column.width} alignment={alignment} onPress={onPress}>
            <RowContainer style={{alignItems: 'center', gap: '2px'}}>
              <TextNormalBold style={{color: colours[GetGlobalTheme()].grey800}}>{column.name}</TextNormalBold>
              {column.sortKey !== undefined && !this.props.disableSorting && (
                <IconWrapper style={{flexShrink: 1}}>
                  {curdir === 'ascending' ? (
                    <PortalIcon iconName={'UpsideIcon'} iconHeight={6} iconColor={colours[GetGlobalTheme()].grey600} />
                  ) : curdir === 'descending' ? (
                    <PortalIcon
                      iconName={'DropdownIcon'}
                      iconHeight={6}
                      iconColor={colours[GetGlobalTheme()].grey600}
                    />
                  ) : (
                    <>
                      <PortalIcon
                        iconName={'UpsideIcon'}
                        iconHeight={6}
                        iconColor={colours[GetGlobalTheme()].grey600}
                      />
                      <PortalIcon
                        iconName={'DropdownIcon'}
                        iconHeight={6}
                        iconColor={colours[GetGlobalTheme()].grey600}
                      />
                    </>
                  )}
                </IconWrapper>
              )}
            </RowContainer>
          </StyledTitle>
        );
      }
    });
    return res;
  };

  getStyledRowStyle(index: number, row, arrayLength: number): ViewStyle {
    const isActive = this.state.activeID === row[this.props.rowID];
    let backgroundColor: string;
    if (isActive) {
      backgroundColor = colours[GetGlobalTheme()].activeBackground;
    } else if (index % 2) {
      backgroundColor = colours[GetGlobalTheme()].inputBackground;
    } else {
      backgroundColor = 'transparent';
    }
    const marginBottom = index === arrayLength - 1 && arrayLength % 2 === 0 ? 16 : 0;

    return {
      flexDirection: 'column',
      flexShrink: 0,
      backgroundColor: backgroundColor,
      marginVertical: 0,
      marginHorizontal: isMobileOrTabletScreen() ? 0 : spacing.space12,
      paddingHorizontal: isMobileOrTabletScreen() ? 0 : spacing.space8,
      paddingVertical: 0,
      borderRadius: 6,
      justifyContent: 'center',
      borderBottomWidth: 0,
      marginBottom: marginBottom,
      width: '100%',
    };
  }

  TableData = () => {
    let res = [];
    let tdata = this.state.data;
    if (tdata) {
      tdata.map((row, index) => {
        res.push(
          <DataTable.Row style={this.getStyledRowStyle(index, row, tdata.length)} key={index}>
            {this.TableCell({row: row, rowindex: index})}
          </DataTable.Row>
        );
      });
    }
    return res;
  };

  FormatBrowseField = (column, value, value2 = '') => {
    let res = value;

    if (column.type !== undefined) {
      res = ConvertDisplayValue(column.type, value, value2);
    }
    return res;
  };

  getMobileOverwriteComponent(row, rowindex, column, index) {
    let overwriteParams: OverwriteBrowseColumnProps = {
      id: row[this.props.rowID],
      value: row[column.key],
      rowindex: rowindex,
      colindex: index,
      row: row,
      column: column,
    };

    return column.overwrite(overwriteParams);
  }

  mobileTableCellComponent(rowData: MobileRowData) {
    return (
      <View style={MobileRowStyle} onClick={rowData.onPress}>
        <View style={{...MobileColumnStyle, ...{alignItems: 'flex-start'}}}>
          {rowData.title}
          {rowData.underTitle}
        </View>

        <View style={{...MobileColumnStyle, ...{alignItems: 'flex-end'}}}>
          {rowData.rightTitle}
          {rowData.rightUnderTitle}
        </View>
      </View>
    );
  }

  getMobileTableCell(row, rowindex) {
    const self = this;
    const rowData: MobileRowData = {
      title: <></>,
      underTitle: <></>,
      rightTitle: <></>,
      rightUnderTitle: <></>,
      onPress: null,
    };

    self.props.browseColumns.forEach((column, index) => {
      let text = '';
      let component = <></>;

      if (objectHasProperty(column, 'overwrite')) {
        component = this.getMobileOverwriteComponent(row, rowindex, column, index);
      } else {
        text = self.FormatBrowseField(column, row[column.key], row[column.key2]);
        component = <View style={MobileNormalText}>{text}</View>;
      }

      const shouldHaveDoClick = self.props.doClick !== undefined && index === 0;

      if (shouldHaveDoClick) {
        rowData.onPress = () => {
          try {
            self.setState({activeID: row[self.props.rowID]});
            self.props.doClick(row[self.props.rowID]);
          } catch (error) {
            logError(error);
          }
        };
      }

      rowData[column.mobileDisplayType] = component;
    });

    return self.mobileTableCellComponent(rowData);
  }

  getDesktopTableCell(row, rowindex) {
    const self = this;
    const tableCell = [];
    this.props.browseColumns.map((column, index) => {
      if (IsHRWeb() || index < 3) {
        let onpress = undefined;
        let cellStyle = {};
        let text = self.FormatBrowseField(column, row[column.key], row[column.key2]);

        let alignment;
        if (column.alignment) {
          alignment = column.alignment;
        }
        let shouldHaveOnPress = true;
        if (this.props.onlyAllowMainColumnClick === true && column.key !== this.props.rowID) {
          shouldHaveOnPress = false;
        }

        if (this.props.doClick !== undefined && shouldHaveOnPress) {
          cellStyle = {cursor: 'pointer', alignText: alignment};
          onpress = () => {
            try {
              this.setState({activeID: row[this.props.rowID]});
              this.props.doClick(row[this.props.rowID]);
            } catch (error) {
              logError(error);
            }
          };
        }
        if (column.checkUndefined === undefined || objectHasProperty(row, column.key)) {
          tableCell.push(
            <StyledCell
              key={index}
              columnWidth={column.width}
              minwidth={column.minwidth}
              maxwidth={column.maxwidth}
              alignment={alignment}
              textStyle={{flex: 1, overflow: 'visible'}}
              style={cellStyle}
              onPress={onpress}>
              {column.key === this.props.rowID ? (
                <TouchableOpacity style={{color: 'blue'}}>
                  {column.overwrite?.({
                    id: row[this.props.rowID],
                    value: row[column.key],
                    rowindex: rowindex,
                    colindex: index,
                    row: row,
                  }) || <TextNormalBold style={{color: colours[GetGlobalTheme()].lightBlue}}>{text}</TextNormalBold>}
                </TouchableOpacity>
              ) : (
                <Cell column={column} text={text} alignment={alignment}>
                  {column.overwrite?.({
                    id: row[this.props.rowID],
                    value: row[column.key],
                    rowindex: rowindex,
                    colindex: index,
                    row: row,
                  }) || (
                    <CellInner>
                      <TextNormal style={{color: colours[GetGlobalTheme()].grey700}}>{text}</TextNormal>
                    </CellInner>
                  )}
                </Cell>
              )}
            </StyledCell>
          );
        }
      }
    });
    return tableCell;
  }

  TableCell = ({row, rowindex}) => {
    if (isMobileOrTabletScreen()) {
      return this.getMobileTableCell(row, rowindex);
    }
    return this.getDesktopTableCell(row, rowindex);
  };

  TableBody = () => {
    let pd = 10;
    if (IsHRWeb()) {
      pd = '10px';
    }

    let bodyStyle = this.props.bodyStyle || {};
    bodyStyle = {...bodyStyle, backgroundColor: colours[GetGlobalTheme()].white};

    return (
      <BodyContainer style={bodyStyle}>
        <DataTable style={{overflowX: isMobileOrTabletScreen() ? 'hidden' : 'scroll'}}>
          {isDesktopScreen() && <StyledHeader style={this.props.headerStyle}>{this.TableColumns()}</StyledHeader>}
          <View style={{paddingBottom: pd}}>{this.TableData()}</View>
        </DataTable>
        {this.props.hasPagination !== false && this.TableFooter()}
      </BodyContainer>
    );
  };

  TableFooter = () => {
    return (
      <FooterContainer>
        <Pagination pagination={this.pagination} data={this.state.data} />
      </FooterContainer>
    );
  };

  getTableWrapperStyle(): ViewStyle {
    const mobileStyle: ViewStyle = {
      paddingBottom: 94,
    };

    return {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      width: '100%',
      height: 'auto',
      padding: spacing.space24,
      alignItems: isMobileOrTabletScreen() ? 'center' : 'flex-start',
      gap: 20,
      borderRadius: spacing.space12,
      backgroundColor: isMobileOrTabletScreen() ? colours[GetGlobalTheme()].white : colours[GetGlobalTheme()].contentBG,
      ...mobileStyle,
    };
  }

  render() {
    if (isMobileOrTabletScreen()) {
      if (this.props.addNewTitle) {
        global.setMobileBottomButton(true, [
          <ActionButton
            onPress={() => this.props.doClick('new')}
            title={this.props.addNewTitle}
            style={{
              width: '100%',
              flexShrink: 1,
              margin: 6,
            }}
            textStyle={{fontSize: 14}}
          />,
        ]);
      }
    }
    return (
      <View
        style={{...this.getTableWrapperStyle(), ...this.props.wrapperStyle}}
        screenWidth={isMobileOrTabletScreen() ? ScreenWidth() : '100%'}
        isMobileOrTabletScreen={isMobileOrTabletScreen()}>
        {this.TableHeader()}
        {this.TableBody()}
      </View>
    );
  }
}
