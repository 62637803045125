import {translate} from '@utils/languageTools';
import {DisplayBrowseClosed, IsHRWeb, WindowElementType} from '../../../Tools/DisplayTools';
import {GetWindowFilter} from '../../../Tools/WindowTools';
import {ListWindowColumn, WindowDefinition} from '@utils/types';

const contractHeaderFields_LAT = (table): WindowDefinition => {
  return [
    {
      fields: [
        {
          label: translate('ContractNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ContractNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Employee'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCode',
          viewOnly: false,
          width: '400px',
          pasteSpecial: {
            vcName: 0,
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'empcode',
                mainKey: 'EmpCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'ContractDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('StartDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'StartDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('EndDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'EndDate',
          viewOnly: false,
          width: '150px',
          allowClearing: true,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'DepCode',
                mainKey: 'DepCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('HourlyRate'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'TSTaxCode',
          viewOnly: false,
          width: '180px',
          pasteSpecial: {
            vcName: 34,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'TaxCode',
                mainKey: 'TaxCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Post'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'PostCode',
          viewOnly: false,
          width: '180px',
          pasteSpecial: {
            vcName: 11,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'PostCode',
                mainKey: 'PostCode',
              },
              {
                width: '65%',
                name: translate('Comment'),
                key: 'PostName',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
};

const contractHeaderFields_EST = (table): WindowDefinition => {
  return [
    {
      fields: [
        {
          label: translate('ContractNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ContractNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Employee'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCode',
          viewOnly: false,
          width: '400px',
          pasteSpecial: {
            vcName: 0,
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'empcode',
                mainKey: 'EmpCode',
              },
              {
                width: '35%',
                name: translate('Name'),
                key: 'Firstname',
              },
              {
                width: '30%',
                name: '',
                key: 'Surname',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('StartDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'StartDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('EndDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'EndDate',
          viewOnly: false,
          width: '150px',
          allowClearing: true,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          viewOnly: true,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'DepCode',
                mainKey: 'DepCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
};

export function headerFields(table) {
  let result;
  switch (global.userData.provider) {
    case 1:
      result = contractHeaderFields_EST(table);
      break;
    default:
      result = contractHeaderFields_LAT(table);
  }
  return result;
}

const contractMatrixColumns_LAT = (table) => {
  let result = [];
  if (IsHRWeb()) {
    result.push({
      title: translate('Type'),
      dataKey: 'Type',
      width: '80px',
      viewOnly: true,
    });
    result.push({
      title: translate('Operation'),
      dataKey: 'TrType',
      width: '100px',
      viewOnly: true,
    });
  }
  result.push({
    title: translate('Name'),
    dataKey: 'Text',
    width: '200px',
    viewOnly: true,
  });
  result.push({
    title: translate('Sum'),
    dataKey: 'Sum',
    width: '70px',
    style: {textAlign: 'right'},
    viewOnly: false,
    onChange: table.updateRecordField,
    type: WindowElementType.kInputTypeVal,
  });
  result.push({
    title: translate('FromDate'),
    dataKey: 'FromDate',
    width: '90px',
    viewOnly: true,
    type: WindowElementType.kInputTypeDate,
    onChange: table.updateRecordField,
  });
  result.push({
    title: translate('ToDate'),
    dataKey: 'ToDate',
    width: '90px',
    type: WindowElementType.kInputTypeDate,
    viewOnly: true,
  });
  if (IsHRWeb()) {
    result.push({
      title: translate('Objects'),
      dataKey: 'Objects',
      width: '90px',
      viewOnly: true,
    });
  }
  return result;
};

const contractMatrixColumns_EST = () => {
  let result = undefined;
  /*
                                      if (IsHRWeb()) {
                                        result.push({
                                          title: translate('Post'),
                                          dataKey: 'PosCode',
                                          width: '80px',
                                          viewOnly: true,
                                        });
                                        result.push({
                                          title: translate('Name'),
                                          dataKey: 'PosName',
                                          width: '100px',
                                          viewOnly: true,
                                        });
                                      }

                                      result.push({
                                        title: translate('Sum'),
                                        dataKey: 'Sum',
                                        width: '70px',
                                        style: {textAlign: 'right'},
                                        viewOnly: false,
                                        onchange: table.updateRecordField,
                                        type: DataTypes.kInputTypeVal,
                                      });
                                      result.push({
                                        title: translate('FromDate'),
                                        dataKey: 'SDate',
                                        width: '90px',
                                        viewOnly: true,
                                        type: DataTypes.kInputTypeDate,
                                        onchange: table.updateRecordField,
                                      });
                                      result.push({
                                        title: translate('ToDate'),
                                        dataKey: 'EDate',
                                        width: '90px',
                                        type: DataTypes.kInputTypeDate,
                                        viewOnly: true,
                                      });
                                      */
  return result;
};

export function matrixFields(table) {
  let result;
  switch (global.userData.provider) {
    case 1:
      result = contractMatrixColumns_EST(table);
      break;
    default:
      result = contractMatrixColumns_LAT(table);
  }
  return result;
}

function getContractBrowseColumns_LAT() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('ContractNr'),
      key: 'contractnr',
      sortKey: 'ContractNr',
      width: '120px',
    },
    {
      name: translate('Code'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'empname',
      sortKey: 'EmpName',
      width: '200px',
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('DepCode'),
      key: 'depcode',
      sortKey: 'DepCode',
      width: '100px',
    },
    {
      name: translate('DepName'),
      key: 'depname',
      sortKey: 'DepName',
      width: '150px',
    },
  ];
  if (GetWindowFilter('Contracts', 'closed') === null) {
    browseColumns.push({
      title: translate('Closed'),
      dataKey: 'closed',
      sortKey: 'Closed',
      width: '80px',
      overwrite: DisplayBrowseClosed,
    });
  }

  return browseColumns;
}

function getContractBrowseColumns_EST() {
  /*
                                      if (GetWindowFilter('Contracts', 'closed') === null) {
                                        result.push({
                                          title: translate('Closed'),
                                          dataKey: 'closed',
                                          width: '80px',
                                          overwrite: DisplayBrowseClosed,
                                        });
                                      }
                                      */

  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('ContractNr'),
      key: 'contractnr',
      sortKey: 'ContractNr',
      width: '120px',
    },
    {
      name: translate('Code'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'firstname',
      sortKey: 'FirstName',
      width: '200px',
      key2: 'surname',
      windowElementType: WindowElementType.kInputTypeJointField,
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '150px',
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
    },

    {
      name: translate('DepCode'),
      key: 'depcode',
      sortKey: 'DepCode',
      width: '100px',
    },
  ];

  return browseColumns;
}

export function browseColumns(table) {
  let result;
  switch (global.userData.provider) {
    case 1:
      result = getContractBrowseColumns_EST(table);
      break;
    default:
      result = getContractBrowseColumns_LAT(table);
  }
  return result;
}
