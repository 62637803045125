const enableErrorLogs = false;

const enableWindowLogs = false;
const enableScreenLogs = false;
const enableDisplayToolsLogs = false;
const enableTempLogs = false;

let loggedMessages = [];
export function logOnce(message: string, data?: any) {
  if (loggedMessages.includes(message)) {
    return;
  }
  console.log(message, data);
  loggedMessages.push(message);
}

export function logError(error, message?: string) {
  if (!enableErrorLogs) {
    return;
  }
  if (message) {
    console.error(message + ' ', error);
    return;
  }
  console.error(error);
}

export const enum LogType {
  screenLog,
  listWindowLog,
  displayToolsLog,
  tempLog,
}

export function log(message: string, data: any, logType: LogType) {
  switch (logType) {
    case LogType.screenLog:
      if (enableScreenLogs) {
        console.log(message, data);
      }
      break;
    case LogType.listWindowLog:
      if (enableWindowLogs) {
        console.log(message, data);
      }
      break;
    case LogType.displayToolsLog:
      if (enableDisplayToolsLogs) {
        console.log(message, data);
      }
      break;
    case LogType.tempLog:
      if (enableTempLogs) {
        console.log(message, data);
      }
      break;
    default:
      console.log(message, data);
  }
}
