import React from 'react';
import {OverwriteBrowseColumnProps} from '@utils/types';
import {RowContainer, TextNormal, TextNormalBold} from '@styles/primitiveComponents';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {Text, View} from 'react-native';
import {translate} from '@utils/languageTools';
import {extractDateFromString, getCurrentDate} from '@tools/dateTools';
import {colours, fontSize, fontWeight, spacing} from '@styles/Style';
import {ConvertDisplayValue, GetGlobalTheme, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import styled from 'styled-components/native';
import ToolTip from '@components/design/ToolTip';

const MobileApprovalStyle = {
  color: colours[GetGlobalTheme()].tableBody,
  fontSize: fontSize.bodySmall,
  fontWeight: fontWeight.bodySmall,
};

const MobileInvDateStyle = {
  color: colours[GetGlobalTheme()].tableBody,
  fontSize: fontSize.bodySmall,
  fontWeight: fontWeight.bodyLight,
};

const MobileRecordListPrefixStyle = {
  color: colours[GetGlobalTheme()].tableBody,
  fontSize: fontSize.bodySmall,
  fontWeight: fontWeight.bodyBold,
};

const StatusContainer = styled.View`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  flex-direction: row;
  border: ${(props) => (props.border ? props.border : 'none')};
`;

const StyledIconWrapper = styled(RowContainer)`
  width: 12px;
  justify-content: center;
  align-items: center;
`;

const ClientAndSupplierContainerStyle = {
  justifyContent: 'center',
  borderRadius: spacing.space24,
  padding: spacing.space4,
};

export function displayBrowseOKFlag({value}: OverwriteBrowseColumnProps) {
  let res;
  switch (value) {
    case '1':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '2px'}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'CheckmarkIcon'} iconHeight={12} iconColor={colours[GetGlobalTheme()].success} />
          </StyledIconWrapper>
          <TextNormal>{translate('Confirmed')}</TextNormal>
        </RowContainer>
      );
      break;
    case '0':
      res = <TextNormal>{translate('NotConfirmed')}</TextNormal>;
      break;
    default:
      res = <Text></Text>;
  }
  return res;
}

const InvDateStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
  width: '100%',
  overflow: 'hidden',
};

export function displayInvDate({value, row}: OverwriteBrowseColumnProps, payDateKey: string, paidKey: string) {
  let res;
  const today = getCurrentDate().setHours(0, 0, 0, 0);
  const overDueDate = extractDateFromString(row[payDateKey]).setHours(0, 0, 0, 0);

  const isOverdue = overDueDate < today;
  const overdueDays = Math.abs(Math.floor((today - overDueDate) / (1000 * 60 * 60 * 24)));

  const notPaid = row && row[paidKey] == 0;
  const textColour = isOverdue && notPaid ? colours[GetGlobalTheme()].error : colours[GetGlobalTheme()].tableBody;
  const convertedDate = ConvertDisplayValue(WindowElementType.kInputTypeDate, value);
  const daySuffix = overdueDays === 1 ? translate('Day') : translate('Days');

  if (isOverdue && notPaid) {
    res = (
      <ToolTip tooltipText={`${overdueDays} ${daySuffix} ${translate('Overdue')}`}>
        <View style={InvDateStyle}>
          <StyledIconWrapper>
            <PortalIcon iconName={'RedExclamationMarkIcon'} iconHeight={12} />
          </StyledIconWrapper>
          <TextNormal style={{color: textColour}}>{convertedDate}</TextNormal>
        </View>
      </ToolTip>
    );
  } else {
    res = <TextNormal style={{color: textColour}}>{convertedDate}</TextNormal>;
  }

  return res;
}

export function displayDueDate({value, row}: OverwriteBrowseColumnProps) {
  const convertedDate = ConvertDisplayValue(WindowElementType.kInputTypeDate, value);
  let paymentTermDescription = row['PayDeal'];
  if (isMobileOrTabletScreen()) {
    paymentTermDescription = paymentTermDescription.shorten(10, '...');
  }
  const displayText = `${convertedDate} | ${paymentTermDescription}`;

  return <TextNormal style={{textWrap: 'nowrap'}}>{displayText}</TextNormal>;
}

export function displayToolTipDueDate({value, row}: OverwriteBrowseColumnProps) {
  const convertedDate = ConvertDisplayValue(WindowElementType.kInputTypeDate, value);
  let paymentTermDescription = translate('PaymentTerm') + row['PayDeal'];
  if (isMobileOrTabletScreen()) {
    paymentTermDescription = paymentTermDescription.shorten(10, '...');
  }
  const displayText = `${convertedDate}`;

  return (
    <ToolTip tooltipText={paymentTermDescription} maxTextLength={40}>
      <TextNormal style={{textWrap: 'nowrap'}}>{displayText}</TextNormal>
    </ToolTip>
  );
}

export function displayOrdDate({value}: OverwriteBrowseColumnProps) {
  let res;
  const convertedDate = ConvertDisplayValue(WindowElementType.kInputTypeDate, value);

  res = <TextNormal>{convertedDate}</TextNormal>;

  return res;
}

export function displayApprovalStatusWithIcons({value}: OverwriteBrowseColumnProps) {
  let res = null;
  switch (value) {
    case '1':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <TextNormal>-</TextNormal>
        </RowContainer>
      );
      break;
    case '2':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'ExclamationMarkIcon'} iconHeight={12} />
          </StyledIconWrapper>
          <TextNormal>{getApprovalStatusText(value)}</TextNormal>
        </RowContainer>
      );
      break;
    case '3':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'PendingIcon'} iconHeight={12} />
          </StyledIconWrapper>
          <TextNormal>{getApprovalStatusText(value)}</TextNormal>
        </RowContainer>
      );
      break;
    case '4':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'CheckmarkIcon'} iconHeight={12} />
          </StyledIconWrapper>
          <TextNormal>{getApprovalStatusText(value)}</TextNormal>
        </RowContainer>
      );
      break;
    case '5':
      res = (
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'XMarkIcon'} iconHeight={12} />
          </StyledIconWrapper>
          <TextNormal>{getApprovalStatusText(value)}</TextNormal>
        </RowContainer>
      );
      break;
    default:
      res = <Text></Text>;
  }
  return res;
}

export function getApprovalStatusText(value) {
  let res = '';

  switch (value) {
    case '1':
      res = translate('ApprNotStarted');
      break;
    case '2':
      res = translate('ApprNotRequested');
      break;
    case '3':
      res = translate('ApprPending');
      break;
    case '4':
      res = translate('ApprApproved');
      break;
    case '5':
      res = translate('ApprRejected');
      break;
  }
  return res;
}

export function displayPaidStatus({value, row}, payDateKey: string) {
  let res = null;
  const isOverdue = row && row[payDateKey] && extractDateFromString(row[payDateKey]) < getCurrentDate();

  switch (value) {
    case '0':
    case '-1':
      if (isOverdue) {
        res = (
          <StatusContainer backgroundColor={colours[GetGlobalTheme()].red100}>
            <TextNormal style={{color: colours[GetGlobalTheme()].red200}}>{translate('NotPaid')}</TextNormal>
          </StatusContainer>
        );
      } else {
        res = (
          <StatusContainer
            backgroundColor={colours[GetGlobalTheme()].grey200}
            border={`1px solid ${colours[GetGlobalTheme()].grey400}`}>
            <TextNormal style={{color: colours[GetGlobalTheme()].grey600}}>{translate('NotPaid')}</TextNormal>
          </StatusContainer>
        );
      }
      break;
    case '1':
      res = (
        <StatusContainer backgroundColor={colours[GetGlobalTheme()].green100}>
          <TextNormal style={{color: colours[GetGlobalTheme()].green400}}>{translate('Paid')}</TextNormal>
        </StatusContainer>
      );
      break;
  }
  return res;
}

export function displayOrderStatus({value}) {
  let res = null;

  switch (value) {
    case 'ToInvoice':
      res = (
        <StatusContainer backgroundColor={colours[GetGlobalTheme()].red100}>
          <TextNormal style={{color: colours[GetGlobalTheme()].red200}}>{translate('StatusToInvoice')}</TextNormal>
        </StatusContainer>
      );
      break;
    case 'Done':
      res = (
        <StatusContainer backgroundColor={colours[GetGlobalTheme()].green100}>
          <TextNormal style={{color: colours[GetGlobalTheme()].green400}}>{translate('Done')}</TextNormal>
        </StatusContainer>
      );
      break;
    case 'Closed':
      res = (
        <StatusContainer backgroundColor={colours[GetGlobalTheme()].grey500}>
          <TextNormal style={{color: colours[GetGlobalTheme()].white}}>{translate('Closed')}</TextNormal>
        </StatusContainer>
      );
      break;
    case 'ToDeliver':
      res = (
        <StatusContainer backgroundColor={colours[GetGlobalTheme()].yellow100}>
          <TextNormal style={{color: colours[GetGlobalTheme()].yellowMedium}}>
            {translate('StatusToDeliver')}
          </TextNormal>
        </StatusContainer>
      );
      break;
    case 'Open':
      res = (
        <StatusContainer
          backgroundColor={colours[GetGlobalTheme()].grey200}
          border={`1px solid ${colours[GetGlobalTheme()].grey400}`}>
          <TextNormal style={{color: colours[GetGlobalTheme()].label}}>{translate('StatusOpen')}</TextNormal>
        </StatusContainer>
      );
      break;
  }
  return res;
}

export function displaySumwithCurrency({value, row}) {
  let res;
  let currency = row['curncycode'].toUpperCase();

  res = (
    <RowContainer styles={{alignItems: 'right'}}>
      <TextNormal>
        {value} {currency}
      </TextNormal>
    </RowContainer>
  );

  return res;
}

export function displayMinimizedSumwithCurrency({value, row}) {
  let res = null;
  let currency = row['Currency'].toUpperCase();

  res = (
    <RowContainer styles={{alignItems: 'right'}}>
      <TextNormal>
        {value} {currency}
      </TextNormal>
    </RowContainer>
  );

  return res;
}

export function getMobileSumStyle(backgroundColor, textColor) {
  return {
    backgroundColor: backgroundColor,
    color: textColor,
    borderRadius: 50,
    fontSize: fontSize.body,
    fontWeight: fontWeight.body,
    paddingVertical: spacing.space4,
    paddingHorizontal: spacing.space16,
  };
}

export function getMobileDisplaySumWithCurrency(
  {value, column, row}: OverwriteBrowseColumnProps,
  dueDateKey: string,
  currencyKey: string,
  paidKey: string
) {
  let component = <></>;
  const isOverdue = row && row[dueDateKey] && extractDateFromString(row[dueDateKey]) < getCurrentDate();
  const currency = row[currencyKey].toUpperCase();
  const componentText = `${value} ${currency}`;

  const paidStatus = ConvertDisplayValue(column.type, row[paidKey]);

  switch (paidStatus) {
    case '0':
      if (isOverdue) {
        component = (
          <View style={getMobileSumStyle(colours[GetGlobalTheme()].red100, colours[GetGlobalTheme()].red200)}>
            {componentText}
          </View>
        );
      } else {
        component = (
          <View style={getMobileSumStyle(colours[GetGlobalTheme()].grey200, colours[GetGlobalTheme()].grey600)}>
            {componentText}
          </View>
        );
      }
      break;
    case '1':
      component = (
        <View style={getMobileSumStyle(colours[GetGlobalTheme()].green100, colours[GetGlobalTheme()].green400)}>
          {componentText}
        </View>
      );
      break;
    default:
      component = (
        <View style={getMobileSumStyle(colours[GetGlobalTheme()].grey200, colours[GetGlobalTheme()].grey600)}>
          {componentText}
        </View>
      );
  }
  return component;
}

export function displayMobileSerNumberAndDate({value, row}: OverwriteBrowseColumnProps) {
  const formattedData = ConvertDisplayValue(WindowElementType.kInputTypeDate, row['Date']);
  const component = (
    <RowContainer>
      <TextNormalBold>{value},&nbsp;</TextNormalBold>
      <TextNormal>{formattedData}</TextNormal>
    </RowContainer>
  );

  return component;
}

export function getMobileDisplayPlannedDelivery({value, row}) {
  return <TextNormal>{value}</TextNormal>;
}

export function getMobileDisplayBrowseOKFlag({value, row}: OverwriteBrowseColumnProps) {
  let component = <></>;
  switch (value) {
    case '1':
      component = (
        <RowContainer style={{alignItems: 'center', gap: '2px', paddingRight: spacing.space4}}>
          <StyledIconWrapper>
            <PortalIcon iconName={'CheckmarkIcon'} iconHeight={12} iconColor={colours[GetGlobalTheme()].success} />
          </StyledIconWrapper>
          <View style={MobileApprovalStyle}>{translate('Confirmed')}</View>
        </RowContainer>
      );
      break;
    case '0':
      component = <View style={MobileApprovalStyle}>{translate('NotConfirmed')}</View>;
      break;
    default:
      component = <View style={MobileApprovalStyle}></View>;
  }
  return component;
}

export function getMobileDisplayInvDate({value, row}: OverwriteBrowseColumnProps, serNrKey: string) {
  const formattedData = ConvertDisplayValue(WindowElementType.kInputTypeDate, value);
  const component = (
    <RowContainer>
      <View style={MobileRecordListPrefixStyle}>{row[serNrKey]},&nbsp;</View>
      <View style={MobileInvDateStyle}>{formattedData}</View>
    </RowContainer>
  );

  return component;
}

export function getMobileDisplayPerson({value, row}: OverwriteBrowseColumnProps) {
  const component = (
    <RowContainer style={{width: '100%', gap: '2px'}}>
      <StyledIconWrapper style={{width: '10%'}}>
        <PortalIcon iconName={'PersonIcon'} iconHeight={14} iconColor={colours[GetGlobalTheme()].icon} />
      </StyledIconWrapper>
      {value ? <TextNormal>{value}</TextNormal> : null}
    </RowContainer>
  );

  return component;
}

export function getMobileDisplayCustomerAndSupplier({value, row}: OverwriteBrowseColumnProps) {
  const isCustomer = row['cutype'] === '1';
  const isSupplier = row['vetype'] === '1';
  return (
    <RowContainer style={{gap: spacing.space8}}>
      {isCustomer && (
        <View style={{...ClientAndSupplierContainerStyle, ...{backgroundColor: colours[GetGlobalTheme()].green100}}}>
          <TextNormal>{translate('Client').charAt(0)}</TextNormal>
        </View>
      )}
      {isSupplier && (
        <View style={{...ClientAndSupplierContainerStyle, ...{backgroundColor: colours[GetGlobalTheme()].purpleLight}}}>
          <TextNormal>{translate('Supplier').charAt(0)}</TextNormal>
        </View>
      )}
    </RowContainer>
  );
}

export function getMobileDisplayCustomerInfo({value, row}: OverwriteBrowseColumnProps, codeKey: string) {
  const component = (
    <RowContainer>
      <View style={MobileRecordListPrefixStyle}>{value}&nbsp;</View>
      <View style={MobileInvDateStyle}>({row[codeKey]})</View>
    </RowContainer>
  );

  return component;
}
