export const RegistrationFields = {
  Name: ['Name', 'Vārds'],
  InvAddr0: ['Invoice Address 1', 'Rēķina adrese 1'],
  InvAddr1: ['Invoice Address 2', 'Rēķina adrese 2'],
  InvAddr2: ['Invoice Address 3', 'Rēķina adrese 3'],
  InvAddr3: ['Invoice Address 4', 'Rēķina adrese 4'],
  InvAddr4: ['Invoice Address 5', 'Rēķina adrese 5'],
  Sorting: ['Sort Key', 'Kārtošanas atslēga'],
  Department: ['Department', 'Departaments'],
  Phone: ['Telephone', 'Telefons'],
  Fax: ['Fax', 'Fakss'],
  AltPhone: ['Alternate Phone', 'Cits telefons'],
  Mobile: ['Mobile', 'Mobilais'],
  SkypeName: ['Skype Name', 'Skype vārds'],
  eMail: ['Email', 'E-pasts'],
  Person: ['Primary Contact', 'Primārā kontaktp.'],
  wwwAddr: ['Web Site', 'Mājas lapa'],
  Classification: ['Classification (select)', 'Klasifikācija (nolaiž. sar.)'],
  DelAddr0: ['Delivery Address 1', 'Nosūtīšanas adrese 1'],
  DelAddr1: ['Delivery Address 2', 'Nosūtīšanas adrese 2'],
  DelAddr2: ['Delivery Address 3', 'Nosūtīšanas adrese 3'],
  DelAddr3: ['Delivery Address 4', 'Nosūtīšanas adrese 4'],
  DelAddr4: ['Delivery Address 5', 'Nosūtīšanas adrese 5'],
  RegNr1: ['Reg. No. 1', 'Reģ. Nr. 1'],
  VATNr: ['VAT Reg. No.', 'PVN Reģ. Nr.'],
  CountryCode: ['Country', 'Valsts'],
};
