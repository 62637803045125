import React from 'react';
import {colours, fontSize, fontWeight, iconSize, spacing} from '@styles/Style';
import {PasteSpecialWindowLayout} from '@utils/types';
import styled from 'styled-components/native';
import {DataTable} from 'react-native-paper';
import {GetGlobalTheme} from '@tools/displayTools';
import {SearchIconStyle} from '@components/switchAccount/SwitchAccount.styles';
import EditField from '@components/editElements/EditField';

const SearchBarWrapperStyle = {
  padding: spacing.space4,
  margin: spacing.space4,
};

const SearchBarStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  borderRadius: 8,
  borderWidth: 1,
  margin: 0,
  padding: spacing.space12,
  paddingLeft: spacing.space32,
  width: '100%',
};

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].white};
  padding-vertical: 0px;
  font-weight: ${fontWeight.bodyBold};
  font-size: ${fontSize.body}px;
  border-bottom-width: 0px;
  height: 36px;
  line-height: 36px;
  padding: 0 ${spacing.space12}px;
  padding-right: ${spacing.space24}px;
  margin: 0;
`;

const ColumnTitle = styled.View<{width: number}>`
  color: black;
  width: ${(props) => props.width};
  display: flex;
  align-items: start;
  padding: 0;
`;

type PasteSpecialHeaderProps = {
  isMultiPasteSpecial: boolean;
  psheader: any;
  windowLayout: PasteSpecialWindowLayout;
  isStaticPasteSpecial: boolean;
  bubbleEntries: React.ReactElement[];
  searchRef: React.Ref<any>;
  onSearchBarKeyPress: (value: number, event: any) => void;
  onSearch: (value: string) => void;
};

export const PasteSpecialHeader: React.FC<PasteSpecialHeaderProps> = ({
  isMultiPasteSpecial,
  psheader,
  windowLayout,
  isStaticPasteSpecial,
  bubbleEntries,
  searchRef,
  onSearchBarKeyPress,
  onSearch,
}) => {
  let shouldRenderHeader = true;

  if (windowLayout === PasteSpecialWindowLayout.SingleColumnWithDescription || isStaticPasteSpecial) {
    shouldRenderHeader = false;
  }

  function getSearchBar() {
    if (isStaticPasteSpecial) {
      return <></>;
    }

    return (
      <EditField
        // @ts-ignore
        inputStyle={SearchBarStyle}
        wrapperStyle={SearchBarWrapperStyle}
        iconName={'SearchIcon'}
        iconStyle={SearchIconStyle}
        iconHeight={iconSize.iconSize}
        value={''}
        reference={searchRef}
        onKeyPress={(event) => {
          switch (event.key) {
            case 'ArrowUp':
              event.target.blur();
              onSearchBarKeyPress(-1, event);
              break;
            case 'ArrowDown':
              event.target.blur();
              onSearchBarKeyPress(1, event);
              break;
            case 'Enter':
              event.target.blur();
              onSearchBarKeyPress(1, event);
              break;
            default:
              global.HandleKeyDown(event);
          }
        }}
        onChangeText={(value: string) => onSearch(value)}
      />
    );
  }

  return (
    <>
      {getSearchBar()}
      {bubbleEntries}
      {shouldRenderHeader && (
        <StyledHeader key={'paste_header'} style={{marginLeft: isMultiPasteSpecial ? spacing.space24 : 0}}>
          {psheader.map((field, index) => (
            <ColumnTitle key={index} width={field.width}>
              {field.name}
            </ColumnTitle>
          ))}
        </StyledHeader>
      )}
    </>
  );
};
