import React, {Component} from 'react';
import styled from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {DefaultIconButton} from '../button/DefaultIconButton';

const ShiftEntryWrap = styled.View`
  width: 260px;
  position: absolute;
  border: 1px solid ${colours[GetGlobalTheme()].lightBackground};
  border-radius: 5px;
  background: ${colours[GetGlobalTheme()].inputBackground};
  font-family: 'Open Sans';
  overflow: hidden;
`;
const ShiftEntryTop = styled.View`
  padding-vertical: ${spacing.small300};
  padding-horizontal: ${spacing.small300};
`;
const ShiftEntryWrapInner = styled.View`
  flex-direction: column;
  align-items: flex-start;
`;

class ShiftEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let self = this;
    return (
      <ShiftEntryWrap style={this.props.style}>
        <ShiftEntryTop>
          <DefaultIconButton
            iconName={'times'}
            mode="text"
            iconType="FA5"
            size={15}
            style={{margin: 0, width: '15px', height: '15px'}}
            action={() => self.props.close()}
          />
        </ShiftEntryTop>
        <ShiftEntryWrapInner>
          {this.props.shiftentry.ShowContent(this.props.shiftentry.key)}
        </ShiftEntryWrapInner>
      </ShiftEntryWrap>
    );
  }
}

export default ShiftEntry;
