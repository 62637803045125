import React from 'react';
import {BrowseColumn} from '@components/screens/types';
import {isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import {
  displayDueDate,
  displayInvDate,
  displayMinimizedSumwithCurrency,
  displayMobileSerNumberAndDate,
  displayPaidStatus,
  displayToolTipDueDate,
  getMobileDisplaySumWithCurrency,
} from '@tools/recordListDisplayTools';
import {
  MinimizedRecordList,
  MinimizedRecordListDefinitionProps,
} from '@components/screens/minimizedRecordList/MinimizedRecordList';
import {OverwriteBrowseColumnProps} from '@utils/types';
import {MobileBrowseColumnDisplayType} from '@src/types/Record/windowDefinitions';

export const ContactsInvoiceList: React.FC<MinimizedRecordListDefinitionProps> = ({window, recordData}) => {
  const tableName = 'IVVc';
  const windowName = 'Invoices';
  const sortKey = 'Date';
  const rowID = 'SerNr';

  const desktopBrowseColumns: BrowseColumn[] = [
    {
      name: translate('SerNr'),
      key: 'SerNr',
      sortKey: 'SerNr',
      alignment: 'left',
      width: '84px',
    },
    {
      name: translate('InvDate'),
      key: 'Date',
      sortKey: 'Date',
      alignment: 'left',
      width: '120px',
      windowElementType: WindowElementType.kInputTypeDate,
      overwrite: (props: OverwriteBrowseColumnProps) => displayInvDate(props, 'Date2', 'Paid'),
    },
    {
      name: translate('DueDate'),
      key: 'Date2',
      sortKey: 'Date2',
      alignment: 'left',
      width: '162px',
      windowElementType: WindowElementType.kInputTypeDate,
      overwrite: displayToolTipDueDate,
    },
    {
      name: translate('Total'),
      key: 'Sum',
      sortKey: 'Sum',
      alignment: 'right',
      width: '84px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: displayMinimizedSumwithCurrency,
    },
    {
      name: translate('Balance'),
      key: 'Balance',
      sortKey: 'Balance',
      alignment: 'right',
      width: '111px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: displayMinimizedSumwithCurrency,
    },
    {
      name: translate('PaymentStatus'),
      key: 'Paid',
      sortKey: 'Paid',
      alignment: 'center',
      width: '148px',
      overwrite: (props: OverwriteBrowseColumnProps) => displayPaidStatus(props, 'Date2'),
    },
  ];

  const mobileBrowseColumns: BrowseColumn[] = [
    {
      name: translate('Name'),
      key: 'SerNr',
      sortKey: 'SerNr',
      alignment: 'left',
      width: '151px',
      overwrite: displayMobileSerNumberAndDate,
      mobileDisplayType: MobileBrowseColumnDisplayType.title,
    },
    {
      name: translate('DueDate'),
      key: 'Date2',
      sortKey: 'Date2',
      alignment: 'left',
      width: '140px',
      windowElementType: WindowElementType.kInputTypeDate,
      overwrite: displayDueDate,
      mobileDisplayType: MobileBrowseColumnDisplayType.underTitle,
    },
    {
      name: translate('Total'),
      key: 'Sum',
      sortKey: 'Sum',
      alignment: 'right',
      width: '111px',
      windowElementType: WindowElementType.kInputTypeValFull,
      overwrite: (props: OverwriteBrowseColumnProps) =>
        getMobileDisplaySumWithCurrency(props, 'Date2', 'Currency', 'Paid'),
      mobileDisplayType: MobileBrowseColumnDisplayType.rightTitle,
    },
  ];

  function getBrowseColumns(): BrowseColumn[] {
    if (isMobileOrTabletScreen()) {
      return mobileBrowseColumns;
    }

    return desktopBrowseColumns;
  }

  return (
    <MinimizedRecordList
      window={window}
      recordData={recordData}
      hasRecordSummary={true}
      tableName={tableName}
      windowName={windowName}
      sortKey={sortKey}
      rowID={rowID}
      browseColumns={getBrowseColumns()}
    />
  );
};
