import {DataTable} from 'react-native-paper';
import React, {Component} from 'react';
import {Animated, View, Text} from 'react-native';
import styled from 'styled-components/native';
import {colours, spacing} from '@styles/Style';
import {
  GetGlobalTheme,
  GetPasteSpecialValue,
  IsHRWeb,
  requiresSearchForPasteSpecial,
  setPasteSpecialValue,
} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import Requests from '../../../../api/Requests';
import {handleError} from '../../../../Tools/PortalLog';
import {PasteSpecialSelectedItem} from '@components/editElements/pasteSpecial/pasteSpecialComponents/PasteSpecialSelectedItem';
import {PasteSpecialStyledRow} from '@components/editElements/pasteSpecial/pasteSpecialComponents/PasteSpecialStyledRow';
import {PasteSpecialWindowLayout, UseMultiDropDownWithFiltersResult} from '@utils/types';
import {isStaticPasteSpecial} from '@utils/pasteSpecialUtils';
import {PasteSpecial} from '@components/editElements/pasteSpecial/PasteSpecial';
import {
  ClassificationPasteSpecial,
  MultiDropDownField,
} from '@components/editElements/pasteSpecial/ClassificationPasteSpecial';
import {InspectWindow} from '@components/editElements/inspectWindow/InspectWindow';

const Message = styled.Text`
  text-align: center;
  margin-top: 20px;
  color: ${colours[GetGlobalTheme()].black};
`;

const ValueWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${spacing.space4}px;
`;

type PasteSpecialWindowState = {
  SearchVal: string;
  activeRecord: any[];
  currentPage: number;
  records: any[];
  multiSelectedRecord: any[];
  inlineNewRecord: boolean;
  canCreateNew: any;
  NewRec: {};
  lastTime: number;
  sortedData: any[];
  currentIndex: number;
  offsetY: any;
  bubbleEntries: string[];
  isLoading: boolean;
};

export type PasteSpecialWindowProps = {
  isMultiPasteSpecial: boolean;
  sortedPasteSpecial: any;
  mainKey: string;
  value: any;
  table: any;
  psheader: any;
  editfield: any;
  rownr: number;
  windowLayout: PasteSpecialWindowLayout;
  multiDropDownField: MultiDropDownField;
  filter: any;
  isDropDownPasteSpecial?: boolean;
  inspectWindow: InspectWindow;
  onDisable: () => void;
  useMultiDropDownWithFilters: () => UseMultiDropDownWithFiltersResult;
  onChangeOverwrite: (value: string) => void;
};

export class PasteSpecialWindow extends Component<PasteSpecialWindowProps, PasteSpecialWindowState> {
  searchMinRequiredCharacters = 1;
  dynamicf = false;
  searchRef = React.createRef<View>();
  isStaticPasteSpecial: boolean;
  constructor(props) {
    super(props);
    const self = this;
    this.state = {
      SearchVal: '',
      activeRecord: [],
      currentPage: 0,
      records: [],
      multiSelectedRecord: [],
      inlineNewRecord: false,
      canCreateNew: this.props.psnewrec,
      NewRec: {},
      lastTime: 0,
      sortedData: [],
      currentIndex: -1,
      offsetY: new Animated.Value(-400),
      bubbleEntries: [],
      isLoading: true,
    };
    if (this.props.value === undefined) {
      this.state.activeRecord = [];
      this.state.origValue = '';
      this.state.value = '';
    } else {
      this.state.activeRecord = this.props.value.split2(',');
      this.state.bubbleEntries = this.props.value.split2(',');
      this.state.origValue = this.props.value;
      this.state.value = this.props.value;
    }
    if (requiresSearchForPasteSpecial(this.props.table)) {
      this.dynamicf = true;
    }
    this.showPasteSpecial();
    global.PushKeyActions('Enter', (e) => {
      if (e.shiftKey) {
        self.insertCurrentRecord();
      } else {
        if (this.props.isMultiPasteSpecial) {
          self.selectFromList(0, e, false);
        }
      }
    });
    global.PushKeyActions('ArrowUp', (e) => self.selectFromList(-1, e));
    global.PushKeyActions('ArrowDown', (e) => self.selectFromList(1, e));
    this.isStaticPasteSpecial = isStaticPasteSpecial(this.props.table);
  }

  selectFromList(diff, e, arrowf = true) {
    e.stopPropagation();
    let sortedDataLength = this.state.sortedData.length;
    let newIndex = this.state.currentIndex + diff;
    if (newIndex < sortedDataLength && newIndex >= 0) {
      let id = this.state.sortedData[newIndex][this.props.psheader[0].key];
      this.setCurrentRecord(newIndex, id, arrowf);
    }
  }

  searchPasteSpecial() {
    const self = this;
    const filterCriteria = this.props.filter || {};
    let fields = '';
    this.props.psheader.map((field) => {
      fields += ',' + field.key;
    });

    if (this.isStaticPasteSpecial) {
      this.handleStaticPasteSpecial();
      return;
    }

    this.setState({isLoading: true});
    Requests.getTableData(this.props.table, filterCriteria, fields)
      .then((response) => {
        let records = [];
        if (self.props.table.toString().slice(-5) === 'Block') {
          records = response.records[0].rows;
        } else {
          records = response.records;
        }
        if (self.state.activeRecord.length > 0) {
          for (let i = 0; i < records.length; i++) {
            if (self.state.activeRecord.indexOf(records[i][self.props.psheader[0].key]) > -1) {
              self.state.currentIndex = i;
              i = records.length;
            }
          }
        }

        self.setState({records: records, isLoading: false});
      })
      .catch((error) => {
        handleError(error);
      });
  }

  handleStaticPasteSpecial() {
    const records = global.pasteSpecials[this.props.table];
    this.setState({records: records, isLoading: false});
  }

  searchPasteSpecial2() {
    let self = this;
    this.setState({isLoading: true});

    Requests.getTableData(this.props.table, {}, '', this.state.SearchVal)
      .then((response) => {
        if (self.props.table.toString().slice(-5) === 'Block') {
          self.setState({records: response.records[0].rows});
        } else {
          self.setState({records: response.records, isLoading: false});
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  showPasteSpecial() {
    Animated.timing(this.state.offsetY, {toValue: 0, duration: 300}).start();
  }

  search(value) {
    this.setState({SearchVal: value, currentIndex: -1}, () => {
      if (this.dynamicf) {
        if (value.length >= this.searchMinRequiredCharacters) {
          this.searchPasteSpecial2();
        } else {
          this.setState({records: []});
        }
      }
    });
  }
  removeValue(value) {
    let index = this.state.activeRecord.indexOf(value);
    if (index > -1) {
      let activeRecordArr = [...this.state.activeRecord];
      activeRecordArr.splice(index, 1);
      this.setState({activeRecord: activeRecordArr});
      const bubbleEntriesArr = this.state.bubbleEntries.filter((entry) => entry !== value);
      this.setState({bubbleEntries: bubbleEntriesArr});
    }
    index = this.state.multiSelectedRecord.indexOf(value);
    if (index > -1) {
      let multiSelectedRecordArr = [...this.state.multiSelectedRecord];
      multiSelectedRecordArr.splice(index, 1);
      this.setState({multiSelectedRecord: multiSelectedRecordArr});
      const bubbleEntriesArr = this.state.bubbleEntries.filter((entry) => entry !== value);
      this.setState({bubbleEntries: bubbleEntriesArr});
    }
  }

  getBubbleEntries() {
    const isMultiSelectWithDescription =
      this.props.isMultiPasteSpecial && this.props.psheader.some((field) => field.key === 'Comment');
    const res = [];
    const items = [];

    if (!this.props.isMultiPasteSpecial) {
      return res;
    }

    this.state.bubbleEntries.map((value) => {
      const pasteSpecialValue = GetPasteSpecialValue(this.props.table, value);

      items.push(
        <PasteSpecialSelectedItem
          pasteSpecialValue={isMultiSelectWithDescription ? value : pasteSpecialValue}
          onRemoveValue={() => this.removeValue(value)}
        />
      );
    });
    res.push(<ValueWrap>{items}</ValueWrap>);
    return res;
  }

  handleMultiPasteSpecial(value) {
    let indexInActive = this.state.activeRecord.indexOf(value);
    let indexInMultiSelected = this.state.multiSelectedRecord.indexOf(value);

    if (indexInActive < 0 && indexInMultiSelected < 0 && value !== '') {
      this.setState({
        multiSelectedRecord: [...this.state.multiSelectedRecord, value],
        bubbleEntries: [...this.state.activeRecord, ...this.state.multiSelectedRecord, value],
      });
    }
  }

  setCurrentRecord(index, value, arrowf = false) {
    if (this.props.isMultiPasteSpecial) {
      this.handleMultiPasteSpecial(value);
    } else {
      const shouldInsert = !arrowf || !IsHRWeb();
      this.setState({currentIndex: index, activeRecord: [value]}, () => {
        if (shouldInsert) {
          this.insertCurrentRecord();
        }
      });
    }
  }

  insertCurrentRecord(valueArr: string[] = []) {
    let value: string;
    if (valueArr.length > 0) {
      value = valueArr.join(',');
    } else {
      value = [...this.state.activeRecord, ...this.state.multiSelectedRecord].join(',');
    }

    if (this.props.onChange !== undefined) {
      if (this.state.origValue !== value) {
        this.props.onChange({
          rowindex: this.props.rownr,
          fieldname: this.props.name,
          value: value,
          inspectWindow: this.props.inspectWindow,
        });
      }
    }

    this.props.onDisable();
  }

  styledRow(rowindex, length, record) {
    const self = this;
    const id = record[this.props.psheader[0].key];
    setPasteSpecialValue(this.props.table, id, record);

    return (
      <PasteSpecialStyledRow
        rowIndex={rowindex}
        length={length}
        record={record}
        psheader={self.props.psheader}
        isMultiPasteSpecial={self.props.isMultiPasteSpecial}
        mainKey={self.props.mainKey}
        windowLayout={self.props.windowLayout}
        table={''}
        activeRecord={self.state.activeRecord}
        multiSelectedRecord={self.state.multiSelectedRecord}
        currentIndex={self.state.currentIndex}
        setCurrentRecord={(rowIndex: number, id: string) => {
          self.setCurrentRecord(rowIndex, id);
        }}
        removeValue={(code: string) => {
          self.removeValue(code);
        }}
      />
    );
  }

  sortAndSearchTableData(tableData) {
    let slicedTableData = tableData.slice();
    let searchParameter = this.state.SearchVal.toLowerCase();

    if (searchParameter !== '') {
      slicedTableData = slicedTableData.filter((item) => {
        return JSON.stringify(item).toLowerCase().indexOf(searchParameter) > -1;
      });
    }

    if (this.props.sortedPasteSpecial) {
      slicedTableData = this.getSortedTableData(tableData);
    }

    return slicedTableData;
  }

  getSortedTableData(tableData) {
    const tableDataCopy = tableData;
    const curDir = this.props.sortedPasteSpecial.dir;
    tableDataCopy.sort((a, b) => {
      if (curDir === 'ascending') {
        return a[this.props.sortedPasteSpecial.key] > b[this.props.sortedPasteSpecial.key] ? 1 : -1;
      } else {
        return a[this.props.sortedPasteSpecial.key] < b[this.props.sortedPasteSpecial.key] ? 1 : -1;
      }
    });
    return tableDataCopy;
  }

  recordList() {
    let recordList = [];
    let tdata = this.state.records;
    if (this.dynamicf === false) {
      tdata = this.sortAndSearchTableData(this.state.records);
    }
    this.state.sortedData = tdata;
    recordList.push(
      <DataTable primaryKey={'paste_body'}>
        {tdata.map((record, index) => {
          return this.styledRow(index, tdata.length, record);
        })}
        {tdata.length === 0 && this.dynamicf && this.state.SearchVal.length < this.searchMinRequiredCharacters && (
          <Message>{translate('StartTyping')}</Message>
        )}
        {tdata.length === 0 &&
        (this.dynamicf === false || this.state.SearchVal.length > this.searchMinRequiredCharacters - 1) ? (
          this.state.isLoading ? (
            <Message>{translate('LoadingPleaseWait')}</Message>
          ) : (
            <Message>{translate('NoData')}</Message>
          )
        ) : (
          <></>
        )}
      </DataTable>
    );

    return recordList;
  }

  shouldShowMultiDropDownPasteSpecial = () => {
    let result: boolean = false;

    if (this.props.useMultiDropDownWithFilters) {
      result = this.props.useMultiDropDownWithFilters().useMultiDropDowns;
    }
    return result;
  };

  componentWillUnmount() {
    global.PopKeyActions('Enter');
    global.PopKeyActions('ArrowUp');
    global.PopKeyActions('ArrowDown');
  }

  componentDidMount() {
    if (this.searchRef.current) {
      this.searchRef.current.focus();
    }
    if (this.dynamicf === false) {
      this.searchPasteSpecial();
    }
  }

  render() {
    if (this.shouldShowMultiDropDownPasteSpecial()) {
      return (
        <ClassificationPasteSpecial
          multiDropdownField={this.props.multiDropDownField}
          name={this.props.name}
          table={this.props.table}
          windowLayout={this.props.windowLayout}
          mainKey={this.props.mainKey}
          onInsertCurrentRecord={(value: string[]) => this.insertCurrentRecord(value)}
        />
      );
    }

    return (
      <PasteSpecial
        props={this.props}
        recordList={this.recordList()}
        isStaticPasteSpecial={this.isStaticPasteSpecial}
        bubbleEntries={this.getBubbleEntries()}
        searchRef={this.searchRef}
        onSearchBarKeyPress={(value, event) => this.selectFromList(value, event)}
        onSearch={(value) => this.search(value)}
        isDropDownPasteSpecial={this.props.isDropDownPasteSpecial}
        onInsertCurrentRecord={() => this.insertCurrentRecord()}
      />
    );
  }
}
