import React from 'react';
import {colours} from '../../styles/Style';
import {Text} from 'react-native';
import Requests from '../../api/Requests';
import {TableContainer} from '../table/Table';
import {ActionButton} from '../button/ActionButton';
import {translate} from '@utils/languageTools';
import {b64toBlob} from '../../Tools/Base64Decode';
import {
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  IsHRWeb,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {PDFViewer} from '../../components/status/PDFViewer';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {OverwriteBrowseColumnProps} from '@utils/types';
import {ListScreenState} from '@components/screens/types';
import {ListScreen} from '@src/types/Record/listScreen';
import {isMobileOrTabletScreen, isSafariBrowser} from '@tools/displayTools';

const Drawer = createDrawerNavigator();

interface CalculationsListState extends ListScreenState {
  base64: {};
}

export class CalculationsList extends ListScreen<{}, CalculationsListState> {
  constructor(props) {
    super(props);
    this._isMounted = true;
    this.table = 6;
    this.ScreenName = 'Calculations';
    this.init = true;
    this.state = {
      ...this.state,
      data: [],
      base64: {},
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('Calculations', 'EmpName', 'ascending');
    AddDateFilter('Calculations');
  }

  displayPrintPaySlip = ({id}: OverwriteBrowseColumnProps) => {
    let self = this;
    if (IsHRWeb()) {
      return <ActionButton onPress={() => self.printPaySlip(id)} title={translate('Download')} />;
    } else {
      return (
        <TouchableOpacity onPress={() => self.printPaySlip(id)}>
          <Text style={{color: colours[GetGlobalTheme()].titleText}}>{translate('Open')}</Text>
        </TouchableOpacity>
      );
    }
  };

  printPaySlip = (sernr: string) => {
    let self = this;
    if (IsHRWeb()) {
      let windowRef;
      if (isSafariBrowser()) {
        windowRef = window.open('about:blank', 'Downloading');
      }
      Requests.downloadDocument(6, {id: sernr})
        .then((response) => {
          if (response.file !== '') {
            let blob = b64toBlob(response.file, 'application/pdf');
            const url = window.URL.createObjectURL(blob);
            if (isSafariBrowser()) {
              windowRef.location = url;
            } else {
              window.open(url, 'Downloading');
            }
            //window.URL.revokeObjectURL(url);
          } else {
            global.messageBox({
              showMessage: true,
              statusMessage: translate('DocumentNotFound'),
            });
          }
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      Requests.downloadDocument(6, {id: sernr})
        .then((response) => {
          if (response.file !== undefined && response.file !== '') {
            self.setState({
              base64: {base64: response.file, name: response.name, showf: true},
            });
          } else {
            global.messageBox({
              showMessage: true,
              statusMessage: translate('DocumentNotFound'),
            });
          }
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  closePDFView = () => {
    this.setState({base64: {base64: '', name: '', showf: false}});
  };

  render() {
    let self = this;
    let res = [];
    let click = (id: string) => {
      if (isMobileOrTabletScreen()) {
        this.printPaySlip(id);
      }
    };

    res.push(
      <TableContainer
        table={6}
        update={this.state.update}
        data={this.state.data}
        doClick={click}
        browseColumns={[
          {
            name: translate('Name'),
            key: 'empname',
            sortKey: 'EmpName',
            width: '150px',
          },
          {
            name: translate('StartDate'),
            key: 'startdate',
            sortKey: 'StartDate',
            width: '100px',
            windowElementType: WindowElementType.kInputTypeDate,
          },
          {
            name: translate('EndDate'),
            key: 'enddate',
            sortKey: 'EndDate',
            width: '100px',
            windowElementType: WindowElementType.kInputTypeDate,
          },
          {
            title: '',
            dataKey: '',
            overwrite: this.displayPrintPaySlip,
            width: '100px',
          },
        ]}
        title={translate('PaySlips')}
        width="800px"
        rowID="sernr"
      />
    );
    if (this.state.base64.showf) {
      res.push(
        <PDFViewer
          base64={this.state.base64.base64}
          name={this.state.base64.name}
          onClose={() => self.closePDFView()}
        />
      );
    }

    return <WindowWrap>{res}</WindowWrap>;
  }
}

function Calculations() {
  return (
    <Drawer.Navigator backBehavior={GetDefaultBackBehaviour()} drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="CalculationsList"
        component={CalculationsList}
        options={{
          title: translate('PaySlips'),
        }}
      />
    </Drawer.Navigator>
  );
}

export default Calculations;
