import React from 'react';
import Invoices_old from '../../../../components/screens/Invoices';
import {DoNavigate, getDefaultBackBehaviour, goBack, IsHRWeb, Supported, WindowWrap} from '@tools/displayTools';
import {InspectWindow} from '@components/editElements/inspectWindow/InspectWindow';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '@tools/windowTools';
import {LangColorContext} from '../../../../App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import * as myDate from '@tools/dateTools';
import {logError} from '@utils/debug';
import {WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import Requests from '../../../../api/Requests';
import {RecordList} from '@components/RecordList/RecordList';
import {InvoicesRecordListDefinition} from './invoicesRecordListDefinition';
import {InvoicesRecordScreenDefinition} from '@components/screens/invoices/invoicesRecordScreenDefinition';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';

const Drawer = createDrawerNavigator();

interface InvoiceListState extends ListScreenState {}

export class InvoicesList extends ListScreen<{}, InvoiceListState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinition = new InvoicesRecordListDefinition(this);
    this.rowClick = this.rowClick.bind(this);
    this.ScreenName = 'Invoices';
    this.table = 'IVVc';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],

      Department: global.userData.deplist.split(',').pop(),
    } as InvoiceListState;
    SetCurrentWindow2('Invoices', 'SerNr', 'descending');
    AddDateFilter('Invoices', myDate.addMonth(myDate.getCurrentDate(), -2), myDate.getCurrentDate());
  }

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          const browseColumns = self.recordWindowDefinition.getBrowseColumns(this);
          return (
            <WindowWrap>
              <RecordList
                table={this.table}
                windowName={'Invoices'}
                update={this.state.update}
                data={this.state.data}
                hasDateFilter={true}
                filters={[
                  {name: translate('Confirmed'), id: 'isok'},
                  {name: translate('NotConfirmed'), id: 'notok'},
                  {name: translate('Paid'), id: 'paid'},
                  {name: translate('NotPaid'), id: 'notpaid'},
                ]}
                browseColumns={browseColumns}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                addNewTitle={translate('AddNewInvoice')}
                navigation={this.props.navigation}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface InvoiceRecordState extends RecordScreenState {
  activeRow: number;
}

export class InvoicesRecord extends RecordScreen<{}, InvoiceRecordState> {
  origrowcnt: number;
  routeParams;

  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new InvoicesRecordScreenDefinition();
    this.CreateSigningActivity = this.CreateSigningActivity.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.origrowcnt = 0;
    this.ScreenName = 'Invoices';
    this.table = 'IVVc';
    this.state = {
      ...this.state,
    };
    this.record = this.createNewRecord();
    this.navigation = props.navigation;
    this.CheckOpenRecord();
    this.newRecordFieldKey = 'CustCode';
  }

  allowsEditAndDelete() {
    return this.record.headerFields.OKFlag !== '1';
  }

  isNewRecordFieldFilled = () => this.record && this.record.headerFields[this.newRecordFieldKey] !== '';

  isNewRecord = () => this.id === 'new';

  createNewRecord = () => {
    return new WindowRecord(
      this.table,
      true,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true,
      this.recordWindowDefinitions.getSideSectionFields(this),
      this.recordWindowDefinitions.getHiddenFields()
    );
  };

  CreateSigningActivity() {
    Requests.doAction('createsigningactivity', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        DoNavigate(this, 'Tasks', true, response.newid);
      })
      .catch((error) => {
        logError(error);
      });
  }

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                key={'invoice' + this.id}
                title={translate('Invoice')}
                window={this}
                headerFields={self.recordWindowDefinitions.getHeaderFields(this)}
                sideSectionFields={self.recordWindowDefinitions.getSideSectionFields(this)}
                forceEditMode={this.isNewRecord()}
                hideActionPane={this.isNewRecord() && this.recordFieldFilled('CustCode') === false}
                record={this.record}
                onClick={this.handleInputClick}
                okcheck={'OKFlag'}
                allowDelete={self.allowsEditAndDelete()}
                allowPrint={IsHRWeb()}
                width={'840px'}
                hasBackButton={true}
                backButton={() => goBack(self, self.ScreenName)}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function Invoices() {
  if (Supported('invoices_v2')) {
    return (
      <Drawer.Navigator
        style={{background: 'white'}}
        screenOptions={{headerShown: false}}
        backBehavior={getDefaultBackBehaviour()}
        drawerStyle={{display: 'none'}}>
        <Drawer.Screen
          name="InvoicesList"
          component={InvoicesList}
          options={{
            title: translate('Invoices'),
          }}
        />
        <Drawer.Screen
          name="InvoicesRecord"
          component={InvoicesRecord}
          backBehavior={getDefaultBackBehaviour()}
          options={{
            title: translate('Invoice'),
          }}
          getId={({params}) => params.id}
        />
      </Drawer.Navigator>
    );
  } else {
    return Invoices_old();
  }
}

export default Invoices;
