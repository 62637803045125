import React from 'react';
import {ColumnContainer, Header6, RowContainer, TextNormalBold} from '@styles/primitiveComponents';
import LanguageSelect from '@components/status/LanguageSelect';
import {ActionButton} from '@components/button/ActionButton';
import {translate} from '@utils/languageTools';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import {logout} from '@utils/loginTools';
import {getSelectedCompanyName} from '@utils/companyTools';
import {OutsideClickWrapper} from '@components/editElements/OutsideClickWrapper';

const ContainerStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  position: 'absolute',
  width: '100vw',
  zIndex: 999,
  borderBottomRightRadius: spacing.space12,
  borderBottomLeftRadius: spacing.space12,
  filter: 'drop-shadow(0 0 4px rgba(128, 128, 128, 0.45))',
};

const HeaderStyle = {
  justifyContent: 'spaceBetween',
  height: 64,
  padding: spacing.space12,
  borderStyle: 'solid',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: colours[GetGlobalTheme()].borderDefault,
};

const CompanyNameStyle = {
  textAlign: 'center',
  color: colours[GetGlobalTheme()].grey800,
};

const EmployeeNameStyle = {
  color: colours[GetGlobalTheme()].grey800,
};

const buttonStyle = {
  justifyContent: 'start',
  backgroundColor: 'none',
  colo: 'blue',
  width: '100%',
  padding: spacing.space12,
};

const buttonText = {
  color: colours[GetGlobalTheme()].secondary,
  alignItems: 'start',
};

type MobileTopDropdownProps = {
  onOutsideClick: () => void;
};

export const MobileTopDropdown: React.FC<MobileTopDropdownProps> = ({onOutsideClick}) => {
  return (
    <OutsideClickWrapper onOutsideClick={onOutsideClick} style={{zIndex: 999}}>
      <ColumnContainer style={ContainerStyle}>
        <RowContainer style={HeaderStyle}>
          <RowContainer>
            <ColumnContainer>
              <Header6 style={EmployeeNameStyle}> {global.userData.empname} </Header6>
              <TextNormalBold style={CompanyNameStyle}> {getSelectedCompanyName()} </TextNormalBold>
            </ColumnContainer>
          </RowContainer>
          <LanguageSelect />
        </RowContainer>
        <ColumnContainer>
          {/*<ActionButton*/}
          {/*  onPress={() => console.log('press')}*/}
          {/*  style={buttonStyle}*/}
          {/*  iconStyle={{order: -1, marginRight: spacing.space4}}*/}
          {/*  textStyle={buttonText}*/}
          {/*  title={translate('MyProfile')}*/}
          {/*  iconName={'BluePersonIcon'}*/}
          {/*/>*/}
          <ActionButton
            onPress={() => global.showSwitchAccount({show: true})}
            style={buttonStyle}
            iconColor={colours[GetGlobalTheme()].secondary}
            iconStyle={{order: -1, marginRight: spacing.space4}}
            textStyle={buttonText}
            title={translate('SwitchAccounts')}
            iconName={'RefreshIcon'}
          />
          <ActionButton
            onPress={() => logout()}
            style={buttonStyle}
            iconColor={colours[GetGlobalTheme()].secondary}
            iconStyle={{order: -1, marginRight: spacing.space4}}
            textStyle={buttonText}
            title={translate('logout')}
            iconName={'LogoutIcon'}
          />
        </ColumnContainer>
      </ColumnContainer>
    </OutsideClickWrapper>
  );
};
