import styled from 'styled-components';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours} from '@styles/Style';
import {ColumnContainer, RowContainer} from '@styles/primitiveComponents';

export const AttachmentWrapper = styled(ColumnContainer)`
  width: 100%;
  align-items: center;
`;

export const MessageText = styled.p`
  display: flex;
  flex-direction: row;
  color: ${colours[GetGlobalTheme()].grey600};
  font-size: 12px;
`;

export const BlueText = styled.span`
  color: ${colours[GetGlobalTheme()].lightBlue};
`;

export const HoverMsg = styled.div`
  border: dashed 2px ${colours[GetGlobalTheme()].grey400};
  border-radius: 5px;
  background-color: ${colours[GetGlobalTheme()].white};
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Description = styled(RowContainer)``;

export const ChildWrapper = styled(ColumnContainer)`
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const IconWrapper = styled(RowContainer)`
  justify-content: center;
  align-items: center;
`;

export const LoadingWrap = styled.div``;

export const LoadingIcon = styled.div`
  border: 8px solid ${colours[GetGlobalTheme()].white};
  border-top: 8px solid ${colours[GetGlobalTheme()].lightBlue};
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
