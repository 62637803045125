import * as RNLocalize from 'react-native-localize';
import i18n from 'i18n-js';
import memoize from 'lodash.memoize';

const translationGetters = {
  en: () => require('../new/assets/translations/en.json'),
  lv: () => require('../new/assets/translations/lv.json'),
  ee: () => require('../new/assets/translations/ee.json'),
};

export const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

export const setI18nConfig = (language) => {
  const fallback = {languageTag: language};
  const {languageTag} =
    RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) ||
    fallback;
  translate.cache.clear();
  i18n.translations = {[languageTag]: translationGetters[language]()};
  i18n.locale = languageTag;
};

export function CurrentLanguage() {
  return global.language;
}

export function MapSystemLanguage(syslang) {
  let res = '';

  switch (syslang) {
    case 'LAT':
      res = 'lv';
      break;
    case 'ENG':
      res = 'en';
      break;
    case 'RUS':
      res = 'en';
      break;
    case 'EST':
      res = 'ee';
      break;
  }
  return res;
}

export function MapLanguage(syslang) {
  let res = '';

  switch (syslang) {
    case 'lv':
      res = 'LAT';
      break;
    case 'en':
      res = 'ENG';
      break;
    case 'ee':
      res = 'EST';
      break;
  }
  return res;
}

export function SystemLanguageMatches(weblang, syslang) {
  let res = false;
  let tstr = MapSystemLanguage(syslang);
  if (weblang === tstr) {
    res = true;
  }
  return res;
}
