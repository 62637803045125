import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import MCIIcon from 'react-native-vector-icons/MaterialCommunityIcons';

const ButtonWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  padding-horizontal: ${spacing.medium}px;
  margin-horizontal: ${spacing.small300}px;
  ${(IsHRWeb() &&
    css`
      margin-vertical: ${spacing.small300};
      box-sizing: border-box;
      height: 35px;
    `) ||
  css`
    justify-content: center;
    height: 45px;
  `}
`;

const InputLabel = styled.View`
  margin-left: 10px;
`;
const InputLabelText = styled.Text`
  color: white;
  font-size: ${fontSize.small200};
  ${IsHRWeb() === false &&
  css`
    font-size: 18px;
  `}
`;

class CheckboxButton extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showCalendar: false,
      px: 0,
      py: 0,
    };
  }

  render() {
    let self = this;
    let styles = {
      backgroundColor: colours[GetGlobalTheme()].buttonBackgroundInactive,
    };
    if (this.props.value === '1') {
      styles.backgroundColor = colours[GetGlobalTheme()].buttonBackground;
    }
    return (
      <ButtonWrapper
        style={{...styles, ...this.props.style}}
        onPress={() => {
          if (self.props.editable) {
            self.props.onChange({
              rowindex: -1,
              fieldname: this.props.name,
              value: this.props.value === '1' ? 0 : 1,
            });
          } else {
            if (self.props.tooltip) {
              global.messageBox({
                showMessage: true,
                statusMessage: self.props.tooltip,
              });
            }
          }
        }}>
        <MCIIcon
          name={this.props.value === '1' ? 'check' : 'square'}
          size={16}
          color="white"
        />
        <InputLabel>
          {this.props.value === '1' && (
            <InputLabelText>{this.props.title}</InputLabelText>
          )}
          {this.props.value !== '1' && (
            <InputLabelText>{this.props.title2}</InputLabelText>
          )}
        </InputLabel>
      </ButtonWrapper>
    );
  }
}

export default CheckboxButton;
