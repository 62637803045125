import React, {useEffect, useState} from 'react';
import {GetGlobalTheme, IsHRWeb} from '../../../../../Tools/DisplayTools';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '@styles/Style';
import {TextNormal} from '@styles/primitiveComponents';
import {getFormattedDisplayValue, GetPasteSpecialValue, isBlockRecord, setPasteSpecialValue} from '@tools/displayTools';
import {objectHasProperty} from '../../../../../Tools/ObjectTools';
import {ToolTipTextProps} from '@components/design/ToolTipText';
import Requests from '../../../../../api/Requests';
import {handleError} from '../../../../../Tools/PortalLog';
import {Dimensions} from '@components/screens/types';
import {isStaticPasteSpecial} from '@utils/pasteSpecialUtils';
import {InputChangeEventProps, PasteSpecial} from '@utils/types';
import {InspectWindow} from '@components/editElements/inspectWindow/InspectWindow';
import {PasteSpecialNameMultiEntry} from '@components/editElements/pasteSpecial/PasteSpecialName/PasteSpecialNameMultiEntry';
import {PasteSpecialNameSingleEntry} from '@components/editElements/pasteSpecial/PasteSpecialName/PasteSpecialNameSingleEntry';

const PasteSpecialNameWrap = styled(TextNormal)<{position?: string; width: number}>`
  background-color: ${colours[GetGlobalTheme()].white};
  position: ${(props) => (props.position ? props.position : 'absolute')};
  top: 7px;
  left: 9px;
  min-height: 24px;
  z-index: 10;

  ${IsHRWeb() &&
  css`
    text-overflow: ellipsis;
  `}
  width: ${(props) => props.width};
  ${IsHRWeb() &&
  css`
    white-space: nowrap;
  `}
`;

const PasteSpecialBubbleWrap = styled(TextNormal)<{position?: string; width: number; height: number}>`
  background-color: ${colours[GetGlobalTheme()].white};
  flex-direction: row;
  position: ${(props) => (props.position ? props.position : 'absolute')};
  top: 4px;
  left: 9px;
  white-space: nowrap;
  height: ${(props) => props.height - spacing.space12}px;
  width: ${(props) => props.width};
`;

const BubbleWrap = styled.View`
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 50px;
  margin-right: ${spacing.space4}px;
  padding: ${spacing.space4}px;
`;

const TextValue = styled.Text`
  position: relative;
  color: ${colours[GetGlobalTheme()].black};
`;

export type MultiEntries = {
  entryName: string;
  entryDescription: string;
};

type PasteSpecialNameProps = {
  dimensions: Dimensions;
  onPress: () => void;
  onChange: (params: InputChangeEventProps) => void;
  value: string;
  row: number;
  name: string;
  pasteSpecial: PasteSpecial;
  inspectWindow?: InspectWindow;
};

export const PasteSpecialName: React.FC<PasteSpecialNameProps> = ({
  dimensions = {width: 245, height: 24},
  onChange,
  value,
  row,
  name,
  pasteSpecial = {
    multiValue: false,
    hideComment: false,
  },
  onPress,
  inspectWindow,
}) => {
  const characterLimit = 30;
  const [pasteSpecialNameArr] = useState<string[]>(getValueArray());
  const [toolTipText, setToolTipText] = useState<string>('');
  const [multiEntries, setMultiEntries] = useState<MultiEntries[]>([]);
  const [record, setRecord] = useState({});

  useEffect(() => {
    initializeComponent();
  }, []);

  function initializeComponent() {
    if (!hasValidValue()) {
      return;
    }

    if (pasteSpecial.multiValue) {
      handleMultiPasteSpecialValue();
      return;
    }

    processSinglePasteSpecialValue();
  }

  function processSinglePasteSpecialValue() {
    if (isStaticPasteSpecial(pasteSpecial.vcName)) {
      let pasteSpecialArr = global.pasteSpecials[pasteSpecial.vcName];
      pasteSpecialArr = pasteSpecialArr.filter((obj) => obj[pasteSpecial.mainKey] === value);
      const fakeResponse = {records: pasteSpecialArr};
      setSinglePasteSpecialValue(fakeResponse);
      return;
    }

    const pasteSpecialRecord = GetPasteSpecialValue(pasteSpecial.vcName, value);
    if (typeof pasteSpecialRecord !== 'object' || pasteSpecialRecord === null) {
      let filter = {};
      filter[pasteSpecial.columns[0].mainKey] = value;
      let fields = '';
      if (objectHasProperty(pasteSpecial, 'pasteSpecialValueFields')) {
        fields = pasteSpecial.pasteSpecialValueFields;
      } else {
        pasteSpecial.columns.map((field) => {
          fields += ',' + field.key;
        });
      }
      Requests.getTableData(pasteSpecial.vcName, filter, fields)
        .then((response) => {
          setSinglePasteSpecialValue(response);
        })
        .catch((error) => {
          handleError(error);
          return;
        });
      return;
    }

    if (pasteSpecial.nameDisplayFormat) {
      setRecord(pasteSpecialRecord);
    }

    if (objectHasProperty(pasteSpecial, 'toolTip')) {
      setToolTipText(pasteSpecialRecord[pasteSpecial.toolTip.key]);
    }
  }

  function setSinglePasteSpecialValue(response) {
    let pasteSpecialRecord;
    if (isBlockRecord(pasteSpecial.vcName)) {
      pasteSpecialRecord = response.records[0].rows.find((record) => record[pasteSpecial.columns[0].mainKey] === value);
    } else {
      pasteSpecialRecord = response.records[0];
    }
    setPasteSpecialValue(pasteSpecial.vcName, value, pasteSpecialRecord);
    pasteSpecialRecord = GetPasteSpecialValue(pasteSpecial.vcName, value); // To get the adjusted object with lowercase letters
    if (pasteSpecial.nameDisplayFormat) {
      setRecord(pasteSpecialRecord);
    }

    if (objectHasProperty(pasteSpecial, 'toolTip')) {
      setToolTipText(pasteSpecialRecord[pasteSpecial.toolTip.key]);
    }
  }

  function handleMultiPasteSpecialValue() {
    const valueArr = value.split2(',');

    const fetchData = (value) => {
      let filter = {};
      filter[pasteSpecial.columns[0].mainKey] = value;
      let fields = pasteSpecial.columns.map((u) => u.key).join(',');
      return Requests.getTableData(pasteSpecial.vcName, filter, fields)
        .then((response) => {
          const pasteSpecialRecord = response.records[0];
          setPasteSpecialValue(pasteSpecial.vcName, value, pasteSpecialRecord);
          return {
            entryName: pasteSpecialRecord[pasteSpecial.mainKey],
            entryDescription: pasteSpecialRecord[pasteSpecial.toolTip.key],
          };
        })
        .catch((error) => {
          handleError(error);
          return null;
        });
    };

    const fetchAllData = async () => {
      const promises = valueArr.map((value) => {
        const pasteSpecialRecord = GetPasteSpecialValue(pasteSpecial.vcName, value);
        if (typeof pasteSpecialRecord !== 'object' || pasteSpecialRecord === null) {
          return fetchData(value);
        } else {
          return Promise.resolve({
            entryName: pasteSpecialRecord[pasteSpecial.mainKey],
            entryDescription: pasteSpecialRecord[pasteSpecial.toolTip.key],
          });
        }
      });

      const results = await Promise.all(promises);
      const validEntries = results.filter((entry) => entry !== null);
      setMultiEntries(validEntries);
    };

    fetchAllData();
  }

  function hasValidValue() {
    return pasteSpecial.hideComment === undefined && value !== '' && value !== undefined;
  }

  function getValueArray(): string[] {
    if (!hasValidValue()) {
      return [];
    }
    return value.split2(',');
  }

  function removeValue(pasteSpecialValue: string) {
    const newPasteSpecial = pasteSpecialNameArr.filter((pasteSpecial) => pasteSpecial != pasteSpecialValue);
    if (!onChange) {
      return;
    }
    onChange({
      rowindex: row,
      fieldname: name,
      value: newPasteSpecial.join(','),
      inspectWindow,
    });
  }

  let entryOverflowRendered = false;
  let charCount = 0;

  function handleFieldPress() {
    onPress();
  }

  function getMultiPasteSpecialComponent() {
    return (
      <PasteSpecialBubbleWrap
        width={dimensions.width > 80 ? dimensions.width - 35 : 80}
        height={dimensions.height}
        onPress={handleFieldPress}>
        {multiEntries.length > 0 &&
          multiEntries.map((entry, index) => {
            const entryLength = entry.entryName.length;
            if (charCount + entryLength <= characterLimit) {
              charCount += entryLength;
              return (
                <PasteSpecialNameMultiEntry
                  key={index}
                  entry={entry}
                  onCrossPress={(entry) => removeValue(entry)}
                  maxCharacters={pasteSpecial.toolTip.maxCharacters}
                />
              );
            } else if (!entryOverflowRendered) {
              entryOverflowRendered = true;
              return (
                <BubbleWrap key={index}>
                  <TextValue>{'+' + (pasteSpecialNameArr.length - index).toString()}</TextValue>
                </BubbleWrap>
              );
            }
            return <> </>;
          })}
      </PasteSpecialBubbleWrap>
    );
  }

  function getPasteSpecialName() {
    let pasteSpecialName = pasteSpecialNameArr.join(' ');
    const hasDisplayFormat = pasteSpecial.nameDisplayFormat && objectHasProperty(record, pasteSpecial.mainKey);
    if (hasDisplayFormat) {
      pasteSpecialName = getFormattedDisplayValue(pasteSpecial.nameDisplayFormat, record);
    }
    return pasteSpecialName;
  }

  function getPasteSpecialToolTip(pasteSpecialName: string) {
    const hasToolTip = objectHasProperty(pasteSpecial, 'toolTip');

    const toolTip: ToolTipTextProps | null = hasToolTip
      ? {
          toolTipText: toolTipText,
          innerText: pasteSpecialName,
          maxCharacters: pasteSpecial.toolTip.maxCharacters,
        }
      : null;

    return toolTip;
  }

  function getSinglePasteSpecialComponent() {
    const pasteSpecialName = getPasteSpecialName();
    const toolTip = getPasteSpecialToolTip(pasteSpecialName);
    const hideRemoveOption = pasteSpecial.hideRemoveOption || isStaticPasteSpecial(pasteSpecial.vcName);

    if (pasteSpecialNameArr.length === 0) {
      return (
        <PasteSpecialNameWrap
          key={'PasteSpecialNameWrap'}
          width={dimensions.width > 80 ? dimensions.width - 35 : 80}
          onPress={handleFieldPress}
        />
      );
    }

    return (
      <PasteSpecialNameWrap
        key={'PasteSpecialNameWrap'}
        width={dimensions.width > 80 ? dimensions.width - 35 : 80}
        onPress={handleFieldPress}>
        <PasteSpecialNameSingleEntry
          name={pasteSpecialName}
          hasToolTip={toolTip !== null}
          toolTip={toolTip}
          hideRemoveOption={hideRemoveOption}
          onCrossPress={(name) => removeValue(name)}
        />
      </PasteSpecialNameWrap>
    );
  }

  return pasteSpecial.hideComment ? (
    <></>
  ) : pasteSpecial.multiValue ? (
    getMultiPasteSpecialComponent()
  ) : (
    getSinglePasteSpecialComponent()
  );
};
