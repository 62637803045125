import React, {useState} from 'react';
import {getAllAllowedDrawers} from '@utils/drawersTools';
import {GetDefaultBackBehaviour, GetGlobalTheme, Supported} from '../../../../Tools/DisplayTools';
import {createDrawerNavigator} from '@react-navigation/drawer';
import Requests from '../../../../api/Requests';
import {logError} from '@utils/debug';
import {ScreenHeader} from '@components/screens/screenHeader/ScreenHeader';
import {DrawerContentWrapper} from '@components/navigation/sideNavigator/DrawerContentWrapper';
import {ScreenContentWrapper} from '@components/navigation/sideNavigator/ScreenContentWrapper';
import {colours} from '@styles/Style';
import {OutsideClickWrapper} from '@components/editElements/OutsideClickWrapper';
import {isMobileOrTabletScreen} from '@tools/displayTools';
import {NoAccess} from '@components/screens/status/NoAccess';

const Drawer = createDrawerNavigator();

global.LoadNotifications = function () {
  if (Supported('activities') && Supported('hasnotifications')) {
    Requests.doAction('getnotifications', {})
      .then((response) => {
        global.setNotifications(response.record);
      })
      .catch((error) => {
        logError(error);
      });
  }
};

export const SideNavigator = () => {
  const [isLoaded, setLoaded] = useState(false);

  if (!isLoaded) {
    global.LoadNotifications();
    setLoaded(true);
  }

  const drawers = getAllAllowedDrawers();
  return (
    <Drawer.Navigator
      key={JSON.stringify(global.userData)}
      openByDefault
      backBehavior={GetDefaultBackBehaviour()}
      drawerType={'permanent'}
      drawerStyle={{
        position: 'fixed',
        top: 0,
        zIndex: 999,
        width: 'auto',
        border: 'none',
      }}
      initialRouteName={drawers[0].name}
      sceneContainerStyle={{backgroundColor: colours[GetGlobalTheme()].white, border: 'none'}}
      drawerContent={(props) => {
        return (
          <OutsideClickWrapper
            style={{margin: 0, padding: 0}}
            onOutsideClick={() => {
              if (isMobileOrTabletScreen()) {
                global.setIsDrawerOpen(false);
              }
            }}>
            <DrawerContentWrapper drawerProps={props} />
          </OutsideClickWrapper>
        );
      }}>
      {drawers.map((drawer, index) => {
        const ScreenComponent = drawer.screen;
        return (
          <Drawer.Screen
            key={index}
            name={drawer.name}
            component={() => (
              <ScreenContentWrapper>
                <ScreenHeader screenName={drawer.title} />
                {isNaN(global.userData.apiversion) && <NoAccess />}
                <ScreenComponent />
              </ScreenContentWrapper>
            )}
          />
        );
      })}
    </Drawer.Navigator>
  );
};

export default SideNavigator;
