import Requests from '../../api/Requests';
import {logError} from './debug';
import {CompanyData} from '@utils/types';

export function switchCompany(companyUuid: string) {
  Requests.switchCompany(companyUuid)
    .then(() => {
      global.refreshScreen(true);
    })
    .catch((error) => {
      logError(error);
    });
}

export function getSelectedCompanyName() {
  const companiesArr: CompanyData[] = global.userData.complist;
  const selectedCompanyArr = companiesArr.filter(
    (company) => company.uuid === global.userData.selectedcomp
  );
  return selectedCompanyArr[0].name;
}
