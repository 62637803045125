import React from 'react';
import {OutsideClickWrapper} from '@components/editElements/OutsideClickWrapper';
import {ScrollViewIndicator} from '@fanchenbao/react-native-scroll-indicator';
import {ActionButton} from '@components/button/ActionButton';
import {translate} from '@utils/languageTools';
import {colours} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import {PasteSpecialHeader} from '@components/editElements/pasteSpecial/pasteSpecialComponents/PasteSpecialHeader';
import {PasteSpecialWindowProps} from '@components/editElements/pasteSpecial/PasteSpecialWindow';
import {
  getPasteSpecialContainerStyle,
  getPasteSpecialScrollContainerStyle,
  PasteSpecialContentStyle,
  PasteSpecialSelectButtonStyle,
  PasteSpecialSelectButtonTextStyle,
} from '@components/editElements/pasteSpecial/pasteSpecial.styles';
import {View, ViewStyle} from 'react-native';

const ScrollWheelStyle: ViewStyle = {
  backgroundColor: colours[GetGlobalTheme()].grey600,
  width: 6,
  borderRadius: 3,
  zIndex: 5,
  marginRight: 4,
};

type PasteSpecialProps = {
  props: PasteSpecialWindowProps;
  recordList: React.ReactElement[];
  isStaticPasteSpecial: boolean;
  bubbleEntries: React.ReactElement[];
  searchRef: React.Ref<any>;
  isDropDownPasteSpecial: boolean;
  onSearchBarKeyPress: (value: number, event: any) => void;
  onSearch: (value: string) => void;
  onInsertCurrentRecord: () => void;
};

export const PasteSpecial: React.FC<PasteSpecialProps> = ({
  props,
  recordList,
  isStaticPasteSpecial,
  bubbleEntries,
  searchRef,
  isDropDownPasteSpecial,
  onSearchBarKeyPress,
  onSearch,
  onInsertCurrentRecord,
}) => {
  return (
    <OutsideClickWrapper onOutsideClick={() => onInsertCurrentRecord()}>
      <View style={getPasteSpecialContainerStyle(isDropDownPasteSpecial)}>
        <View style={PasteSpecialContentStyle}>
          <View>
            <PasteSpecialHeader
              isMultiPasteSpecial={props.isMultiPasteSpecial}
              psheader={props.psheader}
              windowLayout={props.windowLayout}
              isStaticPasteSpecial={isStaticPasteSpecial}
              bubbleEntries={bubbleEntries}
              searchRef={searchRef}
              onSearchBarKeyPress={(value, event) => onSearchBarKeyPress(value, event)}
              onSearch={(value) => onSearch(value)}
            />
          </View>
          <View style={getPasteSpecialScrollContainerStyle(isStaticPasteSpecial)}>
            <ScrollViewIndicator indStyle={ScrollWheelStyle}>{recordList}</ScrollViewIndicator>
          </View>
          {props.isMultiPasteSpecial && (
            <ActionButton
              style={PasteSpecialSelectButtonStyle}
              textStyle={PasteSpecialSelectButtonTextStyle}
              onPress={() => onInsertCurrentRecord}
              title={translate('Select')}
            />
          )}
        </View>
      </View>
    </OutsideClickWrapper>
  );
};
