import {translate} from '@src/utils/languageTools';
import {ViewMode} from '../screens/types';

export function checkUserLeavingEditMode(callBack) {
  if (global.activeEditMode) {
    // We might need this further
    // || (global.activeInspectWindow && global.activeInspectWindow.state.viewMode === ViewMode.Edit)
    global.messageBox2({
      show: true,
      title: translate('LeaveUnsavedTitle'),
      message: translate('LeaveUnsavedDescription'),
      closeAction: () => {
        global.messageBox2({
          show: false,
        });
      },
      buttons: [
        {
          label: translate('LeaveNoSave'),
          onPress: () => {
            global.messageBox2({
              show: false,
              callBack: () => {
                global.activeInspectWindow.props.record.resetRecord();
                global.activeInspectWindow.setState({viewMode: ViewMode.View}, () => {
                  global.activeEditMode = false;
                  callBack();
                });
              },
            });
          },
          type: 'white',
        },
        {
          label: translate('Save'),
          onPress: () =>
            global.messageBox2({
              show: false,
              callBack: () => {
                global.activeInspectWindow.storeWindowRecord(() => callBack());
              },
            }),
        },
      ],
    });
  } else {
    callBack();
  }
}
