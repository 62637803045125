import React, {useEffect, useState} from 'react';
import {Animated, Dimensions} from 'react-native';
import {CustomDrawerContent} from '@components/navigation/sideNavigator/CustomDrawerContent';
import {
  isLargeScreen,
  isMediumScreen,
  isMobileOrTabletScreen,
  isVeryLargeScreen,
  mobileRotateDelay,
  ScreenWidth,
} from '@tools/displayTools';

type DrawerContentWrapperProps = {
  drawerProps: any;
};

export const navigatorAnimationDuration = 250;
export const maxScreenWidth = '1400px';
export const maximizedNavigatorSize = 248;
export const minimizedNavigatorSize = 120;

export const maximizedMobileNavigatorSize = 120;
export const minimizedMobileNavigatorSize = 0;

export function getMaximizedNavigatorSize() {
  if (isMobileOrTabletScreen()) {
    return maximizedMobileNavigatorSize;
  }

  return maximizedNavigatorSize;
}

export function getMinimizedNavigatorSize() {
  if (isMobileOrTabletScreen()) {
    return minimizedMobileNavigatorSize;
  }

  return minimizedNavigatorSize;
}

export const DrawerContentWrapper: React.FC<DrawerContentWrapperProps> = ({drawerProps}) => {
  const [profileInfo, setProfileInfo] = useState({});
  const [drawerScreenWidth, setDrawerScreenWidth] = useState(ScreenWidth());
  const [isSideNavigatorOpen, setIsSideNavigatorOpen] = useState(global.isDrawerOpen);
  const [animatedNavigatorWidth] = useState(
    new Animated.Value(isSideNavigatorOpen ? getMaximizedNavigatorSize() : getMinimizedNavigatorSize())
  );

  global.toggleDrawer = () => {
    toggleNavigatorOpen(!isSideNavigatorOpen);
    global.refreshScreenHeader();
  };

  global.setIsDrawerOpen = (isDrawerOpen: boolean) => {
    toggleNavigatorOpen(isDrawerOpen);
    global.refreshScreenHeader();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    // This event is deprecated but there is no other substitute to it yet - 2024 july
    window.addEventListener('orientationchange', handleOrientationChangeEvent);
    document.addEventListener('visibilitychange', handleOnTabFocus);

    return () => {
      window.removeEventListener('resize', handleResizeEvent);
      window.removeEventListener('orientationchange', handleOrientationChangeEvent);
      document.removeEventListener('visibilitychange', handleOnTabFocus);
    };
  }, []);

  function handleOnTabFocus() {
    if (document.visibilityState === 'visible') {
      handleResizeEvent();
    }
  }

  function handleResizeEvent() {
    if (ScreenWidth() !== drawerScreenWidth) {
      setDrawerScreenWidth(ScreenWidth());
      toggleNavigatorOpen(isVeryLargeScreen() || isLargeScreen() || isMediumScreen());

      global.refreshScreenHeader();
      refreshWindows();
    }
  }

  function handleOrientationChangeEvent() {
    setTimeout(() => {
      refreshWindows();
    }, mobileRotateDelay);
  }

  function refreshWindows() {
    if (typeof global.refreshRecordList === 'function') {
      global.refreshRecordList();
    }
    if (typeof global.refreshRecordScreen === 'function') {
      global.refreshRecordScreen();
    }
    global.refreshScreenHeader();
    if (typeof global.refreshMobileBottomButton === 'function') {
      global.refreshMobileBottomButton();
    }

    if (typeof global.refreshInspectWindow === 'function') {
      global.refreshInspectWindow();
    }
  }

  global.isDrawerOpen = isSideNavigatorOpen;
  global.setNotifications = (record) => setProfileInfo(record);

  function toggleNavigatorOpen(open) {
    Animated.timing(animatedNavigatorWidth, {
      toValue: open ? getMaximizedNavigatorSize() : getMinimizedNavigatorSize(),
      duration: navigatorAnimationDuration,
      useNativeDriver: false,
    }).start(() => {
      setIsSideNavigatorOpen(open);
      global.isDrawerOpen = open;
      global.refreshScreenWidth(open);
    });
  }

  return (
    <Animated.View style={{width: animatedNavigatorWidth, height: Dimensions.get('window').height}}>
      <CustomDrawerContent
        drawerProps={{...drawerProps}}
        isSideNavigatorOpen={isSideNavigatorOpen}
        onMinimizeButtonPress={() => toggleNavigatorOpen(!isSideNavigatorOpen)}
      />
    </Animated.View>
  );
};
