import React, {Component} from 'react';
import {KeyboardAvoidingView} from 'react-native';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRiOS, IsHRWeb} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import ShiftContainer from '../edit_elements/Shifts';
import FieldSection from '../edit_elements/FieldSection';
import {CommentSection} from '../edit_elements/CommentSection';
import {translate} from '@utils/languageTools';
import {AttachmentWindow} from 'src/components/status/AttachmentWindow';
import {ApprovalListWindow} from '../status/ApprovalListWindow';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {MatrixContainer} from './Matrix';
import {WindowDefinition, WindowRecord} from '@utils/types';
import {APISupportsItem} from '@src/utils/drawersTools';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

let InspectWindowContent;
if (isDesktopScreen()) {
  InspectWindowContent = styled.View`
    background-color: ${colours[GetGlobalTheme()].mainBackground};
    padding-bottom: ${spacing.small300};
    width: ${(props) => (props.width ? props.width : '800px')};
    padding-right: ${spacing.medium}px;
  `;
} else {
  InspectWindowContent = styled.ScrollView`
    background-color: ${colours[GetGlobalTheme()].mainBackground};
    padding-bottom: ${spacing.small300};
    width: 97%;
    margin: 0 auto;
  `;
}
const InspectWindowWrapper = styled.View`
  ${(isDesktopScreen() &&
    css`
      /*margin-horizontal: ${spacing.large}px;*/
      flex-direction: row;
      flex-grow: 1;
      margin-vertical: ${spacing.medium}px;
      /*margin-right: ${spacing.medium}px;*/
    `) ||
  css`
    width: 100%;
  `}
`;

const Title = styled.Text`
  font-size: ${fontSize.large};
  color: ${colours[GetGlobalTheme()].titleText};
  padding-vertical: ${spacing.medium200};
  margin-horizontal: ${spacing.small300};
`;

const AttachBadge = styled.View`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
`;
const AttachBadgeText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
  line-height: 20px;
  text-align: center;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  align-items: center;
  ${isDesktopScreen() &&
  css`
    margin-bottom: ${spacing.medium200};
  `}
`;

const HeaderButtonContainer = styled.View`
  flex-direction: row;
  z-index: 1;
  ${isDesktopScreen() &&
  css`
    gap: 10px;
    justify-content: flex-end;
  `}
  ${isMobileOrTabletScreen() &&
  css`
    flex-basis: fit-content;
    flex-wrap: wrap;
    gap: 6px;
  `}
`;

function PopulateColumns(columns, regname) {
  let res = undefined;
  if (columns) {
    res = columns;
    if (columns.updated === undefined) {
      columns.updated = true;
      let ev = columns[0].onchange;
      if (ev === undefined) {
        ev = columns[1].onchange;
      }
      if (global.customfields[regname] !== undefined) {
        let f = global.customfields[regname];
        for (let i = 0; i < f.length; i++) {
          if (f[i].type === '1') {
            let ps_el = null;
            if (f[i].paste !== '') {
              let cols = [];
              if (global.custompastespecials[f[i].paste] !== undefined) {
                let ps = global.custompastespecials[f[i].paste];
                for (let j = 0; j < ps.fields.length; j++) {
                  if (i === 0) {
                    cols.push({
                      name: ps.fields[j].label,
                      width: ps.fields[j].width + '%',
                      mainkey: ps.fields[j].fieldname,
                      key: ps.fields[j].fieldname, //.toLowerCase(),
                    });
                  } else {
                    cols.push({
                      name: ps.fields[j].label,
                      width: ps.fields[j].width + '%',
                      key: ps.fields[j].fieldname, //.toLowerCase(),
                    });
                  }
                }
                ps_el = {
                  ps: ps.vcname,
                  pasteSpecialHeader: cols,
                  pasteSpecialMulti: ps.multi === '1',
                };
              }
            }
            res.push({
              title: f[i].label,
              dataKey: f[i].fieldname,
              width: f[i].width,
              viewOnly: false,
              onchange: ev,
              ...ps_el,
            });
          }
        }
      }
    }
  }
  return res;
}

function PopulateFields(columns, regname) {
  let res = columns;
  if (columns.updated === undefined) {
    columns.updated = true;
    if (global.customfields[regname] !== undefined) {
      let ev = columns[0].fields[0].onChange;
      if (ev === undefined) {
        ev = columns[0].fields[1].onChange;
      }
      let f = global.customfields[regname];
      for (let i = 0; i < f.length; i++) {
        if (f[i].type === '0') {
          let ps_el = null;
          if (f[i].paste !== '') {
            let cols = [];
            if (global.custompastespecials[f[i].paste] !== undefined) {
              let ps = global.custompastespecials[f[i].paste];
              for (let j = 0; j < ps.fields.length; j++) {
                if (i === 0) {
                  cols.push({
                    name: ps.fields[j].label,
                    width: ps.fields[j].width + '%',
                    mainkey: ps.fields[j].fieldname,
                    key: ps.fields[j].fieldname, //.toLowerCase(),
                  });
                } else {
                  cols.push({
                    name: ps.fields[j].label,
                    width: ps.fields[j].width + '%',
                    key: ps.fields[j].fieldname, //.toLowerCase(),
                  });
                }
              }
              ps_el = {
                pasteSpecial: {
                  vcName: ps.vcname,
                  columns: cols,
                  multiValue: ps.multi === '1',
                },
              };
            }
          }
          res[res.length - 1].fields.push({
            title: f[i].label,
            dataKey: f[i].fieldname,
            width: f[i].width,
            viewOnly: false,
            onChange: ev,
            ...ps_el,
          });
        }
      }
    }
  }
  return res;
}

function PopulateRecordFields(record, regname) {
  let res = record;
  if (Object.keys(res).length > 0) {
    if (global.customfields[regname] !== undefined) {
      let f = global.customfields[regname];
      for (let i = 0; i < f.length; i++) {
        if (f[i].type === '0') {
          if (objectHasProperty(res, f[i].fieldname) === false) {
            res[f[i].fieldname] = '';
          }
        }
        if (f[i].type === '1') {
          if (objectHasProperty(res, 'rows')) {
            for (let j = 0; j < res.rows.length; j++) {
              if (objectHasProperty(res.rows[j], f[i].fieldname) === false) {
                res.rows[j][f[i].fieldname] = '';
              }
            }
          }
        }
      }
    }
  }
  return res;
}

type InspectWindowProps = {
  title: string;
  window: any;
  headerFields: WindowDefinition;
  record: WindowRecord;
  allowDelete: boolean;
  okcheck: string;
  backButton: () => void;
};

export class InspectWindow extends Component<InspectWindowProps, {}> {
  constructor(props) {
    super(props);
    let self = this;
    this.state = {
      windoww: 0,
      pwidth: 0,
      attachw: 0,
      attachments: {
        showAttachments: false,
      },
      approvals: {
        showApprovalPersons: false,
      },
      table: this.props.window.table,
      id: this.props.window.id,
      id2: this.props.window.id,
      attach_cnt: 0,
      approval_cnt: 0,
    };
    if (this.props.window.state.approvallistid) {
      this.state.id2 = this.props.window.state.approvallistid;
    }
    global.setAttachmentBadge = (cnt) => {
      self.setState({attach_cnt: cnt});
    };
    global.setApprovalListBadge = (cnt) => {
      self.setState({approval_cnt: cnt});
    };
  }

  OpenAttachmentsMobile = () => {
    global.setAttachmentInfo({
      showAttachments: true,
      table: this.state.table,
      id: this.state.id,
    });
  };
  OpenAttachments = () => {
    if (this.state.attachments.showAttachments) {
      this.setState({attachments: {showAttachments: false}});
    } else {
      this.setState({
        attachments: {showAttachments: true},
        approvals: {showApprovalPersons: false},
      });
    }
  };

  componentDidUpdate() {
    if (this.state.id !== this.props.window.id) {
      this.setState({id: this.props.window.id});
    }
    if (this.props.window.state.approvallistid) {
      if (this.state.id2 !== this.props.window.state.approvallistid) {
        this.setState({id2: this.props.window.state.approvallistid});
      }
    } else {
      if (this.state.id2 !== this.props.window.id) {
        this.setState({id2: this.props.window.id});
      }
    }
  }

  GetAttachBadge = () => {
    let res = [];

    if (this.state.attach_cnt > 0) {
      res.push(
        <AttachBadge>
          <AttachBadgeText>{this.state.attach_cnt}</AttachBadgeText>
        </AttachBadge>
      );
    } else {
      res.push(<></>);
    }
    return res;
  };

  GetApprovalListBadge = () => {
    let res = [];

    if (this.state.approval_cnt > 0) {
      res.push(
        <AttachBadge>
          <AttachBadgeText>{this.state.approval_cnt}</AttachBadgeText>
        </AttachBadge>
      );
    } else {
      res.push(<></>);
    }
    return res;
  };

  OpenApprovalsMobile = () => {
    global.setApprovalInfo({
      showApprovalPersons: true,
      table: this.state.table,
      id: this.state.id2,
    });
  };
  OpenApprovalPersons = () => {
    if (this.state.approvals.showApprovalPersons) {
      this.setState({approvals: {showApprovalPersons: false}});
    } else {
      this.setState({
        approvals: {showApprovalPersons: true},
        attachments: {showAttachments: false},
      });
    }
  };

  WindowHeader = () => {
    let self = this;
    if (IsHRWeb() === false) {
      let buttons = [];
      if (this.props.backButton) {
        buttons.push(
          <ActionButton
            onPress={() => this.props.backButton()}
            iconName={'angle-double-left'}
            iconColor={colours[GetGlobalTheme()].buttonText}
            style={{marginHorizontal: 0, marginRight: 5}}
          />
        );
      }
      if (this.props.allowPrint) {
        buttons.push(<ActionButton onPress={() => this.props.window.downloadDocument()} title={translate('Print')} />);
      }
      if (APISupportsItem('attachments')) {
        buttons.push(<ActionButton onPress={() => self.OpenAttachmentsMobile()} title={translate('Attachments')} />);
      }
      if (APISupportsItem('activities') && this.props.hasApprovals) {
        buttons.push(<ActionButton onPress={() => self.OpenApprovalsMobile()} title={translate('Approvals')} />);
      }
      global.windowNewButton = <HeaderButtonContainer>{buttons}</HeaderButtonContainer>;
      global.setWindowTitle(this.props.title);
    }
    let style = {
      //marginLeft: spacing.small300 * 2,
      //marginRight: 0,
    };
    let style2 = {marginLeft: 0};
    //        {IsHRWeb() && <Title>{this.props.title}</Title>}
    if (isMobileOrTabletScreen()) {
      style = {marginHorizontal: 0, marginBottom: 0, marginTop: 4};
      style2 = {marginHorizontal: 0, marginBottom: 0, marginTop: 4};
    }
    return (
      <HeaderContainer>
        {IsHRWeb() && (
          <HeaderButtonContainer>
            {this.props.backButton && (
              <ActionButton style={style2} onPress={() => this.props.backButton()} title={translate('BackToList')} />
            )}
            {this.props.allowPrint && (
              <ActionButton
                style={style}
                onPress={() => this.props.window.downloadDocument()}
                title={translate('Print')}
              />
            )}
            {APISupportsItem('attachments') && (
              <ActionButton
                style={style}
                onPress={() => self.OpenAttachments()}
                title={translate('Attachments')}
                extra={self.GetAttachBadge()}
              />
            )}
            {APISupportsItem('activities') && this.props.hasApprovals && (
              <ActionButton
                style={style}
                key={this.state.approval_cnt}
                onPress={() => self.OpenApprovalPersons()}
                title={translate('Approvals')}
                extra={self.GetApprovalListBadge()}
              />
            )}
            {this.props.allowDelete && (
              <ActionButton
                style={style}
                onPress={() => self.props.window.setState({activeDelete: true})}
                title={translate('Delete')}
              />
            )}
          </HeaderButtonContainer>
        )}
      </HeaderContainer>
    );
  };

  render() {
    let self = this;
    let w = this.props.width;
    let style = {};
    /*
    if (this.props.minwidth) {
      w = '100%';
      style.minWidth = this.props.minwidth;
      style.maxWidth = this.props.maxwidth;
    } else {
      if (w) {
        if (w.indexOf('px') > 0) {
          w = parseInt(w);
          this.state.windoww = w;
        }
        if (IsHRWeb() === false) {
          w = '100%';
        }
      }
    }
*/
    w = '100%';
    if (Object.entries(this.props.record).length > 1) {
      let beh = '';
      if (IsHRWeb() === false) {
        if (IsHRiOS()) {
          beh = 'position';
        } else {
          beh = 'height';
        }
      }

      return (
        <KeyboardAvoidingView behavior={beh}>
          <InspectWindowWrapper key={w}>
            <InspectWindowContent width={w} style={style}>
              {this.WindowHeader()}
              {this.props.headerFields && (
                <FieldSection
                  style={{zIndex: 1}}
                  fields={this.props.headerFields}
                  record={this.props.record}
                  onClick={this.props.onClick}
                  okcheck={this.props.okcheck}
                />
              )}
              {this.props.matrixFields !== undefined &&
                this.props.record.hasMatrix &&
                this.props.shiftmode === undefined && (
                  <MatrixContainer
                    data={this.props.record.matrixRows}
                    columns={this.props.matrixFields}
                    record={this.props.record}
                    okcheck={this.props.okcheck}
                    title={this.props.title}
                    table={this.props.window}
                    width={this.props.width}
                    minwidth={this.props.minwidth}
                    showrow={this.props.showrow}
                    splitrow={this.props.splitrow}
                    GetMatrixRowStyle={this.props.GetMatrixRowStyle}
                    splitrowend={this.props.splitrowend}
                    showemptymatrix={this.props.showemptymatrix}
                    afterrow={this.props.afterrow}
                    pagination={this.props.record.pagination}
                    rowactions={this.props.rowactions}
                    custommatrixdata={this.props.custommatrixdata}
                    matrixFooterFields={this.props.matrixFooterFields}
                  />
                )}
              {this.props.comments !== undefined && (
                <CommentSection comments={this.props.comments} record={this.props.record} table={this.props.window} />
              )}
              {this.props.shiftmode !== undefined && this.props.record.hasMatrix && (
                <ShiftContainer
                  data={this.props.record.matrixRows}
                  columns={this.props.shiftmode}
                  record={this.props.record}
                  okcheck={this.props.okcheck}
                  title={this.props.title}
                  width={this.props.width}
                  splitrow={this.props.splitrow}
                  splitrowend={this.props.splitrowend}
                  afterrow={this.props.afterrow}
                  rowactions={this.props.rowactions}
                  pagination={this.props.pagination}
                />
              )}
              {this.props.footerFields && (
                <FieldSection
                  fields={this.props.footerFields}
                  record={this.props.record}
                  onClick={this.props.onClick}
                />
              )}
            </InspectWindowContent>
            {IsHRWeb() && (
              <AttachmentWindow
                key={this.state.attachments.showAttachments + ':' + this.state.id + this.state.attachw}
                visible={this.state.attachments.showAttachments}
                table={this.state.table}
                id={this.state.id}
                action={() => self.OpenAttachments()}
                freezeattach={this.props.freezeattach}
                width={this.state.attachw}
              />
            )}
            {IsHRWeb() && (
              <ApprovalListWindow
                key={this.state.approvals.showApprovalPersons + ':' + this.state.id}
                visible={this.state.approvals.showApprovalPersons}
                action={() => self.OpenApprovalPersons()}
                table={this.state.table}
                window={this.props.window}
                id={this.state.id2}
              />
            )}
          </InspectWindowWrapper>
        </KeyboardAvoidingView>
      );
    } else {
      return <></>;
    }
  }
}
