import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {RowContainer} from '@styles/primitiveComponents';

const OutsideClickWrapperContainer = styled(RowContainer)`
  margin: 0;
  padding: 0;
  background: none;
`;

type OutsideClickWrapperProps = {
  children: any;
  onOutsideClick: (e: any) => void;
  style?: any;
};

export const OutsideClickWrapper: React.FC<OutsideClickWrapperProps> = ({children, onOutsideClick, style}) => {
  const outsideRef = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (outsideRef.current && !outsideRef.current.contains(event.target)) {
        handleClickOutside(event);
      }
    };

    document.addEventListener('click', handleClick);
  }, [outsideRef]);

  function handleClickOutside(event) {
    onOutsideClick(event);
  }

  return (
    <OutsideClickWrapperContainer ref={outsideRef} style={style}>
      {children}
    </OutsideClickWrapperContainer>
  );
};
