import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {TextNormal} from '@styles/primitiveComponents';

const TextContainer = styled.View`
  white-space: nowrap;
  display: inline-block;
`;

export type ToolTipTextProps = {
  innerText: string | number;
  toolTipText: string | number;
  maxCharacters: number;
};

export const ToolTipText: React.FC<ToolTipTextProps> = ({innerText, toolTipText, maxCharacters}) => {
  const [layout, setLayout] = useState<{x: number; y: number; width: number}>({x: 0, y: 0, width: 0});
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (isHovering) {
      showToolTip();
      return;
    }

    hideToolTip();
    return;
  }, [isHovering]);

  function showToolTip() {
    global.setToolTip({
      show: true,
      text: toolTipText ? toolTipText.toString() : '',
      positionX: layout.x,
      positionY: layout.y,
      fieldWidth: layout.width,
      maxCharacters: maxCharacters,
    });
  }

  function hideToolTip() {
    global.setToolTip({
      show: false,
      text: '',
      positionX: 0,
      positionY: 0,
      fieldWidth: layout.width,
      maxCharacters: maxCharacters,
    });
  }

  return (
    <TextContainer>
      <TextNormal // @ts-ignore
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        // @ts-ignore
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          let top = layout.top;
          let left = layout.left;
          if (window.scrollY) {
            top = top + window.scrollY;
          }
          setLayout({x: left, y: top, width: layout.width});
        }}>
        {innerText}
      </TextNormal>
    </TextContainer>
  );
};
