import React, {Component} from 'react';
import Requests from '../../api/Requests';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import {
  ConvertDisplayValue,
  GetGlobalTheme,
  IsHRWeb,
  WindowElementType,
} from '../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import EditField from './EditField';
import {ActionButton} from '../button/ActionButton';
import {logError} from '@utils/debug';

const CommentSectionWrap = styled.View`
  background-color: ${colours[GetGlobalTheme()].topNavigationBackground};
  display: flex;
  flex-shrink: 1;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-top: ${spacing.medium200};
  padding-bottom: ${spacing.small300};
  font-family: 'Open Sans';
  font-size: 14;
  color: ${colours[GetGlobalTheme()].inputText};
`;

const CommentWrap = styled.View`
  padding-vertical: ${spacing.small300};
  padding-horizontal: ${spacing.small300};
  margin-bottom: ${spacing.small300};
  background-color: ${(props) =>
    props.index % 2
      ? colours[GetGlobalTheme()].inputBackground
      : 'transparent'};
`;
const CommentTop = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: ${spacing.medium};
`;
const CommentAuthor = styled.View`
  margin-right: ${spacing.medium};
`;
const CommentDateTime = styled.View``;

const CommentTextWrap = styled.View``;

const CommentText = styled.Text`
  ${IsHRWeb() &&
  css`
    white-space: break-spaces;
  `}
  color: ${colours[GetGlobalTheme()].titleText};
`;
const CommentAuthorText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;
const CommentDateTimeText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;
const NewCommentWrap = styled.View``;

export class CommentSection extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      text: '',
    };
  }

  SingleComment = (index, comment) => {
    return (
      <CommentWrap index={index}>
        <CommentTop>
          <CommentAuthor>
            <CommentAuthorText>{comment.sender}</CommentAuthorText>
          </CommentAuthor>
          <CommentDateTime>
            <CommentDateTimeText>
              {ConvertDisplayValue(
                WindowElementType.kInputTypeDate,
                comment.transdate
              ) +
                ' ' +
                comment.transtime}
            </CommentDateTimeText>
          </CommentDateTime>
        </CommentTop>
        <CommentTextWrap>
          <CommentText>{comment.text}</CommentText>
        </CommentTextWrap>
      </CommentWrap>
    );
  };

  addComment = () => {
    let self = this;
    Requests.doAction('addcomment', {
      id: self.props.table.state.CommentSerNr,
      list: [
        {
          field: 'Math',
          value: self.state.text,
        },
        {
          field: 'table',
          value: self.props.table.table,
        },
      ],
    })
      .then(() => {
        self.props.table.rowClick(self.props.table.id);
      })
      .catch((error) => {
        logError(error);
      });
  };

  NewComment = () => {
    let self = this;
    return (
      <NewCommentWrap>
        <EditField
          title={translate('Text')}
          viewOnly={false}
          type={WindowElementType.kInputTypeLongText}
          width={'100%'}
          inputStyle={{height: 200}}
          onChange={({value}: InputChangeEventProps) => {
            self.state.text = value;
          }}
        />
        <ActionButton
          onPress={() => self.addComment()}
          title={translate('Save')}
        />
      </NewCommentWrap>
    );
  };

  CommentSectionContent = () => {
    let res = [];
    let self = this;
    this.props.comments.map(function (el, index) {
      res.push(self.SingleComment(index, el));
    });
    res.push(self.NewComment());

    return res;
  };

  render() {
    let width = '96%';
    if (IsHRWeb()) {
      width =
        parseInt(this.props.width, 10) - parseInt(spacing.small300, 10) * 2;
    }
    return (
      <CommentSectionWrap width={width}>
        {this.CommentSectionContent()}
      </CommentSectionWrap>
    );
  }
}
