import React, {useState} from 'react';
import {Header4, Header6, RowContainer} from '@styles/primitiveComponents';
import LanguageSelect from '@components/status/LanguageSelect';
import {colours, spacing} from '@styles/Style';
import {
  GetGlobalTheme,
  isDesktopScreen,
  isMobileOrTabletScreen,
  isMobileScreen,
  isTabletScreen,
  ScreenWidth,
} from '@tools/displayTools';
import {ActionButton} from '@src/components/button/ActionButton';
import {MobileTopDropdown} from '@components/navigation/MobileTopDropdown';
import {getSelectedCompanyName} from '@utils/companyTools';
import {ProfileIcon} from '@src/components/status/ProfileIcon';

type ScreenHeaderProps = {
  screenName: string;
};

export const mobileScreenHeaderHeight = 48;

const CompanyNameStyle = {
  color: colours[GetGlobalTheme()].white,
  fontSize: spacing.space12,
};

const ProfileDataStyle = {
  height: '100%',
  width: '100%',
  justifyContent: 'end',
  alignItems: 'center',
  flex: 1,
};

const MobileNavigatorXButtonStyle = {
  background: 'none',
  margin: 0,
  width: 30,
  height: 30,
  padding: 0,
  backgroundRadius: 8,
  marginRight: spacing.space8,
};

export const ScreenHeader = ({screenName}: ScreenHeaderProps) => {
  const [isMaxSizeScreen, setIsMaxSizeScreen] = useState<boolean>(isDesktopScreen());
  const [isMobileDropdownActive, setIsMobileDropdownActive] = useState<boolean>(false);
  const [topBarComponent, setTopBarComponent] = useState();
  const [screenWidth, setScreenWidth] = useState(ScreenWidth());

  global.refreshScreenHeader = () => {
    handleResizeEvent();
  };

  function handleResizeEvent() {
    setScreenWidth(ScreenWidth());
    setIsMaxSizeScreen(isDesktopScreen());
  }

  const ScreenNameStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'normal',
    color: isMobileScreen() || isTabletScreen() ? colours[GetGlobalTheme()].white : colours[GetGlobalTheme()].grey800,
  };

  const MobileScreenNameStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'normal',
    color: isMobileScreen() || isTabletScreen() ? colours[GetGlobalTheme()].white : colours[GetGlobalTheme()].grey800,
  };

  const ScreenHeaderContainerStyle = {
    paddingTop: `${spacing.space24}px`,
    paddingLeft: isMaxSizeScreen ? '0' : `${spacing.space20}px`,
    paddingRight: isMaxSizeScreen ? '0' : `${spacing.space20}px`,
    width: '100%',
  };

  function getDesktopComponent() {
    return (
      <RowContainer style={ScreenHeaderContainerStyle}>
        <Header4 style={ScreenNameStyle}> {screenName} </Header4>
        <LanguageSelect />
      </RowContainer>
    );
  }

  const MobileScreenHeaderContainerStyle = {
    backgroundColor: colours[GetGlobalTheme()].primary,
    padding: spacing.space12,
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'start',
    height: mobileScreenHeaderHeight,
    width: screenWidth,
  };

  global.setMobileTopBar = (component) => {
    setTopBarComponent(component);
  };

  function handleMobileProfileIconClick() {
    global.setIsDrawerOpen(false);
    setIsMobileDropdownActive(!isMobileDropdownActive);
  }

  function getMobileComponent() {
    return (
      <>
        <RowContainer style={MobileScreenHeaderContainerStyle}>
          <ActionButton
            onPress={() => global.toggleDrawer()}
            iconName={'ExcellentLogoX'}
            iconSize={24}
            style={MobileNavigatorXButtonStyle}
          />
          {topBarComponent}
          <Header6 style={MobileScreenNameStyle}> {screenName} </Header6>
          <RowContainer style={ProfileDataStyle}>
            <Header6 style={CompanyNameStyle}> {getSelectedCompanyName()}</Header6>
            <ProfileIcon
              onPress={handleMobileProfileIconClick}
              style={{
                marginLeft: spacing.space8,
                padding: 16,
                aspectRatio: 1,
                height: 12,
                width: 12,
                borderRadius: '100%',
              }}
              textStyle={{
                fontSize: '16px',
              }}
            />
          </RowContainer>
        </RowContainer>
        {isMobileDropdownActive && <MobileTopDropdown onOutsideClick={() => setIsMobileDropdownActive(false)} />}
      </>
    );
  }

  return <>{isMobileOrTabletScreen() ? getMobileComponent() : getDesktopComponent()}</>;
};
