import React from 'react';
import {colours} from '../styles/Style';
import {Dimensions, Platform, Text, View} from 'react-native';
import {extractDateFromString} from './DateTools';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {translate} from '@utils/languageTools';
import {objectHasProperty} from './ObjectTools';
import {logError} from '@utils/debug';
import {OverwriteBrowseColumnProps} from '../utils/types';
import {BackBehavior} from '@react-navigation/routers/src/TabRouter';
import {APISupportsItem} from '@src/utils/drawersTools';

export const enum WindowElementType {
  kInputTypeNone = -1,
  kInputTypeText = 0,
  kInputTypeNumber = 1,
  kInputTypeDate = 2,
  kInputTypeCheckbox = 3,
  kInputTypeButton = 4,
  kInputTypeVal = 5,
  kInputTypeBarCode = 6,
  kInputTypeQty = 7,
  kInputTypeJointField = 8,
  kInputTypeLongText = 9,
  kInputTypeStaticText = 10,
  kInputTypeNumber2 = 11,
  kInputTypeValFull = 12,
}

// const DataTypes = {
//   kInputTypeNone: -1,
//   kInputTypeText: 0,
//   kInputTypeNumber: 1,
//   kInputTypeDate: 2,
//   kInputTypeCheckbox: 3,
//   kInputTypeButton: 4,
//   kInputTypeVal: 5,
//   kInputTypeBarCode: 6,
//   kInputTypeQty: 7,
//   kInputTypeJointField: 8,
//   kInputTypeLongText: 9,
//   kInputTypeStaticText: 10,
//   kInputTypeNumber2: 11,
//   kInputTypeValFull: 12,
// };

const WindowTypes = {
  Browse: 0,
  Inspect: 1,
};

export async function storeData(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    logError(error);
    // saving error
  }
}

export async function getData(key) {
  let value = null;
  try {
    value = await AsyncStorage.getItem(key);
  } catch (error) {
    // error reading value
    logError(error);
  }
  return value;
}

export async function getAllData() {
  let value = null;
  try {
    value = await AsyncStorage.multiGet(['@language', '@colortheme', '@windowstates', '@langcode']);
  } catch (error) {
    // error reading value
    logError(error);
  }
  return value;
}

function IsHRWeb() {
  return Platform?.OS === 'web';
}

function IsHRAndroid() {
  return Platform?.OS === 'android';
}

function IsHRiOS() {
  return Platform?.OS === 'ios';
}

function isHRiOSWeb() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  );
}

function IsManager() {
  return global.userData.deplist !== '' || global.userData.admin === '1';
}

function IsSmallScreen() {
  let res = false;
  if (IsHRWeb() === false) {
    if (Dimensions.get('window').height < 800) {
      res = true;
    }
  }
  return res;
}

function IsNarrowScreen() {
  let res = false;
  if (IsHRWeb() === false) {
    if (Dimensions.get('window').width < 500) {
      res = true;
    }
  }
  return res;
}

function ScreenWidth() {
  // TODO: Removed if IsHRWeb check, make sure this doesnt break everything
  return Dimensions.get('window').width;
}

function ScreenHeight() {
  return Dimensions.get('window').height;
}

function GetUserTheme(): string {
  let colorTheme = '';
  let cookie;
  if (IsHRWeb()) {
    cookie = document.cookie.split('; ').find((row) => row.startsWith('colortheme='));
    if (cookie) {
      colorTheme = cookie.split('=')[1];
      return colorTheme;
    }
  }
  //else {
  //  colorTheme = Cookie.get('http://hrportal.excellent.lv','colortheme');
  //}
  const isColorThemeValid = colorTheme === 'dark' || colorTheme === 'light';
  if (!isColorThemeValid) {
    return 'light';
  }
  return 'light';
}

function GetGlobalTheme(): string {
  return global.theme || GetUserTheme() || 'light';
}

function pad(s, num = 2) {
  let res = s.toString();
  if (res.length < num) {
    let l = res.length;
    for (let i = l; i < num; i++) {
      res = '0' + res;
    }
  }
  return res;
}

function ConvertDateField(value) {
  let res = '';
  if (value !== '' && value !== undefined) {
    let td = extractDateFromString(value);
    res = global.userData.dateformat.replace('YYYY', td.getFullYear());
    res = res.replace('MM', pad(td.getMonth() + 1));
    res = res.replace('DD', pad(td.getDate()));
  }
  return res;
}

function ConvertValField(value, datatype) {
  let res = value;
  if (typeof res === 'number') {
    res = res.toString();
  }
  if (value !== undefined) {
    if (value === '') {
      res = ''; //'0.00';
      return res;
    }
    let negf = false;
    let tmp = parseFloat(res);
    if (tmp < 0) {
      negf = true;
      res = res.substring(1);
    }
    let tval = Math.round(parseFloat(res) * 100) / 100;
    let tint = parseInt(res);
    let thousands = Math.floor(tint / 1000);
    let rest = tint - thousands * 1000;
    let dec = Math.abs(Math.round(tval * 100) - tint * 100);

    res = '';
    if (thousands > 0) {
      res = thousands;
      if (global.userData.thousand_separator) {
        res += global.userData.thousand_separator;
      }
      res += pad(rest, 3);
    } else {
      res += rest;
    }
    if (dec > 0 || datatype === WindowElementType.kInputTypeValFull) {
      res = res + global.userData.dec_separator;
      let decstr = dec.toString();
      if (decstr.length === 1) {
        decstr = '0' + decstr;
      }
      res = res + decstr;
      while (res.slice(-1) === '0' && datatype !== WindowElementType.kInputTypeValFull) {
        res = res.slice(0, -1);
      }
    }
    if (negf) {
      res = '-' + res;
    }
  }

  return res;
}

function ConvertValFieldBack(value) {
  let res = value;
  if (value !== undefined) {
    res = res.replace(global.userData.dec_separator, '.');
    res = res.replace(',', '.');
    if (global.userData.thousand_separator) {
      res = res.replace(global.userData.thousand_separator, '');
    }
  }
  return res;
}

function ConvertDisplayValue(datatype, value, value2 = '') {
  let res = value;
  switch (datatype) {
    case WindowElementType.kInputTypeVal:
    case WindowElementType.kInputTypeValFull:
      res = ConvertValField(value, datatype);
      break;
    case WindowElementType.kInputTypeDate:
      res = ConvertDateField(value);
      break;
    case WindowElementType.kInputTypeJointField:
      res = value + ' ' + value2;
      break;
  }

  return res;
}

function RevertDisplayValue(datatype, value) {
  let res = value;
  switch (datatype) {
    case WindowElementType.kInputTypeVal:
    case WindowElementType.kInputTypeValFull:
      res = ConvertValFieldBack(value);
      break;
  }

  return res;
}

function ShouldRefreshWindow(self) {
  let res = false;
  if (
    self.props.route.params &&
    (objectHasProperty(self.props.route.params, 'id') ||
      (objectHasProperty(self.props.route.params, 'update') && self.state.WindowType === WindowTypes.Inspect))
  ) {
    res = true;
  }

  return res;
}

function DisplayBrowseOKFlag({value}: OverwriteBrowseColumnProps) {
  let res = '';
  switch (value) {
    case '1':
      res = <Text>✔</Text>;
      break;
    case '45':
      res = <Text>-</Text>;
      break;
    default:
      res = <Text></Text>;
  }
  return res;
}

function DisplayBrowseClosed({value}: OverwriteBrowseColumnProps) {
  if (value === '1') {
    return <Text>✔</Text>;
  } else {
    return <Text></Text>;
  }
}

function DisplayApprovalStatus(value, curval, rowindex, colindex, row): string {
  let res = ' ';
  switch (curval) {
    case '-1':
      if (row !== undefined && row['okflag'] === '1') {
        res = String.fromCharCode(10004);
      }
      break;
    case '1':
      res = String.fromCharCode(10067);
      break;
    case '2':
      res = String.fromCharCode(10071);
      break;
    case '3':
      res = String.fromCharCode(8987);
      break;
    case '4':
      res = String.fromCharCode(10004);
      break;
    case '5':
      res = String.fromCharCode(10060);
      break;
  }
  return res;
}

function GetApprovalStatusText(value) {
  let res = '';

  switch (value) {
    case '1':
      res = translate('ApprNotStarted');
      break;
    case '2':
      res = translate('ApprNotRequested');
      break;
    case '3':
      res = translate('ApprPending');
      break;
    case '4':
      res = translate('ApprApproved');
      break;
    case '5':
      res = translate('ApprRejected');
      break;
  }
  return res;
}

function ResetRecord(self, index, rowindex, value) {
  let trec = self.state.record;
  let tval = '';
  if (rowindex > -1) {
    tval = self.state.record.rows[rowindex][index];
    trec.rows[rowindex][index] = value;
  } else {
    tval = self.state.record[index];
    trec[index] = value;
  }
  self.setState({record: trec}, function () {
    if (rowindex > -1) {
      trec.rows[rowindex][index] = tval;
    } else {
      trec[index] = tval;
    }
    self.setState({record: trec});
  });
}

function goBack(window, name) {
  //window.navigation.goBack();
  DoNavigate(window, name, false, '', true, true);
}

function GetPasteSpecialValue(ps, id) {
  let res;

  let vector = global.pasteSpecialsVector[ps];
  if (vector) {
    res = vector[id];
  }

  return res;
}

function SetPasteSpecialValue(ps, id, value) {
  let vector = global.pasteSpecialsVector[ps];
  if (vector === undefined) {
    global.pasteSpecialsVector[ps] = {};
  }
  global.pasteSpecialsVector[ps][id] = value;
}

function GetDefaultBackBehaviour(): BackBehavior {
  const backBehavior: BackBehavior = 'history';
  return backBehavior;
}

function WindowWrap(props) {
  return (
    <View
      style={{
        width: '100%',
        background: colours[GetGlobalTheme()].mainBackground,
        height: '100%',
      }}>
      {props.children}
    </View>
  );
}

function GetParameterID(window) {
  let res = '';

  if (window.props.route?.params && window.props.route.params.id) {
    res = window.props.route.params.id;
  }
  if (window.props.route?.params && window.props.route?.params.params && window.props.route?.params.params.id) {
    res = window.props.route.params.params.id;
  }

  return res;
}

function DoNavigate(base, basescreen, record = false, id = '', updatef = false, list = false, custompath = '') {
  let params = {};
  if (updatef) {
    params.update = true;
  }
  if (id !== '') {
    params.id = id;
  }
  let screen = '';
  if (record) {
    screen = basescreen + 'Record';
  }
  if (list) {
    screen = basescreen + 'List';
  }
  if (custompath !== '') {
    screen = basescreen + custompath;
  }
  let obj = {};
  if (screen !== '') {
    obj = {screen: screen, params: params};
  } else {
    obj = params;
  }
  global.ClearEscapeActions();
  base.navigation.navigate(basescreen, obj);
}

export function ShowBreakHours() {
  return objectHasProperty(shiftBlock, 'showbreakhours') && global.shiftBlock.showbreakhours === '1';
}

export function UsePagination() {
  return APISupportsItem('haspagination');
}

export function Supported(app) {
  return APISupportsItem(app);
}

export {
  GetUserTheme,
  GetGlobalTheme,
  IsHRWeb,
  IsHRAndroid,
  ConvertDateField,
  WindowTypes,
  ConvertDisplayValue,
  RevertDisplayValue,
  ShouldRefreshWindow,
  DisplayBrowseOKFlag,
  DisplayBrowseClosed,
  IsManager,
  IsSmallScreen,
  IsNarrowScreen,
  GetApprovalStatusText,
  DisplayApprovalStatus,
  IsHRiOS,
  goBack,
  GetPasteSpecialValue,
  SetPasteSpecialValue,
  GetDefaultBackBehaviour,
  WindowWrap,
  GetParameterID,
  DoNavigate,
  ScreenHeight,
  ScreenWidth,
  ResetRecord,
  isHRiOSWeb,
};
