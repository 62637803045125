import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import InputSelect from '../../../components/edit_elements/InputSelect';
import {DefaultIconButton} from '../../../components/button/DefaultIconButton';
import * as myDate from '@tools/dateTools';
import {DateTimePickerEXC as DateTimePicker} from '../../../Tools/Stubs';
import {ExternalPasteSpecial, InlinePasteSpecial, InputChangeEventProps, PasteSpecial} from '@utils/types';
import {ColumnContainer, RowContainer, TextNormal, TextNormalBold} from '@styles/primitiveComponents';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {PasteSpecialWindow} from '@components/editElements/pasteSpecial/PasteSpecialWindow';
import {ActionButton} from '@components/button/ActionButton';
import {PasteSpecialName} from '@components/editElements/pasteSpecial/pasteSpecialName/PasteSpecialName';
import {OutsideClickWrapper} from './OutsideClickWrapper';
import {DatePickerComponent} from '../datePicker/lib/DatePicker';
import dayjs from 'dayjs';
import {objectHasProperty} from 'src/Tools/ObjectTools';
import Requests from '../../../api/Requests';
import {handleError} from '../../../Tools/PortalLog';
import {
  ConvertDateField,
  ConvertDisplayValue,
  FormatActionButton,
  getEditFieldDataType,
  getFormattedDisplayValue,
  GetGlobalTheme,
  GetPasteSpecialValue,
  isBlockRecord,
  IsHRAndroid,
  IsHRiOS,
  IsHRWeb,
  setPasteSpecialValue,
  WindowElementType,
} from '@tools/displayTools';
import {ToolTipText} from '@components/design/ToolTipText';
import {PasteSpecialDetailedButton} from './pasteSpecialDetailedView/PasteSpecialDetailedButton';
import {DateFormatOrigin, Dimensions} from '@components/screens/types';
import {isStaticPasteSpecial} from '@utils/pasteSpecialUtils';
import {InitializeStaticPasteSpecialDefinitions} from '@components/editElements/pasteSpecial/StaticPasteSpecialDefinition';
import {InspectWindow} from './inspectWindow/InspectWindow';

const InputWrapper = styled(ColumnContainer)`
  ${(props) =>
    (props.row || props.singleline) &&
    css`
      display: flex;
      justify-content: space-between;
    `}
`;

const InputLabel = styled.View`
  justify-content: center;
  width: 150px;
  ${(props) =>
    props.singleline &&
    css`
      width: 150px;
    `}
`;
const InputLabelText = styled(TextNormal)`
  color: ${colours[GetGlobalTheme()].label};
  white-space: nowrap;
`;
const ClearButtonWrap = styled.View`
  position: absolute;
  right: 7px;
  top: 9px;
  ${IsHRWeb() === false &&
  css`
    top: 3px;
  `}
`;

const IconButtonContainer = {
  padding: 0,
  margin: 0,
  background: 'none',
  height: '100%',
  justifyContent: 'center',
};

const InputContainer = styled.View`
  width: ${(props) => (props.row ? '50%' : '100%')};
  flex-shrink: 1;
`;

const DatePickerInputContainer = styled.TouchableOpacity`
  width: ${(props) => (props.row ? '50%' : '100%')};
  flex-shrink: 1;
`;

const InputField = styled.TextInput`
  border-radius: 8px;
  border: 1px solid ${colours[GetGlobalTheme()].borderDefault};
  font-family: 'Open Sans';
  width: 100%;
  /*background-color: ${colours[GetGlobalTheme()].mainBG};*/
  color: ${colours[GetGlobalTheme()].label};
  ${IsHRWeb() &&
  css`
    ${(props) => (props.style && props.style.height ? 'height: ' + props.style.height : 'height: 35px;')}

    cursor: ${(props) => (props.editable ? 'auto' : 'default')};
    padding-horizontal: ${spacing.space12};
    padding-vertical: ${spacing.space12};
  `}
  ${IsHRWeb() === false &&
  css`
    ${(props) => (props.style && props.style.height ? 'height: ' + props.style.height + 'px' : 'height: 45px;')}
    font-size: 18px;
  `}

  border-width: 0;
  ${(props) =>
    props.editable &&
    css`
      border: 1px solid ${colours[GetGlobalTheme()].borderDefault};
    `};
`;

const IconWrapper = styled(RowContainer)`
  position: absolute;
  top: 12px;
  left: 12px;
`;

const CalendarIconWrapper = styled(RowContainer)`
  order: -1;
  position: absolute;
  top: 10px;
  left: 7px;
`;

function showPasteSpecial(props) {
  let res = [];
  res.push(
    <InputSelect
      key={props.name}
      pasteSpecial={props.pasteSpecial}
      name={props.name}
      title={props.title}
      value={props.value}
      style={props.style}
      editable={props.editable}
      onChange={({rowindex, fieldname, value}: InputChangeEventProps) => {
        if (props.onChange !== undefined) {
          props.onChange({
            rowindex: rowindex,
            fieldname: fieldname,
            value: value,
          });
        }
      }}
    />
  );
  return res;
}

function isInlinePasteSpecial(ps: InlinePasteSpecial | ExternalPasteSpecial): ps is InlinePasteSpecial {
  return !!ps && 'fields' in ps;
}

function isExternalPasteSpecial(ps: InlinePasteSpecial | ExternalPasteSpecial): ps is ExternalPasteSpecial {
  return !!ps && 'columns' in ps;
}

export type EditFieldOnChangeProps = {
  rowindex: number;
  fieldname: string;
  value: string;
  inspectWindow: InspectWindow;
};

type EditFieldProps = {
  title?: string;
  name?: string;
  value?: string;
  pasteSpecial?: PasteSpecial;
  rownr?: number;
  reference?: React.ElementRef;
  dateFormatOrigin?: DateFormatOrigin;
  useAutoFill?: boolean;
  allowManualEntry?: boolean | undefined;
  inspectWindow?: InspectWindow;
  defaultValue?: string;
  placeholder?: string;
  placeholdercolor?: string;
  width?: string | number;
  onChangeText?: (text: string) => void;
  inputStyle?: any;
  wrapperStyle?: any;
  iconName?: string;
  iconStyle?: any;
  iconHeight?: number;
  onChange: (props: EditFieldOnChangeProps) => void;
  editable?: boolean;
  type?: WindowElementType;
};

type EditFieldState = {
  showCalendar: boolean;
  showPasteSpecial: boolean;
  value: any;
  keyboard: boolean;
  multiRecords: any[];
  singlePasteSpecialAlternateDisplay: boolean;
  singlePasteSpecialDisplayValues: {displayValue: string | number; toolTipValue: string};
  dimensions: Dimensions;
};

class EditField extends Component<EditFieldProps, EditFieldState> {
  isExternalPasteSpecial: boolean;
  isInlinePasteSpecial: boolean;
  usePasteSpecial: boolean;
  origval: string;

  constructor(props) {
    super(props);
    this.origval = props.value;
    this.isExternalPasteSpecial = false;
    this.isInlinePasteSpecial = false;
    this.usePasteSpecial = this.props.pasteSpecial !== undefined;
    if (this.usePasteSpecial) {
      this.isExternalPasteSpecial = isExternalPasteSpecial(this.props.pasteSpecial);
      this.isInlinePasteSpecial = isInlinePasteSpecial(this.props.pasteSpecial);
    }
    this.state = {
      showCalendar: false,
      showPasteSpecial: false,
      value: this.props.value,
      keyboard: true,
      multiRecords: [],
      singlePasteSpecialAlternateDisplay: false,
      singlePasteSpecialDisplayValues: {displayValue: '', toolTipValue: ''},
      dimensions: {width: 0, height: 0},
    };
  }

  componentDidMount() {
    this.initializePasteSpacial();
  }

  componentDidUpdate() {
    if (this.origval !== this.props.value) {
      this.origval = this.props.value;
      this.initializePasteSpacial();
    }
  }

  initializePasteSpacial() {
    if (this.usePasteSpecial) {
      if (isStaticPasteSpecial(this.props.pasteSpecial.vcName.toString())) {
        this.handleStaticPasteSpecialDisplay();
        return;
      }

      if (this.props.pasteSpecial.multiValue) {
        this.initializeMultiPasteSpecial();
      } else if (
        (this.props.pasteSpecial.useAlternateDisplay || objectHasProperty(this.props.pasteSpecial, 'toolTip')) &&
        this.props.value
      ) {
        this.initializeSinglePasteSpecial();
      }
    }
  }

  initializeMultiPasteSpecial() {
    const multiEntryArr = this.props.value?.split2(',');
    let iterationIndex = 0;
    multiEntryArr?.forEach((value, index) => {
      const pasteSpecialValue = GetPasteSpecialValue(this.props.pasteSpecial.vcName, value);
      if (pasteSpecialValue === undefined) {
        let filter = {};
        filter[this.props.pasteSpecial.columns[0].mainKey] = value;
        let fields = this.props.pasteSpecial.columns.map((u) => u.key).join(',');
        Requests.getTableData(this.props.pasteSpecial.vcName, filter, fields)
          .then((response) => {
            iterationIndex++;
            const pasteSpecialRecord = response.records[0];
            setPasteSpecialValue(this.props.pasteSpecial.vcName, value, pasteSpecialRecord);
            if (iterationIndex === multiEntryArr.length) {
              this.handleMultiPasteSpecialDisplay();
              return;
            }
          })
          .catch((error) => {
            handleError(error);
          });
      } else {
        iterationIndex++;
        if (iterationIndex === multiEntryArr.length) {
          this.handleMultiPasteSpecialDisplay();
        }
      }
    });
  }

  async initializeSinglePasteSpecial() {
    try {
      const pasteSpecialValue = GetPasteSpecialValue(this.props.pasteSpecial.vcName, this.props.value);
      if (pasteSpecialValue === undefined) {
        let fields = '';
        let filter = {};
        if (objectHasProperty(this.props.pasteSpecial, 'pasteSpecialValueFields')) {
          fields = this.props.pasteSpecial.pasteSpecialValueFields;
        } else {
          this.props.pasteSpecial.columns.forEach((field) => {
            fields += ',' + field.key;
          });
        }
        filter[this.props.pasteSpecial.columns[0].mainKey] = this.props.value;
        const response = await Requests.getTableData(this.props.pasteSpecial.vcName, filter, fields);
        this.handleSinglePasteSpecialResponse(response);
      } else {
        this.handleSinglePasteSpecialDisplay();
      }
    } catch (error) {
      handleError(error);
    }
  }

  handleSinglePasteSpecialResponse(response) {
    let pasteSpecialRecord;
    if (isBlockRecord(this.props.pasteSpecial.vcName)) {
      pasteSpecialRecord = response.records[0].rows.find(
        (record) => record[this.props.pasteSpecial.columns[0].mainKey] === this.props.value
      );
    } else {
      pasteSpecialRecord = response.records[0];
    }

    setPasteSpecialValue(this.props.pasteSpecial.vcName, this.props.value, pasteSpecialRecord);
    this.handleSinglePasteSpecialDisplay();
  }

  handleSinglePasteSpecialDisplay() {
    const pasteSpecialRecord = GetPasteSpecialValue(this.props.pasteSpecial.vcName, this.props.value);
    if (pasteSpecialRecord === undefined) {
      handleError(`Paste special record for ${this.props.value} is empty!`);
      return;
    }

    const displayValue = this.props.pasteSpecial.useAlternateDisplay
      ? getFormattedDisplayValue(
          this.props.pasteSpecial.viewModeDisplayFormat,
          pasteSpecialRecord,
          this.props.pasteSpecial.recordLinkFunction
        )
      : this.props.value;

    const hasToolTip = objectHasProperty(this.props.pasteSpecial, 'toolTip');
    const toolTipValue = hasToolTip ? pasteSpecialRecord[this.props.pasteSpecial.toolTip.key] : '';

    this.setState({
      singlePasteSpecialDisplayValues: {
        displayValue,
        toolTipValue,
      },
      singlePasteSpecialAlternateDisplay: true,
    });
  }

  handleStaticPasteSpecialDisplay() {
    if (global.pasteSpecials[this.props.pasteSpecial.vcName] === undefined) {
      InitializeStaticPasteSpecialDefinitions();
    }
    const pasteSpecialRecords = global.pasteSpecials[this.props.pasteSpecial.vcName];
    if (!this.props.value) {
      this.props.value = '0';
    }
    const filteredRecord = pasteSpecialRecords.filter(
      (record) => record[this.props.pasteSpecial.mainKey] === this.props.value
    );
    const pasteSpecialRecord = filteredRecord[0];

    if (pasteSpecialRecord === undefined) {
      handleError(`Paste special record for ${this.props.value} is empty!`);
      return;
    }

    const displayValue = pasteSpecialRecord[this.props.pasteSpecial.keyToDisplayInViewMode[0]];

    const hasToolTip = objectHasProperty(this.props.pasteSpecial, 'toolTip');
    const toolTipValue = hasToolTip ? pasteSpecialRecord[this.props.pasteSpecial.toolTip.key] : '';

    this.setState({
      singlePasteSpecialDisplayValues: {
        displayValue,
        toolTipValue,
      },
      singlePasteSpecialAlternateDisplay: true,
    });
  }

  handleMultiPasteSpecialDisplay() {
    const multiRecordsArr = [];
    this.props.value.split2(',').forEach((value) => {
      const pasteSpecialRecord = GetPasteSpecialValue(this.props.pasteSpecial.vcName, value);
      multiRecordsArr.push(pasteSpecialRecord);
    });
    this.setState({multiRecords: multiRecordsArr});
  }

  toggleCalendar = () => {
    if (this.props.type === WindowElementType.kInputTypeDate && this.props.editable) {
      this.setState((prevState) => ({
        showCalendar: !prevState.showCalendar,
      }));
    }
  };

  setNumValue = (diff) => {
    let nval = parseInt(this.state.value);
    nval = nval + diff;
    this.props.onChange({
      rowindex: objectHasProperty(self.props, 'rownr') ? self.props.rownr : -1,
      fieldname: this.props.name,
      value: nval,
      inspectWindow: this.props.inspectWindow,
    });
  };

  initializeDimensions(layout) {
    const self = this;
    if (self.usePasteSpecial) {
      self.setState({dimensions: {width: layout.width, height: layout.height}});
    }
  }

  clearDates = () => {
    const today = myDate.constructDateString2(new Date());
    this.setState(
      {
        value: '',
        showCalendar: true,
      },
      () => {
        this.props.onChange({
          rowindex: -1,
          fieldname: this.props.name,
          value: '',
          inspectWindow: this.props.inspectWindow,
        });
      }
    );
  };

  onDateChange = (selectedDate) => {
    let formattedDate = myDate.constructDateString2(selectedDate.date.$d);
    if (this.props.dateFormatOrigin === DateFormatOrigin.Database) {
      formattedDate = ConvertDateField(formattedDate);
    }
    this.setState({
      value: formattedDate,
    });

    if (this.props.onChange) {
      this.props.onChange({
        rowindex: -1,
        fieldname: this.props.name,
        value: formattedDate,
        inspectWindow: this.props.inspectWindow,
      });
    }
  };

  extractDateFromEditField = () => {
    let dateString = this.state.value;
    if (this.props.dateFormatOrigin === DateFormatOrigin.Database) {
      dateString = myDate.convertDateStringToISODate(dateString);
    }
    return myDate.extractDateFromString(dateString);
  };

  closeCalendar = () => {
    this.setState({showCalendar: false});
  };

  isActionableLink = () => {
    return (
      this.props.type === WindowElementType.kInputTypeMobile || this.props.type === WindowElementType.kInputTypeEmail
    );
  };

  getNormalFieldDisplayText = () => {
    if (this.isActionableLink()) {
      return FormatActionButton(this.props.value, this.props.type);
    } else {
      return ConvertDisplayValue(
        getEditFieldDataType(this.props.type, {dateFormatOrigin: this.props.dateFormatOrigin}),
        this.props.value
      );
    }
  };

  getPointerEventRules = () => {
    if (this.props.allowManualEntry === true) {
      return 'auto';
    }
    return this.props.pasteSpecial || this.props.type === WindowElementType.kInputTypeDate ? 'none' : 'auto';
  };

  render() {
    let self = this;
    let keys = {
      key: this.props.value,
      ref: this.props.reference,
      name: this.props.name,
      placeholder: this.props.placeholder,
      placeholderTextColor: this.props.placeholdercolor,
      secureTextEntry: this.props.secure,
      autoCompleteType: this.props.autoCompleteType,
      style: this.props.inputStyle,
      wrapperStyle: this.props.wrapperStyle,
      iconStyle: this.props.iconStyle,
      iconName: this.props.iconName,
      iconHeight: this.props.iconHeight,
      viewOnly: this.props.editable,
      row: this.props.row,
      editable: this.props.editable === undefined || this.props.editable === true,
    };
    if (!keys.style) {
      keys.style = {};
    }
    keys.onChangeText = (text) => {
      this.state.value = text;
      if (this.props.onChangeText) {
        this.props.onChangeText(text);
      }
    };
    if (this.props.onKeyPress !== undefined) {
      keys.onKeyPress = (e) => this.props.onKeyPress(e);
    }
    if (this.props.onSubmitEditing !== undefined) {
      keys.onSubmitEditing = () => this.props.onSubmitEditing();
    }
    if (this.props.onFocus !== undefined) {
      keys.onFocus = () => this.props.onFocus();
    }

    keys.onBlur = () => {
      if (this.props.onChange !== undefined && this.props.value !== this.state.value) {
        let value = this.state.value;
        if (
          this.props.type === WindowElementType.kInputTypeDate &&
          this.props.dateFormatOrigin !== DateFormatOrigin.Database
        ) {
          value = myDate.convertDateStringToISODate(value);
        }
        if (self.props.matrixfield) {
          self.props.onChange({
            rowindex: 0,
            fieldname: self.props.name,
            value: value,
            inspectWindow: self.props.inspectWindow,
          });
        } else {
          self.props.onChange({
            rowindex: objectHasProperty(self.props, 'rownr') ? self.props.rownr : -1,
            fieldname: self.props.name,
            value: value,
            inspectWindow: self.props.inspectWindow,
          });
        }
      }
    };
    if (IsHRAndroid() || IsHRiOS()) {
      keys.onFocus = (e) => {
        self.openCalendar(e);
      };
    }

    if (this.props.type === WindowElementType.kInputTypeQty) {
      keys.style.textAlign = 'center';
    }
    if (this.props.type === WindowElementType.kInputTypeLongText) {
      keys.multiline = true;
    }
    if (this.props.type === WindowElementType.kInputTypeNumber) {
      keys.keyboardType = 'number-pad';
    }
    if (this.props.type === WindowElementType.kInputTypeDate) {
      keys.placeholder = global.userData.dateformat;
      keys.style.paddingLeft = spacing.space24;
    }

    if (this.props.type === WindowElementType.kInputTypeNumber2) {
      if (IsHRiOS()) {
        keys.keyboardType = 'numbers-and-punctuation';
      } else {
        keys.keyboardType = 'number-pad';
      }
    }

    if (
      this.props.type === WindowElementType.kInputTypeDate &&
      this.props.dateFormatOrigin !== DateFormatOrigin.Database
    ) {
      const isUnwantedDate = dayjs(this.props.value).format('DD-MM-YYYY') === '01-01-2000'; // ?? why?
      keys.defaultValue =
        this.props.value !== '' && dayjs(this.props.value).isValid() && !isUnwantedDate
          ? ConvertDateField(this.props.value)
          : '';
    } else {
      keys.defaultValue = this.props.value;
    }

    if (this.props.type === WindowElementType.kInputTypeLongText) {
      if (this.props.value !== undefined) {
        let res = this.props.value;
        while (res.slice(-5) === '&#13;') {
          res = res.slice(0, res.length - 5);
        }
        res = res.replace(/&#10;/g, String.fromCharCode(10));
        keys.defaultValue = res.replace(/&#13;/g, String.fromCharCode(13));
      }
    }
    if (this.isExternalPasteSpecial) {
      if (IsHRWeb()) {
        keys.style.paddingRight = 30;
      } else {
        keys.style.paddingRight = 40;
      }
    }
    let w = self.props.width;
    if (w) {
      if (w.indexOf('px') > 0) {
        w = parseInt(w);
      }
      if (IsHRWeb() === false) {
        w = '100%';
      }
    }

    let wrapperStyle = {};
    if (keys.editable === false) {
      if (self.props.wrapperStyle.flexDirection != 'column') {
        wrapperStyle.flexDirection = 'row';
      }
      wrapperStyle.alignItems = 'start';
    }
    let linkeddateval = '';
    if (this.props.linkedfield) {
      linkeddateval = myDate.extractDateFromString(this.props.record[this.props.linkedfield]);
    } else {
      linkeddateval = myDate.getCurrentDate();
    }
    this.state.value = this.props.value;

    if (this.usePasteSpecial) {
      if (this.props.pasteSpecial.multiValue) {
        keys.defaultValue = '';
      }
    }

    if (this.props.useAutoFill !== true) {
      // Looks like we need to set a non-existant option here for safari. This doesn't fully solve the whole issue. We still need DefineWebStyles for safari to work correctly.
      keys.autoComplete = 'offnn';
      keys.autoCorrect = false;
    }

    const ContainerComponent =
      this.props.type === WindowElementType.kInputTypeDate ? DatePickerInputContainer : InputContainer;

    return (
      <InputWrapper
        style={{...this.props.wrapperStyle, ...{width: w, ...wrapperStyle}}}
        key={this.props.value}
        singleline={this.props.singleline}
        row={this.props.row}>
        {this.props.title && (
          <InputLabel row={this.props.row} singleline={this.props.singleline} style={this.props.labelStyle}>
            <InputLabelText>{this.props.title}</InputLabelText>
          </InputLabel>
        )}
        {keys.editable === true && this.props.type !== WindowElementType.kInputTypeStaticText && (
          <ContainerComponent row={self.props.row} onPress={this.toggleCalendar} key={'EditFieldContainer'}>
            {(this.usePasteSpecial === false || this.isExternalPasteSpecial) && (
              <RowContainer>
                {this.props.iconName && (
                  <IconWrapper>
                    <PortalIcon iconName={this.props.iconName} iconHeight={this.props.iconHeight} />
                  </IconWrapper>
                )}

                {this.props.type === WindowElementType.kInputTypeDate && (
                  <CalendarIconWrapper>
                    <PortalIcon iconName={'CalendarIcon'} iconHeight={12} />
                  </CalendarIconWrapper>
                )}

                <InputField
                  {...keys}
                  pointerEvents={this.getPointerEventRules()}
                  onLayout={(event) => {
                    this.initializeDimensions(event.nativeEvent.layout);
                  }}
                />
              </RowContainer>
            )}
            {this.isInlinePasteSpecial && showPasteSpecial(self.props)}
            {this.props.type === WindowElementType.kInputTypeDate &&
              this.props.allowClearing === true &&
              this.props.value !== '' &&
              this.props.value !== undefined && (
                <ClearButtonWrap>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                    }}
                    iconName={'times-circle'}
                    mode="text"
                    size={IsHRWeb() ? 17 : 36}
                    iconType="FA5"
                    action={() =>
                      self.props.onChange({
                        rowindex: objectHasProperty(self.props, 'rownr') ? self.props.rownr : -1,
                        fieldname: self.props.name,
                        value: '',
                        inspectWindow: self.props.inspectWindow,
                      })
                    }
                  />
                </ClearButtonWrap>
              )}
            {this.props.icon !== undefined && (
              <ClearButtonWrap style={{top: IsHRWeb() ? 9 : 10}}>
                <DefaultIconButton
                  style={{
                    height: 'auto',
                    width: 'auto',
                    padding: 0,
                    margin: 0,
                    borderRadius: 0,
                  }}
                  iconName={this.props.icon}
                  mode="text"
                  size={IsHRWeb() ? 17 : 26}
                  iconType="FA5"
                  action={this.props.iconClick}
                />
              </ClearButtonWrap>
            )}
            {this.isExternalPasteSpecial && keys.editable && (
              <ClearButtonWrap style={{top: IsHRWeb() ? 9 : 10}}>
                <ActionButton
                  onPress={() => {
                    const self = this;
                    self.setState({showPasteSpecial: !self.state.showPasteSpecial});
                  }}
                  style={{
                    height: 'auto',
                    width: 'auto',
                    padding: 0,
                    margin: 0,
                    borderRadius: 0,
                  }}
                  iconName={'search'}
                  mode="text"
                  size={IsHRWeb() ? 17 : 26}
                  iconType="FA5"
                  action={() => {}}
                  style={IconButtonContainer}
                  iconName={'DropdownIcon'}
                  iconColor={colours[GetGlobalTheme()].grey500}
                  iconSize={18}
                />
              </ClearButtonWrap>
            )}
            {this.usePasteSpecial && (
              <PasteSpecialName
                dimensions={this.state.dimensions}
                onPress={() => this.setState({showPasteSpecial: !this.state.showPasteSpecial})}
                onChange={this.props.onChange}
                value={this.props.value}
                row={this.props.row}
                name={this.props.name}
                pasteSpecial={this.props.pasteSpecial}
                inspectWindow={self.props.inspectWindow}
              />
            )}

            {this.props.type === WindowElementType.kInputTypeQty && IsHRWeb() === false && (
              <>
                <ClearButtonWrap style={{left: 9, right: 'auto'}}>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                    }}
                    iconName={'chevron-down'}
                    mode="text"
                    size={IsHRWeb() ? 17 : 36}
                    iconType="FA5"
                    action={() => self.setNumValue(-1)}
                  />
                </ClearButtonWrap>
                <ClearButtonWrap>
                  <DefaultIconButton
                    style={{
                      height: 'auto',
                      width: 'auto',
                      padding: 0,
                      margin: 0,
                      borderRadius: 0,
                    }}
                    iconName={'chevron-up'}
                    mode="text"
                    size={IsHRWeb() ? 17 : 36}
                    iconType="FA5"
                    action={() => self.setNumValue(1)}
                  />
                </ClearButtonWrap>
              </>
            )}
            {this.props.type === WindowElementType.kInputTypeDate && this.state.showCalendar === true && IsHRWeb() && (
              <OutsideClickWrapper onOutsideClick={this.toggleCalendar}>
                <DatePickerComponent
                  mode="single"
                  date={{
                    date: dayjs(this.props.value).isValid() === false ? new Date() : this.extractDateFromEditField(),
                  }}
                  height={230}
                  width={'100%'}
                  firstDayOfWeek={1}
                  displayFullDays
                  onChange={this.onDateChange}
                  clearDates={this.clearDates}
                  closeCalendar={this.closeCalendar}
                  marginTop={5}
                />
              </OutsideClickWrapper>
            )}
            {this.props.type === WindowElementType.kInputTypeDate &&
              this.state.showCalendar === true &&
              IsHRWeb() === false && (
                <DateTimePicker
                  mode="date"
                  value={
                    myDate.extractDateFromString(this.props.value) === null
                      ? linkeddateval
                      : myDate.extractDateFromString(this.props.value)
                  }
                  onChange={(event, value) => {
                    if (self.props.onChange !== undefined) {
                      self.props.onChange({
                        rowindex: objectHasProperty(self.props, 'rownr') ? self.props.rownr : -1,
                        fieldname: self.props.name,
                        value: myDate.constructDateString2(value),
                        inspectWindow: self.props.inspectWindow,
                      });
                    }
                    self.setState({showCalendar: false});
                  }}
                />
              )}
            {this.state.showPasteSpecial && (
              <PasteSpecialWindow
                rownr={objectHasProperty(self.props, 'rownr') ? self.props.rownr : -1}
                table={this.props.pasteSpecial.vcName}
                onChange={this.props.onChange}
                psheader={this.props.pasteSpecial.columns}
                psnewrec={this.props.pasteSpecial.psnewrec}
                sortedPasteSpecial={this.props.pasteSpecial.pssort}
                mainKey={this.props.pasteSpecial.mainKey}
                psfields={this.props.pasteSpecial.psfields}
                isMultiPasteSpecial={this.props.pasteSpecial.multiValue}
                inspectWindow={this.props.inspectWindow}
                name={this.props.name}
                value={this.props.value}
                editfield={this}
                windowLayout={this.props.pasteSpecial.windowLayout}
                filter={this.props.pasteSpecial.filter}
                onDisable={() => {
                  const self = this;
                  self.setState({showPasteSpecial: false});
                }}
                useMultiDropDownWithFilters={this.props.pasteSpecial.useMultiDropDownWithFilters}
                multiDropDownField={this.props.pasteSpecial.multiDropDownField}
                isDropDownPasteSpecial={this.props.pasteSpecial.isDropDownField}
              />
            )}
          </ContainerComponent>
        )}
        {keys.editable === false && (
          <InputContainer>
            {this.state.multiRecords.length > 0 ? (
              <>
                {this.state.multiRecords.map((record) => {
                  return (
                    <RowContainer>
                      <TextNormalBold style={this.props.inputStyle}>
                        {record[this.props.pasteSpecial.keyToDisplayInViewMode[0]]}
                      </TextNormalBold>
                      <TextNormal> - {record[this.props.pasteSpecial.keyToDisplayInViewMode[1]]}</TextNormal>
                    </RowContainer>
                  );
                })}
              </>
            ) : this.state.singlePasteSpecialAlternateDisplay ? (
              objectHasProperty(this.props.pasteSpecial, 'toolTip') ? (
                <ToolTipText
                  toolTipText={this.state.singlePasteSpecialDisplayValues.toolTipValue}
                  innerText={this.state.singlePasteSpecialDisplayValues.displayValue}
                  maxCharacters={this.props.pasteSpecial.toolTip.maxCharacters}
                />
              ) : (
                this.props.pasteSpecial.useAlternateDisplay && (
                  <TextNormal
                    style={{...this.props.inputStyle, width: this.props.pasteSpecial.details ? '81%' : '100%'}}>
                    {this.state.singlePasteSpecialDisplayValues.displayValue}
                  </TextNormal>
                )
              )
            ) : (
              <TextNormal style={this.props.inputStyle}>{this.getNormalFieldDisplayText()}</TextNormal>
            )}
            {this.props.pasteSpecial && this.props.pasteSpecial.details && (
              <PasteSpecialDetailedButton
                pasteSpecial={this.props.pasteSpecial}
                value={this.props.value}
                displayValue={this.state.singlePasteSpecialDisplayValues.displayValue}
              />
            )}
          </InputContainer>
        )}
      </InputWrapper>
    );
  }
}

export default EditField;
