import React, {useState} from 'react';
import {RowContainer} from '@styles/primitiveComponents';
import {GetGlobalTheme, isDesktopScreen, isMobileOrTabletScreen, ScreenWidth} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';

export const mobileBottomButtonContainerHeight = 68;

type MobileBottomButtonProps = {};

export const MobileBottomButton: React.FC<MobileBottomButtonProps> = () => {
  const [buttonComponents, setButtonComponents] = useState<JSX.Element[]>([<></>]);
  const [showButton, setShowButton] = useState(false);
  const [containerWidth, setContainerWidth] = useState(ScreenWidth());

  global.refreshMobileBottomButton = () => {
    setContainerWidth(ScreenWidth());
  };

  global.setMobileBottomButton = (showButton: boolean, component = [<></>]) => {
    setShowButton(showButton);
    if (!showButton) {
      return;
    }
    setButtonComponents(component);
  };

  const ContentContainerStyle = {
    width: containerWidth,
    height: mobileBottomButtonContainerHeight,
    backgroundColor: colours[GetGlobalTheme()].white,
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    padding: spacing.space12,
    zIndex: 999,
  };

  if (isDesktopScreen()) {
    return <></>;
  }

  return (
    <>
      {isMobileOrTabletScreen() && showButton && (
        <RowContainer style={ContentContainerStyle}>{buttonComponents}</RowContainer>
      )}
    </>
  );
};
