import React, {Component} from 'react';
import {colours, spacing} from '../../../styles/Style';
import {
  ConvertDisplayValue,
  GetGlobalTheme,
  IsHRAndroid,
  IsHRiOS,
  IsHRWeb,
  WindowElementType,
} from '../../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import Requests from '../../../api/Requests';
import {Text} from 'react-native-paper';
import {DefaultIconButton} from '../../../components/button/DefaultIconButton';
import CompanySelect from '../../../components/status/CompanySelect';

import {Dimensions, Modal, View} from 'react-native';
import {YesNoWindow} from '../../../components/status/YesNoWindow';
import {AttachmentWindow} from '../../../components/status/AttachmentWindow';
import {LoadEmployeeInfo, RequestEmpData} from '../../../App';
import {ActionButton} from '../../../components/button/ActionButton';
import {ApprovalListWindow} from '../../../components/status/ApprovalListWindow';
import {PasteSpecialWindow} from '../../../components/edit_elements/PasteSpecialWindow';
import {ReportWindow} from '../../../components/edit_elements/ReportWindow';
import {SignersWindow} from '../../../components/edit_elements/SignersWindow';
import {KeychainEXC as Keychain} from '../../../Tools/Stubs';
import {GetDrawers} from '../../../navigation/SideNavigator';
import ShiftEntry from '../../../components/edit_elements/ShiftEntry';
import {logError} from '@utils/debug';
import {objectHasProperty} from '@tools/objectTools';
import {RowActions} from '../../../components/edit_elements/Matrix';
import styled, {css} from 'styled-components/native';
import {SwitchAccount} from '@components/switchAccount/SwitchAccount';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const TopBar = styled.View`
  height: 60px;
  background-color: ${colours[GetGlobalTheme()].topNavigationBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${IsHRWeb() === false &&
  css`
    height: ${(props) => props.height};
  `}
`;

const RightNav = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;
/*
  width: 1024px;
*/

const LeftNav = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${IsHRWeb() === false &&
  css`
    justify-content: space-between;
    align-items: center;
    flex: 1;
  `}
`;

const ProfileTop = styled.View`
  position: absolute;
  left: 0px;
  top: 0px;
`;

const WindowTitle = styled.Text`
  font-size: 14px;
  color: ${colours[GetGlobalTheme()].titleText};
  ${IsHRWeb() === false &&
  css`
    font-size: 16px;
  `}
`;

const StyledText = styled.Text`
  font-weight: 500;
  margin-horizontal: ${spacing.medium};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: auto;
  color: ${colours[GetGlobalTheme()].titleText};
`;
const ProfileInfoLine = styled.View`
  flex-direction: row;
  margin-bottom: 4px;
`;
const ProfileInfoLabel = styled.View`
  width: 170px;
`;
const ProfileInfoLabelText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;
const ProfileInfoValue = styled.View`
  flex-shrink: 1;
  color: ${colours[GetGlobalTheme()].titleText};
`;
const ProfileInfoValueText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;
const ProfileInfo = styled.View``;

const ProfileWrap = styled.View`
  position: absolute;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  ${IsHRWeb() === false &&
  css`
    width: 100% !important;
    height: ${Dimensions.get('window').height}px !important;
    left: 0px !important;
    right: 0px !important;
  `}
  ${IsHRiOS() && css``}
  ${IsHRWeb() &&
  css`
    height: auto;
    border: 1px solid ${colours[GetGlobalTheme()].lightBackground};
    ${(props) => (props.style.width ? 'width: : ' + props.style.width : 'width: auto;')};
  `}
`;
const ProfileWrapInner = styled.View`
  background: ${colours[GetGlobalTheme()].activeText}1F;
  padding: 20px;
  ${IsHRWeb() === false &&
  css`
    margin-top: 40px;
    background: transparent;
  `}
`;

const Tooltip = styled.View`
  position: absolute;
  left: 0;
  padding: 10px 20px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
`;

//import BarcodeReader from '../../../components/status/BarcodeScanner';

function ProfileWrapBase(props) {
  const insets = useSafeAreaInsets();
  let h = insets.top;
  if (IsHRWeb()) {
    h = 0;
  }
  return <ProfileWrap style={{marginTop: h, ...props.style}}>{props.children}</ProfileWrap>;
}

class TopNavigator extends Component {
  constructor(props) {
    super(props);
    let self = this;
    this.props = props;
    global.messageBox = (data) => {
      self.setMessage(data);
    };
    global.logout = () => {
      self.logout();
    };
    global.setWindowTitle = (title) => {
      self.setState({windowTitle: title}); //just for refreshing the bar
    };
    global.setAttachmentInfo = (attachments) => {
      self.setState({attachments: attachments});
    };
    global.setApprovalInfo = (approvals) => {
      self.setState({approvals: approvals});
    };
    global.setRowActions = (rowactions) => {
      let self = this;
      if (rowactions.key === this.state.rowactions.key) {
        this.setState({
          rowactions: {
            show: false,
            key: '',
            posx: 0,
            posy: 0,
          },
        });
      } else {
        self.setState({rowactions: rowactions});
        if (rowactions.show) {
          if (this.state.rowactions.show === false) {
            global.PushEscapeActions(() => global.setRowActions({show: false}));
          }
        } else {
          global.PopEscapeActions();
        }
      }
    };

    global.showPasteSpecial = () => {};

    global.setPasteSpecialInfo = (ps) => {
      if (ps.show) {
        global.PushEscapeActions(() => global.setPasteSpecialInfo({show: false}));
      } else {
        global.PopEscapeActions();
      }
      self.setState({pastespecial: ps});
    };
    global.setProfileInfo = (profile) => {
      let self = this;
      if (profile.empcode === this.state.profile.empcode) {
        this.setState({
          profile: {
            show: false,
            empcode: '',
            posx: 0,
            posy: 0,
          },
        });
      } else {
        let func = () => {
          self.setState({profile: profile});
        };
        LoadEmployeeInfo(profile.empcode, func);
        if (profile.show) {
          if (this.state.profile.show === false) {
            global.PushEscapeActions(() => global.setProfileInfo({show: false}));
          }
        } else {
          global.PopEscapeActions();
        }
      }
    };
    global.setShiftEntry = (shiftentry) => {
      let self = this;
      if (shiftentry.key === this.state.shiftentry.key) {
        this.setState({
          shiftentry: {
            show: false,
            key: '',
            posx: 0,
            posy: 0,
          },
        });
      } else {
        self.setState({shiftentry: shiftentry});
        if (shiftentry.show) {
          if (this.state.shiftentry.show === false) {
            global.PushEscapeActions(() => global.setShiftEntry({show: false}));
          }
        } else {
          global.PopEscapeActions();
        }
      }
    };
    global.setShiftEntryRefresh = () => {
      if (this.state.shiftentry.show) {
        let shiftentry = this.state.shiftentry;
        this.setState({shiftentry: shiftentry});
      }
    };
    global.setToolTipInfo = (tooltip) => {
      if (tooltip.show !== this.state.tooltip.show) {
        this.setState({tooltip: tooltip});
      }
    };
    global.showReport = (options) => {
      this.setState({report: options});
      global.PushEscapeActions(() => global.showReport({show: false}));
    };
    global.showSigners = (options) => {
      this.setState({signers: options});
      global.PushEscapeActions(() => global.showSigners({show: false}));
    };
    global.showBarcodeReader = (options) => {
      this.setState({barcode: options});
      global.PushEscapeActions(() => global.showBarcodeReader({show: false}));
    };
    global.showSwitchAccount = (options) => {
      this.setState({switchAccount: options});
    };

    this.profileRef = React.createRef();
    this.clickhandler = null;
    this.state = {
      messageInfo: {
        showMessage: false,
        statusMessage: '',
      },
      attachments: {
        showAttachments: false,
        table: -1,
        id: '',
      },
      approvals: {
        showApprovalPersons: false,
        table: -1,
        id: '',
      },
      updatef: false,
      windowTitle: '',
      px: 0,
      py: 0,
      profile: {
        show: false,
        posy: 0,
        posx: 0,
        empcode: '',
      },
      shiftentry: {
        show: false,
        posy: 0,
        posx: 0,
        key: '',
        data: '',
      },
      tooltip: {
        show: false,
        posy: 0,
        posx: 0,
        text: '',
      },
      rowactions: {
        show: false,
        posy: 0,
        posx: 0,
        key: '',
        data: '',
      },
      pastespecial: {
        show: false,
        table: 0,
        onChange: null,
        row: -1,
      },
      report: {
        show: false,
      },
      signers: {
        show: false,
      },
      barcode: {
        show: false,
        onChange: null,
      },
      switchAccount: {
        show: false,
      },
    };
    this.clickFunction = () => self.CloseProfile();
    if (objectHasProperty(global.userData, 'complist')) {
      this.state.userData = global.userData;
    }
    global.ShowCurrentUserProfile = () => {
      global.setProfileInfo({
        show: true,
        posx: this.state.px - 380,
        posy: this.state.py + 50,
        empcode: global.userData.empcode,
        style: {width: 400},
      });
      if (this.state.profile.show === false) {
        global.PushEscapeActions(() => global.setProfileInfo({show: false}));
      }
    };
  }

  logout = () => {
    Requests.logout()
      .then(() => {
        if (IsHRAndroid()) {
          Keychain.resetGenericPassword('', '').then(() => RequestEmpData());
        } else {
          RequestEmpData();
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  setMessage = (data) => {
    this.setState({messageInfo: data});
  };

  CloseProfile = () => {
    //if (event.target!=profileRef.target)
    global.setProfileInfo({show: false, empcode: '', posx: 0, posy: 0});
  };

  componentDidUpdate = () => {
    if (!IsHRWeb()) {
      let self = this;
      if (this.state.profile.show) {
        if (!this.clickhandler) {
          this.clickhandler = window.addEventListener('click', self.clickFunction);
          let list = document.querySelectorAll("[data-focusable='true']");
          for (let i = 0; i < list.length; i++) {
            list[i].addEventListener('click', self.clickFunction);
          }
        }
      } else {
        //remove event listener
        window.removeEventListener('click', self.clickFunction);
        let list = document.querySelectorAll("[data-focusable='true']");
        for (let i = 0; i < list.length; i++) {
          list[i].removeEventListener('click', self.clickFunction);
        }
        this.clickhandler = null;
      }
    }
  };

  ShowProfileInfo = (label, value) => {
    let res = [];
    if (value !== undefined && value !== '') {
      res.push(
        <ProfileInfoLine>
          <ProfileInfoLabel>
            <ProfileInfoLabelText>{label}</ProfileInfoLabelText>
          </ProfileInfoLabel>
          <ProfileInfoValue>
            <ProfileInfoValueText>{value}</ProfileInfoValueText>
          </ProfileInfoValue>
        </ProfileInfoLine>
      );
    }
    return res;
  };

  render() {
    let self = this;
    let profile = null;
    let profilestyle = {};
    if (this.state.profile.show) {
      profile = global.empInfoList[this.state.profile.empcode];
      if (IsHRWeb()) {
        profilestyle = {
          left: this.state.profile.posx,
          top: this.state.profile.posy,
          ...this.state.profile.style,
        };
      }
    }
    let shiftentrystyle = {};
    if (this.state.shiftentry.show) {
      if (IsHRWeb()) {
        shiftentrystyle = {
          left: this.state.shiftentry.posx - 270,
          top: this.state.shiftentry.posy - 230,
          ...this.state.shiftentry.style,
        };
      }
    }
    let tooltipstyle = {};
    if (this.state.tooltip.show) {
      if (IsHRWeb()) {
        tooltipstyle = {
          left: this.state.tooltip.posx,
          top: this.state.tooltip.posy + 32,
          ...this.state.tooltip.style,
        };
      }
    }
    let rowactionsstyle = {};
    if (this.state.rowactions.show) {
      if (IsHRWeb()) {
        rowactionsstyle = {
          left: this.state.rowactions.posx,
          top: this.state.rowactions.posy + 20,
          ...this.state.rowactions.style,
        };
      }
    }
    let iconsize = 40;
    if (IsHRWeb()) {
      iconsize = 15;
    }
    let showtitlef = true;
    if (
      global.windowNewButton &&
      global.windowNewButton.props?.children &&
      global.windowNewButton.props.children.length > 2
    ) {
      showtitlef = false;
    }
    let compselect = 0;
    if (GetDrawers().length === 0) {
      compselect = global.userData.complist.length;
    }

    return (
      <View style={{zIndex: 1}}>
        {this.state.messageInfo.showMessage && (
          <Modal transparent={true}>
            <YesNoWindow
              question={this.state.messageInfo.statusMessage}
              yes={() => this.setMessage({showMessage: false, statusMessage: ''})}
            />
          </Modal>
        )}
        {IsHRWeb() === false && this.state.attachments.showAttachments && (
          <Modal transparent={true}>
            <AttachmentWindow
              table={this.state.attachments.table}
              visible={this.state.attachments.showAttachments}
              key={this.state.attachments.showAttachments}
              id={this.state.attachments.id}
            />
          </Modal>
        )}
        {IsHRWeb() === false && this.state.approvals.showApprovalPersons && (
          <Modal transparent={true}>
            <ApprovalListWindow
              table={this.state.approvals.table}
              visible={this.state.approvals.showApprovalPersons}
              key={this.state.approvals.showApprovalPersons}
              id={this.state.approvals.id}
            />
          </Modal>
        )}
        {IsHRWeb() === false && (
          <>
            <TopBarMobile>
              <LeftNav>
                {compselect === 0 && (
                  <DefaultIconButton
                    iconName={'bars'}
                    mode="text"
                    iconType="FA5"
                    action={() => {
                      global.setIsDrawerOpen();
                      self.setState({
                        updatef: self.state.updatef ? false : true,
                      });
                    }}
                  />
                )}
                {compselect === 1 && (
                  <ActionButton
                    onPress={() => global.refreshScreen(true)}
                    title={translate('Reload')}
                    style={{flexGrow: 1}}
                  />
                )}
                {compselect > 1 && <CompanySelect userData={global.userData} width={'100%'} />}
                {compselect === 0 && showtitlef && <WindowTitle>{self.state.windowTitle}</WindowTitle>}
                {compselect === 0 && global.windowNewButton}
              </LeftNav>
            </TopBarMobile>
          </>
        )}
        {this.state.profile.show && profile && (
          <ProfileWrapBase style={profilestyle} ref={this.profileRef}>
            <ProfileTop style={{flexDirection: 'row'}}>
              {IsHRWeb() && (
                <DefaultIconButton
                  iconName={'times'}
                  mode="text"
                  iconType="FA5"
                  size={iconsize}
                  style={{margin: 0, width: iconsize, height: iconsize}}
                  action={() => self.CloseProfile()}
                />
              )}
              {IsHRWeb() === false && (
                <ActionButton onPress={() => self.CloseProfile()} title={translate('Close')} style={{flexGrow: 1}} />
              )}
            </ProfileTop>
            <ProfileWrapInner>
              {IsHRWeb() === false && this.ShowProfileInfo(translate('Name') + ': ', global.userData.empname)}
              {this.ShowProfileInfo(translate('Department') + ': ', profile['Department'])}
              {this.ShowProfileInfo(translate('ManagerInDeps'), profile['ManagerInDeps'])}
              {this.ShowProfileInfo(
                translate('TotalVacDaysLeft'),
                ConvertDisplayValue(WindowElementType.kInputTypeVal, profile['VacDaysLeft'])
              )}
              {isNaN(parseFloat(profile['ExtraUsedVacDays'])) === false &&
                this.ShowProfileInfo(
                  translate('ExtraVacDays'),
                  ConvertDisplayValue(
                    WindowElementType.kInputTypeVal,
                    parseFloat(profile['ExtaEarnVacDays']) - parseFloat(profile['ExtraUsedVacDays'])
                  )
                )}

              {this.ShowProfileInfo(translate('NextVaction'), profile['NextVacation'])}
              {this.ShowProfileInfo(translate('NextPlanVaction'), profile['NextPlanVacation'])}
            </ProfileWrapInner>
          </ProfileWrapBase>
        )}
        {this.state.shiftentry.show && (
          <ShiftEntry
            style={shiftentrystyle}
            shiftentry={this.state.shiftentry}
            key={this.state.shiftentry.data}
            close={() => {
              global.setShiftEntry({show: false});
            }}
          />
        )}
        {this.state.tooltip.show && (
          <Tooltip style={tooltipstyle}>
            <Text
              style={{
                whiteSpace: 'pre',
                color: colours[GetGlobalTheme()].titleText,
              }}>
              {this.state.tooltip.text}
            </Text>
          </Tooltip>
        )}
        {this.state.signers.show && (
          <SignersWindow
            record={this.state.signers.record}
            table={this.state.signers.table}
            readonly={this.state.signers.readonly}
          />
        )}
        {this.state.rowactions.show && (
          <RowActions
            style={rowactionsstyle}
            rowactions={this.state.rowactions}
            key={this.state.rowactions.data}
            matrix={this.state.rowactions.data}
            close={() => {
              this.setRowActions({show: false});
            }}
          />
        )}
        {this.state.pastespecial.show && (
          <PasteSpecialWindow
            table={this.state.pastespecial.table}
            row={this.state.pastespecial.row}
            psfields={this.state.pastespecial.psfields}
            psnewrec={this.state.pastespecial.psnewrec}
            psheader={this.state.pastespecial.psheader}
            pssort={this.state.pastespecial.pssort}
            psmulti={this.state.pastespecial.psmulti}
            psextraParams={this.state.pastespecial.psextraParams}
            name={this.state.pastespecial.name}
            onChange={this.state.pastespecial.onChange}
            value={this.state.pastespecial.value}
          />
        )}
        {this.state.switchAccount.show && <SwitchAccount />}
        {this.state.report.show && (
          <ReportWindow
            type={this.state.report.type}
            repname={this.state.report.repname}
            reportwindow={this.state.report.reportwindow}
            reptitle={this.state.report.reptitle}
            fields={this.state.report.fields}
          />
        )}

        {
          this.state.barcode.show /*&& (
          <BarcodeReader
            onChange={this.state.barcode.onChange}
          />
        )*/
        }
      </View>
    );
  }
}

export default TopNavigator;
