import React from 'react';
import {Dimensions, Linking, Platform, View} from 'react-native';
import {extractDateFromString} from '@tools/dateTools';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {objectHasProperty} from '@tools/objectTools';
import {log, logError, LogType} from '@utils/debug';
import {BackBehavior} from '@react-navigation/routers/src/TabRouter';
import {colours} from '@src/styles/Style';
import {TextNormal} from '@src/styles/primitiveComponents';
import {APISupportsItem} from '@src/utils/drawersTools';
import {DateFormatOrigin, ViewMode} from '@src/components/screens/types';
import {checkUserLeavingEditMode} from '@components/messageBox/Tools';
import {handleError} from '../../Tools/PortalLog';
import {b64toStr2, Strtob64} from '../../Tools/Base64Decode';

export const mobileRotateDelay = 100;

export const enum WindowElementType {
  kInputTypeNone = -1,
  kInputTypeText = 0,
  kInputTypeNumber = 1,
  kInputTypeDate = 2,
  kInputTypeCheckbox = 3,
  kInputTypeButton = 4,
  kInputTypeVal = 5,
  kInputTypeBarCode = 6,
  kInputTypeQty = 7,
  kInputTypeJointField = 8,
  kInputTypeLongText = 9,
  kInputTypeStaticText = 10,
  kInputTypeNumber2 = 11,
  kInputTypeValFull = 12,
  kInputTypeEmail = 13,
  kInputTypeMobile = 14,
}

const WindowTypes = {
  Browse: 0,
  Inspect: 1,
};

export async function storeData(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    logError(error);
    // saving error
  }
}

export async function getData(key) {
  let value = null;
  try {
    value = await AsyncStorage.getItem(key);
  } catch (error) {
    // error reading value
    logError(error);
  }
  return value;
}

export async function getAllData() {
  let value = null;
  try {
    value = await AsyncStorage.multiGet(['@language', '@colortheme', '@windowstates']);
  } catch (error) {
    // error reading value
    logError(error);
  }
  return value;
}

export function isSafariBrowser() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome') && !ua.includes('android');
}

function IsHRWeb() {
  return Platform?.OS === 'web';
}

function IsHRAndroid() {
  return Platform?.OS === 'android';
}

function IsHRiOS() {
  return Platform?.OS === 'ios';
}

function IsManager() {
  return global.userData.deplist !== '' || global.userData.admin === '1';
}

function isAdmin() {
  return global.userData.admin === '1';
}

function IsNarrowScreen() {
  let isNarrowScreen = Dimensions.get('window').width < 500;
  log('Is narrow screen: ', isNarrowScreen, LogType.displayToolsLog);
  return isNarrowScreen;
}

function isVerySmallScreen() {
  let isVerySmallScreen = Dimensions.get('window').width < 992;
  log('Is very small screen', isVerySmallScreen, LogType.displayToolsLog);
  return isVerySmallScreen;
}

function isSmallScreen() {
  let isSmallScreen = Dimensions.get('window').width >= 992 && Dimensions.get('window').width < 1200;
  log('Is small screen', isSmallScreen, LogType.displayToolsLog);

  return isSmallScreen;
}

function isMediumScreen() {
  let isMediumScreen = Dimensions.get('window').width >= 1200 && Dimensions.get('window').width < 1400;
  log('Is medium screen', isMediumScreen, LogType.displayToolsLog);
  return isMediumScreen;
}

function isLargeScreen() {
  let isLargeScreen = Dimensions.get('window').width >= 1400 && Dimensions.get('window').width < 1920;
  log('Is large screen', isLargeScreen, LogType.displayToolsLog);
  return isLargeScreen;
}

function isVeryLargeScreen() {
  let res = false;
  if (Dimensions.get('window').width >= 1920) {
    res = true;
  }
  return res;
}

function isMobileScreen() {
  return IsNarrowScreen();
}

function isTabletScreen() {
  return isVerySmallScreen();
}

function isMobileOrTabletScreen() {
  return isMobileScreen() || isTabletScreen();
}

function isDesktopScreen() {
  return isSmallScreen() || isMediumScreen() || isLargeScreen() || isVeryLargeScreen();
}

function ScreenWidth() {
  return Dimensions.get('window').width;
}

function ScreenHeight() {
  return Dimensions.get('window').height;
}

function GetUserTheme(): string {
  let colorTheme = '';
  let cookie;
  if (IsHRWeb()) {
    cookie = document.cookie.split('; ').find((row) => row.startsWith('colortheme='));
    if (cookie) {
      colorTheme = cookie.split('=')[1];
      return colorTheme;
    }
  }
  //else {
  //  colorTheme = Cookie.get('http://hrportal.excellent.lv','colortheme');
  //}
  const isColorThemeValid = colorTheme === 'dark' || colorTheme === 'light';
  if (!isColorThemeValid) {
    return 'light';
  }
  return 'light';
}

function GetGlobalTheme(): string {
  return global.theme || GetUserTheme() || 'light';
}

function getEditFieldDataType(fieldType: WindowElementType, options: {dateFormatOrigin: DateFormatOrigin}) {
  let type = fieldType ?? WindowElementType.kInputTypeText;
  if (options.dateFormatOrigin === DateFormatOrigin.Database) {
    type = WindowElementType.kInputTypeText;
  }
  return type;
}

function pad(s, num = 2) {
  let res = s.toString();
  if (res.length < num) {
    let l = res.length;
    for (let i = l; i < num; i++) {
      res = '0' + res;
    }
  }
  return res;
}

function ConvertDateField(value) {
  let res = '';
  if (value !== '' && value !== undefined) {
    let td = value;
    if (typeof value !== 'object') {
      // we shouldn't really get date objects there
      td = extractDateFromString(value);
    }
    res = global.userData.dateformat.replace('YYYY', td.getFullYear());
    res = res.replace('MM', pad(td.getMonth() + 1));
    res = res.replace('DD', pad(td.getDate()));
  }
  return res;
}

function ConvertValField(value, datatype) {
  let res = value;
  if (typeof res === 'number') {
    res = res.toString();
  }
  if (value !== undefined) {
    if (value === '') {
      res = ''; //'0.00';
      return res;
    }
    let negf = false;
    let tmp = parseFloat(res);
    if (tmp < 0) {
      negf = true;
      res = res.substring(1);
    }
    let tval = Math.round(parseFloat(res) * 100) / 100;
    let tint = parseInt(res);
    let thousands = Math.floor(tint / 1000);
    let rest = tint - thousands * 1000;
    let dec = Math.abs(Math.round(tval * 100) - tint * 100);

    res = '';
    if (thousands > 0) {
      res = thousands;
      if (global.userData.thousand_separator) {
        res += global.userData.thousand_separator;
      }
      res += pad(rest, 3);
    } else {
      res += rest;
    }
    if (dec > 0 || datatype === WindowElementType.kInputTypeValFull) {
      res = res + global.userData.dec_separator;
      let decstr = dec.toString();
      if (decstr.length === 1) {
        decstr = '0' + decstr;
      }
      res = res + decstr;
      while (res.slice(-1) === '0' && datatype !== WindowElementType.kInputTypeValFull) {
        res = res.slice(0, -1);
      }
    }
    if (negf) {
      res = '-' + res;
    }
  }

  return res;
}

function ConvertValFieldBack(value) {
  let res = value;
  if (value !== undefined) {
    res = res.replace(global.userData.dec_separator, '.');
    res = res.replace(',', '.');
    if (global.userData.thousand_separator) {
      res = res.replace(global.userData.thousand_separator, '');
    }
  }
  return res;
}

function ConvertDisplayValue(datatype, value, value2 = '') {
  let res = value;
  switch (datatype) {
    case WindowElementType.kInputTypeVal:
    case WindowElementType.kInputTypeValFull:
      res = ConvertValField(value, datatype);
      break;
    case WindowElementType.kInputTypeDate:
      res = ConvertDateField(value);
      break;
    case WindowElementType.kInputTypeJointField:
      res = value + ' ' + value2;
      break;
  }

  return res;
}

function RevertDisplayValue(datatype, value) {
  let res = value;
  switch (datatype) {
    case WindowElementType.kInputTypeVal:
    case WindowElementType.kInputTypeValFull:
      res = ConvertValFieldBack(value);
      break;
  }

  return res;
}

export function FormatActionButton(value, type, style?) {
  let res = [<TextNormal style={style}>{value}</TextNormal>];
  switch (type) {
    case WindowElementType.kInputTypeEmail:
      res = [
        <TextNormal
          style={{...style, color: colours[GetGlobalTheme()].secondary, textDecoration: 'underline'}}
          onPress={() => Linking.openURL('mailto:' + value)}>
          {value}
        </TextNormal>,
      ];
      break;
    case WindowElementType.kInputTypeMobile:
      res = [
        <TextNormal
          style={{...style, color: colours[GetGlobalTheme()].secondary, textDecoration: 'underline'}}
          onPress={() => Linking.openURL('tel:' + value)}>
          {value}
        </TextNormal>,
      ];
      break;
  }
  return res;
}

function ShouldRefreshWindow(self) {
  let res = false;
  if (
    self.props.route.params &&
    (objectHasProperty(self.props.route.params, 'id') ||
      (objectHasProperty(self.props.route.params, 'update') && self.state.WindowType === WindowTypes.Inspect))
  ) {
    res = true;
  }

  return res;
}

function DisplayApprovalStatus(value, curval, rowindex, colindex, row): string {
  let res = ' ';
  switch (curval) {
    case '-1':
      if (row !== undefined && row['okflag'] === '1') {
        res = String.fromCharCode(10004);
      }
      break;
    case '1':
      res = String.fromCharCode(10067);
      break;
    case '2':
      res = String.fromCharCode(10071);
      break;
    case '3':
      res = String.fromCharCode(8987);
      break;
    case '4':
      res = String.fromCharCode(10004);
      break;
    case '5':
      res = String.fromCharCode(10060);
      break;
  }
  return res;
}

function ResetRecord(self, index, rowindex, value) {
  let trec = self.state.record;
  let tval = '';
  if (rowindex > -1) {
    tval = self.state.record.rows[rowindex][index];
    trec.rows[rowindex][index] = value;
  } else {
    tval = self.state.record[index];
    trec[index] = value;
  }
  self.setState({record: trec}, function () {
    if (rowindex > -1) {
      trec.rows[rowindex][index] = tval;
    } else {
      trec[index] = tval;
    }
    self.setState({record: trec});
  });
}

function goBack(window, name) {
  //window.navigation.goBack();
  DoNavigate(window, name, false, '', true, true);
}

function GetPasteSpecialValue(ps, id) {
  let vector = global.pasteSpecialsVector[ps];
  if (vector) {
    return vector[id];
  }
}

function convertKeysToLowerCase(obj) {
  if (obj === null || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj;
  }

  return Object.keys(obj).reduce((acc, key) => {
    const lowerKey = key.toLowerCase();
    acc[lowerKey] = convertKeysToLowerCase(obj[key]);
    return acc;
  }, {});
}

function setPasteSpecialValue(ps, id, value) {
  let vector = global.pasteSpecialsVector[ps];
  if (vector === undefined) {
    global.pasteSpecialsVector[ps] = {};
  }
  let obj = {...value, ...convertKeysToLowerCase(value)};
  global.pasteSpecialsVector[ps][id] = obj;
}

function getDefaultBackBehaviour(): BackBehavior {
  const backBehavior: BackBehavior = 'history';
  return backBehavior;
}

function WindowWrap(props) {
  return (
    <View
      style={{
        width: '100%',
        display: 'inline-block',
        background: '#FAFAFA', //TODO: Temporary color before list windows are finished
        height: '100%',
        padding: 0,
        margin: 0,
        alignItems: 'center',
        flexShrink: 1,
        flexGrow: 1,
      }}>
      {props.children}
    </View>
  );
}

function GetParameterID(window) {
  let res = '';

  if (window.props.route?.params && window.props.route.params.id) {
    res = window.props.route.params.id;
  }
  if (window.props.route?.params && window.props.route?.params.params && window.props.route?.params.params.id) {
    res = window.props.route.params.params.id;
  }

  return res;
}

function getParameterRecordData(window) {
  let recordData = '';

  if (window.props.route?.params && window.props.route.params.recordData) {
    recordData = window.props.route.params.recordData;
  }
  if (window.props.route?.params && window.props.route?.params.params && window.props.route?.params.params.recordData) {
    recordData = window.props.route.params.params.recordData;
  }

  if (!recordData) {
    return;
  }
  recordData = decodeURIComponent(recordData);
  recordData = b64toStr2(recordData);
  recordData = JSON.parse(recordData);

  return recordData;
}

function clearParameterRecordData(window) {
  if (window.props.route?.params) {
    window.props.route.params.recordData = null;

    if (window.props.route.params.params) {
      window.props.route.params.params.recordData = null;
    }
  }
}

function DoNavigate(
  base,
  basescreen,
  record = false,
  id = '',
  updatef = false,
  list = false,
  custompath = '',
  recordData
) {
  const params = {};

  params.update = updatef;

  if (id !== '') {
    params.id = id;
  }

  if (recordData) {
    params.recordData = Strtob64(JSON.stringify(recordData));
  }

  let screen = '';
  screen = record ? basescreen + 'Record' : screen;
  screen = list ? basescreen + 'List' : screen;
  screen = custompath !== '' ? basescreen + custompath : screen;

  let obj: {};
  if (screen !== '') {
    obj = {screen: screen, params: params};
  } else {
    obj = params;
  }
  checkUserLeavingEditMode(() => {
    if (global.activeInspectWindow) {
      global.activeInspectWindow.setState({viewMode: ViewMode.View});
    }
    global.ClearEscapeActions();

    base.navigation.navigate(basescreen, obj);
  });
}

export function ShowBreakHours() {
  return objectHasProperty(shiftBlock, 'showbreakhours') && global.shiftBlock.showbreakhours === '1';
}

export function UsePagination() {
  return APISupportsItem('haspagination');
}

export function Supported(app) {
  return APISupportsItem(app);
}

export function getFormattedDisplayValue(format: string, record: {}, recordLinkFunction?) {
  if (!record || format === '' || !format || Object.keys(record).length === 0) {
    handleError('Failed to format display value, invalid parameters given!');
    return '';
  }

  const formattedString = format.replace(/{(\w+)}/g, (_, key) => record[key] || '');

  if (recordLinkFunction && formattedString) {
    return (
      <TextNormal
        style={{...{color: colours[GetGlobalTheme()].secondary, textDecoration: 'underline'}}}
        onPress={() => recordLinkFunction()}>
        {formattedString}
      </TextNormal>
    );
  }
  return formattedString;
}

export function isBlockRecord(vcName: string | number) {
  return vcName.toString().slice(-5) === 'Block';
}

export function requiresSearchForPasteSpecial(table: number | string) {
  switch (table) {
    case 0:
    case 1:
    case 24:
    case 25:
    case 'UserVc':
    case 'CUVc':
    case 'VEVc':
    case 'INVc':
      return true;
  }
  return false;
}

function getRegisterNameFromPath() {
  const path = window.location.pathname;
  let registerName = '';
  if (path) {
    registerName = path.split('/hr-portal/')[1]?.split('/')[0]?.split('?')[0];
    registerName = registerName?.split('-').join('');
  }

  return registerName;
}

export {
  GetUserTheme,
  GetGlobalTheme,
  IsHRWeb,
  IsHRAndroid,
  ConvertDateField,
  WindowTypes,
  ConvertDisplayValue,
  RevertDisplayValue,
  ShouldRefreshWindow,
  IsManager,
  isAdmin,
  isVerySmallScreen,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
  isVeryLargeScreen,
  IsNarrowScreen,
  DisplayApprovalStatus,
  IsHRiOS,
  goBack,
  GetPasteSpecialValue,
  setPasteSpecialValue,
  getDefaultBackBehaviour,
  WindowWrap,
  GetParameterID,
  getParameterRecordData,
  DoNavigate,
  ScreenHeight,
  ScreenWidth,
  ResetRecord,
  isMobileScreen,
  isTabletScreen,
  isMobileOrTabletScreen,
  isDesktopScreen,
  getEditFieldDataType,
  getRegisterNameFromPath,
  clearParameterRecordData,
};
