import React, {useState} from 'react';
import {colours, iconSize} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import Company from '@components/switchAccount/Company';
import {CompanyData} from '@utils/types';
import {switchCompany} from '@utils/companyTools';
import EditField from '../editElements/EditField';
import {
  CompanyContainer,
  CompanySection,
  SearchBar,
  SearchBarWrapper,
  SearchIconStyle,
} from '@components/switchAccount/SwitchAccount.styles';
import {translate} from '@utils/languageTools';

import {isDesktopScreen} from '@tools/displayTools';
import {ModalSaveCancelFooter} from '@components/editElements/modalWindow/ModalSaveCancelFooter';
import {ModalWindow} from '@components/editElements/modalWindow/ModalWindow';

let doubleClickable = false;
export const SwitchAccount = () => {
  const [companyArr] = useState<CompanyData[]>(global.userData.complist);
  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState<string>(global.userData.selectedcomp);
  const [searchParams, setSearchParams] = useState<string>('');

  const doubleClickDurationInMS = 350;

  function handleCompanyPress(companyUuid: string) {
    if (selectedCompanyUuid === companyUuid && doubleClickable) {
      switchCompany(selectedCompanyUuid);
      closeSwitchAccount();
      return;
    }
    if (isDesktopScreen()) {
      doubleClickable = true;
      setTimeout(disableDoubleClick, doubleClickDurationInMS);
    }
    setSelectedCompanyUuid(companyUuid);
  }

  function disableDoubleClick() {
    doubleClickable = false;
  }

  function closeSwitchAccount() {
    global.showSwitchAccount({show: false});
  }

  function handleCancelButtonClick() {
    closeSwitchAccount();
  }

  function handleOpenButtonClick() {
    switchCompany(selectedCompanyUuid);
    closeSwitchAccount();
  }

  function filterCompaniesBySearch(text) {
    setSearchParams(text.toUpperCase());
  }

  function getModalContentComponent() {
    return (
        <CompanySection>
          <EditField
            title={''}
            name={''}
            value={''}
            placeholder={translate('SearchCompany') + '...'}
            placeholderTextColor={colours[GetGlobalTheme()].grey600}
            onChangeText={(text) => filterCompaniesBySearch(text)}
            inputStyle={SearchBar}
            wrapperStyle={SearchBarWrapper}
            iconName={'SearchIcon'}
            iconStyle={SearchIconStyle}
            iconHeight={iconSize.iconSize}
          />
          <CompanyContainer>
            {companyArr
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter(({name}) => !searchParams || name.toUpperCase().includes(searchParams))
              .map(({name, uuid}) => (
                <Company
                  key={uuid}
                  companyName={name}
                  uuid={uuid}
                  onPress={handleCompanyPress}
                  selected={selectedCompanyUuid === uuid}
                />
              ))}
          </CompanyContainer>
        </CompanySection>
    );
  }

  function getModalFooterComponent() {
    return (
      <ModalSaveCancelFooter
        onCancelPress={() => handleCancelButtonClick()}
        cancelButtonTitle={translate('Cancel')}
        onSavePress={() => handleOpenButtonClick()}
        saveButtonTitle={translate('Open')}
      />
    );
  }

  return (
      <ModalWindow
        inTransparent={true}
        content={getModalContentComponent()}
        headerProps={{
          title: translate('SwitchAccounts'),
          onCloseButtonPress: () => closeSwitchAccount(),
        }}
        footerComponent={getModalFooterComponent()}
      />
  );
};
