import React, {useState} from 'react';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {getSelectedCompanyName} from '@utils/companyTools';
import {MoreDropdown} from '@components/navigation/MoreDropdown';
import {NavigationButtons} from '@components/navigation/NavigationButtons';
import {ActionButton} from '@components/button/ActionButton';
import {logout} from '@utils/loginTools';
import {translate} from '@utils/languageTools';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../Tools/DisplayTools';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import styled from 'styled-components/native';
import {ColumnContainer, Header6, RowContainer, TextNormalBold} from '@styles/primitiveComponents';
import {getDrawers} from '@src/utils/drawersTools';
import {DoNavigate, isDesktopScreen, isMobileScreen, isTabletScreen} from '@src/tools/displayTools';
import {Dimensions} from 'react-native';
import {
  maximizedNavigatorSize,
  minimizedNavigatorSize,
  navigatorAnimationDuration,
} from '@components/navigation/sideNavigator/DrawerContentWrapper';
import {ProfileIcon} from '@src/components/status/ProfileIcon';

const LogoContainer = styled(ColumnContainer)`
  margin-top: ${spacing.space24};
  margin-bottom: ${spacing.space40};
`;
const ProfileContainer = styled(ColumnContainer)`
  align-items: center;
  gap: ${spacing.space12};
  z-index: 999;
`;

const ProfileDataContainer = styled(ColumnContainer)`
  align-items: center;
  text-align: center;
`;

const CompanyName = styled(TextNormalBold)`
  text-align: center;
  color: ${colours[GetGlobalTheme()].white};
`;

export const EmployeeName = styled(Header6)`
  color: ${colours[GetGlobalTheme()].white};
`;

const LogoutContainer = styled(RowContainer)`
  margin-top: auto;
  width: 100%;
  justify-content: start;
  margin-bottom: ${spacing.space40};
  align-items: center;
`;

const LogoutIcon = {
  order: '-1',
};

type CustomDrawerContentProps = {
  drawerProps: DrawerContentComponentProps;
  isSideNavigatorOpen: boolean;
  onMinimizeButtonPress: () => void;
};

export const CustomDrawerContent = ({
  drawerProps,
  isSideNavigatorOpen,
  onMinimizeButtonPress,
}: CustomDrawerContentProps) => {
  const [showMinimizeButton, setShowMinimizeButton] = useState<boolean>(true);

  function handleMinimizeButtonPress() {
    onMinimizeButtonPress();
    setShowMinimizeButton(false);
    setTimeout(() => setShowMinimizeButton(true), navigatorAnimationDuration);
  }

  const LogoutButtonStyle = {
    width: '100%',
    flexDirection: isSideNavigatorOpen ? 'row' : 'column',
    justifyContent: isSideNavigatorOpen ? 'start' : 'center',
    alignItems: 'center',
  };

  const MinimizeButtonStyle = {
    position: 'fixed',
    left: isSideNavigatorOpen ? maximizedNavigatorSize - 18 : minimizedNavigatorSize - 18,
    padding: spacing.space8,
    borderRadius: 6,
    top: spacing.space48,
    transform: isSideNavigatorOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    width: 32,
    height: 32,
  };
  const CardContainerStyle = {
    zIndex: 'auto',
    alignItems: 'center',
    margin: 0,
    height: Dimensions.get('window').height + 'px',
    backgroundColor: colours[GetGlobalTheme()].darkBlue,
    top: 0,
    overflowY: 'visible',
    overflowX: isDesktopScreen() ? (isSideNavigatorOpen ? 'auto' : 'visible') : 'visible',
    padding: 0,
  };

  if (global.redirectTo) {
    let drawers = getDrawers(1);
    const matchedDrawer = drawers.find((drawer) => redirectTo.includes(drawer.link));
    if (matchedDrawer) {
      const recordLink = matchedDrawer.recordlink || '';
      const recordLinkPart = recordLink.split(':')[0] || '';
      const id = redirectTo.split('/').pop() || '';
      if (redirectTo.includes(recordLinkPart)) {
        DoNavigate(drawerProps, matchedDrawer.screen, true, id);
      } else {
        DoNavigate(drawerProps, matchedDrawer.screen, false, '', false, true, '');
      }
      global.redirectTo = null;
    }
  }

  function getDesktopComponent() {
    return (
      <ColumnContainer style={{...CardContainerStyle, paddingHorizontal: isSideNavigatorOpen ? spacing.space24 : 0}}>
        {showMinimizeButton && (
          <ActionButton
            onPress={() => handleMinimizeButtonPress()}
            iconName={'DoubleArrows'}
            title={''}
            style={MinimizeButtonStyle}
          />
        )}
        <LogoContainer>
          {isSideNavigatorOpen ? (
            <PortalIcon iconName={'ExcellentLogo'} iconWidth={88} iconHeight={17} />
          ) : (
            <PortalIcon iconName={'ExcellentLogoX'} iconWidth={24} iconHeight={24} />
          )}
        </LogoContainer>
        <ProfileContainer>
          <ProfileIcon />
          {isSideNavigatorOpen && (
            <>
              <ProfileDataContainer>
                <EmployeeName> {global.userData.empname} </EmployeeName>
                <CompanyName> {getSelectedCompanyName()} </CompanyName>
              </ProfileDataContainer>
              <MoreDropdown />
            </>
          )}
        </ProfileContainer>
        <NavigationButtons drawerProps={drawerProps} isSideNavigatorOpen={isSideNavigatorOpen} />
        <LogoutContainer>
          <ActionButton
            onPress={logout}
            title={translate('logout')}
            iconName={'LogoutIcon'}
            iconColor={colours[GetGlobalTheme()].white}
            iconStyle={LogoutIcon}
            textStyle={{
              marginLeft: '4px',
              color: colours[GetGlobalTheme()].white,
            }}
            style={LogoutButtonStyle}
            iconSize={isSideNavigatorOpen ? 12 : 20}
          />
        </LogoutContainer>
      </ColumnContainer>
    );
  }

  function getMobileComponent() {
    if (!isSideNavigatorOpen) {
      return <ColumnContainer style={CardContainerStyle} />;
    }
    return (
      <ColumnContainer style={CardContainerStyle}>
        <NavigationButtons
          drawerProps={drawerProps}
          isSideNavigatorOpen={isMobileScreen() || isTabletScreen() ? false : isSideNavigatorOpen}
        />
      </ColumnContainer>
    );
  }

  return <>{isMobileScreen() || isTabletScreen() ? getMobileComponent() : getDesktopComponent()}</>;
};
