import Requests from '../../api/Requests';
import {IsHRAndroid} from '../../Tools/DisplayTools';
import {KeychainEXC as Keychain} from '../../Tools/Stubs';
import {RequestEmpData} from '../../App';
import {logError} from './debug';
import {checkUserLeavingEditMode} from '@components/messageBox/Tools';

export function logout() {
  let func = () => {
    Requests.logout()
      .then(() => {
        if (IsHRAndroid()) {
          Keychain.resetGenericPassword('', '').then(() => RequestEmpData());
        } else {
          RequestEmpData();
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  checkUserLeavingEditMode(() => {
    func();
  });
}
