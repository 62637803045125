import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, iconSize, spacing} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from 'src/Tools/DisplayTools';
import WindowTile from './WindowTile';
import {AttachmentWindow} from '@components/attachments/attachmentWindow';
import {objectHasProperty} from '@tools/objectTools';
import {FieldSection, WindowDefinition2, WindowRecord} from '@utils/types';
import {ViewMode} from '../../screens/types';
import {ActionButton} from '../../button/ActionButton';
import {translate} from '@src/utils/languageTools';
import {logError} from '@utils/debug';
import AlertWindow, {AlertType} from '@components/design/AlertWindow';
import BackToTopButton from '../../button/BackToTopButton';
import {DoNavigate, goBack, isDesktopScreen, isMobileOrTabletScreen, ScreenWidth} from '@src/tools/displayTools';
import FilePreview from '../../FilePreview/FilePreview';
import Requests from 'src/api/Requests';
import {InspectTopBar} from './InspectTopBar';
import {Modal, View} from 'react-native';
import {ColumnContainer, RowContainer} from '@styles/primitiveComponents';
import {OutsideClickWrapper} from '@components/editElements/OutsideClickWrapper';
import {CopyButton, DeleteButton, PrintButton} from '@components/editElements/InspectWindowActionButtons';
import {checkUserLeavingEditMode} from '@components/messageBox/Tools';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {InspectWindowTabSection} from '@components/editElements/inspectWindow/InspectWindowTabSection';
import {InspectStatus} from '@components/editElements/inspectWindow/InspectStatus';
import {OperationsMenu} from '@components/editElements/inspectWindow/OperationsMenu';

const InspectWindowContent = styled.View<{isMobileOrTabletScreen: boolean}>`
  -direction: column;
  padding: ${(props) => (props.isMobileOrTabletScreen ? 0 : spacing.space24)}px;
  gap: ${spacing.space20};
  width: 100%;
`;

const InspectWindowContentWrapper = styled.View<{isMobileOrTabletScreen: boolean}>`
  flex-direction: ${(props) => (props.isMobileOrTabletScreen ? 'column' : 'row')};
  gap: ${spacing.space16}px;
  width: 100%;
`;

const InspectActionPane = styled.View`
  width: 300px;
  background: ${colours[GetGlobalTheme()].white};
  border-radius: ${spacing.space12}px;
  padding: ${spacing.space16}px;
  gap: ${spacing.space16}px;
  z-index: -5;
`;
const InspectActionPaneEmpty = styled.View`
  width: 300px;
  background: ${colours[GetGlobalTheme()].white};
  border-radius: ${spacing.space12}px;
  padding: ${spacing.space16}px;
  gap: ${spacing.space16}px;
  height: 152px;
`;
const EmptyPane = styled.View`
  height: 120px;
  background: ${colours[GetGlobalTheme()].grey100};
  border-radius: ${spacing.space12}px;
`;

const InspectWindowWrapper = styled.View<{screenWidth: number; isMobileOrTabletScreen: boolean}>`
  border-radius: ${spacing.space12};
  background: ${colours[GetGlobalTheme()].grey200};

  ${(props) =>
    (props.isMobileOrTabletScreen &&
      css`
        padding: ${spacing.space24}px ${spacing.space16}px ${spacing.space24}px ${spacing.space16}px;
        border-radius: ${spacing.space12};
        background-color: ${colours[GetGlobalTheme()].contentBG};
      `) ||
    css`
      width: 100%;
      padding: 0 0 ${spacing.space16}px 0;
    `}
`;

const SideSectionFields = styled.View`
  padding-bottom: ${spacing.space16}px;
  border-bottom: 1px solid ${colours[GetGlobalTheme()].borderDefault};
  z-index: 4;
`;

const ActionWrap = styled.View`
  gap: ${spacing.space12}px;
  padding-bottom: ${spacing.space16}px;
  border-bottom: 1px solid ${colours[GetGlobalTheme()].borderDefault};
  z-index: 5;
`;

const MobileActionContainerStyle = {
  backgroundColor: 'rgba(63, 63, 63, 0.4)',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

const MobileActionsButtonStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 1,
  width: '100%',
  margin: 6,
};

const MobileActionButtonStyle = {
  background: 'none',
  flexDirection: 'column-reverse',
  height: 52,
};

const MobileActionButtonIconStyle = {
  border: 'none',
  background: colours[GetGlobalTheme()].contentBG,
  padding: spacing.space14,
  borderRadius: 8,
};

const MobileCancelButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  borderRadius: 12,
  flexGrow: 1,
};

const MobileSaveButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].secondary,
  borderRadius: 12,
  flexGrow: 1,
};

export const MobileBackButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  margin: 0,
  width: 30,
  height: 30,
  padding: 0,
  backgroundRadius: 8,
  marginRight: spacing.space12,
};

export function getCellStyle() {
  return isMobileOrTabletScreen()
    ? {justifyContent: 'flex-start', zIndex: -1}
    : {
        justifyContent: 'flex-end',
      };
}

export function getMatrixTextAlign(viewMode: ViewMode) {
  if (isMobileOrTabletScreen() && viewMode === ViewMode.Edit) {
    return 'right';
  } else if (isMobileOrTabletScreen() && viewMode === ViewMode.View) {
    return 'start';
  }

  return 'right';
}

//this is needed for future
function populateColumns(columns, regname) {
  let newColumns = undefined;
  if (columns) {
    newColumns = columns;
    if (columns.updated === undefined) {
      columns.updated = true;
      let ev = columns[0].onchange;
      if (ev === undefined) {
        ev = columns[1].onchange;
      }
      if (global.customfields[regname] !== undefined) {
        let f = global.customfields[regname];
        for (let i = 0; i < f.length; i++) {
          if (f[i].type === '1') {
            let ps_el = null;
            if (f[i].paste !== '') {
              let cols = [];
              if (global.custompastespecials[f[i].paste] !== undefined) {
                let ps = global.custompastespecials[f[i].paste];
                for (let j = 0; j < ps.fields.length; j++) {
                  if (i === 0) {
                    cols.push({
                      name: ps.fields[j].label,
                      width: ps.fields[j].width + '%',
                      mainkey: ps.fields[j].fieldname,
                      key: ps.fields[j].fieldname, //.toLowerCase(),
                    });
                  } else {
                    cols.push({
                      name: ps.fields[j].label,
                      width: ps.fields[j].width + '%',
                      key: ps.fields[j].fieldname, //.toLowerCase(),
                    });
                  }
                }
                ps_el = {
                  ps: ps.vcname,
                  pasteSpecialHeader: cols,
                  pasteSpecialMulti: ps.multi === '1',
                };
              }
            }
            newColumns.push({
              title: f[i].label,
              dataKey: f[i].fieldname,
              width: f[i].width,
              viewOnly: false,
              onchange: ev,
              ...ps_el,
            });
          }
        }
      }
    }
  }
  return newColumns;
}

//this is needed for future
function populateFields(columns, regname) {
  let newColumns = columns;
  if (columns.updated === undefined) {
    columns.updated = true;
    if (global.customfields[regname] !== undefined) {
      let ev = columns[0].fields[0].onChange;
      if (ev === undefined) {
        ev = columns[0].fields[1].onChange;
      }
      let f = global.customfields[regname];
      for (let i = 0; i < f.length; i++) {
        if (f[i].type === '0') {
          let ps_el = null;
          if (f[i].paste !== '') {
            let cols = [];
            if (global.custompastespecials[f[i].paste] !== undefined) {
              let ps = global.custompastespecials[f[i].paste];
              for (let j = 0; j < ps.fields.length; j++) {
                if (i === 0) {
                  cols.push({
                    name: ps.fields[j].label,
                    width: ps.fields[j].width + '%',
                    mainkey: ps.fields[j].fieldname,
                    key: ps.fields[j].fieldname, //.toLowerCase(),
                  });
                } else {
                  cols.push({
                    name: ps.fields[j].label,
                    width: ps.fields[j].width + '%',
                    key: ps.fields[j].fieldname, //.toLowerCase(),
                  });
                }
              }
              ps_el = {
                pasteSpecial: {
                  vcName: ps.vcname,
                  columns: cols,
                  multiValue: ps.multi === '1',
                },
              };
            }
          }
          newColumns[newColumns.length - 1].fields.push({
            title: f[i].label,
            dataKey: f[i].fieldname,
            width: f[i].width,
            viewOnly: false,
            onChange: ev,
            ...ps_el,
          });
        }
      }
    }
  }
  return newColumns;
}

//this is needed for future
function populateRecordFields(record, regname) {
  let newColumns = record;
  if (Object.keys(newColumns).length > 0) {
    if (global.customfields[regname] !== undefined) {
      let f = global.customfields[regname];
      for (let i = 0; i < f.length; i++) {
        if (f[i].type === '0') {
          if (objectHasProperty(newColumns, f[i].fieldname) === false) {
            newColumns[f[i].fieldname] = '';
          }
        }
        if (f[i].type === '1') {
          if (objectHasProperty(newColumns, 'rows')) {
            for (let j = 0; j < newColumns.rows.length; j++) {
              if (objectHasProperty(newColumns.rows[j], f[i].fieldname) === false) {
                newColumns.rows[j][f[i].fieldname] = '';
              }
            }
          }
        }
      }
    }
  }
  return newColumns;
}

export type InspectWindowProps = {
  title?: string;
  window: RecordScreen<any, any>;
  headerFields: WindowDefinition2;
  record: WindowRecord;
  allowDelete?: boolean;
  okcheck?: string;
  forceEditMode?: boolean;
  hideActionPane?: boolean;
  allowPrint?: boolean;
  hasBackButton?: boolean;
  width?: any;
  minwidth?: any;
  maxwidth?: any;
  windoww?: any;
  sideSectionFields?: FieldSection[];
  showAttachments?: boolean;
  showStatusOnMobile?: boolean;
  preLoadActiveEditFields?: boolean;
  backButton?: () => void;
  onClick?: () => void;
  onDeleteRefreshList?: () => void;
};

export type AlertMessage = {
  showAlertMessage: boolean;
  message?: string;
  alertMessageType?: AlertType;
};

export type InspectWindowState = {
  viewMode: ViewMode;
  windoww: number;
  pwidth: number;
  showOperationsMenu: boolean;
  attachments?: {
    showAttachments: boolean;
  };
  approvals?: {
    showApprovalPersons: boolean;
  };
  table: any;
  id: any;
  id2: any;
  attach_cnt: number;
  approval_cnt: number;
  alertMessage: AlertMessage;
  showPreview: boolean;
  previewFile: any;
  activeTile: number;
  update: boolean;
};

export class InspectWindow extends Component<InspectWindowProps, InspectWindowState> {
  constructor(props) {
    super(props);
    const self = this;
    this.state = {
      viewMode: this.props.forceEditMode ? ViewMode.Edit : ViewMode.View,
      windoww: 0,
      pwidth: 0,
      activeTile: 0,
      showOperationsMenu: false,
      attachments: {
        showAttachments: self.props.showAttachments,
      },
      approvals: {
        showApprovalPersons: false,
      },
      table: this.props.window.table,
      id: this.props.window.id,
      id2: this.props.window.id,
      attach_cnt: 0,
      approval_cnt: 0,
      alertMessage: {
        showAlertMessage: false,
        message: '',
        alertMessageType: AlertType.Success,
      },
      showPreview: false,
      previewFile: {},
      update: false,
    };
    if (this.props.window.state.approvallistid) {
      this.state.id2 = this.props.window.state.approvallistid;
    }
    if (this.state.viewMode === ViewMode.Edit) {
      global.activeInspectWindow = this;
    }

    global.refreshInspectWindow = () => {
      self.setState({update: !self.state.update});
    };

    global.setAttachmentBadge = (cnt) => {
      self.setState({attach_cnt: cnt});
    };

    global.setApprovalListBadge = (cnt) => {
      self.setState({approval_cnt: cnt});
    };

    if (this.props.preLoadActiveEditFields) {
      this.getActiveEditFields();
    }
  }

  openAttachments() {
    if (this.state.attachments.showAttachments) {
      this.setState({attachments: {showAttachments: false}});
    } else {
      this.setState({
        attachments: {showAttachments: true},
        approvals: {showApprovalPersons: false},
      });
    }
  }

  getActiveEditFields(callback?) {
    this.props.window.requestActiveEditFields(callback);
  }

  componentDidUpdate() {
    if (this.props.window.isNewRecord()) {
      const isFieldFilled = this.props.window.isNewRecordFieldFilled();
      if (this.state.attachments.showAttachments !== isFieldFilled) {
        this.setState({attachments: {showAttachments: isFieldFilled}});
      }
    }

    if (this.props.forceEditMode && this.state.viewMode !== ViewMode.Edit) {
      this.setState({viewMode: ViewMode.Edit});
    }
    if (this.state.id !== this.props.window.id) {
      this.setState({id: this.props.window.id});
    }
    if (this.props.window.state.approvallistid) {
      if (this.state.id2 !== this.props.window.state.approvallistid) {
        this.setState({id2: this.props.window.state.approvallistid});
      }
    } else {
      if (this.state.id2 !== this.props.window.id) {
        this.setState({id2: this.props.window.id});
      }
    }
  }

  componentDidMount(): void {
    this.handleDelayedStatusMessage();
  }

  handleDelayedStatusMessage() {
    if (global.delayedStatusMesssage) {
      this.changeAlertMessage(
        true,
        global.delayedStatusMesssage.statusMessage,
        global.delayedStatusMesssage.statusMessageType
      );
      global.delayedStatusMesssage = null;
    }
  }

  showPreview = () => {
    const self = this;
    Requests.downloadDocument(this.props.window.table, {id: this.props.window.id})
      .then((response) => {
        if (response.file !== '') {
          self.setState({showPreview: true, previewFile: response});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  hidePreview = () => {
    this.setState({showPreview: false});
  };

  storeWindowRecord = (storeCallback = null) => {
    const self = this;
    this.props.window.sendRecordData((response, callbackFunc) => {
      if (response.success) {
        //send attachments
        if (response.statusMessage) {
          self.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
        } else {
          self.closeAlertMessage();
        }
        self.props.record.clearUpdateFields();
        if (objectHasProperty(response, 'record')) {
          self.props.record.applyNewValues(response.record);
        }
        let id: string;
        if (response.record) {
          id = response.record[self.props.window.IDField];
        }
        global.updateAttachmentsOnSave(id, () => {
          self.setState({viewMode: ViewMode.View}, () => {
            global.activeEditMode = false;
            if (callbackFunc) {
              callbackFunc();
              if (storeCallback) {
                storeCallback();
              }
            } else {
              if (storeCallback) {
                storeCallback();
              }
            }
          });
        });
        return;
      }

      if (response.statusMessage) {
        self.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
      } else {
        self.closeAlertMessage();
      }
    });
  };

  showInspectActions = () => {
    const actions = [];
    const self = this;
    if (this.state.viewMode == ViewMode.View) {
      if (objectHasProperty(this.props.headerFields, 'startViewComponent')) {
        actions.push(this.props.headerFields.startViewComponent(this));
      }
    }
    if (this.props.headerFields.editable) {
      switch (this.state.viewMode) {
        case ViewMode.View:
          actions.push(
            <ActionButton
              title={translate('Edit')}
              style={{backgroundColor: colours[GetGlobalTheme()].secondary}}
              onPress={() => {
                checkUserLeavingEditMode(() => {
                  this.getActiveEditFields(() => {
                    this.props.window.record.addMatrixRow();
                    this.setState({viewMode: ViewMode.Edit});
                    global.activeInspectWindow = self;
                  });
                });
              }}
            />
          );
          break;
        case ViewMode.Edit:
          actions.push(
            <ActionButton
              title={translate('Save')}
              style={{backgroundColor: colours[GetGlobalTheme()].secondary}}
              onPress={() => self.storeWindowRecord()}
            />
          );
          break;
      }
    }

    actions.push(
      <OperationsMenu
        actionList={this.props.headerFields.operationMenu}
        showOperationsMenu={this.state.showOperationsMenu}
        viewMode={this.state.viewMode}
        onPress={() => this.toggleOperationsMenu()}
      />
    );

    if (this.state.viewMode == ViewMode.View) {
      if (objectHasProperty(this.props.headerFields, 'endViewComponent')) {
        actions.push(this.props.headerFields.endViewComponent(this));
      }
    }
    return <ActionWrap>{actions}</ActionWrap>;
  };

  showWindowBreadCrumbs = () => {
    return <></>;
  };

  changeAlertMessage(showAlertMessage: boolean, message: string, alertMessageType: AlertType, duration?: number) {
    this.setState({
      alertMessage: {
        showAlertMessage: showAlertMessage,
        message: message,
        alertMessageType: alertMessageType,
      },
    });
    if (duration) {
      setTimeout(
        () =>
          this.setState({
            alertMessage: {showAlertMessage: false},
          }),
        duration
      );
    }
  }

  closeAlertMessage() {
    const self = this;
    const alertMessageObj = {...self.state.alertMessage};
    alertMessageObj.showAlertMessage = false;
    self.setState({alertMessage: alertMessageObj});
  }

  handleCancelEdit() {
    if (this.props.window.isNewRecord()) {
      this.cancelEditNewRecord();
      return;
    }
    checkUserLeavingEditMode(() => this.cancelEditRecord());
  }

  cancelEditNewRecord() {
    DoNavigate(this.props.window, this.props.window.ScreenName, false, '', true, true);
  }

  cancelEditRecord() {
    this.props.record.resetRecord();
    this.setState({viewMode: ViewMode.View});
  }

  toggleOperationsMenu(forceState?: boolean) {
    if (forceState !== undefined) {
      this.setState({showOperationsMenu: forceState});
      return;
    }
    this.setState({showOperationsMenu: !this.state.showOperationsMenu});
  }

  setMobileBottomButton() {
    const self = this;
    const MobileActionOutsideClickContainerStyle = {
      backgroundColor: colours[GetGlobalTheme()].white,
      width: ScreenWidth(),
      padding: spacing.space12,
      gap: spacing.space12,
    };
    if (self.state.viewMode === ViewMode.Edit) {
      global.setMobileBottomButton(true, [
        <RowContainer style={{gap: spacing.space12, width: '100%', padding: spacing.space12}}>
          <ActionButton
            title={translate('Cancel')}
            style={MobileCancelButtonStyle}
            onPress={() => {
              this.handleCancelEdit();
            }}
            textStyle={{color: colours[GetGlobalTheme()].primary}}
          />
          <ActionButton
            title={translate('Save')}
            style={MobileSaveButtonStyle}
            onPress={() => self.storeWindowRecord()}
          />
        </RowContainer>,
      ]);
      return;
    }

    if (this.state.showOperationsMenu) {
      global.setMobileBottomButton(true, [
        <Modal transparent={true}>
          <ColumnContainer style={MobileActionContainerStyle}>
            <OutsideClickWrapper
              style={MobileActionOutsideClickContainerStyle}
              onOutsideClick={() => this.toggleOperationsMenu(false)}>
              {this.props.headerFields.editable && (
                <ActionButton
                  title={translate('Edit')}
                  style={{backgroundColor: colours[GetGlobalTheme()].secondary}}
                  onPress={() => {
                    checkUserLeavingEditMode(() => {
                      this.getActiveEditFields(() => {
                        this.props.window.record.addMatrixRow();

                        self.toggleOperationsMenu(false);
                        self.setState({
                          viewMode: ViewMode.Edit,
                        });
                        global.activeInspectWindow = self;
                      });
                    });
                  }}
                />
              )}

              {objectHasProperty(this.props.headerFields, 'startViewComponent') &&
                this.props.headerFields.startViewComponent(this)}

              {this.props.headerFields.operationMenu?.map((opMenu) => (
                <ActionButton
                  title={opMenu.label}
                  onPress={() => {
                    self.toggleOperationsMenu(false);
                    opMenu.onPress({inspectWindow: self});
                  }}
                  hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
                  style={{
                    backgroundColor: colours[GetGlobalTheme()].mainBG,
                    borderWidth: '1px',
                    borderColor: colours[GetGlobalTheme()].borderDefault,
                    transition: 'all 2s',
                  }}
                  textStyle={{
                    color: colours[GetGlobalTheme()].primary,
                  }}
                />
              ))}
              <RowContainer style={{justifyContent: 'center', gap: spacing.space16}}>
                <CopyButton
                  onPress={() => {
                    self.toggleOperationsMenu(false);
                    self.props.window.record = self.props.window.createNewRecord();
                    DoNavigate(self.props.window, self.props.window.ScreenName, true, 'new-' + self.props.window.id);
                  }}
                  style={MobileActionButtonStyle}
                  iconStyle={MobileActionButtonIconStyle}
                  hasLabel={true}
                />
                <PrintButton
                  allowPrint={self.props.allowPrint}
                  onPress={() => {
                    self.toggleOperationsMenu(false);
                    self.showPreview();
                  }}
                  style={MobileActionButtonStyle}
                  iconStyle={MobileActionButtonIconStyle}
                  hasLabel={true}
                />
                <DeleteButton
                  window={self.props.window}
                  allowDelete={self.props.allowDelete}
                  style={MobileActionButtonStyle}
                  iconStyle={MobileActionButtonIconStyle}
                  hasLabel={true}
                  inspectWindow={this}
                  onDelete={() => {
                    self.toggleOperationsMenu(false);
                  }}
                />
              </RowContainer>
            </OutsideClickWrapper>

            <View
              style={{
                backgroundColor: colours[GetGlobalTheme()].white,
                width: '100%',
                margin: 0,
                alignItems: 'center',
                padding: spacing.space12,
              }}>
              <ActionButton
                title={translate('Actions')}
                onPress={() => self.toggleOperationsMenu()}
                iconName={'DropdownIcon'}
                iconSize={iconSize.iconSize}
                hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
                iconStyle={{
                  marginLeft: spacing.space8,
                  transform: self.state.showOperationsMenu ? 'rotate(180deg)' : 'rotate(0)',
                }}
                iconColor={colours[GetGlobalTheme()].white}
                style={MobileActionsButtonStyle}
                textStyle={{
                  fontSize: 14,
                }}
              />
            </View>
          </ColumnContainer>
        </Modal>,
      ]);
    } else {
      global.setMobileBottomButton(true, [
        <View
          style={{
            backgroundColor: colours[GetGlobalTheme()].white,
            width: '100%',
            margin: 0,
            padding: 0,
            alignItems: 'center',
          }}>
          <ActionButton
            title={translate('Actions')}
            onPress={() => self.toggleOperationsMenu()}
            iconName={'DropdownIcon'}
            iconSize={iconSize.iconSize}
            hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
            iconStyle={{
              marginLeft: spacing.space8,
              transform: self.state.showOperationsMenu ? 'rotate(180deg)' : 'rotate(0)',
            }}
            iconColor={colours[GetGlobalTheme()].white}
            style={MobileActionsButtonStyle}
            textStyle={{
              fontSize: 14,
            }}
          />
        </View>,
      ]);
    }
  }

  render() {
    const self = this;
    let w = this.props.width;
    let style: React.CSSProperties = {};

    if (isMobileOrTabletScreen()) {
      style.paddingBottom = '58px';
    }

    if (this.props.minwidth) {
      w = '100%';
      style.minWidth = this.props.minwidth;
      style.maxWidth = this.props.maxwidth;
    } else {
      if (w) {
        if (w.indexOf('px') > 0) {
          w = parseInt(w);
          this.state.windoww = w;
        }
        if (IsHRWeb() === false) {
          w = '100%';
        }
      }
    }

    if (Object.entries(this.props.record).length > 1) {
      if (isMobileOrTabletScreen()) {
        global.setMobileTopBar(
          <ActionButton
            onPress={() => goBack(this.props.window, this.props.window.ScreenName)}
            iconName={'ArrowIcon'}
            iconColor={colours[GetGlobalTheme()].primary}
            iconStyle={{transform: 'rotate(-90deg)'}}
            style={MobileBackButtonStyle}
          />
        );
        this.setMobileBottomButton();
      }
    }
    return (
      <InspectWindowWrapper key={w} screenWidth={ScreenWidth()} isMobileOrTabletScreen={isMobileOrTabletScreen()}>
        {this.showWindowBreadCrumbs()}

        <InspectWindowContent
          style={style}
          screenWidth={ScreenWidth()}
          isMobileOrTabletScreen={isMobileOrTabletScreen()}>
          {!isMobileOrTabletScreen() && (
            <InspectTopBar
              window={this.props.window}
              viewMode={this.state.viewMode}
              inspectWindow={this}
              hasBackButton={this.props.hasBackButton}
              allowDelete={this.props.allowDelete}
              allowPrint={this.props.allowPrint}
            />
          )}
          {this.state.alertMessage.showAlertMessage && (
            <AlertWindow
              message={this.state.alertMessage.message}
              width={style.maxWidth}
              alertType={this.state.alertMessage.alertMessageType}
            />
          )}
          <InspectWindowContentWrapper isMobileOrTabletScreen={isMobileOrTabletScreen()}>
            {isMobileOrTabletScreen() && this.props.showStatusOnMobile !== false && (
              <InspectStatus viewMode={this.state.viewMode} headerFieldsEditable={this.props.headerFields.editable} />
            )}
            {this.props.headerFields.sectionList && (
              <InspectWindowTabSection
                headerFields={this.props.headerFields}
                activeTile={this.state.activeTile}
                isNewRecord={this.props.window.isNewRecord()}
                viewMode={this.state.viewMode}
                window={this}
                onTabClick={(tileIndex) => this.setState({activeTile: tileIndex})}
              />
            )}
            {this.props.headerFields && (
              <>
                {this.props.headerFields.sectionTiles.map((section, sectionIndex) => {
                  return (
                    <WindowTile
                      style={{zIndex: 1}}
                      sections={section.fieldSections}
                      visible={this.state.activeTile === sectionIndex}
                      viewMode={this.state.viewMode}
                      record={this.props.record}
                      window={this.props.window}
                      onClick={this.props.onClick}
                      okcheck={this.props.okcheck}
                      inspectWindow={this}
                    />
                  );
                })}
              </>
            )}
            {isMobileOrTabletScreen() && this.props.hideActionPane === false && (
              <AttachmentWindow
                key={this.state.attachments.showAttachments + ':' + this.state.id}
                visible={true}
                viewMode={this.state.viewMode}
                table={this.state.table}
                window={this.props.window}
                id={this.state.id}
                action={() => self.openAttachments()}
              />
            )}

            {objectHasProperty(this.props.headerFields, 'sectionList') == false && isDesktopScreen() && (
              <>
                {this.props.hideActionPane ? (
                  <InspectActionPaneEmpty>
                    <EmptyPane></EmptyPane>
                  </InspectActionPaneEmpty>
                ) : (
                  <InspectActionPane>
                    {this.showInspectActions()}
                    <InspectStatus
                      viewMode={this.state.viewMode}
                      headerFieldsEditable={this.props.headerFields.editable}
                    />
                    {this.props.sideSectionFields && (
                      <SideSectionFields>
                        <WindowTile
                          style={{zIndex: 1}}
                          sections={this.props.sideSectionFields}
                          viewMode={this.state.viewMode}
                          visible={true}
                          record={this.props.record}
                          window={this.props.window}
                          onClick={this.props.onClick}
                          okcheck={this.props.okcheck}
                          inspectWindow={this}
                        />
                      </SideSectionFields>
                    )}
                    <AttachmentWindow
                      key={this.state.attachments.showAttachments + ':' + this.state.id}
                      visible={true}
                      viewMode={this.state.viewMode}
                      table={this.state.table}
                      window={this.props.window}
                      id={this.state.id}
                      action={() => self.openAttachments()}
                    />
                  </InspectActionPane>
                )}
              </>
            )}
          </InspectWindowContentWrapper>
        </InspectWindowContent>
        {this.state.showPreview && (
          <FilePreview
            fileName={this.state.previewFile.name}
            title={this.state.previewFile.name}
            base64File={this.state.previewFile.file}
            onPressPreviewClose={self.hidePreview}
            allowDownload={true}
          />
        )}
        <BackToTopButton />
      </InspectWindowWrapper>
    );
  }
}
