import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';

import {colours, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRiOS, IsHRWeb} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {translate} from '@utils/languageTools';
import Requests from '../../api/Requests';
import {DefaultIconButton} from '../button/DefaultIconButton';
import SigningWindow from './SigningWindow';
import SafeView from './SafeView';
import {logError} from '@utils/debug';
import {isMobileOrTabletScreen} from '@src/tools/displayTools';

const ApprovalListWrap = styled.View`
  margin: 0 auto;
  padding-vertical: ${spacing.small200}px;
  padding-horizontal: ${spacing.small400}px;
  min-height: 400;
  flex-grow: 1;
  width: 100%;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  ${IsHRWeb() === false &&
  css`
    margin-top: 40px;
    height: 100%;
    margin: 0;
  `}
`;
const ApprovalListHeader = styled.View`
  ${IsHRiOS() &&
  css`
    padding-top: 20px;
  `}
`;
const ApprovalList = styled.View``;

const ApprovalPerson = styled.View`
  flex-direction: row;
  align-items: center;
  ${IsHRWeb() === false &&
  css`
    padding-horizontal: ${spacing.small300}px;
    padding-vertical: ${spacing.small300}px;
  `}
`;
const ApprovalPersonName = styled.TouchableOpacity`
  ${IsHRWeb() &&
  css`
    margin-right: ${spacing.medium300};
  `}
  font-size: 18;
  flex-grow: 1;
`;

const ApprovalPersonNameText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;

const ApprovalListBackground = styled.View`
  position: absolute;
  height: 100%;
  right: 0px;
  width: 350px;
  ${isMobileOrTabletScreen() &&
  css`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  `}
`;

export class ApprovalListWindow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.fileUploadRef = React.createRef();
    this.state = {
      ApprovalList: [],
      visible: props.visible,
      loading: false,
      signlink: '',
      loaded: false,
    };

    if (this.state.loaded === false) {
      this.LoadApprovalLists();
    }
  }

  LoadApprovalLists = () => {
    let self = this;
    if (this.props.id !== '' && this.props.id !== undefined) {
      Requests.doAction('getapprovalpersons', {
        id: this.props.id,
        regname: this.props.table,
      })
        .then((response) => {
          self.setState({
            loaded: true,
            ApprovalList: response.records,
          });
          global.setApprovalListBadge(response.records.length);
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  componentDidUpdate() {}

  find_dimesions(layout) {
    // TODO: Create x y width height Type
    const {width} = layout;
    this.state.width = width;
  }

  SortApprovalList = (list) => {
    let nlist = [].concat(list);
    nlist.sort(function (a, b) {
      if (a.Status === '4') {
        return -1;
      }
      if (a.Status < b.Status) {
        return 1;
      }
      if (a.Status > b.Status) {
        return -1;
      }
      return 0;
      //return a.Status=='4' || a.Status > b.Status;
    });
    return nlist.reverse();
  };
  approveActivity = (id) => {
    let self = this;
    Requests.doAction('approveactivity', {id: id})
      .then(() => {
        self.LoadApprovalLists();
      })
      .catch((error) => {
        logError(error);
      });
  };
  signActivity = (id) => {
    let self = this;
    Requests.doAction('signactivity', {id: id})
      .then((response) => {
        self.setState({signlink: response.newid});
      })
      .catch((error) => {
        logError(error);
      });
  };

  rejectActivity = (id) => {
    let self = this;
    Requests.doAction('rejectactivity', {id: id})
      .then(() => {
        self.LoadApprovalLists();
      })
      .catch((error) => {
        logError(error);
      });
  };

  finishSigning = () => {
    let self = this;
    this.setState({signlink: ''}, () => {
      self.LoadApprovalLists();
    });
  };

  render() {
    let self = this;
    let buttonStyle = {};
    if (IsHRWeb()) {
      buttonStyle = {margin: '0 !important'};
    }
    let list = this.SortApprovalList(this.state.ApprovalList);
    if (this.state.visible) {
      return (
        <>
          <ApprovalListBackground>
            <SafeView>
              <ApprovalListWrap>
                <>
                  <ApprovalListHeader>
                    <ActionButton
                      onPress={() => {
                        if (IsHRWeb()) {
                          self.props.action();
                        } else {
                          global.setApprovalInfo({showApprovalLists: false});
                        }
                      }}
                      style={buttonStyle}
                      title={translate('Close')}
                    />
                  </ApprovalListHeader>
                  <ApprovalList
                    onLayout={(event) => {
                      this.find_dimesions(event.nativeEvent.layout);
                    }}>
                    {list.map((el) => {
                      let icon = 'check';
                      switch (el.Status) {
                        case '3':
                          icon = 'minus';
                          break;
                        case '5':
                          icon = 'times';
                          break;
                      }
                      let canapprove = el.canapprove === '1';
                      let cansign = el.cansign === '1';
                      let curuserf = el.UserCode === global.userData.usercode;
                      return (
                        <>
                          <ApprovalPerson>
                            <ApprovalPersonName>
                              <ApprovalPersonNameText>{el.Name}</ApprovalPersonNameText>
                            </ApprovalPersonName>
                            {curuserf && el.Status === '3' && (
                              //approve/sign button
                              //reject button
                              <>
                                {canapprove && (
                                  <ActionButton
                                    onPress={() => self.approveActivity(el.SerNr)}
                                    title={translate('Approve')}
                                  />
                                )}
                                {cansign && (
                                  <ActionButton onPress={() => self.signActivity(el.SerNr)} title={translate('Sign')} />
                                )}
                                <ActionButton
                                  onPress={() => self.rejectActivity(el.SerNr)}
                                  title={translate('Reject')}
                                />
                              </>
                            )}
                            {(curuserf === false || el.Status !== '3') && (
                              <DefaultIconButton
                                iconName={icon}
                                mode="text"
                                action={() => {}}
                                iconType="FA5"
                                style={{marginRight: spacing.medium200}}
                              />
                            )}
                          </ApprovalPerson>
                        </>
                      );
                    })}
                  </ApprovalList>
                </>
              </ApprovalListWrap>
            </SafeView>
          </ApprovalListBackground>
          {this.state.signlink !== '' && (
            <SigningWindow link={this.state.signlink} finish={() => self.finishSigning()}></SigningWindow>
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
}
