import axios from 'axios';
import {IsHRAndroid} from '../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import {ToastAndroid} from 'react-native';
import {parseString} from 'react-native-xml2js';
import {Buffer} from 'buffer';
import {objectHasProperty} from '../Tools/ObjectTools';
import {AlertType} from '@components/design/AlertWindow';

// import Config from 'react-native-config';

/**
 * Request Wrapper with default success/error actions
 */

function parseXml(xml, options = {}) {
  return new Promise((resolve, reject) => {
    parseString(xml, options, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

/*
  vFn["EmplVc|Code"] = "EmpCode";
  vFn["EmplVc|AccGroupCode"] = "DepCode";
  vFn["EmplVc|PersonIDNr"] = "IDCode";
  vFn["ContractVc|SerNr"] = "ContractNr";
  vFn["ContractVc|EmplCode"] = "EmpCode";
  vFn["ContractVc|AccGroupCode"] = "DepCode";
  vFn["AbsenceVc|ContrSerNr"] = "ContractNr";
  vFn["AbsenceVc|ContrSerNr"] = "ContractNr";
  vFn["PlanAbsenceVc|AccGroupCode"] = "DepCode";
*/

function ConvertKey(key, regname) {
  let res = key;
  switch (regname) {
    case 'EmplVc':
    case 'EmpVc':
    case '0':
      switch (key) {
        case 'Code':
          res = 'EmpCode';
          break;
        case 'code':
          res = 'empcode';
          break;
        case 'AccGroupCode':
          res = 'DepCode';
          break;
        case 'accgroupcode':
          res = 'depcode';
          break;
        case 'personidnr':
          res = 'idcode';
          break;
        case 'PersonIDNr':
          res = 'IDCode';
          break;
      }
      break;
    case 'ContractVc':
    case 'EPContractVc':
    case '1':
      switch (key) {
        case 'SerNr':
          res = 'ContractNr';
          break;
        case 'sernr':
          res = 'contractnr';
          break;
        case 'EmplCode':
          res = 'EmpCode';
          break;
        case 'emplcode':
          res = 'empcode';
          break;
        case 'AccGroupCode':
          res = 'DepCode';
          break;
        case 'accgroupcode':
          res = 'depcode';
          break;
      }
      break;
    case 'AbsenceVc':
    case 'EPOffWorkVc':
    case '3':
      switch (key) {
        case 'ContrSerNr':
          res = 'ContractNr';
          break;
        case 'contrsernr':
          res = 'contractnr';
          break;
      }
      break;
    case 'PlanAbsenceVc':
    case 'EPOffWorkPlanVc':
    case '18':
      switch (key) {
        case 'AccGroupCode':
          res = 'DepCode';
          break;
        case 'accgroupcode':
          res = 'depcode';
          break;
      }
      break;
  }
  return res;
}

function ConvertEstFields(res, regname) {
  if (Array.isArray(res)) {
    for (let i = 0; i < res.length; i++) {
      ConvertEstFields(res[i], regname);
    }
  } else {
    if (typeof res === 'object') {
      Object.entries(res).map(([key]) => {
        let nkey = ConvertKey(key, regname);
        ConvertEstFields(res[key], regname);
        res[nkey] = res[key];
      });
    }
  }
}

function convertJSONReply(json, fieldname, regname) {
  let res = json.reply;
  let foundf = false;
  if (typeof res !== 'object') {
    res = {data: {}};
  }

  if (objectHasProperty(res.data, '$')) {
    if (objectHasProperty(res.data.$, 'register')) {
      if (objectHasProperty(res.data, res.data.$.register)) {
        res = res.data[res.data.$.register];
        foundf = true;
      }
    }
  }
  if (!foundf) {
    Object.entries(res.data).map(([key, value]) => {
      if (key !== '$') {
        res = value;
        foundf = true;
      }
    });
  }
  if (fieldname === 'records' && typeof res === 'object' && Array.isArray(res) === false) {
    if (foundf) {
      res = [res];
    } else {
      res = [];
    }
  }

  if (Array.isArray(res)) {
    res.map((el) => {
      if (objectHasProperty(el, 'rows')) {
        if (Array.isArray(el.rows.row)) {
          el.rows = el.rows.row;
        } else {
          if (objectHasProperty(el.rows, 'row')) {
            el.rows = [el.rows.row];
          } else {
            el.rows = [];
          }
        }
      }
    });
  } else {
    if (objectHasProperty(res, 'rows')) {
      if (objectHasProperty(res.rows, 'row')) {
        if (Array.isArray(res.rows.row)) {
          res.rows = res.rows.row;
        } else {
          res.rows = [res.rows.row];
        }
      } else {
        res.rows = [];
      }
    }
  }
  if (global.userData.provider === 1) {
    ConvertEstFields(res, regname);
  }

  return res;
}

function GetRegName(url) {
  let pars = new URLSearchParams(url);
  let type = pars.get('type');
  type = type.split('').reverse().join('');
  let res = Buffer.from(type, 'base64').toString('utf8');
  if (res === '' || res === undefined) {
    //search in xml
  }

  return res;
}

export function DebugObject(obj) {
  if (typeof obj === 'object') {
    console.log(JSON.parse(JSON.stringify(obj)));
  } else {
    console.log(obj);
  }
}

function FixupXMLData(data) {
  let res = data.replace(/<\?xml.*\?>/g, '');
  //res = res.replace(/(<error[^>]*>)([\\s\\S]*?)(<\/error>)/g,'');
  let arr = (res || '').match(/(<message[^>]*>)([\\s\\S]*?)(<\/message>)/g) || [];
  for (let i = 0; i < arr.length - 1; i++) {
    res = res.replace(arr[i], '');
  }
  res = '<reply>' + res + '</reply>';
  return res;
}

function convertValues(value) {
  let res = value;
  res = res.replace(/&quot;/g, '"');
  res = res.replace(/&lt;/g, '<');
  res = res.replace(/&gt;/g, '>');
  res = res.replace(/&amp;/g, '&');
  res = res.replace(/&#9;/g, String.fromCharCode(9));
  return res;
}

async function APIControl(options) {
  const client = getAxiosClient(options);
  //hrportal-text.excellent.lv:8082
  //hrportal.excellent.lv

  const OnSuccess = async function (response) {
    if (typeof response.data !== 'object') {
      return response.data;
    }
    let message = getMessage(response.data);
    response.data.statusMessage = '';
    response.data.statusMessageType = AlertType.Warning;
    if (objectHasProperty(response.data, 'messages')) {
      response.data.statusMessage = getNewStatusMessage(response.data.statusMessage, message, options);
    }
    if (objectHasProperty(response.data, 'result')) {
      handleResultResponse(response.data, options);
    }
    if (objectHasProperty(response.data, 'type')) {
      if (response.data.type === 'xml') {
        //var parseStringPromise = require('react-native-xml2js').parseStringPromise;
        let tmp = Buffer.from(response.data.xml, 'base64').toString('utf8');
        tmp = FixupXMLData(tmp);
        let tstr: any = await parseXml(tmp, {explicitArray: false, valueProcessors: [convertValues]});
        const errmsg = getXMLErrorMessage(tstr);
        if (errmsg !== '' && errmsg !== undefined) {
          if (options.displayMessage) {
            setGlobalMessageBox(errmsg);
          }

          response.data.statusMessageType = AlertType.Error;
          response.data.statusMessage = errmsg;

          response.data.success = false;
        } else {
          const warningMsg = getXMLWarningMessage(tstr);
          if (warningMsg !== '') {
            if (options.displayMessage) {
              setGlobalMessageBox(warningMsg);
            }
            response.data.statusMessageType = AlertType.Warning;
            response.data.statusMessage = warningMsg;
          }
          let registerName = GetRegName(response.config.url);
          response.data[response.data.fieldname] = convertJSONReply(tstr, response.data.fieldname, registerName);
          response.data.originalData = tstr.reply;
        }
      }
    }
    response.data.statusMessage = getNewStatusMessage(response.data.statusMessage, message, options);

    return response.data;
  };

  const OnError = function (error) {
    if (IsHRAndroid()) {
      ToastAndroid.show(translate('ConnectionFailed'), ToastAndroid.SHORT);
    }
    if (error.response) {
      if (error.response.data.substring(0, 27) === '<HTML><HEAD><TITLE>File not') {
        global.updateLoginStatus(2);
        global.refreshScreen();
      }
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(OnSuccess).catch(OnError);
}

function getAxiosClient(options) {
  let toptions = {
    headers: {'Content-Type': 'application/json', Accept: 'application/json'},
    baseURL: '',
  };
  if (objectHasProperty(options, 'headers')) {
    toptions.headers = options.headers;
  }
  if (options.url === '/dologin') {
    toptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      baseURL: '',
    };
  }
  toptions.baseURL = global.baseURL;
  return axios.create(toptions);
}

function getXMLErrorMessage(tstr) {
  let errorMessage = '';
  if (objectHasProperty(tstr.reply.data, 'error')) {
    errorMessage = tstr.reply.data.error.$.description;
    if (objectHasProperty(tstr.reply.data, 'message')) {
      errorMessage = tstr.reply.data.message.$.description;
    }
  } else {
    if (objectHasProperty(tstr.reply, 'error')) {
      errorMessage = tstr.reply.error.$.description;
      if (objectHasProperty(tstr.reply, 'message')) {
        errorMessage = tstr.reply.message.$.description;
      }
    }
  }
  return errorMessage;
}

function getXMLWarningMessage(tstr) {
  let warningMsg = '';
  if (objectHasProperty(tstr.reply.data, 'message')) {
    warningMsg = tstr.reply.data.message.$.description;
  }
  if (objectHasProperty(tstr.reply, 'message')) {
    warningMsg = tstr.reply.message.$.description;
  }
  return warningMsg;
}

function setGlobalMessageBox(message) {
  global.messageBox({showMessage: true, statusMessage: message});
}

function getMessage(data) {
  let message = '';
  if (objectHasProperty(data, 'messages')) {
    data.messages.map((responseMessage) => {
      if (!message.includes(responseMessage.description)) {
        message += responseMessage.description + '\n';
      }
    });
  }
  if (objectHasProperty(data, 'errors')) {
    data.errors.map((responseMessage) => {
      if (!message.includes(responseMessage.description)) {
        message += responseMessage.description + '\n';
      }
    });
  }

  return message;
}

function getNewStatusMessage(originalMessage, newMessage, options) {
  if (newMessage !== '') {
    if (options.displayMessage) {
      global.messageBox({showMessage: true, statusMessage: newMessage});
    }
    return newMessage;
  }
  return originalMessage;
}

function isSessionActive(options) {
  const isLogoutAction = options.url.includes('=logout');
  const isGetUserDataAction = options.url.includes('=getuserdata');
  const isLanguageAction = options.url.includes('langcode=');
  const isResetLoginAction = options.url.includes('=resetlogin'); // Resetting cookies in some strange cases

  return isLogoutAction || isGetUserDataAction || isLanguageAction || isResetLoginAction;
}

function handleResultResponse(data, options) {
  if (data.result.loginstatus !== 2) {
    return;
  }

  global.sessionEnded = !isSessionActive(options);
  global.updateLoginStatus(2);
  global.refreshScreen();
}

export default APIControl;
