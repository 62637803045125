import {ViewStyle} from 'react-native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';

export function getPasteSpecialScrollContainerStyle(isStaticPasteSpecial: boolean) {
  const StyleObj: ViewStyle = {
    height: isStaticPasteSpecial ? '100%' : 200,
    maxHeight: 200,
  };
  return StyleObj;
}

export function getPasteSpecialContainerStyle(isDropDownPasteSpecial: boolean) {
  const StyleObj: ViewStyle = {
    backgroundColor: colours[GetGlobalTheme()].white,
    borderRadius: 12,
    position: isDropDownPasteSpecial ? 'relative' : 'absolute',
    // @ts-ignore
    filter: 'drop-shadow(0 0 6px rgba(107, 107, 107, 0.15))',
    marginTop: spacing.space12,
    top: -8,
    width: '100%',
    overflow: 'hidden',
  };
  return StyleObj;
}

export const PasteSpecialContentStyle: ViewStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
};

export const PasteSpecialSelectButtonStyle = {
  margin: spacing.space8,
  background: 'none',
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 12,
};

export const PasteSpecialSelectButtonTextStyle = {
  color: colours[GetGlobalTheme()].darkBlue,
};
