import React from 'react';
import styled from 'styled-components/native';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import {ActionButton} from '@components/button/ActionButton';

const ItemValue = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colours[GetGlobalTheme()].infoLight};
  position: relative;
  border-radius: 50px;
  border: 1px solid #eee;
  margin: ${spacing.space4}px;
  padding: ${spacing.space8}px;
`;

const TextValue = styled.Text`
  position: relative;
  color: ${colours[GetGlobalTheme()].black};
`;

const RemoveButtonStyle = {
  backgroundColor: 'invisible',
  width: 14,
  height: 14,
  padding: 0,
  margin: spacing.space4,
  marginBottom: 0,
  marginTop: 0,
};

const RemoveButtonIconStyle = {
  order: 2,
};

type PasteSpecialSelectedItemProps = {
  pasteSpecialValue: any;
  onRemoveValue: () => void;
};

export const PasteSpecialSelectedItem: React.FC<PasteSpecialSelectedItemProps> = ({
  pasteSpecialValue,
  onRemoveValue,
}) => {
  return (
    <ItemValue>
      <TextValue>{pasteSpecialValue}</TextValue>
      <ActionButton
        onPress={onRemoveValue}
        title={''}
        iconName={'CrossIcon'}
        style={RemoveButtonStyle}
        iconStyle={RemoveButtonIconStyle}
        iconColor={colours[GetGlobalTheme()].primary}
        iconSize={8}
      />
    </ItemValue>
  );
};
