import React from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  IsManager,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {YesNoWindow} from '../status/YesNoWindow';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {InputChangeEventProps, ListWindowColumn, WindowDefinition, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const Drawer = createDrawerNavigator();

function headerFields(table) {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Contract'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ContractNr',
          viewOnly: false,
          width: '400px',
          pasteSpecial: {
            vcName: 'ActTypeVc',
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'contractnr',
                sortKey: 'ContractNr',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'empname',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Approved'),
          windowElementType: WindowElementType.kInputTypeCheckbox,
          checkboxInactiveLabel: translate('Approve'),
          dataKey: 'Status',
          viewOnly: false,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('StartDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'StartDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('EndDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'EndDate',
          viewOnly: false,
          width: '150px',
          linkedField: 'StartDate',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          viewOnly: false,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'contractnr',
                sortKey: 'ContractNr',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'empname',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Comment'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Comment',
          viewOnly: false,
          width: '500px',
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
  return windowDefinition;
}

function browseColumns() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('SerNr'),
      key: 'sernr',
      sortKey: 'SerNr',
      width: '100px',
    },
    {
      name: translate('ContractNr'),
      key: 'contractnr',
      sortKey: 'ContractNr',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'empname',
      sortKey: 'EmpName',
      width: '200px',
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('Department'),
      key: 'depcode',
      sortKey: 'DepCode',
      width: '100px',
    },
    {
      name: translate('ApprovedShort'),
      key: 'status',
      sortKey: 'Status',
      width: '70px',
      overwrite: DisplayBrowseOKFlag,
    },
  ];
  return browseColumns;
}

interface ContractChangesListState extends ListScreenState {
  newContractChangeHelper: boolean;
  NewEmp: string;
  NewGroup: string;
  px: number;
  py: number;
  NewStartDate: string;
  NewEndDate: string;
  NewEmpVacDays: [];
}

export class ContractChangesList extends ListScreen<{}, ContractChangesListState> {
  constructor(props) {
    super(props);
    this.createNewContractChange = this.createNewContractChange.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    //this.changePage = this.changePage.bind(this);
    this.table = 38;
    this.ScreenName = 'ContractChanges';
    this.init = true;
    this.state = {
      ...this.state,
      newContractChangeHelper: false,
      data: [],
      NewEmp: '',
      NewGroup: '',
      px: 0,
      py: 0,
      NewStartDate: '',
      NewEndDate: '',
      NewEmpVacDays: [],
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('ContractChanges', 'SerNr', 'descending');
    AddDateFilter('ContractChanges');
  }

  CheckNewChangeData = () => {
    let res = true;
    if (this.state.NewEmp === '') {
      res = false;
    }
    if (this.state.NewStartDate === '') {
      res = false;
    }
    return res;
  };

  createNewContractChange = () => {
    let self = this;
    let tdata;
    if (this.CheckNewChangeData() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    if (global.userData.provider === 0) {
      tdata = {
        record: {
          ContractNr: this.state.NewEmp,
          StartDate: this.state.NewStartDate,
          EndDate: this.state.NewEndDate,
        },
      };
    }
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (objectHasProperty(response, 'record')) {
          self.state.NewEmp = '';
          self.state.NewStartDate = '';
          self.state.NewEndDate = '';
          self.ShowHideNewContractChange();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewContractChange = () => {
    if (this.state.newContractChangeHelper) {
      global.PopEscapeActions();
      this.setState({newContractChangeHelper: false});
    } else {
      this.setState({newContractChangeHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewContractChange());
    }
  };

  renderNewButton = () => {
    let self = this;
    let style = {};
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'contractchange_new'}
        style={{flexDirection: 'row'}}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewContractChange()} title={translate('AddNew')} />
        {this.state.newContractChangeHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('StartDate') + '*'}
                    name={'StartDate'}
                    value={this.state.NewStartDate}
                    editable={true}
                    width={'150px'}
                    type={WindowElementType.kInputTypeDate}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewStartDate: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('EndDate') + '*'}
                    name={'EndDate'}
                    value={this.state.NewEndDate}
                    editable={true}
                    width={'150px'}
                    record={{StartDate: this.state.NewStartDate}}
                    linkedfield={'StartDate'}
                    type={WindowElementType.kInputTypeDate}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewEndDate: value})}
                  />
                </FilterField>
                <FilterField style={{width: '100%'}}>
                  <EditField
                    title={translate('Contract') + '*'}
                    name={'EmpCode'}
                    value={this.state.NewEmp}
                    editable={IsManager()}
                    pasteSpecial={{
                      vcName: 1,
                      columns: [
                        {
                          name: translate('ContractNr'),
                          width: '35%',
                          key: 'contractnr',
                          mainkey: 'ContractNr',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'empname',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewEmp: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton
                  style={style}
                  onPress={() => this.ShowHideNewContractChange()}
                  title={translate('Cancel')}
                />
                <ActionButton onPress={() => this.createNewContractChange()} title={translate('Create')} />
              </FilterActions>
              {this.state.NewEmpVacDays}
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={browseColumns()}
          doClick={self.rowClick}
          rowID={'sernr'}
          title={translate(this.ScreenName)}
          width="900px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface ContractChangesRecordState extends RecordScreenState {
  data: [];
  message: [];
  NewEmpVacDays: [];
}

export class ContractChangesRecord extends RecordScreen<{}, ContractChangesRecordState> {
  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.table = 38;
    this.ScreenName = 'ContractChanges';
    this.state = {
      ...this.state,
      message: [],
      NewEmpVacDays: [],
    };
    this.navigation = props.navigation;
    this.record = new WindowRecord(this.table, false, headerFields(this));
    this.CheckOpenRecord();
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <InspectWindow
          title={translate('ContractChange')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          onClick={this.handleInputClick}
          allowDelete={true}
          okcheck={'Status'}
          backButton={() => goBack(self, self.ScreenName)}
        />
        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

function ContractChanges() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="ContractChangesList"
        component={ContractChangesList}
        options={{
          title: translate('ContractChanges'),
        }}
      />
      <Drawer.Screen
        name="ContractChangesRecord"
        component={ContractChangesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('ContractChange'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default ContractChanges;
