import {ViewMode} from '@src/components/screens/types';
import {spacing} from '@src/styles/Style';
import React, {Component} from 'react';
import styled from 'styled-components/native';
import {InspectWindowProps, InspectWindowState} from './InspectWindow';
import {AlertType} from '@src/components/design/AlertWindow';

const InspectWindowWrapper = styled.View`
  border-radius: ${spacing.space12};
  flex-direction: column;
`;

const ActionWrap = styled.View`
  align-items: end;
`;

const InspectWindowContentWrapper = styled.View`
  gap: ${spacing.space16}px;
  width: 100%;
`;
export class InlineInspectWindow extends Component<InspectWindowProps, InspectWindowState> {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: this.props.forceEditMode ? ViewMode.Edit : ViewMode.View,
      windoww: 0,
      pwidth: 0,
      activeTile: 0,
      showOperationsMenu: false,
      table: this.props.window.table,
      id: this.props.window.id,
      id2: this.props.window.id,
      attach_cnt: 0,
      approval_cnt: 0,
      alertMessage: {
        showAlertMessage: false,
        message: '',
        alertMessageType: AlertType.Success,
      },
      showPreview: false,
      previewFile: {},
      update: false,
    };
  }

  changeAlertMessage = (showAlertMessage: boolean, message: string, alertMessageType: AlertType) => {
    this.setState({
      alertMessage: {
        showAlertMessage: showAlertMessage,
        message: message,
        alertMessageType: alertMessageType,
      },
    });
  };

  closeAlertMessage = () => {
    this.setState({alertMessage: {showAlertMessage: false}});
  };
}
