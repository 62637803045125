import {RecordScreenState, ViewMode} from '@src/components/screens/types';
import {colours, spacing} from '@src/styles/Style';
import React, {Component} from 'react';
import {translate} from '@src/utils/languageTools';
import AlertWindow, {AlertType} from '@src/components/design/AlertWindow';
import {InspectWindowProps} from '@src/components/editElements/inspectWindow/InspectWindow';
import WindowTile from '@components/editElements/inspectWindow/WindowTile';
import Requests from 'src/api/Requests';
import {ModalWindow} from '@components/editElements/modalWindow/ModalWindow';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {View, ViewStyle} from 'react-native';
import {GetGlobalTheme} from '@tools/displayTools';
import {InlineInspectWindow} from '@src/components/editElements/inspectWindow/InlineInspectWindow';
import {ModalSaveCancelFooter} from '@src/components/editElements/modalWindow/ModalSaveCancelFooter';

const ContentContainerStyle: ViewStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderRadius: spacing.space12,
  gap: spacing.space16,
  padding: spacing.space16,
  width: '100%',
};
const ContentWrapperStyle: ViewStyle = {
  backgroundColor: colours[GetGlobalTheme()].grey200,
  zIndex: 999,
  padding: spacing.space24,
  overflow: 'visible',
  gap: spacing.space24,
};

interface AddNewContactPersonInspectWindowProps extends InspectWindowProps {
  onCloseWindow: () => void;
  onRefreshList: () => void;
  contactWindow: RecordScreen<{}, RecordScreenState>;
}

export class AddNewContactPersonInspectWindow extends InlineInspectWindow {
  constructor(props: AddNewContactPersonInspectWindowProps) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  storeWindowRecord = (storeCallback = null) => {
    this.props.window.sendRecordData((response) => {
      if (response.success) {
        this.handleSuccessfulRecordRequest(response);
        return;
      }
      this.handleFailedRecordRequest(response);
    });
  };

  handleSuccessfulRecordRequest = (response) => {
    if (this.props.record.updateFields.length > 0) {
      const updateFields = [
        {
          rownr: -1,
          field: 'CustCode',
          // @ts-ignore
          value: this.props.contactWindow.record.headerFields.Code,
        },
        {
          rownr: -1,
          field: 'ContactCode',
          value: response.record.Code,
        },
      ];
      let data = {
        list: updateFields,
      };
      if (response.statusMessage) {
        this.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
      } else {
        Requests.updateRecordData2('ContactRelVc', data, false).then(() => {
          // @ts-ignore
          this.props.onRefreshList();
          // @ts-ignore
          this.props.onCloseWindow();
        });
      }
    }
    global.activeEditMode = false;
  };

  handleFailedRecordRequest(response) {
    if (response.statusMessage) {
      this.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
    }
  }

  changeAlertMessage = (showAlertMessage: boolean, message: string, alertMessageType: AlertType) => {
    this.setState({
      alertMessage: {
        showAlertMessage: showAlertMessage,
        message: message,
        alertMessageType: alertMessageType,
      },
    });
  };

  getModalContentComponent = () => {
    return (
      <View style={ContentWrapperStyle}>
        {this.state.alertMessage.showAlertMessage && (
          <AlertWindow
            message={this.state.alertMessage.message}
            width={'100%'}
            alertType={this.state.alertMessage.alertMessageType}
          />
        )}
        <View style={ContentContainerStyle}>
          <WindowTile
            style={{zIndex: 1}}
            viewMode={this.state.viewMode}
            visible={true}
            record={this.props.record}
            window={window}
            sections={this.props.headerFields.sectionTiles[0]?.fieldSections}
            inspectWindow={this}
          />
        </View>
      </View>
    );
  };

  getModalFooterComponent = () => {
    return (
      <ModalSaveCancelFooter
        // @ts-ignore
        onCancelPress={() => this.props.onCloseWindow()}
        cancelButtonTitle={translate('Cancel')}
        onSavePress={() => this.storeWindowRecord()}
        saveButtonTitle={translate('Add')}
      />
    );
  };

  render() {
    return (
      <ModalWindow
        inTransparent={true}
        customContentWrapper={this.getModalContentComponent()}
        headerProps={{
          title: translate('AddNewContactPerson'),
          // @ts-ignore
          onCloseButtonPress: () => this.props.onCloseWindow(),
        }}
        footerComponent={this.getModalFooterComponent()}
      />
    );
  }
}
