import {ViewMode} from '@src/components/screens/types';
import {colours, spacing} from '@src/styles/Style';
import React, {Component} from 'react';
import styled from 'styled-components/native';
import WindowTile from '@components/editElements/inspectWindow/WindowTile';
import {ActionButton} from '@src/components/button/ActionButton';
import {GetGlobalTheme} from '@src/tools/displayTools';
import {translate} from '@src/utils/languageTools';
import {objectHasProperty} from '@src/tools/objectTools';
import AlertWindow, {AlertType} from '@src/components/design/AlertWindow';
import {checkUserLeavingEditMode} from '@src/components/messageBox/Tools';
import {DeleteButton} from '@components/editElements/InspectWindowActionButtons';
import {View} from 'react-native';
import {InlineInspectWindow} from '@src/components/editElements/inspectWindow/InlineInspectWindow';

const InspectWindowWrapper = styled.View`
  border-radius: ${spacing.space12};
  flex-direction: column;
`;

const ActionWrap = styled.View`
  align-items: end;
`;

const InspectWindowContentWrapper = styled.View`
  gap: ${spacing.space16}px;
  width: 100%;
`;

const ActionsButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  padding: spacing.space8,
};

const ActionButtonIconStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  width: 10,
  height: 10,
};

const ActionButtonWrapStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
};

export class ContactPersonsInspectWindow extends InlineInspectWindow {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  makeCurrentWindowActive = () => {
    this.setState({viewMode: ViewMode.Edit});
    global.activeInspectWindow = this;
  };

  handleEditButtonPress = () => {
    checkUserLeavingEditMode(() => {
      let madeWindowActive = false;
      if (global.activeInspectWindow) {
        if (global.activeInspectWindow.state.viewMode === ViewMode.Edit) {
          madeWindowActive = true;
          global.activeInspectWindow.setState({viewMode: ViewMode.View}, () => {
            this.makeCurrentWindowActive();
          });
        }
      }
      if (!madeWindowActive) {
        this.makeCurrentWindowActive();
      }
    });
  };

  showInspectActions = () => {
    const actions = [];
    const self = this;
    switch (this.state.viewMode) {
      case ViewMode.View:
        actions.push(
          <View style={ActionButtonWrapStyle}>
            <DeleteButton
              window={this.props.window}
              allowDelete={true}
              style={{
                ...ActionsButtonStyle,
                height: 30,
              }}
              iconStyle={ActionButtonIconStyle}
              hasLabel={false}
              inspectWindow={this}
              onDeleteRefreshList={this.props.onDeleteRefreshList}
              redirect={false}
            />
            <ActionButton
              title={translate('Edit')}
              style={{background: 'none', padding: 0, height: 'auto', flexDirection: 'row-reverse'}}
              textStyle={{
                color: colours[GetGlobalTheme()].lightBlue,
              }}
              iconName="EditIcon"
              iconStyle={{marginRight: spacing.space4}}
              iconColor={colours[GetGlobalTheme()].lightBlue}
              onPress={() => {
                this.handleEditButtonPress();
              }}
            />
          </View>
        );
        break;
      case ViewMode.Edit:
        actions.push(
          <ActionButton
            title={translate('Save')}
            style={{background: 'none', padding: 0, height: 'auto'}}
            onPress={() => this.storeWindowRecord()}
            textStyle={{
              color: colours[GetGlobalTheme()].lightBlue,
            }}
          />
        );
        break;
    }

    return <ActionWrap>{actions}</ActionWrap>;
  };

  storeWindowRecord = (storeCallback = null) => {
    this.props.window.sendRecordData((response) => {
      if (response.success) {
        //send attachments
        if (response.statusMessage) {
          this.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
        } else {
          this.closeAlertMessage();
        }
        this.props.record.clearUpdateFields();
        if (objectHasProperty(response, 'record')) {
          this.props.record.applyNewValues(response.record);
        }
        this.setState({viewMode: ViewMode.View}, () => {
          global.activeEditMode = false;
          if (storeCallback) storeCallback();
        });
      }
    });
  };

  render() {
    return (
      <InspectWindowWrapper>
        {this.state.alertMessage.showAlertMessage && (
          <AlertWindow
            message={this.state.alertMessage.message}
            width={'100%'}
            alertType={this.state.alertMessage.alertMessageType}
          />
        )}
        {this.showInspectActions()}
        <InspectWindowContentWrapper>
          <WindowTile
            style={{zIndex: 1}}
            viewMode={this.state.viewMode}
            visible={true}
            record={this.props.record}
            window={window}
            sections={this.props.headerFields.sectionTiles[0]?.fieldSections}
            inspectWindow={this}
          />
        </InspectWindowContentWrapper>
      </InspectWindowWrapper>
    );
  }
}
