import React, {createContext, useState, useEffect} from 'react';
import {ThemeProvider} from 'styled-components/native';
//import {Appearance, AppearanceProvider} from 'react-native-appearance';
import {colours} from '../styles/Style';

const defaultMode = 'light'; //Appearance.getColorScheme() || 'light';

const ThemeContext = createContext({
  mode: defaultMode,
  setMode: (mode) => {
    global.theme = mode;
  },
});

export const useTheme = () => React.useContext(ThemeContext);

const ManageThemeProvider = ({children}) => {
  const [themeState, setThemeState] = useState(defaultMode);
  const setMode = (mode) => {
    setThemeState(mode);
    global.theme = mode;
  };
  useEffect(() => {
    //const subscription = Appearance.addChangeListener(({colorScheme}) => {
    //  setThemeState(colorScheme);
    //});
    //return () => subscription.remove();
  }, []);
  return (
    <ThemeContext.Provider value={{mode: themeState, setMode}}>
      <ThemeProvider
        theme={themeState === 'dark' ? colours['dark'] : colours['light']}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
/*
const ThemeManager = ({children}) => (
  <AppearanceProvider>
    <ManageThemeProvider>{children}</ManageThemeProvider>
  </AppearanceProvider>
);
*/
const ThemeManager = ({children}) => (
  <ManageThemeProvider>{children}</ManageThemeProvider>
);

export default ThemeManager;
