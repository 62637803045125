import React from 'react';
import {ActionButton} from '@components/button/ActionButton';
import {translate} from '@utils/languageTools';
import {colours, iconSize, spacing} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import {ViewMode} from '@components/screens/types';
import {View} from 'react-native';

type OperationsMenuProps = {
  actionList: any[];
  showOperationsMenu: boolean;
  viewMode: ViewMode;
  direction?: string;
  onPress: () => void;
};

export const OperationsMenu: React.FC<OperationsMenuProps> = ({
  actionList,
  showOperationsMenu,
  viewMode,
  direction,
  onPress,
}) => {
  let operationsMenu = [];

  if (!actionList) {
    return operationsMenu;
  }

  function getSubActionContainerStyle(direction?: string) {
    const directionStyle = direction === 'top' ? {bottom: '100%'} : {top: '100%'};
    return {
      position: 'absolute',
      gap: spacing.space12,
      width: '100%',
      borderRadius: spacing.space12,
      backgroundColor: colours[GetGlobalTheme()].mainBG,
      padding: spacing.space12,
      shadowColor: colours[GetGlobalTheme()].borderDefault,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowRadius: spacing.space16,
      ...directionStyle,
    };
  }

  operationsMenu.push(
    <ActionButton
      title={translate('MoreActions')}
      onPress={() => onPress()}
      iconName={'DropdownIcon'}
      iconSize={iconSize.iconSize}
      hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
      iconStyle={{
        transform: showOperationsMenu ? 'rotate(180deg)' : 'rotate(0)',
      }}
      iconColor={colours[GetGlobalTheme()].primary}
      style={{
        backgroundColor: colours[GetGlobalTheme()].mainBG,
        borderWidth: '1px',
        borderColor: showOperationsMenu ? colours[GetGlobalTheme()].primary : colours[GetGlobalTheme()].borderDefault,
        transition: 'all 2s',
        display: viewMode === ViewMode.View ? 'flex' : 'none',
      }}
      textStyle={{
        color: colours[GetGlobalTheme()].primary,
      }}
    />
  );

  if (showOperationsMenu) {
    let subActions = [];
    for (let i = 0; i < actionList.length; i++) {
      let opMenu = actionList[i];
      subActions.push(
        <ActionButton
          title={opMenu.label}
          onPress={() => opMenu.onPress({inspectWindow: self})}
          hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
          style={{
            backgroundColor: colours[GetGlobalTheme()].mainBG,
            borderWidth: '1px',
            borderColor: colours[GetGlobalTheme()].borderDefault,
            transition: 'all 2s',
          }}
          textStyle={{
            color: colours[GetGlobalTheme()].primary,
          }}
        />
      );
    }
    operationsMenu.push(<View style={getSubActionContainerStyle(direction)}>{subActions}</View>);
  }

  return operationsMenu;
};
