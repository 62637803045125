import React from 'react';
import {View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

function SafeView(props) {
  const insets = useSafeAreaInsets();
  let offset = {
    marginTop: insets.top,
    marginBottom: insets.bottom,
    marginLeft: insets.left,
    marginRight: insets.right,
  };
  if (props.edges) {
    offset = {};
    if (props.edges.indexOf('top') > -1) {
      offset.marginTop = insets.top;
    }
    if (props.edges.indexOf('bottom') > -1) {
      offset.marginBottom = insets.bottom;
    }
    if (props.edges.indexOf('left') > -1) {
      offset.marginLeft = insets.left;
    }
    if (props.edges.indexOf('right') > -1) {
      offset.marginRight = insets.right;
    }
  }
  let style = {
    // Paddings to handle safe area
    ...offset,
    ...props.style,
  };
  return <View style={style}>{props.children}</View>;
}

export default SafeView;
