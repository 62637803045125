import React from 'react';
import {WindowRecord} from '@src/utils/types';
import {ContactPersonData} from './ContactPersons';
import {RecordScreenState} from '../../types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {AddNewContactPersonRecordScreenDefinitions} from '@components/screens/contacts/contactPersons/addNewContactPersonRecordScreenDefinition';
import {AddNewContactPersonInspectWindow} from '@components/screens/contacts/contactPersons/AddNewContactPersonInspectWindow';

interface ContactsRecordState extends RecordScreenState {}
type AddNewContactPersonRecordProps = {
  record: ContactPersonData;
  contactWindow: RecordScreen<{}, RecordScreenState>;
  onCloseWindow: () => void;
  onRefreshList: () => void;
};

export class AddNewContactPersonRecord extends RecordScreen<AddNewContactPersonRecordProps, ContactsRecordState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new AddNewContactPersonRecordScreenDefinitions(this);
    this.ScreenName = 'ContactPerson';
    this.table = 'CUVc';
    this.IDField = 'Code';
    this.state = {
      ...this.state,
    };
    this.forceRefresh = true;
    this.record = this.createNewRecord();
    this.record.applyNewValues(props.record);
    this.id = this.record.headerFields.Code;
  }

  componentDidUpdate() {}

  componentDidMount() {
    this._isMounted = true;
  }

  sendRecordData = async (mainCallBackFunction: Function) => {
    let callBackFunction = (response, storeClassBack) => {
      mainCallBackFunction(response, storeClassBack);
      this.props.onRefreshList();
    };
    await super.sendRecordData(callBackFunction);
  };

  createNewRecord = () => {
    const record = new WindowRecord(
      this.table,
      false,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true
    );
    record.applyNewValues2({newRecord: {CustType: 1, CUType: 0, VEType: 0}, applyWActions: true});
    return record;
  };
  render() {
    return (
      <AddNewContactPersonInspectWindow
        record={this.record}
        headerFields={this.recordWindowDefinitions.getHeaderFields(this)}
        window={this}
        contactWindow={this.props.contactWindow}
        forceEditMode={true}
        onCloseWindow={() => this.props.onCloseWindow()}
        onRefreshList={() => this.props.onRefreshList()}
      />
    );
  }
}
