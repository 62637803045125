import React from 'react';
import {Header5, HeaderTextContainer} from '@styles/primitiveComponents';
import {ActionButton} from '@components/button/ActionButton';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../Tools/DisplayTools';
import {isMobileOrTabletScreen} from '@tools/displayTools';
import {View} from 'react-native';
import styled from 'styled-components/native';

const ContainerStyle = {
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingHorizontal: `${spacing.space16}px`,
  paddingTop: isMobileOrTabletScreen() ? spacing.space12 : spacing.space24,
  paddingBottom: isMobileOrTabletScreen() ? spacing.space12 : spacing.space24,
};

const HeaderText = styled(Header5)``;

export const CloseButtonStyle = {
  padding: '14px',
  marginLeft: 'auto',
  borderRadius: '8px',
};

export type ModalHeaderProps = {
  title: string;
  onCloseButtonPress: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({title,onCloseButtonPress}) => {
  return (
    <View style={ContainerStyle}>
      <HeaderTextContainer>
        <HeaderText> {title} </HeaderText>
        <ActionButton
          onPress={() => onCloseButtonPress()}
          title={''}
          style={CloseButtonStyle}
          iconName={'CrossIcon'}
          iconColor={colours[GetGlobalTheme()].primary}
          iconSize={12}
          backgroundColor={colours[GetGlobalTheme()].iconButtonBG}
          hoverColor={colours[GetGlobalTheme()].grey100}
        />
      </HeaderTextContainer>
    </View>
  );
};
