import React, {useEffect} from 'react';
import {Modal, View} from 'react-native';
import {ModalHeader, ModalHeaderProps} from '@components/editElements/modalWindow/ModalHeader';
import {ColumnContainer, ModalBackground} from '@styles/primitiveComponents';
import {ModalFooter} from '@components/editElements/modalWindow/ModalFooter';
import styled from 'styled-components/native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme, isMobileOrTabletScreen} from '@tools/displayTools';

const Container = styled(ColumnContainer)`
  background-color: ${colours[GetGlobalTheme()].white};
  border-radius: 12px;
  width: 90%;
  max-width: 820px;
  max-height: 90%;
  margin: auto;
  display: flex;
  flex-shrink: 1;
`;

const ContentWrapperStyle = {
  borderRightWidth: 0,
  borderLeftWidth: 0,
  borderColor: colours[GetGlobalTheme()].grey400,
  backgroundColor: isMobileOrTabletScreen() ? colours[GetGlobalTheme()].contentBG : colours[GetGlobalTheme()].white,
  padding: isMobileOrTabletScreen() ? spacing.space12 : spacing.space24,
  flexGrow: 1,
  borderStyle: 'solid',
  borderWidth: 1,
};

type ModalWindowProps = {
  inTransparent: boolean;
  content?: React.ReactNode;
  contentWrapperStyle?: React.CSSProperties;
  /** This will replace the default content wrapper. "Content" and "contentWrapperStyle" props will be ignored if
   *  you use this. */
  customContentWrapper?: JSX.Element;
  headerProps: ModalHeaderProps;
  footerComponent: React.ReactNode;
  showFooter?: boolean;
};

export const ModalWindow: React.FC<ModalWindowProps> = ({
  inTransparent,
  content,
  contentWrapperStyle,
  customContentWrapper,
  headerProps,
  footerComponent,
  showFooter,
}) => {
  useEffect(() => {
    global.PushEscapeActions(() => headerProps.onCloseButtonPress());
    return () => global.PopEscapeActions();
  }, []);

  function getContentWrapper(): JSX.Element {
    if (customContentWrapper) {
      return customContentWrapper;
    }
    return <View style={contentWrapperStyle ? contentWrapperStyle : ContentWrapperStyle}>{content}</View>;
  }

  return (
    <Modal transparent={inTransparent}>
      <ModalBackground>
        <Container>
          <ModalHeader title={headerProps.title} onCloseButtonPress={headerProps.onCloseButtonPress} />
          {getContentWrapper()}
          {showFooter !== false && <ModalFooter children={footerComponent} />}
        </Container>
      </ModalBackground>
    </Modal>
  );
};
