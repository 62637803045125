import {DisplayBrowseOKFlag, DoNavigate, IsHRWeb, IsManager, WindowElementType} from '../../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import {ListWindowColumn, WindowDefinition} from '@utils/types';
import {WindowMatrix} from '@src/utils/types';
import {isDesktopScreen} from '@src/tools/displayTools';

function VacPlanHeaderFields_LAT(table) {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Employee'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCode',
          viewOnly: !IsManager() || table.ApprovalStatus === '3',
          width: '400px',
          pasteSpecial: {
            vcName: 0,
            multiValue: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'empcode',
                mainKey: 'EmpCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Date'),
          dataKey: 'TransDate',
          viewOnly: table.ApprovalStatus === '3',
          width: '150px',
          windowElementType: WindowElementType.kInputTypeDate,
          onChange: table.updateRecordField,
        },
        {
          label: translate('StartDate'),
          dataKey: 'StartDate',
          viewOnly: table.ApprovalStatus === '3',
          width: '150px',
          windowElementType: WindowElementType.kInputTypeDate,
          onChange: table.updateRecordField,
        },
        {
          label: translate('EndDate'),
          dataKey: 'EndDate',
          viewOnly: table.ApprovalStatus === '3',
          width: '150px',
          linkedField: 'StartDate',
          windowElementType: WindowElementType.kInputTypeDate,
          onChange: table.updateRecordField,
        },
        {
          label: translate('OffWorkGroup'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'OffWorkGr',
          viewOnly: table.ApprovalStatus === '3',
          width: '200px',
          pasteSpecial: {
            vcName: 19,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Description',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          viewOnly: true,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'DepCode',
                mainKey: 'DepCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Status'),
          dataKey: 'Status',
          viewOnly: global.userData.deplist === '' && table.hasapprovals !== 0 ? true : false,
          windowElementType: WindowElementType.kInputTypeText,
          pasteSpecial: {
            vcName: 9990,
            fields: ['code', 'comment'],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Comment'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Comment',
          viewOnly: table.ApprovalStatus === '3',
          width: '500px',
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
  if (isDesktopScreen() && global.userData.provider === 0) {
    windowDefinition[2].fields.push({
      label: translate('ShowTimePlan'),
      onPress: () => DoNavigate(table, 'VacPlans', false, '', true, false, 'EmpPlan'),
      width: '150px',
      windowElementType: WindowElementType.kInputTypeButton,
    });
  }
  if (IsManager() && global.userData.provider === 0) {
    switch (table.state.createDocStatus) {
      case 1:
        windowDefinition[2].fields.push({
          label: translate('OpenOrder'),
          onPress: () => DoNavigate(table, 'OrderDocs', true, table.state.createDocNumber),
          width: '150px',
          windowElementType: WindowElementType.kInputTypeButton,
        });

        break;
      case 2:
        windowDefinition[2].fields.push({
          label: translate('CreateOrder'),
          onPress: table.createOrderDoc,
          width: '150px',
          windowElementType: WindowElementType.kInputTypeButton,
        });
        break;
    }
  }
  if (table.CanUseApprovalRules() === 1) {
    let fields = [
      {
        label: translate('ApprovalStatus'),
        fixedValue: table.ApprovalStatusText,
        viewOnly: true,
        width: '200px',
      },
    ];
    switch (table.ApprovalStatus) {
      case '1':
      case '2':
      case '5':
        fields.push({
          label: translate('RequestApproval'),
          onPress: table.RequestApproval,
          width: '200px',
          windowElementType: WindowElementType.kInputTypeButton,
        });

        break;
      case '3':
        if (table.cancancel) {
          fields.push({
            label: translate('CancelApproval'),
            onPress: table.cancelApproval,
            width: '150px',
            windowElementType: WindowElementType.kInputTypeButton,
          });
        }
    }
    windowDefinition.push({
      fields: fields,
    });
  }
  if (table.state.message.length > 1) {
    let fields = [
      {
        label: table.state.message[0],
        windowElementType: WindowElementType.kInputTypeStaticText,
      },
    ];
    table.state.message[1]
      .trim()
      .split2(',')
      .map((nr) => {
        fields.push({
          label: nr,
          onPress: () => DoNavigate(table, 'ShiftTables', true, nr),
          width: '100px',
          windowElementType: WindowElementType.kInputTypeButton,
        });
      });
    windowDefinition.splice(0, 0, {
      fields: fields,
    });
  }
  return windowDefinition;
}

function vacPlanHeaderFields_EST(table) {
  const windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Contract'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ContrSerNr',
          viewOnly: false,
          matrixField: true,
          width: '400px',
          pasteSpecial: {
            vcName: 1,
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'contractnr',
                mainKey: 'ContractNr',
              },
              {
                width: '35%',
                name: translate('Name'),
                key: 'Firstname',
              },
              {
                width: '30%',
                name: '',
                key: 'Surname',
              },
            ],
          },
          onChange: table.updateRecordFieldRow,
        },
      ],
    },

    {
      fields: [
        {
          label: translate('Comment'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Comment',
          viewOnly: false,
          width: '600px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('StartDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'StartDate1',
          viewOnly: false,
          matrixField: true,
          width: '150px',
          onChange: table.updateRecordFieldRow,
        },
        {
          label: translate('EndDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'EndDate1',
          viewOnly: false,
          matrixField: true,
          width: '150px',
          linkedField: 'StartDate1',
          onChange: table.updateRecordFieldRow,
        },
        {
          label: translate('OffWorkGroup'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'AbsTypeCode',
          viewOnly: false,
          matrixField: true,
          width: '200px',
          pasteSpecial: {
            vcName: 19,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordFieldRow,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          viewOnly: true,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Approved'),
          checkboxInactiveLabel: translate('Approve'),
          dataKey: 'OKFlag',
          viewOnly: global.userData.deplist === '' && table.hasapprovals !== 0 ? true : false,
          windowElementType: WindowElementType.kInputTypeCheckbox,
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
  if (IsManager() == false) {
    windowDefinition[3].fields[1].windowElementType = -1; //hiding
  }
  if (isDesktopScreen()) {
    windowDefinition[2].fields.push({
      label: translate('ShowTimePlan'),
      onPress: () => DoNavigate(table, 'VacPlans', false, '', true, false, 'EmpPlan'),
      width: '150px',
      windowElementType: WindowElementType.kInputTypeButton,
    });
  }

  if (table.CanUseApprovalRules() === 1) {
    let fields = [
      {
        label: translate('ApprovalStatus'),
        fixedValue: table.ApprovalStatusText,
        viewOnly: true,
        width: '200px',
      },
    ];
    switch (table.ApprovalStatus) {
      case '1':
      case '2':
      case '5':
        fields.push({
          label: translate('RequestApproval'),
          onPress: table.RequestApproval,
          width: '200px',
          windowElementType: WindowElementType.kInputTypeButton,
        });

        break;
      case '3':
        if (table.cancancel) {
          fields.push({
            label: translate('CancelApproval'),
            onPress: table.cancelApproval,
            width: '150px',
            windowElementType: WindowElementType.kInputTypeButton,
          });
        }
    }
    windowDefinition.push({
      fields: fields,
    });
  }
  if (table.state.message.length > 1) {
    let fields = [
      {
        label: table.state.message[0],
        windowElementType: WindowElementType.kInputTypeStaticText,
      },
    ];
    table.state.message[1]
      .trim()
      .split2(',')
      .map((nr) => {
        fields.push({
          label: nr,
          onPress: () => DoNavigate(table, 'ShiftTables', true, nr),
          width: '100px',
          windowElementType: WindowElementType.kInputTypeButton,
        });
      });
    windowDefinition.splice(0, 0, {
      fields: fields,
    });
  }

  return windowDefinition;
}

export function headerFields(table) {
  let res;
  switch (global.userData.provider) {
    case 1:
      res = vacPlanHeaderFields_EST(table);
      break;

    default:
      res = VacPlanHeaderFields_LAT(table);
  }
  return res;
}

function browseColumns_LAT() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('SerNr'),
      key: 'sernr',
      sortKey: 'SerNr',
      width: '100px',
    },
    {
      name: translate('Code'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'name',
      sortKey: 'Name',
      width: '200px',
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('OffWorkGroup'),
      key: 'offworkgr',
      sortKey: 'OffWorkGr',
      width: '100px',
    },
    {
      title: translate('ApprovedShort'),
      dataKey: 'status',
      sortKey: 'Status',
      width: '70px',
      overwrite: DisplayBrowseOKFlag,
    },
    {
      title: translate('Absence'),
      dataKey: 'offworkflag',
      sortKey: 'OffWorkFlag',
      width: '70px',
      overwrite: DisplayBrowseOKFlag,
    },
  ];
  return browseColumns;
}

function browseColumns_EST() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('SerNr'),
      key: 'sernr',
      sortKey: 'SerNr',
      width: '100px',
    },
    {
      name: translate('Contract'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'name',
      sortKey: 'Name',
      width: '200px',
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '150px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('OffWorkGroup'),
      key: 'offworkgr',
      sortKey: 'OffWorkGr',
      width: '100px',
    },
    {
      title: translate('Approved'),
      key: 'okflag',
      sortKey: 'OKFlag',
      width: '100px',
      overwrite: DisplayBrowseOKFlag,
    },
  ];

  return browseColumns;
}

export function browseColumns(table) {
  let res;
  switch (global.userData.provider) {
    case 1:
      res = browseColumns_EST(table);
      break;

    default:
      res = browseColumns_LAT(table);
  }
  return res;
}

export function matrixFields_EST(table) {
  let windowMatrix: WindowMatrix = null;
  if (global.userData.provider == 1) {
    windowMatrix = [
      {
        label: translate('Item'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'ContrSerNr',
        viewOnly: false,
      },
      {
        label: translate('Item'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'StartDate1',
        viewOnly: false,
      },
      {
        label: translate('Item'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'EndDate1',
        viewOnly: false,
      },
      {
        label: translate('Item'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'AbsTypeCode',
        viewOnly: false,
      },
    ];
  }
  return windowMatrix;
}
