import React, {useEffect, useState} from 'react';
import {Tab, TabSection} from '@components/screens/tabSection/TabSection';
import {translate} from '@utils/languageTools';
import {ContactsInvoiceList} from '@components/screens/contacts/tabWindows/ContactsInvoiceList';
import {RecordScreen} from '@src/types/Record/recordScreen';
import Requests from '../../../../api/Requests';
import {logError} from '@utils/debug';
import {CustomRecordData} from '@components/screens/minimizedRecordList/MinimizedRecordList';
import {ContactsSalesOrdersList} from '@components/screens/contacts/tabWindows/ContactsSalesOrdersList';

const invoiceSummaryKeys = ['NotOverDue', 'Open', 'OverDue'];
const invoiceSummaryTranslationKeys = ['NotOverDue', 'TotalOpen', 'OverDue'];

enum RecordType {
  InvoiceTotals = 'invoicetotals',
  Invoice = 'invoice',
  SalesOrder = 'salesorder',
}

type invoiceTotals = {
  type: RecordType;
  NotOverDue: number;
  Open: number;
  OverDue: number;
};

type statusRecord = {
  type: string;
  Balance: string;
  Currency: string;
  // Date is inv date
  Date: string;
  // Date2 is due date
  Date2: string;
  Paid: string;
  PayDeal: string;
  SerNr: string;
  Sum: string;
};
type customerStatusRecordResponse = {
  records: (invoiceTotals | statusRecord)[];
};

type ContactsTabSectionProps = {
  window: RecordScreen<any, any>;
  show: boolean;
};

export const ContactsTabSection: React.FC<ContactsTabSectionProps> = ({window, show}) => {
  const [invoiceData, setInvoiceData] = useState<CustomRecordData>(null);
  const [salesOrderData, setSalesOrderData] = useState<CustomRecordData>();

  useEffect(() => {
    initializeRecords();
  }, []);

  function initializeRecords() {
    Requests.doAction('getcustomerstatusrecords', {id: window.id, replyArray: true})
      .then((response) => processResponse(response))
      .catch((error) => handleFailedRecordRequest(error));
  }

  function handleFailedRecordRequest(error) {
    logError(error);
    processResponse({records: []});
  }

  function processResponse(response: customerStatusRecordResponse) {
    if (response.records.length === 0) {
      handleEmptyResponse();
      return;
    }

    populateInvoiceData(response.records);
    populateSalesOrderData(response.records);
  }

  function handleEmptyResponse() {
    setInvoiceData({records: [], summaryData: {totals: [], keys: [], suffixKey: ''}});
    setSalesOrderData({records: []});
  }

  function populateInvoiceData(records: any[]) {
    const invoiceTotals: invoiceTotals = records.find((record) => record.Type === RecordType.InvoiceTotals);
    const invoiceRecords: statusRecord[] = records.filter((record) => record.Type === RecordType.Invoice);
    sortInvoiceRecords(invoiceRecords);

    setInvoiceData({
      records: invoiceRecords,
      summaryData: {
        totals: invoiceTotals,
        keys: invoiceSummaryKeys,
        translatioKeys: invoiceSummaryTranslationKeys,
        suffixKey: 'Currency',
      },
    });
  }

  function sortInvoiceRecords(invoiceRecords) {
    invoiceRecords.sort((a, b) => {
      if (a.Balance === '0.00' && b.Balance === '0.00') {
        return b.Date.localeCompare(a.Date); // Sort zero balances by string (descending order)
      }
      if (a.Balance === '0.00') return -1; // Place zero balances before non-zero
      if (b.Balance === '0.00') return 1;
      return b.Date.localeCompare(a.Date); // Sort non-zero balances by string (descending order)
    });
  }
  function populateSalesOrderData(records: any[]) {
    const salesOrderRecords = records.filter((record) => record.Type === RecordType.SalesOrder);
    setSalesOrderData({
      records: salesOrderRecords,
    });
  }

  const statusTabs: Tab[] = [
    {
      tabName: translate('Invoices'),
      component: <ContactsInvoiceList window={window} recordData={invoiceData} />,
    },
    {
      tabName: translate('SalesOrders'),
      component: <ContactsSalesOrdersList window={window} recordData={salesOrderData} />,
    },
  ];

  if (!invoiceData) return <></>;
  if (!show) return <></>;
  return <TabSection tabs={statusTabs} />;
};
