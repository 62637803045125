import React from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import {colours, spacing} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {translate} from '@utils/languageTools';
import EditField from '../../components/edit_elements/EditField';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {LangColorContext} from '../../App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import * as myDate from '../../Tools/DateTools';
import {logError} from '@utils/debug';
import {InputChangeEventProps, WindowDefinition, WindowMatrix, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

function headerFields(table) {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('InvDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'InvDate',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '150px',
        },
        {
          label: translate('TransDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Approved'),
          windowElementType: WindowElementType.kInputTypeCheckbox,
          checkboxInactiveLabel: translate('Approve'),
          dataKey: 'OKFlag',
          viewOnly: table.hasapprovals !== 0 ? true : false,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Supplier'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'VECode',
          viewOnly: false,
          pasteSpecial: {
            hideComment: true,
            newRecord: true,
            vcName: 'VEVc',
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('CompName'),
                key: 'name',
              },
            ],
          },
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('CompName'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'VEName',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '340px',
        },
      ],
    },
    {
      fields: [
        {
          label: translate('PayDeal'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'PayDeal',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'PDVc',
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'pdComment',
              },
            ],
          },
        },
        {
          label: translate('DueDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'DueDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('InvoiceNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'InvoiceNr',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '340px',
        },
        {
          label: translate('SalesMan'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SalesMan',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'UserVc',
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
        },
      ],
    },
  ];

  if (table.CanUseApprovalRules() === 1) {
    let fields = [
      {
        label: translate('ApprovalStatus'),
        fixedValue: table.ApprovalStatusText,
        viewOnly: true,
        width: '200px',
      },
    ];
    switch (table.ApprovalStatus) {
      case '1':
      case '2':
      case '5':
        fields.push({
          label: translate('RequestApproval'),
          onPress: table.RequestApproval,
          width: '200px',
          windowElementType: WindowElementType.kInputTypeButton,
        });

        break;
      case '3':
        if (table.cancancel) {
          fields.push({
            label: translate('CancelApproval'),
            onPress: table.cancelApproval,
            width: '150px',
            windowElementType: WindowElementType.kInputTypeButton,
          });
        }
    }
    windowDefinition.push({
      fields: fields,
    });
  }

  return windowDefinition;
}

const matrixFooterFields = (table): WindowDefinition => [
  {
    fields: [
      {
        label: translate('CalculatedVAT'),
        windowElementType: WindowElementType.kInputTypeValFull,
        dataKey: 'CalcVATVal',
        viewOnly: true,
        width: '300px',
        row: true,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
      {
        label: translate('VAT'),
        windowElementType: WindowElementType.kInputTypeValFull,
        dataKey: 'VATVal',
        viewOnly: false,
        width: '300px',
        row: true,
        onChange: table.updateRecordField,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
      {
        label: translate('Total'),
        windowElementType: WindowElementType.kInputTypeValFull,
        dataKey: 'PayVal',
        viewOnly: false,
        width: '200px',
        onChange: table.updateRecordField,
        row: true,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
    ],
    style: {
      justifyContent: 'flex-end',
    },
  },
];

const matrixFields = (table) => {
  let windowMatrix: WindowMatrix = [];
  windowMatrix.push({
    label: translate('Account'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'AccNumber',
    width: '180px',
    viewOnly: false,
    onChange: table.updateRecordField,
    pasteSpecial: {
      vcName: 'AccVc',
      columns: [
        {
          name: translate('Code'),
          width: '35%',
          key: 'AccNumber',
          mainKey: 'AccNumber',
        },
        {
          width: '65%',
          name: translate('Name'),
          key: 'Comment',
        },
      ],
    },
    onFocus: table.handleMatrixFocus,
    style: {
      borderColor: '#ddd',
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('Description'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'Comment',
    width: '300px',
    viewOnly: false,
    onChange: table.updateRecordField,
    onFocus: table.handleMatrixFocus,
    style: {
      borderColor: '#ddd',
      borderRight: 'none',
      borderRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('VATCode'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'VATCode',
    width: '80px',
    onChange: table.updateRecordField,
    viewOnly: false,
    onFocus: table.handleMatrixFocus,
    pasteSpecial: {
      vcName: 'VATCodeBlock',
      columns: [
        {
          name: translate('Code'),
          width: '35%',
          key: 'VATCode',
          mainKey: 'VATCode',
        },
        {
          width: '65%',
          name: translate('Description'),
          key: 'Comment',
        },
      ],
    },
    style: {
      borderColor: '#ddd',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  });

  windowMatrix.push({
    label: translate('Total'),
    dataKey: 'Sum',
    windowElementType: WindowElementType.kInputTypeValFull,
    width: '80px',
    onChange: table.updateRecordField,
    viewOnly: false,
    onFocus: table.handleMatrixFocus,
    style: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      borderColor: '#ddd',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  });
  return windowMatrix;
};

function invoiceRowActions(table) {
  let res = [];
  res.push({
    name: translate('InsertRow'),
    action: table.insertMatrixRow,
  });
  res.push({
    name: translate('DeleteRow'),
    action: table.deleteMatrixRow,
  });
  /*
                  res.push({
                    name: translate('InsertRow'),
                    action: table.insertMatrixRow,
                  });
                  */
  return res;
}

function CurrentDate() {
  return new Date();
}

interface PurchaseInvoicesListState extends ListScreenState {
  newInvoiceHelper: boolean;
  px: number;
  py: number;
  NewCust: string;
  NewPD: string;
  NewInvDate: string;
  Month: string;
  Year: number;
  Department: string;
  SalesMan: string;
}

export class PurchaseInvoicesList extends ListScreen<{}, PurchaseInvoicesListState> {
  origrowcnt: number;

  constructor(props) {
    super(props);
    this.createNewTable = this.createNewTable.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.ScreenName = 'PurchaseInvoices';
    this.table = 'VIVc';
    this.init = true;
    this.origrowcnt = 0;
    this.state = {
      ...this.state,
      data: [],
      newInvoiceHelper: false,
      px: 0,
      py: 0,
      NewCust: '',
      NewPD: '',
      NewInvDate: myDate.constructDateString2(myDate.getCurrentDate()),
      Month: '01',
      Year: CurrentDate().getFullYear(),
      Department: global.userData.deplist.split(',').pop(),
      SalesMan: global.userData.usercode,
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('PurchaseInvoices', 'SerNr', 'descending');
    AddDateFilter('PurchaseInvoices', myDate.AddMonth(myDate.getCurrentDate(), -2));
  }

  getBrowseListData = () => {
    let self = this;
    Requests.getTableData(this.table, undefined, undefined, undefined, true)
      .then((response) => {
        if (self._isMounted) {
          self.setState({data: response.records});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  createNewTable = () => {
    if (this.state.NewCust === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let self = this;
    let tdata = {
      record: {
        VECode: this.state.NewCust,
        SalesMan: this.state.SalesMan,
      },
    };
    if (this.state.NewPD !== '') {
      tdata.record.PayDeal = this.state.NewPD;
    }
    if (this.state.NewInvDate !== '') {
      tdata.record.InvDate = this.state.NewInvDate;
    }
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (response.success === true) {
          self.ShowHideNewInvoice();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewInvoice = () => {
    if (this.state.newInvoiceHelper) {
      global.PopEscapeActions();
      this.setState({newInvoiceHelper: false});
    } else {
      this.setState({newInvoiceHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewInvoice());
    }
  };
  renderNewButton = () => {
    let style = {};
    let self = this;
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }

    return (
      <View
        key={'invoice_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewInvoice()} title={translate('AddNew')} />
        {this.state.newInvoiceHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('Supplier') + '*'}
                    name={'VECode'}
                    value={this.state.NewCust}
                    style={{width: '100%'}}
                    pasteSpecial={{
                      vcName: 'VEVc',
                      newRecord: true,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewCust: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('PayDeal')}
                    name={'PayDeal'}
                    value={this.state.NewPD}
                    style={{width: '100%'}}
                    pasteSpecial={{
                      vcName: 'PDVc',
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'Code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'pdComment',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewPD: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('InvDate')}
                    name={'InvDate'}
                    type={WindowElementType.kInputTypeDate}
                    editable={true}
                    value={this.state.NewInvDate}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewInvDate: value})}
                    style={{width: '196px'}}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={{style}} onPress={() => this.ShowHideNewInvoice()} title={translate('Cancel')} />
                <ActionButton style={style} onPress={() => this.createNewTable()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <TableContainer
                table={this.table}
                update={this.state.update}
                data={this.state.data}
                browseColumns={[
                  {
                    name: translate('SerNr'),
                    key: 'sernr',
                    sortKey: 'SerNr',
                    width: '150px',
                  },
                  {
                    name: translate('Supplier'),
                    key: 'vecode',
                    sortKey: 'VECode',
                    width: '150px',
                  },
                  {
                    name: translate('Name'),
                    key: 'vename',
                    sortKey: 'VEName',
                    width: '170px',
                  },
                  {
                    name: translate('InvDate'),
                    key: 'invdate',
                    sortKey: 'InvDate',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeDate,
                  },
                  {
                    name: translate('TransDate'),
                    key: 'transdate',
                    sortKey: 'TransDate',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeDate,
                  },
                  {
                    name: translate('Total'),
                    key: 'payval',
                    sortKey: 'PayVal',
                    alignment: 'right',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeValFull,
                  },
                  {
                    name: translate('Approved'),
                    key: 'okflag',
                    sortKey: 'OKFlag',
                    width: '100px',
                    overwrite: DisplayBrowseOKFlag,
                  },
                ]}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                width="800px"
                extraContent={this.renderNewButton}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface PurchaseInvoicesRecordState extends RecordScreenState {
  NewCust: string;
  NewPD: string;
  Department: string;
  NewRowAccount: string;
  NewRowSum: string;
  NewRowIndex: number;
  newMatrixTableHelper: boolean;
  activeRow: number;
}

export class PurchaseInvoicesRecord extends RecordScreen<{}, PurchaseInvoicesRecordState> {
  origrowcnt: number;

  constructor(props) {
    super(props);
    let self = this;
    this.updateRecordField = this.updateRecordField.bind(this);
    this.GetMatrixRowStyle = this.GetMatrixRowStyle.bind(this);
    this.handleMatrixFocus = this.handleMatrixFocus.bind(this);
    this.copyMatrixRow = this.copyMatrixRow.bind(this);
    this.deleteMatrixRow = this.deleteMatrixRow.bind(this);
    this.insertMatrixRow = this.insertMatrixRow.bind(this);
    this.rowClick = this.rowClick.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.ScreenName = 'PurchaseInvoices';
    this.table = 'VIVc';
    this.init = true;
    this.state = {
      ...this.state,
      NewCust: '',
      NewPD: '',
      Department: global.userData.deplist.split(',').pop(),
      NewRowAccount: '',
      NewRowSum: '',
      NewRowIndex: -1,
      newMatrixTableHelper: false,
    };
    this.record = new WindowRecord(this.table, true, headerFields(this), matrixFields(this), matrixFooterFields(this));
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  createNewRecord = () => {
    return new WindowRecord(this.table, true, headerFields(this), matrixFields(this));
  };

  AddBlankInvoiceRow = () => {
    let lastrow = this.record.matrixRows[this.record.matrixRows.length - 1];
    let addf = false;
    if (lastrow) {
      if (lastrow.AccNumber !== '') {
        addf = true;
      }
      if (lastrow.Comment !== '') {
        addf = true;
      }
      if (lastrow.Sum !== '') {
        addf = true;
      }
    } else {
      addf = true;
    }
    if (addf) {
      this.record.addMatrixRow();
      this.origrowcnt = this.record.matrixRows.length;
    }
  };
  rowClick = (id) => {
    let self = this;
    this.setState({record: {}});
    this.record = this.createNewRecord();
    this.hasapprovals = this.canUseApprovalRules();
    Requests.getTableData(this.table, {SerNr: id}, undefined, undefined, true)
      .then((response) => {
        self.id = response.records[0].SerNr;
        self.record.applyNewValues(response.records[0]);
        self.AddBlankInvoiceRow();
      })
      .catch((error) => {
        logError(error);
      });
  };
  insertMatrixRow = (index) => {
    let self = this;
    Requests.insertRecordRow(this.table, this.id + ':' + (index + 1) + ':1')
      .then((response) => {
        if (response.success) {
          self.rowClick(this.id);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  copyMatrixRow = (index) => {
    this.state.NewRowIndex = index;
    this.setState({newMatrixTableHelper: true});
  };
  deleteMatrixRow = (index) => {
    let self = this;
    let sernr = this.id;
    Requests.deleteRecordRow(this.table, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(this.table, {SerNr: sernr})
            .then((response) => {
              self.id = response.records[0].SerNr;
              self.record.applyNewValues(response.records[0]);
              self.AddBlankInvoiceRow();
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  createNewTableRow = () => {
    let self = this;
    Requests.insertRecordRow(this.table, this.id + ':' + (this.state.NewRowIndex + 1) + ':1')
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            let res = {
              list: [
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'stp',
                  value: 1,
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'AccNumber',
                  value: self.state.NewRowAccount,
                },
              ],
              id: self.id,
            };
            Requests.updateRecordData(this.table, res)
              .then((response) => {
                if (response.success) {
                  if (self._isMounted) {
                    self.record.applyNewValues(response.record);
                    self.AddBlankInvoiceRow();
                    self.setState({newMatrixTableHelper: false});
                  }
                }
              })
              .catch((error) => {
                logError(error);
              });
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  handleMatrixFocus = (rowindex) => {
    this.setState({activeRow: rowindex});
  };
  GetMatrixRowStyle = (rowindex) => {
    if (rowindex === this.state.activeRow) {
      return {
        backgroundColor: colours[GetGlobalTheme()].activeMatrixBackground,
      };
    }
  };
  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    if (rowindex > -1) {
      this.state.activeRow = -1;
    }
    let callback = function () {
      Requests.updateRecordData(self.table, {
        field: fieldname,
        rownr: rowindex,
        value: value,
        id: self.id,
      })
        .then((response) => {
          if (response.success) {
            if (self._isMounted) {
              self.record.applyNewValues(response.record);
              self.AddBlankInvoiceRow();
              self.updateScreen();
            }
          } else {
            if (self._isMounted) {
              self.updateScreen();
            }
          }
        })
        .catch((error) => {
          logError(error);
        });
    };
    if (rowindex > -1 && rowindex >= this.origrowcnt) {
      Requests.insertRecordRow(this.table, this.id + ':' + rowindex + ':1')
        .then((response) => {
          if (response.success) {
            callback();
          }
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      callback();
    }
  };
  GetContractList = () => {
    let res = [];
    for (let i = 0; i < this.record.matrixRows.length; i++) {
      res.push(this.record.matrixRows[i].ContractNr);
    }
    return res.join(',');
  };

  renderNewRowButton = () => {
    let self = this;
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Account')}
              name={'AccNumber'}
              value={this.state.NewRowAccount}
              ps={1}
              style={{width: 215}}
              psfields={['contractnr', 'empname']}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowAccount: value})}
            />
          </FilterField>
          <FilterField>
            <EditField
              title={translate('Sum')}
              name={'Sum'}
              value={this.state.NewRowSum}
              type={WindowElementType.kInputTypeValFull}
              ps={22}
              style={{width: 215}}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowSum: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {(data) => {
          return (
            <WindowWrap>
              <InspectWindow
                title={translate('PurchaseInvoice')}
                window={this}
                headerFields={headerFields(this)}
                matrixFooterFields={matrixFooterFields(this)}
                record={this.record}
                matrixFields={matrixFields(this)}
                GetMatrixRowStyle={(rowindex, row) => this.GetMatrixRowStyle(rowindex, row)}
                hasApprovals={this.hasapprovals === 1}
                rowactions={invoiceRowActions(this)}
                onClick={this.handleInputClick}
                /*splitrow={(row1, row2) => self.SplitMatrixRow(row1, row2)}*/
                okcheck={'OKFlag'}
                allowDelete={true}
                allowPrint={IsHRWeb()}
                // width="1293px"
                //width="1309px"
                width={'840px'}
                backButton={() => goBack(self, self.ScreenName)}
              />
              {this.state.newMatrixTableHelper && (
                <Modal transparent={true}>
                  <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
                </Modal>
              )}
              {this.state.activeDelete && (
                <Modal transparent={true}>
                  <YesNoWindow
                    question={translate('RemoveQuestion')}
                    yes={() => this.deleteRecordOld()}
                    no={() => this.setState({activeDelete: false})}
                  />
                </Modal>
              )}
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function PurchaseInvoices() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="PurchaseInvoicesList"
        component={PurchaseInvoicesList}
        options={{
          title: translate('PurchaseInvoices'),
        }}
      />
      <Drawer.Screen
        name="PurchaseInvoicesRecord"
        component={PurchaseInvoicesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('PurchaseInvoice'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default PurchaseInvoices;
