import * as myDate from '../Tools/DateTools';
import {objectHasProperty} from './ObjectTools';

export function GetWebColor(setcolor, transp = '0.4') {
  let res = '';
  switch (setcolor) {
    case '1':
      res = 'rgba(0, 146, 211, ' + transp + ')';
      break;
    case '2':
      res = 'rgba(0, 75, 119, ' + transp + ')';
      break;
    case '3':
      res = 'rgba(0, 30, 69, ' + transp + ')';
      break;
    case '4':
      res = 'rgba(208, 139, 196, ' + transp + ')';
      break;
    case '5':
      res = 'rgba(170, 42, 127, ' + transp + ')';
      break;
    case '6':
      res = 'rgba(140, 96, 181, ' + transp + ')';
      break;
    case '7':
      res = 'rgba(104, 82, 136, ' + transp + ')';
      break;
    case '8':
      res = 'rgba(125, 215, 123, ' + transp + ')';
      break;
    case '9':
      res = 'rgba(136, 172, 143, ' + transp + ')';
      break;
    case '10':
      res = 'rgba(0, 126, 148, ' + transp + ')';
      break;
    case '11':
      res = 'rgba(255, 152, 84, ' + transp + ')';
      break;
    case '12':
      res = 'rgba(249, 106, 96, ' + transp + ')';
      break;
    case '13':
      res = 'rgba(218, 66, 114, ' + transp + ')';
      break;
    //default:
    //  res = 'rgba(218, 66, 114, 0.4)';
  }

  return res;
}

export function GetDayColor(record) {
  let vColor = {};
  if (record) {
    let td = myDate.extractDateFromString(record.StartDate);
    if (td) {
      for (let i = 1; i < 32; i++) {
        let day = td.getDay();
        if (!global.transferredDays?.[td]) {
          //check if date is not a public holiday
          let tt = myDate.constructDateString2(td);
          if (global.transferredDays2[tt]) {
            vColor[i] = GetWebColor(global.shiftBlock.ColorWeekend);
          }
          if (day === 6 || day === 0) {
            vColor[i] = GetWebColor(global.shiftBlock.ColorWeekend);
          }
          if (global.holidaysvector[tt]) {
            vColor[i] = GetWebColor(global.shiftBlock.ColorHoliday);
          }
        }
        td.setDate(td.getDate() + 1);
      }
    }
  }
  return vColor;
}

export function GetOffWorkColor(colval) {
  let res = '';
  if (objectHasProperty(global.pasteSpecialsVector[19], colval)) {
    res = GetWebColor(global.pasteSpecialsVector[19][colval].ShiftColor);
  }

  return res;
}

export function GetIntervalColor(colval) {
  let res = '';
  if (objectHasProperty(global.pasteSpecialsVector[14], colval)) {
    res = GetWebColor(global.pasteSpecialsVector[14][colval].Color);
  }

  return res;
}

export function DayIsRedDay(colkey, curdate) {
  let res = false;
  let day = parseInt(colkey.replace('Day', ''));
  let td = myDate.extractDateFromString(curdate);
  if (td) {
    td.setDate(day);
    let tday = td.getDay();
    let tt = myDate.constructDateString2(td);
    if (!global.transferredDays?.[tt]) {
      //check if this is not a public holiday
      if (global.transferredDays2[tt]) {
        res = true;
      }
      if (tday == 6 || tday == 0) {
        res = true;
      }
      if (global.holidaysvector[tt]) {
        res = true;
      }
    }
  }
  return res;
}
