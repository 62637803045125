import React from 'react';
import {Modal, Text, View} from 'react-native';
import Requests from '../../api/Requests';
import {TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  DoNavigate,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  Supported,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {translate} from '@utils/languageTools';
import EditField from '../../components/edit_elements/EditField';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {LangColorContext} from '../../App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {InputChangeEventProps, OverwriteMatrixProps, WindowDefinition, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {headerFields, matrixFields} from './columns/TimeTableColumns';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isMobileOrTabletScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

function matrixFooterFields(table) {
  let res: WindowDefinition = [
    {
      fields: [
        {
          label: translate('OpenShiftSheet'),
          onPress: table.openShiftSheet,
          width: '150px',
          windowElementType: WindowElementType.kInputTypeButton,
        },
        {
          label: translate('Total'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'TimeSum',
          viewOnly: true,
          width: '150px',
          row: true,
          style: {
            backgroundColor: colours[GetGlobalTheme()].inputBackground,
            textAlign: 'right',
            paddingRight: spacing.medium,
          },
        },
      ],
      style: {
        justifyContent: 'flex-end',
      },
    },
  ];
  if (global.userData.provider === 1) {
    res[0].fields.splice(1, 1);
  }

  return res;
}

function TimeTableRowActions(table) {
  if (global.userData.deplist !== '') {
    let res = [];
    if (table.record.headerFields.OKFlag !== '1') {
      res.push({
        name: translate('InsertRow'),
        action: table.copyMatrixRow,
      });
      res.push({
        name: translate('DeleteRow'),
        action: table.deleteMatrixRow,
      });
    }
    /*
    res.push({
      name: translate('InsertRow'),
      action: table.insertMatrixRow,
    });
    */
    if (res.length > 0) {
      return res;
    }
  }
}

let FilterView;
if (IsHRWeb()) {
  FilterView = styled.View`
    position: absolute;
    top: 100%;
    right: 0px;
    width: 240px;
    background: ${colours[GetGlobalTheme()].lightBackground};
    padding: 20px;
    flex-direction: column;
    align-items: center;
  `;
} else {
  if (IsHRiOS()) {
    FilterView = styled.View`
      position: absolute;
      height: ${ScreenHeight()};
      width: ${ScreenWidth()};
      background: ${colours[GetGlobalTheme()].lightBackground};
      padding: 6px;
    `;
  } else {
    FilterView = styled.Modal``;
  }
}

const FilterViewInner = styled.View`
  ${IsHRWeb() === false &&
  css`
    height: 100%;
    width: 100%;
    background-color: ${colours[GetGlobalTheme()].siteBackground};
  `};
  ${IsHRiOS() &&
  css`
    padding-top: 50;
  `};
`;

const FilterFields = styled.View`
  ${IsHRWeb() &&
  css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-right: -30px;
  `}
`;
const FilterActions = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

const FilterField = styled.View`
  margin-right: 30px;
`;

const MatrixDivider = styled.View`
  ${(IsHRWeb() &&
    css`
      width: calc(100% - ${spacing.medium * 2}px - 12px);
    `) ||
  css`
    width: 100%;
  `}
  height: 1px;
  border-bottom-color: ${colours[GetGlobalTheme()].normalHighligh};
  border-bottom-width: 1;
  margin-horizontal: ${spacing.medium250};
  border-radius: 6px;
`;

function CurrentDate() {
  return new Date();
}

interface TimeTablesListState extends ListScreenState {
  newTableHelper: boolean;
  px: number;
  py: number;
  Department: string;
  Month: string;
  Year: number;
  newMatrixTableHelper: boolean;
}

export class TimeTablesList extends ListScreen<{}, TimeTablesListState> {
  constructor(props) {
    super(props);
    this.createNewTable = this.createNewTable.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);

    //this.changePage = this.changePage.bind(this);
    this._isMounted = true;
    this.ScreenName = 'TimeTables';
    this.init = true;
    this.state = {
      ...this.state,
      data: [],
      newTableHelper: false,
      Month: global.userData.provider === 1 ? '1' : '01',
      Year: CurrentDate().getFullYear(),
      Department: global.userData.deplist.split(',').pop(),
      px: 0,
      py: 0,
      newMatrixTableHelper: false,
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('TimeTables', 'SerNr', 'descending');
    AddDateFilter('TimeTables');
  }

  CheckNewTableData = () => {
    let res = true;
    if (this.state.Year === 0) {
      res = false;
    }
    if (this.state.Month === '') {
      res = false;
    }
    return res;
  };

  createNewTable = () => {
    let self = this;
    if (this.CheckNewTableData() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let tdata = {
      record: {
        StartDate: this.state.Year + '-' + this.state.Month + '-01',
        DepCode: this.state.Department,
        APIFlag: 1,
      },
    };
    if (global.userData.provider === 1) {
      tdata = {
        record: {
          Year: this.state.Year,
          Month: this.state.Month,
          AccGroupCode: this.state.Department,
        },
      };
    }
    Requests.getNewRecord(5, tdata)
      .then((response) => {
        if (response.success === true) {
          self.ShowHideNewTable();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewTable = () => {
    if (this.state.newTableHelper) {
      global.PopEscapeActions();
      this.setState({newTableHelper: false});
    } else {
      this.setState({newTableHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewTable());
    }
  };

  renderNewButton = () => {
    let self = this;
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'timetables_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        {Supported('nonewtimetables') === false && (
          <>
            <ActionButton onPress={() => this.ShowHideNewTable()} title={translate('AddNew')} />
            {this.state.newTableHelper && (
              <FilterView style={style2}>
                <FilterViewInner>
                  <FilterFields>
                    <FilterField>
                      {global.userData.provider === 0 && (
                        <EditField
                          title={translate('Department')}
                          value={this.state.Department}
                          width={'180px'}
                          pasteSpecial={{
                            vcName: 7,
                            columns: [
                              {
                                name: translate('Code'),
                                width: '35%',
                                key: 'DepCode',
                                mainkey: 'DepCode',
                              },
                              {
                                width: '65%',
                                name: translate('Name'),
                                key: 'Name',
                              },
                            ],
                          }}
                          onChange={({value}: InputChangeEventProps) => {
                            self.setState({Department: value});
                          }}
                        />
                      )}
                      {global.userData.provider === 1 && (
                        <EditField
                          title={translate('Department')}
                          value={this.state.Department}
                          width={'180px'}
                          pasteSpecial={{
                            vcName: 7,
                            columns: [
                              {
                                name: translate('Code'),
                                width: '35%',
                                key: 'AccGroupCode',
                                mainkey: 'AccGroupCode',
                              },
                              {
                                width: '65%',
                                name: translate('Name'),
                                key: 'Name',
                              },
                            ],
                          }}
                          onChange={({value}: InputChangeEventProps) => {
                            self.setState({Department: value});
                          }}
                        />
                      )}
                    </FilterField>
                    <FilterField>
                      <EditField
                        title={translate('Year') + '*'}
                        value={this.state.Year}
                        width={'180px'}
                        pasteSpecial={{
                          vcName: 9993,
                          fields: ['code', 'comment'],
                        }}
                        onChange={({value}: InputChangeEventProps) => self.setState({Year: value})}
                      />
                    </FilterField>
                    <FilterField>
                      <EditField
                        title={translate('Month') + '*'}
                        value={this.state.Month}
                        width={'180px'}
                        pasteSpecial={{
                          vcName: 9994,
                          fields: ['code', 'comment'],
                        }}
                        onChange={({value}: InputChangeEventProps) => self.setState({Month: value})}
                      />
                    </FilterField>
                  </FilterFields>
                  <FilterActions>
                    <ActionButton
                      style={{marginTop: '20px', marginRight: '20px'}}
                      onPress={() => this.ShowHideNewTable()}
                      title={translate('Cancel')}
                    />
                    <ActionButton
                      style={{marginTop: '20px'}}
                      onPress={() => this.createNewTable()}
                      title={translate('Create')}
                    />
                  </FilterActions>
                </FilterViewInner>
              </FilterView>
            )}
          </>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          let columns = [
            {
              name: translate('SerNr'),
              key: 'sernr',
              sortKey: 'SerNr',
              width: '150px',
            },
            {
              name: translate('Department'),
              key: 'depcode',
              sortKey: 'DepCode',
              width: '150px',
            },
            {
              name: translate('Date'),
              key: 'regdate',
              sortKey: 'RegDate',
              width: '170px',
              windowElementType: WindowElementType.kInputTypeDate,
            },
            {
              name: translate('StartDate'),
              key: 'startdate',
              sortKey: 'StartDate',
              width: '170px',
              windowElementType: WindowElementType.kInputTypeDate,
            },
            {
              name: translate('EndDate'),
              key: 'enddate',
              sortKey: 'EndDate',
              width: '170px',
              windowElementType: WindowElementType.kInputTypeDate,
            },
            {
              name: translate('Approved'),
              key: 'okflag',
              sortKey: 'OKFlag',
              width: '100px',
              overwrite: DisplayBrowseOKFlag,
            },
          ];
          if (global.userData.provider === 1) {
            columns[1].key = 'accgroupcode';
            columns[1].sortKey = 'AccGroupCode';
          }
          return (
            <WindowWrap>
              <TableContainer
                data={this.state.data}
                table={5}
                update={this.state.update}
                browseColumns={columns}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                width="800px"
                extraContent={this.renderNewButton}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface TimeTablesRecordState extends RecordScreenState {
  NewRowContract: string;
  NewRowType: string;
  NewRowIndex: number;
  newMatrixTableHelper: boolean;
}

export class TimeTablesRecord extends RecordScreen<{}, TimeTablesRecordState> {
  LocalOffWork: any;

  constructor(props) {
    super(props);
    let self = this;
    this.updateRecordField = this.updateRecordField.bind(this);
    this.copyMatrixRow = this.copyMatrixRow.bind(this);
    this.deleteMatrixRow = this.deleteMatrixRow.bind(this);
    this.insertMatrixRow = this.insertMatrixRow.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.openShiftSheet = this.openShiftSheet.bind(this);
    this.overwriteMatrixRow = this.overwriteMatrixRow.bind(this);
    this.overwriteMatrixRowDQty = this.overwriteMatrixRowDQty.bind(this);
    this.shouldShowMatrixRow = this.shouldShowMatrixRow.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.LocalOffWork = null;
    this.table = 5;
    this.ScreenName = 'TimeTables';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      NewRowContract: '',
      NewRowType: '',
      NewRowIndex: -1,
      newMatrixTableHelper: false,
    };
    this.record = new WindowRecord(this.table, true, headerFields(this), matrixFields(this), matrixFooterFields(this));
    this.CheckOpenRecord();
  }

  createNewRecord = () => {
    return new WindowRecord(this.table, true, headerFields(this), matrixFields(this), matrixFooterFields(this));
  };

  rowClick = (id: string) => {
    let self = this;
    this.LocalOffWork = null;
    Requests.getTableData(5, {SerNr: id}, undefined, undefined, true)
      .then((response) => {
        self.id = response.records[0].SerNr;
        self.record.applyNewValues(response.records[0]);
        self.updateScreen();
        self.UpdateWithOffWorkData();
      })
      .catch((error) => {
        logError(error);
      });
  };
  openShiftSheet = () => {
    if (this.record.headerFields.ShiftSerNr !== '-1' && this.record.headerFields.ShiftSerNr !== '') {
      DoNavigate(this, 'ShiftTables', true, this.record.headerFields.ShiftSerNr);
    } else {
      alert(translate('NoShiftSheet'));
    }
  };
  insertMatrixRow = (index) => {
    this.setState({
      NewRowContract: '',
      NewRowType: '',
      NewRowIndex: index,
      newMatrixTableHelper: true,
    });
  };
  copyMatrixRow = (index) => {
    this.setState({
      NewRowContract: this.record.matrixRows[index].ContractNr,
      NewRowType: '',
      NewRowIndex: index,
      newMatrixTableHelper: true,
    });
  };
  deleteMatrixRow = (index) => {
    let self = this;
    let sernr = this.id;
    Requests.deleteRecordRow(5, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(5, {SerNr: sernr})
            .then((response) => {
              response.records[0].id = response.records[0].SerNr;
              self.record.applyNewValues(response.records[0]);
              self.updateScreen();
              self.UpdateWithOffWorkData2();
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  GetTimeTrCode = (tval) => {
    let res;

    for (let i in global.pasteSpecials[22]) {
      if (tval === global.pasteSpecials[22][i].code) {
        res = global.pasteSpecials[22][i].timetr;
        break;
      }
    }

    return res;
  };
  createNewTableRow = () => {
    let self = this;
    Requests.insertRecordRow(5, this.id + ':' + (this.state.NewRowIndex + 1) + ':1')
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            let res = {
              list: [
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'ContractNr',
                  value: self.state.NewRowContract,
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'TrType',
                  value: self.state.NewRowType,
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'TimeTrType',
                  value: self.GetTimeTrCode(self.state.NewRowType),
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'stp',
                  value: 2,
                },
              ],
              id: self.id,
            };
            Requests.updateRecordData(5, res)
              .then((response) => {
                if (response.success) {
                  if (self._isMounted) {
                    self.record.applyNewValues(response.record);
                    self.UpdateWithOffWorkData2();
                    self.setState({newMatrixTableHelper: false});
                  }
                }
              })
              .catch((error) => {
                logError(error);
              });
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(5, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
            self.UpdateWithOffWorkData2();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  overwriteMatrixRow = ({rowindex, datakey}: OverwriteMatrixProps) => {
    if (this.LocalOffWork && this.LocalOffWork[rowindex]) {
      if (this.LocalOffWork[rowindex][datakey]) {
        return (
          <View style={{textAlign: 'center'}}>
            <Text>{this.LocalOffWork[rowindex][datakey]}</Text>
          </View>
        );
      }
    }
  };
  overwriteMatrixRowDQty = ({rowindex}: OverwriteMatrixProps) => {
    let res;
    if (global.userData.provider === 0) {
      if (this.record.matrixRows[rowindex]['stp'] !== '1') {
        res = [];
        res.push(<View></View>);
      }
    }
    return res;
  };
  GetContractList = () => {
    let res = [];
    for (let i = 0; i < this.record.matrixRows.length; i++) {
      res.push(this.record.matrixRows[i].ContractNr);
    }
    return res.join(',');
  };

  UpdateWithOffWorkData2 = () => {
    let update = this.LocalOffWork;
    for (let i = 0; i < update.length; i++) {
      for (let j = 0; j < this.record.matrixRows.length; j++) {
        if (this.record.matrixRows[j].ContractNr === update[i].ContractNr) {
          for (let s = 1; s < 32; s++) {
            if (update[i]['Day' + s] !== '') {
              this.record.matrixRows[j]['Day' + s] = update[i]['Day' + s];
            }
          }
          break;
        }
      }
    }
  };

  UpdateWithOffWorkData = () => {
    let self = this;
    let update = [];
    Requests.doAction('getoffwork', {
      id:
        'empty&sd=' +
        this.record.headerFields.StartDate +
        '&ed=' +
        this.record.headerFields.EndDate +
        '&timetablenr=' +
        self.id,
    })
      .then((response) => {
        let rec = self.record;
        for (let i = 0; i < response.record.rows.length; i++) {
          for (let j = 0; j < rec.matrixRows.length; j++) {
            if (rec.matrixRows[j].ContractNr === response.record.rows[i].ContractNr) {
              update[j] = {ContractNr: rec.matrixRows[j].ContractNr};
              for (let s = 1; s < 32; s++) {
                update[j]['Day' + s] = '';
                if (response.record.rows[i]['Day' + s] !== '') {
                  //rec.matrixRows[j]['Day' + s] = response.record.rows[i]['Day' + s];
                  update[j]['Day' + s] = response.record.rows[i]['Day' + s];
                }
              }
              //break;
            }
          }
        }
        self.LocalOffWork = update;
        self.updateScreen();
      })
      .catch((error) => {
        logError(error);
      });
  };

  shouldShowMatrixRow = () => {
    let res = true;

    return res;
  };

  SplitMatrixRow = (curRow, prevRow) => {
    let res = [];
    if (prevRow !== undefined && curRow.ContractNr !== prevRow.ContractNr) {
      res.push(<MatrixDivider key={'divider' + curRow.ContractNr + prevRow.ContractNr} />);
    }
    return res;
  };

  renderNewRowButton = () => {
    let self = this;
    if (isMobileOrTabletScreen()) {
      return;
    }
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Contract')}
              name={'Contract'}
              value={this.state.NewRowContract}
              ps={1}
              style={{width: 215}}
              psfilter={{depcode: this.record.headerFields.DepCode}}
              psheader={[
                {
                  name: translate('Code'),
                  width: '35%',
                  key: 'contractnr',
                  mainkey: 'ContractNr',
                },
                {
                  width: '65%',
                  name: translate('Name'),
                  key: 'empname',
                },
              ]}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowContract: value})}
            />
          </FilterField>
          <FilterField>
            <EditField
              title={translate('Type')}
              name={'Type'}
              value={this.state.NewRowType}
              ps={22}
              style={{width: 215}}
              psheader={[
                {
                  name: translate('Code'),
                  width: '35%',
                  key: 'Code',
                  mainkey: 'Code',
                },
                {
                  width: '65%',
                  name: translate('Comment'),
                  key: 'Name',
                },
              ]}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowType: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };
  IsWideScreen = () => {
    let res = false;
    if (global.isDrawerOpen) {
      res = true;
    }
    return res;
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                title={translate('TimeTable')}
                window={this}
                headerFields={headerFields(this)}
                matrixFooterFields={matrixFooterFields(this)}
                record={this.record}
                matrixFields={matrixFields(this, this.IsWideScreen())}
                rowactions={TimeTableRowActions(this)}
                showrow={this.shouldShowMatrixRow}
                onClick={this.handleInputClick}
                splitrow={(row1, row2) => self.SplitMatrixRow(row1, row2)}
                okcheck={'OKFlag'}
                allowDelete={true}
                allowPrint={IsHRWeb()}
                // width="1293px"
                //width="1309px"
                width={this.IsWideScreen() ? '1300px' : '1080px'}
                minwidth={this.IsWideScreen() ? '1100px' : '1050px'}
                maxwidth={this.IsWideScreen() ? '1450px' : '1400px'}
                backButton={() => goBack(self, self.ScreenName)}
              />
              {this.state.newMatrixTableHelper && (
                <Modal transparent={true}>
                  <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
                </Modal>
              )}

              {this.state.activeDelete && (
                <Modal transparent={true}>
                  <YesNoWindow
                    question={translate('RemoveQuestion')}
                    yes={() => this.deleteRecordOld()}
                    no={() => this.setState({activeDelete: false})}
                  />
                </Modal>
              )}
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function TimeTables() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="TimeTablesList"
        component={TimeTablesList}
        options={{
          title: translate('TimeTables'),
        }}
      />
      <Drawer.Screen
        name="TimeTablesRecord"
        component={TimeTablesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('TimeTable'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default TimeTables;
