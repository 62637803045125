import {isMobileOrTabletScreen, WindowElementType} from '@src/tools/displayTools';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {translate} from '@src/utils/languageTools';
import {
  FieldSection,
  PasteSpecialDisplayType,
  PasteSpecialWindowLayout,
  WindowDefinition2,
  WindowMatrix,
} from '@src/utils/types';

export class AddNewContactPersonRecordScreenDefinitions extends RecordScreenWindowDefinition {
  window;

  constructor(window) {
    super();
    this.window = window;
  }

  getInputStyle(isEditable: boolean) {
    return isEditable ? {background: 'white'} : null;
  }

  getSmallFieldSize() {
    if (isMobileOrTabletScreen()) {
      return '100%';
    }
    return '48%';
  }

  getHeaderFields(table: any, fullf = false): WindowDefinition2 {
    let windowDefinition: WindowDefinition2 = {
      editable: true,
      sectionTiles: [
        {
          fieldSections: [
            {
              style: {background: 'none', padding: 0},
              groups: [
                {
                  style: {flex: 1, flexDirection: 'row', flexWrap: 'wrap'},
                  fields: [
                    {
                      label: translate('Name'),
                      dataKey: 'Name',
                      width: '100%',
                      onChange: table.updateRecordField,
                      styleFunc: (isEditable) => this.getInputStyle(isEditable),
                    },
                    {
                      label: translate('Mobile'),
                      dataKey: 'Mobile',
                      width: this.getSmallFieldSize(),
                      onChange: table.updateRecordField,
                      styleFunc: (isEditable) => this.getInputStyle(isEditable),
                      windowElementType: WindowElementType.kInputTypeMobile,
                    },
                    {
                      label: translate('Telephone'),
                      dataKey: 'Phone',
                      width: this.getSmallFieldSize(),
                      onChange: table.updateRecordField,
                      styleFunc: (isEditable) => this.getInputStyle(isEditable),
                      windowElementType: WindowElementType.kInputTypeMobile,
                    },
                    {
                      label: translate('Email'),
                      dataKey: 'eMail',
                      width: this.getSmallFieldSize(),
                      onChange: table.updateRecordField,
                      styleFunc: (isEditable) => this.getInputStyle(isEditable),
                      windowElementType: WindowElementType.kInputTypeEmail,
                    },
                    {
                      label: translate('JobTitle'),
                      dataKey: 'JobDesc',
                      width: this.getSmallFieldSize(),
                      onChange: table.updateRecordField,
                      styleFunc: (isEditable) => this.getInputStyle(isEditable),
                      pasteSpecial: {
                        vcName: 'JobTitleVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Title',
                        columns: [
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'Title',
                            mainKey: 'Title',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
    return windowDefinition;
  }

  getSideSectionFields(table): FieldSection[] {
    return null;
  }

  getMatrixFields(table): WindowMatrix {
    return null;
  }
}
