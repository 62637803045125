import React, {Component} from 'react';
import {Dimensions, Modal} from 'react-native';
import styled, {css} from 'styled-components/native';

import {colours} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {translate} from '@utils/languageTools';
import {WebViewEXC as WebView} from '../../Tools/Stubs';
import {logError} from '@utils/debug';

const WebViewWrap = styled.View`
  background-color: ${colours[GetGlobalTheme()].lightBackground};
  width: 90%;
  height: 90%;
  margin: 0 auto;
  margin-top: 2%;
  align-items: flex-start;
  ${IsHRWeb() === false &&
  css`
    width: 100%;
    height: 100%;
  `}
`;
const WebViewBackground = styled.View`
  background-color: ${colours[GetGlobalTheme()].activeText}1F;
  width: 100%;
  height: 100%;
`;

export default class SigningWindow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.scripts = [];
    this.state = {};
  }

  loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = function () {
        callback();
      };
    }

    script.src = url;
    this.scripts.push(script);
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  SetIFrameActions = () => {
    let self = this;
    if (this.scripts.length === 0) {
      this.loadScript(
        'https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js',
        function () {
          self.loadScript(
            'https://gateway.dokobit.com/js/isign.frame.js',
            function () {
              Isign.onSignSuccess = function () {
                self.props.finish();
              };
              Isign.onSignError = function (errors) {
                //alert(loan_str["ErrorSigning"] + errors.join(', '));
                logError(errors);
              };
              Isign.adjustHeight = false;
            }
          );
        }
      );
    } else {
      self.props.finish();
      self.FinishScripts();
    }
  };

  FinishScripts = () => {
    for (let i = 0; i < self.scripts.length; i++) {
      document.getElementsByTagName('head')[0].removeChild(self.scripts[i]);
    }
    this.scripts = [];
  };

  render() {
    let self = this;
    return (
      <Modal transparent={true}>
        <WebViewBackground>
          <WebViewWrap>
            <ActionButton
              onPress={this.props.finish}
              title={translate('Close')}
            />
            {IsHRWeb() && (
              <iframe
                src={this.props.link}
                style={{
                  height: '100%',
                  width: '100%',
                  border: 'none',
                }}
                onLoad={self.SetIFrameActions}
              />
            )}
            {IsHRWeb() === false && (
              <>
                <WebView
                  source={{uri: this.props.link}}
                  style={{marginTop: 20, width: Dimensions.get('window').width}}
                />
              </>
            )}
          </WebViewWrap>
        </WebViewBackground>
      </Modal>
    );
  }
}
