import React from 'react';
import {ContactPersonsInspectWindow} from './ContactPersonsInspectWindow';
import {WindowRecord} from '@src/utils/types';
import {ContactPersonData} from './ContactPersons';
import {RecordScreenState} from '../../types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {contactPersonRecordScreenDefinition} from './contactPersonsRecordScreenDefinition';

interface ContactsRecordState extends RecordScreenState {}
interface ContactsRecordProps {
  record: ContactPersonData;
  onRefreshList: () => void;
}

export class ContactPersonRecord extends RecordScreen<ContactsRecordProps, ContactsRecordState> {
  constructor(props) {
    super(props);
    this.recordWindowDefinitions = new contactPersonRecordScreenDefinition(this);
    this.ScreenName = 'ContactPerson';
    this.table = 'CUVc';
    this.IDField = 'Code';
    this.state = {
      ...this.state,
    };
    this.record = this.createNewRecord();
    this.record.applyNewValues(props.record);
    this.id = this.record.headerFields.Code;
  }

  sendRecordData = async (mainCallBackFunction: Function) => {
    let callBackFunction = (response, storeClassBack) => {
      mainCallBackFunction(response, storeClassBack);
      this.props.onRefreshList();
    };
    await super.sendRecordData(callBackFunction);
  };

  componentDidUpdate() {}

  componentDidMount() {
    this._isMounted = true;
  }
  createNewRecord = () => {
    return new WindowRecord(
      this.table,
      false,
      this.recordWindowDefinitions.getHeaderFields(this, true),
      null,
      null,
      true
    );
  };
  render() {
    return (
      <ContactPersonsInspectWindow
        record={this.record}
        headerFields={this.recordWindowDefinitions.getHeaderFields(this)}
        window={this}
        onDeleteRefreshList={this.props.onRefreshList}
      />
    );
  }
}
