import React from 'react';
import {colours} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {IconButton} from 'react-native-paper';
import MCIIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import FA5Icon from 'react-native-vector-icons/FontAwesome5';

export const DefaultIconButton = (props) => {
  return (
    <IconButton
      onPress={() => {
        props.action();
      }}
      style={props.style}
      icon={() =>
        props.iconType === 'MCI' ? (
          <MCIIcon
            name={props.iconName}
            size={props.size || 20}
            color={colours[GetGlobalTheme()].darkHighligh}
          />
        ) : (
          <FA5Icon
            name={props.iconName}
            size={props.size || 20}
            color={colours[GetGlobalTheme()].darkHighligh}
            solid
          />
        )
      }
    />
  );
};
