import React from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import FA5Icon from 'react-native-vector-icons/FontAwesome5';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const ButtonContainer = styled.TouchableOpacity`
  elevation: 8;
  background-color: ${colours[GetGlobalTheme()].buttonBackground};
  border-radius: 4px;
  padding-horizontal: ${spacing.medium}px;
  ${isDesktopScreen() &&
  css`
    cursor: pointer;
    height: 35px;
  `}
  ${isMobileOrTabletScreen() &&
  css`
    height: 45px;
  `}

  margin-vertical: ${spacing.small300}px;
  margin-horizontal: ${spacing.small300}px;
  ${(props) => (props.style && props.style.margin ? 'margin-horizontal: ' + props.style.margin : '')};
  ${(props) => (props.style && props.style.marginLeft ? 'margin-left: ' + props.style.marginLeft : '')};
  ${(props) => (props.style && props.style.marginRight ? 'margin-right: ' + props.style.marginRight : '')};
`;

const ButtonText = styled.Text`
  font-family: 'Open Sans';
  color: ${colours[GetGlobalTheme()].buttonText};
  text-align: center;
  text-transform: uppercase;
  ${isDesktopScreen() &&
  css`
    white-space: nowrap;
    line-height: 35px;
    font-size: ${fontSize.small100};
  `}
  ${isMobileOrTabletScreen() &&
  css`
    line-height: 45px;
    font-size: ${fontSize.medium}px;
  `}
`;

export const ActionButton = ({
  onPress,
  title,
  style = {},
  extra = null,
  iconName = null,
  iconSize = 20,
  iconColor = colours[GetGlobalTheme()].darkHighligh,
}) => {
  return (
    <ButtonContainer onPress={onPress} style={style}>
      {iconName === null && <ButtonText>{title}</ButtonText>}
      {extra !== null && <>{extra}</>}
      {iconName && (
        <FA5Icon
          style={{lineHeight: 45, textAlign: 'center'}}
          name={iconName}
          size={iconSize}
          color={iconColor}
          solid
        />
      )}
    </ButtonContainer>
  );
};

const ButtonContainer2 = styled.TouchableOpacity`
  elevation: 8;
  background-color: ${colours[GetGlobalTheme()].buttonBackground};
  border-radius: 4px;
  padding-horizontal: ${spacing.medium}px;
  height: 35px;
  margin-vertical: ${spacing.small300}px;
  margin-horizontal: ${spacing.small300}px;
  width: auto;
`;

const ButtonText2 = styled.Text`
  color: ${colours[GetGlobalTheme()].buttonText};
  text-align: center;
  text-transform: uppercase;
  line-height: 35px;
  font-size: ${fontSize.small100}px;
`;

export const ActionButton2 = ({onPress, title, style}) => {
  return (
    <ButtonContainer2 onPress={onPress} style={style}>
      <ButtonText2>{title}</ButtonText2>
    </ButtonContainer2>
  );
};
