import React, {useState} from 'react';
import {ActionButton} from '@components/button/ActionButton';
import {colours, iconSize, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import styled from 'styled-components/native';
import {ColumnContainer} from '@styles/primitiveComponents';
import {translate} from '@utils/languageTools';

const Container = styled(ColumnContainer)`
  position: relative;
`;

const DropdownChildButton = {
  borderRadius: '18px',
  padding: spacing.space4,
  paddingTop: 0,
  paddingBottom: 0,
  justifyContent: 'start',
};

const ButtonWrapper = styled(ColumnContainer)`
  margin: ${spacing.space12}px;
`;

export const MoreDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function handleMoreButtonClick() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  function handleMyProfileButtonClick() {
    console.log('My Profile not implemented');
  }

  function handleSwitchAccountButtonClick() {
    setIsDropdownOpen(false);
    global.showSwitchAccount({show: true});
  }

  const DropdownButtonIcon = {
    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0)',
  };

  const Dropdown = styled(ColumnContainer)`
    display: ${isDropdownOpen ? 'flex' : 'none'};
    background-color: ${colours[GetGlobalTheme()].darkBlue200};
    width: 200px;
    border-radius: 12px;
    position: absolute;
    top: 100%;
  `;

  return (
    <Container>
      {global.userData.complist.length > 1 ? (
        <ActionButton
          onPress={handleMoreButtonClick}
          title={translate('More')}
          style={{
            height: '35px',
            border: '1px solid ' + colours[GetGlobalTheme()].white,
          }}
          textStyle={{
            marginRight: '4px',
            color: colours[GetGlobalTheme()].white,
          }}
          iconName={'DropdownIcon'}
          iconStyle={DropdownButtonIcon}
          iconSize={iconSize.iconSize}
        />
      ) : (
        <ActionButton
          onPress={handleMyProfileButtonClick}
          title={translate('MyProfile')}
          style={{
            height: '35px',
            border: '1px solid ' + colours[GetGlobalTheme()].white,
          }}
          textStyle={{
            marginRight: '4px',
            color: colours[GetGlobalTheme()].white,
          }}
        />
      )}
      <Dropdown>
        <ButtonWrapper>
          <ActionButton
            onPress={() => console.error('My profile not implemented!')}
            title={translate('MyProfile')}
            style={DropdownChildButton}
            textStyle={{color: colours[GetGlobalTheme()].white}}
            hoverColor={colours[GetGlobalTheme()].darkBlue300}
            backgroundColor={colours[GetGlobalTheme()].darkBlue200}
          />
          <ActionButton
            onPress={handleSwitchAccountButtonClick}
            title={translate('SwitchAccounts')}
            style={DropdownChildButton}
            textStyle={{color: colours[GetGlobalTheme()].white}}
            hoverColor={colours[GetGlobalTheme()].darkBlue300}
            backgroundColor={colours[GetGlobalTheme()].darkBlue200}
          />
        </ButtonWrapper>
      </Dropdown>
    </Container>
  );
};
