import React from 'react';
import {Modal, ScrollView, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {YesNoWindow} from '../status/YesNoWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {browseColumns, headerFields} from './columns/EmployeesColumns';
import {AddWindowFilter, GetWindowFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import CheckboxButton from '../../components/edit_elements/CheckboxButton';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {InputChangeEventProps, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

interface EmployeesListState extends ListScreenState {
  newEmployeeHelper: boolean;
  px: number;
  py: number;
  NewPersonID: string;
  NewEmp: string;
}

export class EmployeesList extends ListScreen<{}, EmployeesListState> {
  constructor(props) {
    super(props);
    this.createNewEmployee = this.createNewEmployee.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.showExtraFilter = this.showExtraFilter.bind(this);

    this.ScreenName = 'Employees';
    this.table = 0;
    this.navigation = props.navigation;

    this.state = {
      ...this.state,
      data: [],
      px: 0,
      py: 0,
      newEmployeeHelper: false,
      NewPersonID: '',
      NewEmp: '',
    };

    SetCurrentWindow2('Employees', 'EmpCode', 'ascending');
    if (global.userData.provider === 0) {
      AddWindowFilter('Employees', 'closed', '0');
    } else {
      AddWindowFilter('Employees', 'closedflag', '0');
    }
  }

  createNewEmployee = () => {
    if (this.state.NewPersonID === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }

    let self = this;
    let tdata = {
      record: {
        PersonIDNr: this.state.NewPersonID,
        EmpTypeFlag: 1,
      },
    };
    Requests.getNewRecord(0, tdata)
      .then((response) => {
        this.state.NewEmp = '';
        self.ShowHideNewEmployee();
        if (UsePagination() === false) {
          self.getBrowseListData();
        }
        self.rowClick(response.record['EmpCode']);
      })
      .catch((error) => {
        logError(error);
      });
  };
  ShowHideNewEmployee = () => {
    if (this.state.newEmployeeHelper) {
      global.PopEscapeActions();
      this.setState({newEmployeeHelper: false});
    } else {
      this.setState({newEmployeeHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewEmployee());
    }
  };

  showExtraFilter = (browsewindow) => {
    let self = this;
    if (isMobileOrTabletScreen()) {
      return;
    }
    if (global.userData.provider === 0) {
      return (
        <View>
          <CheckboxButton
            value={GetWindowFilter('Employees', 'closed') === '0' ? '0' : '1'}
            onChange={({value}: InputChangeEventProps) => {
              if (value === 1) {
                AddWindowFilter('Employees', 'closed', null);
              } else {
                AddWindowFilter('Employees', 'closed', '0');
              }
              if (UsePagination()) {
                self.setState({update: self.state.update ? false : true});
                browsewindow.GetTableData();
              } else {
                self.setState({update: self.state.update ? false : true});
              }
            }}
            style={{marginLeft: 0}}
            editable={true}
            title={translate('ShowActive')}
            title2={translate('ShowAll')}
          />
        </View>
      );
    }
  };

  renderNewButton = () => {
    let self = this;
    let style = {};
    if (global.userData.provider === 1) {
      return;
    }
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    let scrollstyle = {};
    if (IsHRWeb()) {
      scrollstyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      };
    }
    return (
      <View
        key={'employee_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewEmployee()} title={translate('AddNew')} />
        {this.state.newEmployeeHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('PersonID') + '*'}
                    name={'PersonIDNr'}
                    value={this.state.NewPersonID}
                    type={WindowElementType.kInputTypeNumber2}
                    onChange={({value}: InputChangeEventProps) => {
                      self.setState({NewPersonID: value});
                      self.state.NewPersonID = value;
                    }}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewEmployee()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewEmployee()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={browseColumns(this)}
          doClick={this.rowClick}
          extraFilter={this.showExtraFilter}
          rowID={'empcode'}
          title={translate(this.ScreenName)}
          width="800px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface EmployeesRecordState extends RecordScreenState {
  NewPersonID: string;
  newEmployeeHelper: boolean;
}

export class EmployeesRecord extends RecordScreen<{}, EmployeesRecordState> {
  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.ScreenName = 'Employees';
    this.table = 0;
    this.IDField = 'EmpCode';
    this.state = {
      ...this.state,
      newEmployeeHelper: false,
      NewPersonID: '',
    };
    this.navigation = props.navigation;
    this.record = new WindowRecord(0, true, headerFields(this));

    this.CheckOpenRecord();
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(0, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          self.record.applyNewValues(response.record);
          self.updateScreen();
        } else {
          self.updateScreen();
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  render() {
    let self = this;
    return (
      <>
        <WindowWrap>
          <InspectWindow
            key={JSON.stringify(this.record)}
            title={translate('Employee')}
            window={this}
            headerFields={headerFields(this)}
            record={this.record}
            onClick={this.handleInputClick}
            allowDelete={true}
            allowPrint={false}
            backButton={() => goBack(self, self.ScreenName)}
          />
          {this.state.activeDelete && (
            <Modal transparent={true}>
              <YesNoWindow
                question={translate('RemoveQuestion')}
                yes={() => this.deleteRecordOld()}
                no={() => this.setState({activeDelete: false})}
              />
            </Modal>
          )}
        </WindowWrap>
      </>
    );
  }
}

function Employees() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="EmployeesList"
        component={EmployeesList}
        options={{
          title: translate('Employees'),
        }}
      />
      <Drawer.Screen
        name="EmployeesRecord"
        component={EmployeesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('Employee'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default Employees;
