import React from 'react';
import {colours, spacing} from '@styles/Style';
import {ActionButton} from '@components/button/ActionButton';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';

type AccountProps = {
  companyName: string;
  uuid: string;
  onPress: (uuid: string) => void;
  selected: boolean;
};

const Icon = {
  order: -1,
  marginRight: spacing.space12,
};

const CompanyName = {
  color: colours[GetGlobalTheme()].grey800,
};

const Company = ({companyName, uuid, onPress, selected}: AccountProps) => {
  const Container = {
    justifyContent: 'start',
    padding: spacing.space12,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colours[GetGlobalTheme()].grey400,
    boxSizing: 'borderBox',
  };

  const iconName = global.userData.selectedcomp === uuid ? 'CheckmarkIcon' : 'PersonIcon';

  return (
    <ActionButton
      onPress={() => onPress(uuid)}
      title={companyName}
      textStyle={CompanyName}
      iconStyle={Icon}
      iconColor={colours[GetGlobalTheme()].darkBlue}
      style={Container}
      iconName={iconName}
      backgroundColor={selected ? colours[GetGlobalTheme()].grey300 : colours[GetGlobalTheme()].white}
      hoverColor={selected ? colours[GetGlobalTheme()].grey300 : colours[GetGlobalTheme()].grey100}
    />
  );
};

export default Company;
