import APIControl from './APIControl';
import {translate} from '@utils/languageTools';
import {b64toStr2, Strtob64} from '../Tools/Base64Decode';
import {Buffer} from 'buffer';

function EncodePortalString(tstr) {
  let t = tstr;
  if (typeof tstr === 'number') {
    t = tstr.toString();
  }
  let res = Buffer.from(t, 'utf-8').toString('base64');
  res = res.split('').reverse().join('');
  return res;
}

export function DecodePortalString(tstr) {
  let res = tstr.toString().split('').reverse().join('');
  res = b64toStr2(res);
  return res;
}
type getTableDataProps = {
  type: string | number;
  filters?: any;
  fields?: any;
  search?: string;
  mainclick?: boolean;
  page?: number;
  perpage?: number;
  sortkey?: string;
  order?: string;
  browse_search?: string;
  sd?: string;
  ed?: string;
  bfilter?: string;
  extraParams: any;
};

export function getTableData2({
  type,
  filters = {},
  fields = undefined,
  search = undefined,
  mainclick = false,
  page = undefined,
  perpage = undefined,
  sortkey = undefined,
  order = undefined,
  browse_search = undefined,
  sd = undefined,
  ed = undefined,
  bfilter = undefined,
  extraParams = undefined,
}: getTableDataProps) {
  let url = '/WebHRAction.hal?action=getdata&type=' + EncodePortalString(type);
  let click = '';
  if (mainclick) {
    click = '1';
  }
  return APIControl({
    url: url,
    method: 'POST',
    data: {
      filters,
      fields,
      search,
      click,
      page,
      perpage,
      sortkey,
      order,
      browse_search,
      sd,
      ed,
      bfilter,
      ...extraParams,
    },
  });
}

export function getTableData(
  type,
  filters = {},
  fields = undefined,
  search = undefined,
  mainclick = false,
  page = undefined,
  perpage = undefined,
  sortkey = undefined,
  order = undefined,
  browse_search = undefined,
  sd = undefined,
  ed = undefined,
  bfilter = undefined
) {
  let url = '/WebHRAction.hal?action=getdata&type=' + EncodePortalString(type);
  let click = '';
  if (mainclick) {
    click = '1';
  }
  return APIControl({
    url: url,
    method: 'POST',
    data: {
      filters,
      fields,
      search,
      click,
      page,
      perpage,
      sortkey,
      order,
      browse_search,
      sd,
      ed,
      bfilter,
    },
  });
}

function postRecord(type, content) {
  return APIControl({
    url: '/WebHRAction.hal?action=postdata&type=' + EncodePortalString(type),
    method: 'POST',
    data: {
      content,
    },
  });
}

function doWindowAction(vcname, id, record, fieldname = '', value = '', rowindex = -1) {
  let data = {
    id: id,
    vcname: vcname,
    values: {header: record.headerFields, rows: record.matrixRows},
    headerfields: record.getHeaderFieldList(),
    rowfields: record.getMatrixFieldList(),
    fieldname: fieldname,
    value: value,
    rowindex: rowindex,
  };
  return APIControl({
    url: '/WebHRAction.hal?action=windowaction',
    method: 'POST',
    data: EncodePortalString(JSON.stringify(data)),
  });
}

function getActiveEditFields(vcname, id, record) {
  let data = {
    id: id,
    vcname: vcname,
  };
  return APIControl({
    url: '/WebHRAction.hal?action=activeeditfields',
    method: 'POST',
    data: EncodePortalString(JSON.stringify(data)),
  });
}

function getReport(options) {
  let tstr = Strtob64(JSON.stringify(options));
  return APIControl({
    url: '/WebHRAction.hal?action=getreport',
    method: 'POST',
    data: tstr,
  });
}

function getNewRecord(type, content) {
  return APIControl({
    url: '/WebHRAction.hal?action=postdata&type=' + EncodePortalString(type),
    method: 'POST',
    data: content,
    startText: translate('CreatingRecord'),
    endText: ' ',
    displayMessage: true,
  });
}

function doAction(type, content, extras = undefined) {
  return APIControl({
    url: '/WebHRAction.hal?action=action&type=' + type,
    method: 'POST',
    data: {
      content,
      extras,
    },
  });
}

function updateRecordData(type, content, displayMessage = true) {
  return APIControl({
    url: '/WebHRAction.hal?action=postdata&type=' + EncodePortalString(type),
    method: 'POST',
    data: content,
    startText: translate('UpdatingRecord'),
    displayMessage: displayMessage,
  });
}

//can be used for update and creating new records in one call
function updateRecordData2(type, content, displayMessage = true) {
  return APIControl({
    url: '/WebHRAction.hal?action=postdata2&type=' + EncodePortalString(type),
    method: 'POST',
    data: content,
    startText: translate('UpdatingRecord'),
    displayMessage: displayMessage,
  });
}

function deleteRecordRow(type, id) {
  return APIControl({
    url: '/WebHRAction.hal?action=deleterow&type=' + EncodePortalString(type) + '&id=' + id,
    method: 'POST',
    startText: translate('UpdatingRecord'),
  });
}

function insertRecordRow(type, id) {
  return APIControl({
    url: '/WebHRAction.hal?action=insertrow&type=' + EncodePortalString(type) + '&id=' + id,
    method: 'POST',
  });
}

function updatePassword(passw1, passw2, session, cc) {
  const postData = {
    passw1: passw1,
    passw2: passw2,
  };
  const formData = new URLSearchParams(postData).toString();
  return APIControl({
    url: '/WebUpdatingAction.hal?action=updatepasssess&sess=' + session + '&cc=' + cc,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

function createPassword(passw1, passw2, session, cc) {
  const postData = {
    passw1: passw1,
    passw2: passw2,
  };
  const formData = new URLSearchParams(postData).toString();
  return APIControl({
    url: '/WebUpdatingAction.hal?action=createnewaccountsess&sess=' + session + '&cc=' + cc,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

function deleteRecord(type, id) {
  return APIControl({
    url: '/WebHRAction.hal?action=deleterecord&type=' + EncodePortalString(type) + '&id=' + id,
    method: 'GET',
    startText: translate('DeletingRecord'),
    endText: translate('SuccessfullyDeletedRecord'),
  });
}

function deleteRecordOld(type, id) {
  return APIControl({
    url: '/WebHRAction.hal?action=deleterecord&type=' + EncodePortalString(type) + '&id=' + id,
    method: 'GET',
    startText: translate('DeletingRecord'),
    endText: translate('SuccessfullyDeletedRecord'),
    displayMessage: true,
  });
}

function downloadDocument(type, content) {
  return APIControl({
    url: '/WebHRAction.hal?action=downloaddoc&type=' + EncodePortalString(type),
    method: 'POST',
    data: content,
    startText: translate('RequestingFile'),
  });
}

function getEmployeeData() {
  return APIControl({
    url: '/WebHRAction.hal?action=getuserdata',
    method: 'GET',
  });
}

function logout() {
  return APIControl({
    url: '/WebUpdatingAction.hal?action=logout',
    method: 'GET',
  });
}

function switchCompany(newcomp) {
  return APIControl({
    url: '/WebHRAction.hal?action=switchcomp&compuuid=' + newcomp,
    method: 'GET',
  });
}

function TestLogin(username, password) {
  return APIControl({
    url: '/dologin',
    method: 'POST',
    data:
      'login=' +
      username +
      '&passwd=' +
      password +
      '&company=1&failed=' +
      encodeURIComponent('/WebUpdatingAction.hal?action=afterlogin&failed=1') +
      '&custpage=' +
      encodeURIComponent('/WebUpdatingAction.hal?action=afterlogin&cust=1'),
  });
}

function testConnection() {
  return APIControl({
    url: '/WebHRAction.hal?action=dummy',
    method: 'GET',
  });
}

function ResetLogin() {
  return APIControl({
    url: '/WebHRAction.hal?action=resetlogin',
    method: 'GET',
  });
}

function ResetPassword(email) {
  return APIControl({
    url: '/WebUpdatingAction.hal?action=createnewpasssess&email=' + email,
    method: 'GET',
  });
}

function CreateAccount(email) {
  return APIControl({
    url: '/WebUpdatingAction.hal?action=createaccount&email=' + email,
    method: 'GET',
  });
}

function getWindowDefs(language) {
  return APIControl({
    url: '/WebHRAction.hal?action=getwindowdefs&langcode=' + language,
    method: 'GET',
  });
}

function CheckAccessToCompany(username, password) {
  const postData = {
    login: username,
    passwd: password,
  };
  const formData = new URLSearchParams(postData).toString();
  return APIControl({
    url: '/WebHRAction.hal?action=checkuseraccess',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

const Requests = {
  getTableData,
  getTableData2,
  postRecord,
  logout,
  getEmployeeData,
  getNewRecord,
  updateRecordData,
  updateRecordData2,
  deleteRecord,
  downloadDocument,
  switchCompany,
  deleteRecordRow,
  testConnection,
  doAction,
  TestLogin,
  insertRecordRow,
  ResetPassword,
  getReport,
  ResetLogin,
  getWindowDefs,
  CheckAccessToCompany,
  CreateAccount,
  doWindowAction,
  updatePassword,
  createPassword,
  deleteRecordOld,
  getActiveEditFields,
};

export default Requests;
