import React from 'react';
import styled from 'styled-components/native';
import {TextNormalBold} from '@src/styles/primitiveComponents';
import {colours} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';

export const enum AlertType {
  Error,
  Warning,
  Success,
  Info,
}

type AlertContainerProps = {
  message: string;
  width: string | number;
  alertType: AlertType;
  show?: boolean;
  alignment?: 'left' | 'center' | 'right';
};

const AlertContainer = ({message, width, alertType, show = true, alignment = 'center'}: AlertContainerProps) => {
  let accentColor = '';
  let backgroundColor = '';

  switch (alertType) {
    case AlertType.Error:
      accentColor = colours[GetGlobalTheme()].error;
      backgroundColor = colours[GetGlobalTheme()].errorLight;
      break;
    case AlertType.Warning:
      accentColor = colours[GetGlobalTheme()].warning;
      backgroundColor = colours[GetGlobalTheme()].warningLight;
      break;
    case AlertType.Success:
      accentColor = colours[GetGlobalTheme()].success;
      backgroundColor = colours[GetGlobalTheme()].successLight;
      break;
    case AlertType.Info:
      accentColor = colours[GetGlobalTheme()].info;
      backgroundColor = colours[GetGlobalTheme()].infoLight;
      break;
    default:
      console.error('Alert type does not exist!');
  }

  const MessageContainer = styled.View`
    width: ${width};
    background-color: ${backgroundColor};
    border-style: solid;
    border-width: 0;
    border-left-width: 4px;
    border-color: ${accentColor};
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    min-height: 53px;
    visibility: ${show ? 'visible' : 'hidden'};
  `;

  const TextContainer = styled.View`
    width: 100%;
    align-items: ${alignment};
    padding: 10px;
  `;

  return (
    <MessageContainer>
      <TextContainer>
        <TextNormalBold style={{color: accentColor}}>{message}</TextNormalBold>
      </TextContainer>
    </MessageContainer>
  );
};

export default AlertContainer;
