import React from 'react';
import styled, {css} from 'styled-components/native';
import EditField from './EditField';
import CheckboxButton from './CheckboxButton';
import {ActionButton} from '../button/ActionButton';
import {isApproved} from '../../utils/helpers';
import {WindowElementType, IsHRiOS} from '../../Tools/DisplayTools';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {ButtonField, CheckboxField, TextField, WindowFieldLine} from '@utils/types';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const HeaderFields = styled.View`
  display: flex;
  ${isDesktopScreen() &&
  css`
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

const FieldGroup = styled.View`
  display: flex;
  ${isDesktopScreen() &&
  css`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
  `}
  width: 100%;
`;

export function WindowElementTypeMatch(fieldtype: WindowElementType | undefined, check_against: WindowElementType) {
  return fieldtype !== undefined && fieldtype === check_against;
}

export function isCheckboxField(field: CheckboxField | TextField | ButtonField): field is CheckboxField {
  return !!field && WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeCheckbox);
}
export function isTextField(field: CheckboxField | TextField | ButtonField): field is TextField {
  return (
    !!field &&
    WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeButton) === false &&
    WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeCheckbox) === false
  );
}
export function isButtonField(field: CheckboxField | TextField | ButtonField): field is ButtonField {
  return !!field && WindowElementTypeMatch(field.windowElementType, WindowElementType.kInputTypeButton);
}

function ShowSingleEditField(field: CheckboxField | TextField | ButtonField, index: number, props) {
  let val: string;
  if (isButtonField(field) === false) {
    val = props.record.headerFields[field.dataKey];
  }
  if (isTextField(field)) {
    //TODO: we should do the check against interface somehow
    if (field.fixedValue !== undefined) {
      val = field.fixedValue;
    }
  }
  if ('matrixField' in field && field.matrixField) {
    if (props.record.matrixRows && props.record.matrixRows.length > 0) {
      let tval = [];
      for (let i = 0; i < props.record.matrixRows.length; i++) {
        tval.push(props.record.matrixRows[i][field.dataKey]);
      }
      val = tval.join(',');
    }
  }
  switch (true) {
    case isCheckboxField(field):
      return (
        <CheckboxButton
          key={field.dataKey}
          name={field.dataKey}
          title={field.label}
          title2={field.checkboxInactiveLabel}
          tooltip={field.checkboxTooltip}
          value={val}
          editable={!field.viewOnly && isApproved(props.record, props.okcheck, field.dataKey) === false}
          onChange={field.onChange}
        />
      );
    case isButtonField(field): {
      let w = field.width;
      if (w) {
        if (w.indexOf('px') > 0) {
          w = parseInt(w);
        }
        if (isMobileOrTabletScreen()) {
          w = '100%';
        }
      }
      return (
        <ActionButton
          onPress={() => {
            if (field.onPress) {
              field.onPress();
            }
          }}
          style={{
            width: w,
            ...field.style,
            textAlign: 'center',
          }}
          title={field.label}
        />
      );
    }
    default:
      if (field.windowElementType === WindowElementType.kInputTypeNone) {
        return;
      }
      let wrapperStyle = {};
      if (isDesktopScreen()) {
        wrapperStyle.paddingLeft = 0;
      }
      if (isTextField(field)) {
        return (
          // can just pass field as prop, so then won't have to create so many props
          <EditField
            wrapperStyle={wrapperStyle}
            key={field.dataKey}
            title={field.label}
            value={val}
            record={props.record}
            pasteSpecial={field.pasteSpecial}
            reference={field.reference}
            name={field.dataKey}
            matrixfield={field.matrixField}
            linkedfield={field.linkedField}
            onChange={field.onChange}
            onFocus={field.onFocus}
            width={field.width}
            barcode={field.windowElementType === WindowElementType.kInputTypeBarCode}
            /*viewOnly={field.viewOnly} we have editable and will be using it*/
            row={field.row}
            allowClearing={field.allowClearing}
            editable={field.viewOnly === false && isApproved(props.record, props.okcheck, field.dataKey) === false}
            type={field.windowElementType ? field.windowElementType : WindowElementType.kInputTypeText}
            onClick={() => props.onClick()}
            inputStyle={field.style}
          />
        );
      }
  }
}

const FieldSection = (props) => {
  return (
    <HeaderFields style={props.style}>
      {props.fields.map(function (fieldline: WindowFieldLine, lineindex: number) {
        //if (objectHasProperty(field, 'group')) {
        let styles: any = {};
        if (objectHasProperty(fieldline, 'styles')) {
          styles = fieldline.style;
        }
        if (IsHRiOS() === false && props.fields) {
          styles.zIndex = props.fields.length - lineindex;
        }
        return (
          <FieldGroup key={lineindex} style={styles}>
            {fieldline.fields.map(function (field_inner, index) {
              return ShowSingleEditField(field_inner, index, props);
            })}
          </FieldGroup>
        );
        /*
        } else {
          return ShowSingleEditField(field, index, props);
        }
        */
      })}
    </HeaderFields>
  );
};

export default FieldSection;
