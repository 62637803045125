import React from 'react';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {
  FieldSection,
  PasteSpecialDisplayType,
  PasteSpecialWindowLayout,
  WindowDefinition2,
  WindowMatrix,
} from '@utils/types';
import {translate} from '@utils/languageTools';
import {DoNavigate, WindowElementType} from '@tools/displayTools';
import {ConfirmButton} from '@src/components/button/confirmButton';
import {displayDualDates, displayDualTimes, displayTaskType} from '@src/tools/tasksDisplayTools';
import {ViewMode} from '../types';

export class TasksRecordScreenDefinition extends RecordScreenWindowDefinition {
  getSideSectionFields(table: any): FieldSection[] {
    let sections: FieldSection[];
    sections = [];
    return sections;
  }

  getHeaderFields(table: any, fullf = false): WindowDefinition2 {
    const self = this;
    let windowDefinition: WindowDefinition2;

    windowDefinition = {
      startViewComponent: () =>
        table.record && table.allowsEditAndDelete() ? <ConfirmButton table={table} title={translate('Done')} /> : null,
      editable: table.record ? table.allowsEditAndDelete() : false,
      sectionTiles: [
        {
          fieldSections: [
            {
              label: translate('TaskInformation'),
              collapse: true,
              groups: [
                {
                  fields: [
                    {
                      label: translate('ActType'),
                      windowElementType: WindowElementType.kInputTypeText,
                      dataKey: 'TodoFlag',
                      viewOnly: false,
                      overwrite: ({record}) => displayTaskType(record),
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Title'),
                      windowElementType: WindowElementType.kInputTypeText,
                      dataKey: 'Comment',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Date'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.View
                          ? WindowElementType.kInputTypeText
                          : WindowElementType.kInputTypeNone,
                      overwrite: ({record}) => displayDualDates(record),
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Date'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.Edit
                          ? WindowElementType.kInputTypeDate
                          : WindowElementType.kInputTypeNone,
                      dataKey: 'TransDate',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Date'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.Edit
                          ? WindowElementType.kInputTypeDate
                          : WindowElementType.kInputTypeNone,
                      dataKey: 'EndDate',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Time'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.View
                          ? WindowElementType.kInputTypeText
                          : WindowElementType.kInputTypeNone,
                      viewOnly: false,
                      overwrite: ({record}) => displayDualTimes(record),
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Time'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.Edit
                          ? WindowElementType.kInputTypeText
                          : WindowElementType.kInputTypeNone,
                      dataKey: 'StartTime',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Time'),
                      windowElementType: (viewMode: ViewMode) =>
                        viewMode === ViewMode.Edit
                          ? WindowElementType.kInputTypeText
                          : WindowElementType.kInputTypeNone,
                      dataKey: 'EndTime',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('AssignedTo'),
                      dataKey: 'MainPersons',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'UserVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'code',
                        toolTip: {hasToolTip: true, key: 'name', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'name',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('Cc'),
                      dataKey: 'CCPersons',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'UserVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'code',
                        toolTip: {hasToolTip: true, key: 'name', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'name',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  fields: [
                    {
                      label: translate('Project'),
                      windowElementType: WindowElementType.kInputTypeText,
                      dataKey: 'PRCode',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Contact'),
                      windowElementType: WindowElementType.kInputTypeText,
                      dataKey: 'CUCode',
                      viewOnly: false,
                      pasteSpecial: {
                        vcName: 'CUVc',
                        newRecord: true,
                        windowLayout: PasteSpecialWindowLayout.Default,
                        useAlternateDisplay: true,
                        recordLinkFunction: () => DoNavigate(table, 'Contacts', true, table.record.headerFields.CUCode),
                        viewModeDisplayFormat: '{name} ({code})',
                        mainKey: 'code',
                        nameDisplayFormat: '{name} ({code})',
                        columns: [
                          {
                            name: translate('CustomerName') + ' ' + translate('Code'),
                            width: '70%',
                            key: 'name',
                            mainKey: 'Code',
                            displayFormat: '{name} ({code})',
                          },
                          {
                            width: '30%',
                            name: translate('RegNr'),
                            key: 'regnr1',
                          },
                        ],
                        pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
                      },
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Telephone'),
                      windowElementType: WindowElementType.kInputTypeMobile,
                      dataKey: 'Phone',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('ContactPerson'),
                      windowElementType: WindowElementType.kInputTypeText,
                      dataKey: 'Contact',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                    },
                  ],
                },
              ],
            },
            {
              label: translate('Description'),
              collapse: true,
              groups: [
                {
                  fields: [],
                },
              ],
            },
            {
              label: translate('Comments'),
              collapse: true,
              groups: [
                {
                  fields: [],
                },
              ],
            },
          ],
        },
      ],
    };

    return windowDefinition;
  }

  getMatrixFields(table: any): WindowMatrix {
    let windowMatrix: WindowMatrix = [];
    return windowMatrix;
  }

  getHiddenFields(): Array<string> {
    return ['PriceList', 'RebCode'];
  }

  isEmptyNewRecord(fullf, table) {
    return fullf == false && table.isNewRecord() && table.recordFieldFilled('CustCode') === false;
  }
}
