import React from 'react';
import {dateStringToTimestamp, getCurrentDate} from '@tools/dateTools';
import {colours} from '@styles/Style';
import {ConvertDateField, GetGlobalTheme} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import {RowContainer, TextNormal} from '@styles/primitiveComponents';
import ToolTip from '@components/design/ToolTip';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import {View, ViewStyle} from 'react-native';

const IconWrapperStyle: ViewStyle = {
  width: '20%',
  justifyContent: 'center',
  alignItems: 'center',
};

const StatusContainerStyle: ViewStyle = {
  paddingHorizontal: 8,
  paddingVertical: 4,
  borderRadius: 50,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 4,
};
  // TODO: We need to add correct colours from backend when we will get them (Text as well? Borders?)
  const taskTypeStyles = {
    '0': {backgroundColor: colours[GetGlobalTheme()].blue100}, // kTodoFlagCalendar
    '1': {backgroundColor: colours[GetGlobalTheme()].green100}, // kTodoFlagTodo
    '2': {backgroundColor: colours[GetGlobalTheme()].red100}, // kTodoFlagTimedTodo
    '4': {backgroundColor: colours[GetGlobalTheme()].purple100}, // kTodoFlagWorkHours
    '5': {backgroundColor: colours[GetGlobalTheme()].orange100}, // kTodoFlagOther
    '6': {backgroundColor: colours[GetGlobalTheme()].yellow100}, // kTodoFlagApproval
    '8': {backgroundColor: colours[GetGlobalTheme()].pink100}, // kTodoFlagProject
  };

export function displayOverdueDate(date, okflag) {
  let dateDisplayJSX = null;

  const today = getCurrentDate().setHours(0, 0, 0, 0);
  const taskDate = dateStringToTimestamp(date);

  const isOverdue = taskDate < today;
  const isToday = taskDate === today;
  const overdueDays = Math.abs(Math.floor((today - taskDate) / (1000 * 60 * 60 * 24)));

  let textColour;
  if (okflag === '1') {
    textColour = colours[GetGlobalTheme()].tableBody;
  } else if (isOverdue) {
    textColour = colours[GetGlobalTheme()].error;
  } else if (isToday) {
    textColour = colours[GetGlobalTheme()].success;
  } else {
    textColour = colours[GetGlobalTheme()].tableBody;
  }

  const daySuffix = overdueDays === 1 ? translate('Day') : translate('Days');

  if (isOverdue && okflag !== '1') {
    dateDisplayJSX = (
      <ToolTip tooltipText={`${overdueDays} ${daySuffix} ${translate('Overdue')}`} offsetX={-10}>
        <RowContainer style={IconWrapperStyle}>
          <PortalIcon iconName={'RedExclamationMarkIcon'} iconHeight={12} />
          <TextNormal style={{color: textColour}}>{date}</TextNormal>
        </RowContainer>
      </ToolTip>
    );
  } else {
    dateDisplayJSX = <TextNormal style={{color: textColour}}>{date}</TextNormal>;
  }

  return dateDisplayJSX;
}

export function displayTaskStatus(activityTypeComment, type) {
  let statusJSX = null;

  if (type === 'Approval') {
    statusJSX = (
      <View style={[StatusContainerStyle, {backgroundColor: colours[GetGlobalTheme()].yellow100}]}>
        <TextNormal style={{color: colours[GetGlobalTheme()].yellowMedium}}>{activityTypeComment}</TextNormal>
      </View>
    );
  } else {
    statusJSX = (
      <View
        style={[
          StatusContainerStyle,
          {backgroundColor: colours[GetGlobalTheme()].grey200, borderColor: colours[GetGlobalTheme()].grey400},
        ]}>
        <TextNormal style={{color: colours[GetGlobalTheme()].label}}>
          {activityTypeComment.length > 20 ? `${activityTypeComment.slice(0, 20)}...` : activityTypeComment}
        </TextNormal>
      </View>
    );
  }
  return statusJSX;
}

export function displayDualDates(record) {
  let datesJSX = null;
  const transDate = ConvertDateField(record.headerFields?.TransDate);
  const endDate = ConvertDateField(record.headerFields?.EndDate);

  if (transDate === endDate) {
    datesJSX = transDate;
  } else {
    datesJSX = (
      <RowContainer>
        <TextNormal>{`${transDate} - ${endDate}`}</TextNormal>
      </RowContainer>
    );
  }

  return datesJSX;
}

export function displayDualTimes(record) {
  let timesJSX = null;
  const startTime = record.headerFields?.StartTime;
  const endTime = record.headerFields?.EndTime;

  if (startTime === endTime || endTime === '') {
    timesJSX = startTime;
  } else {
    timesJSX = (
      <RowContainer>
        <TextNormal>{`${startTime} - ${endTime}`}</TextNormal>
      </RowContainer>
    );
  }

  return timesJSX;
}

export function displayTaskType(record) {
  const todoflag = record.headerFields?.TodoFlag;
  const activityTypeComment = record.headerFields?.ActTypeComment;

  if (!activityTypeComment) {
    return <TextNormal>{todoflag}</TextNormal>;
  }

  const containerStyle = {
    backgroundColor: colours[GetGlobalTheme()].grey200,
    ...taskTypeStyles[todoflag],
  };

  const displayText = activityTypeComment.length > 20 ? `${activityTypeComment.slice(0, 20)}...` : activityTypeComment;

  return (
    <View style={[StatusContainerStyle, containerStyle]}>
      <TextNormal style={{color: colours[GetGlobalTheme()].label}}>{displayText}</TextNormal>
    </View>
  );
}
