import React, {useState} from 'react';
import styled from 'styled-components/native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../../Tools/DisplayTools';
import {ActionButton} from '@components/button/ActionButton';
import {MultiEntries} from '@components/editElements/pasteSpecial/pasteSpecialName/PasteSpecialName';
import {ToolTipText} from '@components/design/ToolTipText';
import {isMobileOrTabletScreen} from '@tools/displayTools';

const BubbleWrap = styled.View`
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 50px;
  margin-right: ${spacing.space4}px;
  padding: ${spacing.space4}px;
`;

const RemoveButtonIconStyle = {
  order: 2,
};

const RemoveButtonStyle = {
  backgroundColor: 'invisible',
  width: 14,
  height: 14,
  padding: 0,
  marginBottom: 0,
  marginTop: 0,
};

type PasteSpecialNameMultiEntryProps = {
  entry: MultiEntries;
  onCrossPress: (entryName) => void;
  maxCharacters: number;
};

export const PasteSpecialNameMultiEntry: React.FC<PasteSpecialNameMultiEntryProps> = ({
  entry,
  onCrossPress,
  maxCharacters,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  function getBackgroundColor() {
    if (isMobileOrTabletScreen()) {
      return colours[GetGlobalTheme()].infoLight;
    }
    return isHovering ? colours[GetGlobalTheme()].infoLight : colours[GetGlobalTheme()].white;
  }

  const BubbleWrapStyle = {
    backgroundColor: getBackgroundColor(),
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 50,
    margin: 2,
    marginRight: 0,
    padding: spacing.space2,
  };

  return (
    <BubbleWrap
      // @ts-ignore
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      // @ts-ignore
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={BubbleWrapStyle}>
      <ToolTipText innerText={entry.entryName} toolTipText={entry.entryDescription} maxCharacters={maxCharacters} />
      <ActionButton
        onPress={() => onCrossPress(entry.entryName)}
        title={''}
        iconName={'CrossIcon'}
        style={RemoveButtonStyle}
        iconStyle={RemoveButtonIconStyle}
        iconColor={colours[GetGlobalTheme()].primary}
        iconSize={8}
      />
    </BubbleWrap>
  );
};
