import React from 'react';
import styled, {css} from 'styled-components/native';
import {LangColorContext} from '../../App';
import {translate} from '@utils/languageTools';
import DropdownSelect from '../edit_elements/DropdownSelect';
import {colours, spacing} from '../../styles/Style';
import {
  GetGlobalTheme,
  IsHRAndroid,
  IsHRiOS,
  IsHRWeb,
} from '../../Tools/DisplayTools';

const LanguageWrap = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${IsHRWeb() &&
  css`
    margin-horizontal: ${spacing.medium};
  `}
  ${IsHRiOS() &&
  css`
    padding-bottom: 15px;
  `}
  ${IsHRWeb() === false &&
  css`
    border-color: ${colours[GetGlobalTheme()].darkHighligh};
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
  `}
`;

const LanguageSelect = (props) => {
  const languages = [
    {code: 'lv', name: translate('latvian')},
    {code: 'en', name: translate('english')},
    {code: 'ee', name: translate('estonian')},
  ];
  let width = 100;
  if (IsHRAndroid()) {
    width = 180;
  }

  return (
    <LangColorContext.Consumer>
      {(data) => (
        <LanguageWrap style={props.style}>
          <DropdownSelect
            value={data.language}
            data={languages}
            width={width}
            action={(itemValue) => global.ToggleLanguage(itemValue)}
          />
        </LanguageWrap>
      )}
    </LangColorContext.Consumer>
  );
};

export default LanguageSelect;
