import {isSafariBrowser} from '@src/tools/displayTools';
import React, {useEffect, useRef} from 'react';
import {b64toBlob} from 'src/Tools/Base64Decode';

type PDFViewerProps = {
  base64: string;
  style: React.CSSProperties;
};

export const PDFViewer: React.FC<PDFViewerProps> = ({base64, style}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  function setIframeSrc(base64: string) {
    const blob = b64toBlob(base64, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);

    if (iframeRef.current) {
      iframeRef.current.src = blobUrl;
      if (isSafariBrowser()) {
        const iframe = iframeRef.current;

        iframe.onload = () => {
          const iframeDocument = iframe.contentWindow?.document;
          if (iframeDocument) {
            const img = iframeDocument.getElementsByTagName('img')[0];
            if (img) {
              img.style.width = '100%'; // Adjust the image width inside the iframe
            }
          }
        };
      }
    }

    return () => {
      URL.revokeObjectURL(blobUrl);
    };
  }

  useEffect(() => {
    const cleanup = setIframeSrc(base64);
    return cleanup;
  }, [base64]);

  return <iframe ref={iframeRef} style={style}></iframe>;
};
