/*
const colours = {
  blue100: '#AEAECF',
  blue150: '#A0ABD1',
  blue300: '#5D6684',
  blue700: '#282F46',
  blue750: '#212A42',
  blue800: '#1D2539',
  pink400: '#D67272',
  lightBackground: '#202a40',
  buttonBackground: '#d57272',
  buttonBorder: '#299aaa',
  buttonText: '#FFFFFF',
  titleText: '#7880A7',
  rowText: '#AAB7E5',
};
*/

/*
EEEEEE
  navigationBackground: '#DCEEF7',
  mainBackground: '#DCEEF7',
  lightBackground: '#FFFFFF',

*/

const opacity = {
  small2: '10', //
  small: '1F', // 12%
  large: 'CC', // 90%
};

const colourChart = {
  // dark blues
  // darkBlue900: '#001E45',
  // darkBlue850: '#0D2850',
  // darkBlue800: '#1A315B',
  // darkBlue700: '#334470',
  // darkBlue600: '#606E9E',
  // darkBlue500: '#8E9BCE',
  // darkBlue400: '#BECBFF',
  deepDarkBlue600: '#000c1c',
  deepDarkBlue400: '#001229',
  deepDarkBlue300: '#001530',
  deepDarkBlue200: '#001837',
  darkBlue900: '#001E45',
  darkBlue850: '#0D2A4F',
  darkBlue800: '#1a3558',
  darkBlue700: '#334b6a',
  darkBlue600: '#4d627d',
  darkBlue500: '#66788f',
  darkBlue400: '#808fa2',
  darkBlue300: '#99a5b5',
  darkBlue200: '#b3bcc7',
  darkBlue100: '#ccd2da',
  darkBlue000: '#e6e9ec',
  // darkBlue000: '#e8ebee',
  darkBlue0000: '#eaedf0',
  // blues
  blue900: '#0092D3',
  blue800: '#1a9dd7',
  blue700: '#33a8dc',
  blue600: '#4db3e0',
  blue500: '#66bee5',
  blue400: '#80c9e9',
  blue300: '#99d3ed',
  blue200: '#b3def2',
  blue100: '#cce9f6',
  blue000: '#e6f4fb',
  // greys
  // grey900: '#D4D4D4',
  // grey900: '#D4D4D4',
  // grey800: '#E1E1E1',
  // grey700: '#EEEEEE',
  // grey200: '#F7F7F7',
  // grey100: '#FBFBFB',
  // grey000: '#FFFFFF',
  grey900: '#D4D4D4',
  grey800: '#d8d8d8',
  grey700: '#dddddd',
  grey600: '#e1e1e1',
  grey500: '#e5e5e5',
  grey400: '#eaeaea',
  grey300: '#eeeeee',
  grey200: '#f2f2f2',
  grey100: '#f6f6f6',
  grey000: '#fbfbfb',
  // pinks
  pink900: '#AA2A80',
  pink000: '#f7eaf2',
};

// const greys = {

// }

// const pinks = {

const colours: any = {
  light: {
    activeText: colourChart.blue900,
    activeBackground: colourChart.darkBlue900 + opacity.small,
    activeMatrixBackground: colourChart.darkBlue900 + opacity.small2,
    // activeText: colourChart.pink900,
    // activeBackground: colourChart.pink900 + opacity.small,
    normalHighligh: '#5D6684',
    darkHighligh: '#081D42',
    background1: '#212A42',
    background2: '#1D2539',
    buttonBackground: '#081D42',
    buttonBackgroundInactive: 'rgba(8,29,66,0.4)',
    buttonBorder: '#299aaa',
    buttonText: colourChart.grey000,
    titleText: '#081D42',
    rowText: '#081D42',
    siteBackground: colourChart.grey000,
    topNavigationBackground: colourChart.blue000,
    navigationBackground: colourChart.blue000,
    mainBackground: colourChart.grey000,
    tableHeader: colourChart.blue000,
    // tableBackground: colourChart.darkBlue0000,
    tableBackground: colourChart.grey200,
    lightBackground: colourChart.grey400,
    inputBackground: colourChart.grey000,
    rowInputBackground: colourChart.darkBlue000,
    inputText: '#081D42',
    inputLabelText: '#081D42',
    inputBorder: colourChart.darkBlue900,
    inactiveTitleText: 'rgba(8, 29, 66, 0.5)',
    buttonBackgroundAlt: '#DCEEF7',
    background: colourChart.grey000,
    border: colourChart.darkBlue900,
    backgroundAlt: colourChart.blue000,
    borderAlt: colourChart.blue000,
    text: '#081D42',
    calendarDay: '#eaebea',
    calendarDayText: '#777',
    calendarBorder: 'white',
  },
  dark: {
    activeText: '#0092D3',
    activeBackground: 'rgba(0, 146, 211, 0.12)',
    activeMatrixBackground: 'rgba(0, 146, 211, 0.09)',
    normalHighligh: '#5D6684',
    darkHighligh: '#d4d4d4',
    background1: '#212A42',
    background2: '#1D2539',
    buttonBackground: '#AA2A80',
    buttonBackgroundInactive: 'rgba(170, 42, 127,0.4)',
    buttonBorder: '#299aaa',
    buttonText: colourChart.grey000,
    titleText: '#d4d4d4',
    rowText: '#d4d4d4',
    siteBackground: colourChart.darkBlue800,
    topNavigationBackground: colourChart.darkBlue900,
    navigationBackground: colourChart.darkBlue900,
    mainBackground: colourChart.darkBlue800,
    tableHeader: colourChart.darkBlue900,
    tableBackground: colourChart.darkBlue850,
    lightBackground: colourChart.darkBlue900,
    inputBackground: colourChart.darkBlue800,
    rowInputBackground: colourChart.blue000,
    // rowInputBackground: 'rgba(170, 42, 127, 0.12)',
    inputText: '#d4d4d4',
    inputLabelText: '#d4d4d4',
    inputBorder: colourChart.blue900,
    inactiveTitleText: 'rgba(8, 29, 66, 0.5)',
    buttonBackgroundAlt: '#DCEEF7',
    background: 'pink',
    border: 'red',
    backgroundAlt: 'orange',
    borderAlt: 'brown',
    text: 'gray',
    calendarDay: '#828282',
    calendarDayText: '#d4d4d4',
    calendarBorder: 'rgba(255,255,255,0)',
  },
  // 'dark': {
  //   activeText: '#0092D3',
  //   activeBackground: 'rgba(0, 146, 211, 0.12)',
  //   normalHighligh: '#5D6684',
  //   darkHighligh: '#AAB7E5',
  //   background1: '#212A42',
  //   background2: '#1D2539',
  //   buttonBackground: '#d57272',
  //   buttonBackgroundInactive: 'rgba(170, 42, 127,0.4)',
  //   buttonBorder: '#299aaa',
  //   buttonText: '#FFFFFF',
  //   titleText: '#AAB7E5',
  //   rowText: '#AAB7E5',
  //   siteBackground: '#282F46',
  //   navigationBackground: '#1D2539',
  //   mainBackground: '#1D2539',
  //   lightBackground: '#212A42',
  //   lightBackground: '#212A42',
  //   inputBackground: '#282F46',
  //   inputText: '#AAB7E5',
  //   inputLabelText: '#7880A7',
  //   inputBorder: 'rgba(170, 42, 127,0)',
  //   inactiveTitleText: 'rgba(8, 29, 66, 0.5)',
  //   buttonBackgroundAlt: '#DCEEF7',
  // },
};

const spacing = {
  small: 2,
  small200: 4,
  small300: 8,
  small400: 10,
  medium: 12,
  medium200: 16,
  medium250: 18,
  medium300: 20,
  large: 24,
  large200: 28,
  large300: 32,
};

const iconSize = {
  small: 16,
  medium: 24,
  large: 32,
};

const fontSize = {
  small: 8,
  small100: 11,
  small200: 12,
  medium: 14,
  medium200: 16,
  large: 22,
  large200: 28,
};

export {colours, spacing, iconSize, fontSize};
