import React from 'react';
import {View} from 'react-native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme, isMobileOrTabletScreen} from '@tools/displayTools';

const ContainerStyle = {
  flexDirection: 'row',
  borderColor: colours[GetGlobalTheme()].grey400,
  padding: `${spacing.space8}px ${spacing.space24}px`,
  width: '100%',
  justifyContent: 'end',
};

const ContentWrapperStyle = {
  flexDirection: 'row',
  padding: spacing.space8,
  gap: spacing.space12,
  width: isMobileOrTabletScreen() ? '100%' : 'initial',
};

export type ModalFooterProps = {
  children?: React.ReactNode;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({children}) => {
  return (
    <View style={ContainerStyle}>
      <View style={ContentWrapperStyle}>{children}</View>
    </View>
  );
};
