import {Component} from 'react';
import {
  DeleteRecordProps,
  InputChangeEventProps,
  OperationMenuPressProps,
  UnOKRecordProps,
  WindowRecord,
} from '@utils/types';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {clearParameterRecordData, DoNavigate, GetParameterID, getParameterRecordData} from '@tools/displayTools';
import Requests from '../../../api/Requests';
import {b64toBlob} from '../../../Tools/Base64Decode';
import {translate} from '@utils/languageTools';
import {logError} from '@utils/debug';
import {getApprovalStatusText} from '@tools/recordListDisplayTools';
import {objectHasProperty} from '../../../Tools/ObjectTools';
import {ActiveEditFields, EditFieldRules, RecordScreenState} from '@components/screens/types';
import {handleError} from 'src/Tools/PortalLog';
import {isApprovalRulesSupported} from '@src/utils/drawersTools';
import {AlertType} from '@src/components/design/AlertWindow';

const defaultRecordScreenState: RecordScreenState = {
  update: false,
  activeDelete: false,
  activeRow: -1,
};

export abstract class RecordScreen<P, S extends RecordScreenState> extends Component<P, S> {
  _isMounted: boolean;
  ScreenName: string;
  table: string | number;
  init: boolean;
  record: WindowRecord;
  id: string;
  navigation: any;
  focusSubscription: any;
  blurSubscription: any;
  IDField: string;
  hasapprovals: number;
  ApprovalStatusText: string;
  ApprovalStatus: string;
  duplicateID: string;
  newRecordFieldKey: string;
  recordWindowDefinitions: RecordScreenWindowDefinition;
  paramRecordData: any;
  useActiveEditFields: boolean;
  activeEditFields: ActiveEditFields;
  editFieldRules: Array<EditFieldRules>;
  isActiveScreen: boolean;
  forceRefresh: boolean;

  constructor(props) {
    super(props);
    this.sendEmailFromRecord = this.sendEmailFromRecord.bind(this);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.IDField = 'SerNr';
    this.init = false;
    this.hasapprovals = this.canUseApprovalRules();
    this.ApprovalStatus = '';
    this.duplicateID = '';
    this.ApprovalStatusText = '';
    this.id = '';
    this._isMounted = false;
    this.state = defaultRecordScreenState as S;
    global.refreshRecordScreen = () => {
      if (!this.isActiveScreen && !this.forceRefresh) {
        return;
      }
      this.setState({update: !this.state.update});
    };
    this.sendRecordData = this.sendRecordData.bind(this);
  }

  canUseApprovalRules() {
    return isApprovalRulesSupported() ? -1 : 0; //-1: supported, 0: not supported, because we skip it in CanUseApprovalRules;
  }

  allowsEditAndDelete() {
    return true;
  }

  createNewRecord() {
    return new WindowRecord(this.table, false, []);
  }

  isNewRecord = () => {
    return false;
  };

  isNewRecordFieldFilled = () => {
    return false;
  };

  recordFieldFilled(field) {
    return this.record && this.record.headerFields[field] !== '';
  }

  extractRecordID(paramID) {
    this.duplicateID = '';
    let id = paramID;
    let arr = id.split('-');
    if (arr[0] === 'new') {
      if (arr.length > 1) {
        id = arr[0];
        this.duplicateID = arr[1];
      }
    }
    return id;
  }

  CheckOpenRecord() {
    this.duplicateID = '';
    if (this.props.route?.params?.id) {
      this.id = this.extractRecordID(this.props.route.params.id);
      this.rowClick(this.props.route.params.id);
    }
    if (this.props.testID) {
      this.id = this.props.testID;
      this.rowClick(this.props.testID);
    }
  }

  updateScreen(callbackFunc = undefined) {
    if (this._isMounted) {
      this.setState({update: !this.state.update}, () => (callbackFunc ? callbackFunc() : () => {}));
    } else {
      callbackFunc ? callbackFunc() : () => {};
    }
  }

  refreshRecordData = () => {
    if (this.isActiveScreen) {
      let paramID = GetParameterID(this);
      if (this.id !== paramID && paramID !== '') {
        let id = this.extractRecordID(paramID);
        let sameID = id === this.id;
        this.id = id;
        if (sameID === false) {
          this.rowClick(this.id);
        }
      }
    }
  };

  componentDidUpdate() {
    this.refreshRecordData();
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleActiveScreenCheck();
  }

  private handleActiveScreenCheck() {
    this.isActiveScreen = true;
    if (!this.props.navigation) {
      return;
    }
    this.focusSubscription = this.props.navigation.addListener('focus', () => {
      this.handleOnNavigationFocus();
    });
    this.blurSubscription = this.props.navigation.addListener('blur', () => {
      this.handleOnNavigationBlur();
    });
  }

  private handleOnNavigationFocus() {
    if (this.init === false) {
      let paramID = GetParameterID(this);
      this.paramRecordData = getParameterRecordData(this);
      let id = this.extractRecordID(paramID);
      this.id = id;
      this.isActiveScreen = true;
      this.rowClick(this.id);
    }
    this.init = false;
  }

  private handleOnNavigationBlur() {
    this.id = '';
    this.record = this.createNewRecord();
    this.isActiveScreen = false;
    this.updateScreen();
  }

  // old. left it for old design where it might still be used.
  downloadDocument = () => {
    /*application/octet-stream*/
    //window.open('about:blank', 'Downloading');
    Requests.downloadDocument(this.table, {id: this.id})
      .then((response) => {
        if (response.file !== '') {
          let blob = b64toBlob(response.file, 'application/pdf');
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          //a.download = response.name;
          //  a.target = '_blank';
          document.body.appendChild(a);
          window.open(url, 'Downloading');
          //window.URL.revokeObjectURL(url);
        } else {
          global.messageBox({
            showMessage: true,
            statusMessage: translate('DocumentNotFound'),
          });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  showPreviewFile = () => {};

  afterRecordLoad() {}

  handleMatrixFocus = (rowindex) => {
    this.setState({activeRow: rowindex});
  };

  deleteRecord = ({inspectWindow, redirect, onDeleteRefreshList}: DeleteRecordProps) => {
    let self = this;
    Requests.deleteRecord(this.table, this.id)
      .then((response) => {
        if (inspectWindow) {
          if (response.statusMessage) {
            inspectWindow.changeAlertMessage(true, response.statusMessage, response.statusMessageType, 5000);
          } else {
            inspectWindow.closeAlertMessage();
            self.id = '';
            if (redirect) {
              DoNavigate(self, this.ScreenName, false, '', true, true);
            }
          }
        } else {
          self.id = '';
          if (redirect) {
            DoNavigate(self, this.ScreenName, false, '', true, true);
          }
        }
        if (onDeleteRefreshList) {
          onDeleteRefreshList();
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  sendEmailFromRecord({inspectWindow}: OperationMenuPressProps) {
    Requests.doAction('sendemail', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        if (response.statusMessage) {
          inspectWindow.changeAlertMessage(true, response.statusMessage, response.statusMessageType, 5000);
        } else {
          inspectWindow.closeAlertMessage();
        }
        global.reloadAttachmentList();
      })
      .catch((error) => {
        global.reloadAttachmentList();
        alert(translate('EmailFailed'));
        logError(error);
      });
  }

  deleteRecordOld = () => {
    let self = this;
    this.setState({activeDelete: false}, () => {
      Requests.deleteRecordOld(this.table, this.id)
        .then((response) => {
          if (response.success) {
            self.id = '';
            DoNavigate(self, this.ScreenName, false, '', true, true);
          }
        })
        .catch((error) => {
          logError(error);
        });
    });
  };

  rowClick = (id: string) => {
    this.hasapprovals = this.canUseApprovalRules();
    this.ApprovalStatus = '';
    this.ApprovalStatusText = '';
    let idValue: any = {};
    idValue[this.IDField] = id;

    if (id === 'new') {
      let action = '__new';
      if (this.duplicateID !== '') {
        action = '__copy';
      }

      this.updateScreen(() => {
        Requests.doWindowAction(this.table, this.duplicateID, this.record, action)
          .then((response) => {
            this.record.applyNewValues2({newRecord: response.record, applyWActions: true, setOrig: false});
            if (action === '__copy') {
              global.activeEditMode = true;
            }
            this.record.addMatrixRow();
            this.handleUpdateRecordFieldWithParams(action);
            this.updateScreen(this.afterRecordLoad);
          })
          .catch((error) => {
            logError(error);
          });
      });
    } else {
      this.id = id;
      Requests.getTableData(this.table, idValue, undefined, undefined, true)
        .then((response) => {
          this.id = response.records[0][this.IDField];
          this.forceRefresh = !this.forceRefresh;
          this.record.applyNewValues(response.records[0]);
          this.updateScreen(this.afterRecordLoad);
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  handleUpdateRecordFieldWithParams(action: string) {
    if (action === '__new' && this.paramRecordData) {
      this.updateRecordFieldWithParams();
    }
  }

  updateRecordFieldWithParams() {
    // This will break if there is more than 1 paramRecordDataKey, since updating record is async
    const paramRecordDataKeys = Object.keys(this.paramRecordData);
    paramRecordDataKeys.forEach((key) =>
      this.updateRecordField({
        rowindex: -1,
        fieldname: key,
        value: this.paramRecordData[key],
      })
    );
    clearParameterRecordData(this);
  }

  CanUseApprovalRules = () => {
    let self = this;
    let res = this.hasapprovals;
    if (this.id !== undefined && this.id !== '') {
      if (this.hasapprovals === -1) {
        this.hasapprovals = 0;
        Requests.doAction('checkapprovalstatus', {
          id: this.id,
          regname: this.table,
        })
          .then((response) => {
            if (response.record.hasapprovals === '1') {
              self.ApprovalStatusText = getApprovalStatusText(response.record.approvalstatus);
              self.ApprovalStatus = response.record.approvalstatus;
              self.cancancel = response.record.cancancel === '1';
              self.hasapprovals = 1;
              self.updateScreen();
              //self.setState({hasapprovals: 1});
            }
          })
          .catch((error) => {
            logError(error);
          });
      }
    }
    return res;
  };
  cancelApproval = () => {
    let self = this;
    Requests.doAction('cancelapproval', {
      id: this.id,
      regname: this.table,
    })
      .then(() => {
        self.hasapprovals = -1;
        self.CanUseApprovalRules();
      })
      .catch((error) => {
        logError(error);
      });
  };

  RequestApproval = () => {
    let self = this;
    this.hasapprovals = -1;
    Requests.doAction('sendforapproval', {
      id: this.id,
      regname: this.table,
    })
      .then(() => {
        self.hasapprovals = -1;
        self.CanUseApprovalRules();
      })
      .catch((error) => {
        logError(error);
      });
  };

  setActiveEditFields(response) {
    if (response.activeFields) {
      let activeFields: ActiveEditFields = {
        headerFields: response.activeFields.headerFields,
        matrixFields: response.activeFields.matrixFields,
        exceptionHeaderList: response.activeFields.exceptionHeaderList.split(','),
        exceptionMatrixList: response.activeFields.exceptionMatrixList.split(','),
      };
      this.activeEditFields = activeFields;
    }
    if (response.rules) {
      this.editFieldRules = [];
      response.rules.forEach((rule) => {
        this.editFieldRules.push({
          field: rule.rulefield,
          type: rule.rulename,
          value: rule.rulevalue,
        });
      });
    }
  }
  requestActiveEditFields(callBackFunction) {
    if (this.useActiveEditFields && this.recordWindowDefinitions.getHeaderFields(this).editable) {
      Requests.getActiveEditFields(this.table, this.id, this.record)
        .then((response) => {
          this.setActiveEditFields(response);
          callBackFunction();
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      callBackFunction();
    }
  }

  async sendRecordData(callBackFunction) {
    let self = this;
    //remove rows first
    try {
      this.record.removedRows.sort(function (a, b) {
        return b - a;
      });
      let success = true;
      if (this.record.removedRows.length > 0) {
        const deleteRowsResponse = await Requests.deleteRecordRow(
          this.table,
          this.id + ':' + this.record.removedRows.join(',')
        );
        success = deleteRowsResponse.success;
      }
      if (success) {
        if (this.record.updateFields.length > 0) {
          let data = {id: this.id === 'new' ? '' : this.id, list: this.record.updateFields};
          Requests.updateRecordData2(this.table, data, false)
            .then((response) => {
              callBackFunction(response, () => {
                if (
                  self.id === 'new' &&
                  objectHasProperty(response, 'record') &&
                  response.record[this.IDField] !== ''
                ) {
                  if (response.statusMessage && response.statusMessageType === AlertType.Warning) {
                    global.delayedStatusMesssage = {
                      statusMessage: response.statusMessage,
                      statusMessageType: response.statusMessageType,
                    };
                  }
                  DoNavigate(self, self.ScreenName, true, response.record[self.IDField]);
                }
              });
            })
            .catch((error) => {
              this.setState({errorMessage: translate('LoginFailed')});
              logError(error);
            });
        } else {
          callBackFunction({success: true});
        }
      }
    } catch (error) {
      logError(error);
    }
  }

  confirmRecord = () => {
    let self = this;
    this.record.addUpdateField(-1, 'OKFlag', 1);
    this.sendRecordData((response, callbackFunc) => {
      if (response.success) {
        self.record.applyNewValues(response.record);
        self.record.clearUpdateFields();
        self.updateScreen();
        if (callbackFunc) {
          callbackFunc();
        }
      }
    }).catch((error) => {
      self.record.clearUpdateFields();
    });
  };

  unConfirmRecord = ({inspectWindow}: UnOKRecordProps) => {
    Requests.doAction('unokrecord', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        if (response.statusMessage) {
          inspectWindow.changeAlertMessage(true, response.statusMessage, response.statusMessageType);
        } else {
          inspectWindow.closeAlertMessage();
          this.rowClick(this.id);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  updateRecordField({rowindex, fieldname, value, inspectWindow}: InputChangeEventProps, callback?) {
    //do window actions here and add to update fields
    this.record.addUpdateField(rowindex, fieldname, value);
    global.activeEditMode = true;
    Requests.doWindowAction(this.table, this.id, this.record, fieldname, value, rowindex)
      .then((response) => {
        this.record.applyNewValues2({
          newRecord: response.record,
          setOrig: false,
          reset: false,
          applyWActions: true,
        });
        if (inspectWindow) {
          if (response.statusMessage) {
            inspectWindow.changeAlertMessage?.(true, response.statusMessage, response.statusMessageType, 5000);
          } else {
            inspectWindow.closeAlertMessage?.();
          }
          if (objectHasProperty(response, 'activeFields')) {
            this.setActiveEditFields(response);
          }
        }
        this.updateScreen();
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        logError(error);
      });
  }
}
