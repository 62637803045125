import React from 'react';
import {View, ViewStyle, TouchableOpacity} from 'react-native';
import {RowContainer, TextNormal, TextNormalBold} from '@styles/primitiveComponents';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import {displayOverdueDate, displayTaskStatus} from '@src/tools/tasksDisplayTools';
import {ProfileIcon} from '@src/components/status/ProfileIcon';
import {GetGlobalTheme} from '@src/tools/displayTools';
import {colours} from '@src/styles/Style';

const KanbanCardStyle: ViewStyle = {
  width: '100%',
  height: 120,
  padding: 12,
  backgroundColor: colours[GetGlobalTheme()].grey100,
  borderRadius: 8,
  overflow: 'hidden',
  gap: 4,
};

const HeaderContainerStyle: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  gap: 4,
  marginBottom: 'auto',
};

const TitleContainerStyle: ViewStyle = {
  flex: 1,
  paddingRight: 10,
};

const ProfileIconContainerStyle: ViewStyle = {
  width: 26,
  height: 26,
  borderRadius: 13,
  justifyContent: 'center',
  alignItems: 'center',
};

const DividerStyle: ViewStyle = {
  width: '100%',
  height: 1,
  backgroundColor: colours[GetGlobalTheme()].grey400,
};

const FooterContainerStyle: ViewStyle = {
  flexDirection: 'row',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const CommentContainerStyle: ViewStyle = {
  flexDirection: 'row',
  alignItems: 'center',
};

type KanbanCardProps = {
  title: string;
  mainPersons: string[];
  type: string;
  activityTypeComment: string;
  date: string;
  commentCount: number;
  okflag: string;
  onPress?: () => void;
};

export const KanbanCard: React.FC<KanbanCardProps> = ({
  title,
  mainPersons,
  type,
  activityTypeComment,
  date,
  commentCount,
  okflag,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.7} style={KanbanCardStyle}>
      <RowContainer style={HeaderContainerStyle}>
        <View style={TitleContainerStyle}>
          <TextNormal numberOfLines={2} ellipsizeMode="tail" style={{color: colours[GetGlobalTheme()].grey800}}>
            {title}
          </TextNormal>
        </View>
        <ProfileIcon
          style={ProfileIconContainerStyle}
          textStyle={{
            fontSize: '10px',
          }}
        />
      </RowContainer>
      <View style={DividerStyle} />
      <RowContainer style={FooterContainerStyle}>
        <RowContainer style={{gap: 12, alignItems: 'center'}}>
          {displayTaskStatus(activityTypeComment, type)}
          {commentCount > 0 && (
            <RowContainer style={CommentContainerStyle}>
              <PortalIcon iconName="CommentCloudIcon" iconHeight={12} iconWidth={12} />
              <TextNormalBold style={{color: colours[GetGlobalTheme()].grey500, fontSize: 14}}>
                {commentCount}
              </TextNormalBold>
            </RowContainer>
          )}
        </RowContainer>
        <TextNormal style={{color: colours[GetGlobalTheme()].grey600, fontSize: 14}}>
          {displayOverdueDate(date, okflag)}
        </TextNormal>
      </RowContainer>
    </TouchableOpacity>
  );
};
