import React from 'react';
import {View} from 'react-native';
import {translate} from '@src/utils/languageTools';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import {Header3, Header6} from '@src/styles/primitiveComponents';
import {colours, spacing} from '@src/styles/Style';
import {GetGlobalTheme} from '@src/tools/displayTools';

const AccessDeniedContainerStyle = {
  flex: 1,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: spacing.space16,
  flexShrink: 1,
};

const AccessDeniedSubContainerStyle = {
  alignItems: 'center',
  flexShrink: 1,
  width: '100%',
};

export const AccessDenied = ({
  header = translate('AccessDenied'),
  subtext = translate('AccessDeniedSubtext'),
  subtext2 = translate('AccessDeniedSubtext2'),
}) => {
  return (
    <View style={AccessDeniedContainerStyle}>
      <PortalIcon iconName="AccessDenied" iconHeight={350} iconWidth={250} />
      <View style={AccessDeniedSubContainerStyle}>
        <Header3 style={{color: colours[GetGlobalTheme()].body}}>{header}</Header3>
        <Header6 style={{color: colours[GetGlobalTheme()].tableBody, marginTop: '5%'}}>{subtext}</Header6>
        <Header6 style={{color: colours[GetGlobalTheme()].tableBody}}>{subtext2}</Header6>
      </View>
    </View>
  );
};
