import React, {useState, useEffect, useRef} from 'react';
import {colours} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import Requests from '../../../../api/Requests';
import {InputChangeEventProps} from '@utils/types';
import EditField from '@components/editElements/EditField';
import {ColumnContainer, Header1, Header6} from '@styles/primitiveComponents';
import {ActionButton} from '@components/button/ActionButton';
import LanguageSelect from '@components/status/LanguageSelect';
import AlertWindow, {AlertType} from '@components/design/AlertWindow';
import {LoginBackground} from './LoginBackground';
import {logError} from '@src/utils/debug';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {Animated, View, Dimensions} from 'react-native';

const CreatePasswordFormStyle = {
  width: '100%',
  maxWidth: 694,
  maxHeight: 960,
  height: 'calc(100% - 120px)',
  minHeight: 720,
  display: 'flex',
  marginVertical: 60,
  marginHorizontal: 'auto',
  paddingVertical: 82,
  paddingHorizontal: '5%',
  alignItems: 'center',
  alignSelf: 'center',
  gap: '10%',
  flexShrink: 0,
  borderRadius: 12,
  backgroundColor: colours[GetGlobalTheme()].mainBG,
};

const InnerContainerStyle = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '4px',
  display: 'flex',
};

const InputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
};

const InputWrapperStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '4px',
};

const ButtonContainerStyle = {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '12px',
  flexDirection: 'row',
};

const CancelButtonWrapperStyle = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '10px',
};

const SendButtonWrapperStyle = {
  flex: 1,
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '10px',
};

const InputAlertWrapperStyle = {
  width: '100%',
  maxWidth: 694,
  maxHeight: 960,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
};

interface CreatePasswordProps {
  navigation: NavigationProp<any, any>;
}
const minScreenHeight = 820;

export const CreatePassword: React.FC<CreatePasswordProps> = () => {
  const navigation = useNavigation<NavigationProp<any, any>>();
  const [Password, setPassword] = useState<string>('');
  const [ConfirmPassword, setConfirmPassword] = useState<string>('');
  const [icon, setIcon] = useState<string>('eye-slash');
  const [confirmIcon, setConfirmIcon] = useState<string>('eye-slash');
  const [secure, setSecure] = useState<boolean>(true);
  const [confirmSecure, setConfirmSecure] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [messageOpacity] = useState(new Animated.Value(1));
  const [session, setSession] = useState<string>('');
  const [cc, setCC] = useState<string>('');
  const [passwordRulesMessage, setPasswordRulesMessage] = useState<string>('');
  const passwordRef = useRef(null);
  const [screenHeight, setScreenHeight] = useState(getClampedScreenHeight());

  const ContainerStyle = {
    backgroundColor: colours[GetGlobalTheme()].darkBlue,
    height: screenHeight,
  };

  function getClampedScreenHeight() {
    const screenHeight = Dimensions.get('window').height;
    if (screenHeight < minScreenHeight) {
      return minScreenHeight;
    }
    return screenHeight;
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  function handleResizeEvent() {
    setScreenHeight(getClampedScreenHeight());
  }

  useEffect(() => {
    if (IsHRWeb()) {
      document.title = translate('ResetPassword');
    }
    initializeSessionAndCC();
  }, []);

  const initializeSessionAndCC = () => {
    const url = new URL(window.location.href);
    const session = url.searchParams.get('sess');
    const cc = url.searchParams.get('cc');
    setSession(session || '');
    setCC(cc || '');
    setPasswordRulesMessage(
      translate('PasswordRules') +
        '\n' +
        '• ' +
        translate('PasswordRequirement1') +
        '\n' +
        '• ' +
        translate('PasswordRequirement2') +
        '\n' +
        '• ' +
        translate('PasswordRequirement3')
    );
  };

  const displayMessage = (errorMessage, successMessage) => {
    messageOpacity.stopAnimation();
    messageOpacity.setValue(1);

    setErrorMessage(errorMessage);
    setSuccessMessage(successMessage);
    Animated.timing(messageOpacity, {
      toValue: 0,
      duration: 15000,
      useNativeDriver: true,
    }).start(() => {
      setErrorMessage('');
      setSuccessMessage('');
      messageOpacity.setValue(1);
    });
  };

  const createPassword = () => {
    if (Password !== ConfirmPassword) {
      displayMessage(translate('PasswordsDontMatch'), '');
      return;
    }

    if (Password.length < 8 || ConfirmPassword.length < 8) {
      displayMessage(translate('PasswordTooShort'), '');
      return;
    }

    const hasCapitalLetter = /[A-Z]/.test(Password);
    const hasNumber = /\d/.test(Password);

    if (!hasCapitalLetter) {
      displayMessage(translate('PasswordNeedsCapital'), '');
      return;
    }

    if (!hasNumber) {
      displayMessage(translate('PasswordNeedsNumber'), '');
      return;
    }

    displayMessage('', '');

    Requests.createPassword(Password, ConfirmPassword, session, cc)
      .then(() => {
        navigation.navigate('Login', {showCreateSuccessMessage: true});
      })
      .catch((error) => {
        logError(error);
        displayMessage(translate('AnErrorOccurred'), '');
      });
  };

  return (
    <View style={ContainerStyle}>
      <LoginBackground />
      <ColumnContainer style={CreatePasswordFormStyle}>
        <ColumnContainer style={InnerContainerStyle}>
          <LanguageSelect style={{marginLeft: 'none'}} />
          <Header1
            style={{
              color: colours[GetGlobalTheme()].darkBlue300,
            }}>
            {translate('CreateYourPassword')}
          </Header1>
          <Header6
            style={{
              color: colours[GetGlobalTheme()].grey600,
            }}>
            {translate('EnterYourPassword')}
          </Header6>
        </ColumnContainer>
        <View style={InputAlertWrapperStyle}>
          <ColumnContainer style={InputContainerStyle}>
            <ColumnContainer style={InputWrapperStyle}>
              <Animated.View style={{opacity: messageOpacity, width: '100%'}}>
                <AlertWindow
                  message={errorMessage || successMessage}
                  width={'100%'}
                  alertType={errorMessage ? AlertType.Error : AlertType.Success}
                  show={!!(errorMessage || successMessage)}
                />
              </Animated.View>
            </ColumnContainer>
            <ColumnContainer style={InputWrapperStyle}>
              <EditField
                title={translate('NewPassword')}
                width="100%"
                value={Password}
                secure={secure}
                icon={icon}
                reference={passwordRef}
                iconClick={() => {
                  setSecure(!secure);
                  setIcon(secure ? 'eye' : 'eye-slash');
                }}
                inputStyle={{
                  backgroundColor: colours[GetGlobalTheme()].maingBG,
                  paddingHorizontal: 10,
                }}
                onChange={({value}: InputChangeEventProps) => setPassword(value)}
              />
            </ColumnContainer>
            <ColumnContainer style={InputWrapperStyle}>
              <EditField
                title={translate('ConfirmPassword')}
                width="100%"
                value={ConfirmPassword}
                secure={confirmSecure}
                icon={confirmIcon}
                reference={passwordRef}
                iconClick={() => {
                  setConfirmSecure(!confirmSecure);
                  setConfirmIcon(confirmSecure ? 'eye' : 'eye-slash');
                }}
                inputStyle={{
                  backgroundColor: colours[GetGlobalTheme()].maingBG,
                  paddingHorizontal: 10,
                }}
                onChange={({value}: InputChangeEventProps) => setConfirmPassword(value)}
              />
            </ColumnContainer>
            <AlertWindow
              message={passwordRulesMessage}
              width={'100%'}
              alertType={AlertType.Info}
              show={true}
              alignment="left"
            />
          </ColumnContainer>
        </View>
        <View style={ButtonContainerStyle}>
          <ColumnContainer style={CancelButtonWrapperStyle}>
            <ActionButton
              onPress={() => navigation.navigate('Login')}
              title={translate('Cancel')}
              style={{
                backgroundColor: colours[GetGlobalTheme()].white,
                border: '1px solid ' + colours[GetGlobalTheme()].grey400,
              }}
              textStyle={{
                color: colours[GetGlobalTheme()].darkBlue,
                width: '100%',
              }}
            />
          </ColumnContainer>
          <ColumnContainer style={SendButtonWrapperStyle}>
            <ActionButton
              onPress={createPassword}
              title={translate('CreatePassword')}
              textStyle={{
                color: colours[GetGlobalTheme()].white,
                width: '100%',
              }}
            />
          </ColumnContainer>
        </View>
      </ColumnContainer>
    </View>
  );
};
