import {Component} from 'react';
import {Animated, Modal, View} from 'react-native';
import styled, {css} from 'styled-components/native';
import {fontSize, spacing} from '../../styles/Style';
import {IsHRWeb, WindowElementType} from '../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import EditField from './EditField';
import Requests from '../../api/Requests';
import {ActionButton} from '../button/ActionButton';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import {logError} from '@utils/debug';
import {MatrixContainer} from './Matrix';
import {WindowDefinition, WindowMatrix, WindowRecord} from '@utils/types';

const kDefPerPage = 20;

const FilterFields = styled.View`
  ${IsHRWeb() &&
  css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    /*margin-right: -30px;*/
  `}
`;
const FilterField = styled.View`
  ${IsHRWeb() &&
  css`
    width: 100%;
    /*margin-right: 30px;*/
  `}
`;
const FilterActions = styled.View`
  display: flex;
  ${(IsHRWeb() &&
    css`
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
    `) ||
  css`
    flex-direction: column-reverse;
  `}
`;

const ReportBody = styled.ScrollView`
  padding: ${spacing.medium}px;
`;
const ReportWrap = styled.View`
  position: absolute;
  width: 50%;
  height: 400px;
  left: 25%;
  top: 5%;
  background: white;
`;
const ReportTop = styled.View`
  justify-content: center;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
`;
const ReportTitle = styled.Text`
  padding: ${spacing.medium}px;
  font-size: ${fontSize.large}px;
`;

function matrixFields(table) {
  let matrixFields: WindowMatrix = [
    {
      label: translate('Name'),
      dataKey: 'Name',
      width: '300px',
      viewOnly: table.props.readonly === true,
      style: {width: 300},
      windowElementType: WindowElementType.kInputTypeText,
    },
    {
      label: translate('Email'),
      dataKey: 'eMail',
      width: '300px',
      viewOnly: table.props.readonly === true,
      style: {width: 300},
      windowElementType: WindowElementType.kInputTypeText,
    },
  ];

  return matrixFields;
}

interface SignersWindowState {
  SearchVal: string;
  curpage: number;
  reportdata: {};
  offsetY: any;
  showmatrix: boolean;
  update: boolean;
  newMatrixTableHelper: boolean;
}

export class SignersWindow extends Component<{}, SignersWindowState> {
  record: WindowRecord;

  constructor(props) {
    super(props);
    this.props = props;
    let self = this;
    this.state = {
      SearchVal: '',
      curpage: 0,
      reportdata: {},
      update: false,
      offsetY: new Animated.Value(-400),
      newMatrixTableHelper: false,
      showmatrix: false,
    };
    this.record = new WindowRecord(39, true, undefined, matrixFields(this), this.footerFields());
    this.record.applyNewValues(props.record);
    this.showReportSpecification();
  }

  showReportSpecification() {
    let self = this;
    Animated.timing(this.state.offsetY, {toValue: 0, duration: 300}).start(() => self.setState({showmatrix: true}));
  }

  componentWillUnmount() {}

  footerFields = () => {
    let self = this;
    let res: WindowDefinition = [];
    if (this.record?.headerFields.SignToken === '' || this.record?.headerFields.SignToken === undefined) {
      res.push({
        fields: [
          {
            label: translate('InsertSigner'),
            onPress: () => self.insertMatrixRow(self.record.matrixRows.length),
            width: '170px',
            windowElementType: WindowElementType.kInputTypeButton,
          },
        ],
      });
    }
    return res;
  };
  //TODO index is +1 too many. we skip one row
  insertMatrixRow = (index) => {
    this.state.NewRowName = '';
    this.state.NewRowEmail = '';
    this.state.NewRowIndex = index;
    this.setState({newMatrixTableHelper: true});
  };
  updateScreen = (): void => {
    this.setState({update: !this.state.update});
  };

  deleteMatrixRow = (index) => {
    let self = this;
    let sernr = this.record.headerFields.UUID;
    Requests.deleteRecordRow(39, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(39, {
            RecordNr: self.record.headerFields.RecordNr,
          })
            .then((response) => {
              self.record.applyNewValues(response.records[0]);
              self.updateScreen();
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  TaskRowActions = () => {
    let res = [];
    if (this.record.headerFields.SignToken === '' || this.record.headerFields.SignToken === undefined) {
      res.push({
        name: translate('DeleteRow'),
        action: this.deleteMatrixRow,
      });
    }
    return res;
  };

  TestNewRowData = () => {
    let errstr = '';
    if (this.state.NewRowEmail === '') {
      errstr = translate('BlankEmail');
    }
    if (this.state.NewRowName.indexOf(' ') < 1) {
      errstr = translate('FillNameAndSurname');
    }
    if (this.state.NewRowName === '') {
      errstr = translate('BlankName');
    }

    if (errstr !== '') {
      global.messageBox({
        showMessage: true,
        statusMessage: errstr,
      });
      return false;
    } else {
      return true;
    }
  };

  createNewTableRow = () => {
    let self = this;

    if (this.TestNewRowData() === false) {
      return;
    }
    let res = {
      list: [
        {
          rownr: self.state.NewRowIndex + 1,
          field: 'Name',
          value: self.state.NewRowName,
        },
        {
          rownr: self.state.NewRowIndex + 1,
          field: 'eMail',
          value: self.state.NewRowEmail,
        },
      ],
      id: self.record.headerFields.SerNr,
    };
    res.id = self.record.headerFields.UUID;
    Requests.insertRecordRow(39, this.record.headerFields.UUID + ':' + (this.state.NewRowIndex + 1) + ':1')
      .then((response) => {
        if (response.success) {
          Requests.updateRecordData(39, res)
            .then((response) => {
              if (response.success) {
                self.record.applyNewValues(response.record);
                self.setState({
                  newMatrixTableHelper: false,
                });
              }
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  renderNewRowButton = () => {
    let self = this;
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Name')}
              name={'Name'}
              width={'260px'}
              value={this.state.NewRowName}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowName: value})}
            />
          </FilterField>
          <FilterField>
            <EditField
              title={translate('Email')}
              name={'eMail'}
              width={'260px'}
              value={this.state.NewRowEmail}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowEmail: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };

  handleExternalsChange = (rowindex, index, value) => {
    let self = this;
    Requests.updateRecordData(39, {
      field: index,
      rownr: rowindex,
      value: value,
      id: this.record.headerFields.UUID,
    })
      .then((response) => {
        if (response.success) {
          self.updateScreen();
          //self.setState({customrows: response.record.rows});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  StartSigningProcess = () => {
    let self = this;
    Requests.doAction('startsigning', {id: this.record.headerFields.RecordNr})
      .then((response) => {
        if (response.messages && response.messages.length > 0) {
          //global.messageBox({showMessage: true, statusMessage: response.errstr});
        } else {
          self.props.table.rowClick(self.record.headerFields.RecordNr);
          global.showSigners({showf: false});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  render() {
    let self = this;
    return (
      <>
        <View style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
          <Animated.View style={{transform: [{translateY: this.state.offsetY}]}}>
            <ReportWrap>
              <ReportTop>
                <View>
                  <ReportTitle>{translate('Signers')}</ReportTitle>
                </View>
                {self.props.readonly !== true && (
                  <ActionButton onPress={() => self.StartSigningProcess()} title={translate('StartSigning')} />
                )}
                <ActionButton onPress={() => global.showSigners({showf: false})} title={translate('Close')} />
              </ReportTop>

              <ReportBody>
                {this.state.showmatrix && (
                  <MatrixContainer
                    data={this.record.matrixRows}
                    columns={matrixFields(this)}
                    record={this.record}
                    /*okcheck={this.record}*/
                    /*title={this.props.title}*/
                    matrixFooterFields={this.footerFields()}
                    rowactions={this.TaskRowActions()}
                    //width={this.props.width}
                    pagination={this.record.pagination}
                  />
                )}
              </ReportBody>
            </ReportWrap>
          </Animated.View>
        </View>
        {this.state.newMatrixTableHelper && (
          <Modal transparent={true}>
            <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
          </Modal>
        )}
      </>
    );
  }
}
