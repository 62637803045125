import React from 'react';
import {translate} from '@utils/languageTools';
import Tasks from '@components/screens/tasks/Tasks';
import Employees from 'src/components/screens/Employees';
import Contracts from 'src/components/screens/Contracts';
import ContractChanges from '../../components/screens/ContractChanges';
import VacPlans from 'src/components/screens/VacPlans';
import Absences from 'src/components/screens/Absences';
import OrderDocs from 'src/components/screens/OrderDocs';
import ShiftTables from 'src/components/screens/ShiftTables';
import TimeTables from 'src/components/screens/TimeTables';
import Calculations from 'src/components/screens/Calculations';
import Invoices from '@components/screens/invoices/Invoices';
import SalesOrders from '@src/components/screens/salesOrders/SalesOrders';
import PurchaseInvoices from 'src/components/screens/PurchaseInvoices';
import Contacts from '@components/screens/contacts/Contacts';
import {View} from 'react-native';
import {AccessDenied} from '@src/components/screens/status/AccessDenied';
import {isDesktopScreen} from '@src/tools/displayTools';
import {ModuleType} from './types';

export function APISupportsItem(item: string): boolean {
  return global.userData.supported.includes(item) || global.userData.apiversion === '';
}

export function isApprovalRulesSupported() {
  return APISupportsItem('approvalrules'); //-1: supported, 0: not supported, because we skip it in CanUseApprovalRules;
}

export type DrawerType = {
  name: string;
  title: string;
  screen: () => JSX.Element;
  iconName: string;
  openDrawer: ModuleType;
};

export type Modules = {
  tasks: DrawerType[];
  sales: DrawerType[];
  hr: DrawerType[];
  purchases: DrawerType[];
  stock: DrawerType[];
  reports: DrawerType[];
};

export function getDrawers(type = 0) {
  const modules: Modules = {
    tasks: [],
    sales: [],
    hr: [],
    purchases: [],
    stock: [],
    reports: [],
  };
  const links = [];
  if (type === 1) {
    links.unshift({
      screen: 'AccessDenied',
      link: 'hr-portal/access-denied',
    });
  }
  if (APISupportsItem('activities')) {
    modules.tasks.push({
      name: 'Tasks',
      title: translate('Tasks'),
      screen: Tasks,
      iconName: 'TasksIcon',
      openDrawer: ModuleType.tasks,
    });
    links.push({
      vcName: ['ActVc'],
      screen: 'Tasks',
      link: 'hr-portal/tasks',
      record: 'TasksRecord',
      list: 'TasksList',
      recordlink: 'hr-portal/tasks/:id',
    });
  }

  if (APISupportsItem('employees')) {
    modules.hr.push({
      name: 'Employees',
      title: translate('Employees'),
      screen: Employees,
      iconName: 'EmployeesIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPEmpVc'],
      screen: 'Employees',
      link: 'hr-portal/employees',
      record: 'EmployeesRecord',
      list: 'EmployeesList',
      recordlink: 'hr-portal/employees/:id',
    });
  }

  if (APISupportsItem('contracts')) {
    modules.hr.push({
      name: 'Contracts',
      title: translate('Contracts'),
      screen: Contracts,
      iconName: 'ContractsIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPContractVc', 'ContractVc'],
      screen: 'Contracts',
      link: 'hr-portal/contracts',
      record: 'ContractsRecord',
      list: 'ContractsList',
      recordlink: 'hr-portal/contracts/:id',
    });
  }
  if (APISupportsItem('contractchanges')) {
    modules.hr.push({
      name: 'ContractChanges',
      title: translate('ContractChanges'),
      screen: ContractChanges,
      iconName: 'ContractChangesIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPContrChangeVc'],
      screen: 'ContractChanges',
      link: 'hr-portal/contract-changes',
      record: 'ContractChangesRecord',
      list: 'ContractChangesList',
      recordlink: 'hr-portal/contract-changes/:id',
    });
  }
  if (APISupportsItem('absenceplans')) {
    modules.hr.push({
      name: 'VacPlans',
      title: translate('VacationPlanning'),
      screen: VacPlans,
      iconName: 'PlannedAbsencesIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPOffWorkPlanVc', 'PlanAbsenceVc'],
      screen: 'VacPlans',
      link: 'hr-portal/vacplan',
      record: 'VacPlansRecord',
      list: 'VacPlansList',
      recordlink: 'hr-portal/vacplan/:id',
      extra: 'VacPlansEmpPlan',
      extralink: 'hr-portal/vacplan/empplan',
    });
  }
  if (APISupportsItem('absences')) {
    modules.hr.push({
      name: 'Absences',
      title: translate('Absences'),
      screen: Absences,
      iconName: 'AbsencesIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPOffWorkVc', 'AbsenceVc'],
      screen: 'Absences',
      link: 'hr-portal/absences',
      record: 'AbsencesRecord',
      list: 'AbsencesList',
      recordlink: 'hr-portal/absences/:id',
    });
  }
  if (APISupportsItem('documents')) {
    modules.hr.push({
      name: 'OrderDocs',
      title: translate('OrderDocs'),
      screen: OrderDocs,
      iconName: 'DocumentsIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPOrderDocVc'],
      screen: 'OrderDocs',
      link: 'hr-portal/documents',
      record: 'OrderDocsRecord',
      list: 'OrderDocsList',
      recordlink: 'hr-portal/documents/:id',
    });
  }
  if (APISupportsItem('shiftsheets')) {
    modules.hr.push({
      name: 'ShiftTables',
      title: translate('ShiftTables'),
      screen: ShiftTables,
      iconName: 'ShiftPlansIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPShiftSheetVc'],
      screen: 'ShiftTables',
      link: 'hr-portal/shifttables',
      record: 'ShiftTablesRecord',
      list: 'ShiftTablesList',
      recordlink: 'hr-portal/shifttables/:id',
    });
  }
  if (APISupportsItem('timesheets') && isDesktopScreen()) {
    modules.hr.push({
      name: 'TimeTables',
      title: translate('TimeTables'),
      screen: TimeTables,
      iconName: 'TimeTablesIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['WorkedHoursVc', 'EPTS2Vc'],
      screen: 'TimeTables',
      link: 'hr-portal/timetables',
      record: 'TimeTablesRecord',
      list: 'TimeTablesList',
      recordlink: 'hr-portal/timetables/:id',
    });
  }
  if (APISupportsItem('calculations')) {
    modules.hr.push({
      name: 'Calculations',
      title: translate('PaySlips'),
      screen: Calculations,
      iconName: 'PaySlipsIcon',
      openDrawer: ModuleType.hr,
    });
    links.push({
      vcName: ['EPCalcResVc'],
      screen: 'Calculations',
      link: 'hr-portal/calculations',
      record: 'CalculationsRecord',
      list: 'CalculationsList',
      recordlink: 'hr-portal/calculations/:id',
    });
  }
  if (APISupportsItem('invoices')) {
    modules.sales.push({
      name: 'Invoices',
      title: translate('Invoices'),
      screen: Invoices,
      iconName: 'InvoiceIcon',
      openDrawer: ModuleType.sales,
    });
    links.push({
      vcName: ['IVVc'],
      screen: 'Invoices',
      link: 'hr-portal/invoices',
      record: 'InvoicesRecord',
      list: 'InvoicesList',
      recordlink: 'hr-portal/invoices/:id',
    });
  }
  if (APISupportsItem('salesorders')) {
    modules.sales.push({
      name: 'SalesOrders',
      title: translate('SalesOrders'),
      screen: SalesOrders,
      iconName: 'CartIcon',
      openDrawer: ModuleType.sales,
    });
    links.push({
      vcName: ['ORVc'],
      screen: 'SalesOrders',
      link: 'hr-portal/sales-orders',
      record: 'SalesOrdersRecord',
      list: 'SalesOrdersList',
      recordlink: 'hr-portal/sales-orders/:id',
    });
  }
  if (isDesktopScreen()) {
    // if (APISupportsItem('stocktakes')) {  // Removed as per EP-471 until we fix it later as its buggy and none of the clients uses it
    //   modules.stock.push({
    //     name: 'StockTakings',
    //     title: translate('StockTakings'),
    //     screen: StockTakings,
    //     iconName: 'StockTakingsIcon',
    //   });
    //   links.push({
    //     vcName: [''],
    //     screen: 'StockTakings',
    //     link: 'hr-portal/stocktaking',
    //     record: 'StockTakingsRecord',
    //     list: 'StockTakingsList',
    //     recordlink: 'hr-portal/stocktaking/:id',
    //   });
    // }
    if (APISupportsItem('purchaseinvoices')) {
      modules.purchases.push({
        name: 'PurchaseInvoices',
        title: translate('PurchaseInvoices'),
        screen: PurchaseInvoices,
        iconName: 'PurchaseInvoicesIcon',
        openDrawer: ModuleType.purchases,
      });
      links.push({
        vcName: ['VIVc'],
        screen: 'PurchaseInvoices',
        link: 'hr-portal/purchase-invoices',
        record: 'PurchaseInvoicesRecord',
        list: 'PurchaseInvoicesList',
        recordlink: 'hr-portal/purchase-invoices/:id',
      });
    }
  }
  if (APISupportsItem('contacts')) {
    if (modules.sales.length > 0) {
      modules.sales.push({
        name: 'Contacts',
        title: translate('Contacts'),
        screen: Contacts,
        iconName: 'UserIcon',
        openDrawer: ModuleType.sales,
      });
    } else {
      if (modules.purchases.length > 0) {
        modules.purchases.push({
          name: 'Contacts',
          title: translate('Contacts'),
          screen: Contacts,
          iconName: 'UserIcon',
          openDrawer: ModuleType.purchases,
        });
      }
    }
    links.push({
      vcName: ['CUVc'],
      screen: 'Contacts',
      link: 'hr-portal/contacts',
      record: 'ContactsRecord',
      list: 'ContactsList',
      recordlink: 'hr-portal/contacts/:id',
    });
  }
  if (type === 0) {
    return modules;
  } else {
    return links;
  }
}

export function getAllAllowedDrawers() {
  const modules: Modules = getDrawers();
  const drawers: DrawerType[] = Object.values(modules).flat();
  const curpath = window.location.pathname;

  if (
    !drawers.some((drawer) => drawer.name === 'AccessDenied') &&
    curpath !== '/hr-portal' &&
    !isNaN(global.userData.apiversion)
  ) {
    drawers.unshift({
      name: 'AccessDenied',
      title: '',
      iconName: '',
      openDrawer: ModuleType.none,
      screen: () => <AccessDenied />,
    });
  }

  if (drawers.length === 0) {
    drawers.push({
      name: ' ',
      title: ' ',
      iconName: '',
      openDrawer: ModuleType.none,
      screen: () => <View />,
    });
  }
  return drawers;
}
