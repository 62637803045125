import React from 'react';
import {Image, Text} from 'react-native';
import styled, {css} from 'styled-components/native';
import {translate} from '@utils/languageTools';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import {colours} from '../../styles/Style';
import ExcellentLogo from '../../assets/images/excellent_logo';

const LoadingWrap = styled.View`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colours[GetGlobalTheme()].siteBackground};
  ${IsHRWeb() &&
  css`
    position: fixed;
  `};
  ${IsHRWeb() === false &&
  css`
    position: absolute;
  `};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;
const ImageWrap = styled.View`
  width: 70px;
  margin-bottom: 20px;
`;

export const Loading = () => {
  return (
    <LoadingWrap>
      <ImageWrap>
        <Image style={{height: 70}} source={{uri: ExcellentLogo}} alt="Logo" />
      </ImageWrap>
      <Text style={{color: colours[GetGlobalTheme()].titleText}}>
        {translate('Loading')}
      </Text>
    </LoadingWrap>
  );
};
