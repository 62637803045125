import {FieldSection, WindowDefinition2, WindowMatrix} from '@utils/types';
import {BrowseColumn} from '@components/screens/types';

export const enum MobileBrowseColumnDisplayType {
  title = 'title',
  underTitle = 'underTitle',
  rightTitle = 'rightTitle',
  rightUnderTitle = 'rightUnderTitle',
}

export abstract class RecordListWindowDefinition {
  abstract getMobileBrowseColumns(): BrowseColumn[];

  abstract getDesktopBrowseColumns(): BrowseColumn[];

  abstract getBrowseColumns(window): BrowseColumn[];
}

export abstract class RecordScreenWindowDefinition {
  abstract getSideSectionFields(table): FieldSection[];

  abstract getHeaderFields(table, fullf?: boolean): WindowDefinition2;

  abstract getMatrixFields(table): WindowMatrix;

  getHiddenFields(): Array<string> {
    return [];
  }
}
