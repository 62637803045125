import {WindowElementType} from '@tools/displayTools';
import {MobileBrowseColumnDisplayType} from '@src/types/Record/windowDefinitions';

export interface ListScreenState {
  update: boolean;
  data?: [];
  activeRow: number;
}

export interface KanbanBoardState {
  update: boolean;
  data?: [];
  activeRow: number;
}

export const enum ViewMode {
  View,
  Edit,
}

export type ActiveEditFields = {
  headerFields: boolean;
  matrixFields: boolean;
  exceptionHeaderList: Array<string>;
  exceptionMatrixList: Array<string>;
};

export type EditFieldRules = {
  field: string;
  type: string;
  value: string;
};

export type BrowseColumn = {
  name: string;
  key: string;
  sortKey?: string;
  checkUndefined?: boolean;
  alignment?: string;
  width: string;
  windowElementType?: WindowElementType;
  mobileDisplayType?: MobileBrowseColumnDisplayType;
  overwrite?: (OverwriteBrowseColumnProps) => any;
};

export type KanbanColumn = {
  title: string;
  key: string;
};

export const enum DateFormatOrigin {
  ISO,
  Database,
}

export type Dimensions = {
  width: number;
  height: number;
};

export interface RecordScreenState {
  update: boolean;
  activeRow: number;
  activeDelete: false;
}
