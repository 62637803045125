import React from 'react';
import styled from 'styled-components/native';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {ActionButton} from '@components/button/ActionButton';
import {TextNormalBold} from '@styles/primitiveComponents';
import {ViewMode} from '../screens/types';

const Wrapper = styled.View<{columnDisplay: boolean}>`
  background-color: ${colours[GetGlobalTheme()].grey200};
  border-radius: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  gap: ${spacing.space12}px;
  padding: ${spacing.space8}px;
  margin: ${spacing.space4}px 0;
  width: ${(props) => (props.columnDisplay ? 'calc(33% - 4px)' : '100%')};
`;

const IconWrapper = styled.View`
  background-color: white;
  justify-content: start;
  align-items: center;
  margin-left: ${spacing.space4}px;
  padding: ${spacing.space8}px;
  border-radius: 4px;
  width: 36px;
`;

const RecordLinkButtonStyle = {
  width: '100%',
  backgroundColor: 'invisible',
  alignItems: 'center',
  justifyContent: 'start',
  marginRight: 'auto',
  padding: 0,
  margin: 0,
};
const RecordLinkButtonTextStyle = {
  color: colours[GetGlobalTheme()].lightBlue,
  marginRight: 48,
  justifyContent: 'start',
};

const RecordLinkText = styled(TextNormalBold)`
  color: ${colours[GetGlobalTheme()].lightBlue};
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-right: auto;
  padding: 0;
  margin: 0;
`;

type RecordLinkProps = {
  name: string;
  canBeOpened: boolean;
  onNamePress: () => void;
  wideView: boolean;
  viewMode: ViewMode;
};

export const RecordLink = ({name, canBeOpened, onNamePress, wideView, viewMode}: RecordLinkProps) => {
  const nameLengthLimit = 25;
  const shortRecordLinkNameSuffix = '...';

  function getRecordName() {
    if (name.length > nameLengthLimit) {
      return name.slice(0, nameLengthLimit) + shortRecordLinkNameSuffix;
    }
    return name;
  }

  return (
    <Wrapper wideView={wideView && viewMode === ViewMode.Edit}>
      <IconWrapper>
        <PortalIcon iconName={'LinkIcon'} iconHeight={24} iconColor={colours[GetGlobalTheme()].lightBlue} />
      </IconWrapper>
      {canBeOpened ? (
        <ActionButton
          style={RecordLinkButtonStyle}
          textStyle={RecordLinkButtonTextStyle}
          title={getRecordName()}
          onPress={onNamePress}
        />
      ) : (
        <RecordLinkText> {getRecordName()} </RecordLinkText>
      )}
    </Wrapper>
  );
};
