function fixupString(str: string) {
  let string = str.toString();
  if (string.length < 2) {
    string = '0' + string;
  }
  return string;
}

export function constructDateString(year, month, day) {
  return year + '-' + fixupString(month) + '-' + fixupString(day);
}

export function constructDateString2(date) {
  if (typeof date === 'object') {
    return (
      date.getFullYear() +
      '-' +
      fixupString(date.getMonth() + 1) +
      '-' +
      fixupString(date.getDate())
    );
  }
  return '';
}

export function getTimeSeconds(time: number) {
  let td = new Date('01/01/2007 ' + time);

  let seconds = td.getHours() * 3600;
  seconds += td.getMinutes() * 60;
  seconds += td.getSeconds();
  return seconds;
}

export function extractDateFromString(str: string) {
  if (str) {
    return new Date(
      parseInt(str.substring(0, 4)),
      parseInt(str.substring(5, 7)) - 1,
      parseInt(str.substring(8, 10))
    );
  }
  return null;
}

function checkReplaceDateString(res, str: string, search, errf) {
  let pos = global.userData.dateformat.indexOf(search);
  if (pos > -1) {
    let numstr = str.substring(pos, pos + search.length);
    let num = parseInt(numstr);
    if (isNaN(num)) {
      errf.val = true;
    } else {
      res = res + numstr;
    }
  }
  return res;
}

export function convertDateStringToISODate(str: string) {
  let errf = {val: false};
  if (str) {
    let tstr = checkReplaceDateString('', str, 'YYYY', errf) + '-';
    tstr = checkReplaceDateString(tstr, str, 'MM', errf) + '-';
    tstr = checkReplaceDateString(tstr, str, 'DD', errf);
    if (errf.val === false) {
      return tstr;
    }
  }
  return '';
}

export function getCurrentDate() {
  return new Date();
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getDaysInMonth2(td: Date) {
  return new Date(td.getFullYear(), td.getMonth() + 1, 0).getDate();
}

export function AddDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function AddMonth(td: Date, diff) {
  let year = td.getFullYear();
  let month = td.getMonth();
  let day = td.getDate();
  month += diff;
  if (diff > 0 && month > 11) {
    month = month - 12;
    year = year + 1;
  } else if (diff < 0 && month < 0) {
    month = 12 + month;
    year = year - 1;
  }
  return new Date(year, month, day);
}

export function AddYear(td: Date, diff) {
  let year = td.getFullYear();
  let month = td.getMonth();
  let day = td.getDate();
  year += diff;
  return new Date(year, month, day);
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function GetMonthName(monthIndex: number) {
  let res = '';
  if (monthIndex <= 0 || monthIndex >= 13) {
    return;
  }
  res = months[monthIndex - 1];
  return res;
}
