import React, {Component} from 'react';
import {ScrollView, Text, View} from 'react-native';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {
  ConvertDisplayValue,
  GetGlobalTheme,
  IsHRiOS,
  IsHRWeb,
  UsePagination,
  WindowElementType,
} from '../../Tools/DisplayTools';
import Requests from '../../api/Requests';
import {DataTable} from 'react-native-paper';
import {translate} from '@utils/languageTools';
import EditField from '../edit_elements/EditField';
import {
  GetFilterEndDate,
  GetFilterStartDate,
  GetWindowSearch,
  SetWindowEndDate,
  SetWindowSearch,
  SetWindowStartDate,
  UpdateWindowData,
  WindowHasDateFilter,
} from '../../Tools/WindowTools';
import {log, logError, LogType} from '@utils/debug';
import {Pagination} from '../edit_elements/Matrix';
import {TableContainerProps} from '@utils/types';
import {isDesktopScreen, isMobileOrTabletScreen, ScreenHeight, ScreenWidth} from '@src/tools/displayTools';

const FooterContainer = styled.View`
  padding: 0px 22px;
  height: 64px;
`;

const TableWrapper = styled.View`
  background-color: ${colours[GetGlobalTheme()].mainBackground};
  display: flex;
  margin-vertical: ${spacing.medium}px;
  ${(isDesktopScreen() &&
    css`
      width: ${(props) => (props.tableWidth ? props.tableWidth : '800px')};
    `) ||
  css`
    width: 96%;
    margin: 0 auto;
  `}
  border-radius: 4px;
  height: 100%;
`;

const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  align-items: center;
  ${isDesktopScreen() &&
  css`
    margin-bottom: ${spacing.small300};
  `}
  ${isMobileOrTabletScreen() &&
  css`
    margin-vertical: ${spacing.small200};
  `}
`;
const HeaderRight = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${isMobileOrTabletScreen() &&
  css`
    flex-wrap: wrap;
    flex-basis: fit-content;
    justify-content: flex-start;
    width: ${ScreenWidth()}px;
  `}
`;

const SearchWrap = styled.View`
  flex-direction: row;
  display: flex;
  align-items: center;
  ${isMobileOrTabletScreen() &&
  css`
    flex-wrap: wrap;
    flex-basis: fit-content;
    width: ${ScreenWidth()}px;
  `}
`;
const SearchInput = styled.TextInput`
  font-family: 'Open Sans';
  border-radius: 4px;
  border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
  ${isDesktopScreen() &&
  css`
    width: 100%;
    height: 35px;
  `}
  ${isMobileOrTabletScreen() &&
  css`
    width: 200px;
    height: 45px;
  `}
    background-color: ${colours[GetGlobalTheme()].inputBackground};
  padding-vertical: ${spacing.small200};
  color: ${colours[GetGlobalTheme()].inputText};
  ${IsHRWeb() &&
  css`
    cursor: ${(props) => (props.editable ? 'auto' : 'default')};
    padding-horizontal: ${(props) => (props.viewOnly ? 0 : spacing.small300)};
  `}
  border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
`;

const Title = styled.Text`
  font-size: ${fontSize.large};
  color: ${colours[GetGlobalTheme()].rowText};
  padding-vertical: ${spacing.medium200};
  margin-horizontal: ${spacing.small300};
`;

export let FilterView;
if (isDesktopScreen()) {
  FilterView = styled.View`
    position: absolute;
    top: 100%;
    right: 0px;
    width: 300px;
    background: ${colours[GetGlobalTheme()].lightBackground};
    padding: 20px;
    flex-direction: column;
    align-items: center;
  `;
} else {
  if (IsHRiOS()) {
    FilterView = styled.View`
      position: absolute;
      height: ${ScreenHeight()};
      width: ${ScreenWidth()};
      background: ${colours[GetGlobalTheme()].lightBackground};
      padding: 6px;
    `;
  } else {
    FilterView = styled.Modal``;
  }
}

export const FilterViewInner = styled.View`
  ${isMobileOrTabletScreen() &&
  css`
    height: 100%;
    width: 100%;
    background-color: ${colours[GetGlobalTheme()].siteBackground};
  `};
  ${IsHRiOS() &&
  css`
    padding-top: 50;
  `};
`;

export const FilterFields = styled.View`
  ${isDesktopScreen() &&
  css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    /*margin-right: -30px;*/
  `}
`;
export const FilterField = styled.View`
  ${isDesktopScreen() &&
  css`
    width: 100%;
    /*margin-right: 30px;*/
  `}
`;
export const FilterActions = styled.View`
  display: flex;
  ${(isDesktopScreen() &&
    css`
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
    `) ||
  css`
    flex-direction: column-reverse;
  `}
`;

function EmptyViewTest() {
  let res = [];
  res.push(<View></View>);
  return [res];
}

const BodyContainer = styled.View`
  background-color: ${colours[GetGlobalTheme()].tableBackground};
  font-weight: 600;
  /*overflow: scroll;*/
  display: flex;
  flex-shrink: 0;
  border-radius: 4px;
  /*margin-horizontal: ${spacing.small300};*/
`;

const StyledTitle = styled(DataTable.Title)`
  width: ${(props) => props.columnWidth};
  ${(props) => (props.alignment ? props.alignment : '')};
  ${(isDesktopScreen() &&
    css`
      padding-horizontal: ${spacing.medium};
    `) ||
  css`
    padding-horizontal: ${spacing.small};
  `}
`;

const StyledCell = styled(DataTable.Cell)`
  width: ${(props) => props.columnWidth};
  ${(props) => (props.alignment ? props.alignment : '')};
  ${(isDesktopScreen() &&
    css`
      padding-horizontal: ${spacing.medium};
    `) ||
  css`
    padding-horizontal: ${spacing.small};
  `}
  border-radius: 6px;
  ${(props) => (props.minwidth !== undefined ? 'min-width: ' + props.minwidth : '')}
  ${(props) => (props.maxwidth !== undefined ? 'max-width: ' + props.maxwidth : '')}
`;

const StyledRow = styled(DataTable.Row)`
  background-color: ${(props) => (props.rowIndex % 2 ? colours[GetGlobalTheme()].inputBackground : 'transparent')}
  margin-vertical: 0px;
  ${(props) => (props.active ? 'background-color: ' + colours[GetGlobalTheme()].activeBackground : '')}

  ${
    (isDesktopScreen() &&
      css`
        padding-horizontal: ${spacing.medium300 - spacing.medium};
      `) ||
    css`
      padding-horizontal: ${spacing.small};
    `
  }
  margin-horizontal: ${spacing.medium};
  padding-vertical: 0px;
  border-radius: 6px;
  border-bottom-width: 0px;
  font-size: ${fontSize.medium};
  margin-bottom: ${(props) => (!(props.arrayLength % 2) && props.rowIndex === props.arrayLength - 1 ? '16px' : '0px')}
`;

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].navigationBackground};
  padding-vertical: 0px;
  ${(isDesktopScreen() &&
    css`
      padding-horizontal: ${spacing.medium300};
    `) ||
  css`
    padding-horizontal: ${spacing.medium};
  `}
  font-size: ${fontSize.medium};
  border-bottom-width: 0px;
`;

function CellInner(props) {
  if (IsHRWeb()) {
    return <div title={props.text}>{props.children}</div>;
  } else {
    return <>{props.children}</>;
  }
}

export class TableContainer extends Component<TableContainerProps> {
  constructor(props) {
    super(props);
    let self = this;
    this.state = {
      data: props.data || [],
      update: false,
      activeID: '',
      pagination: {
        page: 0,
        perpage: global.PerPage,
        pages: 0,
        showperiod: false,
        changeMatrixPage: function (page) {
          self.changePage(page);
        },
        changePagePeriod: function (value) {
          self.changePagePeriod(value);
        },
      },
    };
    global.windows.list[global.windows.curwindow].UpdateWindowData = () => {
      self.GetTableData();
    };
    if (UsePagination()) {
      this.GetTableData();
    }
  }

  changePage = (page) => {
    let npagination = this.state.pagination;
    npagination.page = page;
    this.GetTableData();
  };

  changePagePeriod = (value) => {
    let npagination = this.state.pagination;
    npagination.perpage = value;
    npagination.page = 0;
    this.setState({pagination: npagination});
  };

  componentDidUpdate(prevprops) {
    if (prevprops.data !== this.props.data) {
      this.setState({data: this.props.data});
    }
    if (UsePagination()) {
      if (prevprops.update !== this.props.update) {
        this.GetTableData();
      }
    }
  }

  GetTableData = () => {
    let self = this;
    let windef = global.windows.list[global.windows.curwindow];
    let search = windef.cursearch;
    if (search.length < 3) {
      search = '';
    }
    let cust_search = windef.customsearch;
    let bsearch;
    for (let i in cust_search) {
      if (cust_search[i] !== null) {
        bsearch = i;
      }
    }
    Requests.getTableData(
      this.props.table,
      undefined,
      undefined,
      undefined,
      true,
      this.state.pagination.page,
      this.state.pagination.perpage,
      windef.cursort,
      windef.curdir,
      search,
      windef.curstartdate,
      windef.curenddate,
      bsearch
    )
      .then((response) => {
        self.state.pagination.pages = Math.ceil(response.total / self.state.pagination.perpage);
        log('List: ', response.records, LogType.listWindowLog);
        self.setState({
          data: response.records,
          update: self.state.update ? false : true,
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  TableHeader = function () {
    if (IsHRWeb() === false) {
      global.windowNewButton = [];
      if (this.props.extraContent) {
        global.windowNewButton = this.props.extraContent();
      } else {
        global.windowNewButton = EmptyViewTest();
      }
      global.setWindowTitle(this.props.title);
    }
    let datestyle = {};
    if (this.props.extraFilter === undefined) {
      datestyle = {paddingLeft: 0};
    }
    return (
      <View style={{zIndex: 1}}>
        {IsHRWeb() && (
          <HeaderContainer>
            <HeaderRight>
              <SearchWrap>
                {this.props.extraFilter && this.props.extraFilter(this)}
                {WindowHasDateFilter() && UsePagination() && (
                  <>
                    <EditField
                      key={'sd' + this.state.update}
                      setMobileWidth={true}
                      value={GetFilterStartDate()}
                      type={WindowElementType.kInputTypeDate}
                      editable={true}
                      allowClearing={true}
                      placeholdercolor={colours[GetGlobalTheme()].inactiveTitleText}
                      width={isDesktopScreen() ? '130px' : '150px'}
                      onChange={({value}: InputChangeEventProps) => {
                        SetWindowStartDate(value);
                      }}
                      wrapperStyle={datestyle}
                      placeholder={translate('StartDate')}
                    />
                    <Text>{':'}</Text>
                    <EditField
                      key={'ed' + this.state.update}
                      value={GetFilterEndDate()}
                      setMobileWidth={true}
                      type={WindowElementType.kInputTypeDate}
                      width={isDesktopScreen() ? '130px' : '150px'}
                      allowClearing={true}
                      editable={true}
                      placeholdercolor={colours[GetGlobalTheme()].inactiveTitleText}
                      onChange={({value}: InputChangeEventProps) => {
                        SetWindowEndDate(value);
                      }}
                      placeholder={translate('EndDate')}
                    />
                  </>
                )}
                <View>
                  <SearchInput
                    defaultValue={GetWindowSearch()}
                    placeholderTextColor={colours[GetGlobalTheme()].inactiveTitleText}
                    onChangeText={(text) => {
                      SetWindowSearch(text);
                    }}
                    placeholder={translate('Search')}
                  />
                </View>
              </SearchWrap>
              {this.props.extraContent && this.props.extraContent()}
            </HeaderRight>
          </HeaderContainer>
        )}
      </View>
    );
  };

  TableColumns = () => {
    let res = [];
    let cursort = global.windows.list[global.windows.curwindow].cursort;
    this.props.browseColumns.map((column, index) => {
      if (isDesktopScreen() || index < 3) {
        let curdir = '';
        let newdir = 'ascending';
        if (cursort === column.sortKey) {
          curdir = global.windows.list[global.windows.curwindow].curdir;
          newdir = curdir === 'ascending' ? 'descending' : 'ascending';
        }
        let alignment;
        if (column.alignment) {
          alignment = 'justify-content: ' + column.alignment;
        }
        res.push(
          <StyledTitle
            key={index}
            theme={{colors: {text: colours[GetGlobalTheme()].rowText}}}
            columnWidth={column.width}
            alignment={alignment}
            sortDirection={curdir}
            onPress={() => {
              if (column.sortKey !== undefined) {
                UpdateWindowData(column.sortKey, newdir);
              }
            }}>
            {column.name}
          </StyledTitle>
        );
      }
    });
    return res;
  };

  SortTableData = (data, curwin) => {
    let res = data;

    if (global.windows.list[curwin].cursearch !== '') {
      let search = global.windows.list[curwin].cursearch;
      res = res.filter(function (item) {
        return JSON.stringify(item).toLowerCase().indexOf(search) > -1;
      });
    }

    if (Object.keys(global.windows.list[curwin].customsearch).length > 0) {
      let cust_search = global.windows.list[curwin].customsearch;
      res = res.filter(function (item) {
        let tres = false;

        for (let i in cust_search) {
          if (cust_search[i] === null || cust_search[i] === item[i]) {
            tres = true;
          }
        }

        return tres;
      });
    }

    let cursort = global.windows.list[curwin].cursort;
    let curdir = global.windows.list[curwin].curdir;
    res.sort((a, b) => {
      if (curdir === 'ascending') {
        return a[cursort] > b[cursort] ? 1 : -1;
      } else {
        return a[cursort] < b[cursort] ? 1 : -1;
      }
    });

    return res;
  };

  TableData = () => {
    let res = [];
    let tdata = this.state.data;
    if (UsePagination() === false) {
      tdata = this.SortTableData(tdata, global.windows.curwindow);
    }
    let self = this;
    if (tdata) {
      tdata.map((row, index) => {
        res.push(
          <StyledRow
            key={index}
            active={this.state.activeID === row[this.props.rowID]}
            rowIndex={index}
            arrayLength={self.state.data.length}>
            {this.TableCell({row: row, rowindex: index})}
          </StyledRow>
        );
      });
    }
    return res;
  };

  FormatBrowseField = (column, value, value2 = '') => {
    let res = value;
    if (column.type !== undefined) {
      //res = ConvertDateField(value);
      res = ConvertDisplayValue(column.type, value, value2);
    }
    return res;
  };

  TableCell = ({row, rowindex}) => {
    let res = [];
    let self = this;
    this.props.browseColumns.map((column, index) => {
      if (isDesktopScreen() || index < 3) {
        let onpress = undefined;
        let cellStyle = {};
        if (this.props.doClick !== undefined) {
          cellStyle = {cursor: 'pointer'};
          onpress = () => {
            try {
              this.setState({activeID: row[this.props.rowID]});
              this.props.doClick(row[this.props.rowID]);
            } catch (error) {
              logError(error);
            }
          };
        }
        let text = self.FormatBrowseField(column, row[column.key], row[column.key2]);
        let alignment;
        if (column.alignment) {
          alignment = 'justify-content: ' + column.alignment;
        }
        res.push(
          <StyledCell
            key={index}
            columnWidth={column.width}
            minwidth={column.minwidth}
            maxwidth={column.maxwidth}
            alignment={alignment}
            style={cellStyle}
            onPress={onpress}>
            <CellInner column={column} text={text}>
              {column.overwrite?.({
                id: row[this.props.rowID],
                value: row[column.key],
                rowindex: rowindex,
                colindex: index,
                row: row,
              }) || text}
            </CellInner>
          </StyledCell>
        );
      }
    });
    return res;
  };

  TableBody = () => {
    let pd = 10;
    if (isDesktopScreen()) {
      pd = '10px';
    }

    return (
      <BodyContainer>
        <DataTable style={{height: '100%'}}>
          <StyledHeader>{this.TableColumns()}</StyledHeader>
          <ScrollView style={{paddingBottom: pd, flexShrink: 0}}>{this.TableData()}</ScrollView>
        </DataTable>
      </BodyContainer>
    );
  };
  TableFooter = () => {
    return (
      <FooterContainer>
        <Pagination pagination={this.state.pagination} data={this.state.data} />
      </FooterContainer>
    );
  };

  render() {
    return (
      <TableWrapper tableWidth={'100%'}>
        {this.TableHeader()}
        {this.TableBody()}
        {this.TableFooter()}
      </TableWrapper>
    );
  }
}
