import React, {useState} from 'react';
import {OutsideClickWrapper} from '@components/editElements/OutsideClickWrapper';
import {View} from 'react-native';
import {
  PasteSpecialContentStyle,
  PasteSpecialSelectButtonStyle,
  PasteSpecialSelectButtonTextStyle,
} from '@components/editElements/pasteSpecial/pasteSpecial.styles';
import EditField, {EditFieldOnChangeProps} from '@components/editElements/EditField';
import {translate} from '@utils/languageTools';
import {PasteSpecialDisplayType, PasteSpecialWindowLayout} from '@utils/types';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import {ActionButton} from '@components/button/ActionButton';

const ContainerStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderRadius: 12,
  position: 'absolute',
  // @ts-ignore
  filter: 'drop-shadow(0 0 6px rgba(107, 107, 107, 0.15))',
  marginTop: spacing.space12,
  top: -8,
  width: '100%',
  overflow: 'hidden',
  padding: spacing.space12,
};

export type MultiDropDownField = {field: string; editFieldRule: string; value: string; key: string};

export type MultiDropdownPasteSpecialProps = {
  multiDropdownField: MultiDropDownField;
  name: string;
  table: string;
  windowLayout: PasteSpecialWindowLayout;
  mainKey: string;
  onInsertCurrentRecord: (valueArr: string[]) => void;
};

export const ClassificationPasteSpecial: React.FC<MultiDropdownPasteSpecialProps> = ({
  multiDropdownField,
  name,
  table,
  windowLayout,
  mainKey,
  onInsertCurrentRecord,
}) => {
  const [pasteSpecialFields] = useState<string[]>(getInitialMultiDropdownFieldValue());
  const [pasteSpecialValues, setPasteSpecialValues] = useState<string[]>(['']);
  const [pasteSpecials, setPasteSpecials] = useState(getInitialPasteSpecials());

  function getInitialMultiDropdownFieldValue() {
    let multiDropdownFieldValue = multiDropdownField.value.split(',');
    multiDropdownFieldValue.push('Extras');
    return multiDropdownFieldValue;
  }

  function getInitialPasteSpecials() {
    let pasteSpecialsObj = {};
    pasteSpecialFields.forEach((field: string) => {
      pasteSpecialsObj[field] = '';
    });
    pasteSpecialsObj['Extras'] = '';
    return pasteSpecialsObj;
  }

  function setValueToPasteSpecial(editFieldOnChangeProps: EditFieldOnChangeProps, field: string) {
    let pasteSpecialsObj = {...pasteSpecials};
    pasteSpecialsObj[field] = editFieldOnChangeProps.value;
    setPasteSpecials(pasteSpecialsObj);
    const arrayOfValues = Object.values(pasteSpecialsObj).map((value) => String(value));
    setPasteSpecialValues(arrayOfValues);
  }

  function getPasteSpecialFilter(field: string, index: number) {
    if (index === pasteSpecialFields.length - 1) {
      return null;
    }

    return {[multiDropdownField.key]: field};
  }

  return (
    <OutsideClickWrapper onOutsideClick={() => onInsertCurrentRecord(pasteSpecialValues)}>
      <View style={ContainerStyle}>
        <View style={PasteSpecialContentStyle}>
          {pasteSpecialFields.map((field: string, index: number) => (
            <EditField
              title={index === pasteSpecialFields.length - 1 ? translate('Extras') : field}
              value={pasteSpecials[field]}
              name={name}
              onChange={(props) => setValueToPasteSpecial(props, field)}
              pasteSpecial={{
                vcName: table,
                windowLayout: windowLayout,
                mainKey: mainKey,
                filter: getPasteSpecialFilter(field, index),
                multiValue: false,
                isDropDownField: true,
                columns: [
                  {
                    name: translate('Code'),
                    width: '35%',
                    key: 'Code',
                    mainKey: 'Code',
                    displayType: PasteSpecialDisplayType.Name,
                  },
                  {
                    width: '65%',
                    name: translate('Name'),
                    key: 'Comment',
                    displayType: PasteSpecialDisplayType.Description,
                  },
                ],
              }}
              useAutoFill={false}
              allowManualEntry={false}
            />
          ))}
          <ActionButton
            style={PasteSpecialSelectButtonStyle}
            textStyle={PasteSpecialSelectButtonTextStyle}
            onPress={() => onInsertCurrentRecord(pasteSpecialValues)}
            title={translate('Select')}
          />
        </View>
      </View>
    </OutsideClickWrapper>
  );
};
