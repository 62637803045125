import React from 'react';
import {Modal, Text, ToastAndroid, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import EditField from '../../components/edit_elements/EditField';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import KeyEvent from 'react-native-keyevent';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {InputChangeEventProps, OverwriteMatrixProps, WindowDefinition, WindowMatrix, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

function headerFields(table): WindowDefinition {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Location'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Location',
          viewOnly: true,
          width: '150px',
        },
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Qty'),
          windowElementType: WindowElementType.kInputTypeQty,
          dataKey: 'HeaderQty',
          viewOnly: false,
          width: '150px',
          onChange: table.SetCurrentQty,
        },
        {
          label: translate('Item'),
          windowElementType: WindowElementType.kInputTypeBarCode,
          dataKey: 'HeaderArtCode',
          viewOnly: false,
          width: '200px',
          reference: table.ArtCodeRef,
          onChange: table.SetCurrentItem,
        },
      ],
    },
  ];

  if (IsHRWeb()) {
    windowDefinition[0].fields.splice(3, 0, {
      label: translate('Approved'),
      checkboxInactiveLabel: translate('Approve'),
      dataKey: 'OKFlag',
      viewOnly: false,
      windowElementType: WindowElementType.kInputTypeCheckbox,
      onChange: table.updateRecordField,
    });
  } else {
    windowDefinition[0].fields.splice(0, 0, {
      label: translate('Approved'),
      checkboxInactiveLabel: translate('Approve'),
      dataKey: 'OKFlag',
      viewOnly: false,
      windowElementType: WindowElementType.kInputTypeCheckbox,
      onChange: table.updateRecordField,
    });
  }

  return windowDefinition;
}

const matrixFields = (table) => {
  let matrixFields: WindowMatrix = [];

  if (IsHRWeb()) {
    matrixFields.push({
      label: translate('Item'),
      dataKey: 'ArtCode',
      width: '100px',
      viewOnly: true,
      windowElementType: WindowElementType.kInputTypeText,
    });
    matrixFields.push({
      label: translate('Name'),
      dataKey: 'Spec',
      width: '100px',
      viewOnly: true,
      windowElementType: WindowElementType.kInputTypeText,
    });
    matrixFields.push({
      label: translate('InStock'),
      dataKey: 'Qty',
      width: '100px',
      viewOnly: true,
      windowElementType: WindowElementType.kInputTypeText,
    });
  } else {
    matrixFields.push({
      label: translate('Item'),
      dataKey: 'ArtCode',
      dataKey2: 'Spec',
      width: '70%',
      viewOnly: true,
      windowElementType: WindowElementType.kInputTypeJointField,
      overwrite: table.ItemColumn,
    });
    matrixFields.push({
      label: translate('InStock'),
      dataKey: 'Qty',
      width: '30%',
      cellstyle: {textAlign: 'right', justifyContent: 'flex-end'},
      style: {textAlign: 'right'},
      overwrite: table.QtyColumn,
      viewOnly: true,
      windowElementType: WindowElementType.kInputTypeText,
    });
  }
  return matrixFields;
};

interface StockTakingsListState extends ListScreenState {
  px: number;
  py: number;
  newTableHelper: boolean;
  Location: string;
  CurrentItem: string;
  CurrentQty: string;
}

export class StockTakingsList extends ListScreen<{}, StockTakingsListState> {
  ArtCodeUpdatef: boolean;

  constructor(props) {
    super(props);
    this.createNewTable = this.createNewTable.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.table = 'StockTakeVc';
    this.ArtCodeUpdatef = false;
    this.ScreenName = 'StockTakings';
    this.init = true;
    this.state = {
      ...this.state,
      data: [],
      px: 0,
      py: 0,
      newTableHelper: false,
      Location: '',
      CurrentItem: '',
      CurrentQty: '1',
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('StockTakings', 'SerNr', 'descending');
    AddDateFilter('StockTakings');
  }

  createNewTable = () => {
    if (this.state.Location === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let self = this;
    let tdata = {
      record: {
        Location: this.state.Location,
      },
    };
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (response.success === true) {
          self.ShowHideNewTable();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewTable = () => {
    if (this.state.newTableHelper) {
      global.PopEscapeActions();
      this.setState({newTableHelper: false});
    } else {
      this.setState({newTableHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewTable());
    }
  };
  renderNewButton = () => {
    let self = this;
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'stocktaking_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewTable()} title={translate('AddNew')} />
        {this.state.newTableHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('Location') + '*'}
                    name={'Location'}
                    value={this.state.Location}
                    style={{width: 215}}
                    pasteSpecial={{
                      vcName: 'LocationVc',
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'Code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'Name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({Location: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton
                  style={IsHRWeb() ? {marginTop: 20, marginRight: 20} : {}}
                  onPress={() => this.ShowHideNewTable()}
                  title={translate('Cancel')}
                />
                <ActionButton
                  style={IsHRWeb() ? {marginTop: 20} : {}}
                  onPress={() => this.createNewTable()}
                  title={translate('Create')}
                />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    KeyEvent.removeKeyDownListener();
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={[
            {
              name: translate('SerNr'),
              key: 'sernr',
              sortKey: 'SerNr',
              width: '150px',
            },
            {
              name: translate('Location'),
              key: 'location',
              sortKey: 'Location',
              width: '150px',
            },
            {
              name: translate('Date'),
              key: 'transdate',
              sortKey: 'TransDate',
              width: '170px',
              windowElementType: WindowElementType.kInputTypeDate,
            },
            {
              name: translate('Approved'),
              key: 'okflag',
              sortKey: 'OKFlag',
              width: '100px',
              overwrite: DisplayBrowseOKFlag,
            },
          ]}
          doClick={self.rowClick}
          rowID={'sernr'}
          title={translate(this.ScreenName)}
          width="800px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface StockTakingsRecordState extends RecordScreenState {
  data: [];
  newTableHelper: boolean;
  Location: string;
  CurrentItem: string;
  CurrentQty: string;
}

export class StockTakingsRecord extends RecordScreen<{}, StockTakingsRecordState> {
  ArtCodeRef: React.Ref<any>; // TODO: Add type to ref
  ArtCodeUpdatef: boolean;

  constructor(props) {
    super(props);
    let self = this;
    this.updateRecordField = this.updateRecordField.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.SetCurrentItem = this.SetCurrentItem.bind(this);
    this.SetCurrentQty = this.SetCurrentQty.bind(this);

    //this.changePage = this.changePage.bind(this);
    this._isMounted = true;
    this.table = 'StockTakeVc';
    this.ArtCodeRef = React.createRef();
    this.ArtCodeUpdatef = false;
    this.ScreenName = 'StockTakings';
    this.state = {
      ...this.state,
      newTableHelper: false,
      Location: '',
      CurrentItem: '',
      CurrentQty: '1',
    };
    this.record = new WindowRecord(this.table, true, headerFields(this), matrixFields(this));
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.record.SerNr,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record = {};
            self.setState({record: response.record});
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  GetItemInsertData = (item, qty) => {
    let res = {
      list: [
        {
          rownr: this.record.rows.length,
          field: 'ArtCode',
          value: item,
        },
        {
          rownr: this.record.rows.length,
          field: 'Qty',
          value: qty,
        },
      ],
      id: this.record.SerNr,
    };

    for (let i = 0; i < this.record.rows.length; i++) {
      let row = this.record.rows[i];
      let index = i;
      if (row.ArtCode !== undefined) {
        if (row.ArtCode.toUpperCase() === item.toUpperCase()) {
          res = {
            rownr: index,
            field: 'Qty',
            value: (parseInt(row.Qty) || 0) + parseInt(qty),
            id: this.record.SerNr,
          };
          break;
        }
      }
    }

    return res;
  };
  ItemColumn = ({value, value2 = ''}: OverwriteMatrixProps) => {
    return (
      <>
        <Text style={{color: colours[GetGlobalTheme()].inputText}}>{value}</Text>
        {value2 !== '' && <Text style={{color: colours[GetGlobalTheme()].inputText}}>{value2}</Text>}
      </>
    );
  };
  QtyColumn = ({value}: OverwriteMatrixProps) => {
    return <Text style={{color: colours[GetGlobalTheme()].inputText}}>{value}</Text>;
  };

  SetCurrentItem = (rowindex, index, value) => {
    if (value !== '' && value !== null && value !== undefined) {
      let self = this;
      this.state.CurrentItem = value;
      let data = this.GetItemInsertData(this.state.CurrentItem, this.state.CurrentQty);
      let trec = Object.assign({}, this.record);
      trec.rows = this.record.rows.slice();
      let nrec = this.record;
      if (objectHasProperty(data, 'rownr')) {
        nrec.rows[data.rownr][data.field] = data.value;
      } else {
        nrec.rows[data.list[0].rownr] = {};
        nrec.rows[data.list[0].rownr][data.list[0].field] = data.list[0].value;
        nrec.rows[data.list[1].rownr][data.list[1].field] = data.list[1].value;
      }
      this.state.CurrentQty = '1';
      this.state.CurrentItem = '';
      nrec.HeaderArtCode = ' ';
      nrec.HeaderQty = ' ';

      this.setState({record: nrec}, function () {
        nrec.HeaderArtCode = '';
        nrec.HeaderQty = '1';
        self.ArtCodeUpdatef = true;
        self.setState({record: nrec}, function () {
          self.ArtCodeRef.current.focus();
        });
      });

      Requests.updateRecordData(this.table, data)
        .then((response) => {
          if (response.success) {
            if (self._isMounted) {
              self.record = {};
              response.record.HeaderArtCode = '';
              response.record.HeaderQty = '1';
              self.setState({record: response.record}, function () {
                self.ArtCodeRef.current.focus();
              });
              if (IsHRWeb() === false) {
                ToastAndroid.show(translate('ItemAdded').replace('{Item}', value), ToastAndroid.SHORT);
              }
            }
          } else {
            self.record = {};
            trec.HeaderArtCode = ' ';
            trec.HeaderQty = ' ';

            self.setState({record: trec}, function () {
              trec.HeaderArtCode = '';
              trec.HeaderQty = '1';
              self.setState({record: trec});
            });
          }
        })
        .catch((error) => {
          self.record = {};
          trec.HeaderArtCode = ' ';
          trec.HeaderQty = ' ';

          self.setState({record: trec}, function () {
            trec.HeaderArtCode = '';
            trec.HeaderQty = '1';
            self.setState({record: trec});
          });
          logError(error);
        });
    }
  };

  SetCurrentQty = (value) => {
    let nrec = this.record;
    nrec.HeaderQty = value.toString();
    this.state.CurrentQty = value.toString();
    this.setState({record: nrec});
  };

  render() {
    let self = this;
    KeyEvent.removeKeyDownListener();
    return (
      <WindowWrap>
        <InspectWindow
          title={translate('StockTaking')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          matrixFields={matrixFields(this)}
          okcheck={'OKFlag'}
          allowDelete={true}
          allowPrint={false}
          width="800px"
          backButton={() => goBack(self, self.ScreenName)}
        />

        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

function StockTakings() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="StockTakingsList"
        component={StockTakingsList}
        options={{
          title: translate('StockTakings'),
        }}
      />
      <Drawer.Screen
        name="StockTakingsRecord"
        component={StockTakingsRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('StockTaking'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default StockTakings;
