import {BrowseColumn} from '@components/screens/types';
import {DoNavigate, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import ToolTip from '@components/design/ToolTip';
import {RowContainer} from '@styles/primitiveComponents';
import React from 'react';
import {
  displayApprovalStatusWithIcons,
  displayBrowseOKFlag,
  displayOrdDate,
  displayOrderStatus,
  displaySumwithCurrency,
  getMobileDisplayInvDate,
  getMobileDisplaySumWithCurrency,
} from '@tools/recordListDisplayTools';
import {MobileBrowseColumnDisplayType, RecordListWindowDefinition} from '@src/types/Record/windowDefinitions';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import styled from 'styled-components/native';
import {OverwriteBrowseColumnProps} from '@utils/types';
import {isApprovalRulesSupported} from '@src/utils/drawersTools';

const CopyButton = styled.TouchableOpacity`
  width: 40px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #f4f7fa;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

export class SalesOrdersRecordListDefinition extends RecordListWindowDefinition {
  window;

  constructor(window) {
    super();
    this.window = window;
  }

  copyRecord(id) {
    DoNavigate(this.window, 'SalesOrders', true, 'new-' + id);
  }

  DisplayCopyButton = ({row}) => {
    return (
      <ToolTip tooltipText={translate('Copy')}>
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <CopyButton onPress={() => this.copyRecord(row.sernr)}>
            <PortalIcon iconName={'DuplicateIcon'} iconHeight={12} />
          </CopyButton>
        </RowContainer>
      </ToolTip>
    );
  };

  getBrowseColumns(): BrowseColumn[] {
    if (isMobileOrTabletScreen()) {
      return this.getMobileBrowseColumns();
    }

    return this.getDesktopBrowseColumns();
  }

  getDesktopBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('SerNr'),
        key: 'sernr',
        sortKey: 'SerNr',
        alignment: 'left',
        width: '124px',
      },
      {
        name: translate('CustCode'),
        key: 'custcode',
        sortKey: 'CustCode',
        alignment: 'left',
        width: '122px',
      },
      {
        name: translate('Name'),
        key: 'addr0',
        sortKey: 'Addr0',
        alignment: 'left',
        width: '151px',
      },
      {
        name: translate('OrdDate'),
        key: 'orddate',
        sortKey: 'OrdDate',
        alignment: 'left',
        width: '135px',
        windowElementType: WindowElementType.kInputTypeDate,
        overwrite: displayOrdDate,
      },
      {
        name: translate('Total'),
        key: 'sum4',
        sortKey: 'Sum4',
        alignment: 'right',
        width: '111px',
        windowElementType: WindowElementType.kInputTypeValFull,
        overwrite: displaySumwithCurrency,
      },
      {
        name: translate('Class'),
        key: 'orderclass',
        sortKey: 'OrderClass',
        alignment: 'left',
        width: '112px',
      },
      {
        name: translate('CustOrdNr'),
        key: 'custordnr',
        sortKey: 'CustOrdNr',
        alignment: 'left',
        width: '125px',
      },
      {
        name: translate('Status'),
        key: 'okflag',
        sortKey: 'OKFlag',
        alignment: 'left',
        width: '142px',
        overwrite: displayBrowseOKFlag,
      },
      {
        name: translate('OrderStatus'),
        key: 'addr3',
        sortKey: 'Addr3',
        alignment: 'center',
        width: '131px',
        overwrite: displayOrderStatus,
      },
      {
        name: '',
        key: 'copy',
        alignment: 'center',
        width: '64px',
        overwrite: this.DisplayCopyButton,
      },
    ];
    if (isApprovalRulesSupported()) {
      browseColumns.splice(9, 0, {
        name: translate('ApprovalStatus'),
        key: 'approval',
        sortKey: 'Approval',
        checkUndefined: true,
        alignment: 'left',
        width: '152px',
        overwrite: displayApprovalStatusWithIcons,
      });
    }

    return browseColumns;
  }

  getMobileBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('Name'),
        key: 'addr0',
        sortKey: 'Addr0',
        alignment: 'left',
        width: '151px',
        mobileDisplayType: MobileBrowseColumnDisplayType.title,
      },
      {
        name: translate('OrdDate'),
        key: 'orddate',
        sortKey: 'OrdDate',
        alignment: 'left',
        width: '140px',
        windowElementType: WindowElementType.kInputTypeDate,
        overwrite: (props: OverwriteBrowseColumnProps) => getMobileDisplayInvDate(props, 'sernr'),
        mobileDisplayType: MobileBrowseColumnDisplayType.underTitle,
      },
      {
        name: translate('Total'),
        key: 'sum4',
        sortKey: 'Sum4',
        alignment: 'right',
        width: '111px',
        windowElementType: WindowElementType.kInputTypeValFull,
        overwrite: (props: OverwriteBrowseColumnProps) =>
          getMobileDisplaySumWithCurrency(props, 'paydate', 'curncycode', 'paid'),
        mobileDisplayType: MobileBrowseColumnDisplayType.rightTitle,
      },
      {
        name: translate('OrderStatus'),
        key: 'addr3',
        sortKey: 'Addr3',
        alignment: 'left',
        width: '142px',
        overwrite: displayOrderStatus,
        mobileDisplayType: MobileBrowseColumnDisplayType.rightUnderTitle,
      },
    ];
    return browseColumns;
  }
  getHiddenFields(): Array<string> {
    return ['PriceList', 'RebCode'];
  }
}
