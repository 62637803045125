import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from '@tools/displayTools';
import MCIIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {TextNormal} from '@src/styles/primitiveComponents';
import {PortalIcon} from '../portalIcon/PortalIcon';

const ButtonWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputLabel = styled.View`
  margin-left: ${spacing.space16}px;
`;
const InputLabelText = styled(TextNormal)`
  color: ${colours[GetGlobalTheme()].label};
`;
const Checkbox = styled.View`
  border-radius: ${spacing.space2}px;
  border: ${colours[GetGlobalTheme()].disabledButton};
  background: ${colours[GetGlobalTheme()].disabledButton};
  width: ${spacing.space12}px;
  height: ${spacing.space12}px;
`;
const CheckBoxWrap = styled.View`
  position: relative;
  width: ${spacing.space12}px;
  height: ${spacing.space12}px;
`;
const CheckboxTick = styled.View`
  position: absolute;
  width: ${spacing.space12}px;
  height: ${spacing.space12}px;
  top: 3px;
`;

class CheckboxButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      px: 0,
      py: 0,
    };
  }

  render() {
    let self = this;
    let styles = {};

    return (
      <ButtonWrapper
        style={{...styles, ...this.props.style}}
        onPress={() => {
          if (self.props.editable) {
            self.props.onChange({
              rowindex: -1,
              fieldname: this.props.name,
              value: this.props.value === '1' ? 0 : 1,
              inspectWindow: this.props.inspectWindow,
            });
          } else {
            if (self.props.tooltip) {
              global.messageBox({
                showMessage: true,
                statusMessage: self.props.tooltip,
              });
            }
          }
        }}>
        <CheckBoxWrap>
          <Checkbox color={colours[GetGlobalTheme()].disabledButton} />
          {this.props.value === '1' && (
            <CheckboxTick>
              <PortalIcon iconName={'CheckboxIcon'} iconHeight={6} iconColor={colours[GetGlobalTheme()].white} />
            </CheckboxTick>
          )}
        </CheckBoxWrap>
        <InputLabel>
          <InputLabelText>{this.props.label}</InputLabelText>
        </InputLabel>
      </ButtonWrapper>
    );
  }
}

export default CheckboxButton;
