import React from 'react';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {translate} from '@utils/languageTools';

const YesNoNav = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const YesNoBackground = styled.View`
  position: fixed;

  ${IsHRWeb() === false &&
  css`
    position: absolute;
  `}

  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const YesNoWrap = styled.View`
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  margin-top: 40px;
`;
const YesNoQuestion = styled.View`
  margin-bottom: 15px;
  text-align: center;
`;
const YesNoQuestionText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;

export const YesNoWindow = (props) => {
  let yeslab = translate('Yes');
  if (props.no === undefined) {
    yeslab = translate('OK');
  }
  return (
    <YesNoBackground>
      <YesNoWrap>
        <YesNoQuestion>
          <YesNoQuestionText>{props.question}</YesNoQuestionText>
        </YesNoQuestion>
        <YesNoNav>
          <ActionButton onPress={() => props.yes()} title={yeslab} />
          {props.no !== undefined && (
            <ActionButton onPress={() => props.no()} title={translate('No')} />
          )}
        </YesNoNav>
      </YesNoWrap>
    </YesNoBackground>
  );
};
