import React from 'react';
import {Modal, Text, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {YesNoWindow} from '../status/YesNoWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {AddWindowFilter, GetWindowFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import CheckboxButton from '../../components/edit_elements/CheckboxButton';
import {browseColumns, headerFields, matrixFields} from './columns/ContractsColumns';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {InputChangeEventProps, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

interface ContractsListState extends ListScreenState {
  newContractHelper: boolean;
  px: number;
  py: number;
  NewEmp: string;
  NewPost: string;
  NewDate: string;
}

export class ContractsList extends ListScreen<{}, ContractsListState> {
  constructor(props) {
    super(props);
    this.createNewContract = this.createNewContract.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    //this.changePage = this.changePage.bind(this);
    // parameters?
    this.ScreenName = 'Contracts';
    this.table = 1;
    this.init = true;
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
      newContractHelper: false,
      px: 0,
      py: 0,
      NewEmp: '',
      NewPost: '',
      NewDate: '',
    };
    SetCurrentWindow2('Contracts', 'ContractNr', 'ascending');
    if (global.userData.provider === 0) {
      AddWindowFilter('Contracts', 'closed', '0');
    }
  }

  CheckNewContractData = (obj) => {
    let res = true;
    if (obj.record.StartDate === '') {
      res = false;
    }
    if (global.userData.provider === 0) {
      if (obj.record.EmpCode === '') {
        res = false;
      }
      if (obj.record.PostCode === '') {
        res = false;
      }
    } else {
      if (obj.record.EmplCode === '') {
        res = false;
      }
    }
    return res;
  };

  createNewContract = () => {
    let self = this;
    let tdata;
    if (global.userData.provider === 0) {
      tdata = {
        record: {
          EmpCode: this.state.NewEmp,
          StartDate: this.state.NewDate,
          PostCode: this.state.NewPost,
        },
      };
    } else {
      tdata = {
        record: {
          EmplCode: this.state.NewEmp,
          StartDate: this.state.NewDate,
          ITFStartDate: this.state.NewDate,
        },
      };
    }
    if (this.CheckNewContractData(tdata) === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    Requests.getNewRecord(1, tdata)
      .then((response) => {
        if (response.success) {
          this.state.NewEmp = '';
          this.state.NewPost = '';
          self.ShowHideNewContract();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.ContractNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewContract = () => {
    if (this.state.newContractHelper) {
      global.PopEscapeActions();
      this.setState({newContractHelper: false});
    } else {
      this.setState({newContractHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewContract());
    }
  };

  DisplayBrowseClosed = (value, curval) => {
    if (curval === '1') {
      return <Text>✔</Text>;
    } else {
      return <Text></Text>;
    }
  };

  showExtraFilter = (browsewindow) => {
    let self = this;
    if (global.userData.provider === 0 && isDesktopScreen()) {
      return (
        <View>
          <CheckboxButton
            value={GetWindowFilter('Contracts', 'closed') === '0' ? '0' : '1'}
            onChange={({value}: InputChangeEventProps) => {
              if (value === 1) {
                AddWindowFilter('Contracts', 'closed', null);
              } else {
                AddWindowFilter('Contracts', 'closed', '0');
              }
              if (UsePagination()) {
                self.setState({update: self.state.update ? false : true});
                browsewindow.GetTableData();
              } else {
                self.setState({update: self.state.update ? false : true});
              }
            }}
            style={{marginLeft: 0}}
            editable={true}
            title={translate('ShowActive')}
            title2={translate('ShowAll')}
          />
        </View>
      );
    }
  };

  renderNewButton = () => {
    let self = this;
    let style = {};
    if (global.userData.provider === 1) {
      return;
    }
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'contract_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewContract()} title={translate('AddNew')} />
        {this.state.newContractHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('StartDate') + '*'}
                    name={'StartDate'}
                    value={this.state.NewDate}
                    editable={true}
                    type={WindowElementType.kInputTypeDate}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewDate: value})}
                  />
                </FilterField>
                {global.userData.provider === 0 && (
                  <FilterField>
                    <EditField
                      title={translate('Employee') + '*'}
                      name={'EmpCode'}
                      value={this.state.NewEmp}
                      style={{width: '100%'}}
                      pasteSpecial={{
                        vcName: 0,
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'empcode',
                            mainkey: 'EmpCode',
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'name',
                          },
                        ],
                      }}
                      onChange={({value}: InputChangeEventProps) => self.setState({NewEmp: value})}
                    />
                  </FilterField>
                )}
                {global.userData.provider === 1 && (
                  <FilterField>
                    <EditField
                      title={translate('Employee') + '*'}
                      name={'EmpCode'}
                      value={this.state.NewEmp}
                      style={{width: '100%'}}
                      pasteSpecial={{
                        vcName: 0,
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'empcode',
                            mainkey: 'EmpCode',
                          },
                          {
                            width: '35%',
                            name: translate('Name'),
                            key: 'firstname',
                          },
                          {
                            width: '30%',
                            name: '',
                            key: 'surname',
                          },
                        ],
                      }}
                      onChange={({value}: InputChangeEventProps) => self.setState({NewEmp: value})}
                    />
                  </FilterField>
                )}
                {global.userData.provider === 0 && (
                  <FilterField>
                    <EditField
                      title={translate('Post') + '*'}
                      name={'PostCode'}
                      value={this.state.NewPost}
                      style={{width: '100%'}}
                      pasteSpecial={{
                        vcName: 11,
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'PostCode',
                            mainkey: 'PostCode',
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'PostName',
                          },
                        ],
                      }}
                      onChange={({value}: InputChangeEventProps) => self.setState({NewPost: value})}
                    />
                  </FilterField>
                )}
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewContract()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewContract()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={browseColumns(this)}
          doClick={self.rowClick}
          rowID={'contractnr'}
          title={translate(this.ScreenName)}
          extraFilter={this.showExtraFilter}
          width="820px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface ContractsRecordState extends RecordScreenState {
  data: [];
  newContractHelper: boolean;
  NewEmp: string;
  NewPost: string;
  NewDate: string;
}

export class ContractsRecord extends RecordScreen<{}, ContractsRecordState> {
  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.ScreenName = 'Contracts';
    this.table = 1;
    this.IDField = 'ContractNr';
    this.state = {
      ...this.state,
      newContractHelper: false,
      NewEmp: '',
      NewPost: '',
      NewDate: '',
    };
    this.record = new WindowRecord(this.table, true, headerFields(this), matrixFields(this));
    this.navigation = props.navigation;
    this.rowClick(props.route?.params.id);
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(1, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  shouldShowMatrixRow = (row) => {
    let res = false;
    if (['1', '2', 'Uzstādīt', 'Uzstādīt pamatalgu'].indexOf(row.Type) > -1) {
      res = true;
    }
    return res;
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <InspectWindow
          title={translate('Contract')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          matrixFields={matrixFields(this)}
          onClick={this.handleInputClick}
          showrow={(row) => this.shouldShowMatrixRow(row)}
          allowDelete={true}
          allowPrint={IsHRWeb()}
          backButton={() => goBack(self, self.ScreenName)}
        />
        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

function Contracts() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="ContractsList"
        component={ContractsList}
        options={{
          title: translate('Contracts'),
        }}
      />
      <Drawer.Screen
        name="ContractsRecord"
        component={ContractsRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('Contract'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default Contracts;
