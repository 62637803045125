import React from 'react';
import styled from 'styled-components/native';
import {ActionButton} from '@src/components/button/ActionButton';
import {ViewMode} from '@src/components/screens/types';
import {colours, spacing} from '@src/styles/Style';
import {DoNavigate, GetGlobalTheme, goBack} from '@src/tools/displayTools';
import {translate} from '@src/utils/languageTools';
import {CopyButton, DeleteButton, PrintButton} from '@components/editElements/InspectWindowActionButtons';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {InspectWindow} from './InspectWindow';
import {checkUserLeavingEditMode} from '@src/components/messageBox/Tools';

const TopBar = styled.View`
  justify-content: space-between;
  flex-direction: row;
`;

const TopBarLeft = styled.View``;

const TopBarRight = styled.View`
  flex-direction: row;
  gap: ${spacing.space8};
`;

const ActionsButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  padding: spacing.space12,
};

const ActionButtonIconStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
};

type InspectTopBarProps = {
  window: RecordScreen<any, any>;
  viewMode: ViewMode;
  allowPrint: boolean;
  hasBackButton: boolean;
  allowDelete: boolean;
  inspectWindow: InspectWindow;
};

export const InspectTopBar: React.FC<InspectTopBarProps> = (props) => {
  let topBar = [];

  let leftSide = [];
  let rightSide = [];
  if (props.hasBackButton) {
    leftSide.push(
      <ActionButton
        title={translate('Back')}
        iconName={'ArrowIcon'}
        iconColor={colours[GetGlobalTheme()].lightBlue}
        iconStyle={{transform: 'rotate(270deg)', marginRight: spacing.space4}}
        onPress={() => goBack(props.window, props.window.ScreenName)}
        style={{border: 'none', background: 'none', flexDirection: 'row-reverse', padding: 0}}
        textStyle={{color: colours[GetGlobalTheme()].lightBlue}}
      />
    );
  }
  if (props.viewMode === ViewMode.View) {
    rightSide.push(
      <CopyButton
        onPress={() => {
          props.window.record = props.window.createNewRecord();
          DoNavigate(props.window, props.window.ScreenName, true, 'new-' + props.window.id);
        }}
        style={ActionsButtonStyle}
        iconStyle={ActionButtonIconStyle}
        hasLabel={false}
      />
    );
    rightSide.push(
      <PrintButton
        allowPrint={props.allowPrint}
        onPress={() => props.inspectWindow.showPreview()}
        style={ActionsButtonStyle}
        iconStyle={ActionButtonIconStyle}
        hasLabel={false}
      />
    );
    rightSide.push(
      <DeleteButton
        window={props.window}
        allowDelete={props.allowDelete}
        style={ActionsButtonStyle}
        iconStyle={ActionButtonIconStyle}
        hasLabel={false}
        inspectWindow={props.inspectWindow}
      />
    );
  }

  if (props.inspectWindow.props.headerFields.sectionList) {
    if (props.inspectWindow.props.headerFields.editable) {
      switch (props.viewMode) {
        case ViewMode.View:
          rightSide.push(
            <ActionButton
              title={translate('Edit')}
              style={{backgroundColor: colours[GetGlobalTheme()].secondary}}
              onPress={() => {
                checkUserLeavingEditMode(() => {
                  props.inspectWindow.getActiveEditFields(() => {
                    props.inspectWindow.setState({viewMode: ViewMode.Edit});
                    global.activeInspectWindow = props.inspectWindow;
                  });
                });
              }}
            />
          );
          break;
        case ViewMode.Edit:
          rightSide.push(
            <ActionButton
              title={translate('Save')}
              style={{backgroundColor: colours[GetGlobalTheme()].secondary}}
              onPress={() => props.inspectWindow.storeWindowRecord()}
            />
          );
          break;
      }
    }
  }

  topBar.push(
    <TopBar>
      <TopBarLeft>{leftSide}</TopBarLeft>
      <TopBarRight>{rightSide}</TopBarRight>
    </TopBar>
  );

  return topBar;
};
