import React from 'react';
import {View} from 'react-native';
import {Tab} from '@components/screens/tabSection/TabSection';
import {ActionButton} from '@components/button/ActionButton';
import {GetGlobalTheme, isMobileOrTabletScreen} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';

const ContainerStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  gap: spacing.space4,
  borderRadius: spacing.space12,
  padding: spacing.space8,
  flexDirection: 'row',
};

type TabsProps = {
  activeTabIndex: number;
  tabs: Tab[];
  // eslint-disable-next-line no-unused-vars
  onTabClick: (id: number) => void;
};

const selectedTextColor = colours[GetGlobalTheme()].lightBlue400;
const defaultTextColor = colours[GetGlobalTheme()].darkBlue;
const selectedMobileTextColor = colours[GetGlobalTheme()].white;
const defaultMobileTextColor = colours[GetGlobalTheme()].darkBlue;
const selectedBackgroundColor = colours[GetGlobalTheme()].lightBlue100;
const defaultBackgroundColor = '';

export const Tabs: React.FC<TabsProps> = ({activeTabIndex, tabs, onTabClick}) => {
  function getDesktopTabButtonStyle(index: number) {
    let style: React.CSSProperties = {
      backgroundColor: 'none',
      borderRadius: spacing.space12,
      padding: spacing.space16,
    };

    style.backgroundColor = index === activeTabIndex ? selectedBackgroundColor : defaultBackgroundColor;
    return style;
  }

  function getMobileTabButtonStyle(index: number) {
    const style = {
      display: 'flex',
      padding: '4px 12px',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #081d42',
      borderRadius: '50px',
      backgroundColor: index === activeTabIndex ? colours[GetGlobalTheme()].darkBlue : 'transparent',
      borderColor: colours[GetGlobalTheme()].darkBlue,
    };
    return style;
  }
  function getTabButtonStyle(index: number) {
    if (isMobileOrTabletScreen()) {
      return getMobileTabButtonStyle(index);
    }

    return getDesktopTabButtonStyle(index);
  }

  function getTabButtonTextStyle(index) {
    const styleObj: React.CSSProperties = {};
    if (isMobileOrTabletScreen()) {
      styleObj.color = index === activeTabIndex ? selectedMobileTextColor : defaultMobileTextColor;
    } else {
      styleObj.color = index === activeTabIndex ? selectedTextColor : defaultTextColor;
    }
    return styleObj;
  }

  return (
    <View style={ContainerStyle}>
      {tabs.map((tab, index) => (
        <ActionButton
          key={index}
          title={tab.tabName}
          style={getTabButtonStyle(index)}
          textStyle={getTabButtonTextStyle(index)}
          onPress={() => onTabClick(index)}
        />
      ))}
    </View>
  );
};
