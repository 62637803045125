import {PDFViewer} from './PDFViewer';
import React, {Component} from 'react';
import {Alert, Image, PermissionsAndroid, Platform, ToastAndroid, View} from 'react-native';
import styled, {css} from 'styled-components/native';

import {colours, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRiOS, IsHRWeb} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {translate} from '@utils/languageTools';
import Requests from '../../api/Requests';
import LoadingCircle from '../../assets/images/LoadingCircle';
import DocumentPicker from 'react-native-document-picker';
import {Dirs, FileSystem} from 'react-native-file-access';
import {b64toBlob} from '../../Tools/Base64Decode';
import {DefaultIconButton} from '../button/DefaultIconButton';
import {exc_fs} from '../../Tools/Stubs';
import SafeView from './SafeView';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {isMobileOrTabletScreen} from '@src/tools/displayTools';

const AttachmentWrap = styled.View`
  margin: 0 auto;
  padding-vertical: ${spacing.small200}px;
  padding-horizontal: ${spacing.small400}px;
  min-height: 400;
  flex-grow: 1;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  width: 100%;
  ${IsHRWeb() === false &&
  css`
    height: 100%;
    margin: 0;
    padding-top: 20px;
  `}
`;
const AttachmentHeader = styled.View`
  ${IsHRiOS() && css``}
`;
const AttachmentList = styled.View``;
const LoadingWrap = styled.View`
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const AttachFile = styled.View`
  flex-direction: row;
  align-items: center;
  ${IsHRWeb() === false &&
  css`
    padding-horizontal: ${spacing.small300}px;
    padding-vertical: ${spacing.small300}px;
  `}
`;
const AttachName = styled.TouchableOpacity`
  ${IsHRWeb() &&
  css`
    margin-right: ${spacing.medium300};
  `}
  flex-grow: 1;
  width: 60%;
`;

const AttachNameText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
  font-size: 23;
  ${IsHRWeb() &&
  css`
    white-space: nowrap;
  `}
  text-overflow: ellipsis;
  overflow: hidden;
  ${IsHRWeb() &&
  css`
    font-size: 14;
  `}
`;

const AttachmentBackground = styled.View`
  position: absolute;
  height: 100%;
  right: 0px;
  width: 350px;
  ${isMobileOrTabletScreen() &&
  css`
    width: 100%;
  `}
`;
//rgba(0, 0, 0, 0.4)
const AttachPreview = styled.View`
  width: 100%;
  flex-grow: 1;
`;
const PreviewHeader = styled.View``;
const PreviewContent = styled.View`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const permissionWriteExternalStorage = async () => {
  const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);
  return granted === PermissionsAndroid.RESULTS.GRANTED;
};

export class AttachmentWindow extends Component {
  constructor(props) {
    super(props);
    this.MobileFileNavigation = this.MobileFileNavigation.bind(this);
    //this.props = props;
    this.fileUploadRef = React.createRef();
    this.state = {
      attachmentList: [],
      showPreview: false,
      activeFile: {},
      fileList: {},
      visible: props.visible,
      loading: false,
      uploading: false,
      loaded: false,
    };
    this.LoadAttachments();
  }

  LoadAttachments = () => {
    let self = this;
    if (this.props.id !== undefined) {
      Requests.doAction('getattachlist', {
        id: this.props.id,
        regname: this.props.table,
      })
        .then((response) => {
          self.setState({
            loaded: true,
            attachmentList: response.records,
            showPreview: false,
          });
          global.setAttachmentBadge(response.records.length);
        })
        .catch((error) => {
          logError(error);
        });
    }
  };
  WriteAndroidFile = async (name, file) => {
    //const filePath = `${RNFetchBlob.fs.dirs.DownloadDir}/${name}`;
    const filePath = `${Dirs.DocumentDir}/${name}`;
    await FileSystem.writeFile(filePath, file, 'base64');
    if (!FileSystem.exists(filePath)) return; // check to see if our filePath was created
    await FileSystem.cpExternal(filePath, name, 'downloads'); // copies our file to the downloads folder/directory
    ToastAndroid.show(translate('FileStoredInDownloads'), ToastAndroid.SHORT);
  };
  WriteiOSFile = async (name, file) => {
    const filePath = `${Dirs.DocumentDir}/${name}`;
    await FileSystem.writeFile(filePath, file, 'base64');
    Alert.alert('', translate('FileStoredInDocuments'));
  };

  GetFile = async (el) => {
    let file = null;
    if (objectHasProperty(this.state.fileList, el.UUID)) {
      file = this.state.fileList[el.UUID];
    }
    if (!file) {
      let response = await Requests.doAction('getfile', {
        id: this.props.id + '&file=' + el.UUID,
        regname: this.props.table,
      });
      file = response.file;
      if (file) {
        this.state.fileList[el.UUID] = file;
      }
    }

    // Get the current 'global' time from an API using Promise
    return new Promise((resolve, reject) => {
      if (file) {
        resolve(file);
      } else {
        reject('Error');
      }
    });
  };

  DeleteFile = async (el) => {
    let self = this;

    Requests.doAction('deleteattachment', {
      id: this.props.id + '&file=' + el.UUID,
      regname: this.props.table,
    })
      .then(() => {
        self.LoadAttachments();
      })
      .catch((error) => {
        logError(error);
      });
  };

  DownloadFile = async (el) => {
    let self = this;
    switch (Platform.OS) {
      case 'android':
        {
          const permissionGranted = await permissionWriteExternalStorage();
          if (permissionGranted) {
            this.GetFile(el)
              .then((file) => {
                self.WriteAndroidFile(el.Name, file);
              })
              .catch((error) => {
                logError(error);
              });
          }
        }
        break;
      case 'ios':
        this.GetFile(el)
          .then((file) => {
            self.WriteiOSFile(el.Name, file);
          })
          .catch((error) => {
            logError(error);
          });
        break;
      default:
        //download web file
        this.GetFile(el)
          .then((file) => {
            let blob = b64toBlob(file, 'application/octet-stream');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = el.Name;
            a.target = '_blank';
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            logError(error);
          });
    }
  };

  ClickUploadButton = async () => {
    let self = this;
    if (IsHRWeb()) {
      this.fileUploadRef.current.click();
    } else {
      try {
        await DocumentPicker.pickSingle({
          type: [DocumentPicker.types.allFiles],
        })
          .then((resolve) => {
            //Printing the log realted to the file
            exc_fs
              .readFile(resolve.uri, 'base64')
              .then((data) => {
                Requests.doAction('doupload', {
                  id: self.props.id,
                  regname: self.props.table,
                  base64: data,
                  filename: resolve.name,
                })
                  .then(() => {
                    self.setState({uploading: false});
                    self.LoadAttachments();
                  })
                  .catch((error) => {
                    logError(error);
                  });
              })
              .catch((error) => {
                logError(error);
              });
          })
          .catch((reject) => {
            console.log('Reject: ', reject);
            //mErrorCallback.invoke(exception.getMessage());
          });
      } catch (err) {
        //Handling any exception (If any)
        if (DocumentPicker.isCancel(err)) {
          //If user canceled the document selection
          alert('Canceled from single doc picker');
        } else {
          //For Unknown Error
          alert('Unknown Error: ' + JSON.stringify(err));
          throw err;
        }
      }
    }
  };

  ShowPreview = (el) => {
    let self = this;
    this.setState({loading: true});
    this.GetFile(el)
      .then((file) => {
        self.setState({
          loading: false,
          showPreview: true,
          activeFile: {base64: file, name: el.Name, uuid: el.UUID},
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  FindFirstValidAttach = () => {
    let res = null;
    for (let i = 0; i < this.state.attachmentList.length; i++) {
      let el = this.state.attachmentList[i];
      let ext = el.Name.split2('.').pop();
      switch (ext) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif':
        case 'pdf':
          res = el;
          i = this.state.attachmentList.length;
          break;
      }
    }
    return res;
  };

  componentDidUpdate() {
    if (this.state.loaded && this.state.visible) {
      if (IsHRWeb()) {
        this.state.loaded = false;
        if (this.state.attachmentList.length > 0) {
          let el = this.FindFirstValidAttach();
          if (el) {
            this.ShowPreview(el);
          }
        }
      }
    }
  }

  ShowLoading = (typef) => {
    let res = [];
    let dim = 70;
    if (typef === 0) {
      dim = 30;
    }
    res.push(
      <LoadingWrap>
        <Image
          style={{height: dim, width: dim}}
          resizeMode={'contain'}
          resizeMethod={'scale'}
          source={{uri: LoadingCircle}}
          alt="Loading"
        />
      </LoadingWrap>
    );
    return res;
  };

  ShowPreviewFile = () => {
    let res = [];
    let self = this;
    let ext = this.state.activeFile.name.split2('.').pop();
    switch (ext) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
      case 'gif':
        if (IsHRWeb()) {
          res.push(
            <img
              style={{maxHeight: '100%', maxWidth: this.state.width}}
              src={'data:image/' + ext + ';base64,' + self.state.activeFile.base64}
              alt={self.state.activeFile.name}
            />
          );
        } else {
          res.push(
            <Image
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                aspectRatio: 1,
                resizeMode: 'contain',
                width: '100%',
              }}
              source={{
                uri: 'data:image/' + ext + ';base64,' + self.state.activeFile.base64,
              }}
              alt={self.state.activeFile.name}
            />
          );
        }
        break;
      case 'pdf':
        res.push(
          <PDFViewer
            base64={self.state.activeFile.base64}
            onClose={() => self.setSate({showPreview: false})}
            style={{height: '100%', width: '100%', border: 'none'}}
            header={() => self.MobileFileNavigation()}
          />
        );
        break;
      default:
        console.log('different file type');
    }

    return res;
  };

  find_dimesions(layout) {
    // TODO: Create x y width height Type
    const {width} = layout;
    this.state.width = width;
  }

  DoUploadFile = () => {
    let self = this;
    this.setState({uploading: true});
    if (this.fileUploadRef.current.files.length > 0) {
      let file = this.fileUploadRef.current.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.fileUploadRef.current.value = '';
        Requests.doAction('doupload', {
          id: self.props.id,
          regname: self.props.table,
          base64: reader.result.split2(',').pop(),
          filename: file.name,
        })
          .then(() => {
            self.setState({uploading: false});
            self.LoadAttachments();
          })
          .catch((error) => {
            logError(error);
          });
      };
      reader.readAsDataURL(file);
    }
  };

  ClosePreview = () => {
    this.setState({showPreview: false});
  };

  MobileFileNavigation = () => {
    let res = [];
    let self = this;
    let tmp = this.state.activeFile;
    let el = {UUID: tmp.uuid, Name: tmp.name};
    res.push(
      <View style={{flexDirection: 'row'}}>
        <DefaultIconButton iconName={'times'} mode="text" size={30} iconType="FA5" action={() => self.ClosePreview()} />
        <DefaultIconButton
          iconName={'download'}
          mode="text"
          iconType="FA5"
          style={{marginRight: spacing.medium200}}
          action={() => self.DownloadFile(el)}
        />
        {this.props.freezeattach !== true && (
          <DefaultIconButton iconName={'trash'} mode="text" iconType="FA5" action={() => self.DeleteFile(el)} />
        )}
      </View>
    );
    return res;
  };

  render() {
    let self = this;
    let buttonStyle = {};
    if (IsHRWeb()) {
      buttonStyle = {margin: '0 !important'};
    }
    if (this.state.visible) {
      return (
        <AttachmentBackground>
          <SafeView>
            <AttachmentWrap width={this.props.width}>
              {IsHRWeb() === false && (self.state.showPreview || self.state.loading) && (
                <AttachPreview>
                  <PreviewHeader>{this.MobileFileNavigation()}</PreviewHeader>
                  <PreviewContent>
                    {self.state.loading && <>{self.ShowLoading(1)}</>}
                    {self.state.loading === false && <>{self.ShowPreviewFile()}</>}
                  </PreviewContent>
                </AttachPreview>
              )}

              {(IsHRWeb() || self.state.showPreview === false) && (
                <>
                  <AttachmentHeader>
                    <ActionButton
                      onPress={() => {
                        if (IsHRWeb()) {
                          self.props.action();
                        } else {
                          global.setAttachmentInfo({showAttachments: false});
                        }
                      }}
                      style={buttonStyle}
                      title={translate('Close')}
                    />
                    {IsHRWeb() && (
                      <input
                        style={{display: 'none'}}
                        type={'file'}
                        ref={this.fileUploadRef}
                        onChange={() => self.DoUploadFile()}
                      />
                    )}
                    {self.state.uploading && <>{self.ShowLoading(0)}</>}
                    {self.state.uploading === false && this.props.freezeattach !== true /*&& IsHRWeb()*/ && (
                      <>
                        <ActionButton
                          onPress={() => self.ClickUploadButton()}
                          style={buttonStyle}
                          title={translate('Upload')}
                        />
                      </>
                    )}
                  </AttachmentHeader>
                  <AttachmentList
                    onLayout={(event) => {
                      this.find_dimesions(event.nativeEvent.layout);
                    }}>
                    {this.state.attachmentList.map((el) => {
                      let style = {};
                      if (el.UUID === self.state.activeFile.uuid) {
                        style = {fontWeight: 'bold'};
                      }
                      return (
                        <>
                          <AttachFile>
                            <AttachName
                              onPress={() => {
                                self.ShowPreview(el);
                              }}>
                              <AttachNameText style={style}>{el.Name}</AttachNameText>
                            </AttachName>
                            {IsHRWeb() && (
                              <>
                                <DefaultIconButton
                                  iconName={'download'}
                                  mode="text"
                                  iconType="FA5"
                                  style={{marginRight: spacing.medium200}}
                                  action={() => self.DownloadFile(el)}
                                />
                                {this.props.freezeattach !== true && (
                                  <DefaultIconButton
                                    iconName={'trash'}
                                    mode="text"
                                    iconType="FA5"
                                    action={() => self.DeleteFile(el)}
                                  />
                                )}
                              </>
                            )}
                          </AttachFile>
                        </>
                      );
                    })}
                  </AttachmentList>
                </>
              )}
              {IsHRWeb() && (self.state.showPreview || self.state.loading) && (
                <AttachPreview>
                  <PreviewContent>
                    {self.state.loading && <>{self.ShowLoading(1)}</>}
                    {self.state.loading === false && <>{self.ShowPreviewFile()}</>}
                  </PreviewContent>
                </AttachPreview>
              )}
            </AttachmentWrap>
          </SafeView>
        </AttachmentBackground>
      );
    } else {
      return <></>;
    }
  }
}
