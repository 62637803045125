import React from 'react';
import {translate} from '@src/utils/languageTools';
import {ActionButton} from './ActionButton';
import {colours} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';

export const UnConfirmButton = (table, inspectWindow) => {
  return (
    <ActionButton
      title={translate('UnConfirmRecord')}
      style={{backgroundColor: colours[GetGlobalTheme()].white}}
      textStyle={{color: colours[GetGlobalTheme()].secondary}}
      onPress={() => table.unConfirmRecord({inspectWindow})}
    />
  );
};
