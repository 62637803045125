import React from 'react';
import {colours, fontSize} from '../../styles/Style';
import {GetGlobalTheme, IsHRiOS, IsHRWeb} from '../../Tools/DisplayTools';
import {Picker} from '@react-native-picker/picker';
import {translate} from '@utils/languageTools';
import {ModalSelectorEXC as ModalSelector} from '../../Tools/Stubs';

const DropdownSelect = (props) => {
  let data = [];
  let initval;
  props.data.map(function (row, index) {
    let value = row.code || row.uuid;
    if (props.value === value) {
      initval = row.name;
    }
    data.push({
      key: index,
      label: row.name,
      customKey: value,
    });
  });

  let style = {
    color: colours[GetGlobalTheme()].titleText,
    borderColor: colours[GetGlobalTheme()].darkHighligh,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    fontSize: fontSize.medium,
    height: 25,
  };
  if (props.width) {
    style.width = props.width;
  }
  if (IsHRWeb()) {
    style.background = 'transparent';
  }

  return (
    <>
      {IsHRiOS() && (
        <ModalSelector
          data={data}
          initValue={initval}
          accessible={true}
          cancelText={translate('Cancel')}
          initValueTextStyle={{
            color: colours[GetGlobalTheme()].inputText,
            textAlign: 'left',
          }}
          selectStyle={{
            borderWidth: 0,
            paddingVertical: 12,
            paddingHorizontal: 0,
          }}
          overlayStyle={{backgroundColor: 'rgba(0,0,0,0.8)'}}
          onChange={(option) => {
            if (props.action !== undefined) {
              props.action(option.customKey, option.key);
            }
          }}
        />
      )}
      {IsHRiOS() === false && (
        <Picker
          style={style}
          selectedValue={props.value}
          onValueChange={props.action}
          dropdownIconColor={colours[GetGlobalTheme()].titleText}>
          {props.data.map(function (row) {
            let value = row.code || row.uuid;
            return <Picker.Item key={value} label={row.name} value={value} />;
          })}
        </Picker>
      )}
    </>
  );
};

export default DropdownSelect;
