import {BrowseColumn} from '@components/screens/types';
import {DoNavigate, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import ToolTip from '@components/design/ToolTip';
import {RowContainer} from '@styles/primitiveComponents';
import React from 'react';
import {CopyButton} from '@components/editElements/InspectWindowActionButtons';
import {
  displayApprovalStatusWithIcons,
  displayBrowseOKFlag,
  displayInvDate,
  displayPaidStatus,
  displaySumwithCurrency,
  getMobileDisplayBrowseOKFlag,
  getMobileDisplayInvDate,
  getMobileDisplaySumWithCurrency,
} from '@tools/recordListDisplayTools';
import {MobileBrowseColumnDisplayType, RecordListWindowDefinition} from '@src/types/Record/windowDefinitions';
import {OverwriteBrowseColumnProps} from '@utils/types';
import {isApprovalRulesSupported} from '@src/utils/drawersTools';

const CopyButtonStyle = {
  width: 40,
  paddingLeft: 14,
  paddingRight: 14,
  paddingTop: 12,
  paddingBottom: 12,
  backgroundColor: '#f4f7fa',
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
};

export class InvoicesRecordListDefinition extends RecordListWindowDefinition {
  window;

  constructor(window) {
    super();
    this.window = window;
  }

  copyRecord(id) {
    DoNavigate(this.window, 'Invoices', true, 'new-' + id);
  }

  DisplayCopyButton = ({row}) => {
    return (
      <ToolTip tooltipText={translate('Copy')}>
        <RowContainer style={{alignItems: 'center', gap: '4px'}}>
          <CopyButton onPress={() => this.copyRecord(row.sernr)} hasLabel={false} style={CopyButtonStyle} />
        </RowContainer>
      </ToolTip>
    );
  };

  getBrowseColumns(): BrowseColumn[] {
    if (isMobileOrTabletScreen()) {
      return this.getMobileBrowseColumns();
    }

    return this.getDesktopBrowseColumns();
  }

  getDesktopBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('SerNr'),
        key: 'sernr',
        sortKey: 'SerNr',
        alignment: 'left',
        width: '124px',
      },
      {
        name: translate('CustCode'),
        key: 'custcode',
        sortKey: 'CustCode',
        alignment: 'left',
        width: '122px',
      },
      {
        name: translate('Name'),
        key: 'addr0',
        sortKey: 'Addr0',
        alignment: 'left',
        width: '151px',
      },
      {
        name: translate('InvDate'),
        key: 'invdate',
        sortKey: 'InvDate',
        alignment: 'left',
        width: '140px',
        windowElementType: WindowElementType.kInputTypeDate,
        overwrite: (props: OverwriteBrowseColumnProps) => displayInvDate(props, 'paydate', 'paid'),
      },
      {
        name: translate('Total'),
        key: 'sum4',
        sortKey: 'Sum4',
        alignment: 'right',
        width: '111px',
        windowElementType: WindowElementType.kInputTypeValFull,
        overwrite: displaySumwithCurrency,
      },
      {
        name: translate('OrderNr'),
        key: 'ordernr',
        sortKey: 'OrderNr',
        alignment: 'left',
        width: '112px',
      },
      {
        name: translate('Status'),
        key: 'okflag',
        sortKey: 'OKFlag',
        alignment: 'left',
        width: '142px',
        overwrite: displayBrowseOKFlag,
      },
      {
        name: translate('PaymentStatus'),
        key: 'paid',
        sortKey: 'Paid',
        alignment: 'center',
        width: '131px',
        overwrite: (props: OverwriteBrowseColumnProps) => displayPaidStatus(props, 'paydate'),
      },
      {
        name: '',
        key: 'copy',
        alignment: 'center',
        width: '64px',
        overwrite: this.DisplayCopyButton,
      },
    ];
    if (isApprovalRulesSupported()) {
      browseColumns.splice(8, 0, {
        name: translate('ApprovalStatus'),
        key: 'approval',
        sortKey: 'Approval',
        checkUndefined: true,
        alignment: 'left',
        width: '152px',
        overwrite: displayApprovalStatusWithIcons,
      });
    }
    return browseColumns;
  }

  getMobileBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('Name'),
        key: 'addr0',
        sortKey: 'Addr0',
        alignment: 'left',
        width: '151px',
        mobileDisplayType: MobileBrowseColumnDisplayType.title,
      },
      {
        name: translate('InvDate'),
        key: 'invdate',
        sortKey: 'InvDate',
        alignment: 'left',
        width: '140px',
        windowElementType: WindowElementType.kInputTypeDate,
        overwrite: (props: OverwriteBrowseColumnProps) => getMobileDisplayInvDate(props, 'sernr'),
        mobileDisplayType: MobileBrowseColumnDisplayType.underTitle,
      },
      {
        name: translate('Total'),
        key: 'sum4',
        sortKey: 'Sum4',
        alignment: 'right',
        width: '111px',
        windowElementType: WindowElementType.kInputTypeValFull,
        overwrite: (props: OverwriteBrowseColumnProps) =>
          getMobileDisplaySumWithCurrency(props, 'paydate', 'curncycode', 'paid'),
        mobileDisplayType: MobileBrowseColumnDisplayType.rightTitle,
      },
      {
        name: translate('Status'),
        key: 'okflag',
        sortKey: 'OKFlag',
        alignment: 'left',
        width: '142px',
        overwrite: getMobileDisplayBrowseOKFlag,
        mobileDisplayType: MobileBrowseColumnDisplayType.rightUnderTitle,
      },
    ];
    return browseColumns;
  }
}
