import React, {useState} from 'react';
import styled from 'styled-components/native';
import {ActionButton} from '@components/button/ActionButton';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {ViewMode} from '../screens/types';

const AttachmentWrapper = styled.View<{columnDisplay: boolean}>`
  background-color: ${colours[GetGlobalTheme()].grey200};
  border-radius: 4px;
  flex-direction: row;
  width: ${(props) => (props.columnDisplay ? 'calc(33% - 4px)' : '100%')};
  flex-wrap: nowrap;
  align-items: center;
  gap: ${spacing.space12}px;
  padding: ${spacing.space8}px;
  margin: ${spacing.space4}px 0;
  justify-content: start;
`;

const IconWrapper = styled.View`
  justify-content: start;
  align-items: center;
  width: 48px;
`;

const AttachmentButton = {
  backgroundColor: 'invisible',
  justifyContent: 'start',
  alignItems: 'center',
  padding: 0,
  margin: 0,
  width: 124,
};
const AttachmentButtonText = {
  color: colours[GetGlobalTheme()].lightBlue,
  justifyContent: 'start',
};

const CloseAttachmentButton = {
  padding: 0,
  margin: 0,
  width: 30,
  position: 'absolute',
  right: 10,
};

const PossibleFileTypes = {
  png: 'png',
  pdf: 'pdf',
  jpg: 'jpg',
  jpeg: 'jpeg',
  gif: 'gif',
  docx: 'docx',
  doc: 'doc',
  xlsx: 'xlsx',
  xls: 'xls',
};

type AttachmentProps = {
  attachmentName: string;
  canBeDeleted: boolean;
  // eslint-disable-next-line no-unused-vars
  onFilePress: (downloadInstead: boolean) => void;
  onCrossPress: () => void;
  onDownloadPress: () => void;
  wideView: boolean;
  viewMode: ViewMode;
};

export const Attachment = ({
  attachmentName,
  canBeDeleted,
  onFilePress,
  onCrossPress,
  onDownloadPress,
  wideView,
  viewMode,
}: AttachmentProps) => {
  const [isHovering, setIsHovering] = useState(false);

  function getAttachmentFileType() {
    return attachmentName.split('.').pop() || '';
  }

  const fileType = getAttachmentFileType();

  function shouldHavePreview() {
    switch (fileType) {
      case PossibleFileTypes.png:
        return true;
      case PossibleFileTypes.pdf:
        return true;
      case PossibleFileTypes.jpg:
        return true;
      case PossibleFileTypes.jpeg:
        return true;
      default:
        return false;
    }
  }

  const hasPreview = shouldHavePreview();

  function getFileIcon(): string {
    switch (fileType) {
      case PossibleFileTypes.png:
        return 'PNGIcon';
      case PossibleFileTypes.pdf:
        return 'PDFIcon';
      case PossibleFileTypes.jpg:
        return 'JPGIcon';
      case PossibleFileTypes.jpeg:
        return 'JPGIcon';
      case PossibleFileTypes.gif:
        return 'GIFIcon';
      case PossibleFileTypes.doc:
        return 'WordIcon';
      case PossibleFileTypes.docx:
        return 'WordIcon';
      case PossibleFileTypes.xls:
        return 'ExcelIcon';
      case PossibleFileTypes.xlsx:
        return 'ExcelIcon';
      default:
        return 'DefaultFileIcon';
    }
  }

  const wordCutLength = 3;

  function getAttachmentNameSuffix(hasLastLetters: boolean) {
    const fileTypeDotOffset = 1;
    const fileTypeLength = getAttachmentFileType().length;
    const attachmentLastLetters = attachmentName.slice(
      attachmentName.length - fileTypeLength - wordCutLength - fileTypeDotOffset,
      attachmentName.length - fileTypeLength
    );

    if (hasLastLetters) {
      return attachmentLastLetters + getAttachmentFileType();
    }
    return getAttachmentFileType();
  }

  const attachmentNameLengthLimit = 18;
  const shortAttachmentNameSuffix = `..`;

  function getAttachmentName() {
    const attachmentNameLength = attachmentName.length;
    if (attachmentNameLength >= attachmentNameLengthLimit) {
      let shortAttachmentName =
        attachmentName.slice(0, attachmentNameLengthLimit - wordCutLength) + shortAttachmentNameSuffix;
      if (attachmentNameLength > attachmentNameLengthLimit + wordCutLength * 2) {
        shortAttachmentName += getAttachmentNameSuffix(true);
        return shortAttachmentName;
      }
      return shortAttachmentName + getAttachmentNameSuffix(false);
    }
    return attachmentName;
  }

  return (
    <AttachmentWrapper
      columnDisplay={wideView && viewMode === ViewMode.Edit}
      // @ts-ignore
      onMouseEnter={() => setIsHovering(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovering(false)}>
      <IconWrapper>
        <PortalIcon iconName={getFileIcon()} iconHeight={24} iconColor={colours[GetGlobalTheme()].lightBlue} />
      </IconWrapper>
      <ActionButton
        style={AttachmentButton}
        textStyle={AttachmentButtonText}
        title={getAttachmentName()}
        onPress={() => onFilePress(hasPreview)}
      />
      {canBeDeleted ? (
        <ActionButton
          style={CloseAttachmentButton}
          backgroundColor={'invisible'}
          hoverColor={colours[GetGlobalTheme()].grey200}
          title={''}
          iconName={isHovering ? 'CrossIcon' : ''}
          iconColor={colours[GetGlobalTheme()].primary}
          onPress={onCrossPress}
        />
      ) : (
        <ActionButton
          style={CloseAttachmentButton}
          backgroundColor={'invisible'}
          hoverColor={colours[GetGlobalTheme()].grey200}
          title={''}
          iconName={isHovering ? 'DownloadCloudIcon' : ''}
          onPress={onDownloadPress}
        />
      )}
    </AttachmentWrapper>
  );
};
