import React from 'react';
import {View} from 'react-native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import {ColumnContainer, RowContainer, TextNormalBold} from '@styles/primitiveComponents';

const ContainerStyle = {
  width: '248px',
  backgroundColor: colours[GetGlobalTheme()].white,
  margin: spacing.space8,
  padding: spacing.space24,
  borderWidth: 1,
  borderRadius: spacing.space12,
  borderStyle: 'solid',
  borderColor: colours[GetGlobalTheme()].borderDefault,
};

const ContentContainerStyle = {
  gap: spacing.space8,
};

const LineWrapperStyle = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

const LineValueWrapperStyle = {
  justifyContent: 'end',
};

export type SummaryLine = {
  title: string;
  value: string;
};

type RecordSummaryProps = {
  SummaryLines: SummaryLine[];
};

export const RecordSummary: React.FC<RecordSummaryProps> = ({SummaryLines}) => {
  return (
    <View style={ContainerStyle}>
      <ColumnContainer style={ContentContainerStyle}>
        {SummaryLines.map((line) => (
          <RowContainer style={LineWrapperStyle}>
            <TextNormalBold> {line.title} </TextNormalBold>
            <RowContainer style={LineValueWrapperStyle}>
              <TextNormalBold> {line.value} </TextNormalBold>
            </RowContainer>
          </RowContainer>
        ))}
      </ColumnContainer>
    </View>
  );
};
