import React, {useEffect, useRef, useState} from 'react';
import {colours} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import Requests from '../../../../api/Requests';
import {logError} from '@utils/debug';
import EditField from '@components/editElements/EditField';
import {ColumnContainer, Header1, Header6, RowContainer, TextNormal, TextNormalBold} from '@styles/primitiveComponents';
import {ActionButton} from '@components/button/ActionButton';
import LanguageSelect from '@components/status/LanguageSelect';
import {RequestEmpData} from 'src/App';
import AlertWindow, {AlertType} from '@components/design/AlertWindow';
import {LoginBackground} from './LoginBackground';
import {NavigationProp, RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {Animated, Dimensions, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

const LoginFormStyle = {
  width: '100%',
  maxWidth: 694,
  maxHeight: 960,
  height: 'calc(100% - 120px)',
  minHeight: 720,
  display: 'flex',
  marginVertical: 60,
  marginHorizontal: 'auto',
  paddingVertical: 82,
  paddingHorizontal: '5%',
  alignItems: 'center',
  alignSelf: 'center',
  gap: '10%',
  flexShrink: 0,
  borderRadius: 12,
  backgroundColor: colours[GetGlobalTheme()].mainBG,
};

const InnerContainerStyle = {
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 4,
  display: 'flex',
};

const InputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  alignSelf: 'stretch',
};

const InputWrapperStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: 4,
};

const ForgotPasswordStyle = {
  fontSize: 14,
  fontWeight: '700',
  lineHeight: 19.6,
  wordWrap: 'break-word',
  cursor: 'pointer',
};

const ForgotPasswordContainerStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'flex-end',
};

const FooterContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  alignSelf: 'stretch',
};

const SignUpContainerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
};

type LoginProps = {
  navigation: NavigationProp<any, any>;
  route: RouteProp<{params: {showSuccessMessage?: boolean; showCreateSuccessMessage?: boolean}}, 'params'>;
};
const minScreenHeight = 820;

export const Login: React.FC<LoginProps> = () => {
  const navigation = useNavigation<NavigationProp<any, any>>();
  const route =
    useRoute<RouteProp<{params: {showSuccessMessage?: boolean; showCreateSuccessMessage?: boolean}}, 'params'>>();
  const [icon, setIcon] = useState<string>('eye-slash');
  const [secure, setSecure] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [screenHeight, setScreenHeight] = useState(getClampedScreenHeight());
  const successOpacity = useRef(new Animated.Value(1)).current;
  const ID = useRef(null);
  const Password = useRef(null);
  const [, forceUpdate] = useState(0);

  const ContainerStyle = {
    backgroundColor: colours[GetGlobalTheme()].darkBlue,
    height: screenHeight,
  };

  function getClampedScreenHeight() {
    const screenHeight = Dimensions.get('window').height;
    if (screenHeight < minScreenHeight) {
      return minScreenHeight;
    }
    return screenHeight;
  }

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  function showSessionExpiredMessage() {
    global.messageBox2({
      show: true,
      title: translate('SessionExpired'),
      buttons: [
        {
          label: translate('OK'),
          onPress: () => {
            global.messageBox2({show: false});
          },
          style: {flexGrow: 1},
          type: 'blue',
        },
      ],
    });
    global.sessionEnded = false;
  }

  useEffect(() => {
    if (global.sessionEnded) {
      showSessionExpiredMessage();
    }

    if (IsHRWeb()) {
      document.title = translate('LoginPage');
    }
    const showSuccessMessage = route.params?.showSuccessMessage || false;
    const showCreateSuccessMessage = route.params?.showCreateSuccessMessage || false;

    if (showSuccessMessage || showCreateSuccessMessage) {
      const successMessage = showSuccessMessage
        ? translate('PasswordChangeSuccess')
        : translate('PasswordCreateSuccess');

      displayMessage('', successMessage);
      navigation.setParams({showSuccessMessage: false, showCreateSuccessMessage: false});
    }
  }, [route.params]);

  function handleResizeEvent() {
    setScreenHeight(getClampedScreenHeight());
  }

  function displayMessage(errorMessage: string, successMessage: string) {
    successOpacity.stopAnimation();
    successOpacity.setValue(1);

    setErrorMessage(errorMessage);
    setSuccessMessage(successMessage);

    Animated.timing(successOpacity, {
      toValue: 0,
      duration: 15000,
      useNativeDriver: false,
    }).start(() => {
      setErrorMessage('');
      setSuccessMessage('');
      successOpacity.setValue(1);
    });
  }

  function handleLoginError(errorMessage: string) {
    displayMessage(errorMessage, '');
  }

  // Seems like async functions can't be called directly from button press
  async function LoginUser() {
    try {
      const accessResponse = await Requests.CheckAccessToCompany(ID.current.value.trim(), Password.current.value);
      /*
      For unknown reasons we make two requests (sometimes) and the second one has empty response. not sure why yet, but for now I made following check to quit the function if we get invalid response
      */
      if (typeof accessResponse !== 'object') {
        return;
      }
      if (accessResponse.success) {
        await Requests.TestLogin(ID.current.value.trim(), Password.current.value);
        global.setUserDetails = {
          username: ID.current.value.trim(),
          password: Password.current.value,
        };
        await RequestEmpData(true, handleLoginError);
        global.refreshScreen(true);
      } else {
        displayMessage(translate('LoginFailed'), '');
      }
    } catch (error) {
      displayMessage(translate('AnErrorOccurred'), '');
      logError(error);
    }
  }

  function onKeyPressPassword() {
    setTimeout(LoginUser, 500);
  }

  function onLoingPress() {
    setTimeout(LoginUser, 500);
  }

  function toggleSecure() {
    setIcon(secure ? 'eye' : 'eye-slash');
    setSecure(!secure);
    forceUpdate((prev) => prev + 1);
  }

  return (
    <View style={ContainerStyle}>
      <LoginBackground />
      <ColumnContainer style={LoginFormStyle}>
        <ColumnContainer style={InnerContainerStyle}>
          <LanguageSelect style={{marginLeft: 'none'}} />
          <Header1 style={{color: colours[GetGlobalTheme()].darkBlue300}}>{translate('WelcomeBack')}!</Header1>
          <Header6 style={{color: colours[GetGlobalTheme()].grey600}}>{translate('EnterDetails')}!</Header6>
        </ColumnContainer>
        <ColumnContainer style={InputContainerStyle}>
          <ColumnContainer style={InputWrapperStyle}>
            <Animated.View style={{opacity: successOpacity, width: '100%'}}>
              <AlertWindow
                message={errorMessage || successMessage}
                width={'100%'}
                alertType={errorMessage ? AlertType.Error : AlertType.Success}
                show={!!(errorMessage || successMessage)}
              />
            </Animated.View>
          </ColumnContainer>
          <ColumnContainer style={InputWrapperStyle}>
            <EditField
              title={translate('EmailPersCode')}
              width="100%"
              name={'username'}
              inputStyle={{backgroundColor: colours[GetGlobalTheme()].mainBG}}
              reference={ID}
              useAutoFill={true}
            />
          </ColumnContainer>
          <ColumnContainer style={InputWrapperStyle}>
            <EditField
              title={translate('Password')}
              width="100%"
              secure={secure}
              icon={icon}
              onSubmitEditing={onKeyPressPassword}
              iconClick={toggleSecure}
              inputStyle={{backgroundColor: colours[GetGlobalTheme()].maingBG, paddingHorizontal: 10}}
              reference={Password}
              useAutoFill={true}
            />
          </ColumnContainer>
          <View style={ForgotPasswordContainerStyle}>
            <TouchableOpacity style={ForgotPasswordStyle} onPress={() => navigation.navigate('ForgotPassword')}>
              <TextNormalBold style={{color: colours[GetGlobalTheme()].lightBlue400}}>
                {translate('ForgotPassword')}
              </TextNormalBold>
            </TouchableOpacity>
          </View>
        </ColumnContainer>
        <ColumnContainer style={FooterContainerStyle}>
          <ActionButton
            onPress={onLoingPress}
            title={translate('Login')}
            style={{width: '100%'}}
            textStyle={{color: colours[GetGlobalTheme()].white, width: '100%'}}
          />
          <RowContainer style={SignUpContainerStyle}>
            <TextNormal style={{color: colours[GetGlobalTheme()].grey600}}>{translate('NoAccount')}</TextNormal>
            <TextNormalBold
              style={{color: colours[GetGlobalTheme()].lightBlue400}}
              onPress={() => navigation.navigate('CreateAccount')}>
              {translate('Register')}
            </TextNormalBold>
          </RowContainer>
        </ColumnContainer>
      </ColumnContainer>
    </View>
  );
};
