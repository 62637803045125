import {DataTable} from 'react-native-paper';
import React, {Component} from 'react';
import {Animated, Modal, TouchableOpacity, View} from 'react-native';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {GetGlobalTheme, GetPasteSpecialValue, IsHRiOS, IsHRWeb, SetPasteSpecialValue} from '../../Tools/DisplayTools';
import {CurrentLanguage, MapLanguage, translate} from '@utils/languageTools';
import EditField from './EditField';
import Requests from '../../api/Requests';
import {ActionButton} from '../button/ActionButton';
import {DefaultIconButton} from '../button/DefaultIconButton';
import {RegistrationFields} from '../../assets/translations/RegistrationFields';
import {logError} from '@utils/debug';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const NewFields = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin-right: -30px;
  ${isDesktopScreen() &&
  css`
    width: 50%;
    margin: 0 auto;
  `}
`;

const NewField = styled.View`
  margin-right: 30px;
`;

const PasteSpecialHeader = styled.View``;
const PasteSpecialValue = styled.View``;

const PasteSpecialList = styled.ScrollView`
  height: 200px;
  background: ${colours[GetGlobalTheme()].tableBackground}
  margin-horizontal: ${spacing.small300};
  `;
const SearchWrap = styled.View``;
const PasteSpecialActions = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const PasteSpecialContent = styled.View`
  ${(isDesktopScreen() &&
    css`
      width: 50%;
      left: 25%;
      padding: 10px 30px 30px 30px;
    `) ||
  css`
    width: 100%;
    heigth: 100%;
    padding: 5px;
  `}
  top: -1px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #ddd;
  background: ${colours[GetGlobalTheme()].mainBackground};
`;

const StyledTitle = styled(DataTable.Title)`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  ${isMobileOrTabletScreen() &&
  css`
    padding: 0px !important;
  `}
`;

const StyledCell = styled(DataTable.Cell)`
  width: ${(props) => props.width};
  user-select: none;
`;

const StyledRowContainer = styled(DataTable.Row)`
background-color: ${(props) => (props.rowIndex % 2 ? colours[GetGlobalTheme()].inputBackground : 'transparent')}
  ${(props) => (props.actf ? 'background-color: ' + colours[GetGlobalTheme()].activeText + '1F' : '')}
  font-size: ${fontSize.medium};
  min-height: 32px;
  border-bottom-width: 0px;
  border-radius: 6px;
  margin-vertical: 0px;
  ${
    isDesktopScreen() &&
    css`
      padding-horizontal: ${spacing.small200};
      margin-horizontal: ${spacing.small400};
    `
  }
  padding-vertical: 0px;
  margin-bottom: ${(props) =>
    !(props.arrayLength % 2) && props.rowIndex === props.arrayLength - 1 ? /*spacing.medium200*/ 0 : 0};
  z-index: ${(props) => props.arrayLength - props.rowIndex};
`;

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].tableHeader};
  padding-vertical: 0px;
  font-size: ${fontSize.medium};
  border-bottom-width: 0px;
  height: 36px;
  line-height: 36px;
  ${isDesktopScreen() &&
  css`
    padding-horizontal: ${spacing.medium200};
  `}
`;

const SingleValue = styled.View`
  position: relative;
  border: 1px solid #eee;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
`;
const Message = styled.Text`
  text-align: center;
  margin-top: 20px;
  color: ${colours[GetGlobalTheme()].inputText};
`;
const SingleValueText = styled.Text`
  position: relative;
  color: ${colours[GetGlobalTheme()].inputText};
`;
const SingleValueRemove = styled.TouchableOpacity`
  position: absolute;
  width: 15px;
  height: 15px;
  background: transparent;
  right: -7px;
  top: -7px;
`;

const ValueWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

function GetCustFieldName(list, name) {
  let res = '';
  for (let i in list) {
    if (list[i].indexOf(name) > -1 || name === i) {
      res = i;
      break;
    }
  }
  return res;
}

const searchMinRequiredCharacters = 1;

export class PasteSpecialWindow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    let self = this;
    this.dynamicf = false;
    this.searchref = React.createRef();
    this.state = {
      SearchVal: '',
      curpage: 0,
      records: [],
      multiSelectedRecord: [],
      inlineNewRecord: false,
      canCreateNew: this.props.psnewrec,
      NewRec: {},
      lasttime: 0,
      sorteddata: [],
      curIndex: -1,
      offsetY: new Animated.Value(-400),
    };
    if (this.props.value === undefined) {
      this.state.activeRecord = [];
      this.state.origValue = '';
      this.state.value = '';
    } else {
      this.state.activeRecord = this.props.value.split2(',');
      this.state.origValue = this.props.value;
      this.state.value = this.props.value;
    }
    /*global.userData.provider === 0 && */
    if (this.HasSearchPasteSpecial()) {
      this.dynamicf = true;
    }
    if (this.dynamicf === false) {
      this.SearchPasteSpecial();
    }
    this.showPasteSpecial();
    global.PushKeyActions('Enter', (e) => {
      if (e.shiftKey) {
        self.insertCurrentRecord();
      } else {
        if (this.props.psmulti) {
          self.SelectFromList(0, e, false);
        }
      }
    });
    global.PushKeyActions('ArrowUp', (e) => self.SelectFromList(-1, e));
    global.PushKeyActions('ArrowDown', (e) => self.SelectFromList(1, e));
  }

  SelectFromList = (diff, e, arrowf = true) => {
    e.stopPropagation();
    let len = this.state.sorteddata.length;
    let newindex = this.state.curIndex + diff;
    if (newindex < len && newindex >= 0) {
      let id = this.state.sorteddata[newindex][this.props.psheader[0].key];
      this.setCurrentRecord(newindex, id, arrowf);
    }
  };

  HasSearchPasteSpecial = () => {
    let res = false;
    switch (this.props.table) {
      case 0:
      case 1:
      case 24:
      case 25:
      case 'UserVc':
      case 'CUVc':
      case 'VEVc':
      case 'INVc':
        res = true;
    }
    return res;
  };

  SearchPasteSpecial = () => {
    let self = this;
    let fields = '';
    this.props.psheader.map((field) => {
      fields += ',' + field.key;
    });
    Requests.getTableData2({type: this.props.table, fields: fields, extraParams: this.props.psextraParams})
      .then((response) => {
        if (self.state.activeRecord.length > 0) {
          for (let i = 0; i < response.records.length; i++) {
            if (self.state.activeRecord.indexOf(response.records[i][self.props.psheader[0].key]) > -1) {
              self.state.curIndex = i;
              i = response.records.length;
            }
          }
        }
        if (self.props.table.toString().slice(-5) === 'Block') {
          self.setState({records: response.records[0].rows});
        } else {
          self.setState({records: response.records});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  SearchPasteSpecial2 = () => {
    let self = this;
    Requests.getTableData(this.props.table, {}, '', this.state.SearchVal)
      .then((response) => {
        if (self.props.table.toString().slice(-5) === 'Block') {
          self.setState({records: response.records[0].rows});
        } else {
          self.setState({records: response.records});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  showPasteSpecial() {
    Animated.timing(this.state.offsetY, {toValue: 0, duration: 300}).start();
  }

  WindowHeader = () => {
    let res = [];
    let self = this;
    res.push(
      <SearchWrap>
        <EditField
          title={translate('Search')}
          value={''}
          reference={this.searchref}
          onKeyPress={(e) => {
            switch (e.key) {
              case 'ArrowUp':
                e.target.blur();
                self.SelectFromList(-1, e);
                break;
              case 'ArrowDown':
                e.target.blur();
                self.SelectFromList(1, e);
                break;
              case 'Enter':
                e.target.blur();
                self.SelectFromList(1, e);
                break;
              default:
                global.HandleKeyDown(e);
            }
          }}
          onChangeText={(value) => {
            self.setState({SearchVal: value, curIndex: -1}, () => {
              if (this.dynamicf) {
                if (value.length >= searchMinRequiredCharacters) {
                  self.SearchPasteSpecial2();
                } else {
                  self.setState({records: []});
                }
              }
            });
          }}
        />
      </SearchWrap>
    );

    return res;
  };

  RemoveValue = (value) => {
    let index = this.state.activeRecord.indexOf(value);
    if (index > -1) {
      let narr = [...this.state.activeRecord];
      narr.splice(index, 1);
      this.setState({activeRecord: narr});
    }
    index = this.state.multiSelectedRecord.indexOf(value);
    if (index > -1) {
      let narr = [...this.state.multiSelectedRecord];
      narr.splice(index, 1);
      this.setState({multiSelectedRecord: narr});
    }
  };

  WindowValue = () => {
    let res = [];
    let items = [];
    let self = this;
    if (this.props.psmulti) {
      this.state.activeRecord.map((value) => {
        let psvalue = GetPasteSpecialValue(this.props.table, value);
        if (psvalue !== undefined) {
          items.push(
            <SingleValue>
              <SingleValueText>{psvalue}</SingleValueText>
              <SingleValueRemove>
                <DefaultIconButton
                  style={{height: 'auto', width: 'auto', padding: 0, margin: 0}}
                  iconName={'times'}
                  mode="text"
                  size={15}
                  iconType="FA5"
                  action={() => self.RemoveValue(value)}
                />
              </SingleValueRemove>
            </SingleValue>
          );
        }
      });
      this.state.multiSelectedRecord.map((value) => {
        let psvalue = GetPasteSpecialValue(this.props.table, value);
        if (psvalue !== undefined) {
          items.push(
            <SingleValue
              style={{
                backgroundColor: colours[GetGlobalTheme()].activeText + '1F',
              }}>
              <SingleValueText>{psvalue}</SingleValueText>
              <SingleValueRemove>
                <DefaultIconButton
                  style={{height: 'auto', width: 'auto', padding: 0, margin: 0}}
                  iconName={'times'}
                  mode="text"
                  size={15}
                  iconType="FA5"
                  action={() => self.RemoveValue(value)}
                />
              </SingleValueRemove>
            </SingleValue>
          );
        }
      });
      res.push(<ValueWrap>{items}</ValueWrap>);
    }
    return res;
  };

  ChechDoubleClick = (value) => {
    let res = false;
    let curtime = Date.now();
    if (curtime - this.lasttime < 300 && this.state.activeRecord.indexOf(value) > -1) {
      res = true;
    }
    this.lasttime = curtime;

    return res;
  };

  setCurrentRecord = (index, value, arrowf = false) => {
    if (arrowf === false && IsHRWeb() && this.ChechDoubleClick(value)) {
      this.insertCurrentRecord();
    } else {
      this.setState({curIndex: index});
      if (this.props.psmulti) {
        if (arrowf === false) {
          let index = this.state.activeRecord.indexOf(value);
          let index2 = this.state.multiSelectedRecord.indexOf(value);
          if (index < 0 && index2 < 0 && value !== '') {
            this.setState({
              multiSelectedRecord: [...this.state.multiSelectedRecord, value],
            });
          }
        }
      } else {
        this.setState({activeRecord: [value]}, function () {
          if (IsHRWeb() === false) {
            this.insertCurrentRecord();
          }
        });
      }
    }
  };
  insertCurrentRecord = () => {
    if (this.props.onChange !== undefined) {
      let val = [...this.state.activeRecord, ...this.state.multiSelectedRecord].join(',');
      if (this.state.origValue !== val) {
        this.props.onChange({
          rowindex: this.props.row,
          fieldname: this.props.name,
          value: val,
        });
      }
    }
    global.setPasteSpecialInfo({show: false});
  };

  StyledRow = (rowindex, length, record) => {
    let self = this;
    let id = record[this.props.psheader[0].key];
    let actf = this.state.activeRecord.indexOf(id) > -1;
    let selectedf = this.state.multiSelectedRecord.indexOf(id) > -1;
    if (selectedf || this.state.curIndex === rowindex) {
      actf = true;
    }
    SetPasteSpecialValue(this.props.table, id, record);
    return (
      <StyledRowContainer
        key={rowindex}
        actf={actf}
        rowIndex={rowindex}
        arrayLength={length}
        pointerEvents={'none'}
        onPress={(e) => {
          self.setCurrentRecord(rowindex, id);
          e.stopPropagation();
          e.target.blur();
        }}>
        {self.props.psheader.map((field, index) => {
          return (
            <StyledCell key={index} width={field.width} style={{...field.cellstyle}}>
              {record[field.key]}
            </StyledCell>
          );
        })}
      </StyledRowContainer>
    );
  };

  SortTableData = (data) => {
    let res = data.slice();
    let searchval = this.state.SearchVal.toLowerCase();
    if (searchval !== '') {
      res = res.filter(function (item) {
        return JSON.stringify(item).toLowerCase().indexOf(searchval) > -1;
      });
    }
    /*
      res.sort(function(a, b) {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });  
    */
    if (this.props.pssort) {
      let curdir = this.props.pssort.dir;
      res.sort((a, b) => {
        if (curdir === 'ascending') {
          return a[this.props.pssort.key] > b[this.props.pssort.key] ? 1 : -1;
        } else {
          return a[this.props.pssort.key] < b[this.props.pssort.key] ? 1 : -1;
        }
      });
    }

    return res;
  };

  RecordList = () => {
    let res = [];
    let tdata = this.state.records;
    if (this.dynamicf === false) {
      tdata = this.SortTableData(this.state.records);
    }
    this.state.sorteddata = tdata;
    res.push(
      <DataTable primaryKey={'paste_body'}>
        <StyledHeader key={'paste_header'}>
          {this.props.psheader.map((field, index) => {
            return (
              <StyledTitle key={index} width={field.width} style={{...field.style, ...field.cellstyle}}>
                {field.name}
              </StyledTitle>
            );
          })}
        </StyledHeader>
        {tdata.map((record, index) => {
          return this.StyledRow(index, tdata.length, record);
        })}
        {tdata.length === 0 && this.dynamicf && this.state.SearchVal.length < searchMinRequiredCharacters && (
          <Message>{translate('StartTyping')}</Message>
        )}
        {tdata.length === 0 &&
          (this.dynamicf === false || this.state.SearchVal.length >= searchMinRequiredCharacters) && (
            <Message>{translate('NoData')}</Message>
          )}
      </DataTable>
    );

    return res;
  };

  ShowActions = () => {
    let res = [];
    let style = {};
    let self = this;
    res.push(<TouchableOpacity></TouchableOpacity>);
    res.push(<ActionButton style={style} onPress={() => self.insertCurrentRecord()} title={translate('Insert')} />);
    res.push(<ActionButton onPress={() => global.setPasteSpecialInfo({show: false})} title={translate('Cancel')} />);
    if (this.state.canCreateNew) {
      res.push(<ActionButton onPress={() => self.InitNewRecordRender()} title={translate('AddNew')} />);
    }
    return res;
  };

  ShowNewActions = () => {
    let res = [];
    let style = {};
    let self = this;
    res.push(<TouchableOpacity></TouchableOpacity>);
    res.push(<ActionButton style={style} onPress={() => self.storeNewInlineRecord()} title={translate('Save')} />);
    res.push(<ActionButton onPress={() => self.setState({inlineNewRecord: false})} title={translate('Cancel')} />);
    return res;
  };

  componentWillUnmount() {
    global.PopKeyActions('Enter');
    global.PopKeyActions('ArrowUp');
    global.PopKeyActions('ArrowDown');
  }

  validateNewRecord() {
    let res = true;
    if (this.state.NewRec.Name === '' || this.state.NewRec.Name === undefined) {
      res = false;
    }
    if (this.state.NewRec.RegNr1 === '' || this.state.NewRec.RegNr1 === undefined) {
      res = false;
    }

    if (global.pasteSpecials[37]) {
      global.pasteSpecials[37].map((row) => {
        if (this.state.NewRec[row.fieldname] === '' || this.state.NewRec[row.fieldname] === undefined) {
          if (row.required === '1') {
            res = false;
          }
        }
      });
    }
    return res;
  }

  storeNewInlineRecord() {
    if (this.validateNewRecord() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillAllFields'),
      });
      return;
    }
    let tdata = {
      record: this.state.NewRec,
    };
    let self = this;
    Requests.getNewRecord(this.props.table, tdata)
      .then((response) => {
        self.state.NewRec = {};
        self.state.activeRecord = [response.record.Code];
        self.insertCurrentRecord();
      })
      .catch((error) => {
        logError(error);
      });
  }

  InitNewRecordRender() {
    let self = this;
    switch (this.props.table) {
      case 25:
        if (global.pasteSpecials[37]) {
          this.setState({inlineNewRecord: true});
        } else {
          Requests.getTableData(37)
            .then((response) => {
              if (response.records.length > 0) {
                for (let i in response.records) {
                  response.records[i].fieldname = GetCustFieldName(RegistrationFields, response.records[i].fieldtype);
                }
                global.pasteSpecials[37] = response.records;
              }
              self.setState({inlineNewRecord: true});
            })
            .catch((error) => {
              logError(error);
            });
        }
        break;
      default:
        this.setState({inlineNewRecord: true});
    }
  }

  RenderNewInlineRecord() {
    //    MatCol(1,24,"Field Name",0,FieldName,false,FieldSClass);
    //    MatCol(1,104,"Type",0,EditState,false,SetSClass);
    //    MatCol(1,184,"Label",0,Label,false,0);
    let res = [];
    let fields = [];
    res.push(<PasteSpecialActions>{this.ShowNewActions()}</PasteSpecialActions>);

    switch (this.props.table) {
      case 25:
        {
          fields.push(
            <NewField>
              <EditField
                title={translate('Name') + '*'}
                name={'Name'}
                value={this.state.NewRec['Name']}
                style={{width: '100%'}}
                onChange={({value}: InputChangeEventProps) => {
                  let rec = this.state.NewRec;
                  rec['Name'] = value;
                  self.setState({NewRec: rec});
                }}
              />
            </NewField>
          );
          fields.push(
            <NewField>
              <EditField
                title={translate('Email')}
                name={'eMail'}
                value={this.state.NewRec['eMail']}
                style={{width: '100%'}}
                onChange={({value}: InputChangeEventProps) => {
                  let rec = this.state.NewRec;
                  rec['eMail'] = value;
                  self.setState({NewRec: rec});
                }}
              />
            </NewField>
          );

          fields.push(
            <NewField>
              <EditField
                title={translate('RegNr') + '*'}
                name={'RegNr1'}
                value={this.state.NewRec['RegNr1']}
                style={{width: '100%'}}
                onChange={({value}: InputChangeEventProps) => {
                  let rec = this.state.NewRec;
                  rec['RegNr1'] = value;
                  self.setState({NewRec: rec});
                }}
              />
            </NewField>
          );
          let lang = CurrentLanguage();
          let lang2 = MapLanguage(lang);
          lang = lang.toUpperCase();
          if (global.pasteSpecials[37]) {
            global.pasteSpecials[37].map((row) => {
              let tstr = row.comment;
              for (let i in row.rows) {
                if (row.rows[i].langcode === lang || row.rows[i].langcode === lang2) {
                  tstr = row.rows[i].text;
                  break;
                }
              }
              if (row.required === '1') {
                tstr = tstr + '*';
              }
              fields.push(
                <NewField>
                  <EditField
                    title={tstr}
                    name={row.fieldname}
                    value={this.state.NewRec[row.fieldname]}
                    style={{width: '100%'}}
                    onChange={({value}: InputChangeEventProps) => {
                      let rec = this.state.NewRec;
                      rec[row.fieldname] = value;
                      self.setState({NewRec: rec});
                    }}
                  />
                </NewField>
              );
            });
          }
        }
        break;
    }
    res.push(<NewFields>{fields}</NewFields>);

    return res;
  }

  render() {
    let style = {};
    if (IsHRiOS()) {
      style = {top: 40};
    }
    return (
      <Modal transparent={true} onShow={() => this.searchref.current.focus()}>
        <View style={style}>
          <Animated.View style={{transform: [{translateY: this.state.offsetY}]}}>
            <PasteSpecialContent>
              {this.state.inlineNewRecord === false && (
                <>
                  <PasteSpecialActions>{this.ShowActions()}</PasteSpecialActions>
                  <PasteSpecialValue>{this.WindowValue()}</PasteSpecialValue>
                  <PasteSpecialHeader>{this.WindowHeader()}</PasteSpecialHeader>
                  <PasteSpecialList>{this.RecordList()}</PasteSpecialList>
                </>
              )}
              {this.state.inlineNewRecord && <>{this.RenderNewInlineRecord()}</>}
            </PasteSpecialContent>
          </Animated.View>
        </View>
      </Modal>
    );
  }
}
