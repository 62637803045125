import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '@styles/Style';
import {GetGlobalTheme, IsHRWeb} from 'src/Tools/DisplayTools';
import {PortalIcon} from '../portalIcon/PortalIcon';
import {TextNormalBold} from '@styles/primitiveComponents';

const ButtonText = styled(TextNormalBold)`
  text-align: center;
  color: ${colours[GetGlobalTheme()].white}
    ${IsHRWeb() &&
    css`
      white-space: nowrap;
    `}
    ${IsHRWeb() === false &&
    css`
      line-height: 45px;
      font-size: ${fontSize.medium}px;
    `};
`;

const ButtonContainer = styled.TouchableOpacity`
  border-radius: 12px;
  background-color: ${(props) => (props.isHovering ? props.hoverColor : props.backgroundColor)};
  padding-vertical: ${spacing.space12}px;
  padding-horizontal: ${spacing.space24}px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${IsHRWeb() &&
  css`
    cursor: pointer;
    height: 40px;
  `}
  ${IsHRWeb() === false &&
  css`
    height: 45px;
  `}
`;

const IconWrap = styled.View``;

type ActionButtonProps = {
  onPress: () => void;
  title?: string;
  style?: React.CSSProperties;
  hoverColor?: string;
  onHover?: (hoverStatus: boolean) => void;
  backgroundColor?: string;
  textStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  secondIconStyle?: React.CSSProperties;
  extra?: any;
  iconName?: string;
  secondIconName?: string;
  iconSize?: number;
  secondIconSize?: number;
  iconColor?: string;
  secondIconColor?: string;
  hoverStyle?: React.CSSProperties;
  onLayout?: (event) => void;
};

export const ActionButton = ({
  onPress,
  title,
  style = {},
  hoverStyle = {},
  hoverColor = colours[GetGlobalTheme()].darkBlue100,
  onHover,
  backgroundColor = colours[GetGlobalTheme()].darkBlue,
  textStyle = {},
  iconStyle = {},
  secondIconStyle = {},
  extra = null,
  iconName = null,
  secondIconName = null,
  iconSize = 12,
  secondIconSize = 12,
  iconColor = '#FFFFFF',
  secondIconColor = '#FFFFFF',
  onLayout = undefined,
}: ActionButtonProps) => {
  const [isHovering, setIsHovering] = useState(false);

  if (isHovering) {
    style = {...style, ...hoverStyle};
  }

  useEffect(() => {
    if (onHover) {
      onHover(isHovering);
    }
  }, [isHovering]);

  return (
    <ButtonContainer
      onPress={onPress}
      isHovering={isHovering}
      hoverColor={hoverColor}
      backgroundColor={backgroundColor}
      // @ts-ignore
      onMouseEnter={() => setIsHovering(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovering(false)}
      onLayout={(event) => {
        onLayout ? onLayout(event) : null;
      }}
      style={style}>
      {title && (
        <ButtonText key={'ActionButtonText'} style={textStyle}>
          {title}
        </ButtonText>
      )}
      {extra !== null && <>{extra}</>}
      {iconName && (
        <IconWrap style={iconStyle}>
          <PortalIcon iconName={iconName} iconWidth={iconSize} iconHeight={iconSize} iconColor={iconColor} />
        </IconWrap>
      )}
      {secondIconName && (
        <IconWrap style={secondIconStyle}>
          <PortalIcon
            iconName={secondIconName}
            iconWidth={secondIconSize}
            iconHeight={secondIconSize}
            iconColor={secondIconColor}
          />
        </IconWrap>
      )}
    </ButtonContainer>
  );
};
