import React from 'react';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme, isMobileOrTabletScreen} from '@tools/displayTools';
import {ActionButton} from '@components/button/ActionButton';
import {ModalFooter} from '@components/editElements/modalWindow/ModalFooter';

const LeftButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderWidth: 1,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  padding: spacing.space24,
  flexGrow: isMobileOrTabletScreen() ? 1 : 0,
};

const LeftButtonTextStyle = {
  color: colours[GetGlobalTheme()].primary,
};

const RightButtonStyleStyle = {
  backgroundColor: colours[GetGlobalTheme()].primary,
  padding: spacing.space24,
  flexGrow: isMobileOrTabletScreen() ? 1 : 0,
};

const RightButtonTextStyle = {
  color: colours[GetGlobalTheme()].white,
};

type ModalFooterProps = {
  onCancelPress?: () => void;
  cancelButtonTitle?: string;
  onSavePress?: () => void;
  saveButtonTitle?: string;
  children?: React.ReactNode;
};

export const ModalSaveCancelFooter: React.FC<ModalFooterProps> = ({
  onCancelPress,
  cancelButtonTitle,
  onSavePress,
  saveButtonTitle,
  children,
}) => {
  function getDefaultButtons() {
    return (
      <>
        <ActionButton
          onPress={() => onCancelPress()}
          title={cancelButtonTitle}
          style={LeftButtonStyle}
          textStyle={LeftButtonTextStyle}
        />
        <ActionButton
          onPress={() => onSavePress()}
          title={saveButtonTitle}
          style={RightButtonStyleStyle}
          textStyle={RightButtonTextStyle}
        />
      </>
    );
  }

  return (
    <ModalFooter>
      {children}
      {getDefaultButtons()}
    </ModalFooter>
  );
};
