import {ColumnContainer} from '@src/styles/primitiveComponents';
import {logError} from '@src/utils/debug';
import React, {useEffect, useState} from 'react';
import Requests from 'src/api/Requests';
import {RecordScreenState, ViewMode} from '../../types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ContactPersonRecord} from './ContactPersonsRecord';
import {ActionButton} from '@src/components/button/ActionButton';
import {translate} from '@src/utils/languageTools';
import {View, ViewStyle} from 'react-native';
import {colours, spacing} from '@src/styles/Style';
import {GetGlobalTheme} from '@src/tools/displayTools';
import {Divider} from '@src/components/editElements/inspectWindow/WindowTile';
import {AddNewContactPersonRecord} from '@components/screens/contacts/contactPersons/AddNewContactPersonRecord';
import {isMobileOrTabletScreen} from '@src/tools/displayTools';
import {contactPersonsEvents} from './contactPersonsEvents';

/* Based on the field names in the database */
export type ContactPersonData = {
  Code: string;
  Name: string;
  Mobile: string;
  Phone: string;
  eMail: string;
  JobDesc: string;
};

type ContactPersonSectionProps = {
  window: RecordScreen<{}, RecordScreenState>;
  id: string;
  viewMode: ViewMode;
};

export const ContactPersons: React.FC<ContactPersonSectionProps> = ({window, id, viewMode}) => {
  const [contactPersonsList, setContactPersonsList] = useState<Array<ContactPersonData>>([]);
  const [isAddNewContactPersonOpen, setIsAddNewContactPersonOpen] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);

  const isMobileOrTablet = isMobileOrTabletScreen();

  const contactPersonHeaderStyle: ViewStyle = {
    alignItems: isMobileOrTablet ? 'flex-start' : 'flex-end',
    width: isMobileOrTablet ? '100%' : 'auto',
  };

  const contactPersonListWrapStyle: ViewStyle = {
    flexDirection: isMobileOrTablet ? 'column' : 'row',
    flexWrap: isMobileOrTablet ? 'nowrap' : 'wrap',
    gap: spacing.space12,
  };

  const contactPersonWrapStyle: ViewStyle = {
    background: colours[GetGlobalTheme()].contentBG,
    borderRadius: spacing.space12,
    padding: spacing.space12,
    maxWidth: isMobileOrTablet ? '100%' : `calc(50% - ${spacing.space12}px)`,
    minWidth: isMobileOrTablet ? '100%' : `calc(50% - ${spacing.space12 + 1}px)`,
    flexGrow: 1,
  };

  useEffect(() => {
    loadCustomerContactPersons();
  }, [id]);

  useEffect(() => {
    checkRefreshContactPersonViewMode();
  }, [viewMode]);

  useEffect(() => {
    const unsubscribe = contactPersonsEvents.subscribe(() => {
      setIsAddNewContactPersonOpen(true);
    });

    return () => unsubscribe();
  }, []);

  function loadCustomerContactPersons() {
    if (id !== '') {
      Requests.doAction('getcustomercontactpersons', {
        //@ts-ignore
        id: window.id,
        replyArray: true,
      })
        .then((response) => {
          if (response.records) {
            setContactPersonsList(response.records);
          }
        })
        .catch((error) => {
          logError(error);
        });
    }
  }

  function checkRefreshContactPersonViewMode() {
    if (viewMode === ViewMode.Edit) {
      setUpdate(!update);
    }
  }

  function AddContactListButton() {
    return (
      <ActionButton
        title={translate('AddNewContactPerson')}
        onPress={() => setIsAddNewContactPersonOpen(true)}
        style={{
          background: 'none',
          padding: 0,
        }}
        textStyle={{
          color: colours[GetGlobalTheme()].lightBlue,
        }}
      />
    );
  }

  return (
    <ColumnContainer style={{flexGrow: 1, width: '100%', flexDirection: isMobileOrTablet ? 'column' : undefined}}>
      {isAddNewContactPersonOpen && (
        <AddNewContactPersonRecord
          record={{
            Code: '',
            Name: '',
            Mobile: '',
            Phone: '',
            eMail: '',
            JobDesc: '',
          }}
          onCloseWindow={() => setIsAddNewContactPersonOpen(false)}
          onRefreshList={loadCustomerContactPersons}
          contactWindow={window}
        />
      )}

      <View style={contactPersonHeaderStyle}>{AddContactListButton()}</View>
      <View style={contactPersonListWrapStyle}>
        {contactPersonsList.map((contactPerson, index) => {
          return (
            <>
              {index % 2 === 1 ? <Divider /> : null}
              <View style={{...contactPersonWrapStyle, zIndex: contactPersonsList.length - index}}>
                <ContactPersonRecord
                  key={JSON.stringify(contactPerson) + (update ? 1 : 0)}
                  record={contactPerson}
                  onRefreshList={loadCustomerContactPersons}
                />
              </View>
            </>
          );
        })}
      </View>
    </ColumnContainer>
  );
};
