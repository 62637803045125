import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
// import DateTimePicker, {DateType} from 'react-native-ui-datepicker';
import DateTimePicker, {DateType} from './src';
import {GetGlobalTheme} from '@src/tools/displayTools';
import {colours} from '@src/styles/Style';
import {TextNormal} from '@src/styles/primitiveComponents';
import {translate} from '@src/utils/languageTools';
import {TouchableHighlight} from 'react-native';
import {PortalIcon} from '@components/portalIcon/PortalIcon';

const DatePickerContainer = styled.View<{marginTop?: number}>`
  align-items: center;
  position: absolute;
  margin-top: ${(props) => props.marginTop}px;
  z-index: 999;
`;

const DatePickerView = styled.View<{width?: string}>`
  width: ${(props) => props.width};
  height: 310px;
  background-color: ${colours[GetGlobalTheme()].white};
  padding: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
  border: 1px ${colours[GetGlobalTheme()].borderDefault} solid;
`;

const ClearButtonContainer = styled(TouchableHighlight).attrs({
  underlayColor: colours[GetGlobalTheme()].grey200,
  activeOpacity: 0.85,
})`
  width: 100%;
  height: 100%;
  background-color: ${colours[GetGlobalTheme()].white};
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px ${colours[GetGlobalTheme()].borderDefault} solid;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

type ModeType = 'single' | 'range' | 'multiple';

type DatePickerProps = {
  mode: ModeType;
  date?: {startDate?: DateType; endDate?: DateType; date?: DateType};
  height: number;
  width: string;
  firstDayOfWeek: number;
  displayFullDays: boolean;
  marginTop: number;
  onChange: (params: DateChangeParams) => void;
  closeCalendar: () => void;
  clearDates: () => void;
};

type DateChangeParams = {
  startDate?: DateType;
  endDate?: DateType;
  date?: DateType;
};

const DatePickerComponent = ({
  mode,
  date,
  height,
  width,
  firstDayOfWeek,
  displayFullDays,
  marginTop,
  onChange,
  closeCalendar,
  clearDates,
}: DatePickerProps) => {
  const [range, setRange] = useState<{startDate?: DateType; endDate?: DateType}>({
    startDate: date?.startDate,
    endDate: date?.endDate,
  });
  const [singleDate, setSingleDate] = useState<DateType | undefined>(date?.startDate || date?.date);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);

  useEffect(() => {
    if (date) {
      setRange({startDate: date.startDate, endDate: date.endDate});
      setSingleDate(date.startDate || date.date);
    }
  }, [date]);

  function handleDateChange(params: DateChangeParams) {
    if (mode === 'single') {
      setSingleDate(params.date);
      onChange({date: params.date});
      closeCalendar();
    } else if (mode === 'range') {
      if (isSelectingStartDate) {
        setRange({startDate: params.startDate, endDate: params.endDate});
        setIsSelectingStartDate(false);
        onChange({startDate: params.startDate, endDate: params.endDate});
      } else {
        setRange((prevRange) => ({
          ...prevRange,
          endDate: params.endDate,
        }));
        onChange({startDate: params.startDate, endDate: params.endDate});
        setIsSelectingStartDate(true);
        closeCalendar();
      }
    }
  }

  function handleClearDates() {
    setRange({startDate: undefined, endDate: undefined});
    setSingleDate(undefined);
    setIsSelectingStartDate(true);
    closeCalendar();
    clearDates();
  }

  return (
    <DatePickerContainer marginTop={marginTop}>
      <DatePickerView width={width}>
        <DateTimePicker
          mode={mode}
          date={mode === 'single' ? singleDate : {startDate: range.startDate, endDate: range.endDate}}
          height={height}
          firstDayOfWeek={firstDayOfWeek}
          displayFullDays={displayFullDays}
          buttonPrevIcon={
            <PortalIcon
              iconName={'ArrowIcon'}
              iconHeight={12}
              iconColor={colours[GetGlobalTheme()].primary}
              wrapperStyle={{transform: 'rotate(-90deg)'}}
            />
          }
          buttonNextIcon={
            <PortalIcon
              iconName={'ArrowIcon'}
              iconHeight={12}
              iconColor={colours[GetGlobalTheme()].primary}
              wrapperStyle={{transform: 'rotate(90deg)'}}
            />
          }
          calendarTextStyle={{
            fontSize: 10,
          }}
          headerButtonsPosition="right"
          headerButtonStyle={{
            display: 'flex',
            width: 30,
            height: 30,
            padding: 4,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 8,
            backgroundColor: colours[GetGlobalTheme()].grey200,
          }}
          weekDaysTextStyle={{
            fontSize: 14,
            fontWeight: '500',
          }}
          dayContainerStyle={{borderRadius: 8}}
          startDate={range.startDate}
          endDate={range.endDate}
          onChange={handleDateChange}
          headerButtonColor={colours[GetGlobalTheme()].primary}
          selectedItemColor={colours[GetGlobalTheme()].lightBlue300}
          activeTextColor={colours[GetGlobalTheme()].white}
          todayContainerStyle={{
            borderWidth: 1,
          }}
        />
      </DatePickerView>
      <ClearButtonContainer onPress={handleClearDates}>
        <TextNormal style={{color: colours[GetGlobalTheme()].primary}}>{translate('Clear')}</TextNormal>
      </ClearButtonContainer>
    </DatePickerContainer>
  );
};

export {DatePickerComponent};
