import React from 'react';
import {ViewMode} from '@components/screens/types';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../Tools/DisplayTools';
import {translate} from '@utils/languageTools';
import {isMobileOrTabletScreen} from '@tools/displayTools';
import {View, ViewStyle} from 'react-native';

type InspectStatusProps = {
  viewMode: ViewMode;
  headerFieldsEditable: boolean;
};

export const InspectStatus: React.FC<InspectStatusProps> = ({viewMode, headerFieldsEditable}) => {
  let statusColor: string;
  let statusText: string;
  let statusTextColor: string;

  if (viewMode === ViewMode.Edit) {
    statusColor = colours[GetGlobalTheme()].contentBG;
    statusText = translate('Draft');
    statusTextColor = colours[GetGlobalTheme()].label;
  } else if (headerFieldsEditable) {
    statusColor = colours[GetGlobalTheme()].infoLight;
    statusText = translate('Saved');
    statusTextColor = colours[GetGlobalTheme()].darkBlue100;
  } else {
    statusColor = colours[GetGlobalTheme()].successLight;
    statusText = translate('Confirmed');
    statusTextColor = colours[GetGlobalTheme()].success;
  }
  function getStatusWrapStyle() {
    const mobileStyle: ViewStyle = {
      borderRadius: spacing.space12,
      backgroundColor: colours[GetGlobalTheme()].white,
      paddingTop: spacing.space24,
      paddingRight: spacing.space16,
      paddingBottom: spacing.space24,
      paddingLeft: spacing.space16,
    };

    const style: ViewStyle = {
      ...{
        borderColor: colours[GetGlobalTheme()].borderDefault,
        borderWidth: 0,
        zIndex: 2,
        padding: 0,
        paddingBottom: spacing.space16,
        borderRadius: isMobileOrTabletScreen() ? spacing.space12 : 0,
        backgroundColor: colours[GetGlobalTheme()].white,
      },
      ...(isMobileOrTabletScreen() ? mobileStyle : {}),
    };

    return style;
  }

  function getStatusButtonStyle() {
    const isActive = viewMode === ViewMode.Edit;
    let backgroundColor = isActive ? colours[GetGlobalTheme()].contentBG : colours[GetGlobalTheme()].infoLight;
    if (statusColor) {
      backgroundColor = statusColor;
    }
    const border = isActive ? `1px solid ${colours[GetGlobalTheme()].borderDefault}` : 'none';
    return {
      padding: spacing.space4,
      borderRadius: spacing.space40,
      backgroundColor: backgroundColor,
      border: border,
    };
  }

  function getStatusTextStyle() {
    return {
      color: statusTextColor || colours[GetGlobalTheme()].label,
      textAlign: 'center',
    };
  }

  return (
    <View style={getStatusWrapStyle()}>
      <View style={getStatusButtonStyle()}>
        <View style={getStatusTextStyle()}>{statusText}</View>
      </View>
    </View>
  );
};
