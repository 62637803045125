import React, {Component} from 'react';
import {RecordListWindowDefinition} from '@src/types/Record/windowDefinitions';
import Requests from '../../../api/Requests';
import {logError} from '@utils/debug';
import {DoNavigate, UsePagination} from '@tools/displayTools';
import {SetCurrentWindow2} from '../../../Tools/WindowTools';
import {ListScreenState} from '@components/screens/types';

const defaultListScreenState: ListScreenState = {
  update: false,
  activeRow: -1,
};

export abstract class ListScreen<P, S> extends Component<P, S> {
  _isMounted: boolean;
  ScreenName: string;
  table: string | number;
  init: boolean;
  navigation: any;
  recordWindowDefinition: RecordListWindowDefinition;
  isActiveScreen: boolean;
  focusSubscription: any;
  blurSubscription: any;

  constructor(props) {
    super(props);
    this.init = false;
    this._isMounted = false;
    this.state = defaultListScreenState as S;

    global.refreshRecordList = () => {
      if (!this.isActiveScreen) {
        return;
      }
      this.setState({update: !this.state.update});
    };
  }

  getBrowseListData = () => {
    let self = this;
    Requests.getTableData(this.table, undefined, undefined, undefined, true)
      .then((response) => {
        if (self._isMounted) {
          self.setState({data: response.records});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  displayBrowseList = () => {
    this.getBrowseListData();
  };

  rowClick = (id: string) => {
    DoNavigate(this, this.ScreenName, true, id);
  };

  updateScreen() {
    this.setState({update: !this.state.update});
  }

  UpdateBrowseListData() {
    let self = this;
    SetCurrentWindow2(this.ScreenName, '', '');
    if (UsePagination() === false) {
      self.getBrowseListData();
    } else {
      if (self.init === false) {
        self.updateScreen();
      }
      self.init = false;
    }
  }

  componentDidMount() {
    this.handleActiveScreenCheck();
    this._isMounted = true;
  }

  private handleActiveScreenCheck() {
    this.isActiveScreen = true;
    if (!this.props.navigation) {
      return;
    }
    this.focusSubscription = this.props.navigation.addListener('focus', () => {
      this.isActiveScreen = true;
      this.UpdateBrowseListData();
    });
    this.blurSubscription = this.props.navigation.addListener('blur', () => {
      this.isActiveScreen = false;
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    global.setMobileBottomButton(false, [<></>]);
    if (this.isActiveScreen) {
      global.setMobileTopBar(<></>);
    }
  }
}
