import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from 'src/Tools/DisplayTools';
import {
  Header,
  HeaderTextContainer,
  ModalBackground,
  ColumnContainer,
  Header5,
  TextNormal,
} from '@styles/primitiveComponents';
import {Modal} from 'react-native';
import {translate} from '@src/utils/languageTools';
import {ActionButton} from '../button/ActionButton';

const Container = styled(ColumnContainer)`
  background-color: ${colours[GetGlobalTheme()].white};
  border-radius: 12px;
  width: 350px;
  margin: auto auto;
  padding: ${spacing.space24}px;
`;

const Description = styled(TextNormal)``;
const Buttons = styled.View`
  flex-direction: row;
  gap: ${spacing.space12}px;
`;

type MessageBoxButton = {
  label?: string;
  onPress: () => void;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  type?: MessageButtonType;
};

type MessageButtonType = 'white' | 'blue';

type MessageBoxProps = {
  buttons: Array<MessageBoxButton>;
  title?: string;
  message?: string;
  closeAction?: () => void;
  style?: React.CSSProperties;
  callBack?: () => void;
  show: boolean;
};

const CloseButton = {
  padding: '9px',
  marginLeft: 'auto',
  borderRadius: '8px',
  height: '30px',
};

export const MessageBox = () => {
  const [messageBoxProps, setMessageBoxProps] = useState<MessageBoxProps>({
    show: false,
    buttons: [],
    title: '',
    message: '',
    closeAction: undefined,
    style: undefined,
    callBack: undefined,
  });

  useEffect(() => {
    if (messageBoxProps.callBack) {
      messageBoxProps.callBack();
    }
  }, [messageBoxProps]);

  global.messageBox2 = (messageBox) => {
    setMessageBoxProps(messageBox);
  };

  if (messageBoxProps.show) {
    if (messageBoxProps.buttons.length == 0) {
      messageBoxProps.buttons.push({
        label: translate('OK'),
        onPress: global.messageBox2({show: false}),
      });
    }
    const whiteStyle = {
      background: colours[GetGlobalTheme()].white,
      border: '1px solid ' + colours[GetGlobalTheme()].borderDefault,
      flexGrow: 1,
    };

    const whiteTextStyle = {color: colours[GetGlobalTheme()].primary};
    return (
      <Modal transparent={true}>
        <ModalBackground>
          <Container style={{gap: spacing.space40}}>
            <Header style={{padding: 0, border: 'none'}}>
              <HeaderTextContainer style={{alignItems: 'top'}}>
                <Header5 style={{fontSize: 18}}>{messageBoxProps.title}</Header5>
                {messageBoxProps.closeAction && (
                  <ActionButton
                    onPress={messageBoxProps.closeAction}
                    title={''}
                    style={CloseButton}
                    iconName={'CrossIcon'}
                    iconColor={colours[GetGlobalTheme()].primary}
                    iconSize={12}
                    backgroundColor={colours[GetGlobalTheme()].iconButtonBG}
                    hoverColor={colours[GetGlobalTheme()].grey100}
                  />
                )}
              </HeaderTextContainer>
            </Header>
            {messageBoxProps.message && <Description>{messageBoxProps.message}</Description>}
            <Buttons>
              {messageBoxProps.buttons.map((button) => {
                let style = button.style;
                let textStyle = button.textStyle;
                if (button.type === 'white') {
                  style = {...style, ...whiteStyle};
                  textStyle = {...textStyle, ...whiteTextStyle};
                }
                return (
                  <ActionButton
                    title={button.label}
                    onPress={() => button.onPress()}
                    style={style}
                    textStyle={textStyle}
                  />
                );
              })}
            </Buttons>
          </Container>
        </ModalBackground>
      </Modal>
    );
  } else {
    return <></>;
  }
};
