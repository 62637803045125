import React from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  DoNavigate,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  Supported,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {translate} from '@utils/languageTools';
import EditField from '../edit_elements/EditField';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {LangColorContext} from '../../App';
import {createDrawerNavigator} from '@react-navigation/drawer';
import * as myDate from '../../Tools/DateTools';
import {logError} from '@utils/debug';
import {InputChangeEventProps, WindowDefinition, WindowMatrix, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {APISupportsItem} from '@utils/drawersTools';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const Drawer = createDrawerNavigator();

function headerFields(table) {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('InvDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'InvDate',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '150px',
        },
        {
          label: translate('TransDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Approved'),
          windowElementType: WindowElementType.kInputTypeCheckbox,
          checkboxInactiveLabel: translate('Approve'),
          dataKey: 'OKFlag',
          viewOnly: false,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('CustCode'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'CustCode',
          viewOnly: false,
          pasteSpecial: {
            vcName: 'CUVc',
            newRecord: true,
            hideComment: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('CompName'),
                key: 'name',
              },
            ],
          },
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('CompName'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Addr0',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '340px',
        },
        {
          label: translate('RegNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'RegNr1',
          viewOnly: false,
          onChange: table.updateRecordField,
          width: '150px',
        },
        {
          label: translate('PayDeal'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'PayDeal',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'PDVc',
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'pdComment',
              },
            ],
          },
        },
      ],
    },
  ];
  if (Supported('sendemail')) {
    windowDefinition[0].fields.push({
      label: translate('SendEmail'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.SendInvoiceEmail,
    });
  }
  if (APISupportsItem('hassigning')) {
    windowDefinition[0].fields.push({
      label: translate('CreateSigningActivity'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.CreateSigningActivity,
    });
  }

  return windowDefinition;
}

const matrixFooterFields = (): WindowDefinition => [
  {
    fields: [
      {
        label: translate('Subtotal'),
        windowElementType: WindowElementType.kInputTypeValFull,
        dataKey: 'Sum1',
        viewOnly: true,
        width: '200px',
        row: true,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
      {
        label: translate('VAT'),
        windowElementType: WindowElementType.kInputTypeValFull,
        dataKey: 'Sum3',
        viewOnly: true,
        width: '150px',
        row: true,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
      {
        label: translate('Total'),
        dataKey: 'Sum4',
        viewOnly: true,
        width: '150px',
        row: true,
        windowElementType: WindowElementType.kInputTypeValFull,
        style: {
          backgroundColor: colours[GetGlobalTheme()].inputBackground,
          textAlign: 'right',
          paddingRight: spacing.medium,
        },
      },
    ],
    style: {
      justifyContent: 'flex-end',
    },
  },
];

const matrixFields = (table) => {
  let windowMatrix: WindowMatrix = [];
  windowMatrix.push({
    label: translate('Item'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'ArtCode',
    width: '180px',
    viewOnly: false,
    onChange: table.updateRecordField,
    pasteSpecial: {
      vcName: 'INVc',
      columns: [
        {
          name: translate('Code'),
          width: '35%',
          key: 'code',
          mainKey: 'Code',
        },
        {
          width: '65%',
          name: translate('Name'),
          key: 'name',
        },
      ],
    },
    onFocus: table.handleMatrixFocus,
    style: {
      borderColor: '#ddd',
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('Description'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'Spec',
    width: '300px',
    viewOnly: false,
    onChange: table.updateRecordField,
    onFocus: table.handleMatrixFocus,
    style: {
      borderColor: '#ddd',
      borderRight: 'none',
      borderRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('Quant'),
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'Quant',
    width: '80px',
    viewOnly: false,
    onChange: table.updateRecordField,
    onFocus: table.handleMatrixFocus,
    style: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      borderColor: '#ddd',
      borderRight: 'none',
      borderRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('Price'),
    dataKey: 'Price',
    windowElementType: WindowElementType.kInputTypeValFull,
    width: '80px',
    viewOnly: false,
    onChange: table.updateRecordField,
    onFocus: table.handleMatrixFocus,
    style: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      borderColor: '#ddd',
      borderRight: 'none',
      borderRadius: 0,
    },
  });
  windowMatrix.push({
    label: translate('Discount'),
    dataKey: 'vRebate',
    windowElementType: WindowElementType.kInputTypeVal,
    width: '50px',
    viewOnly: false,
    onChange: table.updateRecordField,
    onFocus: table.handleMatrixFocus,
    style: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      borderColor: '#ddd',
      borderRight: 'none',
      borderRadius: 0,
    },
  });

  windowMatrix.push({
    label: translate('Total'),
    dataKey: 'Sum',
    windowElementType: WindowElementType.kInputTypeValFull,
    width: '80px',
    onChange: table.updateRecordField,
    viewOnly: false,
    onFocus: table.handleMatrixFocus,
    style: {
      justifyContent: 'flex-end',
      textAlign: 'right',
      borderColor: '#ddd',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  });
  return windowMatrix;
};

function InvoiceRowActions(table) {
  let res = [];
  res.push({
    name: translate('InsertRow'),
    action: table.insertMatrixRow,
  });
  res.push({
    name: translate('DeleteRow'),
    action: table.deleteMatrixRow,
  });
  /*
        res.push({
          name: translate('InsertRow'),
          action: table.insertMatrixRow,
        });
        */
  return res;
}

let FilterView;
if (IsHRWeb()) {
  FilterView = styled.View`
    position: absolute;
    top: 100%;
    right: 0px;
    width: 240px;
    background: ${colours[GetGlobalTheme()].lightBackground};
    padding: 20px;
  `;
} else {
  if (IsHRiOS()) {
    FilterView = styled.View`
      position: absolute;
      height: ${ScreenHeight()};
      width: ${ScreenWidth()};
      background: ${colours[GetGlobalTheme()].lightBackground};
      padding: 6px;
    `;
  } else {
    FilterView = styled.Modal``;
  }
}

const FilterViewInner = styled.View`
  ${IsHRWeb() === false &&
  css`
    height: 100%;
    width: 100%;
    background-color: ${colours[GetGlobalTheme()].siteBackground};
  `};
  ${IsHRiOS() &&
  css`
    padding-top: 50;
  `};
`;

const FilterFields = styled.View`
  ${IsHRWeb() &&
  css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-right: -30px;
  `}
`;
const FilterActions = styled.View`
  display: flex;
  ${(IsHRWeb() &&
    css`
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
    `) ||
  css`
    flex-direction: column-reverse;
  `}
`;

const FilterField = styled.View``;

function CurrentDate() {
  return new Date();
}

interface InvoiceListState extends ListScreenState {
  newInvoiceHelper: boolean;
  px: number;
  py: number;
  NewCust: string;
  NewPD: string;
  Month: string;
  Year: number;
  Department: string;
}

export class InvoicesList extends ListScreen<{}, InvoiceListState> {
  constructor(props) {
    super(props);
    this.createNewTable = this.createNewTable.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.ScreenName = 'Invoices';
    this.table = 'IVVc';
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
      newInvoiceHelper: false,
      px: 0,
      py: 0,
      NewCust: '',
      NewPD: '',
      Month: '01',
      Year: CurrentDate().getFullYear(),
      Department: global.userData.deplist.split(',').pop(),
    } as InvoiceListState;
    SetCurrentWindow2('Invoices', 'SerNr', 'descending');
    AddDateFilter('Invoices', myDate.AddMonth(myDate.getCurrentDate(), -2));
  }

  createNewTable = () => {
    if (this.state.NewCust === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let self = this;
    let tdata = {
      record: {
        CustCode: this.state.NewCust,
      },
    };
    if (this.state.NewPD !== '') {
      tdata.record.PayDeal = this.state.NewPD;
    }
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (response.success === true) {
          self.ShowHideNewInvoice();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewInvoice = () => {
    if (this.state.newInvoiceHelper) {
      global.PopEscapeActions();
      this.setState({newInvoiceHelper: false});
    } else {
      this.setState({newInvoiceHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewInvoice());
    }
  };
  renderNewButton = () => {
    let style = {};
    let self = this;
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }

    return (
      <View
        key={'invoice_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewInvoice()} title={translate('AddNew')} />
        {this.state.newInvoiceHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('Customer') + '*'}
                    name={'CustCode'}
                    value={this.state.NewCust}
                    ps={'CUVc'}
                    style={{width: '100%'}}
                    psnewrec={true}
                    psheader={[
                      {
                        name: translate('Code'),
                        width: '35%',
                        key: 'code',
                        mainkey: 'Code',
                      },
                      {
                        width: '65%',
                        name: translate('Name'),
                        key: 'name',
                      },
                    ]}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewCust: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('PayDeal')}
                    name={'PayDeal'}
                    value={this.state.NewPD}
                    ps={'PDVc'}
                    style={{width: '100%'}}
                    psnewrec={true}
                    psheader={[
                      {
                        name: translate('Code'),
                        width: '35%',
                        key: 'Code',
                        mainkey: 'Code',
                      },
                      {
                        width: '65%',
                        name: translate('Name'),
                        key: 'pdComment',
                      },
                    ]}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewPD: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={{style}} onPress={() => this.ShowHideNewInvoice()} title={translate('Cancel')} />
                <ActionButton style={style} onPress={() => this.createNewTable()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <TableContainer
                table={this.table}
                update={this.state.update}
                data={this.state.data}
                browseColumns={[
                  {
                    name: translate('SerNr'),
                    key: 'sernr',
                    sortKey: 'SerNr',
                    width: '150px',
                  },
                  {
                    name: translate('CustCode'),
                    key: 'custcode',
                    sortKey: 'CustCode',
                    width: '150px',
                  },
                  {
                    name: translate('Name'),
                    key: 'addr0',
                    sortKey: 'Addr0',
                    width: '170px',
                  },
                  {
                    name: translate('InvDate'),
                    key: 'invdate',
                    sortKey: 'InvDate',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeDate,
                  },
                  {
                    name: translate('TransDate'),
                    key: 'transdate',
                    sortKey: 'TransDate',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeDate,
                  },
                  {
                    name: translate('Total'),
                    key: 'sum4',
                    sortKey: 'Sum4',
                    alignment: 'right',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeValFull,
                  },
                  {
                    name: translate('Approved'),
                    key: 'okflag',
                    sortKey: 'OKFlag',
                    width: '100px',
                    overwrite: DisplayBrowseOKFlag,
                  },
                ]}
                doClick={self.rowClick}
                rowID={'sernr'}
                title={translate(this.ScreenName)}
                width="800px"
                extraContent={this.renderNewButton}
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface InvoiceRecordState extends RecordScreenState {
  NewRowContract: string;
  NewRowType: string;
  NewRowIndex: number;
  newMatrixTableHelper: boolean;
  activeRow: number;
}

export class InvoicesRecord extends RecordScreen<{}, InvoiceRecordState> {
  origrowcnt: number;

  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.SendInvoiceEmail = this.SendInvoiceEmail.bind(this);
    this.CreateSigningActivity = this.CreateSigningActivity.bind(this);
    this.GetMatrixRowStyle = this.GetMatrixRowStyle.bind(this);
    this.handleMatrixFocus = this.handleMatrixFocus.bind(this);
    this.copyMatrixRow = this.copyMatrixRow.bind(this);
    this.deleteMatrixRow = this.deleteMatrixRow.bind(this);
    this.insertMatrixRow = this.insertMatrixRow.bind(this);
    this.rowClick = this.rowClick.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.origrowcnt = 0;
    this.ScreenName = 'Invoices';
    this.table = 'IVVc';
    this.state = {
      ...this.state,
      NewRowContract: '',
      NewRowType: '',
      NewRowIndex: -1,
      newMatrixTableHelper: false,
    };
    this.record = new WindowRecord('IVVc', true, headerFields(this), matrixFields(this), matrixFooterFields());
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  AddBlankInvoiceRow = () => {
    let lastrow = this.record.matrixRows[this.record.matrixRows.length - 1];
    let addf = false;
    if (lastrow) {
      if (lastrow.ArtCode !== '') {
        addf = true;
      }
      if (lastrow.Spec !== '') {
        addf = true;
      }
      if (lastrow.Price !== '') {
        addf = true;
      }
      if (lastrow.Quant !== '') {
        addf = true;
      }
      if (lastrow.vRebate !== '') {
        addf = true;
      }
      if (lastrow.Sum !== '') {
        addf = true;
      }
    } else {
      addf = true;
    }
    if (addf) {
      this.record.addMatrixRow();
      this.origrowcnt = this.record.matrixRows.length - 1;
    }
  };

  //override the built-in one because of blank row
  rowClick = (id: string) => {
    let self = this;
    //this.setState({record: {}});
    Requests.getTableData(this.table, {SerNr: id}, undefined, undefined, true)
      .then((response) => {
        self.id = response.records[0].SerNr;
        self.record.applyNewValues(response.records[0]);
        self.AddBlankInvoiceRow();
        self.updateScreen();
      })
      .catch((error) => {
        logError(error);
      });
  };
  insertMatrixRow = (index: number) => {
    let self = this;
    Requests.insertRecordRow(this.table, this.id + ':' + (index + 1) + ':1')
      .then((response) => {
        if (response.success) {
          self.rowClick(this.id);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  copyMatrixRow = (index: number) => {
    this.state.NewRowIndex = index;
    this.setState({newMatrixTableHelper: true});
  };
  deleteMatrixRow = (index: number) => {
    let self = this;
    let sernr = this.id;
    Requests.deleteRecordRow(this.table, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(this.table, {SerNr: sernr})
            .then((response) => {
              self.id = response.records[0].SerNr;
              self.record.applyNewValues(response.records[0]);
              self.AddBlankInvoiceRow();
              self.updateScreen();
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  createNewTableRow = () => {
    let self = this;
    Requests.insertRecordRow(this.table, this.id + ':' + (this.state.NewRowIndex + 1) + ':1')
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            let res = {
              list: [
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'ContractNr',
                  value: self.state.NewRowContract,
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'TrType',
                  value: self.state.NewRowType,
                },
                {
                  rownr: self.state.NewRowIndex + 1,
                  field: 'stp',
                  value: 2,
                },
              ],
              id: self.record.SerNr,
            };
            Requests.updateRecordData(this.table, res)
              .then((response) => {
                if (response.success) {
                  if (self._isMounted) {
                    self.record.applyNewValues(response.records[0]);
                    self.AddBlankInvoiceRow();
                    self.setState({newMatrixTableHelper: false});
                  }
                }
              })
              .catch((error) => {
                logError(error);
              });
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  GetMatrixRowStyle = (rowindex) => {
    if (rowindex === this.state.activeRow) {
      return {
        backgroundColor: colours[GetGlobalTheme()].activeMatrixBackground,
      };
    }
  };

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    if (rowindex > -1) {
      this.state.activeRow = -1;
    }
    let callback = function () {
      Requests.updateRecordData(self.table, {
        field: fieldname,
        rownr: rowindex,
        value: value,
        id: self.id,
      })
        .then((response) => {
          if (self._isMounted) {
            if (response.success) {
              self.record.applyNewValues(response.record);
              self.AddBlankInvoiceRow();
              self.updateScreen();
            } else {
              self.updateScreen();
            }
          }
        })
        .catch((error) => {
          logError(error);
        });
    };
    if (rowindex > -1 && rowindex >= this.origrowcnt) {
      Requests.insertRecordRow(this.table, this.id + ':' + rowindex + ':1')
        .then((response) => {
          if (response.success) {
            callback();
          }
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      callback();
    }
  };

  SendInvoiceEmail() {
    Requests.doAction('sendemail', {
      id: this.id,
      regname: this.table,
    })
      .then(() => {
        //do nothing
      })
      .catch((error) => {
        alert(translate('EmailFailed'));
        logError(error);
      });
  }

  CreateSigningActivity() {
    Requests.doAction('createsigningactivity', {
      id: this.id,
      regname: this.table,
    })
      .then((response) => {
        DoNavigate(this, 'Tasks', true, response.newid);
      })
      .catch((error) => {
        logError(error);
      });
  }

  renderNewRowButton = () => {
    let self = this;
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Contract')}
              name={'Contract'}
              value={this.state.NewRowContract}
              ps={1}
              style={{width: 215}}
              psfields={['contractnr', 'empname']}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowContract: value})}
            />
          </FilterField>
          <FilterField>
            <EditField
              title={translate('Type')}
              name={'Type'}
              value={this.state.NewRowType}
              ps={22}
              style={{width: 215}}
              psfields={['code', 'comment']}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowType: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                key={JSON.stringify(this.record)}
                title={translate('Invoice')}
                window={this}
                headerFields={headerFields(this)}
                matrixFooterFields={matrixFooterFields()}
                record={this.record}
                matrixFields={matrixFields(this)}
                GetMatrixRowStyle={(rowindex, row) => this.GetMatrixRowStyle(rowindex, row)}
                rowactions={InvoiceRowActions(this)}
                onClick={this.handleInputClick}
                /*splitrow={(row1, row2) => self.SplitMatrixRow(row1, row2)}*/
                okcheck={'OKFlag'}
                allowDelete={true}
                allowPrint={IsHRWeb()}
                width={'840px'}
                backButton={() => goBack(self, self.ScreenName)}
              />
              {this.state.newMatrixTableHelper && (
                <Modal transparent={true}>
                  <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
                </Modal>
              )}
              {this.state.activeDelete && (
                <Modal transparent={true}>
                  <YesNoWindow
                    question={translate('RemoveQuestion')}
                    yes={() => this.deleteRecord()}
                    no={() => this.setState({activeDelete: false})}
                  />
                </Modal>
              )}
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function Invoices_old() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="InvoicesList"
        component={InvoicesList}
        options={{
          title: translate('Invoices'),
        }}
      />
      <Drawer.Screen
        name="InvoicesRecord"
        component={InvoicesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('Invoice'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default Invoices_old;
