import {ActionButton} from '@components/button/ActionButton';
import {translate} from '@utils/languageTools';
import {colours, fontSize} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import React from 'react';
import {InspectWindow} from './inspectWindow/InspectWindow';
import {RecordScreen} from '@src/types/Record/recordScreen';
import { ViewStyle } from 'react-native';

type DeleteButtonProps = {
  window: RecordScreen<any, any>;
  allowDelete: boolean;
  style?: ViewStyle;
  iconStyle?: React.CSSProperties;
  hasLabel: boolean;
  inspectWindow: InspectWindow;
  onDelete?: () => void;
  onDeleteRefreshList?: () => void;
  redirect?: boolean;
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  window,
  allowDelete,
  style,
  iconStyle,
  hasLabel,
  inspectWindow,
  onDelete,
  onDeleteRefreshList,
  redirect = true,
}) => {
  if (allowDelete !== true) {
    return <></>;
  }

  function handleDelete() {
    global.messageBox2({
      show: true,
      title: translate('RemoveQuestion'),
      buttons: [
        {
          label: translate('Yes'),
          onPress: () => {
            global.messageBox2({show: false});
            window.deleteRecord({inspectWindow, redirect, onDeleteRefreshList});
          },
          type: 'white',
        },
        {
          label: translate('No'),
          onPress: () => global.messageBox2({show: false}),
          style: {flexGrow: 1},
        },
      ],
    });
    if (onDelete) {
      onDelete();
    }
  }

  return (
    <ActionButton
      title={hasLabel ? translate('Delete') : null}
      iconName={'Remove'}
      iconColor={colours[GetGlobalTheme()].primary}
      iconStyle={iconStyle}
      style={style}
      textStyle={{color: colours[GetGlobalTheme()].primary, fontSize: fontSize.bodySmall}}
      onPress={() => handleDelete()}
    />
  );
};

type CopyButtonProps = {
  onPress: () => void;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  hasLabel: boolean;
};

export const CopyButton: React.FC<CopyButtonProps> = ({onPress, style, iconStyle, hasLabel}) => {
  return (
    <ActionButton
      title={hasLabel ? translate('Copy') : null}
      iconName={'DuplicateIcon'}
      iconColor={colours[GetGlobalTheme()].primary}
      iconStyle={iconStyle}
      style={style}
      textStyle={{color: colours[GetGlobalTheme()].primary, fontSize: fontSize.bodySmall}}
      onPress={onPress}
    />
  );
};

type PrintButtonProps = {
  allowPrint: boolean;
  onPress: () => void;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  hasLabel: boolean;
};

export const PrintButton: React.FC<PrintButtonProps> = ({allowPrint, onPress, style, iconStyle, hasLabel}) => {
  if (allowPrint) {
    return (
      <ActionButton
        title={hasLabel ? translate('Print') : null}
        iconName={'Print'}
        iconColor={colours[GetGlobalTheme()].primary}
        iconStyle={iconStyle}
        style={style}
        textStyle={{color: colours[GetGlobalTheme()].primary, fontSize: fontSize.bodySmall}}
        onPress={onPress}
      />
    );
  }

  return <></>;
};
