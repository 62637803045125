import React, {Component, ReactElement, useEffect, useState} from 'react';
import {View, ViewStyle} from 'react-native';
import styled from 'styled-components/native';
import {colours, iconSize, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import Requests from '../../../api/Requests';
import {translate} from '../../../Tools/LanguageTools';
import EditField from '../editElements/EditField';
import {GetBrowseWindowDetails, GetWindowSearch} from '@tools/windowTools';
import {log, logError, LogType} from '@utils/debug';
import {BrowseFilter, TableContainerProps} from '@utils/types';
import {ActionButton} from '../button/ActionButton';
import {
  ColumnContainer,
  RowContainer,
  TextNormal,
  TextNormalBold,
  TextSmallBold,
} from '@src/styles/primitiveComponents';
import {PortalIcon} from '@src/components/portalIcon/PortalIcon';
import {IconWrapper} from '../fileUploader/FileUploader.styles';
import {
  ConvertDisplayValue,
  isDesktopScreen,
  isMobileOrTabletScreen,
  ScreenWidth,
  WindowElementType,
} from '@tools/displayTools';
import {DateRangePicker} from '../datePicker/DateRangePicker';
import {KanbanColumn} from '../screens/types';
import {KanbanCard} from './kanbanCard/KanbanCard';

const TableWrapper = styled(ColumnContainer)<{screenWidth: number | string}>`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  padding: 24px;
  align-items: ${isMobileOrTabletScreen() ? 'center' : 'flex-start'};
  gap: 20px;
  border-radius: 12px;
  background-color: ${isMobileOrTabletScreen() ? colours[GetGlobalTheme()].white : colours[GetGlobalTheme()].contentBG};
`;

const HeaderContainer = styled(ColumnContainer)`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  z-index: 1;
`;

const FilterWrap = styled(RowContainer)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 30px;
  align-self: stretch;
`;

const MobileFilterWrap = styled(RowContainer)`
  display: flex;
  align-items: center;
  gap: ${spacing.space4}px;
  align-self: stretch;
  padding-top: ${spacing.space4}px;
  padding-bottom: ${spacing.space8}px;
  overflow-x: scroll;
`;

const FilterButton = styled.TouchableOpacity<{active: boolean}>`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid #081d42;
  border-radius: 50px;
  background-color: ${(props) => (props.active ? colours[GetGlobalTheme()].darkBlue : 'transparent')};
  border-color: ${(props) => (props.active ? colours[GetGlobalTheme()].darkBlue : colours[GetGlobalTheme()].darkBlue)};
`;

const SearchWrap = styled(RowContainer)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  z-index: 999;
`;

const MobileSearchWrap = styled(RowContainer)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: ${spacing.space12}px;
  align-self: stretch;
  z-index: 999;
`;
const SingleActivity = styled.TouchableOpacity`
  width: 100%;
  background: ${colours[GetGlobalTheme()].white};
  border-radius: ${spacing.space12}px;
  width: 100%;
  padding: ${spacing.space12}px;
`;

const SearchBarWrapper = {
  flexShrink: 1,
  padding: 0,
};

const SearchIconStyle = {
  order: -1,
  marginRight: spacing.space8,
};

const SearchBar = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderColor: colours[GetGlobalTheme()].borderDefault,
  borderRadius: 8,
  borderWidth: 1,
  margin: 0,
  padding: spacing.space12,
  paddingLeft: spacing.space32,
  width: '100%',
};

const KanbanBoardStyle: ViewStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: spacing.space24,
  padding: spacing.space24,
  /*overflowX: 'auto',*/
  width: '100%',
  height: '100%',
  minHeight: 500,
};

const KanbanColumnStyle: ViewStyle = {
  backgroundColor: colours[GetGlobalTheme()].grey300,
  borderRadius: 12,
  //@ts-ignore
  flex: '1 0 0',
  width: '33%',
  padding: spacing.space16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: spacing.space12,
  minHeight: 862,
};

const ColumnHeaderStyle: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: spacing.space12,
  marginBottom: spacing.space8,
};
const ColumnListStyle: ViewStyle = {
  alignItems: 'flex-start',
  gap: spacing.space12,
  width: '100%',
};

export interface KanbanBoardProps {
  data: any;
  table: string | number;
  update: boolean;
  title: string;
  width?: number | string;
  rowID?: string;
  filters?: BrowseFilter[];
  doClick?: (id: string) => void;
  extraContent?: () => ReactElement;
  defaultColumns: Array<KanbanColumn>;
  hasDateFilter?: boolean;
  hasSearch?: boolean;
  addNewTitle?: string;
  wrapperStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  windowName: string;
}

export const KanbanBoard: React.FC<KanbanBoardProps> = ({
  table,
  windowName,
  filters,
  defaultColumns,
  hasDateFilter,
  addNewTitle,
  hasSearch,
  doClick,
  wrapperStyle,
}) => {
  let displayprops = GetBrowseWindowDetails(windowName);
  const sorting = displayprops.cursort;
  const curdir = displayprops.curdir;
  const [currentSearch, setCurrentSearch] = useState(displayprops.search);
  const [currentStartDate, setCurrentStartDate] = useState(displayprops.curstartdate);
  const [currentEndDate, setCurrentEndDate] = useState(displayprops.curenddate);
  let columns: KanbanColumn[] = defaultColumns || [];
  const [data, setData] = useState({});
  //  const [activeID, setActiveID] = useState(''); // We might need this later
  const [activeFilters, setActiveFilters] = useState(getDefaultFilters(filters) || []);

  useEffect(() => {
    getTableData();
  }, [currentSearch, currentStartDate, currentEndDate, activeFilters]);

  function getDefaultFilters(filters: BrowseFilter[]) {
    return filters.filter((filter) => filter.selected).map((filter) => filter.id);
  }

  function getTableData() {
    let search = currentSearch;
    if (search && search.length < 2) {
      search = '';
    }

    Requests.getTableData(
      table,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      sorting,
      curdir,
      currentSearch,
      currentStartDate,
      currentEndDate,
      activeFilters.join(',') || ''
    )
      .then((response) => {
        fixupData(response.originalData.data);
        setData(response.originalData.data);
        //setUpdate(update ? false : true);
      })
      .catch((error) => {
        logError(error);
      });
  }

  function fixupData(data) {
    columns.forEach((column) => {
      let columnData = data?.[column.key]?.ActVc;
      if (!Array.isArray(columnData) && columnData) {
        data[column.key].ActVc = [columnData];
      }
    });
  }

  function getDesktopFiltersComponent(filters) {
    return (
      <FilterWrap style={{width: '50%'}}>
        <TextSmallBold style={{color: colours[GetGlobalTheme()].grey800}}>{translate('Filters')}:</TextSmallBold>
        {filters.map((filter) => {
          return (
            <FilterButton
              active={activeFilters.indexOf(filter.id) > -1}
              onPress={() => {
                if (filter.changeable !== false) {
                  let pos = activeFilters.indexOf(filter.id);
                  if (pos > -1) {
                    activeFilters.splice(pos, 1);
                  } else {
                    activeFilters.push(filter.id);
                  }
                  getTableData();
                }
              }}>
              <RowContainer style={{alignItems: 'center', gap: '2px'}}>
                {activeFilters.indexOf(filter.id) > -1 && (
                  <IconWrapper style={{flexShrink: 1}}>
                    <PortalIcon iconName={'CheckboxIcon'} iconHeight={16} iconColor={colours[GetGlobalTheme()].white} />
                  </IconWrapper>
                )}
                <TextNormal
                  style={{
                    color:
                      activeFilters.indexOf(filter.id) > -1
                        ? colours[GetGlobalTheme()].white
                        : colours[GetGlobalTheme()].darkBlue,
                  }}>
                  {filter.name}
                </TextNormal>
              </RowContainer>
            </FilterButton>
          );
        })}
      </FilterWrap>
    );
  }

  function getMobileFiltersComponent(filters) {
    const self = this;
    return (
      <MobileFilterWrap style={{width: '100%'}}>
        {filters.map((el, index) => {
          return (
            <FilterButton
              active={activeFilters.indexOf(el.id) > -1}
              onPress={() => {
                let pos = activeFilters.indexOf(el.id);
                if (pos > -1) {
                  activeFilters.splice(pos, 1);
                } else {
                  activeFilters.push(el.id);
                }
                getTableData();
              }}>
              <RowContainer style={{alignItems: 'center', gap: '2px'}}>
                {activeFilters.indexOf(el.id) > -1 && (
                  <IconWrapper style={{flexShrink: 1}}>
                    <PortalIcon iconName={'CheckboxIcon'} iconHeight={16} iconColor={colours[GetGlobalTheme()].white} />
                  </IconWrapper>
                )}
                <TextNormal
                  style={{
                    color:
                      activeFilters.indexOf(el.id) > -1
                        ? colours[GetGlobalTheme()].white
                        : colours[GetGlobalTheme()].darkBlue,
                  }}>
                  {el.name}
                </TextNormal>
              </RowContainer>
            </FilterButton>
          );
        })}
      </MobileFilterWrap>
    );
  }

  function RecordListFilters(filters) {
    if (isMobileOrTabletScreen()) {
      return getMobileFiltersComponent(filters);
    }

    return getDesktopFiltersComponent(filters);
  }
  function handleDateFilter(startDate, endDate) {
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
  }

  function getDesktopSearchComponent() {
    const self = this;
    return (
      <SearchWrap>
        <EditField
          defaultValue={GetWindowSearch()}
          placeholder={translate('Search')}
          placeholdercolor={colours[GetGlobalTheme()].label}
          width={'100%'}
          onChangeText={(text) => {
            setCurrentSearch(text.toLowerCase());
          }}
          inputStyle={SearchBar}
          wrapperStyle={SearchBarWrapper}
          iconName={'SearchIcon'}
          iconStyle={SearchIconStyle}
          iconHeight={iconSize.iconSize}
        />
        {hasDateFilter && (
          <DateRangePicker
            action={(startDate, endDate) => {
              handleDateFilter(startDate, endDate);
            }}
            startDate={currentStartDate}
            endDate={currentEndDate}
          />
        )}
        {addNewTitle && isDesktopScreen && (
          <ActionButton
            onPress={() => doClick('new')}
            title={addNewTitle}
            style={{
              width: '20%',
              maxWidth: '20%',
              flexShrink: 0,
              height: 34,
            }}
            textStyle={{fontSize: 14}}
          />
        )}
      </SearchWrap>
    );
  }

  function getMobileSearchComponent() {
    const self = this;
    return (
      <MobileSearchWrap>
        <EditField
          defaultValue={GetWindowSearch()}
          placeholder={translate('Search')}
          placeholdercolor={colours[GetGlobalTheme()].label}
          width={'100%'}
          onChangeText={(text) => {
            setCurrentSearch(text.toLowerCase());
          }}
          inputStyle={SearchBar}
          wrapperStyle={SearchBarWrapper}
          iconName={'SearchIcon'}
          iconStyle={SearchIconStyle}
          iconHeight={iconSize.iconSize}
        />
        {hasDateFilter && (
          <DateRangePicker
            action={(startDate, endDate) => handleDateFilter(startDate, endDate)}
            startDate={currentStartDate}
            endDate={currentEndDate}
          />
        )}
      </MobileSearchWrap>
    );
  }

  function TableHeader() {
    return (
      <View style={{zIndex: 1, width: '100%'}}>
        <HeaderContainer>
          {getSearchComponent()}
          {filters && RecordListFilters(filters)}
        </HeaderContainer>
      </View>
    );
  }

  function getSearchComponent() {
    let searchComponent = <></>;
    if (hasSearch !== false) {
      searchComponent = isMobileOrTabletScreen() ? getMobileSearchComponent() : getDesktopSearchComponent();
    }

    return searchComponent;
  }

  function KanbanBody() {
    return (
      <View style={KanbanBoardStyle}>
        {columns.map((column) => (
          <View key={column.key} style={KanbanColumnStyle}>
            <View style={ColumnHeaderStyle}>
              <TextNormalBold>
                {`${column.title} `}
                <TextNormalBold style={{color: colours[GetGlobalTheme()].grey700}}>
                  ({data?.[column.key]?.ActVc?.length || '0'})
                </TextNormalBold>
              </TextNormalBold>
            </View>
            <View style={ColumnListStyle}>
              {data?.[column.key]?.ActVc?.map((activity) => (
                <KanbanCard
                  key={activity.sernr}
                  title={activity.comment}
                  mainPersons={activity.mainpersons}
                  type={activity.origin}
                  activityTypeComment={activity.acttypecomment}
                  date={ConvertDisplayValue(WindowElementType.kInputTypeDate, activity.transdate)}
                  commentCount={activity.comments}
                  okflag={activity.okflag}
                  onPress={() => {
                    doClick(activity.sernr);
                  }}
                />
              ))}
            </View>
          </View>
        ))}
      </View>
    );
  }

  if (isMobileOrTabletScreen()) {
    if (addNewTitle) {
      global.setMobileBottomButton(true, [
        <ActionButton
          onPress={() => doClick('new')}
          title={addNewTitle}
          style={{
            width: '100%',
            flexShrink: 1,
            margin: 6,
          }}
          textStyle={{fontSize: 14}}
        />,
      ]);
    }
  }
  return (
    <TableWrapper screenWidth={isMobileOrTabletScreen() ? ScreenWidth() : '100%'} style={wrapperStyle}>
      {TableHeader()}
      {KanbanBody()}
    </TableWrapper>
  );
};
