import {WindowElementType} from '@src/tools/displayTools';
import {translate} from '@src/utils/languageTools';
import {WindowDefinition, WindowMatrix} from '@src/utils/types';
import {GetDayColor} from 'src/Tools/ShiftTools';

const headerFields_LAT = (table): WindowDefinition => [
  {
    fields: [
      {
        label: translate('SerNr'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'SerNr',
        viewOnly: true,
        width: '100px',
      },
      {
        label: translate('Department'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'DepCode',
        viewOnly: true,
        width: '150px',
      },
      {
        label: translate('Date'),
        dataKey: 'RegDate',
        viewOnly: false,
        width: '150px',
        windowElementType: WindowElementType.kInputTypeDate,
        onChange: table.updateRecordField,
      },
      {
        label: translate('StartDate'),
        dataKey: 'StartDate',
        viewOnly: true,
        width: '150px',
        windowElementType: WindowElementType.kInputTypeDate,
      },
      {
        label: translate('EndDate'),
        dataKey: 'EndDate',
        viewOnly: true,
        width: '150px',
        windowElementType: WindowElementType.kInputTypeDate,
      },
      {
        label: translate('Approved'),
        checkboxInactiveLabel: translate('Approve'),
        dataKey: 'OKFlag',
        viewOnly: false,
        windowElementType: WindowElementType.kInputTypeCheckbox,
        onChange: table.updateRecordField,
      },
    ],
  },
];

const headerFields_EST = (table): WindowDefinition => [
  {
    fields: [
      {
        label: translate('SerNr'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'SerNr',
        viewOnly: true,
        width: '100px',
      },
      {
        label: translate('Department'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'AccGroupCode',
        viewOnly: true,
        width: '150px',
      },
      {
        label: translate('Year'),
        dataKey: 'Year',
        viewOnly: false,
        width: '150px',
        windowElementType: WindowElementType.kInputTypeText,
        onChange: table.updateRecordField,
      },
      {
        label: translate('Month'),
        dataKey: 'Month',
        viewOnly: true,
        width: '150px',
        windowElementType: WindowElementType.kInputTypeText,
      },
      {
        label: translate('Approved'),
        checkboxInactiveLabel: translate('Approve'),
        dataKey: 'OKFlag',
        viewOnly: false,
        windowElementType: WindowElementType.kInputTypeCheckbox,
        onChange: table.updateRecordField,
      },
    ],
  },
];

export function headerFields(table): WindowDefinition {
  let def: WindowDefinition;
  if (global.userData.provider === 0) {
    def = headerFields_LAT(table);
  } else {
    def = headerFields_EST(table);
  }
  return def;
}

const matrixFields_LAT = (table, widef = false) => {
  let res: WindowMatrix = [];
  res.push({
    label: translate('ContractNr'),
    dataKey: 'ContractNr',
    width: '90px',
    viewOnly: true,
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Employee'),
    dataKey: 'EmpName',
    width: widef ? '140px' : '120px',
    viewOnly: true,
    showProfile: 'EmpCode',
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Type'),
    dataKey: 'TrName',
    width: widef ? '140px' : '100px',
    viewOnly: true,
    windowElementType: WindowElementType.kInputTypeText,
  });

  let vColor = GetDayColor(table.record?.headerFields);
  for (let i = 1; i < 32; i++) {
    res.push({
      label: i.toString(),
      dataKey: 'Day' + i,
      width: widef ? '25px' : '20px',
      viewOnly: false,
      windowElementType: WindowElementType.kInputTypeVal,
      overwrite: table.overwriteMatrixRow,
      style: {
        justifyContent: 'center',
        textAlign: 'center',
        height: '23px',
        fontSize: '12px',
      },
      cellstyle: {backgroundColor: vColor[i], justifyContent: 'center'},
      onChange: table.updateRecordField,
    });
  }
  res.push({
    label: translate('QtyShort'),
    dataKey: 'Qty',
    windowElementType: WindowElementType.kInputTypeVal,
    width: '45px',
    viewOnly: true,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
  });
  res.push({
    label: translate('Total'),
    dataKey: 'DQty',
    windowElementType: WindowElementType.kInputTypeVal,
    width: '38px',
    viewOnly: true,
    overwrite: table.overwriteMatrixRowDQty,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
  });
  res.push({
    label: translate('Days'),
    dataKey: 'DayCnt',
    width: '38px',
    viewOnly: true,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
    cellstyle: {borderLeft: '1px solid #ddd', marginLeft: 5},
    windowElementType: WindowElementType.kInputTypeText,
  });
  return res;
};

const matrixFields_EST = (table, widef = false) => {
  let res: WindowMatrix = [];
  res.push({
    label: translate('ContractNr'),
    dataKey: 'ContrSerNr',
    width: '90px',
    viewOnly: true,
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Firstname'),
    dataKey: 'Firstname',
    width: widef ? '70px' : '120px',
    viewOnly: true,
    showProfile: 'EmpCode',
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Surname'),
    dataKey: 'Surname',
    width: widef ? '70px' : '120px',
    viewOnly: true,
    showProfile: 'EmpCode',
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Type'),
    dataKey: 'WorkHourTypeCode',
    width: widef ? '140px' : '100px',
    viewOnly: true,
    windowElementType: WindowElementType.kInputTypeText,
  });

  let vColor = GetDayColor(table.record?.headerFields);
  for (let i = 1; i < 32; i++) {
    res.push({
      label: i.toString(),
      dataKey: 'd' + i,
      width: widef ? '25px' : '20px',
      viewOnly: false,
      windowElementType: WindowElementType.kInputTypeVal,
      overwrite: table.overwriteMatrixRow,
      style: {
        justifyContent: 'center',
        textAlign: 'center',
        height: '23px',
        fontSize: '12px',
      },
      cellstyle: {backgroundColor: vColor[i], justifyContent: 'center'},
      onChange: table.updateRecordField,
    });
  }
  res.push({
    label: translate('Hours'),
    dataKey: 'HoursTotal',
    windowElementType: WindowElementType.kInputTypeVal,
    width: '38px',
    viewOnly: true,
    overwrite: table.overwriteMatrixRowDQty,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
  });
  res.push({
    label: translate('Days'),
    dataKey: 'DaysTotal',
    width: '38px',
    viewOnly: true,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
    cellstyle: {borderLeft: '1px solid #ddd', marginLeft: 5},
    windowElementType: WindowElementType.kInputTypeVal,
  });
  return res;
};

export function matrixFields(table): WindowMatrix {
  let def: WindowMatrix;
  if (global.userData.provider === 0) {
    def = matrixFields_LAT(table);
  } else {
    def = matrixFields_EST(table);
  }
  return def;
}
