import React from 'react';
import {Modal, View} from 'react-native';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import {
  DisplayApprovalStatus,
  DoNavigate,
  GetApprovalStatusText,
  GetDefaultBackBehaviour,
  GetParameterID,
  goBack,
  IsHRiOS,
  IsHRWeb,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import {InspectWindow} from '../edit_elements/InspectWindow';
import SigningWindow from '../../components/status/SigningWindow';
import {b64toBlob} from '../../Tools/Base64Decode';
import {translate} from '@utils/languageTools';
import {AddWindowFilter, GetWindowFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {LangColorContext} from '../../App';
import CheckboxButton from '../../components/edit_elements/CheckboxButton';
import EditField from '../../components/edit_elements/EditField';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {
  InputChangeEventProps,
  OverwriteBrowseColumnProps,
  WindowDefinition,
  WindowFieldLine,
  WindowRecord,
} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const Drawer = createDrawerNavigator();

function headerFields(table): WindowDefinition {
  let apprf = table.ApprovalStatus === '3';
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('Comment'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Comment',
          viewOnly: table.state.canapprove,
          width: '650px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('ActType'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ActType',
          viewOnly: false,
          width: '200px',
          pasteSpecial: {
            vcName: 'ActTypeVc',
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Comment',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: true,
          width: '150px',
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Approvers'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ApprovalFwdPersons',
          viewOnly: apprf,
          width: '500px',
          pasteSpecial: {
            vcName: 'UserVc',
            multiValue: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Cc'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'CCPersons',
          viewOnly: false,
          width: '500px',
          pasteSpecial: {
            vcName: 'UserVc',
            multiValue: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Person'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'MainPersons',
          viewOnly: apprf || table.state.cansign,
          width: '500px',
          pasteSpecial: {
            vcName: 'UserVc',
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
  ];

  if (table.state.canapprove && table.state.status !== undefined) {
    windowDefinition[0].fields.push({
      label: '',
      windowElementType: WindowElementType.kInputTypeText,
      dataKey: 'Status',
      viewOnly: true,
      width: '180px',
    });
  }
  if (table.CanUseApprovalRules() === 1) {
    let windowFieldLine: WindowFieldLine = {
      fields: [
        {
          label: translate('ApprovalStatus'),
          windowElementType: WindowElementType.kInputTypeText,
          fixedValue: table.ApprovalStatusText,
          viewOnly: true,
          width: '200px',
        },
      ],
    };
    switch (table.ApprovalStatus) {
      case '1':
      case '2':
      case '5':
        windowFieldLine.fields.push({
          label: translate('RequestApproval'),
          windowElementType: WindowElementType.kInputTypeButton,
          onPress: table.RequestApproval,
          width: '200px',
        });

        break;
    }
    windowDefinition.splice(5, 0, windowFieldLine);

    windowDefinition[1].fields.push({
      label: translate('Approved'),
      windowElementType: WindowElementType.kInputTypeCheckbox,
      checkboxInactiveLabel: translate('Approve'),
      checkboxTooltip: translate('WaitingApproval'),
      dataKey: 'OKFlag',
      viewOnly: true,
      onChange: table.updateRecordField,
    });
  } else {
    if (table.state.canapprove === false) {
      windowDefinition[1].fields.push({
        label: translate('Approved'),
        windowElementType: WindowElementType.kInputTypeCheckbox,
        checkboxInactiveLabel: translate('Approve'),
        dataKey: 'OKFlag',
        viewOnly: false,
        onChange: table.updateRecordField,
      });
    }
  }

  if (IsHRWeb() === false) {
    windowDefinition[0].fields[0].windowElementType = WindowElementType.kInputTypeLongText;
    windowDefinition[0].fields[0].style = {height: 90};
  }

  if (table.state.canapprove) {
    windowDefinition[1].fields.push({
      label: translate('Approve'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.approveActivity,
      width: '100px',
    });
  } else {
    if (table.CanUseApprovalRules() !== 1) {
      if (table.state.caninitsign) {
        windowDefinition[1].fields.push({
          label: translate('StartSigning'),
          windowElementType: WindowElementType.kInputTypeButton,
          onPress: table.initSigning,
          width: '170px',
        });
      }
      if (table.state.cansign) {
        windowDefinition[1].fields.push({
          label: translate('Sign'),
          windowElementType: WindowElementType.kInputTypeButton,
          onPress: table.signActivity,
          width: '100px',
        });
      }
      if (table.cancancel) {
        windowDefinition[1].fields.push({
          label: translate('Cancel'),
          onPress: table.cancelSigning,
          width: '100px',
          windowElementType: WindowElementType.kInputTypeButton,
        });
      }
      if (table.state.signstatus) {
        windowDefinition[1].fields.push({
          label: translate('Signers'),
          windowElementType: WindowElementType.kInputTypeButton,
          onPress: table.taskShowSigners,
          width: '120px',
        });
      }
    }
  }
  if (table.canreject) {
    windowDefinition[1].fields.push({
      label: translate('Reject'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.rejectActivity,
      width: '100px',
    });
    if (table.cancancel) {
      windowDefinition[1].fields.push({
        label: translate('CancelApproval'),
        windowElementType: WindowElementType.kInputTypeButton,
        onPress: table.cancelApproval,
        width: '150px',
      });
    }
  }
  if (table.CanOpenSubRecord()) {
    windowDefinition[1].fields.push({
      label: translate('OpenRecord'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.openRecord,
      width: '150px',
    });
  }

  if (table.CanUseApprovalRules() !== 1) {
    windowDefinition.splice(2, 1);
  }
  return windowDefinition;
}

interface TasksListState extends ListScreenState {
  newTaskHelper: boolean;
  NewActType: string;
  px: number;
  py: number;
  NewEmp: string;
  NewUser: string;
  Department: string;
}

export class TasksList extends ListScreen<{}, TasksListState> {
  constructor(props) {
    super(props);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.showExtraFilter = this.showExtraFilter.bind(this);
    this.rowClick = this.rowClick.bind(this);

    //this.changePage = this.changePage.bind(this);
    this.table = 'ActVc';
    this.ScreenName = 'Tasks';
    this.init = true;
    this.navigation = props.navigation;
    this.state = {
      data: [],
      newTaskHelper: false,
      NewActType: '',
      px: 0,
      py: 0,
      NewEmp: '',
      NewUser: global.userData.usercode,
      Department: global.userData.deplist.split(',').pop(),
      update: false,
    } as TasksListState;
    SetCurrentWindow2('Tasks', 'TransDate', 'descending');
    AddWindowFilter('Tasks', 'okflag', '0');
  }

  createNewTask = () => {
    let self = this;
    if (this.state.NewActType === '' || this.state.NewUser === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let tdata = {
      record: {
        ActType: this.state.NewActType,
        MainPersons: this.state.NewUser,
        TodoFlag: 1,
      },
    };
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        this.state.NewEmp = '';
        self.ShowHideNewTask();
        self.UpdateBrowseListData();
        global.LoadNotifications();
        self.rowClick(response.record.SerNr);
      })
      .catch((error) => {
        logError(error);
      });
  };
  ShowHideNewTask = () => {
    if (this.state.newTaskHelper) {
      global.PopEscapeActions();
      this.setState({newTaskHelper: false});
    } else {
      this.setState({newTaskHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewTask());
    }
  };

  showExtraFilter = (browsewindow) => {
    let self = this;
    if (isMobileOrTabletScreen()) {
      return;
    }
    return (
      <View>
        <CheckboxButton
          value={GetWindowFilter('Tasks', 'okflag') === '0' ? '0' : '1'}
          onChange={({value}: InputChangeEventProps) => {
            if (value === 1) {
              AddWindowFilter('Tasks', 'okflag', null);
            } else {
              AddWindowFilter('Tasks', 'okflag', '0');
            }
            if (UsePagination()) {
              browsewindow.GetTableData();
            } else {
              self.setState({update: self.state.update ? false : true});
            }
          }}
          style={{marginLeft: 0}}
          editable={true}
          title={translate('ShowOpen')}
          title2={translate('ShowAll')}
        />
      </View>
    );
  };
  renderNewButton = () => {
    let self = this;
    let style = {};
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }

    return (
      <View
        key={'tasks_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewTask()} title={translate('AddNew')} />
        {this.state.newTaskHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('Employee') + '*'}
                    value={this.state.NewUser}
                    pasteSpecial={{
                      vcName: 'UserVc',
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewUser: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('ActType') + '*'}
                    value={this.state.NewActType}
                    pasteSpecial={{
                      vcName: 'ActTypeVc',
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'Code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'Comment',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewActType: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewTask()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewTask()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <TableContainer
                table={this.table}
                update={this.state.update}
                data={this.state.data}
                browseColumns={[
                  {
                    name: translate('Date'),
                    key: 'transdate',
                    sortKey: 'TransDate',
                    width: '170px',
                    windowElementType: WindowElementType.kInputTypeDate,
                  },
                  {
                    name: translate('Comment'),
                    key: 'comment',
                    sortKey: 'Comment',
                    width: '100%',
                  },
                  {
                    name: translate('ApprovedShort'),
                    key: 'approvalstatus',
                    width: '100px',
                    overwrite: ({id, value, rowindex, colindex, row}: OverwriteBrowseColumnProps): string =>
                      DisplayApprovalStatus(id, value, rowindex, colindex, row),
                  },
                  {
                    name: translate('ActType'),
                    key: 'acttype',
                    sortKey: 'ActType',
                    width: '100px',
                  },
                ]}
                doClick={self.rowClick}
                rowID={'sernr'}
                extraFilter={this.showExtraFilter}
                title={translate(this.ScreenName)}
                extraContent={this.renderNewButton}
                width="800px"
              />
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface TasksRecordState extends RecordScreenState {
  hasapprovals: number;
  canapprove: boolean;
  comments: [];
  canprint: boolean;
  cansign: boolean;
  signstatus: boolean;
  cancancel: boolean;
  caninitsign: boolean;
  canreject: boolean;
  approvallistid: string;
  CommentSerNr: string;
  show: boolean;
  rec_table: string;
  status: string;
  rec_id: string;
  inlineact: string;
  statusnum: string;
  showexternals: boolean;
  customrows: null;
  hasexternals: boolean;
  canapprove_inline: boolean;
  newTaskHelper: boolean;
  NewRowName: string;
  NewRowEmail: string;
  newMatrixTableHelper: boolean;
  NewUser: string;
  signlink: string;
  Department: string;
  update: boolean;
}

export class TasksRecord extends RecordScreen<{}, TasksRecordState> {
  constructor(props) {
    super(props);
    let self = this;
    this.updateRecordField = this.updateRecordField.bind(this);
    this.approveActivity = this.approveActivity.bind(this);
    this.initSigning = this.initSigning.bind(this);
    this.signActivity = this.signActivity.bind(this);
    this.cancelSigning = this.cancelSigning.bind(this);
    this.printActivity = this.printActivity.bind(this);
    this.rejectActivity = this.rejectActivity.bind(this);
    this.cancelApproval = this.cancelApproval.bind(this);
    this.taskShowSigners = this.taskShowSigners.bind(this);
    this.handleExternalsChange = this.handleExternalsChange.bind(this);
    this.rowClick = this.rowClick.bind(this);

    this.table = 'ActVc';
    this.ScreenName = 'Tasks';
    this.init = true;
    this.navigation = props.navigation;
    this.state = {
      hasapprovals: -1,
      canapprove: false,
      comments: [],
      canprint: false,
      cansign: false,
      signstatus: false,
      cancancel: false,
      caninitsign: false,
      canreject: false,
      approvallistid: '',
      CommentSerNr: '',
      show: false,
      rec_table: '',
      status: '',
      rec_id: '',
      inlineact: '',
      statusnum: '0',
      showexternals: false,
      customrows: null,
      hasexternals: false,
      canapprove_inline: false,
      newTaskHelper: false,
      NewRowName: '',
      NewRowEmail: '',
      newMatrixTableHelper: false,
      NewUser: global.userData.usercode,
      signlink: '',
      update: false,
      Department: global.userData.deplist.split(',').pop(),
    } as TasksRecordState;
    this.record = new WindowRecord(this.table, true, headerFields(this));
    this.CheckOpenRecord();
  }

  CanOpenSubRecord = () => {
    let res = false;
    switch (this.state.rec_table) {
      case '12':
      case '2':
      case '18':
      case '23': // Old sales invoices
      case '32': // Old sales orders
      case 'IVVc':
      case 'ORVc':
      case 'VIVc':
        res = true;
        break;
    }
    return res;
  };
  approveActivity = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.doAction('approveactivity', {id: id})
      .then(() => {
        self.rowClick(self.id);
      })
      .catch((error) => {
        logError(error);
      });
  };
  initSigning = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.doAction('createsigningsess', {id: id})
      .then(() => {
        Requests.getTableData(39, {RecordNr: id})
          .then((response) => {
            response.records.map((rec) => {
              if (rec.CompanyNr === '1') {
                //TODO::FIX THIS SOMEHOW
                global.showSigners({show: true, record: rec, table: self});
                //break;
                //self.setState({externals: rec});
              }
            });
          })
          .catch((error) => {
            logError(error);
          });
      })
      .catch((error) => {
        logError(error);
      });
  };

  signActivity = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.doAction('signactivity', {id: id})
      .then((response) => {
        //self.rowClick(self.id);
        if (response.newid !== '') {
          self.setState({signlink: response.newid});
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  cancelSigning = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.doAction('cancelsigning', {id: id})
      .then(() => {
        self.rowClick(self.id);
      })
      .catch((error) => {
        logError(error);
      });
  };

  rejectActivity = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.doAction('rejectactivity', {id: id})
      .then(() => {
        self.rowClick(self.id);
      })
      .catch((error) => {
        logError(error);
      });
  };
  cancelApproval = () => {
    let self = this;

    Requests.doAction('cancelapproval', {
      id: this.state.rec_id,
      regname: this.state.rec_table,
    })
      .then(() => {
        self.rowClick(self.id);
      })
      .catch((error) => {
        logError(error);
      });
  };

  taskShowSigners = () => {
    let self = this;
    let id = this.id;
    if (this.state.canapprove_inline) {
      id = this.state.inlineact;
    }
    Requests.getTableData(39, {RecordNr: id})
      .then((response) => {
        response.records.map((rec) => {
          if (rec.CompanyNr === '1') {
            //TODO::FIX THIS SOMEHOW
            global.showSigners({
              show: true,
              record: rec,
              table: self,
              readonly: true,
            });
          }
        });
      })
      .catch((error) => {
        logError(error);
      });
  };

  printActivity = () => {
    this.downloadDocument(this.state.rec_table, this.state.rec_id);
  };
  openRecord = () => {
    switch (this.state.rec_table) {
      case '12':
        DoNavigate(this, 'ShiftTables', true, this.state.rec_id);
        break;
      case '2':
        DoNavigate(this, 'OrderDocs', true, this.state.rec_id);
        break;
      case '18':
        DoNavigate(this, 'VacPlans', true, this.state.rec_id);
        break;
      case '23': // Old invoices
      case 'IVVc':
        DoNavigate(this, 'Invoices', true, this.state.rec_id);
        break;
      case '32': // Old sales orders
      case 'ORVc':
        DoNavigate(this, 'SalesOrders', true, this.state.rec_id);
        break;
      case 'VIVc':
        DoNavigate(this, 'PurchaseInvoices', true, this.state.rec_id);
        break;
    }
  };
  GetExtraTaskData = () => {
    let self = this;
    Requests.doAction('getcomments', {id: this.id})
      .then((response) => {
        let canapprove = response.record.canapprove === '1';
        let canreject = response.record.canreject === '1';
        let cansign = response.record.cansign === '1';
        let signstatus = response.record.signstatus === '1';
        let cancancel = response.record.cancancel === '1';
        let caninitsign = response.record.caninitsign === '1';
        let hasexternals = response.record.hasexternals === '1';
        let canprint = response.record.canprint === '1';
        let canapprove_inline = response.record.canapprove_inline === '1';
        let inlineact = response.record.inlineact;
        let rec = self.record;
        rec.Status = translate('Status') + ': ' + GetApprovalStatusText(response.record.status);
        rec.CCPersons = response.record.actCC;
        self.setState({
          canapprove: canapprove,
          cansign: cansign,
          signstatus: signstatus,
          cancancel: cancancel,
          caninitsign: caninitsign,
          hasexternals: hasexternals,
          canreject: canreject,
          inlineact: inlineact,
          canapprove_inline: canapprove_inline,
          canprint: canprint,
          rec_table: response.record.rec_table,
          rec_id: response.record.rec_id,
          comments: response.record.rows,
          statusnum: response.record.status,
          id: response.record.commentrec_id,
          CommentSerNr: response.record.commentrec_id,
          approvallistid: rec.SerNr,
          status: GetApprovalStatusText(response.record.status),
          record: rec,
        });
      })
      .catch((error) => {
        logError(error);
      });
  };
  updateScreen = (): void => {
    this.setState({update: !this.state.update});
  };

  rowClick = (id: string) => {
    let self = this;
    this.state.hasapprovals = -1;
    this.state.signlink = '';
    this.state.hasapprovals = -1;
    this.state.canapprove = false;
    this.state.comments = [];
    this.state.canprint = false;
    this.state.CommentSerNr = '';
    this.state.show = false;
    this.state.rec_table = '';
    this.state.status = '';
    this.state.rec_id = '';
    Requests.getTableData(this.table, {SerNr: id}, undefined, undefined, true)
      .then((response) => {
        self.id = response.records[0].SerNr;
        self.record.applyNewValues(response.records[0]);
        self.updateScreen();
        self.GetExtraTaskData();
      })
      .catch((error) => {
        logError(error);
      });
  };

  CanUseApprovalRules = () => {
    let self = this;
    let res = this.state.hasapprovals;
    if (this.id !== undefined && this.id !== '') {
      if (this.state.hasapprovals === -1) {
        this.state.hasapprovals = 0;
        Requests.doAction('checkapprovalstatus', {
          id: this.id,
          regname: this.table,
        })
          .then((response) => {
            if (response.record.hasapprovals === '1') {
              self.ApprovalStatusText = GetApprovalStatusText(response.record.approvalstatus);
              self.ApprovalStatus = response.record.approvalstatus;
              self.setState({hasapprovals: 1});
            }
          })
          .catch((error) => {
            logError(error);
          });
      }
    }
    return res;
  };

  ShowExternalSigners = () => {
    if (this.state.showexternals) {
      global.ClearEscapeActions();
      this.setState({showexternals: false});
    } else {
      let self = this;
      Requests.getTableData(36, {SerNr: this.state.CommentSerNr})
        .then((response) => {
          if (response.records[0]) {
            self.setState({
              customrows: response.records[0].rows,
              showexternals: true,
            });
          } else {
            self.setState({customrows: [], showexternals: true});
          }
        })
        .catch((error) => {
          logError(error);
        });
    }
  };
  RequestApproval = () => {
    let self = this;
    this.state.hasapprovals = -1;
    Requests.doAction('sendforapproval', {
      id: this.id,
      regname: this.table,
    })
      .then(() => {
        self.state.hasapprovals = -1;
        self.state.showexternals = false;
        self.CanUseApprovalRules();
        self.GetExtraTaskData();
      })
      .catch((error) => {
        logError(error);
      });
  };
  showemptymatrix = () => {
    /*
    let res = [];
    res.push(
      <View style={{display: 'inline-block'}}>
        <ActionButton
          onPress={() => this.insertMatrixRow(0)}
          style={{display: 'inline-block'}}
          title={translate('InsertSigner')}
        />
      </View>,
    )
    */
    let self = this;
    let windowDefinition: WindowDefinition = [
      {
        fields: [
          {
            label: translate('InsertSigner'),
            windowElementType: WindowElementType.kInputTypeButton,
            onPress: () => self.insertMatrixRow(self.state.customrows.length),
            width: '170px',
          },
        ],
      },
    ];
    return windowDefinition;
  };
  insertMatrixRow = (index) => {
    this.state.NewRowName = '';
    this.state.NewRowEmail = '';
    this.state.NewRowIndex = index;
    this.setState({newMatrixTableHelper: true});
  };
  deleteMatrixRow = (index) => {
    let self = this;
    let sernr = this.id;
    Requests.deleteRecordRow(36, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(36, {SerNr: sernr})
            .then((response) => {
              self.setState({customrows: response.records[0].rows});
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  TaskRowActions = () => {
    let res = [];
    //if (table.record.OKFlag !== '1') {
    res.push({
      name: translate('InsertSigner'),
      action: this.insertMatrixRow,
    });
    res.push({
      name: translate('DeleteRow'),
      action: this.deleteMatrixRow,
    });
    //}
    return; //always return empty. we don't want this anymore, we have a button now
  };

  TestNewRowData = () => {
    let errstr = '';
    if (this.state.NewRowEmail === '') {
      errstr = translate('BlankEmail');
    }
    if (this.state.NewRowName.indexOf(' ') < 1) {
      errstr = translate('FillNameAndSurname');
    }
    if (this.state.NewRowName === '') {
      errstr = translate('BlankName');
    }

    if (errstr !== '') {
      global.messageBox({
        showMessage: true,
        statusMessage: errstr,
      });
      return false;
    } else {
      return true;
    }
  };

  createNewTableRow = () => {
    let self = this;

    if (this.TestNewRowData() === false) {
      return;
    }
    let res = {
      list: [
        {
          rownr: self.state.NewRowIndex + 1,
          field: 'Name',
          value: self.state.NewRowName,
        },
        {
          rownr: self.state.NewRowIndex + 1,
          field: 'eMail',
          value: self.state.NewRowEmail,
        },
      ],
      id: self.id,
    };
    if (this.state.hasexternals === false) {
      res = {
        record: {
          SerNr: this.id,
          rows: [
            {
              Name: self.state.NewRowName,
              eMail: self.state.NewRowEmail,
            },
          ],
        },
      };

      Requests.getNewRecord(36, res)
        .then((response) => {
          self.setState({
            customrows: response.record.rows,
            newMatrixTableHelper: false,
          });
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      res.id = self.id;
      Requests.insertRecordRow(36, this.id + ':' + (this.state.NewRowIndex + 1) + ':1')
        .then((response) => {
          if (response.success) {
            if (self._isMounted) {
              Requests.updateRecordData(36, res)
                .then((response) => {
                  if (response.success) {
                    if (self._isMounted) {
                      self.setState({
                        customrows: response.record.rows,
                        newMatrixTableHelper: false,
                      });
                    }
                  }
                })
                .catch((error) => {
                  logError(error);
                });
            }
          }
        })
        .catch((error) => {
          logError(error);
        });
    }
  };
  renderNewRowButton = () => {
    let self = this;
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Name')}
              name={'Name'}
              width={'260px'}
              value={this.state.NewRowName}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowName: value})}
            />
          </FilterField>
          <FilterField>
            <EditField
              title={translate('Email')}
              name={'eMail'}
              width={'260px'}
              value={this.state.NewRowEmail}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowEmail: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };

  handleExternalsChange = (rowindex, index, value) => {
    let self = this;
    Requests.updateRecordData(36, {
      field: index,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.setState({customrows: response.record.rows});
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
            global.LoadNotifications();
            if (fieldname === 'ActType') {
              self.state.hasapprovals = -1;
              self.CanUseApprovalRules();
            }
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  AllowDeletingActivity = () => {
    let res = true;
    let appr = this.CanUseApprovalRules();
    switch (appr) {
      case -1:
        res = false;
        break;
      case 1:
        switch (this.ApprovalStatus) {
          case '1':
          case '2':
          case '5':
            break;
          default:
            res = false;
        }
    }
    if (this.state.canapprove && this.state.status !== undefined) {
      res = false;
    }
    return res;
  };

  componentDidUpdate() {
    let param_id = GetParameterID(this);
    if (this.id !== param_id && param_id !== '') {
      this.id = param_id;
      this.rowClick(this.id);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    let self = this;
    if (this.props.navigation) {
      this.focusSubscription = this.props.navigation.addListener('focus', () => {
        if (self.init === false) {
          this.rowClick(this.id);
        }
        self.init = false;
      });
    }
  }

  deleteRecord = () => {
    let self = this;
    this.setState({activeDelete: false});
    Requests.deleteRecordOld(this.table, this.id)
      .then((response) => {
        if (response.success === true) {
          self.id = '';
          global.LoadNotifications();
          DoNavigate(self, 'Tasks', false, '', true, true);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  downloadDocument = (table, id) => {
    /*application/octet-stream*/
    //window.open('about:blank', 'Downloading');
    Requests.downloadDocument(table, {id: id})
      .then((response) => {
        if (response.file !== '') {
          let blob = b64toBlob(response.file, 'application/pdf');
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          //a.download = response.name;
          //  a.target = '_blank';
          document.body.appendChild(a);
          window.open(url, 'Downloading');
          //window.URL.revokeObjectURL(url);
        } else {
          global.messageBox({
            showMessage: true,
            statusMessage: translate('DocumentNotFound'),
          });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  FinishSuccessFulSign = () => {
    let self = this;
    this.setState({signlink: ''}, function () {
      self.rowClick(self.id);
    });
  };
  ShouldFreezeAttachments = () => {
    let res = false;
    if (this.state.statusnum === '3' || this.state.statusnum === '4') {
      res = true;
    }
    return res;
  };

  render() {
    let self = this;
    return (
      <LangColorContext.Consumer>
        {() => {
          return (
            <WindowWrap>
              <InspectWindow
                title={translate('Tasks')}
                window={this}
                headerFields={headerFields(this)}
                record={this.record}
                onClick={this.handleInputClick}
                okcheck={'OKFlag'}
                allowDelete={self.AllowDeletingActivity()}
                allowPrint={false}
                comments={this.state.comments}
                hasApprovals={this.hasapprovals === 1}
                rowactions={this.TaskRowActions()}
                custommatrixdata={this.state.customrows}
                matrixFooterFields={this.showemptymatrix()}
                freezeattach={this.ShouldFreezeAttachments()}
                // width="1293px"
                //width="1309px"
                width={'980px'}
                backButton={() => goBack(self, self.ScreenName)}
              />
              {this.state.newMatrixTableHelper && (
                <Modal transparent={true}>
                  <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
                </Modal>
              )}
              {this.state.activeDelete && (
                <Modal transparent={true}>
                  <YesNoWindow
                    question={translate('RemoveQuestion')}
                    yes={() => this.deleteRecord()}
                    no={() => this.setState({activeDelete: false})}
                  />
                </Modal>
              )}
              {this.state.signlink !== '' && (
                <SigningWindow link={this.state.signlink} finish={() => self.FinishSuccessFulSign()}></SigningWindow>
              )}
            </WindowWrap>
          );
        }}
      </LangColorContext.Consumer>
    );
  }
}

function Tasks_old() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="TasksList"
        component={TasksList}
        options={{
          title: translate('Tasks'),
        }}
      />
      <Drawer.Screen
        name="TasksRecord"
        component={TasksRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('Task'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default Tasks_old;
