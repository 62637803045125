import React from 'react';
import {Modal, Text, View} from 'react-native';
import Requests from '../../api/Requests';
import {TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {
  ConvertDisplayValue,
  DisplayBrowseOKFlag,
  DoNavigate,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRWeb,
  IsManager,
  ShowBreakHours,
  Supported,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {YesNoWindow} from '../status/YesNoWindow';
import {InspectWindow} from '../edit_elements/InspectWindow';
import {translate} from '@utils/languageTools';
import {DayIsRedDay, GetDayColor, GetIntervalColor, GetOffWorkColor, GetWebColor} from '../../Tools/ShiftTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {LangColorContext} from '../../App';
import {isApproved} from '../../utils/helpers';
import * as myDate from '../../Tools/DateTools';
import {ModalWindow_Old} from '../status/ModalWindow_Old';
import EditField, {PasteSpecialName} from '../../components/edit_elements/EditField';
import {StyledRow} from '../edit_elements/Matrix';
import ShiftCalendar from '../../components/edit_elements/ShiftCalendar';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {
  InputChangeEventProps,
  OverwriteMatrixProps,
  WindowDefinition,
  WindowFieldLine,
  WindowMatrix,
  WindowRecord,
} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {APISupportsItem} from '@src/utils/drawersTools';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

function headerFields(table): WindowDefinition {
  let manf = IsManager();
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'DepCode',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'DepCode',
                mainKey: 'DepCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          viewOnly: true,
          width: '150px',
        },
        {
          label: translate('Post'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'PostCode',
          viewOnly: true,
          width: '150px',
          pasteSpecial: {
            vcName: 11,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'PostCode',
                mainKey: 'PostCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'PostName',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: !manf,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Approved'),
          windowElementType: WindowElementType.kInputTypeCheckbox,
          checkboxInactiveLabel: translate('Approve'),
          dataKey: 'OKFlag',
          viewOnly: !manf,
          onChange: table.updateRecordField,
        },
        {
          label: translate('Plan'),
          windowElementType: WindowElementType.kInputTypeCheckbox,
          checkboxInactiveLabel: translate('Plan'),
          dataKey: 'PlanFlag',
          viewOnly: !manf,
          onChange: table.updateRecordField,
        },
      ],
    },

    {
      fields: [
        {
          label: translate('StartDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'StartDate',
          viewOnly: true,
          width: '150px',
        },
        {
          label: translate('EndDate'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'EndDate',
          viewOnly: true,
          width: '150px',
        },
        {
          label: translate('Shift'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ShiftGroup',
          viewOnly: !manf,
          width: '150px',
          pasteSpecial: {
            vcName: 13,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Comment',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('NormHours'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'NormalHours',
          viewOnly: true,
          width: '150px',
        },
      ],
    },
  ];

  let action = table.switchDetailedMode;
  let style = {};
  if (table.state.ShiftMode) {
    action = null;
    style = {
      backgroundColor: colours[GetGlobalTheme()].buttonBackgroundInactive,
    };
  }

  if (table.state.detailedf) {
    windowDefinition[1].fields.push({
      label: translate('Overview'),
      onPress: action,
      width: '100px',
      style: style,
      windowElementType: WindowElementType.kInputTypeButton,
    });
  } else {
    windowDefinition[1].fields.push({
      label: translate('Detailed'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: action,
      width: '100px',
      style: style,
    });
  }

  if (manf) {
    windowDefinition[1].fields.push({
      label: table.state.ShiftMode ? translate('HideShifts') : translate('ShowShifts'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.switchShiftMode,
      width: '100px',
    });
    if (Supported('nonewtimetables') === false) {
      windowDefinition[1].fields.push({
        label: translate('CreateTable'),
        windowElementType: WindowElementType.kInputTypeButton,
        onPress: table.createTimeTable2,
        width: '150px',
      });
    }
    windowDefinition[1].fields.push({
      label: translate('UpdateOffWork'),
      onPress: table.updateOffWork,
      width: '180px',
      windowElementType: WindowElementType.kInputTypeButton,
    });
    windowDefinition[0].fields.push({
      label: translate('CopyShift'),
      windowElementType: WindowElementType.kInputTypeButton,
      onPress: table.copyShiftSheet,
      width: '130px',
    });
  }
  if (global.shiftBlock && objectHasProperty(global.shiftBlock, 'ordertype') && global.shiftBlock.ordertype === '1') {
    windowDefinition[1].fields.push({
      label: translate('Filter'),
      windowElementType: WindowElementType.kInputTypeText,
      dataKey: 'PostCode',
      fixedValue: table.state.Filter,
      viewOnly: false,
      width: '200px',
      pasteSpecial: {
        vcName: 11,
        columns: [
          {
            name: translate('Code'),
            width: '35%',
            key: 'PostCode',
            mainKey: 'PostCode',
          },
          {
            width: '65%',
            name: translate('Name'),
            key: 'PostName',
          },
        ],
      },
      onChange: table.filterMatrixPost,
    });
  }
  if (global.userData.provider === 1) {
    windowDefinition[0].fields.splice(2, 1);
  }

  if (table.CanUseApprovalRules() === 1) {
    let windowFieldLine: WindowFieldLine = {
      fields: [
        {
          label: translate('ApprovalStatus'),
          windowElementType: WindowElementType.kInputTypeText,
          fixedValue: table.ApprovalStatusText,
          viewOnly: true,
          width: '200px',
        },
      ],
    };
    if (IsManager()) {
      switch (table.ApprovalStatus) {
        case '1':
        case '2':
        case '5':
          windowFieldLine.fields.push({
            label: translate('RequestApproval'),
            windowElementType: WindowElementType.kInputTypeButton,
            onPress: table.RequestApproval,
            width: '200px',
          });

          break;
        case '3':
          if (table.cancancel) {
            windowFieldLine.fields.push({
              label: translate('CancelApproval'),
              windowElementType: WindowElementType.kInputTypeButton,
              onPress: table.cancelApproval,
              width: '150px',
            });
          }
      }
    }

    windowDefinition[1].fields.push(...windowFieldLine.fields);
  }

  return windowDefinition;
}

function matrixFooterFields(table): WindowDefinition {
  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('OpenTable'),
          windowElementType: WindowElementType.kInputTypeButton,
          onPress: table.openTimeTable,
          width: '150px',
        },
        {
          label: translate('Total'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'TotalHours',
          viewOnly: true,
          width: '150px',
          row: true,
          style: {
            backgroundColor: colours[GetGlobalTheme()].inputBackground,
            textAlign: 'right',
            paddingRight: spacing.medium,
          },
        },
      ],
      style: {
        justifyContent: 'flex-end',
      },
    },
  ];

  if (!IsManager()) {
    windowDefinition[0].fields.splice(0, 1);
  }

  return windowDefinition;
}

const matrixFields = (table, type, w = 0) => {
  let res: WindowMatrix = [];
  res.push({
    label: translate('ContractNr'),
    dataKey: 'ContractNr',
    width: '70px',
    viewOnly: true,
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: translate('Employee'),
    dataKey: 'EmpName',
    width: '140px',
    viewOnly: true,
    showProfile: 'EmpCode',
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: ' ',
    dataKey: 'LineFlag',
    width: w,
    viewOnly: true,
    cellstyle: {borderRight: '1px solid #ddd'},
    onChange: table.handleMultiInputChange,
    windowElementType: WindowElementType.kInputTypeText,
  });
  let viewonly = false;
  if (type === 0) {
    viewonly = true;
  }
  let vColor = GetDayColor(table.record?.headerFields);
  let days = 32;
  if (objectHasProperty(table.record?.headerFields, 'EndDate') && table.record.headerFields.EndDate !== '') {
    days = myDate.extractDateFromString(table.record.headerFields.EndDate).getDate() + 1;
  }
  for (let i = 1; i < days; i++) {
    res.push({
      label: i.toString(),
      dataKey: 'Day' + i /* + addtxt*/,
      width: w,
      viewOnly: viewonly,
      style: {textAlign: 'center', fontSize: fontSize.small200},
      cellstyle: {backgroundColor: vColor[i], justifyContent: 'center'},
      overwrite: table.showDayColor,
      onChange: table.updateRecordField,
      windowElementType: WindowElementType.kInputTypeText,
    });
  }
  res.push({
    label: translate('Total'),
    dataKey: 'TotalHoursRow',
    width: '50px',
    viewOnly: true,
    overwrite: table.showRowTotal,
    style: {justifyContent: 'flex-end', textAlign: 'right'},
    windowElementType: WindowElementType.kInputTypeText,
  });
  res.push({
    label: '',
    windowElementType: WindowElementType.kInputTypeText,
    dataKey: 'PostCodeRow',
    viewOnly: false,
    display: false,
  });
  return res;
};

const FilterView = styled.View`
  position: absolute;
  top: 100%;
  right: 0px;
  width: 470px;
  background: ${colours[GetGlobalTheme()].lightBackground};
  padding: 20px;
  flex-direction: column;
  align-items: center;
`;
const PostRow = styled.View`
  ${IsHRWeb() &&
  css`
    margin-horizontal: ${spacing.medium};
    padding-horizontal: ${spacing.medium200};
  `}
  padding-vertical: ${spacing.medium};
  flex-direction: row;
`;
const MatrixDivider = styled.View`
  ${(IsHRWeb() &&
    css`
      width: calc(100% - ${spacing.medium * 2}px - 12px);
    `) ||
  css`
    width: 100%;
  `}
  height: 1px;
  border-bottom-color: ${colours[GetGlobalTheme()].normalHighligh};
  border-bottom-width: 1;
  margin-horizontal: ${spacing.medium250};
  border-radius: 6px;
`;

const NormalText = styled.Text`
  background-color: transparent;
  width: 100%;
  text-align: center;
  border-bottom: none;
  color: ${colours[GetGlobalTheme()].rowText};
`;
const PostText = styled.Text`
  background-color: transparent;
  text-align: left;
  border-bottom: none;
  color: ${colours[GetGlobalTheme()].rowText};
`;
const ShiftValue = styled.Text`
  border-left: 1px solid #ddd;
  margin-left: ${spacing.medium};
  padding-left: ${spacing.medium};
`;

const FilterFields = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-right: -30px;
`;
const FilterActions = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

const FilterField = styled.View`
  margin-right: 30px;
`;

const TimeSlotRow = styled.View`
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const TimeSlotActions = styled.View`
  flex-direction: row;
  align-items: space-between;
`;

const ManualEntryTitle = styled.View`
  padding-horizontal: ${spacing.small300};
  flex-direction: row;
`;

const TimeTableListWrap = styled.View`
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
  margin-top: 40px;
`;
const TimeTableTextWrap = styled.View`
  margin-bottom: 15px;
  text-align: center;
`;
const TimeTableText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;
const TimeTableListNav = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const ClickableCell = styled.TouchableOpacity``;

function CurrentDate() {
  return new Date();
}

function ShiftSheetRowActions(table) {
  let res = [];
  if (table.record.headerFields.OKFlag !== '1') {
    res.push({
      name: translate('InsertRow'),
      action: table.insertMatrixRow,
    });
    res.push({
      name: translate('CopyRow'),
      action: table.copyMatrixRow,
    });
    res.push({
      name: translate('DeleteRow'),
      action: table.deleteMatrixRow,
    });
    if (Supported('nonewtimetables') === false) {
      res.push({
        name: translate('CreateTimeTable'),
        action: table.createTimeTableRow,
      });
    }
  }
  if (IsManager() && res.length > 0) {
    return res;
  }
}

interface ShiftTablesListState extends ListScreenState {
  newTableHelper: boolean;
  px: number;
  py: number;
  Department: string;
  Month: string;
  Year: number;
  select_record: any;
  Post: string;
  CopyShift: string;
}

export class ShiftTablesList extends ListScreen<{}, ShiftTablesListState> {
  RecordList: Array<any>;

  constructor(props) {
    super(props);
    this.createNewTable = this.createNewTable.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.table = 12;
    //this.changePage = this.changePage.bind(this);
    this.ScreenName = 'ShiftTables';
    this.init = true;
    this.RecordList = [];
    this.state = {
      ...this.state,
      data: [],
      newTableHelper: false,
      Month: '01',
      select_record: null,
      px: 0,
      py: 0,
      Year: CurrentDate().getFullYear(),
      Department: global.userData.deplist.split(',').pop(),
      Post: '',
      CopyShift: '',
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('ShiftTables', 'SerNr', 'descending');
    AddDateFilter('ShiftTables');
    if (isMobileOrTabletScreen()) {
      this.displayBrowseList();
    }
  }

  getBrowseListData = () => {
    let self = this;
    Requests.getTableData(this.table, undefined, undefined, undefined, true, 0, 40, 'StartDate', 'descending')
      .then((response) => {
        if (self._isMounted) {
          if (isDesktopScreen()) {
            self.setState({data: response.records});
          } else {
            let list = self.GetShiftRecordList(response.records);
            let actnum = self.getShiftNumber(list);
            global.setWindowTitle(translate('ShiftTables'));
            self.RecordList = list;
            if (list.length > 0) {
              self.getLocalRecord(actnum.sernr);
            } else {
              //display some message?
            }
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  GetShiftRecordList = (list) => {
    let nlist = [].concat(list);
    nlist.sort(function (a, b) {
      return a.startdate < b.startdate ? -1 : 1;
    });
    return nlist;
  };
  getShiftNumber = (list) => {
    let res = -1;
    let firstf = true;
    let curdate = myDate.getCurrentDate();
    for (let i = list.length - 1; i >= 0; i--) {
      let td = myDate.extractDateFromString(list[i].startdate);
      if (td.getMonth() === curdate.getMonth() || firstf) {
        firstf = false;
        res = parseInt(list[i].sernr);
        return {sernr: res, num: i};
      }
    }
    return {sernr: res, num: -1};
  };

  getLocalRecord = (id) => {
    let self = this;
    Requests.getTableData(this.table, {SerNr: id})
      .then((response) => {
        self.setState({select_record: response.records[0]});
      })
      .catch((error) => {
        logError(error);
      });
  };

  checkNewTableData = () => {
    let res = true;
    if (this.state.Year === '') {
      res = false;
    }
    if (this.state.Month === '') {
      res = false;
    }
    if (this.state.Department === '') {
      res = false;
    }
    return res;
  };

  createNewTable = () => {
    let self = this;
    if (this.checkNewTableData() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let tdata = {
      record: {
        DepCode: this.state.Department,
        PostCode: this.state.Post,
        StartDate: this.state.Year + '-' + this.state.Month + '-01',
        CopyShift: this.state.CopyShift,
      },
    };
    this.setState({
      Department: global.userData.deplist.split(',').pop(),
      Post: '',
      CopyShift: '',
    });
    this.ShowHideNewTable();
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (response.success === true) {
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewTable = () => {
    if (this.state.newTableHelper) {
      global.PopEscapeActions();
      this.setState({newTableHelper: false});
    } else {
      this.setState({newTableHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewTable());
    }
  };

  renderNewButton = () => {
    let self = this;
    if (isMobileOrTabletScreen()) {
      return;
    }
    return (
      <View
        key={'shifttable_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        {IsManager() && <ActionButton onPress={() => this.ShowHideNewTable()} title={translate('AddNew')} />}
        {this.state.newTableHelper && (
          <FilterView>
            <FilterFields>
              <FilterField>
                {global.userData.provider === 0 && (
                  <EditField
                    title={translate('Department') + '*'}
                    value={this.state.Department}
                    width={'180px'}
                    pasteSpecial={{
                      vcName: 7,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'DepCode',
                          mainkey: 'DepCode',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'Name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => {
                      self.setState({Department: value});
                    }}
                  />
                )}
                {global.userData.provider === 1 && (
                  <EditField
                    title={translate('Department') + '*'}
                    value={this.state.Department}
                    width={'180px'}
                    pasteSpecial={{
                      vcName: 7,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'Code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'Name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => {
                      self.setState({Department: value});
                    }}
                  />
                )}
              </FilterField>
              {global.userData.provider === 0 && (
                <FilterField>
                  <EditField
                    title={translate('Post')}
                    value={this.state.Post}
                    width={'180px'}
                    pasteSpecial={{
                      vcName: 11,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'PostCode',
                          mainkey: 'PostCode',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'PostName',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({Post: value})}
                  />
                </FilterField>
              )}
              <FilterField>
                <EditField
                  title={translate('Year') + '*'}
                  value={this.state.Year}
                  width={'180px'}
                  pasteSpecial={{
                    vcName: 9993,
                    fields: ['code', 'comment'],
                  }}
                  onChange={({value}: InputChangeEventProps) => self.setState({Year: value})}
                />
              </FilterField>
              <FilterField>
                <EditField
                  title={translate('Month') + '*'}
                  value={this.state.Month}
                  width={'180px'}
                  pasteSpecial={{
                    vcName: 9994,
                    fields: ['code', 'comment'],
                  }}
                  onChange={({value}: InputChangeEventProps) => self.setState({Month: value})}
                />
              </FilterField>
              <FilterField>
                <EditField
                  title={translate('CopyShift')}
                  value={this.state.CopyShift}
                  width={'180px'}
                  pasteSpecial={{
                    vcName: 12,
                    pssort: {key: 'sernr', dir: 'descending'},
                    extraParams: {perpage: 20, page: 0, order: 'descending', sortkey: 'sernr'},
                    columns: [
                      {
                        name: translate('SerNr'),
                        width: '15%',
                        key: 'sernr',
                        mainkey: 'SerNr',
                      },
                      {
                        width: '40%',
                        name: translate('Comment'),
                        key: 'comment',
                      },
                      {
                        width: '15%',
                        name: translate('Department'),
                        key: 'depcode',
                      },
                      {
                        width: '15%',
                        name: translate('StartDate'),
                        key: 'startdate',
                      },
                      {
                        width: '15%',
                        name: translate('EndDate'),
                        key: 'endddate',
                      },
                    ],
                  }}
                  onChange={({value}: InputChangeEventProps) => self.setState({CopyShift: value})}
                />
              </FilterField>
            </FilterFields>
            <FilterActions>
              <ActionButton
                style={{marginTop: '20px', marginRight: '20px'}}
                onPress={() => this.ShowHideNewTable()}
                title={translate('Cancel')}
              />
              <ActionButton
                style={{marginTop: '20px'}}
                onPress={() => this.createNewTable()}
                title={translate('Create')}
              />
            </FilterActions>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    return (
      <LangColorContext.Consumer>
        {(data) => {
          let self = this;
          if (isDesktopScreen()) {
            let columns = [
              {
                name: translate('SerNr'),
                key: 'sernr',
                sortKey: 'SerNr',
                width: '120px',
              },
              {
                name: translate('Department'),
                key: 'depcode',
                sortKey: 'DepCode',
                width: '100px',
              },
              {
                name: translate('Date'),
                key: 'transdate',
                sortKey: 'TransDate',
                width: '115px',
                windowElementType: WindowElementType.kInputTypeDate,
              },
              {
                name: translate('StartDate'),
                key: 'startdate',
                sortKey: 'SerNr',
                width: '115px',
                windowElementType: WindowElementType.kInputTypeDate,
              },
              {
                name: translate('EndDate'),
                key: 'enddate',
                sortKey: 'EndDate',
                width: '115px',
                windowElementType: WindowElementType.kInputTypeDate,
              },
              {
                name: translate('Plan'),
                key: 'planflag',
                sortKey: 'PlanFlag',
                width: '35px',
                overwrite: DisplayBrowseOKFlag,
              },
              {
                name: translate('ApprovedShort'),
                key: 'okflag',
                sortKey: 'OKFlag',
                width: '35px',
                overwrite: DisplayBrowseOKFlag,
              },
            ];
            switch (global.userData.provider) {
              case 0:
                columns.splice(2, 0, {
                  name: translate('Post'),
                  key: 'postname',
                  sortKey: 'PostName',
                  width: '200px',
                });
                break;
              case 1:
                columns.splice(2, 0, {
                  name: translate('Shift'),
                  key: 'shiftgroup',
                  sortKey: 'ShiftGroup',
                  width: '200px',
                });
                break;
            }
            return (
              <WindowWrap key={data.globalupdate}>
                <TableContainer
                  table={this.table}
                  update={this.state.update}
                  data={this.state.data}
                  browseColumns={columns}
                  doClick={self.rowClick}
                  rowID={'sernr'}
                  title={translate(this.ScreenName)}
                  width="1000px"
                  extraContent={this.renderNewButton}
                />
              </WindowWrap>
            );
          } else {
            if (this.state.select_record) {
              return <ShiftCalendar record={this.state.select_record} list={this.RecordList} window={this} />;
            }
          }
        }}
      </LangColorContext.Consumer>
    );
  }
}

interface TimeTablesRecordState extends RecordScreenState {
  NewRowContract: string;
  NewRowType: string;
  NewRowIndex: number;
  newMatrixTableHelper: boolean;
  ShiftMode: boolean;
  Month: string;
  StartTime: string;
  EndTime: string;
  Break: string;
  UpdateTime: boolean;
  Filter: string;
  timetableid: string;
  px: any;
  py: any;
  detailedf: boolean;
  Year: number;
  Department: string;
  Post: string;
  NewRowLine: any;
  cancancel: boolean;
}

export class ShiftTablesRecord extends RecordScreen<{}, TimeTablesRecordState> {
  ActiveUpdates: number;
  CurPostVal: number;
  CurPostShifts: any;

  constructor(props) {
    super(props);
    let self = this;
    this.updateRecordField = this.updateRecordField.bind(this);
    this.handleMultiInputChange = this.handleMultiInputChange.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.switchShiftMode = this.switchShiftMode.bind(this);
    this.switchDetailedMode = this.switchDetailedMode.bind(this);
    this.updateOffWork = this.updateOffWork.bind(this);
    this.openTimeTable = this.openTimeTable.bind(this);
    this.createTimeTable2 = this.createTimeTable2.bind(this);
    this.createTimeTableRow = this.createTimeTableRow.bind(this);
    this.insertMatrixRow = this.insertMatrixRow.bind(this);
    this.copyMatrixRow = this.copyMatrixRow.bind(this);
    this.deleteMatrixRow = this.deleteMatrixRow.bind(this);
    this.showRowTotal = this.showRowTotal.bind(this);
    this.showDayColor = this.showDayColor.bind(this);
    this.shouldShowMatrixRow = this.shouldShowMatrixRow.bind(this);
    this.RequestApproval = this.RequestApproval.bind(this);
    this.cancelApproval = this.cancelApproval.bind(this);
    this.copyShiftSheet = this.copyShiftSheet.bind(this);

    this.table = 12;
    //this.changePage = this.changePage.bind(this);
    this.ActiveUpdates = 0;
    this.ScreenName = 'ShiftTables';
    this.CurPostVal = 0;
    this.CurPostShifts = {};
    this.navigation = props.navigation;

    this.state = {
      ShiftMode: false,
      newMatrixTableHelper: false,
      NewRowContract: '',
      NewRowType: '',
      NewRowIndex: -1,
      Month: '01',
      StartTime: '00:00',
      EndTime: '00:00',
      Break: '00:00',
      UpdateTime: false,
      NewRowLine: null,
      Filter: '',
      timetableid: '',
      px: {},
      py: {},
      detailedf: false,
      Year: CurrentDate().getFullYear(),
      Department: global.userData.deplist.split(',').pop(),
      Post: '',
      update: false,
      activeDelete: false,
      cancancel: false,
    };
    this.record = new WindowRecord(
      12,
      true,
      headerFields(this),
      matrixFields(this, 0).concat(matrixFields(this, 1)),
      matrixFooterFields(this)
    );

    this.CheckOpenRecord();
  }

  createNewRecord = () => {
    return new WindowRecord(this.table, true, headerFields(this), matrixFields(this, 0, 30));
  };

  filterMatrixPost = (rowindex, index, value) => {
    this.setState({Filter: value});
  };
  rowClick = (id) => {
    let self = this;
    this.state.ShiftMode = false;
    this.record = this.createNewRecord();
    this.hasapprovals = this.canUseApprovalRules();
    Requests.getTableData(this.table, {SerNr: id}, undefined, undefined, true)
      .then((response) => {
        self.id = response.records[0]['SerNr'];
        self.record.applyNewValues(response.records[0]);
        self.CanUseApprovalRules();
        if (self._isMounted) {
          self.updateScreen();
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  insertMatrixRow = (index) => {
    this.setState({
      NewRowContract: this.record.matrixRows[index].ContractNr,
      NewRowIndex: index,
      newMatrixTableHelper: true,
    });
  };

  prepareListField = (rownr, row) => {
    let res = [];
    for (let i in row) {
      if (i.substring(0, 3) === 'Day') {
        res.push({
          rownr: rownr,
          field: i,
          value: row[i],
        });
      }
    }
    return res;
  };

  copyMatrixRow = (index) => {
    this.setState({
      NewRowIndex: index,
      NewRowContract: this.record.matrixRows[index].ContractNr,
      NewRowLine: this.prepareListField(index + 1, this.record.matrixRows[index]),
      newMatrixTableHelper: true,
    });
  };
  deleteMatrixRow = (index) => {
    let self = this;
    let sernr = this.id;
    Requests.deleteRecordRow(this.table, sernr + ':' + index)
      .then((response) => {
        if (response.success) {
          Requests.getTableData(this.table, {SerNr: sernr})
            .then((response) => {
              if (self._isMounted) {
                self.id = response.records[0].SerNr;
                self.record.applyNewValues(response.records[0]);
                //self.UpdateRecordRows(the_record);??is this needed?
                self.setState({
                  newMatrixTableHelper: false,
                });
              }
            })
            .catch((error) => {
              logError(error);
            });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  createTimeTableRow = (index) => {
    this.createTimeTable2(index);
  };

  createNewTableRow = () => {
    let self = this;
    Requests.insertRecordRow(this.table, this.id + ':' + (this.state.NewRowIndex + 1) + ':1')
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            let list = [];
            if (self.state.NewRowLine) {
              list = self.state.NewRowLine;
              self.state.NewRowLine = null;
            }
            list.push({
              rownr: self.state.NewRowIndex + 1,
              field: 'ContractNr',
              value: self.state.NewRowContract,
            });
            let res = {
              list: list,
              id: self.id,
            };
            Requests.updateRecordData(self.table, res)
              .then((response) => {
                if (response.success) {
                  if (self._isMounted) {
                    self.record.applyNewValues(response.record);
                    //self.UpdateRecordRows(the_record);
                    self.setState({
                      newMatrixTableHelper: false,
                    });
                  }
                }
              })
              .catch((error) => {
                logError(error);
              });
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  copyShiftSheet = () => {
    Requests.doAction('copyshiftsheet', {id: this.id})
      .then((response) => {
        if (response.newid) {
          DoNavigate(this, 'ShiftTables', true, response.newid);
        } else {
          global.messageBox2({
            show: true,
            title: translate('FailedToCreateShiftSheet'),
            buttons: [{label: translate('OK'), onPress: () => global.messageBox2({show: false}), style: {flexGrow: 1}}],
          });
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  updateRecordField2 = (rowindex, index, value) => {
    let self = this;
    this.ActiveUpdates++;
    /*TODO: why is this needed
    if (rowindex >= 0) {
      let the_record = this.record;
      this.record = {SerNr: this.id};
      the_record.rows[rowindex].index = value;
      this.UpdateRecordRows(the_record);
      this.setState({record: the_record});
    }
    */
    let data = {
      list: [],
      id: this.id,
    };
    index.map((field, tindex) => {
      data.list.push({
        field: field,
        rownr: rowindex,
        value: value[tindex],
      });
    });

    Requests.updateRecordData(this.table, data)
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.ActiveUpdates--;
            if (self.ActiveUpdates === 0) {
              self.record.applyNewValues(response.record);
              //self.UpdateRecordRows(the_record);
              self.updateScreen();
              global.setShiftEntryRefresh();
            }
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    this.updateRecordField2(rowindex, [fieldname], [value]);
  };

  handleMultiInputChange = ({rowindex, value}: InputChangeEventProps) => {
    let self = this;
    let data = {
      list: [],
      id: this.id,
    };
    let tvalue = value;
    let tmp = tvalue.split(':');
    let addf = tmp[0] === '1';
    value = tmp[1];
    for (let i = 1; i < 32; i++) {
      let newval = '';
      let testf = false;
      let rval = this.record.matrixRows[rowindex]['Day' + i];
      if (addf) {
        let reddayf = DayIsRedDay('Day' + i, this.record.headerFields.StartDate);
        if (reddayf === false) {
          if (rval === '') {
            newval = value;
            testf = true;
          }
        }
      } else {
        if (rval === value) {
          newval = '';
          testf = true;
        }
      }
      if (testf) {
        data.list.push({
          field: 'Day' + i,
          rownr: rowindex,
          value: newval,
        });
      }
    }
    Requests.updateRecordData(this.table, data)
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            //self.UpdateRecordRows(the_record);
            self.updateScreen();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  GetContractList = () => {
    let res = [];
    for (let i = 0; i < this.record.matrixRows.length; i++) {
      res.push(this.record.matrixRows[i].ContractNr);
    }
    return res.join(',');
  };

  updateOffWork = () => {
    let self = this;
    Requests.doAction('getoffwork', {
      id: 'empty&sd=' + this.record.StartDate + '&ed=' + this.record.EndDate + '&shiftnr=' + this.id,
    })
      .then(() => {
        self.rowClick(this.id);
      })
      .catch((error) => {
        logError(error);
      });
  };

  openTimeTable = () => {
    let self = this;
    let action = 'timetablefromshiftsheet';
    if (APISupportsItem('gettimetablelist')) {
      action = 'gettimetablelist';
    }
    Requests.doAction(action, {id: this.id})
      .then((response) => {
        if (response.newid !== '') {
          if (response.newid.indexOf(',') > -1) {
            self.setState({timetableid: response.newid});
          } else {
            DoNavigate(self, 'TimeTables', true, response.newid);
          }
        } else {
          alert(translate('NoTimeTable'));
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  switchShiftMode = () => {
    let newstate = this.state.ShiftMode ? false : true;
    this.setState({ShiftMode: newstate});
  };
  switchDetailedMode = () => {
    let newstate = this.state.detailedf ? false : true;
    this.setState({detailedf: newstate});
    if (newstate === false) {
      global.setShiftEntry({show: false});
    } else {
      if (global.isDrawerOpen) {
        global.setIsDrawerOpen();
      }
    }
  };
  ShowHideNewTable = () => {
    if (this.state.newTableHelper) {
      this.setState({newTableHelper: false});
    } else {
      this.setState({newTableHelper: true});
    }
  };
  deleteRecord = () => {
    let self = this;
    this.setState({activeDelete: false});
    Requests.deleteRecordOld(this.table, this.id)
      .then((response) => {
        if (response.success === true) {
          self.id = '';
          DoNavigate(self, 'ShiftTables', false, '', true, true);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  IntervalExists = (colval) => {
    let res = false;
    if (objectHasProperty(global.pasteSpecialsVector[14], colval)) {
      res = true;
    }

    return res;
  };

  UpdateRecordRows = (record) => {
    for (let i in record.rows) {
      matrixFields(this, 1).map((col) => {
        if (col.dataKey.substring(0, 3) === 'Day' && col.dataKey.slice(-5) !== 'Hours') {
          let colval = record.rows[i][col.dataKey];
          if (
            colval !== undefined &&
            colval !== '' &&
            colval.indexOf(',') === -1 &&
            this.IntervalExists(colval) === false
          ) {
            //record.rows[i][col.dataKey + 'Hours'] = colval;
          }
        }
        if (col.dataKey === 'LineFlag') {
          record.rows[i][col.dataKey] = '';
        }
      });
    }
  };

  showDayColor = ({value}: OverwriteMatrixProps) => {
    let style = {};
    if (global.isDrawerOpen) {
      style = {fontSize: '12px'};
    }
    let color = '';
    let nval = value;
    if (value !== '') {
      if (value.indexOf(':') > -1) {
        nval = '!M';
      } else {
        if (this.IntervalExists(value)) {
          color = GetIntervalColor(value);
        } else {
          color = GetOffWorkColor(value);
        }
      }
    }
    if (color !== '') {
      style.backgroundColor = color;
      style.height = '32px';
      style.lineHeight = '32px';
      style.width = '32px';
    }
    return (
      <View style={style}>
        <NormalText>{nval}</NormalText>
      </View>
    );
  };
  RecordIsEditable = () => {
    let res = false;
    if (isApproved(this.record, 'OKFlag') === false) {
      res = true;
    }
    return res;
  };

  showDetailedDay = (value, fakerowindex, datakey, rowindex) => {
    let self = this;
    let style = {fontSize: '12px'};
    if (global.isDrawerOpen) {
      style = {fontSize: '9px'};
    }
    if (fakerowindex < 3) {
      let key = rowindex + ':' + datakey;
      if (value === '') {
        style.height = '18px';
        style.width = '20px';
      }
      return (
        <ClickableCell
          style={style}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            self.state.py[key] = layout.top;
            if (window.scrollY) {
              self.state.py[key] = layout.top + window.scrollY;
            }
            self.state.px[key] = layout.left;
          }}
          onPress={() => {
            if (IsManager() && self.RecordIsEditable()) {
              global.setShiftEntry({
                show: true,
                key: key,
                posx: self.state.px[key],
                posy: self.state.py[key],
                ShowContent: () => {
                  return self.ShowShiftEntry(key);
                },
                style: {},
              });
            }
          }}>
          <NormalText>{value}</NormalText>
        </ClickableCell>
      );
    } else {
      return (
        <View style={style}>
          <NormalText>{value}</NormalText>
        </View>
      );
    }
  };

  SlotOverride = (slot, st, et) => {
    let arr = slot.split2('-');
    if (st > arr[0] && st < arr[1]) {
      return true;
    }
    if (et > arr[0] && et < arr[1]) {
      return true;
    }
    return false;
  };

  SlotsOverride = (val, st, et) => {
    let slots = [];
    let res = false;
    if (val.length > 0) {
      slots = val.split(',');
    }
    slots.map((slot) => {
      if (this.SlotOverride(slot, st, et)) {
        res = true;
      }
    });

    return res;
  };

  TestTimeSlots = () => {
    let res = '';

    if (this.state.StartTime > this.state.EndTime) {
      if (this.state.StartTime < '12:00:00') {
        //res = translate('StartTimeLarger');
      }
    }
    let reg = /^([0-1][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
    if (reg.test(this.state.StartTime) === false) {
      res = translate('FormatStartDate');
    }
    if (reg.test(this.state.EndTime) === false) {
      res = translate('FormatEndDate');
    }
    if (ShowBreakHours() && reg.test(this.state.Break) === false) {
      res = translate('FormatBreak');
    }

    return res;
  };

  addTimeSlot = (id) => {
    let err = this.TestTimeSlots();
    if (err !== '') {
      global.messageBox({
        showMessage: true,
        statusMessage: err,
      });
    } else {
      let fields = ['Break' + id[1]];
      if (this.state.UpdateTime) {
        fields.push(id[1]);
      }
      let values = [this.state.Break];
      if (this.state.UpdateTime) {
        values.push(this.state.StartTime + '-' + this.state.EndTime);
      }
      this.updateRecordField2(parseInt(id[0]), fields, values);
      this.state.StartTime = '00:00';
      this.state.EndTime = '00:00';
      this.state.Break = '00:00';
      this.state.UpdateTime = false;
      global.setShiftEntry({show: false});
    }
  };

  removeTimeSlot = (id) => {
    this.updateRecordField2(parseInt(id[0]), ['Break' + id[1], id[1]], ['', '']);
    this.state.StartTime = '00:00';
    this.state.EndTime = '00:00';
    this.state.Break = '00:00';
    this.state.UpdateTime = false;
    global.setShiftEntry({show: false});
  };

  SingleShiftEntryLine = (id) => {
    let res = [];
    let lines = [];
    let self = this;
    lines.push(
      <>
        <EditField
          title={translate('StartTime')}
          value={this.state.StartTime}
          singleline={true}
          style={{paddingLeft: 0}}
          width={'100px'}
          onChange={({value}: InputChangeEventProps) => self.setState({StartTime: value, UpdateTime: true})}
        />
        <EditField
          title={translate('EndTime')}
          value={this.state.EndTime}
          singleline={true}
          width={'100px'}
          onChange={({value}: InputChangeEventProps) => self.setState({EndTime: value, UpdateTime: true})}
        />
        {ShowBreakHours() && (
          <EditField
            title={translate('Break')}
            value={this.state.Break}
            width={'100px'}
            singleline={true}
            onChange={({value}: InputChangeEventProps) => self.setState({Break: value})}
          />
        )}
        <TimeSlotActions>
          <ActionButton
            title={translate('Delete')}
            onPress={() => {
              self.removeTimeSlot(id);
            }}
          />
          <ActionButton
            title={translate('Save')}
            onPress={() => {
              self.addTimeSlot(id);
            }}
          />
        </TimeSlotActions>
      </>
    );

    res.push(<TimeSlotRow>{lines}</TimeSlotRow>);
    return res;
  };
  ShowShiftEntry = (key) => {
    let res = [];
    let id = key.split2(':');
    let tmp = myDate.extractDateFromString(this.record.headerFields.StartDate);
    let num = parseInt(id[1].substring(3));
    let td = myDate.constructDateString2(myDate.AddDays(tmp, num - 1));
    res.push(
      <ManualEntryTitle>
        <View>
          <Text>{this.record.matrixRows[parseInt(id[0])]['EmpName']}: </Text>
        </View>
        <View>
          <Text>{td}</Text>
        </View>
      </ManualEntryTitle>
    );
    let day = this.record.matrixRows[parseInt(id[0])][id[1]];
    let brstr = this.record.matrixRows[parseInt(id[0])]['Break' + id[1]];
    let st, et, br;
    if (day !== '') {
      if (objectHasProperty(global.pasteSpecialsVector[14], day)) {
        let interval = global.pasteSpecialsVector[14][day];
        st = this.TrimTime(interval.FromTime);
        et = this.TrimTime(interval.ToTime);
        br = this.TrimTime(this.calculateDuration(interval.BreakFrom, interval.BreakTo));
      } else {
        let times = day.split('-');
        st = this.TrimTime(times[0]);
        et = this.TrimTime(times[1]);
      }
    } else {
      if (this.state.StartTime) {
        st = this.state.StartTime;
        et = this.state.EndTime;
      } else {
        st = '00:00';
        et = '00:00';
      }
    }
    if (brstr !== '' && brstr !== undefined) {
      br = this.TrimTime(brstr);
    }
    if (br === '') {
      br = '00:00';
    }
    this.state.StartTime = st;
    this.state.EndTime = et;
    this.state.Break = br;
    res.push(this.SingleShiftEntryLine(id));

    /*
                            let day = this.record.matrixRows[parseInt(id[0])][id[2]];
                            day.split(',').map((slot, index) => {
                              if (slot !== '') {
                                let times = slot.split('-');
                                let st = times[0];
                                let et = times[1];
                                res.push(this.SingleShiftEntryLine(st, et, id, index));
                              }
                            });
                        */
    return res;
  };

  AddShiftValues = (shifts, row) => {
    Object.entries(row).map(([key, value]) => {
      if (key.substring(0, 3) === 'Day' && key.length < 6) {
        let tval = value.toString();
        tval.split2(',').map((interval) => {
          if (interval.indexOf('-') > -1) {
            interval = '!M';
          }
          if (interval !== '') {
            if (objectHasProperty(shifts, interval) === false) {
              shifts[interval] = 0;
            }
            shifts[interval]++;
          }
        });
      }
    });
  };

  TrimTime = (time) => {
    let res;
    res = time;
    if (res) {
      while ((res.substring(res.length - 1) === '0' || res.substring(res.length - 1) === ':') && res.length > 5) {
        res = res.substring(0, res.length - 1);
      }
    }
    return res;
  };

  PadZero = (time) => {
    let res;

    res = time.toString();
    if (res.length === 1) {
      res = '0' + res;
    }

    return res;
  };

  calculateDuration = (startTime, endTime) => {
    let startTimeSeconds = myDate.getTimeSeconds(startTime);
    let endTimeSeconds = myDate.getTimeSeconds(endTime);

    let totalSeconds = endTimeSeconds - startTimeSeconds;
    let seconds = totalSeconds % 60;
    totalSeconds = totalSeconds - seconds;
    let min = totalSeconds % 3600;
    min = min / 60;
    const hours = parseInt(totalSeconds / 3600);
    return this.PadZero(hours) + ':' + this.PadZero(min) + ':' + this.PadZero(seconds);
  };

  getIntervalData = (value) => {
    let st, et, br;
    let interval = global.pasteSpecialsVector[14][value];
    if (interval) {
      st = this.TrimTime(interval.FromTime);
      et = this.TrimTime(interval.ToTime);
      br = this.TrimTime(this.calculateDuration(interval.BreakFrom, interval.BreakTo));
    }
    return {st: st, et: et, br: br};
  };

  AfterMatrixRow = (curRow, num, len, props) => {
    if (this.state.detailedf) {
      let res = [];
      let self = this;
      props.columns = JSON.parse(JSON.stringify(props.columns));
      props.columns.map((col) => {
        if (col.dataKey.substring('Day') && col.dataKey.length < 6) {
          col.overwrite = ({value, rowindex, datakey}: OverwriteMatrixProps) =>
            self.showDetailedDay(value, rowindex, datakey, num);
        }
        col.showProfile = undefined;
        if (col.dataKey === 'EmpName') {
          col.style = {textAlign: 'right'};
        }
      });
      //add extra rows
      props.data = [{}, {}, {}, {}];
      props.rowactions = undefined;

      Object.keys(curRow).map(function (key) {
        if (key.substring(0, 3) === 'Day' && key.substring(key.length - 5) !== 'Hours' && curRow[key] !== '') {
          let tval = curRow[key];
          let st,
            et,
            br = '';
          if (tval.indexOf(':') > 0) {
            let tt = tval.split2('-');
            st = tt[0];
            et = tt[1];
          } else {
            let obj = self.getIntervalData(curRow[key]);
            st = obj.st;
            et = obj.et;
            br = obj.br;
          }
          if (curRow['Break' + key] !== '' && curRow['Break' + key] !== undefined) {
            br = curRow['Break' + key];
          }
          props.data[0][key] = self.TrimTime(st);
          props.data[1][key] = self.TrimTime(et);
          props.data[2][key] = self.TrimTime(br);
          props.data[3][key] = ConvertDisplayValue(WindowElementType.kInputTypeVal, curRow[key + 'Hours']);
        } else {
          if (key === 'EmpName') {
            props.data[0][key] = translate('From');
            props.data[1][key] = translate('To');
            props.data[2][key] = translate('Break');
            props.data[3][key] = translate('Hours');
          } else {
            props.data[0][key] = '';
            props.data[1][key] = '';
            props.data[2][key] = '';
            props.data[3][key] = '';
          }
        }
      });

      res.push(
        <StyledRow key={num + '_1'} rowIndex={0} arrayLength={props.data.length} {...props} />,
        <StyledRow key={num + '_2'} rowIndex={1} arrayLength={props.data.length} {...props} />
      );
      if (ShowBreakHours()) {
        res.push(<StyledRow key={num + '_3'} rowIndex={2} arrayLength={props.data.length} {...props} />);
      }
      res.push(<StyledRow key={num + '_5'} rowIndex={3} arrayLength={props.data.length} {...props} />);

      return res;
    }
  };

  SplitMatrixRow = (curRow, prevRow, num, rowsbeforef) => {
    let res = [];

    if (global.shiftBlock.ordertype === '1') {
      if (prevRow !== undefined && rowsbeforef) {
        if (curRow.PostCodeRow !== prevRow.PostCodeRow) {
          res.push(
            <PostRow key={'total' + curRow.ContractNr}>
              <PostText>{translate('TotalHours') + this.CurPostVal}</PostText>
              {Object.entries(this.CurPostShifts).map(([key, value]) => {
                return (
                  <ShiftValue>
                    {key}: {value}
                  </ShiftValue>
                );
              })}
            </PostRow>
          );
          this.CurPostVal = 0;
          this.CurPostShifts = {};
        }
      }
      if (prevRow === undefined || curRow.PostCodeRow !== prevRow.PostCodeRow) {
        if (prevRow !== undefined) {
          res.push(<MatrixDivider key={'divider' + curRow.ContractNr} />);
        }
        res.push(
          <PostRow key={'post' + curRow.ContractNr}>
            <PostText>
              {/*global.pasteSpecialsVector[11][curRow.PostCodeRow].postname*/}
              <PasteSpecialName
                ps={11}
                psheader={[{key: 'postcode', mainkey: 'PostCode'}, {key: 'postname'}]}
                value={curRow.PostCodeRow}
                style={{position: 'static', fontSize: 14}}></PasteSpecialName>
            </PostText>
          </PostRow>
        );
      }
    }
    if (isNaN(parseFloat(curRow.TotalHoursRow)) === false) {
      this.CurPostVal += parseFloat(curRow.TotalHoursRow);
    }
    this.AddShiftValues(this.CurPostShifts, this.record.matrixRows[num]);
    return res;
  };

  SplitMatrixRowEnd = () => {
    let res = [];
    res.push(
      <PostRow key={'total'}>
        <PostText>{translate('TotalHours') + this.CurPostVal}</PostText>
        {Object.entries(this.CurPostShifts).map(([key, value]) => {
          return (
            <ShiftValue>
              {key}: {value}
            </ShiftValue>
          );
        })}
      </PostRow>
    );
    this.CurPostVal = 0;
    this.CurPostShifts = {};

    return res;
  };

  shouldShowMatrixRow = (row) => {
    let res = true;
    if (this.state.Filter !== '') {
      if (row.PostCodeRow !== this.state.Filter) {
        res = false;
      }
    }

    return res;
  };

  showRowTotal = ({value}: OverwriteMatrixProps) => {
    let style = {};
    if (parseFloat(value) > parseFloat(this.record.headerFields.NormalHours)) {
      style = {
        backgroundColor: GetWebColor(global.shiftBlock.colornormhours),
        height: '32px',
        lineHeight: '32px',
      };
    }

    return (
      <View style={style}>
        <NormalText>{value}</NormalText>
      </View>
    );
  };
  createTimeTable = () => {
    let self = this;
    Requests.doAction('timetablefromshiftsheet', {id: this.id})
      .then((response) => {
        if (response.newid === '') {
          let tdata = {
            record: {
              ShiftSerNr: self.id,
              APIFlag: 1,
            },
          };
          Requests.getNewRecord(5, tdata)
            .then((response) => {
              DoNavigate(self, 'TimeTables', true, response.record.SerNr);
            })
            .catch((error) => {
              logError(error);
            });
        } else {
          alert(translate('TimeTableAlreadyCreated') + response.newid);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  createTimeTable2 = (rownr) => {
    if (APISupportsItem('timetablesfromshiftrows')) {
      let self = this;
      let id = this.id;
      if (rownr !== undefined) {
        id += ':' + rownr;
      }
      Requests.doAction('timetablefromshiftsheet', {id: id})
        .then((response) => {
          if (response.newid !== '') {
            DoNavigate(self, 'TimeTables', true, response.newid);
          } else {
            alert(translate('TimeTableAlreadyCreated'));
          }
        })
        .catch((error) => {
          logError(error);
        });
    } else {
      this.createTimeTable();
    }
  };

  renderNewRowButton = () => {
    let self = this;
    return (
      <View>
        <FilterFields>
          <FilterField>
            <EditField
              title={translate('Contract')}
              name={'Contract'}
              value={this.state.NewRowContract}
              ps={1}
              style={{width: 215}}
              psfilter={{depcode: this.record.headerFields.DepCode}}
              psheader={[
                {
                  name: translate('Code'),
                  width: '35%',
                  key: 'contractnr',
                  mainkey: 'ContractNr',
                },
                {
                  width: '65%',
                  name: translate('Name'),
                  key: 'empname',
                },
              ]}
              onChange={({value}: InputChangeEventProps) => self.setState({NewRowContract: value})}
            />
          </FilterField>
        </FilterFields>
        <FilterActions>
          <ActionButton
            style={{marginTop: '20px', marginRight: '20px'}}
            onPress={() => this.setState({newMatrixTableHelper: false})}
            title={translate('Cancel')}
          />
          <ActionButton style={{marginTop: '20px'}} onPress={() => this.createNewTableRow()} title={translate('Add')} />
        </FilterActions>
      </View>
    );
  };
  IsWideScreen = () => {
    let res = false;
    if (global.isDrawerOpen) {
      res = true;
    }
    return res;
  };

  render() {
    this.CurPostVal = 0;
    this.CurPostShifts = {};
    return (
      <LangColorContext.Consumer>
        {(data) => {
          let self = this;
          let keys = {
            key: 'shifttable' + this.id,
            title: translate('ShiftTable'),
            window: this,
            headerFields: headerFields(this),
            matrixFooterFields: matrixFooterFields(this),
            record: this.record,
            rowactions: ShiftSheetRowActions(this),
            onClick: this.handleInputClick,
            okcheck: 'OKFlag',
            allowDelete: IsManager(),
            showrow: this.shouldShowMatrixRow,
            splitrow: (row1, row2, num, rowsbeforef) => self.SplitMatrixRow(row1, row2, num, rowsbeforef),
            splitrowend: () => self.SplitMatrixRowEnd(),
            afterrow: (row, num, len, props) => self.AfterMatrixRow(row, num, len, props),
            allowPrint: false,
            hasApprovals: this.hasapprovals === 1,
            backButton: () => goBack(self, self.ScreenName),
            matrixFields: matrixFields(this, 0, this.IsWideScreen(data) ? '30px' : '23px'),
            width: this.IsWideScreen(data) ? '1270px' : '1050px',
            minwidth: this.IsWideScreen(data) ? '1100px' : '1050px',
            maxwidth: this.IsWideScreen(data) ? '1450px' : '1400px',
          };

          if (this.state.ShiftMode) {
            keys['shiftmode'] = matrixFields(this, 1, this.IsWideScreen(data) ? '30px' : '23px');
          }
          if (isDesktopScreen()) {
            return (
              <WindowWrap>
                <InspectWindow {...keys} />
                {this.state.newMatrixTableHelper && (
                  <Modal transparent={true}>
                    <ModalWindow_Old>{this.renderNewRowButton()}</ModalWindow_Old>
                  </Modal>
                )}
                {this.state.activeDelete && (
                  <Modal transparent={true}>
                    <YesNoWindow
                      question={translate('RemoveQuestion')}
                      yes={() => this.deleteRecord()}
                      no={() => this.setState({activeDelete: false})}
                    />
                  </Modal>
                )}
                {this.state.timetableid !== '' && (
                  <Modal transparent={true}>
                    <TimeTableListWrap>
                      <TimeTableTextWrap>
                        <TimeTableText>{translate('MultipleTimeTables')}</TimeTableText>
                      </TimeTableTextWrap>
                      <TimeTableListNav>
                        {this.state.timetableid.split(',').map((id) => {
                          return (
                            <ActionButton
                              onPress={() => {
                                self.setState({timetableid: ''});
                                DoNavigate(self, 'TimeTables', true, id);
                              }}
                              title={id}
                            />
                          );
                        })}
                        <ActionButton onPress={() => self.setState({timetableid: ''})} title={translate('Cancel')} />
                      </TimeTableListNav>
                    </TimeTableListWrap>
                  </Modal>
                )}
              </WindowWrap>
            );
          }
        }}
      </LangColorContext.Consumer>
    );
  }
}

function ShiftTables() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="ShiftTablesList"
        component={ShiftTablesList}
        options={{
          title: translate('ShiftTables'),
        }}
      />
      <Drawer.Screen
        name="ShiftTablesRecord"
        component={ShiftTablesRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('ShiftTable'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default ShiftTables;
