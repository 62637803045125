import React, {useEffect} from 'react';
import * as myDate from '@tools/dateTools';
import {ColumnContainer, RowContainer, TextSmall} from '@src/styles/primitiveComponents';
import {PortalIcon} from '../portalIcon/PortalIcon';
import {IconWrapper} from '../fileUploader/FileUploader.styles';
import {colours} from '@src/styles/Style';
import {ConvertDateField, GetGlobalTheme, isMobileOrTabletScreen} from '@src/tools/displayTools';
import {OutsideClickWrapper} from '../editElements/OutsideClickWrapper';
import {DatePickerComponent} from './lib/DatePicker';
import styled from 'styled-components/native';
import {useState} from 'react';
import dayjs from 'dayjs';

const DateRangeContainer = styled(RowContainer)`
  display: inline-flex;
  flex-shrink: 1;
  height: 35px;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  background: ${colours[GetGlobalTheme()].white};
  border-radius: 8px;
  border: 1px ${colours[GetGlobalTheme()].grey400} solid;
  ${isMobileOrTabletScreen() ? '' : `width: 369px;`}
`;

const DateRangeTextContainer = styled.TouchableOpacity`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  justify-content: flex-start;
  height: 18px;
  white-space: nowrap;
`;

const DateDivider = styled(RowContainer)`
  width: 20px;
  height: 0;
  align-self: center;
  transform: rotate(90deg);
  border: 1px ${colours[GetGlobalTheme()].borderDefault} solid;
`;

const DateRangeText = styled(TextSmall)`
  color: ${colours[GetGlobalTheme()].label};
  white-space: nowrap;
`;

const DateRangePickerContainer = styled(ColumnContainer)``;

type DateRangePickertProps = {
  action: (startDate: string, endDate: string) => void;
  startDate: string;
  endDate: string;
};

export const DateRangePicker: React.FC<DateRangePickertProps> = ({action, startDate, endDate}) => {
  const [range, setRange] = useState<{startDate: Date; endDate: Date}>({
    startDate: startDate !== '' ? dayjs(startDate).toDate() : null,
    endDate: endDate !== '' ? dayjs(endDate).toDate() : null,
  });
  const [visibleState, setVisibleState] = useState<boolean>(false);

  useEffect(() => {
    callAction();
  }, [range]);

  function forceCloseDatePicker(event) {
    if (event) event.stopPropagation();
    setVisibleState(false);
  }
  function getReturnDateText() {
    const startDate = myDate.constructDateString2(range.startDate);
    const endDate = myDate.constructDateString2(range.endDate);
    return {startDate, endDate};
  }

  function callAction() {
    if (action) {
      const {startDate, endDate} = getReturnDateText();
      action(startDate, endDate);
    }
  }

  function clearDates() {
    setRange({startDate: null, endDate: null});
  }

  function handleCalendarOnChange(startDate, endDate) {
    if (endDate) {
      setRange({startDate: range.startDate, endDate: endDate.$d});
    } else {
      setRange({startDate: startDate.$d, endDate: null});
    }
  }
  function getDateText(date) {
    if (date) {
      return ConvertDateField(myDate.constructDateString2(date));
    } else {
      return global.userData.dateformat;
    }
  }

  return (
    <DateRangePickerContainer>
      <DateRangeContainer>
        <DateRangeTextContainer onPress={() => setVisibleState(!visibleState)}>
          <RowContainer style={{justifyContent: 'center'}}>
            <IconWrapper style={{width: 10, alignSelf: 'center', marginRight: 5}}>
              <PortalIcon iconName={'CalendarIcon'} iconHeight={12} iconColor={colours[GetGlobalTheme()].icon} />
            </IconWrapper>
            <DateRangeText>{getDateText(range.startDate)}</DateRangeText>
          </RowContainer>
        </DateRangeTextContainer>
        <DateDivider />
        <DateRangeTextContainer onPress={() => setVisibleState(!visibleState)}>
          <DateRangeText>{getDateText(range.endDate)}</DateRangeText>
        </DateRangeTextContainer>
      </DateRangeContainer>
      {visibleState && (
        <OutsideClickWrapper onOutsideClick={(event) => forceCloseDatePicker(event)}>
          <DatePickerComponent
            mode="range"
            date={range}
            height={230}
            width={'100%'}
            firstDayOfWeek={1}
            displayFullDays
            onChange={({startDate, endDate}) => {
              handleCalendarOnChange(startDate, endDate);
            }}
            closeCalendar={() => setVisibleState(false)}
            clearDates={clearDates}
            marginTop={5}
          />
        </OutsideClickWrapper>
      )}
    </DateRangePickerContainer>
  );
};
