import {IsHRWeb, storeData, UsePagination} from '@tools/displayTools';
import * as myDate from '@tools/dateTools';
import {objectHasProperty} from '@tools/objectTools';
import {ActiveEditFields, ViewMode} from '@src/components/screens/types';
import {isApproved} from '@src/utils/helpers';

export function SetWindowSearch(search) {
  global.windows.list[global.windows.curwindow].cursearch = search.toLowerCase();
  global.windows.list[global.windows.curwindow].UpdateWindowData();
}

export function SetWindowStartDate(date) {
  global.windows.list[global.windows.curwindow].curstartdate = date;
  global.windows.list[global.windows.curwindow].UpdateWindowData();
}

export function SetWindowEndDate(date) {
  global.windows.list[global.windows.curwindow].curenddate = date;
  global.windows.list[global.windows.curwindow].UpdateWindowData();
}

export function UpdateWindowData2() {
  global.windows.list[global.windows.curwindow].UpdateWindowData();
}

export function GetWindowSearch() {
  return global.windows.list[global.windows.curwindow].cursearch;
}

export function GetFilterStartDate() {
  let res = global.windows.list[global.windows.curwindow].curstartdate;
  return res;
}

export function GetFilterEndDate() {
  let res = global.windows.list[global.windows.curwindow].curenddate;
  return res;
}

export function WindowHasDateFilter() {
  return global.windows.list[global.windows.curwindow].datefilter;
}

export function AddWindowFilter(window, field, value) {
  global.windows.list[window].customsearch[field] = value;
}

export function AddDateFilter(window: string, td?: Date, enddate?: Date) {
  if (!td) {
    td = myDate.addYear(myDate.getCurrentDate(), -1);
  }
  if (!enddate) {
    enddate = null;
  }
  global.windows.list[window].curstartdate = myDate.constructDateString2(td);
  global.windows.list[window].curenddate = myDate.constructDateString2(enddate);
  global.windows.list[window].datefilter = true;
}

export function GetWindowFilter(window, field) {
  return global.windows.list[window].customsearch[field];
}

export function UpdateWindowData(cursort, curdir) {
  let curwin = global.windows.curwindow;
  global.windows.list[curwin].cursort = cursort;
  global.windows.list[curwin].curdir = curdir;
  global.windows.list[curwin].UpdateWindowData();
  UpdateWindowDefaults(curwin, cursort, curdir);
}

export function GetBrowseWindowDetails(window) {
  return global.windows.list[window];
}

export function SetCurrentWindow(curwin, defsort, defdir) {
  global.windows.curwindow = curwin;
  if (global.windows.list[curwin]) {
    return;
  }
  global.windows.list[curwin] = {};
  global.windows.list[curwin].cursort = defsort;
  global.windows.list[curwin].curdir = defdir;
  global.windows.list[curwin].cursearch = '';
  global.windows.list[curwin].customsearch = {};
  global.windows.list[curwin].filters = [];
}

export function SetCurrentWindow2(curwin, tdefsort, defdir) {
  let defsort = tdefsort;
  if (UsePagination() === false) {
    defsort = defsort.toLowerCase();
  }
  global.windows.curwindow = curwin;
  if (global.windows.list[curwin]) {
    return;
  }
  global.windows.list[curwin] = {};
  global.windows.list[curwin].cursort = defsort;
  global.windows.list[curwin].curdir = defdir;
  global.windows.list[curwin].cursearch = '';
  global.windows.list[curwin].customsearch = {};
  global.windows.list[curwin].filters = [];
}

export function SetWindowDefaults() {
  let list = global.windowStates;
  for (let i in list) {
    global.windows.list[i] = {};
    global.windows.list[i].cursort = list[i].cursort;
    global.windows.list[i].curdir = list[i].curdir;
    global.windows.list[i].cursearch = '';
    global.windows.list[i].customsearch = {};
    global.windows.list[i].filters = [];
  }
}

export function UpdateWindowDefaults(curwin, cursort, curdir) {
  if (objectHasProperty(global.windowStates, curwin) === false) {
    global.windowStates[curwin] = {};
  }

  global.windowStates[curwin].cursort = cursort;
  global.windowStates[curwin].curdir = curdir;

  let data = JSON.stringify(global.windowStates);
  if (IsHRWeb()) {
    document.cookie = 'windowstates=' + data;
  } else {
    storeData('@windowstates', data);
  }
}

export function fieldIsEditable(
  viewOnly: boolean,
  dataKey: string,
  record,
  okcheck,
  viewMode,
  activeEditFields: ActiveEditFields,
  isMatrix = false
) {
  const approved: boolean = isApproved(record, okcheck, dataKey);
  let isEditable: boolean = viewOnly !== true && approved === false && viewMode == ViewMode.Edit;
  if (isEditable && activeEditFields) {
    if (isMatrix) {
      isEditable = activeEditFields.matrixFields;
      if (activeEditFields.exceptionMatrixList.indexOf(dataKey) > -1) {
        isEditable = !isEditable;
      }
    } else {
      isEditable = activeEditFields.headerFields;
      if (activeEditFields.exceptionHeaderList.indexOf(dataKey) > -1) {
        isEditable = !isEditable;
      }
    }
  }
  return isEditable;
}

export function matrixIsEditable(activeEditFields: ActiveEditFields) {
  let isEditable: boolean = true;
  if (isEditable && activeEditFields) {
    isEditable = activeEditFields.matrixFields;
  }
  return isEditable;
}
