import {ViewMode} from '@components/screens/types';
import {
  FieldSection,
  PasteSpecialDisplayType,
  PasteSpecialWindowLayout,
  WindowDefinition2,
  WindowMatrix,
} from '@utils/types';
import {translate} from '@utils/languageTools';
import {DoNavigate, GetGlobalTheme, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {colours, fontWeight, spacing} from '@styles/Style';
import {ConfirmButton} from '@components/button/confirmButton';
import React from 'react';
import {getCellStyle, getMatrixTextAlign} from '@components/editElements/inspectWindow/InspectWindow';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {UnConfirmButton} from '@components/button/UnconfirmButton';
import {APISupportsItem} from '@utils/drawersTools';

export class InvoicesRecordScreenDefinition extends RecordScreenWindowDefinition {
  getSideSectionFields(table: any): FieldSection[] {
    let sections: FieldSection[];
    sections = [
      {
        style: {padding: '0px'},
        groups: [
          {
            fields: [
              {
                label: translate('SalesMan'),
                windowElementType: WindowElementType.kInputTypeText,
                dataKey: 'SalesMan',
                viewOnly: false,
                onChange: table.updateRecordField,
                pasteSpecial: {
                  vcName: 'UserVc',
                  windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                  mainKey: 'code',
                  toolTip: {hasToolTip: true, key: 'name', maxCharacters: 60},
                  columns: [
                    {
                      name: translate('Code'),
                      width: '35%',
                      key: 'code',
                      mainKey: 'Code',
                      displayType: PasteSpecialDisplayType.Name,
                    },
                    {
                      width: '65%',
                      name: translate('Name'),
                      key: 'name',
                      displayType: PasteSpecialDisplayType.Description,
                    },
                  ],
                },
              },
              {
                label: translate('EInvoiceReceiver'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'EInvReceiverFlag',
                viewOnly: true,
                onChange: table.updateRecordField,
              },
              {
                label: translate('UpdateStock'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'UpdStockFlag',
                viewOnly: false,
                onChange: table.updateRecordField,
              },
            ],
          },
        ],
      },
    ];
    return sections;
  }

  getHeaderFields(table: any, fullf = false): WindowDefinition2 {
    const self = this;
    let windowDefinition: WindowDefinition2;
    if (fullf == false && table.isNewRecord() && table.recordFieldFilled('CustCode') === false) {
      windowDefinition = {
        sectionTiles: [
          {
            fieldSections: [
              {
                label: translate('CustomerDetails'),
                groups: [
                  {
                    fields: [
                      {
                        label: translate('ChooseCustomer'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'CustCode',
                        viewOnly: false,
                        pasteSpecial: {
                          vcName: 'CUVc',
                          newRecord: true,
                          windowLayout: PasteSpecialWindowLayout.Default,
                          useAlternateDisplay: true,
                          viewModeDisplayFormat: '{name} ({code})',
                          mainKey: 'code',
                          nameDisplayFormat: '{name} ({code})',
                          columns: [
                            {
                              name: translate('CustomerName') + ' ' + translate('Code'),
                              width: '70%',
                              key: 'name',
                              mainKey: 'Code',
                              displayFormat: '{name} ({code})',
                            },
                            {
                              width: '30%',
                              name: translate('RegNr'),
                              key: 'regnr1',
                            },
                          ],
                        },
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('InvoiceInformation'),
                groups: [
                  {
                    style: {
                      background: colours[GetGlobalTheme()].grey100,
                      height: 90,
                    },
                    fields: [],
                  },
                ],
              },
              {
                label: translate('Items'),
                groups: [
                  {
                    style: {
                      background: colours[GetGlobalTheme()].grey100,
                      height: 90,
                    },
                    fields: [],
                  },
                ],
              },
            ],
          },
        ],
      };
    } else {
      windowDefinition = {
        operationMenu: [
          {
            label: translate('SendEmail'),
            onPress: table.sendEmailFromRecord,
          },
          {
            label: translate('CreateSigningActivity'),
            onPress: table.CreateSigningActivity,
          },
        ],
        startViewComponent: () =>
          table.record && table.allowsEditAndDelete() ? <ConfirmButton table={table} /> : null,
        endViewComponent: (inspectWindow) =>
          table.record && !table.allowsEditAndDelete()
            ? APISupportsItem('unok')
              ? UnConfirmButton(table, inspectWindow)
              : null
            : null,
        editable: table.record ? table.allowsEditAndDelete() : false,
        sectionTiles: [
          {
            fieldSections: [
              {
                label: translate('CustomerDetails'),
                collapse: true,
                groups: [
                  {
                    viewStyle: {
                      flex: '1',
                    },
                    fields: [
                      {
                        label: translate('CustCode'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'CustCode',
                        viewOnly: false,
                        pasteSpecial: {
                          vcName: 'CUVc',
                          newRecord: true,
                          windowLayout: PasteSpecialWindowLayout.Default,
                          useAlternateDisplay: true,
                          recordLinkFunction: () =>
                            DoNavigate(table, 'Contacts', true, table.record.headerFields.CustCode),
                          viewModeDisplayFormat: '{name} ({code})',
                          mainKey: 'code',
                          nameDisplayFormat: '{name} ({code})',
                          columns: [
                            {
                              name: translate('CustomerName') + ' ' + translate('Code'),
                              width: '70%',
                              key: 'name',
                              mainKey: 'Code',
                              displayFormat: '{name} ({code})',
                            },
                            {
                              width: '30%',
                              name: translate('RegNr'),
                              key: 'regnr1',
                            },
                          ],
                          pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
                          details: {
                            titleKey: 'name',
                            fields: [
                              {
                                label: translate('PrimaryContact'),
                                key: 'person',
                                type: WindowElementType.kInputTypeText,
                              },
                              {label: translate('Mobile'), key: 'mobile', type: WindowElementType.kInputTypeMobile},
                              {label: translate('PhoneNo'), key: 'phone', type: WindowElementType.kInputTypeMobile},
                              {label: translate('Email'), key: 'email', type: WindowElementType.kInputTypeEmail},
                            ],
                          },
                        },
                        onChange: table.updateRecordField,
                      },
                      {
                        line: [
                          {
                            label: translate('RegNr'),
                            windowElementType: WindowElementType.kInputTypeText,
                            dataKey: 'RegNr1',
                            viewOnly: false,
                            onChange: table.updateRecordField,
                          },
                          {
                            label: translate('VATNr'),
                            windowElementType: WindowElementType.kInputTypeText,
                            dataKey: 'VATNr',
                            viewOnly: false,
                            onChange: table.updateRecordField,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    divider: true,
                  },
                  {
                    viewStyle: {
                      flex: '1',
                      background: colours[GetGlobalTheme()].contentBG,
                    },
                    fields: [
                      {
                        label: translate('StreetName'),
                        hideLabelWhileView: true,
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'Addr1',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Village'),
                        hideLabelWhileView: true,
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'Addr2',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },

                      {
                        label: translate('City'),
                        hideLabelWhileView: true,
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'Addr3',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('County'),
                        hideLabelWhileView: true,
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'InvAddr3',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                      {
                        line: [
                          {
                            label: translate('PostIndex'),
                            hideLabelWhileView: true,
                            windowElementType: WindowElementType.kInputTypeText,
                            dataKey: 'InvAddr4',
                            viewOnly: false,
                            onChange: table.updateRecordField,
                          },
                          {
                            label: translate('Country'),
                            hideLabelWhileView: true,
                            windowElementType: WindowElementType.kInputTypeText,
                            dataKey: 'InvCountry',
                            viewOnly: false,
                            onChange: table.updateRecordField,
                            pasteSpecial: {
                              vcName: 'CountryVc',
                              windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                              useAlternateDisplay: true,
                              viewModeDisplayFormat: '{Comment}',
                              mainKey: 'Code',
                              columns: [
                                {
                                  name: translate('Code'),
                                  width: '35%',
                                  key: 'Code',
                                  mainKey: 'Code',
                                  displayType: PasteSpecialDisplayType.Name,
                                },
                                {
                                  name: translate('Name'),
                                  width: '65%',
                                  key: 'Comment',
                                  displayType: PasteSpecialDisplayType.Description,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('InvoiceInformation'),
                collapse: true,
                groups: [
                  {
                    fields: [
                      {
                        label: translate('SerNr'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'SerNr',
                        viewOnly: true,
                      },
                      {
                        label: translate('InvDate'),
                        windowElementType: WindowElementType.kInputTypeDate,
                        dataKey: 'InvDate',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('TransDate'),
                        windowElementType: WindowElementType.kInputTypeDate,
                        dataKey: 'TransDate',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('PayDeal'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'PayDeal',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'PDVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'pdComment', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Name'),
                              key: 'pdComment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('PayDate'),
                        windowElementType: WindowElementType.kInputTypeDate,
                        dataKey: 'PayDate',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                  {
                    divider: true,
                  },
                  {
                    fields: [
                      {
                        label: translate('Objects'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'Objects',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'ObjVc',
                          multiValue: true,
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          keyToDisplayInViewMode: ['Code', 'Comment'],
                          toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 40},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('Location'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'Location',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'LocationVc',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'Name', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Name',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('Language'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'LangCode',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                        pasteSpecial: {
                          vcName: 'LangBlock',
                          windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                          mainKey: 'Code',
                          toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                          columns: [
                            {
                              name: translate('Code'),
                              width: '35%',
                              key: 'Code',
                              mainKey: 'Code',
                              displayType: PasteSpecialDisplayType.Name,
                            },
                            {
                              width: '65%',
                              name: translate('Comment'),
                              key: 'Comment',
                              displayType: PasteSpecialDisplayType.Description,
                            },
                          ],
                        },
                      },
                      {
                        label: translate('OrderNr'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'OrderNr',
                        viewOnly: true,
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Comment'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'InvComment',
                        viewOnly: false,
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
              {
                label: translate('Items'),
                collapse: true,
                listStyle: {alignItems: 'end', flexDirection: 'column'},
                groups: [
                  {
                    matrix: self.getMatrixFields(table),
                  },
                  {
                    style: {
                      width: '230px',
                      background: colours[GetGlobalTheme()].contentBG,
                      padding: spacing.space12,
                    },
                    fields: [
                      {
                        label: translate('Subtotal'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum1',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Currency'),
                        windowElementType: WindowElementType.kInputTypeText,
                        dataKey: 'CurncyCode',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('VAT'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum3',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                      {
                        label: translate('Total'),
                        windowElementType: WindowElementType.kInputTypeValFull,
                        dataKey: 'Sum4',
                        viewOnly: true,
                        labelStyle: {textAlign: 'right', width: '100px'},
                        style: {textAlign: 'right'},
                        onChange: table.updateRecordField,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      };
    }

    if (!isMobileOrTabletScreen()) {
      return windowDefinition;
    }

    if (!self.isEmptyNewRecord(fullf, table)) {
      if (windowDefinition.sectionTiles?.[0]?.fieldSections?.[1]?.groups?.[2]?.fields) {
        windowDefinition.sectionTiles[0].fieldSections[1].groups[2].fields.splice(1, 0, {
          label: translate('SalesMan'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SalesMan',
          viewOnly: false,
          pasteSpecial: {
            vcName: 'UserVc',
            newRecord: true,
            hideComment: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordField,
        });
      }
      windowDefinition.sectionTiles[0].fieldSections.splice(2, 0, {
        label: translate('AdditionalInformation'),
        collapse: true,
        listStyle: {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
        groups: [
          {
            style: {
              width: '230px',
              background: colours[GetGlobalTheme()].contentBG,
              padding: spacing.space12,
            },
            fields: [
              {
                label: translate('EInvoiceReceiver'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'EInvReceiverFlag',
                viewOnly: true,
                onChange: table.updateRecordField,
              },
              {
                label: translate('UpdateStock'),
                windowElementType: WindowElementType.kInputTypeCheckbox,
                dataKey: 'UpdStockFlag',
                viewOnly: false,
                onChange: table.updateRecordField,
              },
            ],
          },
        ],
      });

      windowDefinition.sectionTiles[0].fieldSections[0].groups[0].fields[0] = {
        label: translate('CustomerDetails'),
        windowElementType: WindowElementType.kInputTypeText,
        dataKey: 'CustCode',
        viewOnly: false,
        pasteSpecial: {
          vcName: 'CUVc',
          newRecord: false,
          windowLayout: PasteSpecialWindowLayout.Default,
          useAlternateDisplay: true,
          viewModeDisplayFormat: '{name} ({Code})',
          mainKey: 'code',
          nameDisplayFormat: '{Name} ({Code})',
          columns: [
            {
              name: translate('CustomerName') + ' ' + translate('Code'),
              width: '70%',
              key: 'name',
              mainKey: 'Code',
              displayFormat: '{name} ({code})',
            },
            {
              width: '30%',
              name: translate('RegNr'),
              key: 'regnr1',
            },
          ],
          pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
          details: {
            titleKey: 'Name',
            fields: [
              {label: translate('PrimaryContact'), key: 'Person', type: WindowElementType.kInputTypeText},
              {label: translate('Mobile'), key: 'Mobile', type: WindowElementType.kInputTypeMobile},
              {label: translate('PhoneNo'), key: 'Phone', type: WindowElementType.kInputTypeMobile},
              {label: translate('Email'), key: 'eMail', type: WindowElementType.kInputTypeEmail},
            ],
          },
        },
        onChange: table.updateRecordField,
      };
    }
    return windowDefinition;
  }

  getMatrixFields(table: any): WindowMatrix {
    let windowMatrix: WindowMatrix = [];
    windowMatrix.push({
      label: translate('Item'),
      width: isMobileOrTabletScreen() ? '100%' : '440px',
      cellStyle: {
        flexDirection: 'column',
        border: isMobileOrTabletScreen() ? 'solid' : 'none',
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: colours[GetGlobalTheme()].grey400,
        paddingBottom: spacing.space12,
      },
      singleColumn: [
        {
          label: translate('Item'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'ArtCode',
          width: isMobileOrTabletScreen() ? '100%' : '440px',
          viewOnly: false,
          style: (viewMode) =>
            viewMode === ViewMode.View
              ? {
                  fontWeight: fontWeight.bodyBold,
                }
              : {},
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'INVc',
            hideRemoveOption: true,
            windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'code',
                mainKey: 'Code',
                displayType: PasteSpecialDisplayType.Name,
                tagKey: 'uprice1',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
                displayType: PasteSpecialDisplayType.Description,
              },
            ],
          },
          onFocus: table.handleMatrixFocus,
        },
        {
          label: translate('Description'),
          windowElementType: WindowElementType.kInputTypeText,
          style: (viewMode) => {
            let style: React.CSSProperties = {color: colours[GetGlobalTheme()].label};
            if (viewMode === ViewMode.View) {
              style.whiteSpace = 'break-spaces';
            }
            return style;
          },
          dataKey: 'Spec',
          width: isMobileOrTabletScreen() ? '100%' : '440px',
          viewOnly: false,
          onChange: table.updateRecordField,
          onFocus: table.handleMatrixFocus,
        },
      ],
    });
    windowMatrix.push({
      label: translate('Price'),
      dataKey: 'Price',
      windowElementType: WindowElementType.kInputTypeValFull,
      width: isMobileOrTabletScreen() ? '30%' : '110px',
      viewOnly: false,
      onChange: table.updateRecordField,
      onFocus: table.handleMatrixFocus,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: getMatrixTextAlign(viewMode)};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });

    windowMatrix.push({
      label: translate('Quant'),
      windowElementType: WindowElementType.kInputTypeText,
      dataKey: 'Quant',
      windowElementType: WindowElementType.kInputTypeVal,
      width: isMobileOrTabletScreen() ? '25%' : '90px',
      viewOnly: false,
      onChange: table.updateRecordField,
      onFocus: table.handleMatrixFocus,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: getMatrixTextAlign(viewMode)};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });
    windowMatrix.push({
      label: translate('Discount'),
      dataKey: 'vRebate',
      windowElementType: WindowElementType.kInputTypeVal,
      width: isMobileOrTabletScreen() ? '25%' : '90px',
      viewOnly: false,
      onChange: table.updateRecordField,
      onFocus: table.handleMatrixFocus,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: getMatrixTextAlign(viewMode)};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });

    windowMatrix.push({
      label: translate('Total'),
      dataKey: 'Sum',
      windowElementType: WindowElementType.kInputTypeValFull,
      width: isMobileOrTabletScreen() ? '30%' : '110px',
      onChange: table.updateRecordField,
      viewOnly: false,
      onFocus: table.handleMatrixFocus,
      style: (viewMode) => {
        let style: React.CSSProperties = {justifyContent: 'flex-end', textAlign: getMatrixTextAlign(viewMode)};
        if (viewMode === ViewMode.View) {
          style.fontWeight = fontWeight.bodyBold;
        }
        return style;
      },
      cellstyle: getCellStyle(),
    });
    windowMatrix.push({
      label: translate('VATCode'),
      dataKey: 'VATCode',
      display: false,
    });
    return windowMatrix;
  }

  getHiddenFields(): Array<string> {
    return ['PriceList', 'RebCode', 'pdays'];
  }

  isEmptyNewRecord(fullf, table) {
    return fullf == false && table.isNewRecord() && table.recordFieldFilled('CustCode') === false;
  }
}
