import React from 'react';
import styled from 'styled-components/native';

export function LoginBackground() {
  const ImageTiles = styled.Image`
    display: flex;
    background-image: url(${require('@assets/images/ExcellentBackground.png')});
    background-repeat: repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 660px;
  `;

  return <ImageTiles />;
}
