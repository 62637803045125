import React, {useState, useRef, useEffect} from 'react';
import {colours} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import EditField from '@components/editElements/EditField';
import {translate} from '@utils/languageTools';
import Requests from '../../../../api/Requests';
import {logError} from '@utils/debug';
import {InputChangeEventProps} from '@utils/types';
import {ColumnContainer, Header1, Header6} from '@styles/primitiveComponents';
import {ActionButton} from '@components/button/ActionButton';
import AlertWindow, {AlertType} from '@components/design/AlertWindow';
import {LoginBackground} from './LoginBackground';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {Animated, View, Dimensions} from 'react-native';

const ForgotFormStyle = {
  width: '100%',
  maxWidth: 694,
  maxHeight: 960,
  height: 'calc(100% - 120px)',
  minHeight: 720,
  display: 'flex',
  marginVertical: 60,
  marginHorizontal: 'auto',
  paddingVertical: 82,
  paddingHorizontal: '5%',
  alignItems: 'center',
  alignSelf: 'center',
  gap: '10%',
  flexShrink: 0,
  borderRadius: 12,
  backgroundColor: colours[GetGlobalTheme()].mainBG,
};

const InnerContainerStyle = (isMobile: boolean) => ({
  justifyContent: isMobile ? 'center' : 'flex-start',
  textAlign: isMobile ? 'center' : 'left',
  alignItems: 'center',
  gap: '4px',
  display: 'flex',
});

const InputContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
};

const ButtonContainerStyle = {
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '12px',
  flexDirection: 'row',
};

const CancelButtonWrapperStyle = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '10px',
};

const SendButtonWrapperStyle = {
  flex: 1,
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  gap: '10px',
};

const InputWrapperStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  gap: '4px',
};

interface ForgotPasswordProps {
  navigation: NavigationProp<any, any>;
}
const minScreenHeight = 820;
const mobileScreenWidth = 480;

export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const navigation = useNavigation<NavigationProp<any, any>>();
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const messageOpacity = useRef(new Animated.Value(1)).current;
  const [screenHeight, setScreenHeight] = useState(getClampedScreenHeight());
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

  const ContainerStyle = {
    backgroundColor: colours[GetGlobalTheme()].darkBlue,
    height: screenHeight,
  };

  function getClampedScreenHeight() {
    const screenHeight = Dimensions.get('window').height;
    if (screenHeight < minScreenHeight) {
      return minScreenHeight;
    }
    return screenHeight;
  }

  const isMobile = screenWidth <= mobileScreenWidth;

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  function handleResizeEvent() {
    setScreenHeight(getClampedScreenHeight());
    setScreenWidth(Dimensions.get('window').width);
  }

  const displayMessage = (errorMessage: string, successMessage: string) => {
    messageOpacity.stopAnimation();
    messageOpacity.setValue(1);

    setErrorMessage(errorMessage);
    setSuccessMessage(successMessage);

    Animated.timing(messageOpacity, {
      toValue: 0,
      duration: 15000,
      useNativeDriver: true,
    }).start(() => {
      setErrorMessage('');
      setSuccessMessage('');
      messageOpacity.setValue(1);
    });
  };

  const SendForgotPassword = () => {
    if (email !== '') {
      Requests.ResetPassword(email.trim())
        .then((response) => {
          if (response.msg === 'notfound') {
            displayMessage(translate('Reset_UserNotFound'), '');
          } else {
            displayMessage('', translate('Reset_CheckEmail'));
          }
        })
        .catch((error) => {
          logError(error);
          displayMessage(translate('AnErrorOccurred'), '');
        });
    } else {
      displayMessage(translate('PleaseEnterEmail'), '');
    }
  };

  return (
    <View style={ContainerStyle}>
      <LoginBackground />
      <ColumnContainer style={ForgotFormStyle}>
        <ColumnContainer style={InnerContainerStyle(isMobile)}>
          <Header1 style={{color: colours[GetGlobalTheme()].darkBlue300}}>{translate('YouForgotPassword')}</Header1>
          <Header6 style={{color: colours[GetGlobalTheme()].grey600}}>
            {translate('ForgotPasswordInstructions')}
          </Header6>
        </ColumnContainer>
        <ColumnContainer style={InputContainerStyle}>
          <ColumnContainer style={InputWrapperStyle}>
            <Animated.View style={{opacity: messageOpacity, width: '100%'}}>
              <AlertWindow
                message={errorMessage || successMessage}
                width={'100%'}
                alertType={errorMessage ? AlertType.Error : AlertType.Success}
                show={!!(errorMessage || successMessage)}
              />
            </Animated.View>
          </ColumnContainer>
          <EditField
            title={translate('Email')}
            value={email}
            width="100%"
            name={'username'}
            inputStyle={{backgroundColor: colours[GetGlobalTheme()].mainBG}}
            onChange={({value}: InputChangeEventProps) => setEmail(value)}
          />
          <View style={ButtonContainerStyle}>
            <ColumnContainer style={CancelButtonWrapperStyle}>
              <ActionButton
                onPress={() => navigation.navigate('Login')}
                title={translate('Cancel')}
                style={{
                  backgroundColor: colours[GetGlobalTheme()].white,
                  border: '1px solid ' + colours[GetGlobalTheme()].grey400,
                }}
                textStyle={{color: colours[GetGlobalTheme()].darkBlue, width: '100%'}}
              />
            </ColumnContainer>
            <ColumnContainer style={SendButtonWrapperStyle}>
              <ActionButton
                onPress={SendForgotPassword}
                title={translate('Send')}
                textStyle={{color: colours[GetGlobalTheme()].white, width: '100%'}}
              />
            </ColumnContainer>
          </View>
        </ColumnContainer>
      </ColumnContainer>
    </View>
  );
};
