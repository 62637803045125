const opacity = {
  small2: '10', //
  small: '1F', // 12%
  large: 'CC', // 90%
};

type LightColours = {
  active: string;
  activeDark: string;

  lightBlue: string;
  darkBlue: string;

  // Neutral
  white: string;
  black: string;
  grey100: string;
  grey200: string;
  grey300: string;
  grey400: string;
  grey500: string;
  grey600: string;
  grey700: string;
  grey800: string;

  // Blues

  darkBlue100: string;
  darkBlue200: string;
  darkBlue300: string;
  lightBlue100: string;
  lightBlue200: string;
  lightBlue300: string;
  lightBlue400: string;

  // Theme

  primary: string;
  secondary: string;

  // Background

  mainBG: string;
  contentBG: string;
  disabledBG: string;

  // Text

  body: string;
  tableBody: string;
  icon: string;
  label: string;
  headingLight: string;
  bodyLight: string;

  // Button

  primaryButtonHover: string;
  disabledButton: string;
  secondaryButtonHover: string;
  iconButtonBG: string;
  iconButtonHover: string;

  // Red

  red100: string;
  red200: string;
  red300: string;

  // Yellow

  yellow100: string;
  yellow200: string;
  yellow300: string;
  yellow400: string;

  // Green

  green100: string;
  green200: string;
  green300: string;
  green400: string;
  green500: string;
  green600: string;

  // Purple

  purple100: string;
  purple200: string;

  // Alert

  error: string;
  warning: string;
  success: string;
  info: string;
  errorLight: string;
  warningLight: string;
  successLight: string;
  infoLight: string;

  // Border

  borderDefault: string;
  shadowDefault: string;

  // Badge

  yellowDark: string;
  yellowMedium: string;
  yellowLight: string;
  greenDark: string;
  greenLight: string;
  tealDark: string;
  tealLight: string;
  purpleDark: string;
  purpleLight: string;
  redDark: string;
  redLight: string;

  // Tables

  tableHover: string;
};

type DarkColours = {};

type Colours = {
  light: LightColours;
  dark: DarkColours;
};

type IconColours = {
  lavenderPink: string;
  lightPink: string;
  deepMagenta: string;
  raspberryRed: string;
  coral: string;
  peachOrange: string;
  goldenYellow: string;
  paleSkyBlue: string;
  softCyan: string;
  mintGreen: string;
  ceruleanBlue: string;
  brightTurquoise: string;
  lightLimeGreen: string;
  lemonYellow: string;
};

const colours: Colours = {
  light: {
    active: '#184799',
    activeDark: '#1B3665',

    lightBlue: '#0092D3',
    darkBlue: '#081D42',

    // Neutral
    white: '#FFFFFF',
    black: '#000000',
    grey100: '#FAFAFA',
    grey200: '#F4F7FA',
    grey300: '#E9EBF1',
    grey400: '#D5D7DD',
    grey500: '#8990AC',
    grey600: '#53575D',
    grey700: '#31363D',
    grey800: '#101820',

    // Blues

    darkBlue100: '#0D306D',
    darkBlue200: '#0B285B',
    darkBlue300: '#081D42',
    lightBlue100: '#EBF5FC',
    lightBlue200: '#0AB6FF',
    lightBlue300: '#00ABF5',
    lightBlue400: '#0092D3',

    // Theme

    primary: '#081D42',
    secondary: '#0092D3',

    // Background

    mainBG: '#FFFFFF',
    contentBG: '#F4F7FA',
    disabledBG: '#FAFAFA',

    // Text

    body: '#101820',
    tableBody: '#31363D',
    icon: '#8990AC',
    label: '#53575D',
    headingLight: '#FFFFFF',
    bodyLight: '#FAFAFA',

    // Button

    primaryButtonHover: '#0D306D',
    disabledButton: '#8990AC',
    secondaryButtonHover: '#00ABF5',
    iconButtonBG: '#F4F7FA',
    iconButtonHover: '#FAFAFA',

    // Red

    red100: '#FBE7EB',
    red200: '#D10F33',
    red300: '#800F2F',

    // Yellow

    yellow100: '#FDF5E6',
    yellow200: '#FFE5D5',
    yellow300: '#E29203',
    yellow400: '#7E3100',

    // Green

    green100: '#EAF2EE',
    green200: '#D0EAF0',
    green300: '#E7EDEE',
    green400: '#2C7A4C',
    green500: '#1B4965',
    green600: '#025057',

    // Purple

    purple100: '#ECE7F6',
    purple200: '#3A0CA3',

    // Alert

    error: '#D10F33',
    warning: '#E29203',
    success: '#2C7A4C',
    info: '#00ABF5',
    errorLight: '#FBE7EB',
    warningLight: '#FDF5E6',
    successLight: '#EAF2EE',
    infoLight: '#EBF5FC',

    // Border

    borderDefault: '#D5D7DD',
    shadowDefault: '#F4F4F4',

    // Badge

    yellowDark: '#7E3100',
    yellowMedium: '#9C6400',
    yellowLight: '#FFE5D5',
    greenDark: '#025057',
    greenLight: '#E7EDEE',
    tealDark: '#1B4965',
    tealLight: '#D0EAF0',
    purpleDark: '#3A0CA3',
    purpleLight: '#ECE7F6',
    redDark: '#800F2F',
    redLight: '#FBE7EB',

    // Tables

    tableHover: '#FAFAFA',
  },
  dark: {},
};

// Icon Colours

const iconColours: IconColours = {
  lavenderPink: '#CE8BC3',
  lightPink: '#FFABDF',
  deepMagenta: '#AA2A80',
  raspberryRed: '#DA4271',
  coral: '#F96A60',
  peachOrange: '#FF9854',
  goldenYellow: '#FFC855',
  paleSkyBlue: '#DCEEF7',
  softCyan: '#BCF7FE',
  mintGreen: '#A3FFCE',
  ceruleanBlue: '#0092D3',
  brightTurquoise: '#00CACC',
  lightLimeGreen: '#A0F087',
  lemonYellow: '#F9F871',
};

type Spacing = {
  space2: number;
  space4: number;
  space8: number;
  space12: number;
  space14: number;
  space16: number;
  space20: number;
  space24: number;
  space28: number;
  space32: number;
  space36: number;
  space40: number;
  space48: number;
};

const spacing: Spacing = {
  space2: 2,
  space4: 4,
  space8: 8,
  space12: 12,
  space14: 14,
  space16: 16,
  space20: 20,
  space24: 24,
  space28: 28,
  space32: 32,
  space36: 36,
  space40: 40,
  space48: 48,
};

type IconSize = {
  iconSize: number;
};

const iconSize: IconSize = {
  iconSize: 12,
};

type FontFamily = {
  mainFont: string;
};

const fontFamily: FontFamily = {
  mainFont: 'Open Sans',
};

type FontSize = {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  h6: number;
  body: number;
  bodyBold: number;
  bodySmall: number;
  bodySmallBold: number;

  tableBody: number;
  link: number;
  button: number;
};

const fontSize: FontSize = {
  h1: 40,
  h2: 36,
  h3: 32,
  h4: 28,
  h5: 20,
  h6: 16,
  body: 14,
  bodyBold: 14,
  bodySmall: 12,
  bodySmallBold: 12,

  tableBody: 14,
  link: 14,
  button: 14,
};

type LineHeight = {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  body: string;
  bodyBold: string;
  bodySmall: string;
  bodySmallBold: string;

  tableBody: '150%';
  link: '150%';
  button: '150%';
};

const lineHeight: LineHeight = {
  h1: '140%',
  h2: '140%',
  h3: '140%',
  h4: '140%',
  h5: '150%',
  h6: '140%',
  body: '150%',
  bodyBold: '150%',
  bodySmall: '150%',
  bodySmallBold: '150%',

  tableBody: '150%',
  link: '150%',
  button: '150%',
};

type FontWeight = {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  body: string;
  bodyBold: string;
  bodySmall: string;
  bodyLight: string;
  bodySmallBold: string;

  tableBody: string;
  link: string;
  button: string;
};

const fontWeight: FontWeight = {
  h1: '600',
  h2: '600',
  h3: '600',
  h4: '600',
  h5: '600',
  h6: '600',
  body: 'regular',
  bodyBold: '600',
  bodySmall: 'regular',
  bodyLight: '400',
  bodySmallBold: 'regular',

  tableBody: 'regular',
  link: '600',
  button: 'regular',
};

export {colours, spacing, iconSize, fontSize, lineHeight, fontWeight, iconColours};
