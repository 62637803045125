import React from 'react';
import {
  FieldSection,
  PasteSpecialDisplayType,
  PasteSpecialWindowLayout,
  WindowDefinition2,
  WindowMatrix,
} from '@utils/types';
import {translate} from '@utils/languageTools';
import {DoNavigate, GetGlobalTheme, isMobileOrTabletScreen, WindowElementType} from '@tools/displayTools';
import {RecordScreenWindowDefinition} from '@src/types/Record/windowDefinitions';
import {colours, spacing} from '@src/styles/Style';
import {ContactPersons} from './contactPersons/ContactPersons';
import {ContactsTabSection} from '@components/screens/contacts/ContactsTabSection';
import {StaticPasteSpecial} from '@components/editElements/pasteSpecial/StaticPasteSpecialDefinition';
import {contactPersonsEvents} from './contactPersons/contactPersonsEvents';

export class ContactsRecordScreenDefinition extends RecordScreenWindowDefinition {
  window;

  constructor(window) {
    super();
    this.createInvoice = this.createInvoice.bind(this);
    this.createSalesOrder = this.createSalesOrder.bind(this);
    this.window = window;
  }

  getHeaderFields(table: any, fullf = false): WindowDefinition2 {
    // const details = table.getClassificationDetails();
    const classificationFields = table.getClassificationFields();

    let tiles = [
      {tileNumber: 0, name: translate('ContactInformation')},
      {tileNumber: 1, name: translate('AdditionalInformation')},
      {tileNumber: 2, name: translate('Accounts')},
      {tileNumber: 3, name: translate('DeliveryInformation')},
      {tileNumber: 4, name: translate('Attachments')},
      {tileNumber: 5, name: translate('Status')},
    ];

    if (isMobileOrTabletScreen()) {
      tiles = [
        {
          tileNumber: 0,
          name: translate('ContactInformationShort'),
        },
        {tileNumber: 5, name: translate('StatusShort')},
        {
          tileNumber: 1,
          name: translate('AdditionalInformationShort'),
        },
        {tileNumber: 2, name: translate('AccountsShort')},
        {
          tileNumber: 3,
          name: translate('DeliveryInformationShort'),
        },
        {
          tileNumber: 4,
          name: translate('AttachmentsShort'),
        },
      ];
    }

    if (this.isEmptyNewRecord(fullf, table)) {
      const indexToRemove = tiles.findIndex((tile) => tile.tileNumber === 5);
      if (indexToRemove !== -1) {
        tiles.splice(indexToRemove, 1);
      }
    }

    let windowDefinition: WindowDefinition2 = {
      sectionList: {
        component: table.record && !table.isNewRecord() ? table.showContactShortInfo() : null,
        tiles: tiles,
      },
      operationMenu: [
        {
          label: translate('CreateSalesInvoice'),
          onPress: () => this.createInvoice(),
        },
        {
          label: translate('CreateSalesOrder'),
          onPress: () => this.createSalesOrder(),
        },
        {
          label: translate('CreateContactPerson'),
          onPress: () => contactPersonsEvents.open(),
        },
      ],
      editable: true,
      sectionTiles: [
        {
          fieldSections: [
            {
              label: translate('ContactInformation'),
              groups: [
                {
                  viewStyle: {
                    flex: '1',
                    padding: spacing.space12,
                  },
                  fields: [
                    {
                      label: translate('Name'),
                      dataKey: 'Name',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('PrimaryContact'),
                      dataKey: 'Person',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Telephone'),
                      dataKey: 'Phone',
                      windowElementType: WindowElementType.kInputTypeMobile,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Mobile'),
                      dataKey: 'Mobile',
                      windowElementType: WindowElementType.kInputTypeMobile,
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Email'),
                      dataKey: 'eMail',
                      windowElementType: WindowElementType.kInputTypeEmail,
                      onChange: table.updateRecordField,
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  viewStyle: {
                    flex: '1',
                    background: colours[GetGlobalTheme()].contentBG,
                    padding: spacing.space12,
                  },
                  fields: [
                    {
                      label: translate('StreetName'),
                      dataKey: 'InvAddr0',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Village'),
                      dataKey: 'InvAddr1',
                      onChange: table.updateRecordField,
                    },

                    {
                      label: translate('City'),
                      dataKey: 'InvAddr2',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('County'),
                      dataKey: 'InvAddr3',
                      onChange: table.updateRecordField,
                    },
                    {
                      line: [
                        {
                          label: translate('PostIndex'),
                          dataKey: 'InvAddr4',
                          onChange: table.updateRecordField,
                        },
                        {
                          label: translate('Country'),
                          dataKey: 'CountryCode',
                          viewOnly: false,
                          onChange: table.updateRecordField,
                          pasteSpecial: {
                            vcName: 'CountryVc',
                            windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                            useAlternateDisplay: true,
                            viewModeDisplayFormat: '{Comment}',
                            mainKey: 'Code',
                            columns: [
                              {
                                name: translate('Code'),
                                width: '35%',
                                key: 'Code',
                                mainKey: 'Code',
                                displayType: PasteSpecialDisplayType.Name,
                              },
                              {
                                name: translate('Name'),
                                width: '65%',
                                key: 'Comment',
                                displayType: PasteSpecialDisplayType.Description,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              overrideKey: table.id,
              groups: [
                {
                  component: (window) => (
                    <ContactPersons viewMode={window.state.viewMode} window={this.window} id={this.window.id} />
                  ),
                },
              ],
            },
          ],
        },
        {
          fieldSections: [
            {
              label: translate('AdditionalInformation'),
              groups: [
                {
                  fields: [
                    {
                      label: translate('Type'),
                      dataKey: 'CustType',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: StaticPasteSpecial.ContactType,
                        multiValue: false,
                        mainKey: 'Code',
                        nameDisplayFormat: '{Comment}',
                        useAlternateDisplay: true,
                        keyToDisplayInViewMode: ['Comment'],
                        columns: [
                          {
                            name: translate('Name'),
                            width: '100%',
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('RegNr'),
                      dataKey: 'RegNr1',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('VATNr'),
                      dataKey: 'VATNr',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('VATZone'),
                      dataKey: 'ExportFlag',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: StaticPasteSpecial.VatZone,
                        multiValue: false,
                        mainKey: 'Code',
                        nameDisplayFormat: '{Comment}',
                        useAlternateDisplay: true,
                        keyToDisplayInViewMode: ['Comment'],
                        columns: [
                          {
                            name: translate('Name'),
                            width: '100%',
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('Language'),
                      dataKey: 'LangCode',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'LangBlock',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('CustomerCategory'),
                      dataKey: 'CustCat',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'CCatVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('Classification'),
                      dataKey: 'Classification',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'CClassVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        multiValue: true,
                        useMultiDropDownWithFilters: table.getClassificationTypes,
                        multiDropDownField: classificationFields,
                        keyToDisplayInViewMode: ['Code', 'Comment'],
                        // details: details,
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesMan'),
                      dataKey: 'SalesMan',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'UserVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'code',
                        toolTip: {hasToolTip: true, key: 'name', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'name',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('WarningOnSales'),
                      dataKey: 'WarnText1',
                      onChange: table.updateRecordField,
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  fields: [
                    {
                      label: translate('Comment'),
                      dataKey: 'Comment',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('SalesGroup'),
                      dataKey: 'SalesGroup',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'SalesGroupVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'SGroupCode',
                        viewModeDisplayFormat: '{SGroupCode} {SGroupName}',
                        toolTip: {hasToolTip: true, key: 'SGroupName', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'SGroupCode',
                            mainKey: 'SGroupCode',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'SGroupName',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesCurrency'),
                      dataKey: 'CurncyCode',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'CurncyCodeVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'CurncyCode',
                        viewModeDisplayFormat: '{CurncyCode} {Comment}',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'CurncyCode',
                            mainKey: 'CurncyCode',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('PriceList'),
                      dataKey: 'PLCode',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'PLDefVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        viewModeDisplayFormat: '{Code} {Comment}',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('DiscountMatrix'),
                      dataKey: 'RebCode',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'RebVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        viewModeDisplayFormat: '{Code} {Comment}',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesVATCode'),
                      dataKey: 'VATCode',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'VATCodeBlock',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'VATCode',
                        viewModeDisplayFormat: '{VATCode} {Comment}',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'VATCode',
                            mainKey: 'VATCode',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('PayDeal'),
                      dataKey: 'PayDeal',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'PDVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        toolTip: {hasToolTip: true, key: 'pdComment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'pdComment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesCredLim'),
                      dataKey: 'CreditLimit',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('SalesCredLimDays'),
                      dataKey: 'CreditLimitDays',
                      onChange: table.updateRecordField,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldSections: [
            {
              label: translate('Accounts'),
              groups: [
                {
                  fields: [
                    {
                      label: translate('IBAN'),
                      dataKey: 'IBANCode',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('SWIFT'),
                      dataKey: 'SWIFT',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('AccountOperator'),
                      dataKey: 'AccOperator',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'BankVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        toolTip: {hasToolTip: true, key: 'Name', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'Name',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  fields: [
                    {
                      label: translate('CreditorAC'),
                      dataKey: 'AccAP',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'AccVc',
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'AccNumber',
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 60},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'AccNumber',
                            mainKey: 'AccNumber',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesTagsObj'),
                      dataKey: 'Objects',
                      viewOnly: false,
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'ObjVc',
                        multiValue: true,
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        keyToDisplayInViewMode: ['Code', 'Comment'],
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 40},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('DontShowVATRep'),
                      windowElementType: WindowElementType.kInputTypeCheckbox,
                      dataKey: 'VATInfFlag',
                      onChange: table.updateRecordField,
                    },
                  ],
                },
              ],
            },
            {
              label: translate('EDataExchange'),
              groups: [
                {
                  fields: [
                    {
                      label: translate('EInvoiceReceiver'),
                      windowElementType: WindowElementType.kInputTypeCheckbox,
                      dataKey: 'EInvReceiverFlag',
                      onChange: table.updateRecordField,
                      viewOnly: true,
                    },
                    {
                      label: translate('ReceivingPreference'),
                      dataKey: 'eInvRcvPref',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: StaticPasteSpecial.ReceivingPreference,
                        multiValue: false,
                        mainKey: 'Code',
                        nameDisplayFormat: '{Comment}',
                        useAlternateDisplay: true,
                        keyToDisplayInViewMode: ['Comment'],
                        columns: [
                          {
                            name: translate('Name'),
                            width: '100%',
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('AlternativeChannel'),
                      dataKey: 'eInvAltRcvPref',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: StaticPasteSpecial.AlternativeChannel,
                        multiValue: false,
                        mainKey: 'Code',
                        nameDisplayFormat: '{Comment}',
                        useAlternateDisplay: true,
                        keyToDisplayInViewMode: ['Comment'],
                        columns: [
                          {
                            name: translate('Name'),
                            width: '100%',
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('OVTmark'),
                      dataKey: 'ANACode',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('PeppolID'),
                      dataKey: 'ExcPeppolID',
                      onChange: table.updateRecordField,
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  fields: [
                    {
                      label: translate('InvoiceTo'),
                      dataKey: 'InvoiceToCode',
                      onChange: table.updateRecordField,
                      viewOnly: false,
                      pasteSpecial: {
                        vcName: 'CUVc',
                        newRecord: true,
                        windowLayout: PasteSpecialWindowLayout.Default,
                        useAlternateDisplay: true,
                        viewModeDisplayFormat: '{name} ({code})',
                        mainKey: 'code',
                        nameDisplayFormat: '{name} ({code})',
                        columns: [
                          {
                            name: translate('CustomerName') + ' ' + translate('Code'),
                            width: '70%',
                            key: 'name',
                            mainKey: 'Code',
                            displayFormat: '{name} ({code})',
                          },
                          {
                            width: '30%',
                            name: translate('RegNr'),
                            key: 'regnr1',
                          },
                        ],
                        pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
                      },
                    },
                    {
                      label: translate('Recipient'),
                      dataKey: 'Recipient',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'CUVc',
                        newRecord: true,
                        windowLayout: PasteSpecialWindowLayout.Default,
                        useAlternateDisplay: true,
                        viewModeDisplayFormat: '{name} ({code})',
                        mainKey: 'code',
                        nameDisplayFormat: '{name} ({code})',
                        columns: [
                          {
                            name: translate('CustomerName') + ' ' + translate('Code'),
                            width: '70%',
                            key: 'name',
                            mainKey: 'Code',
                            displayFormat: '{name} ({code})',
                          },
                          {
                            width: '30%',
                            name: translate('RegNr'),
                            key: 'regnr1',
                          },
                        ],
                        pasteSpecialValueFields: 'Code,Name,Person,eMail,Phone,Mobile,RegNr1',
                      },
                    },
                    {
                      label: translate('ElectrInvPDF'),
                      windowElementType: WindowElementType.kInputTypeCheckbox,
                      dataKey: 'eInvAttachPDF',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('DontShowCustBal'),
                      windowElementType: WindowElementType.kInputTypeCheckbox,
                      dataKey: 'ExcNoBalOnEInv',
                      onChange: table.updateRecordField,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldSections: [
            {
              label: translate('DeliveryInformation'),
              groups: [
                {
                  viewStyle: {
                    flex: '1',
                  },
                  fields: [
                    {
                      label: translate('SalesDelTerms'),
                      dataKey: 'ShipDeal',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'ShipDealVc',
                        multiValue: false,
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        keyToDisplayInViewMode: ['Code', 'Comment'],
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 40},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                    {
                      label: translate('SalesDelMode'),
                      dataKey: 'ShipMode',
                      onChange: table.updateRecordField,
                      pasteSpecial: {
                        vcName: 'DMVc',
                        multiValue: false,
                        windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                        mainKey: 'Code',
                        keyToDisplayInViewMode: ['Code', 'Comment'],
                        toolTip: {hasToolTip: true, key: 'Comment', maxCharacters: 40},
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'Code',
                            mainKey: 'Code',
                            displayType: PasteSpecialDisplayType.Name,
                          },
                          {
                            width: '65%',
                            name: translate('Comment'),
                            key: 'Comment',
                            displayType: PasteSpecialDisplayType.Description,
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  divider: true,
                },
                {
                  viewStyle: {
                    flex: '1',
                    background: colours[GetGlobalTheme()].contentBG,
                    padding: spacing.space12,
                  },
                  label: translate('DeliveryAddress'),
                  fields: [
                    {
                      label: translate('StreetName'),
                      dataKey: 'DelAddr0',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('Village'),
                      dataKey: 'DelAddr1',
                      onChange: table.updateRecordField,
                    },

                    {
                      label: translate('City'),
                      dataKey: 'DelAddr2',
                      onChange: table.updateRecordField,
                    },
                    {
                      label: translate('County'),
                      dataKey: 'DelAddr3',
                      onChange: table.updateRecordField,
                    },
                    {
                      line: [
                        {
                          label: translate('PostIndex'),
                          dataKey: 'DelAddr4',
                          onChange: table.updateRecordField,
                        },
                        {
                          label: translate('Country'),
                          dataKey: 'DelCountryName',
                          viewOnly: false,
                          onChange: table.updateRecordField,
                          pasteSpecial: {
                            vcName: 'CountryVc',
                            windowLayout: PasteSpecialWindowLayout.SingleColumnWithDescription,
                            useAlternateDisplay: true,
                            viewModeDisplayFormat: '{Comment}',
                            mainKey: 'Code',
                            columns: [
                              {
                                name: translate('Code'),
                                width: '35%',
                                key: 'Code',
                                mainKey: 'Code',
                                displayType: PasteSpecialDisplayType.Name,
                              },
                              {
                                name: translate('Name'),
                                width: '65%',
                                key: 'Comment',
                                displayType: PasteSpecialDisplayType.Description,
                              },
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          fieldSections: [
            {
              label: translate('Attachments'),
              groups: [
                {
                  attachments: {
                    wideView: true,
                    showTitle: false,
                  },
                },
              ],
            },
          ],
        },
        {
          fieldSections: [
            {
              style: {backgroundColor: 'none', padding: 0},
              groups: [
                {
                  component: () => <ContactsTabSection window={this.window} show={true} />,
                },
              ],
            },
          ],
        },
      ],
    };

    if (this.isEmptyNewRecord(fullf, table)) {
      const indexToRemove = windowDefinition.sectionTiles[0].fieldSections.findIndex(
        (section) => section.label === translate('ListOfContactPers')
      );
      if (indexToRemove !== -1) {
        windowDefinition.sectionTiles[0].fieldSections.splice(indexToRemove, 1);
      }
    }

    return windowDefinition;
  }

  isEmptyNewRecord(fullf, table) {
    return fullf === false && table.isNewRecord();
  }

  createInvoice() {
    const recordData = {CustCode: this.window.record.headerFields.Code};
    DoNavigate(this.window, 'Invoices', true, 'new', false, false, '', recordData);
  }

  createSalesOrder() {
    const recordData = {CustCode: this.window.record.headerFields.Code};
    DoNavigate(this.window, 'SalesOrders', true, 'new', false, false, '', recordData);
  }

  getSideSectionFields(table): FieldSection[] {
    return null;
  }

  getMatrixFields(table): WindowMatrix {
    return null;
  }

  getHiddenFields(): Array<string> {
    return ['VEType', 'CUType'];
  }
}
