import React, {useEffect, useState} from 'react';
import {Image, Modal, Platform, ScrollView, useWindowDimensions} from 'react-native';
import styled from 'styled-components/native';
import {ColumnContainer, Header5, RowContainer} from '@styles/primitiveComponents';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import {ActionButton} from '@components/button/ActionButton';
import {translate} from '@utils/languageTools';
import {IsHRWeb} from '@src/tools/displayTools';
import {b64toBlob} from 'src/Tools/Base64Decode';
import {PDFViewer} from 'src/components/status/PDFViewer';
import {AccessDenied} from '../screens/status/AccessDenied';
import {ModalWindow} from '@components/editElements/modalWindow/ModalWindow';

const ModalBackground = styled(ColumnContainer)`
  background-color: rgba(63, 63, 63, 0.4);
  width: 100%;
  height: 100%;
`;

const Container = styled(ColumnContainer)`
  background-color: ${colours[GetGlobalTheme()].white};
  border-radius: 12px;
  width: 90%;
  max-width: 820px;
  max-height: 90%;
  margin: auto;
  display: flex;
  overflow: hidden;
  flex-shrink: 1;
`;

const FileHeaderContainer = styled(RowContainer)`
  padding: ${spacing.space16}px ${spacing.space24}px;
`;

const FileName = styled(Header5)``;

const ClosePreviewButtonStyle = {
  padding: 0,
  margin: 0,
  width: 30,
  height: 30,
  borderRadius: 8,
  marginLeft: 'auto',
  border: 'none',
};

const PreviewContainer = styled.View`
  background-color: ${colours[GetGlobalTheme()].contentBG};
  padding: ${spacing.space8}px;
  flex: 1;
  min-height: ${(props) => props.containerHeight}px;
  max-height: 600px;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
`;

const FooterContainer = styled(RowContainer)`
  padding: ${spacing.space16}px ${spacing.space24}px;
  width: 100%;
  flex-shrink: 0;
`;

const DownloadFileButtonStyle = {
  padding: spacing.space24,
  margin: 0,
  borderRadius: 8,
  marginLeft: 'auto',
  border: 'none',
};

const DownloadFileIconStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  order: -1,
  paddingRight: spacing.space4,
};

const DownloadFileTextStyle = {
  color: 'black',
};

function isAndroidChrome() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('android') && userAgent.includes('chrome');
}

function showPreviewFile(fileName, base64) {
  let previewImageElement = [];
  let extension = fileName.split2('.').pop();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'bmp':
    case 'gif':
      if (IsHRWeb()) {
        previewImageElement.push(
          <img
            style={{maxHeight: '100%', maxWidth: '100%'}}
            src={'data:image/' + extension + ';base64,' + base64}
            alt={fileName}
          />
        );
      } else {
        previewImageElement.push(
          <Image
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              aspectRatio: 1,
              resizeMode: 'contain',
              width: '100%',
            }}
            source={{
              uri: 'data:image/' + extension + ';base64,' + base64,
            }}
            alt={fileName}
          />
        );
      }
      break;
    case 'pdf':
      previewImageElement.push(
        <PDFViewer base64={base64} style={{height: '100%', width: '100%', border: 'none', padding: 0, margin: 0}} />
      );
      break;
    default:
      console.log('different file type');
  }

  return previewImageElement;
}

type FilePreviewProps = {
  fileName: string;
  title: string;
  base64File: any;
  onPressPreviewClose: () => void;
  allowDownload: boolean;
};

const FilePreview = ({fileName, title, base64File, onPressPreviewClose, allowDownload}: FilePreviewProps) => {
  const [shouldSkipPreview, setShouldSkipPreview] = useState(false);
  const {width, height} = useWindowDimensions();

  useEffect(() => {
    if (isAndroidChrome()) {
      setShouldSkipPreview(true);
    }
  }, []);

  const isDesktop = () => {
    return Platform.OS === 'web' && (window.innerWidth > 800 || window.innerHeight > 600);
  };

  const getPreviewContainerHeight = (width, height) => {
    if (isDesktop()) {
      return height * 0.7;
    }
    return width > height ? 220 : height * 0.5;
  };

  const containerHeight = getPreviewContainerHeight(width, height);

  function closePreview() {
    onPressPreviewClose();
  }

  function downloadFile() {
    switch (Platform.OS) {
      default:
        //download web file
        let blob = b64toBlob(base64File, 'application/octet-stream');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = fileName;
        a.target = '_blank';
        a.click();
        window.URL.revokeObjectURL(url);
    }
  }

  function getModalContentComponent() {
    return (
      <PreviewContainer containerHeight={containerHeight}>
        {shouldSkipPreview ? (
          <AccessDenied header={''} subtext={translate('NotSupported')} subtext2={''} />
        ) : (
          showPreviewFile(fileName, base64File)
        )}
      </PreviewContainer>
    )
  }

  function getModalFooterComponent() {
    return  (
      <ActionButton
      style={DownloadFileButtonStyle}
      iconStyle={DownloadFileIconStyle}
      textStyle={DownloadFileTextStyle}
      backgroundColor={colours[GetGlobalTheme()].grey200}
      hoverColor={colours[GetGlobalTheme()].grey400}
      title={translate('Download')}
      iconName={'DownloadCloudIcon'}
      onPress={downloadFile}
    />
    )
  }

  return (
    <ModalWindow
      inTransparent={true}
      customContentWrapper={getModalContentComponent()}
      headerProps={{
        title: title,
        onCloseButtonPress: () => closePreview(),
      }}
      showFooter={allowDownload}
      footerComponent={getModalFooterComponent()}
    />
  );
};

export default FilePreview;
