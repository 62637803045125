import {translate} from '@utils/languageTools';
import {
  DisplayBrowseClosed,
  WindowElementType,
} from '../../../Tools/DisplayTools';
import {GetWindowFilter} from '../../../Tools/WindowTools';
import {ListWindowColumn, WindowDefinition} from '@utils/types';

const employeeHeaderFields_LAT = (table): WindowDefinition => {
  return [
    {
      fields: [
        {
          label: translate('Code'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCode',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Name'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Name',
          viewOnly: false,
          width: '400px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('TaxGroup'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'TaxGroup',
          viewOnly: false,
          width: '200px',
          pasteSpecial: {
            vcName: 9,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Description',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
        {
          label: translate('Group'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Group',
          viewOnly: false,
          width: '200px',
          pasteSpecial: {
            vcName: 10,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Group',
                mainKey: 'Group',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'GroupName',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('PersonID'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'PersonIDNr',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Post'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'OffPostCode',
          viewOnly: false,
          width: '180px',
          pasteSpecial: {
            vcName: 11,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'PostCode',
                mainKey: 'PostCode',
              },
              {
                width: '65%',
                name: translate('Comment'),
                key: 'PostName',
              },
            ],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
};

const employeeHeaderFields_EST = (table): WindowDefinition => {
  return [
    {
      fields: [
        {
          label: translate('Code'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCode',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Firstname'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Firstname',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('Surname'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Surname',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('BirthDate'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'BirthDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('PersonID'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'IDCode',
          viewOnly: false,
          width: '200px',
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
};

export function headerFields(table) {
  let res;
  switch (global.userData.provider) {
    case 1:
      res = employeeHeaderFields_EST(table);
      break;

    default:
      res = employeeHeaderFields_LAT(table);
  }
  return res;
}

function empBrowseColumns_LAT() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('Code'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'name',
      sortKey: 'Name',
      width: '300px',
    },
    {
      name: translate('PersonID'),
      key: 'personidnr',
      sortKey: 'PersonIDNr',
      width: '200px',
    },
  ];
  if (GetWindowFilter('Employees', 'closed') === null) {
    browseColumns.push({
      name: translate('Closed'),
      key: 'closed',
      sortKey: 'Closed',
      width: '70px',
      overwrite: DisplayBrowseClosed,
    });
  }
  return browseColumns;
}

function empBrowseColumns_EST() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('Code'),
      key: 'empcode',
      sortKey: 'EmpCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'firstname',
      sortKey: 'FirstName',
      width: '300px',
      key2: 'surname',
      windowElementType: WindowElementType.kInputTypeJointField,
    },
    {
      name: translate('PersonID'),
      key: 'idcode',
      sortKey: 'IDCode',
      width: '200px',
    },
  ];
  /*
            if (GetWindowFilter('Employees', 'closedflag') === null) {
              browseColumns.push({
                title: translate('Closed'),
                dataKey: 'closedflag',
                sortKey: 'ClosedFlag',
                width: '70px',
                overwrite: DisplayBrowseClosed,
              });
            }
            */
  return browseColumns;
}

export function browseColumns(table) {
  let result;
  switch (global.userData.provider) {
    case 1:
      result = empBrowseColumns_EST(table);
      break;
    default:
      result = empBrowseColumns_LAT(table);
  }
  return result;
}
