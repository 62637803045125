import React, {useEffect, useState} from 'react';
import {
  isDesktopScreen,
  isLargeScreen,
  isMediumScreen,
  isMobileOrTabletScreen,
  isVeryLargeScreen,
  ScreenWidth,
} from '@tools/displayTools';
import {
  getMaximizedNavigatorSize,
  getMinimizedNavigatorSize,
  maximizedNavigatorSize,
  maxScreenWidth,
  minimizedNavigatorSize,
} from '@components/navigation/sideNavigator/DrawerContentWrapper';
import {ColumnContainer} from '@styles/primitiveComponents';

type ScrenContentWrapperProps = {
  children: React.ReactNode;
};

function getDefaultScreenSize() {
  let defScreenSize = ScreenWidth();
  if (isDesktopScreen()) {
    defScreenSize -= global.isDrawerOpen ? maximizedNavigatorSize : minimizedNavigatorSize;
  }
  return defScreenSize;
}

export const ScreenContentWrapper: React.FC<ScrenContentWrapperProps> = ({children}) => {
  const [screenData, setScreenData] = useState({
    width: getDefaultScreenSize(),
    isDrawerOpen: global.isDrawerOpen,
  });

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);
    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  }, []);

  global.refreshScreenWidth = (isDrawerOpen: boolean) => {
    handleResizeEvent(isDrawerOpen);
  };

  function getPadding() {
    if (isMobileOrTabletScreen()) {
      return 0;
    }
    if (isMediumScreen()) {
      return 8;
    }
    if (isLargeScreen()) {
      return 16;
    }
    return 48;
  }

  const ScreenContainerWrapper = {
    width: screenData.width,
    maxWidth: isVeryLargeScreen() ? maxScreenWidth : 'initial',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: isMobileScreen() ? 0 : screenData.isDrawerOpen ? maximizedNavigatorSize : minimizedNavigatorSize,
    marginLeft: isMobileOrTabletScreen()
      ? getMinimizedNavigatorSize()
      : screenData.isDrawerOpen
      ? getMaximizedNavigatorSize()
      : getMinimizedNavigatorSize(),
  };
  const ScreenContainerStyle = {
    width: isVeryLargeScreen() ? maxScreenWidth : screenData.width,
    maxWidth: maxScreenWidth,
    margin: '0 auto',
    paddingLeft: getPadding(),
    paddingRight: getPadding(),
  };

  function handleResizeEvent(isDrawerOpen) {
    if (isDrawerOpen !== undefined || screenData.width !== getDefaultScreenSize()) {
      setScreenData({
        width: getDefaultScreenSize(),
        isDrawerOpen: isDrawerOpen,
      });
    }
  }

  return (
    <ColumnContainer style={ScreenContainerWrapper}>
      <ColumnContainer style={ScreenContainerStyle}>{children}</ColumnContainer>
    </ColumnContainer>
  );
};
