import React, {Component} from 'react';

import {View} from 'react-native';
import styled from 'styled-components/native';
import {colours, fontSize} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {CheckBox} from 'react-native-elements';
import {isApproved} from '../../utils/helpers';
import {translate} from '@utils/languageTools';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {DayIsRedDay, GetIntervalColor, GetOffWorkColor, GetWebColor} from '../../Tools/ShiftTools';
import {MatrixContainer} from './Matrix';
import {OverwriteMatrixProps, WindowMatrix, WindowRecord} from '@utils/types';

const ShiftSwitchContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2px;
  padding-bottom: 20px;
  border-radius: 3px;
  background-color: ${colours[GetGlobalTheme()].mainBackground};
  margin-left: 20px;
`;
const ShiftWrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2px;
  border-radius: 3px;
  background-color: ${colours[GetGlobalTheme()].mainBackground};
`;
const ShiftButton = styled.TouchableOpacity`
  margin-right: 5px;
`;
const ChekboxWrapper = styled.TouchableOpacity`
  padding-left: 2px;
`;

const ShiftButtonText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
  border: 1px solid ${colours[GetGlobalTheme()].normalHighligh};
  padding: 4px 10px;
  border-radius: 3px;
  font-size: ${fontSize.small200};
`;

const NormalText = styled.Text`
  background-color: transparent;
  width: 100%;
  text-align: center;
  border-bottom: none;
  color: ${colours[GetGlobalTheme()].rowText};
`;

function ShiftInDay(str, interval): string {
  let res = '0';
  let list = str.split2(',');
  if (interval !== '') {
    if (list.indexOf(interval) > -1) {
      res = '1';
    } else {
      if (str !== '') {
        res = '2';
      }
    }
  }
  return res;
}

const ShiftSwitch = (props) => {
  let group = [];
  if (objectHasProperty(global.pasteSpecialsVector['13_2'], props.window.props.record.headerFields.ShiftGroup)) {
    group = global.pasteSpecialsVector['13_2'][props.window.props.record.headerFields.ShiftGroup];
  }
  return (
    <ShiftSwitchContainer>
      {group.map((interval, index) => {
        let intrec = global.pasteSpecialsVector[14][interval.ShiftInterval];
        let comment = interval.IntervalComment + ' (' + interval.ShiftInterval + ')';
        if (intrec) {
          comment += '\n' + intrec.FromTime.substring(0, 5) + '-' + intrec.ToTime.substring(0, 5);
        } else {
          comment += '\n' + translate('IntervalNotFound');
        }
        let style = {};
        if (interval.ShiftInterval === props.window.state.activeInterval) {
          style.backgroundColor = colours[GetGlobalTheme()].buttonBackgroundAlt;
        }
        return (
          <ShiftButton
            key={index}
            onPress={() => {
              props.window.switchShifts(interval.ShiftInterval);
            }}>
            <ShiftButtonText style={style}>{comment}</ShiftButtonText>
          </ShiftButton>
        );
      })}
    </ShiftSwitchContainer>
  );
};

interface ShiftContainerState extends RecordScreenState {
  activeInterval: string;
  px: any;
  py: any;
  StartTime: string;
  EndTime: string;
  EditSlot: string;
}

class ShiftContainer extends Component<{}, ShiftContainerState> {
  localcolumns: WindowMatrix;
  localrows: Array<any>;
  localrecord: WindowRecord;
  _skipUpdate: boolean;

  constructor(props) {
    super(props);
    this.switchShifts = this.switchShifts.bind(this);
    this.localrows = [];
    this.localrecord = new WindowRecord(12, true, undefined, JSON.parse(JSON.stringify(props.columns)));
    this.localcolumns = this.localrecord.WindowDef.matrixFields;
    this._skipUpdate = false;
    let tmp = global.pasteSpecialsVector['13_2'][this.props.record.headerFields.ShiftGroup];
    let interval = '';
    if (tmp && tmp.length > 0) {
      interval = tmp[0].ShiftInterval;
    }
    this.state = {
      activeInterval: interval,
      px: {},
      py: {},
      StartTime: '00:00:00',
      EndTime: '00:00:00',
      EditSlot: '',
    };

    this.setLocalRecord(this.state.activeInterval);
    for (let i = 0; i < this.localcolumns.length; i++) {
      if (this.localcolumns[i].dataKey.substring(0, 3) === 'Day') {
        this.localcolumns[i].overwrite = this.showShiftCell;
      }
      if (this.localcolumns[i].dataKey === 'LineFlag') {
        this.localcolumns[i].overwrite = this.showShiftCell;
      }
      if (this.localcolumns[i].dataKey === 'TotalHoursRow') {
        this.localcolumns[i].overwrite = this.showRowTotal;
      }
    }
  }

  componentWillUpdate(props) {
    if (this._skipUpdate === false) {
      const descriptor = Object.getOwnPropertyDescriptor(this.props, 'record');
      if (descriptor.writable) {
        this.props.record = props.record;
      }
      this.setLocalRecord(this.state.activeInterval);
    } else {
      this._skipUpdate = false;
    }
  }

  switchShifts = (shift) => {
    this.setLocalRecord(shift);
    this._skipUpdate = true;
    this.setState({activeInterval: shift});
  };

  ColumnIsEditable = (columnindex) => {
    let res = false;
    if (
      this.props.columns[columnindex].viewOnly === false &&
      isApproved(this.props.record, this.props.okcheck) === false
    ) {
      res = true;
    }
    if (
      this.props.columns[columnindex].dataKey === 'LineFlag' &&
      isApproved(this.props.record, this.props.okcheck) === false
    ) {
      res = true;
    }
    return res;
  };

  IntervalExists = (colval) => {
    let res = false;
    if (objectHasProperty(global.pasteSpecialsVector[14], colval)) {
      res = true;
    }

    return res;
  };

  ShowInactiveShiftDay2 = (rowindex, colkey) => {
    let res = false;
    let colval = this.props.record.matrixRows[rowindex][colkey];
    let colcolor = '';
    if (colval !== '') {
      if (colval.indexOf(',') === -1 && this.IntervalExists(colval) === false && colval.indexOf(':') === -1) {
        res = true;
        colcolor = GetOffWorkColor(colval);
      } else {
        if (this.IntervalExists(colval)) {
          colcolor = GetIntervalColor(colval);
        }
      }
    }
    if (colkey === 'LineFlag') {
      res = false;
    }
    return {res: res, colkey: colkey, colval: colval, colcolor: colcolor};
  };

  ShowInactiveShiftDay = (rowindex, columnindex) => {
    let colkey = this.props.columns[columnindex].dataKey;
    return this.ShowInactiveShiftDay2(rowindex, colkey);
  };

  showShiftCell = ({value, rowindex, colindex}: OverwriteMatrixProps): Element => {
    let self = this;
    let updatef = true;
    let opacity = '0.7';
    let editablef = true;
    let res = this.ShowInactiveShiftDay(rowindex, colindex);
    if (res.res) {
      editablef = false;
    }
    if (value === '2') {
      value = '0';
      updatef = false;
      opacity = '0.3';
      editablef = false;
    }
    let style = {};
    if (res.colcolor !== '') {
      style = {
        backgroundColor: res.colcolor,
        height: '32px',
        lineHeight: '32px',
      };
    }
    if (editablef) {
      return (
        <ChekboxWrapper style={style}>
          <CheckBox
            onPress={() => {
              if (updatef && self.ColumnIsEditable(colindex)) {
                self.updateShiftCellValue(rowindex, colindex, value === '0' || value === '');
              }
            }}
            size={18}
            checked={value === '1' ? true : false}
            checkedIcon="check-square"
            uncheckedIcon="square"
            iconType="font-awesome-5"
            checkedColor={colours[GetGlobalTheme()].titleText}
            uncheckedColor={colours[GetGlobalTheme()].titleText}
            containerStyle={{
              margin: '0px !important',
              marginLeft: '0px !important',
              marginRight: '0px !important',
              padding: '0px !important',
              transform: 'scale(0.8)',
              opacity: opacity,
            }}
          />
        </ChekboxWrapper>
      );
    } else {
      let val = res.colval;
      if (val.indexOf(':') > -1) {
        val = '!M';
      }
      return (
        <View style={style}>
          <NormalText>{val}</NormalText>
        </View>
      );
    }
    //    }
  };

  showRowTotal = ({value}: OverwriteMatrixProps): Element => {
    let style = {};
    if (parseFloat(value) > parseFloat(this.props.record.headerFields.NormalHours)) {
      style = {
        backgroundColor: GetWebColor(global.shiftBlock.colornormhours),
        height: '32px',
        lineHeight: '32px',
      };
    }
    return (
      <View style={style}>
        <NormalText>{value}</NormalText>
      </View>
    );
  };

  updateShiftCellValue = (rowid, columnindex, addf) => {
    let id = this.props.columns[columnindex].dataKey;
    let arr = [];
    if (this.props.record.matrixRows[rowid][id] !== '') {
      arr = this.props.record.matrixRows[rowid][id].split2(',');
    }
    let interval = this.state.activeInterval;
    let newval = this.props.record.matrixRows[rowid][id];
    if (addf) {
      if (arr.indexOf(interval) === -1) {
        arr.push(interval);
        newval = arr.join(',');
      }
    } else {
      let pos = arr.indexOf(interval);
      if (pos > -1) {
        arr.splice(pos, 1);
        newval = arr.join(',');
      }
    }
    if (id === 'LineFlag') {
      newval = (addf ? '1' : '0') + ':' + this.state.activeInterval;
    }
    this.props.record.matrixRows[rowid][id] = newval;
    if (this.props.columns[columnindex].onChange !== undefined) {
      this.props.columns[columnindex].onChange({
        rowindex: rowid,
        fieldname: id,
        value: newval,
      });
    }
    //this.setLocalRecord(this.state.activeInterval);
    //this.setState({update: this.state.update ? false : true});
  };

  IsNextWorkDayOff = (inactf, day, index) => {
    let res = false;

    if (inactf === false) {
      for (let i = day + 1; i < 32; i++) {
        let reddayf = DayIsRedDay('Day' + i, this.props.record.headerFields.StartDate);
        let dayres = this.ShowInactiveShiftDay2(index, 'Day' + i);
        if (!reddayf) {
          if (dayres.res) {
            res = true;
          }
          i = 32;
        }
      }
    }
    return res;
  };

  setLocalRecord = (shift) => {
    this.localrows = [];
    this.props.record.matrixRows.map((row, index) => {
      let nrow = Object.assign({}, row);
      for (let i = 1; i < 32; i++) {
        let res = this.ShowInactiveShiftDay2(index, 'Day' + i);
        let reddayf = DayIsRedDay('Day' + i, this.props.record.headerFields.StartDate);
        if (res.res) {
          // || (reddayf && this.IsNextWorkDayOff(res.res, i, index))
          nrow['Day' + i] = '2';
        } else {
          nrow['Day' + i] = ShiftInDay(row['Day' + i], shift);
        }
      }
      let testf = true;
      for (let j = 1; j < 32; j++) {
        let reddayf = DayIsRedDay('Day' + j, this.props.record.headerFields.StartDate);
        if (reddayf === false && nrow['Day' + j] == 0) {
          testf = false;
        }
      }
      if (testf) {
        nrow['LineFlag'] = '1';
      }

      this.localrows.push(nrow);
    });
  };

  render() {
    return (
      <ShiftWrapper width={this.props.width} key={JSON.stringify(this.localrows)}>
        <ShiftSwitch window={this} />
        <MatrixContainer
          data={this.localrows}
          columns={this.localcolumns}
          record={this.localrecord}
          okcheck={this.props.okcheck}
          title={this.props.title}
          rowactions={this.props.rowactions}
          width={this.props.width}
          splitrow={this.props.splitrow}
          splitrowend={this.props.splitrowend}
          pagination={this.props.record.pagination}
        />
      </ShiftWrapper>
    );
  }
}

export default ShiftContainer;
