import React, {Component, useState} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize, spacing} from '../../styles/Style';
import {
  ConvertDisplayValue,
  GetGlobalTheme,
  isDesktopScreen,
  IsHRiOS,
  IsHRWeb,
  isMobileOrTabletScreen,
  WindowElementType,
} from '@tools/displayTools';

import {DataTable} from 'react-native-paper';
import {Picker} from '@react-native-picker/picker';
import {LayoutChangeEvent, Text, View} from 'react-native';
import {translate} from '@utils/languageTools';
import {ViewMode} from '../screens/types';
import {objectHasProperty} from '@tools/objectTools';
import EditField from './EditField';
import {ActionButton} from '../button/ActionButton';
import {MatrixField} from '@utils/types';
import {fieldIsEditable, matrixIsEditable} from '@src/tools/windowTools';

const MatrixWrapper = styled.View`
  background-color: ${colours[GetGlobalTheme()].topNavigationBackground};
  display: flex;
  flex-shrink: 1;
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  /*margin-horizontal: ${spacing.small300};*/
  margin-top: ${spacing.medium200};
  padding-bottom: ${spacing.small300};
`;

const BodyContainer = styled.ScrollView`
  font-weight: 600;
  display: flex;
  overflow: visible;

  ${IsHRWeb() &&
  css`
    overflow-x: visible;
    overflow-y: visible;
  `}
  flex-shrink: 1;
  z-index: 1;
  position: relative;
`;

const StyledTitle = styled(DataTable.Title)`
  width: ${(props) => props.columnWidth};
  padding: ${spacing.space12}px ${spacing.space4}px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  ${IsHRWeb() === false &&
  css`
    padding: 0px !important;
  `}
`;

const StyledCell = styled(DataTable.Cell)`
  width: ${(props) => props.columnWidth};
  flex: 1 1 auto;
  padding: ${spacing.space12}px ${spacing.space4}px;
  min-height: 21px; /* put in Styles.tsx? */
`;

const StyledRemoveCell = styled(DataTable.Cell)`
  width: 43px;
  max-width: 43px;
  min-width: 43px;
  flex: 1 1 auto;
  padding: ${spacing.space12}px ${spacing.space4}px;
  min-height: 21px; /* put in Styles.tsx? */
`;

const StyledRowWrap = styled(DataTable.Row)`
  background-color: ${(props) => (props.rowIndex % 2 ? colours[GetGlobalTheme()].inputBackground : 'transparent')};
  font-size: ${fontSize.medium};
  min-height: 32px;
  padding: 0px;
  border-bottom: 1px solid ${colours[GetGlobalTheme()].shadowDefault};
  z-index: ${(props) => props.arrayLength - props.rowIndex};
`;
const StyledRowContainer = styled.View`
  align-items: start;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledHeader = styled(DataTable.Header)`
  background-color: ${colours[GetGlobalTheme()].grey100};
  border-bottom-width: 0px;
  padding: 0px;
`;

const InputContainer = styled.View`
  ${(IsHRWeb() &&
    css`
      width: 100%;
    `) ||
  css`
    width: ${(props) => props.columnWidth};
  `}

  flex: 1 1 100%;
`;

const PageButton = styled.TouchableOpacity``;

const ActivePageText = styled.Text`
  background-color: ${colours[GetGlobalTheme()].inputBackground};
  color: ${colours[GetGlobalTheme()].activeText};
  padding: 2px 8px;
  border-radius: 3px;
  font-size: ${fontSize.small200};
`;
const PageText = styled.Text`
  padding: 2px 8px;
  color: ${colours[GetGlobalTheme()].normalHighligh};
  font-size: ${fontSize.small200};
`;
const PaginationContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-horizontal: ${spacing.medium};
  padding-vertical: ${spacing.small300};
  justify-content: space-between;
`;
const PaginationWrap = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
const PageContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2px;
  background-color: ${colours[GetGlobalTheme()].inputBackground};
  border-color: ${colours[GetGlobalTheme()].inputBorder};
  border-radius: 3px;
`;
const PageList = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2px;
  border-radius: 3px;
  background-color: ${colours[GetGlobalTheme()].lightBackground};
`;

const PaginationPerPage = styled.View`
  margin-horizontal: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;
const PaginationPerPageText = styled.Text`
  margin-right: 15px;
  color: ${colours[GetGlobalTheme()].inputLabelText};
`;
const RowAction = styled.TouchableOpacity`
  padding: 4px;
`;

const RowActionWrap = styled.View`
  position: absolute;
  background: ${colours[GetGlobalTheme()].mainBackground}
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 6px;
  border: 1px solid ${colours[GetGlobalTheme()].inputBorder}
  border-radius: 5px;
`;
const RowActionToggle = styled.TouchableOpacity`
  position: absolute;
  top: 10px;
  left: -15px;
`;

const SelectWrap = styled.View`
  ${IsHRWeb() === false &&
  css`
    border-color: ${colours[GetGlobalTheme()].darkHighligh};
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
  `}
`;

const RemoveButtonStyle = {
  border: 'none',
  background: colours[GetGlobalTheme()].iconButtonBG,
  height: '35px',
  width: '35px',
  padding: 0,
};

const MobileRemoveButtonStyle = {
  border: 'none',
  background: colours[GetGlobalTheme()].iconButtonBG,
  height: '35px',
  width: '35px',
  padding: 0,

  position: 'absolute',
  top: -55,
  right: -12,
};

function matrixColumns(props) {
  let result = [];
  props.columns.map((column: MatrixField, index: number) => {
    if (column.display === undefined || column.display === true) {
      result.push(
        <StyledTitle
          key={index}
          textStyle={{color: colours[GetGlobalTheme()].body}}
          columnWidth={column.width}
          viewOnly={column.viewOnly}
          style={{
            ...column.style,
            ...column.cellstyle,
            display: isMobileOrTabletScreen() ? 'none' : 'flex',
          }}>
          {column.label}
        </StyledTitle>
      );
    }
  });
  if (props.viewMode === ViewMode.Edit && matrixIsEditable(props.table.activeEditFields)) {
    result.push(
      <StyledTitle
        key={props.columns.length}
        textStyle={{color: colours[GetGlobalTheme()].body}}
        columnWidth={'43px'}
        style={{maxWidth: '43px', minWidth: '43px'}}>
        {''}
      </StyledTitle>
    );
  }
  return result;
}

export class StyledRow extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      px: 0,
      py: 0,
    };
  }

  render() {
    let self = this;
    let rowWrapStyle = {};
    const mobileRowWrapStyle = {
      backgroundColor: colours[GetGlobalTheme()].contentBG,
      borderRadius: 12,
      border: 'none',
      marginBottom: 16,
      marginTop: spacing.space40,
      padding: spacing.space12,
    };

    const mobileRowContainerStyle = {
      flexDirection: 'column',
    };

    if (this.props.GetMatrixRowStyle !== undefined) {
      rowWrapStyle = this.props.GetMatrixRowStyle(this.props.rowIndex, this.props.data[this.props.rowIndex]);
    }
    return (
      <StyledRowWrap
        key={this.props.rowIndex}
        rowIndex={this.props.rowIndex}
        style={isMobileOrTabletScreen() ? mobileRowWrapStyle : rowWrapStyle}
        onLayout={(event: LayoutChangeEvent) => {
          const layout = event.nativeEvent.layout;
          self.state.py = layout.top;
          if (window.scrollY) {
            self.state.py = layout.top + window.scrollY;
          }
          self.state.px = layout.left;
        }}
        arrayLength={this.props.arrayLength}>
        {isMobileOrTabletScreen() ? (
          <StyledRowContainer style={mobileRowContainerStyle}>
            <View
              style={
                isMobileOrTabletScreen()
                  ? {
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '100%',
                    }
                  : {flexDirection: 'column', margin: 0, padding: 0, width: '100%', height: '100%'}
              }>
              {this.props.rowactions && (
                <RowActionToggle
                  onPress={() =>
                    global.setRowActions({
                      key: this.props.rowIndex,
                      posx: this.state.px,
                      posy: this.state.py,
                      data: this,
                      show: true,
                    })
                  }>
                  <Text>{'...'}</Text>
                </RowActionToggle>
              )}
              {MatrixCell(
                this.props.columns,
                this.props.data[this.props.rowIndex],
                this.props.rowIndex,
                this.props.record,
                this.props.okcheck,
                this.props.viewMode,
                this.props.table,
                this.props.activeEditFields,
                this.props.inspectWindow
              )}
            </View>
          </StyledRowContainer>
        ) : (
          <StyledRowContainer>
            {MatrixCell(
              this.props.columns,
              this.props.data[this.props.rowIndex],
              this.props.rowIndex,
              this.props.record,
              this.props.okcheck,
              this.props.viewMode,
              this.props.table,
              this.props.activeEditFields,
              this.props.inspectWindow
            )}
          </StyledRowContainer>
        )}
      </StyledRowWrap>
    );
  }
}

export function RowActions(props) {
  return (
    <RowActionWrap style={props.style}>
      {props.matrix.props.rowactions.map((action) => {
        return (
          <RowAction
            onPress={() => {
              action.action(props.matrix.props.rowIndex);
              global.setRowActions({show: false});
            }}>
            <Text>{action.name}</Text>
          </RowAction>
        );
      })}
    </RowActionWrap>
  );
}

function MatrixData(props) {
  let res = [];
  let sp = props.pagination.page * props.pagination.perpage;
  let ep = sp + props.pagination.perpage;
  let showanyf = false;
  //data.map((row, index) => {
  for (let i = sp; i < ep && i < props.data.length; i++) {
    //if (i > 0) {
    //}
    let showf = true;

    if (props.showrow !== undefined) {
      showf = props.showrow(props.data[i]);
    }

    if (showf) {
      if (props.splitrow !== undefined) {
        res.push(props.splitrow(props.data[i], props.data[i - 1], i, showanyf));
      }
      res.push(
        <StyledRow key={i} rowIndex={i} arrayLength={props.data.length} rowactions={props.rowactions} {...props} />
      );
      if (props.afterrow !== undefined) {
        res.push(props.afterrow(props.data[i], i, props.data.length, Object.assign({}, props)));
      }
      showanyf = true;
    }
  }
  if (showanyf) {
    if (props.splitrowend !== undefined) {
      res.push(props.splitrowend());
    }
  } else {
    if (props.showemptymatrix !== undefined) {
      res.push(props.showemptymatrix());
    }
  }
  if (matrixIsEditable(props.table.activeEditFields) && props.viewMode === ViewMode.Edit) {
    res.push(
      <View style={{alignItems: 'end', marginTop: spacing.space12}}>
        <ActionButton
          title={translate('AddRow')}
          style={{
            background: colours[GetGlobalTheme()].white,
            border: '1px solid' + colours[GetGlobalTheme()].borderDefault,
            flexDirection: 'row-reverse',
          }}
          textStyle={{color: colours[GetGlobalTheme()].primary}}
          iconName={'Plus'}
          iconStyle={{marginRight: '3px'}}
          iconColor={colours[GetGlobalTheme()].primary}
          onPress={() => {
            props.record.addMatrixRow();
            props.table.updateScreen();
          }}
        />
      </View>
    );
  }
  return res;
}

/*, display: 'flex'*/

//textStyle={{flex: '1 1 100%'}}

function MatrixCell(
  columns,
  row,
  rowindex,
  record,
  okcheck,
  viewMode,
  table,
  activeEditFields: ActiveEditFields,
  inspectWindow
) {
  let result = [];
  const isMobileOrTabletEditMode = isMobileOrTabletScreen() && viewMode === ViewMode.Edit;
  columns.map((column, index) => {
    if (column.display === undefined || column.display === true) {
      if (objectHasProperty(column, 'singleColumn')) {
        let res_cols = [];
        column.singleColumn.map((stack_col, index) => {
          let wrapperStyle = {...stack_col.wrapperStyle};
          let style = stack_col.style ?? {};
          if (typeof stack_col.style === 'function') {
            style = stack_col.style(viewMode);
          }

          wrapperStyle.zIndex = column.singleColumn.length + 1 - index;
          if (isMobileOrTabletEditMode) {
            style.backgroundColor = colours[GetGlobalTheme()].white;
          }

          if (viewMode === ViewMode.View) {
            wrapperStyle.flexDirection = 'column';
            wrapperStyle.padding = 0;
            style.padding = 0;
          }

          if (objectHasProperty(style, 'color') == false) {
            style.color = colours[GetGlobalTheme()].tableBody;
          }
          let editablef = fieldIsEditable(
            stack_col.viewOnly,
            stack_col.dataKey,
            record,
            okcheck,
            viewMode,
            table.activeEditFields,
            true
          );
          let val = ConvertDisplayValue(stack_col.type, row[stack_col.dataKey], row[stack_col.dataKey2]);

          res_cols.push(
            <EditField
              value={val}
              wrapperStyle={wrapperStyle}
              key={stack_col.dataKey + ':' + val}
              record={record}
              pasteSpecial={stack_col.pasteSpecial}
              name={stack_col.dataKey}
              rownr={rowindex}
              onChange={stack_col.onChange}
              onFocus={stack_col.onFocus}
              inspectWindow={inspectWindow}
              width={'100%' /*stack_col.width*/}
              editable={editablef}
              type={stack_col.windowElementType ? stack_col.windowElementType : WindowElementType.kInputTypeText}
              onClick={() => stack_col.onClick()}
              title={isMobileOrTabletScreen() && viewMode === ViewMode.Edit ? stack_col.label : null}
              inputStyle={style}
            />
          );
        });
        if (isMobileOrTabletEditMode && matrixIsEditable(table.activeEditFields)) {
          result.push(
            <ActionButton
              title={''}
              style={MobileRemoveButtonStyle}
              iconName={'Remove'}
              iconColor={colours[GetGlobalTheme()].darkBlue}
              iconSize={14}
              onPress={() => {
                record.deleteMatrixRow(rowindex);
                table.updateRecordField({rowindex: rowindex, fieldname: '', value: ''});
                table.updateScreen();
              }}
            />
          );
        }
        result.push(
          <StyledCell
            key={index}
            columnWidth={column.width}
            viewOnly={column.viewOnly}
            textStyle={{width: '100%', overflow: 'visible'}}>
            <InputContainer columnWidth={column.width} style={{...column.cellStyle, gap: spacing.space4}}>
              {res_cols}
            </InputContainer>
          </StyledCell>
        );
      } else {
        let value = ConvertDisplayValue(column.type, row[column.dataKey], row[column.dataKey2]);
        let style = column.style ?? {};
        if (typeof column.style === 'function') {
          style = column.style(viewMode);
        }
        if (isMobileOrTabletEditMode) {
          style.backgroundColor = colours[GetGlobalTheme()].white;
        }

        if (objectHasProperty(style, 'color') == false) {
          style.color = colours[GetGlobalTheme()].tableBody;
        }

        let editablef = fieldIsEditable(
          column.viewOnly,
          column.dataKey,
          record,
          okcheck,
          viewMode,
          table.activeEditFields,
          true
        );
        result.push(
          <StyledCell
            key={index}
            columnWidth={column.width}
            viewOnly={column.viewOnly}
            style={{...column.cellstyle, overflow: 'visible'}}>
            <InputContainer columnWidth={column.width}>
              <EditField
                value={value}
                wrapperStyle={{
                  ...column.wrapperStyle,
                  paddingLeft: 0,
                  flexDirection: viewMode === ViewMode.View ? 'column' : 'initial',
                  flexWrap: isMobileOrTabletEditMode ? 'wrap' : 'nowrap',
                }}
                key={column.dataKey + ':' + value}
                record={record}
                pasteSpecial={column.pasteSpecial}
                name={column.dataKey}
                onChange={column.onChange}
                rownr={rowindex}
                onFocus={column.onFocus}
                inspectWindow={inspectWindow}
                width={'100%' /*column.width*/}
                editable={editablef}
                type={column.windowElementType ? column.windowElementType : WindowElementType.kInputTypeText}
                onClick={() => column.onClick()}
                inputStyle={style}
                title={isMobileOrTabletScreen() ? column.label : null}
                labelStyle={{width: '20%', paddingRight: spacing.space8}}
              />
            </InputContainer>
          </StyledCell>
        );
      }
    }
  });
  if (isDesktopScreen() && viewMode === ViewMode.Edit && matrixIsEditable(table.activeEditFields)) {
    result.push(
      <StyledRemoveCell key={columns.length} textStyle={{width: '100%'}}>
        <InputContainer columnWidth={'35px'} style={{}}>
          <ActionButton
            title={''}
            style={RemoveButtonStyle}
            iconName={'Remove'}
            iconColor={colours[GetGlobalTheme()].darkBlue}
            iconSize={14}
            onPress={() => {
              record.deleteMatrixRow(rowindex);
              table.updateRecordField({rowindex: rowindex, fieldname: '', value: ''});
              table.updateScreen();
            }}
          />
        </InputContainer>
      </StyledRemoveCell>
    );
  }
  return result;
}

const MatrixBody = (props) => {
  const [topPos, setTopOffset] = useState(0);
  const [topScroll, setTopScrollBody] = useState(0);
  const [width, setWidth] = useState(0);

  return (
    <BodyContainer
      key={'Matrix_container'}
      onLayout={(event) => {
        const layout = event.nativeEvent.layout;
        setTopOffset(layout.top);
        setTopScrollBody(window.scrollY);
      }}>
      <DataTable primaryKey={'Matrix_body'}>
        <StyledHeader
          key={'Matrix_header'}
          style={{
            display: isMobileOrTabletScreen() ? 'none' : 'flex',
          }}
          onLayout={(event: LayoutChangeEvent) => {
            const layout = event.nativeEvent.layout;
            setWidth(layout.width);
          }}>
          {matrixColumns(props)}
        </StyledHeader>
        {MatrixData(props)}
      </DataTable>
    </BodyContainer>
  );
};

/*
        {IsHRWeb() && (
          <StyledFixedHeader toppos={topPos} width={width} initscroll={topScroll} columns={props.columns} />
        )}
*/

function ChangePaginationPage(props, page: number) {
  props.pagination.changeMatrixPage(page);
  if (props.table) {
    props.table.updateScreen();
  }
}

const PaginationList = (props) => {
  let res = [];
  let sp = props.pagination.page - 6;
  let pref = [];
  let post = [];
  if (sp < 0) {
    sp = 0;
  } else {
    pref.push(<PageText key="pg_mid">{'...'}</PageText>);
  }
  let ep = props.pagination.page + 6;
  if (ep > props.pagination.pages) {
    ep = props.pagination.pages;
  } else {
    post.push(<PageText key="pg_mid">{'...'}</PageText>);
  }
  for (let i = sp; i < ep; i++) {
    if (i === props.pagination.page) {
      res.push(
        <PageButton key={i}>
          <ActivePageText>{i + 1}</ActivePageText>
        </PageButton>
      );
    } else {
      res.push(
        <PageButton
          key={i}
          onPress={() => {
            ChangePaginationPage(props, i);
          }}>
          <PageText>{i + 1}</PageText>
        </PageButton>
      );
    }
  }
  let res2 = [];
  res2.push(
    <PageContainer key={res2.length}>
      <PageButton key={'page_first'} onPress={() => ChangePaginationPage(props, 0)}>
        <PageText>{'<<'}</PageText>
      </PageButton>
      {props.pagination.page > 0 && (
        <PageButton key={'page_back'} onPress={() => ChangePaginationPage(props, props.pagination.page - 1)}>
          <PageText>{'<'}</PageText>
        </PageButton>
      )}
      {pref}
      <PageList key={'page_list'}>{res}</PageList>
      {post}
      {props.pagination.page < props.pagination.pages - 1 && (
        <PageButton key={'page_next'} onPress={() => ChangePaginationPage(props, props.pagination.page + 1)}>
          <PageText>{'>'}</PageText>
        </PageButton>
      )}
      <PageButton key={'page_last'} onPress={() => ChangePaginationPage(props, props.pagination.pages - 1)}>
        <PageText>{'>>'}</PageText>
      </PageButton>
    </PageContainer>
  );
  return res2;
};

export const Pagination = (props) => {
  let style = {
    color: colours[GetGlobalTheme()].titleText,
    borderColor: colours[GetGlobalTheme()].inputBorder,
    borderRadius: 3,
    borderWidth: 1,
    fontSize: fontSize.small200,
  };
  if (IsHRWeb() === false) {
    style.width = 90;
    style.height = 30;
  }
  return (
    <View>
      {props.data && props.data.length > 0 && (
        <PaginationContainer noTopSpacing={props.noTopSpacing}>
          <PaginationWrap>
            {props.pagination.pages > 1 && (
              <PaginationList key={'page_list'} pagination={props.pagination} table={props.table} />
            )}
          </PaginationWrap>
          {props.pagination.showperiod !== false && (
            <PaginationPerPage>
              <PaginationPerPageText>{translate('RowsPerPage')}</PaginationPerPageText>
              <SelectWrap>
                <Picker
                  dropdownIconColor={colours[GetGlobalTheme()].titleText}
                  selectedValue={props.pagination.perpage}
                  onValueChange={(itemValue) => {
                    props.pagination.changePagePeriod(itemValue);
                    props.table.updateScreen();
                  }}>
                  <Picker.Item label="10" value="10" />
                  <Picker.Item label="20" value="20" />
                  <Picker.Item label="40" value="40" />
                  <Picker.Item label="60" value="60" />
                  <Picker.Item label="80" value="80" />
                  <Picker.Item label="100" value="100" />
                  <Picker.Item label="150" value="150" />
                  <Picker.Item label="200" value="200" />
                </Picker>
              </SelectWrap>
            </PaginationPerPage>
          )}
        </PaginationContainer>
      )}
    </View>
  );
};

export const MatrixContainer = (props) => {
  let width = '96%';
  if (IsHRWeb()) {
    width = parseInt(props.width, 10) - parseInt(spacing.small300, 10) * 2;
    if (props.minwidth) {
      width = '100%';
    }
  }
  if (props.custommatrixdata) {
    props.data = props.custommatrixdata;
  }
  if (IsHRiOS()) {
    return null;
  }

  return (
    <MatrixWrapper width={width} style={props.style}>
      <MatrixBody {...props} key={JSON.stringify(props.record.rows)} />
    </MatrixWrapper>
  );
};

/*
      <Pagination
        noTopSpacing={
          props.matrixFooterFields && props.matrixFooterFields.length > 0
        }
        pagination={props.pagination}
        data={props.data}
        table={props.table}
      />
*/
