import React, {useEffect, useState} from 'react';
import {ColumnContainer} from '@styles/primitiveComponents';
import styled from 'styled-components/native';
import {colours, iconSize, spacing} from '@styles/Style';
import {ActionButton} from '@components/button/ActionButton';
import {DrawerContentComponentProps} from '@react-navigation/drawer';
import {DoNavigate, GetGlobalTheme, getRegisterNameFromPath, isMobileOrTabletScreen} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import {getDrawers, Modules} from '@utils/drawersTools';
import {getReports, ReportType} from '@utils/ledgerTools';
import {minimizedNavigatorSize} from './sideNavigator/DrawerContentWrapper';
import {ModuleType} from '@src/utils/types';

const IconStyle = {
  order: '-1',
  width: iconSize.iconSize,
  marginRight: 5,
};

const minimizedNavigationButtonIconSize = 20;

type NavigationButtonsProps = {
  drawerProps: DrawerContentComponentProps<DrawerContentComponentProps>;
  isSideNavigatorOpen: boolean;
};
export const NavigationButtons = ({drawerProps, isSideNavigatorOpen}: NavigationButtonsProps) => {
  const [currentNavigationRoot, setCurrentNavigationRoot] = useState<ModuleType>(ModuleType.none);
  const [currentlyOpenModule, setCurrentlyOpenModule] = useState<ModuleType>(ModuleType.none);
  const [currentlyOpenRegister, setCurrentlyOpenRegister] = useState<string>('');
  const [modules] = useState<Modules>(getDrawers());
  const [reports] = useState<ReportType[]>(getReports());

  const ButtonSection = styled(ColumnContainer)<{active?: Boolean}>`
    width: 100%;
    align-items: ${isSideNavigatorOpen ? 'start' : (props) => (props.active ? 'end' : 'center')};
  `;

  const NavigationContainer = styled(ColumnContainer)`
    margin-top: ${isSideNavigatorOpen ? spacing.space4 : spacing.space24};
    margin-bottom: ${spacing.space24};
    width: 100%;
    align-items: ${isSideNavigatorOpen ? 'start' : 'center'};
    gap: ${spacing.space16}px;
    z-index: 998;
  `;

  const ModuleDropdown = styled(ColumnContainer)`
    width: ${isSideNavigatorOpen ? '100%' : ''};
    gap: ${spacing.space4};
    position: ${isSideNavigatorOpen ? '' : 'absolute'};
    background-color: ${colours[GetGlobalTheme()].activeDark};
    left: ${isSideNavigatorOpen ? '0' : minimizedNavigatorSize + 'px'};
    border-top-left-radius: 0;
    border-top-right-radius: ${isSideNavigatorOpen ? 0 : 12}px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  `;

  const SalesManagementDropdown = styled(ModuleDropdown)`
    display: ${currentlyOpenModule === ModuleType.sales ? 'flex' : 'none'};
  `;

  const HRDropdown = styled(ModuleDropdown)`
    display: ${currentlyOpenModule === ModuleType.hr ? 'flex' : 'none'};
  `;

  const PurchaseInvoicesDropdown = styled(ModuleDropdown)`
    display: ${currentlyOpenModule === ModuleType.purchases ? 'flex' : 'none'};
  `;

  const StockDropdown = styled(ModuleDropdown)`
    display: ${currentlyOpenModule === ModuleType.stock ? 'flex' : 'none'};
  `;

  const ReportsDropdown = styled(ModuleDropdown)`
    display: ${currentlyOpenModule === ModuleType.reports ? 'flex' : 'none'};
  `;

  useEffect(() => {
    if (!isSideNavigatorOpen) {
      toggleModule(ModuleType.none);
    }
    if (isMobileOrTabletScreen()) {
      initializeMobileNavigationData();
    }
  }, [isSideNavigatorOpen]);

  global.setInitialNavigationData = () => {
    initializeNavigationData();
  };

  function initializeMobileNavigationData() {
    const registerName = getRegisterNameFromPath();
    const moduleName = getRootModuleOfRegister(registerName);

    if (!registerName || !moduleName) {
      return;
    }

    const moduleEnumValue: ModuleType = ModuleType[moduleName];
    setCurrentlyOpenRegister(registerName.toLowerCase());
    setCurrentNavigationRoot(moduleEnumValue);
  }

  function initializeNavigationData() {
    const registerName = getRegisterNameFromPath();
    const moduleName = getRootModuleOfRegister(registerName);

    if (!registerName || !moduleName) {
      return;
    }

    const moduleEnumValue: ModuleType = ModuleType[moduleName];
    setCurrentlyOpenRegister(registerName.toLowerCase());
    setCurrentlyOpenModule(moduleEnumValue);
    setCurrentNavigationRoot(moduleEnumValue);
  }

  function getRootModuleOfRegister(registerName: string) {
    let moduleName = '';
    Object.keys(modules).forEach((module) => {
      modules[module].forEach((obj) => {
        if (obj.name.toLowerCase() === registerName) {
          moduleName = module;
        }
      });
    });

    return moduleName;
  }

  function getNavigationRegisterButtonStyle(name: string) {
    return {
      padding: '10px 12px',
      justifyContent: 'start',
      width: isSideNavigatorOpen ? '' : '100%',
      backgroundColor:
        currentlyOpenRegister === name ? colours[GetGlobalTheme()].active : colours[GetGlobalTheme()].activeDark,
    };
  }

  function getRegisterButton(title: string, name: string, module: ModuleType) {
    const registerName = name.toLowerCase();
    return (
      <ActionButton
        onPress={() => {
          setCurrentNavigationRoot(module);
          setCurrentlyOpenRegister(registerName);
          handleNavigate(name);
        }}
        title={title}
        style={getNavigationRegisterButtonStyle(registerName)}
        textStyle={{color: colours[GetGlobalTheme()].white}}
      />
    );
  }

  function getReportsButton(name: string, window, fields, title: string) {
    return (
      <ActionButton
        onPress={() => {
          global.showReport({
            show: true,
            type: 'inline',
            repname: name,
            reportwindow: window,
            fields: fields,
            reptitle: title,
          });
        }}
        title={title}
        style={getNavigationRegisterButtonStyle(name)}
        textStyle={{color: colours[GetGlobalTheme()].white}}
        iconSize={minimizedNavigationButtonIconSize}
      />
    );
  }

  function getModuleButtonBackgroundColor(module: ModuleType, canBeOpened: boolean) {
    let backgroundColor = '';
    if (module === currentlyOpenModule) {
      backgroundColor = canBeOpened ? colours[GetGlobalTheme()].activeDark : colours[GetGlobalTheme()].active;
    } else if (module === currentNavigationRoot) {
      backgroundColor = colours[GetGlobalTheme()].active;
    }
    return backgroundColor;
  }

  function getModuleButtonWidth(isModuleOpen) {
    let width = '';
    if (isSideNavigatorOpen) {
      width = '100%';
    } else {
      width = isModuleOpen ? '90%' : '80%';
    }
    return width;
  }

  function getModuleButtonStyle(module: ModuleType, canBeOpened: boolean) {
    const isModuleOpen = currentlyOpenModule === module && canBeOpened;
    const isOpenInMaximizedView = isModuleOpen && isSideNavigatorOpen;
    const isOpenInMinimizedView = isModuleOpen && canBeOpened && !isSideNavigatorOpen;
    const topBorderRadius = 12;
    const bottomBorderRadius = isOpenInMaximizedView ? 0 : 12;

    let style: React.CSSProperties = {
      borderTopLeftRadius: topBorderRadius,
      borderBottomLeftRadius: bottomBorderRadius,
      width: getModuleButtonWidth(isModuleOpen),
      backgroundColor: getModuleButtonBackgroundColor(module, canBeOpened),
    };
    if (isSideNavigatorOpen) {
      style.flexDirection = 'row';
      style.padding = '10px 12px';
      style.height = 'initial';
      style.justifyContent = 'flex-start';
    } else {
      style.flexDirection = 'column-reverse';
      style.padding = '12px';
      if (isModuleOpen) {
        style.paddingRight = 'calc(10% + 12px)';
      }
      style.height = '48px';
      style.justifyContent = 'center';
    }
    if (!isOpenInMinimizedView) {
      style.borderTopRightRadius = topBorderRadius;
      style.borderBottomRightRadius = bottomBorderRadius;
    } else {
      style.borderTopRightRadius = 0;
      style.borderBottomRightRadius = 0;
    }
    return style;
  }

  function getDropdownModuleButton(module: ModuleType, name: string, iconName: string) {
    return (
      <ActionButton
        onPress={() => toggleModule(module)}
        title={translate(name)}
        iconName={'DropdownIcon'}
        iconStyle={{
          display: isSideNavigatorOpen ? 'flex' : 'none',
          transform: currentlyOpenModule === module ? 'rotate(180deg)' : 'rotate(0)',
        }}
        secondIconName={iconName}
        secondIconSize={iconSize.iconSize}
        secondIconStyle={IconStyle}
        style={getModuleButtonStyle(module, true)}
        textStyle={{color: colours[GetGlobalTheme()].white}}
      />
    );
  }

  function getDirectModuleButton(module: ModuleType, name: string, iconName: string) {
    return (
      <ActionButton
        onPress={() => {
          setCurrentlyOpenRegister(ModuleType.none);
          setCurrentNavigationRoot(module);
          toggleModule(module);
          handleNavigate(name);
        }}
        iconName={iconName}
        title={translate(name)}
        iconSize={iconSize.iconSize}
        iconStyle={IconStyle}
        style={getModuleButtonStyle(module, false)}
        textStyle={{color: colours[GetGlobalTheme()].white}}
      />
    );
  }

  function handleNavigate(route: string) {
    if (!isSideNavigatorOpen) {
      toggleModule(ModuleType.none);
    }
    if (isMobileOrTabletScreen()) {
      global.toggleDrawer();
    }
    setNavigation(route);
  }

  function setNavigation(route) {
    DoNavigate(drawerProps, route, false, '', true, true);
  }

  function toggleModule(module: ModuleType) {
    if (currentlyOpenModule === module) {
      setCurrentlyOpenModule(ModuleType.none);
      return;
    }
    setCurrentlyOpenModule(module);
  }

  function getOpenDrawerNavigation() {
    return (
      <>
        {modules.tasks.length > 0 && <>{getDirectModuleButton(ModuleType.tasks, 'Tasks', 'TasksMinimizedIcon')}</>}
        {modules.sales.length > 0 && (
          <ButtonSection>
            {getDropdownModuleButton(ModuleType.sales, 'SalesManagement', 'SalesMinimizedIcon')}
            <SalesManagementDropdown>
              {modules.sales.map(({title, name}) => getRegisterButton(title, name, ModuleType.sales))}
            </SalesManagementDropdown>
          </ButtonSection>
        )}

        {modules.hr.length > 0 && (
          <ButtonSection>
            {getDropdownModuleButton(ModuleType.hr, 'HumanResources', 'HRMinimizedIcon')}
            <HRDropdown>{modules.hr.map(({title, name}) => getRegisterButton(title, name, ModuleType.hr))}</HRDropdown>
          </ButtonSection>
        )}

        {modules.purchases.length > 0 && (
          <ButtonSection>
            {getDropdownModuleButton(ModuleType.purchases, 'Purchases', 'PurchasesMinimizedIcon')}
            <PurchaseInvoicesDropdown>
              {modules.purchases.map(({title, name}) => getRegisterButton(title, name, ModuleType.purchases))}
            </PurchaseInvoicesDropdown>
          </ButtonSection>
        )}

        {modules.stock.length > 0 && (
          <ButtonSection>
            {getDropdownModuleButton(ModuleType.stock, 'Stock', 'StockMinimizedIcon')}
            <StockDropdown>
              {modules.stock.map(({title, name}) => getRegisterButton(title, name, ModuleType.stock))}
            </StockDropdown>
          </ButtonSection>
        )}
        {reports.length > 0 && (
          <ButtonSection>
            {getDropdownModuleButton(ModuleType.reports, 'Reports', 'ReportsMinimizedIcon')}
            <ReportsDropdown>
              {reports.map(({reportName, reportWindow, fields, reportTitle}) =>
                getReportsButton(reportName, reportWindow, fields, reportTitle)
              )}
            </ReportsDropdown>
          </ButtonSection>
        )}
      </>
    );
  }

  function getClosedDrawerNavigation() {
    return (
      <NavigationContainer>
        {' '}
        {modules.tasks.length > 0 && <>{getDirectModuleButton(ModuleType.tasks, 'Tasks', 'TasksMinimizedIcon')}</>}
        {modules.sales.length > 0 && (
          <ButtonSection style={{zIndex: 8}} active={currentlyOpenModule === ModuleType.sales}>
            {getDropdownModuleButton(ModuleType.sales, 'Sales', 'SalesMinimizedIcon')}
            <SalesManagementDropdown>
              {modules.sales.map(({title, name}) => getRegisterButton(title, name, ModuleType.sales))}
            </SalesManagementDropdown>
          </ButtonSection>
        )}
        {modules.hr.length > 0 && (
          <ButtonSection style={{zIndex: 7}} active={currentlyOpenModule === ModuleType.hr}>
            {getDropdownModuleButton(ModuleType.hr, 'HR', 'HRMinimizedIcon')}
            <HRDropdown>{modules.hr.map(({title, name}) => getRegisterButton(title, name, ModuleType.hr))}</HRDropdown>
          </ButtonSection>
        )}
        {modules.purchases.length > 0 && (
          <ButtonSection style={{zIndex: 6}} active={currentlyOpenModule === ModuleType.purchases}>
            {getDropdownModuleButton(ModuleType.purchases, 'Purchases', 'PurchasesMinimizedIcon')}
            <PurchaseInvoicesDropdown>
              {modules.purchases.map(({title, name}) => getRegisterButton(title, name, ModuleType.purchases))}
            </PurchaseInvoicesDropdown>
          </ButtonSection>
        )}
        {modules.stock.length > 0 && (
          <ButtonSection style={{zIndex: 5}} active={currentlyOpenModule === ModuleType.stock}>
            {getDropdownModuleButton(ModuleType.stock, 'Stock', 'StockMinimizedIcon')}
            <StockDropdown>
              {modules.stock.map(({title, name}) => getRegisterButton(title, name, ModuleType.stock))}
            </StockDropdown>
          </ButtonSection>
        )}
        {reports.length > 0 && (
          <ButtonSection style={{zIndex: 4}} active={currentlyOpenModule === ModuleType.reports}>
            {getDropdownModuleButton(ModuleType.reports, 'Reports', 'ReportsMinimizedIcon')}
            <ReportsDropdown>
              {reports.map(({reportName, reportWindow, fields, reportTitle}) =>
                getReportsButton(reportName, reportWindow, fields, reportTitle)
              )}
            </ReportsDropdown>
          </ButtonSection>
        )}
      </NavigationContainer>
    );
  }

  return (
    <NavigationContainer>
      {isSideNavigatorOpen ? getOpenDrawerNavigation() : getClosedDrawerNavigation()}
    </NavigationContainer>
  );
};
