import React from 'react';
import {Component} from 'react';
import styled from 'styled-components/native';
import {DefaultIconButton} from '../button/DefaultIconButton';

const Wrap = styled.View``;

export default class InlineProfileIcon extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      py: 0,
      px: 0,
    };
  }

  ShowUserProfile = () => {
    global.setProfileInfo({
      show: true,
      empcode: this.props.empcode,
      posx: this.state.px,
      posy: this.state.py,
      style: {},
    });
  };
  render() {
    let self = this;
    return (
      <Wrap
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.state.py = layout.top + 30;
          self.state.px = layout.left;
        }}>
        <DefaultIconButton
          iconName="info"
          mode="text"
          size={14}
          style={{
            padding: 0,
            width: 'auto !important',
            height: 'auto !important',
          }}
          iconType="FA5"
          action={this.ShowUserProfile}
        />
      </Wrap>
    );
  }
}
