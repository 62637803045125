import React from 'react';
import {colours, spacing} from '../../styles/Style';
import {GetGlobalTheme, IsHRWeb} from '../../Tools/DisplayTools';
import styled, {css} from 'styled-components/native';
import Requests from '../../api/Requests';
import DropdownSelect from '../edit_elements/DropdownSelect';
import {logError} from '@utils/debug';

const CompanyWrap = styled.View`
  margin-horizontal: ${spacing.medium};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CompanyName = styled.Text`
  color: ${colours[GetGlobalTheme()].rowText};
  font-family: 'Open Sans';
  width: 224px;
  ${IsHRWeb() &&
  css`
    text-align: right;
  `}
`;

const CompanySelect = (props) => {
  return (
    <CompanyWrap>
      {props.userData.complist.length > 1 && (
        <DropdownSelect
          value={props.userData.selectedcomp}
          data={props.userData.complist}
          width={props.width || 224}
          action={(itemValue) => {
            Requests.switchCompany(itemValue)
              .then(() => {
                global.refreshScreen(true);
              })
              .catch((error) => {
                logError(error);
              });
          }}
        />
      )}
      {props.userData.complist.length === 1 && (
        <CompanyName>{props.userData.complist[0].name}</CompanyName>
      )}
    </CompanyWrap>
  );
};

export default CompanySelect;
