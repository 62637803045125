import {isDesktopScreen} from '@src/tools/displayTools';
import {objectHasProperty} from '@src/tools/objectTools';

declare global {
  interface String {
    split2(ch: string): string[];
    shorten(length: number, suffix: string): string;
  }
}

export function DefineStringProrotypes() {
  String.prototype.split2 = function (ch: string): string[] {
    return !this ? [] : this.split(ch);
  };

  String.prototype.shorten = function (maxLength: number, suffix: string): string {
    if (this.length <= maxLength) {
      return this;
    }
    return this.substring(0, maxLength) + suffix;
  };
}

export function InitGlobalValues() {
  global.pasteSpecials = {};
  global.duringOpen = false;
  global.pasteSpecialsVector = {};
  global.holidays = {};
  global.resetScreen = false;
  global.shiftBlock = {};
  global.holidaysvector = {};
  global.transferredDays = {};
  global.escapeactions = [];
  global.keyactions = {};
  global.scrollactions = [];
  global.transferredDays2 = {};
  global.empInfoList = {};
  global.customfields = {};
  global.activeEditMode = false;
  global.custompastespecials = {};
  global.userData = {
    deplist: '',
    complist: [],
    selectedcomp: '',
    empname: '',
    provider: 0,
    supported: [''],
    apiversion: 0,
    initials: '',
    iconcolour: '',
  };
  global.windowTile = '';
  global.windowNewButton = [];
  global.setUserDetails = null;
  global.testLoginCredentials = false;
  global.windows = {list: {}, curwindow: ''};
  global.windowStates = {};
  global.loadingspec = 0;
  global.loadedspec = 0;
  global.isDrawerOpen = isDesktopScreen();

  global.PushEscapeActions = (action) => {
    global.escapeactions.splice(0, 0, action);
  };
  global.PopEscapeActions = () => {
    global.escapeactions.splice(0, 1);
  };
  global.ClearEscapeActions = () => {
    global.escapeactions.map((action) => {
      action();
    });
    global.escapeactions = [];
  };

  global.PushKeyActions = (key, action) => {
    if (objectHasProperty(global.keyactions, key) === false) {
      global.keyactions[key] = [];
    }
    global.keyactions[key].splice(0, 0, action);
  };
  global.PopKeyActions = (key) => {
    global.keyactions[key].splice(0, 1);
  };

  global.HandleKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        if (global.escapeactions.length > 0) {
          global.escapeactions[0](e);
        }
        break;
      default:
        if (objectHasProperty(global.keyactions, e.key)) {
          if (global.keyactions[e.key].length > 0) {
            global.keyactions[e.key][0](e);
          }
        }
    }
  };
}
