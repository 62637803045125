import React from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  DisplayBrowseOKFlag,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {YesNoWindow} from '../status/YesNoWindow';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {InputChangeEventProps, TextField, WindowDefinition, WindowFieldLine, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const kDefPerPage = 20;
const Drawer = createDrawerNavigator();

function headerFields(table) {
  let apprf = table.ApprovalStatus === '3';
  let empfilter: TextField;
  if (table.record) {
    if (table.record.headerFields.FilterType === undefined) {
      table.record.headerFields.FilterType = '0';
    }
    switch (table.record.headerFields.FilterType) {
      case '1':
        empfilter = {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'FilterString',
          viewOnly: apprf,
          width: '300px',
          pasteSpecial: {
            vcName: 7,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'DepCode',
                mainKey: 'DepCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Name',
              },
            ],
          },
          onChange: table.updateRecordField,
        };
        break;
      case '2':
        empfilter = {
          label: translate('Department'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'FilterString',
          viewOnly: apprf,
          width: '300px',
          pasteSpecial: {
            vcName: 11,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'PostCode',
                mainKey: 'PostCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'PostName',
              },
            ],
          },
          onChange: table.updateRecordField,
        };
        break;
      default:
      case '0':
        empfilter = {
          label: translate('Employees'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'EmpCodeRow',
          viewOnly: apprf,
          matrixField: true,
          width: '700px',
          pasteSpecial: {
            vcName: 0,
            multiValue: true,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'empcode',
                mainKey: 'EmpCode',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'name',
              },
            ],
          },
          onChange: table.updateRecordFieldMatrix,
        };
    }
  }

  let windowDefinition: WindowDefinition = [
    {
      fields: [
        {
          label: translate('Filter'),
          dataKey: 'FilterType',
          viewOnly: apprf,
          windowElementType: WindowElementType.kInputTypeText,
          width: '300px',
          pasteSpecial: {
            vcName: 9992,
            fields: ['code', 'comment'],
            hideComment: true,
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [empfilter],
    },
    {
      fields: [
        {
          label: translate('SerNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'SerNr',
          viewOnly: true,
          width: '150px',
        },
        {
          label: translate('Date'),
          windowElementType: WindowElementType.kInputTypeDate,
          dataKey: 'TransDate',
          viewOnly: false,
          width: '150px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('StartDate'),
          dataKey: 'StartDate',
          viewOnly: false,
          width: '150px',
          windowElementType: WindowElementType.kInputTypeDate,
          onChange: table.updateRecordField,
        },
        {
          label: translate('EndDate'),
          dataKey: 'EndDate',
          viewOnly: false,
          width: '150px',
          windowElementType: WindowElementType.kInputTypeDate,
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Type'),
          dataKey: 'OrdType',
          viewOnly: false,
          width: '500px',
          pasteSpecial: {
            vcName: 8,
            fields: ['OrdType', 'Comment'],
          },
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Header'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Header',
          viewOnly: false,
          width: '500px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Text'),
          dataKey: 'Math',
          viewOnly: false,
          windowElementType: WindowElementType.kInputTypeLongText,
          width: '500px',
          style: {height: 200},
          onChange: table.updateRecordField,
        },
      ],
    },
  ];

  if (table.CanUseApprovalRules() === 1) {
    let field_line: WindowFieldLine = {
      fields: [
        {
          label: translate('ApprovalStatus'),
          fixedValue: table.ApprovalStatusText,
          viewOnly: true,
          width: '200px',
        },
      ],
    };
    switch (table.ApprovalStatus) {
      case '1':
      case '2':
      case '5':
        field_line.fields.push({
          label: translate('RequestApproval'),
          onPress: table.RequestApproval,
          width: '200px',
          windowElementType: WindowElementType.kInputTypeButton,
        });

        break;
      case '3':
        if (table.cancancel) {
          field_line.fields.push({
            label: translate('CancelApproval'),
            onPress: table.cancelApproval,
            width: '150px',
            windowElementType: WindowElementType.kInputTypeButton,
          });
        }
    }
    windowDefinition.splice(2, 0, field_line);
  }

  return windowDefinition;
}

interface OrderListState extends ListScreenState {
  id: string;
  px: number;
  py: number;
  newOrderHelper: boolean;
  NewEmp: string;
  NewOrderType: string;
}

export class OrdersList extends ListScreen<{}, OrderListState> {
  constructor(props) {
    super(props);
    this.createNewOrder = this.createNewOrder.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    this.table = 2;
    this.ScreenName = 'OrderDocs';
    this.init = true;
    this.state = {
      ...this.state,
      data: [],
      id: '',
      px: 0,
      py: 0,
      newOrderHelper: false,
      NewEmp: global.userData.deplist !== '' ? '' : global.userData.empcode,
      NewOrderType: '',
    };
    this.navigation = props.navigation;
    SetCurrentWindow2('OrderDocs', 'SerNr', 'ascending');
    AddDateFilter('OrderDocs');
  }

  createNewOrder = () => {
    let self = this;
    if (this.state.NewOrderType === '' || this.state.NewEmp === '') {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    let tdata = {
      record: {
        OrdType: this.state.NewOrderType,
        rows: [
          {
            EmpCodeRow: this.state.NewEmp,
          },
        ],
      },
    };
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        self.state.NewEmp = '';
        self.state.NewOrderType = '';
        self.ShowHideNewOrder();
        if (UsePagination() === false) {
          self.getBrowseListData();
        }
        self.rowClick(response.record.SerNr);
      })
      .catch((error) => {
        logError(error);
      });
  };
  ShowHideNewOrder = () => {
    if (this.state.newOrderHelper) {
      global.PopEscapeActions();
      this.setState({newOrderHelper: false});
    } else {
      this.setState({newOrderHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewOrder());
    }
  };
  renderNewButton = () => {
    let self = this;
    let style = {};
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'orderdoc_new'}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        {(global.userData.deplist !== '' || global.userData.deplist === '') && (
          <ActionButton onPress={() => this.ShowHideNewOrder()} title={translate('AddNew')} />
        )}
        {this.state.newOrderHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('Type') + '*'}
                    value={this.state.NewOrderType}
                    pasteSpecial={{
                      vcName: 8,
                      fields: ['OrdType', 'Comment'],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewOrderType: value})}
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('Employee') + '*'}
                    value={this.state.NewEmp}
                    pasteSpecial={{
                      vcName: 0,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'empcode',
                          mainkey: 'EmpCode',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: 'name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewEmp: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewOrder()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewOrder()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={[
            {
              name: translate('SerNr'),
              key: 'sernr',
              sortKey: 'SerNr',
              width: '100px',
            },
            {
              name: translate('Code'),
              key: 'empcode',
              sortKey: 'EmpCode',
              width: '100px',
            },
            {
              name: translate('Name'),
              key: 'name',
              sortKey: 'Name',
              width: '200px',
            },
            {
              name: translate('Header'),
              key: 'header',
              sortKey: 'Header',
              width: '300px',
            },
            {
              name: translate('StartDate'),
              key: 'startdate',
              sortKey: 'StartDate',
              width: '150px',
              windowElementType: WindowElementType.kInputTypeDate,
            },
            {
              name: translate('EndDate'),
              key: 'enddate',
              sortKey: 'EndDate',
              windowElementType: WindowElementType.kInputTypeDate,
              width: '150px',
            },
            {
              name: translate('Type'),
              key: 'ordtype',
              sortKey: 'OrdType',
              width: '100px',
            },
            {
              name: translate('ApprovedShort'),
              key: 'okflag',
              sortKey: 'OKFlag',
              width: '70px',
              overwrite: DisplayBrowseOKFlag,
            },
          ]}
          doClick={self.rowClick}
          rowID={'sernr'}
          title={translate(this.ScreenName)}
          width="900px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface OrderRecordState extends RecordScreenState {
  data: [];
  hasapprovals: number;
}

export class OrdersRecord extends RecordScreen<{}, OrderRecordState> {
  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.updateRecordFieldMatrix = this.updateRecordFieldMatrix.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.cancelApproval = this.cancelApproval.bind(this);
    this.RequestApproval = this.RequestApproval.bind(this);
    this.table = 2;
    this.ScreenName = 'OrderDocs';
    this.state = {
      ...this.state,
      data: [],
    };
    this.record = new WindowRecord(this.table, true, headerFields(this));
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  updateRecordFieldMatrix = ({value}: InputChangeEventProps) => {
    let self = this;
    let addlist = [];
    let fieldlist = [];
    let remlist = [];

    let valarr = value.split(',');

    valarr.map((field) => {
      let foundf = false;
      for (let i = 0; i < this.record.matrixRows.length; i++) {
        let id = this.record.matrixRows[i].EmpCodeRow;
        if (field === id) {
          foundf = true;
          i = this.record.matrixRows;
        }
      }
      if (foundf === false) {
        addlist.push(field);
      }
    });
    for (let i = 0; i < this.record.matrixRows.length; i++) {
      let id = this.record.matrixRows[i].EmpCodeRow;
      if (valarr.indexOf(id) < 0) {
        remlist.push(i);
      }
    }
    let totcnt = 0;
    let curcnt = 0;
    let rwcnt = this.record.matrixRows.length;
    for (let i = 0; i < addlist.length; i++) {
      fieldlist.push({
        field: 'EmpCodeRow',
        rownr: rwcnt,
        value: addlist[i],
      });
      rwcnt++;
    }
    if (fieldlist.length > 0) {
      totcnt++;
    }
    totcnt += remlist.length;
    if (fieldlist.length > 0) {
      Requests.updateRecordData(this.table, {
        list: fieldlist,
        id: this.id,
      })
        .then((response) => {
          if (response.success) {
            curcnt++;
            if (totcnt === curcnt) {
              self.record.applyNewValues(response.record);
              self.updateScreen();
            }
          }
        })
        .catch((error) => {
          logError(error);
        });
    }

    for (let i = remlist.length - 1; i >= 0; i--) {
      Requests.deleteRecordRow(this.table, this.id + ':' + remlist[i])
        .then((response) => {
          if (self._isMounted) {
            if (response.success) {
              curcnt++;
              if (totcnt === curcnt) {
                self.rowClick(this.id);
              }
            }
          }
        })
        .catch((error) => {
          logError(error);
        });
    }
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <InspectWindow
          title={translate('Order')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          onClick={this.handleInputClick}
          allowDelete={true}
          allowPrint={IsHRWeb()}
          hasApprovals={this.state.hasapprovals === 1}
          backButton={() => goBack(self, self.ScreenName)}
        />

        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

function OrderDocs() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="OrderDocsList"
        component={OrdersList}
        options={{
          title: translate('OrderDocs'),
        }}
      />
      <Drawer.Screen
        name="OrderDocsRecord"
        component={OrdersRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('Order'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default OrderDocs;
