import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {ActionButton} from '../button/ActionButton';
import {TableContainer} from '../table/Table';
import {translate} from '@utils/languageTools';
import {
  DisplayBrowseOKFlag,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  IsHRWeb,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {colours} from '../../styles/Style';
import {ListWindowColumn, OverwriteBrowseColumnProps} from '@utils/types';
import {ListScreenState} from '@components/screens/types';
import {ListScreen} from '@src/types/Record/listScreen';

const Drawer = createDrawerNavigator();
const GetAbsenceColumns_LAT = (): ListWindowColumn[] => [
  {
    name: translate('Nr'),
    key: 'sernr',
    sortKey: 'SerNr',
    width: '100px',
  },
  {
    name: translate('ContractNr'),
    key: 'contractnr',
    sortKey: 'ContractNr',
    width: '100px',
  },
  {
    name: translate('Code'),
    key: 'empcode',
    sortKey: 'EmpCode',
    width: '100px',
  },
  {
    name: translate('Name'),
    key: 'empname',
    sortKey: 'EmpName',
    width: '300px',
  },
  {
    name: translate('StartDate'),
    key: 'startdate',
    sortKey: 'StartDate',
    width: '115px',
    windowElementType: WindowElementType.kInputTypeDate,
  },
  {
    name: translate('EndDate'),
    key: 'enddate',
    sortKey: 'EndDate',
    width: '115px',
    windowElementType: WindowElementType.kInputTypeDate,
  },
  {
    name: translate('Date'),
    key: 'transdate',
    sortKey: 'TransDate',
    width: '115px',
    windowElementType: WindowElementType.kInputTypeDate,
  },
  {
    name: translate('OffWorkGroup'),
    key: 'group',
    sortKey: 'Group',
    width: '100px',
    maxWidth: '100px',
  },
  {
    name: translate('ApprovedShort'),
    key: 'okflag',
    sortKey: 'OKFlag',
    width: '60px',
    maxWidth: '60px',
    overwrite: DisplayBrowseOKFlag,
  },
];
const GetAbsenceColumns_EST = () => {
  let listWindowColumns: ListWindowColumn[] = [];
  listWindowColumns = [
    {
      name: translate('Nr'),
      key: 'sernr',
      sortKey: 'SerNr',
      width: '100px',
    },
    {
      name: translate('ContractNr'),
      key: 'contractnr',
      sortKey: 'ContractNr',
      width: '100px',
    },
    {
      name: translate('Code'),
      key: 'emplcode',
      sortKey: 'EmplCode',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'firstname',
      sortKey: 'FirstName',
      key2: 'surname',
      windowElementType: WindowElementType.kInputTypeJointField,
      width: '300px',
    },
    {
      name: translate('StartDate'),
      key: 'startdate',
      sortKey: 'StartDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('EndDate'),
      key: 'enddate',
      sortKey: 'EndDate',
      width: '115px',
      windowElementType: WindowElementType.kInputTypeDate,
    },
    {
      name: translate('OffWorkGroup'),
      key: 'abstypecode',
      sortKey: 'AbsTypeCode',
      width: '150px',
    },
  ];
  return listWindowColumns;
};

export class AbsencesList extends ListScreen<{}, ListScreenState> {
  constructor(props) {
    super(props);
    this.navigation = props.navigation;
    this.table = 3;
    this.ScreenName = 'Absences';
    this.init = true;
    this.state = {
      ...this.state,
      data: [],
    };
    SetCurrentWindow2('Absences', 'SerNr', 'descending');
    AddDateFilter('Absences');
  }

  displayPrintOffWorkCalc = ({id}: OverwriteBrowseColumnProps) => {
    if (IsHRWeb()) {
      return (
        <ActionButton
          onPress={() =>
            global.showReport({
              show: true,
              type: 'inline',
              repname: 'EPOffWorkCalcRn',
              reportwindow: 'EPOffWorkCalcRClass',
              fields: {f1: id},
              reptitle: 'Prombūtnes kavējuma aprēķins',
            })
          }
          title={translate('OffWorkCalculation')}
        />
      );
    } else {
      return (
        <TouchableOpacity
          onPress={() =>
            global.showReport({
              show: true,
              type: 'inline',
              repname: 'EPOffWorkCalcRn',
              reportwindow: 'EPOffWorkCalcRClass',
              fields: {f1: id},
              reptitle: 'Prombūtnes kavējuma aprēķins',
            })
          }>
          <Text style={{color: colours[GetGlobalTheme()].titleText}}>{translate('OffWorkCalculation')}</Text>
        </TouchableOpacity>
      );
    }
  };

  render() {
    let w = '1050px';
    let columns = [];
    let self = this;
    switch (global.userData.provider) {
      case 1:
        columns = GetAbsenceColumns_EST();
        break;
      default:
        columns = GetAbsenceColumns_LAT();
        if (global.userData.apiversion >= 2.00001) {
          columns.push({
            title: '',
            dataKey: '',
            overwrite: self.displayPrintOffWorkCalc,
            width: '125px',
            minwidth: '125px',
          });
          w = '1100px';
        }
    }
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={columns}
          title={translate('Absences')}
          width={w}
          rowID="sernr"
          // TODO: Put extraContent here
        />
      </WindowWrap>
    );
  }
}

function Absences() {
  return (
    <Drawer.Navigator
      // TODO: Check if breaks everything, previously "style={{background: 'white'}}"
      // TODO: Check if breaks everything, previously "screenOptions={{headerShown: false}}"
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="AbsencesList"
        component={AbsencesList}
        options={{
          title: translate('Absences'),
        }}
      />
    </Drawer.Navigator>
  );
}

export default Absences;
