import {BrowseColumn} from '@components/screens/types';
import {DoNavigate, isMobileOrTabletScreen} from '@tools/displayTools';
import {translate} from '@utils/languageTools';
import {MobileBrowseColumnDisplayType, RecordListWindowDefinition} from '@src/types/Record/windowDefinitions';
import {getMobileDisplayCustomerAndSupplier, getMobileDisplayCustomerInfo, getMobileDisplayPerson} from '@src/tools/recordListDisplayTools';
import { OverwriteBrowseColumnProps } from '@src/utils/types';

export class ContactsRecordListDefinition extends RecordListWindowDefinition {
  window;

  constructor(window) {
    super();
    this.window = window;
  }

  copyRecord(id) {
    DoNavigate(this.window, 'contacts', true, 'new-' + id);
  }

  getBrowseColumns(): BrowseColumn[] {
    if (isMobileOrTabletScreen()) {
      return this.getMobileBrowseColumns();
    }

    return this.getDesktopBrowseColumns();
  }

  getDesktopBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('Code'),
        key: 'code',
        sortKey: 'Code',
        width: '100px',
      },
      {
        name: translate('Name'),
        key: 'name',
        sortKey: 'Name',
        width: '100px',
      },
      {
        name: translate('RegNr'),
        key: 'regnr1',
        sortKey: 'RegNr1',
        width: '100px',
      },
    ];
    return browseColumns;
  }

  getMobileBrowseColumns(): BrowseColumn[] {
    const browseColumns: BrowseColumn[] = [
      {
        name: translate('Name'),
        key: 'name',
        sortKey: 'Name',
        alignment: 'left',
        width: '151px',
        overwrite: (props: OverwriteBrowseColumnProps) => getMobileDisplayCustomerInfo(props, 'code'),
        mobileDisplayType: MobileBrowseColumnDisplayType.title,
      },
      {
        name: translate('PrimaryContact'),
        key: 'person',
        sortKey: 'Person',
        alignment: 'left',
        width: '140px',
        overwrite: getMobileDisplayPerson,
        mobileDisplayType: MobileBrowseColumnDisplayType.underTitle,
      },
      {
        name: translate('ClientAndSupplier'),
        key: 'cutype',
        sortKey: 'Cutype',
        alignment: 'left',
        width: '140px',
        overwrite: getMobileDisplayCustomerAndSupplier,
        mobileDisplayType: MobileBrowseColumnDisplayType.rightTitle,
      },
    ];
    return browseColumns;
  }
}
