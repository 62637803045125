import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours, fontSize} from '../../styles/Style';
import {GetGlobalTheme, IsHRiOS, IsHRWeb} from '../../Tools/DisplayTools';
import {Picker} from '@react-native-picker/picker';
import {translate} from '@utils/languageTools';
import {ModalSelectorEXC as ModalSelector} from '../../Tools/Stubs';

const SelectWrap = styled.View`
  ${(props) =>
    (props.editable || props.editable === undefined) &&
    IsHRWeb() === false &&
    css`
      border: 1px solid ${colours[GetGlobalTheme()].inputBorder};
      border-radius: 4px;
    `};
`;

class InputSelect extends Component {
  props;

  constructor(props) {
    super(props);
    this.props = props;
  }

  recordInFilter = (rec) => {
    for (let opt in this.props.pasteSpecial.filter) {
      if (rec[opt] !== this.props.pasteSpecial.filter[opt]) {
        return false;
      }
    }
    return true;
  };

  render() {
    let self = this;
    if (this.props.value === undefined) {
      this.props.value = '';
    }
    let styles = {height: 35, width: '100%'};
    if (this.props.style !== undefined) {
      Object.keys(this.props.style).forEach(function (index) {
        styles[index] = self.props.style[index];
      });
    }
    if (IsHRWeb() === false) {
      styles.width = '100%';
      styles.height = 55;
    }

    let data = [];
    let initval = '';
    if (global.pasteSpecials[this.props.pasteSpecial.vcName]) {
      global.pasteSpecials[this.props.pasteSpecial.vcName].map(function (
        row,
        index
      ) {
        if (
          self.props.pasteSpecial.filter === undefined ||
          self.recordInFilter(row)
        ) {
          if (row[self.props.pasteSpecial.fields[0]] === self.props.value) {
            initval = row[self.props.pasteSpecial.fields[1]];
          }
          data.push({
            key: index,
            label: row[self.props.pasteSpecial.fields[1]],
            customKey: row[self.props.pasteSpecial.fields[0]],
          });
        }
      });
    }
    if (IsHRiOS() === false) {
      styles.backgroundColor = colours[GetGlobalTheme()].inputBackground;
      styles.color = colours[GetGlobalTheme()].inputText;
      styles.border = 'none';
      styles.borderRadius = 4;
      styles.padding = 8;
      styles.fontSize = fontSize.medium;
    } else {
      styles.height = 45;
    }
    //if (IsHRAndroid()) {
    styles.padding = 1;
    //}
    if (this.props.editable || this.props.editable === undefined) {
      styles.border = '1px solid ' + colours[GetGlobalTheme()].inputBorder;
    }
    return (
      <SelectWrap>
        <>
          {IsHRiOS() && (
            <ModalSelector
              data={data}
              initValue={initval}
              accessible={this.props.editable}
              cancelText={translate('Cancel')}
              initValueTextStyle={{
                color: colours[GetGlobalTheme()].inputText,
                textAlign: 'left',
              }}
              selectStyle={{
                borderWidth: 0,
                paddingVertical: 12,
                paddingHorizontal: 0,
              }}
              overlayStyle={{backgroundColor: 'rgba(0,0,0,0.8)'}}
              onChange={(option) => {
                if (self.props.onChange !== undefined) {
                  self.props.onChange({
                    rowindex: -1,
                    fieldname: self.props.name,
                    value: option.customKey,
                  });
                }
              }}
              style={styles}
            />
          )}
          {IsHRiOS() === false && (
            <Picker
              selectedValue={this.props.value}
              style={styles}
              enabled={this.props.editable}
              dropdownIconColor={colours[GetGlobalTheme()].titleText}
              onValueChange={(itemValue) => {
                if (self.props.onChange !== undefined) {
                  self.props.onChange({
                    rowindex: -1,
                    fieldname: self.props.name,
                    value: itemValue,
                  });
                }
                return true;
              }}>
              {this.props.pasteSpecial.vcName.toString().substring(0, 3) !==
                '999' && <Picker.Item label={''} value={''} />}
              {global.pasteSpecials[this.props.pasteSpecial.vcName] &&
                global.pasteSpecials[this.props.pasteSpecial.vcName].map(
                  function (row) {
                    if (
                      self.props.pasteSpecial.filter === undefined ||
                      self.recordInFilter(row)
                    ) {
                      return (
                        <Picker.Item
                          key={row[self.props.pasteSpecial.fields[0]]}
                          label={row[self.props.pasteSpecial.fields[1]]}
                          value={row[self.props.pasteSpecial.fields[0]]}
                        />
                      );
                    }
                  }
                )}
            </Picker>
          )}
        </>
      </SelectWrap>
    );
  }
}

export default InputSelect;
