import React from 'react';
import {ReactComponent as DropdownIcon} from '@assets/images/DropdownIcon.svg';
import {ReactComponent as LogoutIcon} from '@assets/images/LogoutIcon.svg';
import {ReactComponent as ExcellentLogo} from '@assets/images/ExcellentLogo.svg';
import {ReactComponent as ExcellentLogoX} from '@assets/images/ExcellentLogoX.svg';
import {ReactComponent as CartIcon} from '@assets/images/CartIcon.svg';
import {ReactComponent as EmployeesIcon} from '@assets/images/EmployeesIcon.svg';
import {ReactComponent as HolidayIcon} from '@assets/images/HolidayIcon.svg';
import {ReactComponent as HolidayIconWhite} from '@assets/images/HolidayIconWhite.svg';
import {ReactComponent as InvoiceIcon} from '@assets/images/InvoiceIcon.svg';
import {ReactComponent as OrdersIcon} from '@assets/images/OrdersIcon.svg';
import {ReactComponent as TimeIcon} from '@assets/images/TimeIcon.svg';
import {ReactComponent as UserIcon} from '@assets/images/UserIcon.svg';
import {ReactComponent as CheckmarkIcon} from '@assets/images/CheckmarkIcon.svg';
import {ReactComponent as PersonIcon} from '@assets/images/PersonIcon.svg';
import {ReactComponent as PrintIcon} from '@assets/images/PrintIcon.svg';
import {ReactComponent as SearchIcon} from '@assets/images/SearchIcon.svg';
import {ReactComponent as AbsencesIcon} from '@assets/images/AbsencesIcon.svg';
import {ReactComponent as BalanceSheetIcon} from '@assets/images/BalanceSheetIcon.svg';
import {ReactComponent as ContractChangesIcon} from '@assets/images/ContractChangesIcon.svg';
import {ReactComponent as ContractsIcon} from '@assets/images/ContractsIcon.svg';
import {ReactComponent as DocumentsIcon} from '@assets/images/DocumentsIcon.svg';
import {ReactComponent as PaySlipsIcon} from '@assets/images/PaySlipsIcon.svg';
import {ReactComponent as PlannedAbsencesIcon} from '@assets/images/PlannedAbsencesIcon.svg';
import {ReactComponent as PurchaseInvoicesIcon} from '@assets/images/PurchaseInvoicesIcon.svg';
import {ReactComponent as PurchaseLedgerIcon} from '@assets/images/PurchaseLedgerIcon.svg';
import {ReactComponent as SalesLedgerIcon} from '@assets/images/SalesLedgerIcon.svg';
import {ReactComponent as ShiftPlansIcon} from '@assets/images/ShiftPlansIcon.svg';
import {ReactComponent as StockTakingsIcon} from '@assets/images/StockTakingsIcon.svg';
import {ReactComponent as TimeTablesIcon} from '@assets/images/TimeTablesIcon.svg';
import {ReactComponent as CrossIcon} from '@assets/images/CrossIcon.svg';
import {ReactComponent as CheckboxEmpty} from '@assets/images/CheckboxEmpty.svg';
import {ReactComponent as CheckboxFull} from '@assets/images/CheckboxFull.svg';
import {ReactComponent as ExclamationMarkIcon} from '@assets/images/ExclamationMarkIcon.svg';
import {ReactComponent as RedExclamationMarkIcon} from '@assets/images/RedExclamationMarkIcon.svg';
import {ReactComponent as XMarkIcon} from '@assets/images/XMarkIcon.svg';
import {ReactComponent as PendingIcon} from '@assets/images/PendingIcon.svg';
import {ReactComponent as CheckboxIcon} from '@assets/images/CheckboxTick.svg';
import {ReactComponent as DuplicateIcon} from '@assets/images/DuplicateIcon.svg';
import {ReactComponent as EditIcon} from '@assets/images/EditIcon.svg';
import {ReactComponent as FileDropIcon} from '@assets/images/FileDropIcon.svg';
import {ReactComponent as PDFIcon} from '@assets/images/PDFIcon.svg';
import {ReactComponent as JPGIcon} from '@assets/images/JPGIcon.svg';
import {ReactComponent as PNGIcon} from '@assets/images/PNGIcon.svg';
import {ReactComponent as GIFIcon} from '@assets/images/GIFIcon.svg';
import {ReactComponent as DefaultFileIcon} from '@assets/images/DefaultFileIcon.svg';
import {ReactComponent as DownloadCloudIcon} from '@assets/images/DownloadCloudIcon.svg';
import {ReactComponent as WordIcon} from '@assets/images/WordIcon.svg';
import {ReactComponent as ExcelIcon} from '@assets/images/ExcelIcon.svg';
import {ReactComponent as CalendarIcon} from '@assets/images/CalendarIcon.svg';
import {ReactComponent as UpsideIcon} from '@assets/images/UpsideIcon.svg';
import {ReactComponent as Plus} from '@assets/images/Plus.svg';
import {ReactComponent as LinkIcon} from '@assets/images/LinkIcon.svg';
import {ReactComponent as Remove} from '@assets/images/Remove.svg';
import {ReactComponent as PlaceholderProfileIcon} from '@assets/images/PlaceholderProfilePicture.svg';
import {ReactComponent as TasksMinimizedIcon} from '@assets/images/TasksMinimizedIcon.svg';
import {ReactComponent as SalesMinimizedIcon} from '@assets/images/SalesMinimizedIcon.svg';
import {ReactComponent as HRMinimizedIcon} from '@assets/images/HRMinimizedIcon.svg';
import {ReactComponent as PasteSpecialDetailIcon} from '@assets/images/PasteSpecialDetailIcon.svg';
import {ReactComponent as StockMinimizedIcon} from '@assets/images/StockMinimizedIcon.svg';
import {ReactComponent as PurchasesMinimizedIcon} from '@assets/images/PurchasesMinimizedIcon.svg';
import {ReactComponent as ReportsMinimizedIcon} from '@assets/images/ReportsMinimizedIcon.svg';
import {ReactComponent as DoubleArrows} from '@assets/images/DoubleArrows.svg';
import {ReactComponent as ArrowIcon} from '@assets/images/ArrowIcon.svg';
import {ReactComponent as RefreshIcon} from '@assets/images/RefreshIcon.svg';
import {ReactComponent as AccessDenied} from '@assets/images/AccessDenied.svg';
import {ReactComponent as FolderIcon} from '@assets/images/FolderIcon.svg';
import {ReactComponent as NotepadIcon} from '@assets/images/NotepadIcon.svg';
import {ReactComponent as CommentCloudIcon} from '@assets/images/CommentCloud.svg';
import {ReactComponent as ThreeDotsIcon} from '@assets/images/ThreeDots.svg';
import {colours} from '@styles/Style';
import {GetGlobalTheme} from '../../../Tools/DisplayTools';
import {RowContainer} from '@styles/primitiveComponents';
import styled from 'styled-components/native';

const IconWrapper = styled(RowContainer)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

type PortalIconProps = {
  iconName: string;
  iconHeight: number;
  iconWidth?: number;
  iconColor?: string;
  wrapperStyle?: React.CSSProperties;
};

const icons: {[key: string]: React.ComponentType<any>} = {
  Remove: Remove,
  ExcellentLogo: ExcellentLogo,
  ExcellentLogoX: ExcellentLogoX,
  DropdownIcon: DropdownIcon,
  UpsideIcon: UpsideIcon,
  CheckboxIcon: CheckboxIcon,
  LogoutIcon: LogoutIcon,
  CartIcon: CartIcon,
  EmployeesIcon: EmployeesIcon,
  HolidayIcon: HolidayIcon,
  HolidayIconWhite: HolidayIconWhite,
  InvoiceIcon: InvoiceIcon,
  OrdersIcon: OrdersIcon,
  TimeIcon: TimeIcon,
  UserIcon: UserIcon,
  CheckmarkIcon: CheckmarkIcon,
  PersonIcon: PersonIcon,
  Print: PrintIcon,
  SearchIcon: SearchIcon,
  CrossIcon: CrossIcon,
  AbsencesIcon: AbsencesIcon,
  PurchaseLedgerIcon: PurchaseLedgerIcon,
  SalesLedgerIcon: SalesLedgerIcon,
  ShiftPlansIcon: ShiftPlansIcon,
  StockTakingsIcon: StockTakingsIcon,
  TimeTablesIcon: TimeTablesIcon,
  PurchaseInvoicesIcon: PurchaseInvoicesIcon,
  PlannedAbsencesIcon: PlannedAbsencesIcon,
  PaySlipsIcon: PaySlipsIcon,
  DocumentsIcon: DocumentsIcon,
  ContractsIcon: ContractsIcon,
  ContractChangesIcon: ContractChangesIcon,
  BalanceSheetIcon: BalanceSheetIcon,
  CheckboxEmpty: CheckboxEmpty,
  CheckboxFull: CheckboxFull,
  ExclamationMarkIcon: ExclamationMarkIcon,
  RedExclamationMarkIcon: RedExclamationMarkIcon,
  XMarkIcon: XMarkIcon,
  PendingIcon: PendingIcon,
  DuplicateIcon: DuplicateIcon,
  EditIcon: EditIcon,
  FileDropIcon: FileDropIcon,
  PDFIcon: PDFIcon,
  JPGIcon: JPGIcon,
  PNGIcon: PNGIcon,
  GIFIcon: GIFIcon,
  DefaultFileIcon: DefaultFileIcon,
  DownloadCloudIcon: DownloadCloudIcon,
  WordIcon: WordIcon,
  ExcelIcon: ExcelIcon,
  CalendarIcon: CalendarIcon,
  Plus: Plus,
  LinkIcon: LinkIcon,
  PlaceholderProfileIcon: PlaceholderProfileIcon,
  TasksMinimizedIcon: TasksMinimizedIcon,
  SalesMinimizedIcon: SalesMinimizedIcon,
  PasteSpecialDetailIcon: PasteSpecialDetailIcon,
  HRMinimizedIcon: HRMinimizedIcon,
  StockMinimizedIcon: StockMinimizedIcon,
  PurchasesMinimizedIcon: PurchasesMinimizedIcon,
  ReportsMinimizedIcon: ReportsMinimizedIcon,
  DoubleArrows: DoubleArrows,
  ArrowIcon: ArrowIcon,
  RefreshIcon: RefreshIcon,
  AccessDenied: AccessDenied,
  FolderIcon: FolderIcon,
  NotepadIcon: NotepadIcon,
  CommentCloudIcon: CommentCloudIcon,
  ThreeDotsIcon: ThreeDotsIcon,
};

export const PortalIcon = ({
  iconName,
  iconHeight,
  iconWidth,
  iconColor = colours[GetGlobalTheme()].white,
  wrapperStyle,
}: PortalIconProps) => {
  let PickedIcon: React.ComponentType<any> = icons[iconName];
  if (!PickedIcon) {
    console.error(`Icon with name ${iconName} does not exist!`);
    return <></>;
  }
  return (
    <IconWrapper style={wrapperStyle}>
      <PickedIcon width={iconWidth} height={iconHeight} fill={iconColor} color={iconColor} />
    </IconWrapper>
  );
};
