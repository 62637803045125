import {colours} from '@src/styles/Style';
import {GetGlobalTheme} from '@src/tools/displayTools';
import React, {useState} from 'react';
import {Text, TextStyle, View, ViewStyle} from 'react-native';

type ToolTipProps = {
  children: React.ReactNode;
  tooltipText: string;
  offsetX?: number;
  maxTextLength?: number;
};

const TooltipContainerStyle: ViewStyle = {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
};

const TooltipContentContainerStyle: ViewStyle = {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
};

const TooltipTextStyle: TextStyle = {
  color: colours[GetGlobalTheme()].white,
  textAlign: 'center',
  fontSize: 12,
  lineHeight: 16,
  whiteSpace: 'nowrap',
};

const TooltipArrowStyle: ViewStyle = {
  position: 'absolute',
  top: '100%',
  left: '50%',
  marginLeft: -5,
  borderWidth: 5,
  borderStyle: 'solid',
  borderTopColor: colours[GetGlobalTheme()].primary,
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  borderLeftColor: 'transparent',
  transform: 'translateX(-50%)',
};
const defaultMaxTextLength = 18;
const ToolTip: React.FC<ToolTipProps> = ({children, tooltipText, offsetX, maxTextLength}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const toolTipTextLengthLimit = maxTextLength ?? defaultMaxTextLength;
  const toolTipShortenedSuffix = '...';

  const TooltipContentStyle: ViewStyle = {
    width: 'auto',
    backgroundColor: colours[GetGlobalTheme()].primary,
    borderRadius: 6,
    padding: 8,
    zIndex: 1,
    bottom: '100%',
    transform: 'translateX(-50%)',
    position: 'absolute',
    opacity: isTooltipVisible ? 1 : 0,
    visibility: isTooltipVisible ? 'visible' : 'hidden',
    left: offsetX ? `calc(55% + ${offsetX}px)` : '55%',
    minHeight: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  function getToolTipText() {
    if (tooltipText.length > toolTipTextLengthLimit) {
      return tooltipText.slice(0, toolTipTextLengthLimit) + toolTipShortenedSuffix;
    }
    return tooltipText;
  }

  return (
    <View style={TooltipContainerStyle}>
      <View
        style={TooltipContentContainerStyle}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}>
        {children}
        {isTooltipVisible && (
          <View style={TooltipContentStyle}>
            <Text style={TooltipTextStyle}>{getToolTipText()}</Text>
            <View style={TooltipArrowStyle}></View>
          </View>
        )}
      </View>
    </View>
  );
};

export default ToolTip;
