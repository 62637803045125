import {IsHRWeb, isDesktopScreen} from '@tools/displayTools';
import {apiSupportsItem} from '@utils/apiTools';
import {translate} from '@utils/languageTools';
import {APISupportsItem} from './drawersTools';

export type ReportType = {
  reportName: string;
  reportWindow: string;
  reportTitle: string;
  fields: {};
  iconName: string;
};

export function getReports() {
  const ledgers = [];

  if (isDesktopScreen()) {
    if (APISupportsItem('ARRn')) {
      const ledger: ReportType = {
        reportName: 'ARRn',
        reportWindow: 'ARRClass',
        fields: {f1: ''},
        reportTitle: translate('SalesLedger'),
        iconName: 'SalesLedgerIcon',
      };
      ledgers.push(ledger);
    }

    if (APISupportsItem('APRn')) {
      const ledger: ReportType = {
        reportName: 'APRn',
        reportWindow: 'APRClass',
        fields: {f1: ''},
        reportTitle: translate('PurchaseLedger'),
        iconName: 'PurchaseLedgerIcon',
      };
      ledgers.push(ledger);
    }

    if (APISupportsItem('BalRn')) {
      const ledger: ReportType = {
        reportName: 'BalRn',
        reportWindow: 'BalRClass',
        fields: {sStartDate: '', sEndDate: ''},
        reportTitle: translate('BalRn'),
        iconName: 'BalanceSheetIcon',
      };
      ledgers.push(ledger);
    }
  }

  return ledgers;
}
