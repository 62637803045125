import React from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {SetCurrentWindow2} from '../../Tools/WindowTools';
import {YesNoWindow} from '../status/YesNoWindow';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {InputChangeEventProps, ListWindowColumn, WindowDefinition, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const Drawer = createDrawerNavigator();

function headerFields(table: ContactsRecord) {
  let windowDefinitions: WindowDefinition = [
    {
      fields: [
        {
          label: translate('Code'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Code',
          viewOnly: true,
          width: '100px',
        },
        {
          label: translate('Name'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Name',
          viewOnly: false,
          width: '400px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('RegNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'RegNr1',
          viewOnly: false,
          width: '250px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('VATNr'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'VATNr',
          viewOnly: false,
          width: '300px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Email'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'eMail',
          viewOnly: false,
          width: '250px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Mobile'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Mobile',
          viewOnly: false,
          width: '250px',
          onChange: table.updateRecordField,
        },
        {
          label: translate('PhoneNo'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Phone',
          viewOnly: false,
          width: '250px',
          onChange: table.updateRecordField,
        },
      ],
    },
    {
      fields: [
        {
          label: translate('Bank'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'Bank',
          viewOnly: false,
          width: '300px',
          onChange: table.updateRecordField,
          pasteSpecial: {
            vcName: 'BankVc',
            multiValue: false,
            columns: [
              {
                name: translate('Code'),
                width: '35%',
                key: 'Code',
                mainKey: 'Code',
              },
              {
                width: '65%',
                name: translate('Name'),
                key: 'Comment',
              },
            ],
          },
        },
        {
          label: translate('BankAccount'),
          windowElementType: WindowElementType.kInputTypeText,
          dataKey: 'BankAccount',
          viewOnly: false,
          width: '300px',
          onChange: table.updateRecordField,
        },
      ],
    },
  ];
  return windowDefinitions;
}

function browseColumns() {
  let browseColumns: ListWindowColumn[] = [
    {
      name: translate('Code'),
      key: 'code',
      sortKey: 'Code',
      width: '100px',
    },
    {
      name: translate('Name'),
      key: 'name',
      sortKey: 'Name',
      width: '100px',
    },
    {
      name: translate('RegNr'),
      key: 'regnr1',
      sortKey: 'RegNr1',
      width: '100px',
    },
  ];
  return browseColumns;
}

interface ContactsListState extends ListScreenState {
  newContactHelper: boolean;
  NewName: string;
  NewRegNr: string;
  NewGroup: string;
  px: number;
  py: number;
  NewStartDate: string;
  NewEndDate: string;
  NewEmpVacDays: [];
}

export class ContactsList extends ListScreen<{}, ContactsListState> {
  constructor(props: any) {
    super(props);
    this.createNewContact = this.createNewContact.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    //this.changePage = this.changePage.bind(this);
    this.table = 'CUVc';
    this.ScreenName = 'Contacts';
    this.init = true;
    this.navigation = props.navigation;
    this.state = {
      ...this.state,
      data: [],
      newContactHelper: false,
      NewName: '',
      NewRegNr: '',
      NewGroup: '',
      px: 0,
      py: 0,
      NewStartDate: '',
      NewEndDate: '',
      NewEmpVacDays: [],
    };
    SetCurrentWindow2('Contacts', 'Code', 'descending');
  }

  CheckNewChangeData = () => {
    let res = true;
    if (this.state.NewName === '') {
      res = false;
    }
    if (this.state.NewRegNr === '') {
      res = false;
    }
    return res;
  };

  createNewContact = () => {
    let self = this;
    let tdata;
    if (this.CheckNewChangeData() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    if (global.userData.provider === 0) {
      tdata = {
        record: {
          Name: this.state.NewName,
          RegNr1: this.state.NewRegNr,
          VEType: 1,
        },
      };
    }
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (objectHasProperty(response, 'record')) {
          self.ShowHideNewContact();
          if (UsePagination() === false) {
            self.getBrowseListData();
          }
          self.rowClick(response.record.Code);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewContact = () => {
    if (this.state.newContactHelper) {
      global.PopEscapeActions();
      this.setState({newContactHelper: false, NewName: '', NewRegNr: ''});
    } else {
      this.setState({newContactHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewContact());
    }
  };
  renderNewButton = () => {
    let self = this;
    let style = {};
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'Contact_new'}
        style={{flexDirection: 'row'}}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        <ActionButton onPress={() => this.ShowHideNewContact()} title={translate('AddNew')} />
        {this.state.newContactHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField style={{width: '100%'}}>
                  <EditField
                    title={translate('Name') + '*'}
                    name={'Name'}
                    value={this.state.NewName}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewName: value})}
                  />
                </FilterField>
                <FilterField style={{width: '100%'}}>
                  <EditField
                    title={translate('RegNr') + '*'}
                    name={'RegNr'}
                    value={this.state.NewRegNr}
                    onChange={({value}: InputChangeEventProps) => self.setState({NewRegNr: value})}
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewContact()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewContact()} title={translate('Create')} />
              </FilterActions>
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={browseColumns()}
          doClick={self.rowClick}
          rowID={'code'}
          title={translate(this.ScreenName)}
          width="900px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface ContactsRecordState extends RecordScreenState {
  data: [];
  message: [];
  NewEmpVacDays: [];
}

export class ContactsRecord extends RecordScreen<{}, ContactsRecordState> {
  origrowcnt: number;

  constructor(props: any) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.origrowcnt = 0;
    this.ScreenName = 'Contacts';
    this.table = 'CUVc';
    this.navigation = props.navigation;
    this.IDField = 'Code';
    this.state = {
      ...this.state,
      data: [],
      message: [],
      NewEmpVacDays: [],
    };
    this.record = new WindowRecord(this.table, false, headerFields(this));
    this.CheckOpenRecord();
  }

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;
    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <InspectWindow
          key={JSON.stringify(this.record)}
          title={translate('Contact')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          allowDelete={true}
          okcheck={'Status'}
          backButton={() => goBack(self, self.ScreenName)}
        />
        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

function Contacts_old() {
  return (
    <Drawer.Navigator
      // TODO: Check if breaks everything, previously "style={{background: 'white'}}"
      // TODO: Check if breaks everything, previously "screenOptions={{headerShown: false}}"
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="ContactsList"
        component={ContactsList}
        options={{
          title: translate('Contacts'),
        }}
      />
      <Drawer.Screen
        name="ContactsRecord"
        component={ContactsRecord}
        options={{
          title: translate('Contact'),
        }}
        getId={({params}: any) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default Contacts_old;
