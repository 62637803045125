import React, {useEffect, useState} from 'react';
import {colours} from '@src/styles/Style';
import {PasteSpecial} from '@src/utils/types';
import {ActionButton} from '../../button/ActionButton';
import {GetGlobalTheme, GetPasteSpecialValue} from '@src/tools/displayTools';

type PasteSpecialDetailedHButtonProps = {
  pasteSpecial: PasteSpecial;
  value: string;
  displayValue: string | number;
};

export const PasteSpecialDetailedButton: React.FC<PasteSpecialDetailedHButtonProps> = ({
  pasteSpecial,
  value,
  displayValue,
}) => {
  const [record, setRecord] = useState(undefined);
  const [pos, setPos] = useState({left: 0, top: 0});

  useEffect(() => {
    const pasteSpecialRecord = GetPasteSpecialValue(pasteSpecial.vcName, value);
    if (pasteSpecialRecord) {
      setRecord(pasteSpecialRecord);
    }
  }, [pasteSpecial, displayValue]);
  if (record) {
    let fields = pasteSpecial.details.fields;
    fields.map((field) => {
      field.value = record[field.key];
    });
    let title = record[pasteSpecial.details.titleKey];
    return (
      <ActionButton
        title={''}
        onPress={() => global.setPasteSpecialDetailedView({show: true, fields: fields, title: title, pos: pos})}
        iconName={'PasteSpecialDetailIcon'}
        style={{
          borderRadius: '8px',
          background: colours[GetGlobalTheme()].iconButtonBG,
          height: 30,
          width: 30,
          position: 'absolute',
          right: -5,
          padding: 0,
          top: -2,
        }}
        onLayout={(event) => {
          let pos = event.nativeEvent.layout;
          if (window.scrollY) {
            pos.top = pos.top + window.scrollY;
          }
          setPos(pos);
        }}
      />
    );
  } else {
    return <></>;
  }
};
