import React, {Component} from 'react';
import styled, {css} from 'styled-components/native';
import {colours} from '../../styles/Style';
import {GetGlobalTheme} from '../../Tools/DisplayTools';
import {Modal} from 'react-native';
import * as myDate from '../../Tools/DateTools';
import {ScreenWidth, isDesktopScreen, isMobileOrTabletScreen} from '@src/tools/displayTools';

function getMobileCalendarMaxWidth() {
  return 350;
}

const CalendarWrap = styled.View<{left: number; top: number; centerCalendar: boolean}>`
  width: 300px;
  left: ${(props) => props.left};
  top: ${(props) => props.top};

  position: absolute;
  border: 1px solid ${colours[GetGlobalTheme()].lightBackground};
  border-radius: 5px;
  background: ${colours[GetGlobalTheme()].inputBackground};
  font-family: 'Open Sans';
  overflow: hidden;
  ${isMobileOrTabletScreen() &&
  css`
    width: 90%;
    max-width: ${getMobileCalendarMaxWidth()}px;
  `}
  ${(props) =>
    props.centerCalendar
      ? `
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  `
      : ``}
`;
const CalendarNav = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 5px;
  background: ${colours[GetGlobalTheme()].navigationBackground};
`;
const CalendarMonth = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
`;

const CalendarDay = styled.TouchableOpacity`
  width: 39px;
  margin: 1px;
  box-sizing: border-box;
  color: #333;
  text-align: center;
  padding: 10px;
`;
const CalendarText = styled.Text`
  color: ${colours[GetGlobalTheme()].titleText};
`;

const ModalBackground = styled.TouchableOpacity`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;

const CalendarBackButton = styled.TouchableOpacity`
  height: 20px;
  width: 20px;
  color: #333;
  border-radius: 2px;
  text-align: center;
`;

const CalendarCenter = styled.View`
  flex-grow: 1;
  text-align: center;
  color: #333;
`;

const CalendarNextButton = styled.TouchableOpacity`
  height: 20px;
  width: 20px;
  color: #333;
  border-radius: 2px;
  text-align: center;
`;

class PasteCurDate extends Component {
  constructor(props) {
    super(props);
    let tdate;
    this.handleScroll = this.handleScroll.bind(this);
    if (props.value === '' || props.value === undefined) {
      if (props.linkeddateval) {
        tdate = props.linkeddateval;
      } else {
        tdate = myDate.getCurrentDate();
      }
    } else {
      tdate = myDate.extractDateFromString(props.value);
    }
    this.onChoose = props.onChoose;
    let self = this;
    this.state = {
      update: false,
      activeDate: tdate,
      activeMonth: tdate.getMonth() + 1,
      activeYear: tdate.getFullYear(),
      activeDay: tdate.getDate(),
    };
    global.PushEscapeActions(() => self.props.editfield.CloseCalendar());
  }

  OutputCalendarDay = (day, addf, key, curday) => {
    let keys = {};
    let keys2 = {};
    if (addf) {
      keys['onPress'] = () =>
        this.props.onChoose(myDate.constructDateString(this.state.activeYear, this.state.activeMonth, day));
    } else {
      keys['style'] = {opacity: '0.5'};
    }
    keys['key'] = key;
    if (day === curday) {
      keys['style'] = {
        background: colours[GetGlobalTheme()].buttonBackground,
        borderRadius: '50%',
      };
      keys2['style'] = {color: 'white'};
    }
    return (
      <CalendarDay {...keys}>
        <CalendarText {...keys2}>{day}</CalendarText>
      </CalendarDay>
    );
  };

  DisplayCalendarMonth = () => {
    let res = [];
    let firstday = new Date(this.state.activeYear, this.state.activeMonth - 1, 1).getDay();
    let curdate = new Date(this.state.activeYear, this.state.activeMonth, this.state.activeDay);
    let monthdays = myDate.getDaysInMonth(this.state.activeMonth, this.state.activeYear);
    let prefmonthdays = myDate.getDaysInMonth2(myDate.AddMonth(curdate, -1));
    let curday = 1;
    switch (firstday) {
      case 0:
        curday = -5;
        break;
      case 1:
        curday = 1;
        break;
      case 2:
        curday = 0;
        break;
      case 3:
        curday = -1;
        break;
      case 4:
        curday = -2;
        break;
      case 5:
        curday = -3;
        break;
      case 6:
        curday = -4;
        break;
    }
    let i = 0;
    while (curday <= monthdays) {
      if (curday > 0) {
        res.push(this.OutputCalendarDay(curday, true, i, this.state.activeDay));
      } else {
        res.push(this.OutputCalendarDay(prefmonthdays + curday, false, i, -100));
      }
      curday = curday + 1;
      i++;
    }
    let s = 1;
    while (i % 7 !== 0) {
      res.push(this.OutputCalendarDay(s, false, i, -100));
      i++;
      s++;
    }

    return res;
  };

  ChangeMonth = (diff) => {
    let month = this.state.activeMonth;
    let year = this.state.activeYear;
    let day = this.state.activeDay;
    month = month + diff;
    if (month > 12) {
      year = year + 1;
      month = 1;
    }
    if (month < 1) {
      year = year - 1;
      month = 12;
    }
    let monthdays = myDate.getDaysInMonth(month, year);
    if (day > monthdays) {
      day = monthdays;
    }
    this.setState({activeYear: year, activeMonth: month, activeDay: day});
  };

  handleScroll() {
    this.setState({update: !this.state.update});
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    global.PopEscapeActions();
  }

  render() {
    let self = this;
    let top = this.props.editfield.state.py;
    if (isDesktopScreen()) {
      top += 50;
    } else {
      top += 30;
    }
    if (window.scrollY && this.props.editfield.props.ignorescroll !== true) {
      top = top - window.scrollY;
    }

    let centerCalendar: boolean = false;
    if (this.props.editfield.state.px + getMobileCalendarMaxWidth() > ScreenWidth()) {
      centerCalendar = true;
    }

    return (
      <Modal transparent={true}>
        <ModalBackground onPress={() => self.props.editfield.CloseCalendar()}>
          <CalendarWrap left={this.props.editfield.state.px} top={top} centerCalendar={centerCalendar}>
            <CalendarNav>
              <CalendarBackButton onPress={() => self.ChangeMonth(-1)}>
                <CalendarText>{'<'}</CalendarText>
              </CalendarBackButton>
              <CalendarCenter>
                <CalendarText>{myDate.GetMonthName(this.state.activeMonth) + ' ' + this.state.activeYear}</CalendarText>
              </CalendarCenter>
              <CalendarNextButton onPress={() => self.ChangeMonth(1)}>
                <CalendarText>{'>'}</CalendarText>
              </CalendarNextButton>
            </CalendarNav>
            <CalendarMonth>{this.DisplayCalendarMonth()}</CalendarMonth>
          </CalendarWrap>
        </ModalBackground>
      </Modal>
    );
  }
}

export default PasteCurDate;
