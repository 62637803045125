import React, {useState} from 'react';
import {getFormattedDisplayValue, GetGlobalTheme, setPasteSpecialValue} from '@tools/displayTools';
import styled from 'styled-components/native';
import {DataTable} from 'react-native-paper';
import {colours, fontSize, fontWeight, spacing} from '@styles/Style';
import {ActionButton} from '@components/button/ActionButton';
import {PasteSpecialDisplayType, PasteSpecialWindowLayout} from '@utils/types';
import {View, Text} from 'react-native';

const RowContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const RowCheckmarkStyle = {
  margin: 4,
  marginLeft: spacing.space12,
  marginRight: spacing.space12,
  padding: 4,
  width: 8,
  height: 8,
  justifyContent: 'center',
  alignItems: 'center',
};

const Cell = styled.View<{width: number}>`
  width: ${(props) => props.width};
  display: flex;
  align-items: start;
  padding: 0;
  font-family: 'Open Sans';
`;

const CellNameStyle = {
  paddingLeft: spacing.space8,
  fontSize: fontSize.bodyBold,
  userSelect: 'none',
};

const CellDescriptionStyle = {
  color: colours[GetGlobalTheme()].label,
  paddingLeft: spacing.space8,
  fontSize: fontSize.body,
  userSelect: 'none',
};

const CellTagStyle = {
  alignItems: 'end',
  paddingLeft: spacing.space8,
  fontSize: fontSize.bodyBold,
  userSelect: 'none',
};

const CellNameTextStyle = {
  fontWeight: fontWeight.bodyBold,
  fontSize: fontSize.body,
  userSelect: 'none',
};

const CellDescriptionTextStyle = {
  color: colours[GetGlobalTheme()].grey600,
  userSelect: 'none',
};

const CellTagTextStyle = {
  color: colours[GetGlobalTheme()].grey600,
  userSelect: 'none',
};

const StyledRowContainer = styled(DataTable.Row)`
  background-color: ${colours[GetGlobalTheme()].white};
  font-size: ${fontSize.body}px;
  min-height: 32px;
  border-bottom-width: 0;
  border-radius: 0px;
  margin-vertical: 0;
  padding-vertical: 0;
  margin-bottom: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  z-index: ${(props) => props.arrayLength - props.rowIndex};
`;

const DataTableContainer = styled.View`
  flex-direction: ${(props) => props.direction};
  margin-left: ${(props) => props.marginLeft};
  border-radius: 0;
  width: 94%;
  user-select: none;
  -webkit-user-select: none;
  margin-top: ${(props) => props.verticalMargin};
  margin-bottom: ${(props) => props.verticalMargin};
`;

type StyledRowProps = {
  rowIndex: number;
  length: number;
  record: any;
  psheader: any[];
  isMultiPasteSpecial: boolean;
  mainKey: string | undefined;
  table: string;
  activeRecord: string[];
  multiSelectedRecord: string[];
  currentIndex: number;
  setCurrentRecord: (rowIndex: number, id: string) => void;
  removeValue: (code: string) => void;
  windowLayout: PasteSpecialWindowLayout;
};

export const PasteSpecialStyledRow: React.FC<StyledRowProps> = ({
  rowIndex,
  length,
  record,
  psheader,
  isMultiPasteSpecial,
  mainKey,
  table,
  activeRecord,
  multiSelectedRecord,
  currentIndex,
  setCurrentRecord,
  removeValue,
  windowLayout,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>();

  let id;

  if (mainKey) {
    id = record[mainKey];
  } else {
    id = record[psheader[0].key];
  }
  let isRecordActive = activeRecord.indexOf(id) > -1;
  const isRecordSelected = multiSelectedRecord.indexOf(id) > -1;

  if (isRecordSelected || currentIndex === rowIndex) {
    isRecordActive = true;
  }
  setPasteSpecialValue(table, id, record);
  const isRecordChecked = activeRecord.includes(record.Code) || multiSelectedRecord.includes(record.Code);

  function getWindowDirection() {
    switch (windowLayout) {
      case PasteSpecialWindowLayout.SingleColumnWithDescription: {
        return 'column';
      }
      case PasteSpecialWindowLayout.Default: {
        return 'row';
      }
      default: {
        return 'row';
      }
    }
  }

  function getLeftMargin() {
    switch (windowLayout) {
      case PasteSpecialWindowLayout.SingleColumnWithDescription: {
        if (isMultiPasteSpecial) {
          return 0;
        }
        return spacing.space4;
      }
      case PasteSpecialWindowLayout.Default: {
        return spacing.space4;
      }
      default: {
        return 0;
      }
    }
  }

  function getVerticalMargin() {
    switch (windowLayout) {
      case PasteSpecialWindowLayout.SingleColumnWithDescription: {
        if (isMultiPasteSpecial) {
          return 0;
        }
        return spacing.space4;
      }
      case PasteSpecialWindowLayout.Default: {
        return spacing.space12;
      }
      default: {
        return 0;
      }
    }
  }

  function getCellStyle(style: PasteSpecialDisplayType) {
    switch (style) {
      case PasteSpecialDisplayType.Name: {
        return CellNameStyle;
      }
      case PasteSpecialDisplayType.Description: {
        return CellDescriptionStyle;
      }
      default: {
        return CellNameStyle;
      }
    }
  }

  function getCellTextStyle(style: PasteSpecialDisplayType) {
    switch (style) {
      case PasteSpecialDisplayType.Name: {
        return CellNameTextStyle;
      }
      case PasteSpecialDisplayType.Description: {
        return CellDescriptionTextStyle;
      }
      default: {
        return CellNameTextStyle;
      }
    }
  }

  function getCellWithTag(field, index) {
    return (
      <View style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Cell
          key={index}
          width={field.width}
          style={getCellStyle(field.displayType)}
          textStyle={getCellTextStyle(field.displayType)}>
          {field.displayFormat ? getFormattedDisplayValue(field.displayFormat, record) : record[field.key]}
        </Cell>
        {record[field.tagKey] && (
          <Cell key={index} width={field.width} style={CellTagStyle} textStyle={CellTagTextStyle}>
            {record[field.tagKey]}
          </Cell>
        )}
      </View>
    );
  }

  return (
    <RowContainer
      // @ts-ignore
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      // @ts-ignore
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        borderRadius: 0,
        backgroundColor: isHovering ? colours[GetGlobalTheme()].grey300 : colours[GetGlobalTheme()].white,
      }}>
      {isMultiPasteSpecial && (
        <ActionButton
          onPress={() => {
            if (isRecordChecked) {
              removeValue(record.Code);
              return;
            }
            setCurrentRecord(rowIndex, id);
          }}
          title=""
          style={RowCheckmarkStyle}
          iconColor={isRecordChecked ? colours[GetGlobalTheme()].lightBlue : colours[GetGlobalTheme()].white}
          iconName={isRecordChecked ? 'CheckboxFull' : 'CheckboxEmpty'}
          iconSize={12}
        />
      )}
      <StyledRowContainer
        key={rowIndex}
        actf={isRecordActive}
        rowIndex={rowIndex}
        arrayLength={length}
        pointerEvents="none"
        onPress={(e) => {
          setCurrentRecord(rowIndex, id);
          e.stopPropagation();
          e.target.blur();
        }}
        style={{backgroundColor: isHovering ? colours[GetGlobalTheme()].grey300 : colours[GetGlobalTheme()].white}}>
        <DataTableContainer
          direction={getWindowDirection()}
          marginLeft={getLeftMargin()}
          verticalMargin={getVerticalMargin()}>
          {psheader.map((field, index) => {
            if (field.tagKey) {
              return getCellWithTag(field, index);
            }

            return (
              <Cell
                key={index}
                width={field.width}
                style={getCellStyle(field.displayType)}
                textStyle={getCellTextStyle(field.displayType)}>
                <Text>
                  {field.displayFormat ? getFormattedDisplayValue(field.displayFormat, record) : record[field.key]}
                </Text>
              </Cell>
            );
          })}
        </DataTableContainer>
      </StyledRowContainer>
    </RowContainer>
  );
};
