import React, {useEffect, useState} from 'react';
import {translate} from '@utils/languageTools';
import {BackHandler, ToastAndroid} from 'react-native';
import {IsHRAndroid} from '../../Tools/DisplayTools';

export const ExecuteOnlyOnAndroid = (props) => {
  const {message} = props;
  const [exitApp, setExitApp] = useState(0);
  const backAction = () => {
    setTimeout(() => {
      setExitApp(0);
    }, 2000); // 2 seconds to tap second-time

    if (exitApp === 0) {
      setExitApp(exitApp + 1);

      ToastAndroid.show(message, ToastAndroid.SHORT);
    } else if (exitApp === 1) {
      BackHandler.exitApp();
    }
    return true;
  };
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction
    );
    return () => backHandler.remove();
  });
  return <></>;
};

export default function DoubleTapToClose(props) {
  const {message = translate('DoubleBackToClose')} = props;
  return IsHRAndroid ? <ExecuteOnlyOnAndroid message={message} /> : <></>;
}
