import {
  AttachmentWrapper,
  BlueText,
  ChildWrapper,
  Description,
  DescriptionWrapper,
  HoverMsg,
  IconWrapper,
  LoadingIcon,
  LoadingWrap,
  MessageText,
  UploaderWrapper,
} from './FileUploader.styles';
import {acceptedExt, checkType, getFileSizeMB} from './FileUploader.utils';
import React, {useEffect, useRef, useState} from 'react';
import useDragging from './useDragging';
import {PortalIcon} from '@components/portalIcon/PortalIcon';
import {translate} from '@utils/languageTools';
import {GetGlobalTheme} from '@tools/displayTools';
import {colours, spacing} from '@styles/Style';
import styled from 'styled-components';

const description = (
  currFile: Array<File> | File | null,
  uploaded: boolean,
  typeError: boolean,
  disabled: boolean | undefined,
  label: string | undefined
) => {
  return typeError ? (
    <MessageText>{translate('FileTypeNotSupported')}</MessageText>
  ) : (
    <Description>
      {disabled ? (
        <MessageText> {translate('UploadDisabled')}</MessageText>
      ) : !currFile && !uploaded ? (
        <>
          {label ? (
            <>
              <MessageText>{label.split(' ')[0]}</MessageText> {label.substr(label.indexOf(' ') + 1)}
            </>
          ) : (
            <>
              <MessageText>
                {translate('DragAndDropFilesOr')}&nbsp;
                <BlueText>{translate('ChooseFromComputer')}</BlueText>
              </MessageText>
            </>
          )}
        </>
      ) : (
        <>
          <MessageText> {translate('UploadedSuccessfully')}</MessageText>
        </>
      )}
    </Description>
  );
};

type FileDragAndDropProps = {
  name?: string; // the name for your form (if exist)
  multiple?: boolean; // a boolean to determine whether the multiple files is enabled or not
  label?: string; // the label (text) for your form (if exist) inside the uploading box - first word underlined
  required?: boolean; // Conditionally set the input field as required
  disabled?: boolean; // this for disabled the input
  hoverTitle?: string; // text appears(hover) when trying to drop a file
  fileOrFiles?: Array<File> | File | null; // this mainly made because if you would like to remove uploaded file(s) pass null
  // or pass another file as initial
  types?: string[]; // array of strings with extensions to check and go throw
  onTypeError?: (error) => void; // function that will be called only of error occurred related to type
  children?: any; // if you want to replace the current design inside the box of drop zone. (it will remove the default exist style)
  maxSize?: number; // the maximum size of the file (number in mb)
  minSize?: number; // the minimum size of the file (number in mb)
  onSizeError?: (file: File) => void; // function that will be called only if error related to min or max size occurred
  onDrop?: (arg0: File | Array<File>) => void; // function that will be called when the user drops file(s) on the drop area only
  onSelect?: (arg0: File | Array<File>) => void; // function that will be called when the user selects file(s) on click the file area only
  handleChange?: (arg0: Array<File>) => void; // function that will be called when the user selects or drops file(s)
  onDraggingStateChange?: (dragging: boolean) => void; // function that will be called with the state of dragging
  dropMessageStyle?: React.CSSProperties | undefined; // A CSS property to style the hover message
  isLoading: boolean; // Determins if the file is being uploaded
};

const FileUploader = ({
  name,
  multiple,
  label,
  required,
  disabled,
  hoverTitle,
  fileOrFiles,
  types,
  onTypeError,
  children,
  maxSize,
  minSize,
  onSizeError,
  onDrop,
  onSelect,
  handleChange,
  onDraggingStateChange,
  dropMessageStyle,
  isLoading,
}: FileDragAndDropProps) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploaded, setUploaded] = useState(false);
  const [currFiles, setFile] = useState<Array<File> | File | null>(null);
  const [error, setError] = useState(false);

  const UploaderWrapper = styled.div`
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: ${spacing.space12}px;
    margin-bottom: ${spacing.space12}px;
    display: flex;
    border: dashed 2px ${colours[GetGlobalTheme()].grey400};
    background-color: ${isLoading
      ? colours[GetGlobalTheme()].contentBG // disabledBG
      : colours[GetGlobalTheme()].contentBG};
    padding: ${spacing.space24}px ${spacing.space8}px;
    border-radius: 12px;
    cursor: pointer;

    & > input {
      display: block;
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  `;

  const validateFile = (file: File) => {
    if (types && !checkType(file, types)) {
      setError(true);
      if (onTypeError) onTypeError(translate('FileTypeNotSupported'));
      return false;
    }
    if (maxSize && getFileSizeMB(file.size) > maxSize) {
      setError(true);
      if (onSizeError) onSizeError(translate('FileSizeTooBig'));
      return false;
    }
    if (minSize && getFileSizeMB(file.size) < minSize) {
      setError(true);
      if (onSizeError) onSizeError(translate('FileSizeTooSmall'));
      return false;
    }
    return true;
  };

  const handleChanges = (files: File | Array<File>): boolean => {
    let checkError = false;
    if (files) {
      const fileList = Array.isArray(files) ? files : [files];
      if (files instanceof File) {
        checkError = !validateFile(files);
      } else {
        for (let i = 0; i < fileList.length; i++) {
          const file = files[i];
          checkError = !validateFile(file) || checkError;
        }
      }

      if (checkError) return false;
      if (handleChange) handleChange(fileList);
      setFile(fileList);

      setUploaded(true);
      setError(false);
      return true;
    }
    return false;
  };

  const blockEvent = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
  };
  const handleClick = (ev: any) => {
    ev.stopPropagation();
    // eslint-disable-next-line no-param-reassign
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const handleInputChange = (ev: any) => {
    const allFiles = ev.target.files;
    const files = multiple ? allFiles : allFiles[0];
    const success = handleChanges(files);
    if (onSelect && success) onSelect(files);
  };
  const dragging = useDragging({
    labelRef,
    inputRef,
    multiple,
    handleChanges,
    onDrop,
  });

  useEffect(() => {
    onDraggingStateChange?.(dragging);
  }, [dragging]);

  useEffect(() => {
    if (fileOrFiles) {
      setUploaded(true);
      setFile(fileOrFiles);
    } else {
      if (inputRef.current) inputRef.current.value = '';
      setUploaded(false);
      setFile(null);
    }
  }, [fileOrFiles]);

  return (
    <AttachmentWrapper>
      {isLoading ? (
        <UploaderWrapper overRide={children} ref={labelRef} htmlFor={name} onClick={blockEvent}>
          {!children && (
            <ChildWrapper>
              <LoadingWrap>
                <LoadingIcon />
              </LoadingWrap>
            </ChildWrapper>
          )}
          {children}
        </UploaderWrapper>
      ) : (
        <UploaderWrapper overRide={children} ref={labelRef} htmlFor={name} onClick={blockEvent}>
          <input
            onClick={handleClick}
            onChange={handleInputChange}
            accept={acceptedExt(types)}
            ref={inputRef}
            type="file"
            name={name}
            disabled={disabled}
            multiple={multiple}
            required={required}
          />
          {dragging && (
            <HoverMsg style={dropMessageStyle}>
              <span>{hoverTitle || translate('DropHere')}</span>
            </HoverMsg>
          )}
          {!children && (
            <ChildWrapper>
              <IconWrapper>
                <PortalIcon iconName={'FileDropIcon'} iconHeight={24} />
              </IconWrapper>
              <DescriptionWrapper error={error}>
                {description(currFiles, uploaded, error, disabled, label)}
              </DescriptionWrapper>
            </ChildWrapper>
          )}
          {children}
        </UploaderWrapper>
      )}
    </AttachmentWrapper>
  );
};
export default FileUploader;
