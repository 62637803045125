import React, {useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FA5Icon from 'react-native-vector-icons/FontAwesome5';
import {Dimensions, ScrollView, Text, View} from 'react-native';
import {colours, iconSize, spacing} from '../styles/Style';
import {
  DoNavigate,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  IsHRWeb,
  IsNarrowScreen,
  IsSmallScreen,
  ScreenWidth,
  Supported,
} from '../Tools/DisplayTools';
import Absences from '../components/screens/Absences';
import Employees from '../components/screens/Employees';
import Contracts from '../components/screens/Contracts';
import ContractChanges from '../components/screens/ContractChanges';
import VacPlans from '../components/screens/VacPlans';
import Calculations from '../components/screens/Calculations';
import TimeTables from '../components/screens/TimeTables';
import Invoices from '../components/screens/Invoices';
import PurchaseInvoices from '../components/screens/PurchaseInvoices';
import SalesOrders from '../components/screens/SalesOrders';
import Contacts from '../components/screens/Contacts';
import Tasks from '../components/screens/Tasks';
import ShiftTables from '../components/screens/ShiftTables';
import StockTakings from '../components/screens/Stocktakings';
import {createDrawerNavigator, DrawerContentScrollView, DrawerItem} from '@react-navigation/drawer';
import {translate} from '@utils/languageTools';
import CompanySelect from '../components/status/CompanySelect';
import LanguageSelect from '../components/status/LanguageSelect';
import Requests from '../api/Requests';
import {logError} from '@utils/debug';
import OrderDocs from '../components/screens/OrderDocs';

function APISupportsItem(item) {
  let res = false;

  if (global.userData.supported.indexOf(item) > -1 || global.userData.apiversion === '') {
    res = true;
  }

  return res;
}

export function GetDrawers(type = 0) {
  let res = [];
  let links = [];
  if (APISupportsItem('activities')) {
    res.push({
      name: 'Tasks',
      title: translate('Tasks'),
      screen: Tasks,
      iconName: 'file-document-edit',
    });
    links.push({
      screen: 'Tasks',
      link: 'hr-portal/tasks',
      record: 'TasksRecord',
      list: 'TasksList',
      recordlink: 'hr-portal/tasks/:id',
    });
  }

  if (APISupportsItem('employees')) {
    res.push({
      name: 'Employees',
      title: translate('Employees'),
      screen: Employees,
      iconName: 'account-multiple',
    });
    links.push({
      screen: 'Employees',
      link: 'hr-portal/employees',
      record: 'EmployeesRecord',
      list: 'EmployeesList',
      recordlink: 'hr-portal/employees/:id',
    });
  }

  if (APISupportsItem('contracts')) {
    res.push({
      name: 'Contracts',
      title: translate('Contracts'),
      screen: Contracts,
      iconName: 'file-document-edit',
    });
    links.push({
      screen: 'Contracts',
      link: 'hr-portal/contracts',
      record: 'ContractsRecord',
      list: 'ContractsList',
      recordlink: 'hr-portal/contracts/:id',
    });
  }
  if (APISupportsItem('contractchanges')) {
    res.push({
      name: 'ContractChanges',
      title: translate('ContractChanges'),
      screen: ContractChanges,
      iconName: 'file-document-edit',
    });
    links.push({
      screen: 'ContractChanges',
      link: 'hr-portal/contract-changes',
      record: 'ContractChangesRecord',
      list: 'ContractChangesList',
      recordlink: 'hr-portal/contract-changes/:id',
    });
  }
  if (APISupportsItem('absenceplans')) {
    res.push({
      name: 'VacPlans',
      title: translate('VacationPlanning'),
      screen: VacPlans,
      iconName: 'briefcase-account',
    });
    links.push({
      screen: 'VacPlans',
      link: 'hr-portal/vacplan',
      record: 'VacPlansRecord',
      list: 'VacPlansList',
      recordlink: 'hr-portal/vacplan/:id',
      extra: 'VacPlansEmpPlan',
      extralink: 'hr-portal/vacplan/empplan',
    });
  }
  if (APISupportsItem('absences')) {
    res.push({
      name: 'Absences',
      title: translate('Absences'),
      screen: Absences,
      iconName: 'calendar-account',
    });
    links.push({
      screen: 'Absences',
      link: 'hr-portal/absences',
      record: 'AbsencesRecord',
      list: 'AbsencesList',
      recordlink: 'hr-portal/absences/:id',
    });
  }
  if (APISupportsItem('documents')) {
    res.push({
      name: 'OrderDocs',
      title: translate('OrderDocs'),
      screen: OrderDocs,
      iconName: 'briefcase-check',
    });
    links.push({
      screen: 'OrderDocs',
      link: 'hr-portal/documents',
      record: 'OrderDocsRecord',
      list: 'OrderDocsList',
      recordlink: 'hr-portal/documents/:id',
    });
  }
  if (APISupportsItem('shiftsheets')) {
    res.push({
      name: 'ShiftTables',
      title: translate('ShiftTables'),
      screen: ShiftTables,
      iconName: 'account-switch',
    });
    links.push({
      screen: 'ShiftTables',
      link: 'hr-portal/shifttables',
      record: 'ShiftTablesRecord',
      list: 'ShiftTablesList',
      recordlink: 'hr-portal/shifttables/:id',
    });
  }
  if (APISupportsItem('timesheets') && IsHRWeb()) {
    res.push({
      name: 'TimeTables',
      title: translate('TimeTables'),
      screen: TimeTables,
      iconName: 'account-clock',
    });
    links.push({
      screen: 'TimeTables',
      link: 'hr-portal/timetables',
      record: 'TimeTablesRecord',
      list: 'TimeTablesList',
      recordlink: 'hr-portal/timetables/:id',
    });
  }
  if (APISupportsItem('calculations')) {
    res.push({
      name: 'Calculations',
      title: translate('PaySlips'),
      screen: Calculations,
      iconName: 'account-cash',
    });
    links.push({
      screen: 'Calculations',
      link: 'hr-portal/calculations',
      record: 'CalculationsRecord',
      list: 'CalculationsList',
      recordlink: 'hr-portal/calculations/:id',
    });
  }

  if (IsHRWeb()) {
    if (APISupportsItem('stocktakes')) {
      res.push({
        name: 'StockTakings',
        title: translate('StockTakings'),
        screen: StockTakings,
        iconName: 'file-document-edit',
      });
      links.push({
        screen: 'StockTakings',
        link: 'hr-portal/stocktaking',
        record: 'StockTakingsRecord',
        list: 'StockTakingsList',
        recordlink: 'hr-portal/stocktaking/:id',
      });
    }
    if (APISupportsItem('invoices')) {
      res.push({
        name: 'Invoices',
        title: translate('Invoices'),
        screen: Invoices,
        iconName: 'file-document-edit',
      });
      links.push({
        screen: 'Invoices',
        link: 'hr-portal/invoices',
        record: 'InvoicesRecord',
        list: 'InvoicesList',
        recordlink: 'hr-portal/invoices/:id',
      });
    }
    if (APISupportsItem('salesorders')) {
      res.push({
        name: 'SalesOrders',
        title: translate('SalesOrders'),
        screen: SalesOrders,
        iconName: 'file-document-edit',
      });
      links.push({
        screen: 'SalesOrders',
        link: 'hr-portal/sales-orders',
        record: 'SalesOrdersRecord',
        list: 'SalesOrdersList',
        recordlink: 'hr-portal/sales-orders/:id',
      });
    }
    if (APISupportsItem('purchaseinvoices')) {
      res.push({
        name: 'PurchaseInvoices',
        title: translate('PurchaseInvoices'),
        screen: PurchaseInvoices,
        iconName: 'file-document-edit',
      });
      links.push({
        screen: 'PurchaseInvoices',
        link: 'hr-portal/purchase-invoices',
        record: 'PurchaseInvoicesRecord',
        list: 'PurchaseInvoicesList',
        recordlink: 'hr-portal/purchase-invoices/:id',
      });
    }
    if (APISupportsItem('contacts')) {
      res.push({
        name: 'Contacts',
        title: translate('Contacts'),
        screen: Contacts,
        iconName: 'file-document-edit',
      });
      links.push({
        screen: 'Contacts',
        link: 'hr-portal/contacts',
        record: 'ContactsRecord',
        list: 'ContactsList',
        recordlink: 'hr-portal/contacts/:id',
      });
    }
  }
  if (type === 0) {
    return res;
  } else {
    return links;
  }
}

const TaskBadge = styled.View`
  position: absolute;
  left: 40px;
  top: -10;
  border-radius: 15;
  background-color: rgb(8, 29, 66);
  padding: 1px 5px;
`;
const TaskBadgeText = styled.Text`
  font-size: 12px;
  color: white;
`;

const ShowDrawerLabel = (color, drawer, props, badge = null) => {
  let style = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  };
  let style2 = {
    color: color,
    marginLeft: spacing.large300,
  };
  if (drawer.iconType === 'FA5') {
    style.marginLeft = 10;
    style2.marginLeft = spacing.large300 + 5; //this is a terrible workaround, but not sure how to fix it now
  }
  return (
    <View style={style}>
      {(drawer.iconType === undefined || drawer.iconType !== 'FA5') && (
        <Icon name={drawer.iconName} size={iconSize.medium} color={color} />
      )}
      {drawer.iconType === 'FA5' && <FA5Icon name={drawer.iconName} size={iconSize.medium} color={color} />}
      <Text style={style2}>{props.isDrawerOpen ? drawer.title : ''}</Text>
      {badge !== null && <>{badge}</>}
    </View>
  );
};

const CompanySelectDrawerLabel = () => {
  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}>
      <Icon
        name={'office-building'}
        size={iconSize.medium}
        style={{
          marginRight: spacing.medium,
          color: colours[GetGlobalTheme()].titleText,
        }}
      />
      <CompanySelect userData={global.userData} />
    </View>
  );
};

const SetNavigation = (props, drawer) => {
  //global.resetScreen = true;
  //history.push(drawer.link);
  //  if (drawer.name === "PaySlips" || drawer.name === "Absences") {
  //    props.navigation.navigate(drawer.name, {update: true});
  //    DoNavigate(props, drawer.name, false, '', true);

  if (IsHRWeb() === false) {
    global.setIsDrawerOpen();
  }
  //  } else {
  DoNavigate(props, drawer.name, false, '', true, true);
  //  }
};

function MenuAdjust(props) {
  const insets = useSafeAreaInsets();
  let h = insets.top;
  return <View style={{marginTop: -h}}>{props.children}</View>;
}

const Drawer = createDrawerNavigator();
//<DrawerItemList {...props} />
//props.isDrawerOpen ? drawer.name : ''
//props.state.index === index
const CustomDrawerContent = (props) => {
  return (
    <>
      <MenuAdjust></MenuAdjust>
      <ScrollView style={{marginTop: IsHRWeb() ? 0 : -30}}>
        <DrawerContentScrollView
          contentContainerStyle={{
            flex: 1,
            marginTop: 9,
            borderRight: '0px solid',
            borderColor: colours[GetGlobalTheme()].titleText,
          }}
          {...props}
          key={JSON.stringify(global.userData)}>
          {IsHRWeb() === false && global.isDrawerOpen && (
            <View style={{marginHorizontal: spacing.medium300, marginTop: 20}}>{CompanySelectDrawerLabel()}</View>
          )}
          {GetDrawers().map(function (drawer, index) {
            let badge = null;
            if (drawer.name === 'Tasks') {
              if (props.profileInfo.TaskCnt && parseInt(props.profileInfo.TaskCnt) > 0) {
                badge = (
                  <TaskBadge>
                    <TaskBadgeText>{props.profileInfo.TaskCnt}</TaskBadgeText>
                  </TaskBadge>
                );
              }
            }
            return (
              <DrawerItem
                inactiveTintColor={props.inactiveTintColor}
                activeTintColor={props.activeTintColor}
                focused={index === props.state.index}
                key={index}
                style={{
                  marginVertical: IsSmallScreen() ? 0 : spacing.small,
                  marginHorizontal: spacing.medium,
                  paddingVertical: IsSmallScreen() ? 0 : 3,
                  paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
                }}
                label={({color}) => ShowDrawerLabel(color, drawer, props, badge)}
                onPress={() => {
                  SetNavigation(props, drawer);
                }}
              />
            );
          })}
          {IsHRWeb() && APISupportsItem('ARRn') && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) =>
                ShowDrawerLabel(
                  color,
                  {
                    iconName: 'info',
                    iconType: 'FA5',
                    title: translate('SalesLedger'),
                  },
                  props
                )
              }
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.showReport({
                  show: true,
                  type: 'inline',
                  repname: 'ARRn',
                  reportwindow: 'ARRClass',
                  fields: {f1: ''},
                  reptitle: translate('SalesLedger'),
                });
              }}
            />
          )}
          {IsHRWeb() && APISupportsItem('APRn') && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) =>
                ShowDrawerLabel(
                  color,
                  {
                    iconName: 'info',
                    iconType: 'FA5',
                    title: translate('PurchaseLedger'),
                  },
                  props
                )
              }
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.showReport({
                  show: true,
                  type: 'inline',
                  repname: 'APRn',
                  reportwindow: 'APRClass',
                  fields: {f1: ''},
                  reptitle: translate('PurchaseLedger'),
                });
              }}
            />
          )}
          {IsHRWeb() && APISupportsItem('BalRn') && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) =>
                ShowDrawerLabel(
                  color,
                  {
                    iconName: 'info',
                    iconType: 'FA5',
                    title: translate('BalRn'),
                  },
                  props
                )
              }
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.showReport({
                  show: true,
                  type: 'inline',
                  repname: 'BalRn',
                  reportwindow: 'BalRClass',
                  fields: {sStartDate: '', sEndDate: ''},
                  reptitle: translate('BalRn'),
                });
              }}
            />
          )}
          {false && IsHRWeb() && APISupportsItem('ResRn') && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) =>
                ShowDrawerLabel(
                  color,
                  {
                    iconName: 'info',
                    iconType: 'FA5',
                    title: translate('ResRn'),
                  },
                  props
                )
              }
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.showReport({
                  show: true,
                  type: 'inline',
                  repname: 'ResRn',
                  reportwindow: 'ResRClass',
                  fields: {sStartDate: '', sEndDate: ''},
                  reptitle: translate('ResRn'),
                });
              }}
            />
          )}
          {IsHRWeb() && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              label={({color}) => (
                <Icon
                  style={{textAlign: 'right'}}
                  color={color}
                  size={iconSize.medium}
                  name={props.isDrawerOpen ? 'chevron-left' : 'chevron-right'}
                />
              )}
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                props.customToggleDrawer();
              }}
              style={{
                marginTop: 'auto',
              }}
            />
          )}
          {IsHRWeb() === false && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) =>
                ShowDrawerLabel(
                  color,
                  {
                    iconName: 'info',
                    iconType: 'FA5',
                    title: translate('Profile'),
                  },
                  props
                )
              }
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.ShowCurrentUserProfile();
              }}
            />
          )}
          {IsHRWeb() === false && (
            <DrawerItem
              inactiveTintColor={props.inactiveTintColor}
              style={{
                marginVertical: IsSmallScreen() ? 0 : spacing.small,
                marginHorizontal: spacing.medium,
                paddingVertical: IsSmallScreen() ? 0 : 3,
                paddingHorizontal: IsHRWeb() ? spacing.medium200 : 0,
              }}
              label={({color}) => ShowDrawerLabel(color, {iconName: 'logout', title: translate('logout')}, props)}
              // onPress={() => props.navigation.toggleDrawer()}
              onPress={() => {
                global.logout();
              }}
            />
          )}
        </DrawerContentScrollView>
      </ScrollView>
      {IsHRWeb() === false && (
        <View style={{marginHorizontal: spacing.medium300}}>
          <LanguageSelect />
        </View>
      )}
    </>
  );
};

/*
{IsHRWeb() === false && (
  <DrawerItem
    inactiveTintColor={props.inactiveTintColor}
    label={({color}) =>
      ShowDrawerLabel(
        color,
        {
          iconName: global.theme === 'dark' ? 'sun' : 'moon',
          iconType: 'FA5',
          name:
            global.theme === 'dark'
              ? translate('LightMode')
              : translate('DarkMode'),
        },
        props,
      )
    }
    // onPress={() => props.navigation.toggleDrawer()}
    onPress={() => {
      global.ToggleColorMode();
    }}
  />
)}
*/

function GetDrawerWidth() {
  let res = 310;

  if (IsHRWeb() === false) {
    let w = ScreenWidth();
    if (w < 400) {
      //res = w - 30;
    }
  }

  return res;
}

global.LoadNotifications = function () {
  if (Supported('activities') && Supported('hasnotifications')) {
    Requests.doAction('getnotifications', {})
      .then((response) => {
        global.setNotifications(response.record);
      })
      .catch((error) => {
        logError(error);
      });
  }
};

const SideNavigator = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true); //IsHRWeb() ? true : false
  const [profileInfo, setProfileInfo] = useState({});
  const [isLoaded, setLoaded] = useState(false);

  // global.setIsDrawerOpen = (isDrawerOpen) => {
  //   setIsDrawerOpen(isDrawerOpen);
  //   global.refreshScreen(false);
  // };
  global.isDrawerOpen = () => {
    return isDrawerOpen;
  };
  global.setNotifications = (record) => {
    setProfileInfo(record);
  };
  if (isLoaded === false) {
    global.LoadNotifications();
    setLoaded(true);
  }

  /*
  useEffect(() => {
    const handleLocalizationChange = () => {
      setI18nConfig()
        .then(() => forceUpdate(true))
        .catch((error) => {
          console.error(error);
        });
    };
    RNLocalize.addEventListener('change', handleLocalizationChange);
  }, []);
  */

  let style = {
    backgroundColor: colours[GetGlobalTheme()].siteBackground,
  };
  if (isDrawerOpen && IsNarrowScreen()) {
    style.position = 'relative';
    let w = Dimensions.get('window').width;
    let min = 310;
    let pad = 170;
    if (w - pad < min) {
      pad = w - min;
    }
    style.paddingRight = pad;
  }

  return (
    <Drawer.Navigator
      key={JSON.stringify(global.userData)}
      openByDefault
      backBehavior={GetDefaultBackBehaviour()}
      drawerType={'permanent'}
      initialRouteName={GetDrawers()[0].name}
      sceneContainerStyle={style}
      drawerStyle={{
        // backgroundColor: colours[GetGlobalTheme()].navigationBackground,
        backgroundColor: colours[GetGlobalTheme()].navigationBackground,
        border: 0,
        width: isDrawerOpen ? GetDrawerWidth() : IsHRWeb() ? 96 : 0,
      }}
      drawerContentOptions={{
        activeBackgroundColor: colours[GetGlobalTheme()].navigationBackground,
        inactiveTintColor: colours[GetGlobalTheme()].titleText,
        activeTintColor: colours[GetGlobalTheme()].activeText,
        // itemStyle: {paddingLeft: 28, paddingVertical: 8},
        labelStyle: {height: 17, marginRight: 0},
        isDrawerOpen: isDrawerOpen,
        customToggleDrawer: () => global.setIsDrawerOpen(!isDrawerOpen),
      }}
      drawerContent={(props) => (
        <CustomDrawerContent {...props} profileInfo={profileInfo} isDrawerOpen={isDrawerOpen} />
      )}>
      {GetDrawers().map(function (drawer, index) {
        return (
          <>
            <Drawer.Screen
              key={index}
              name={drawer.name}
              component={drawer.screen}
              options={{
                title: drawer.title,
              }}
            />
          </>
        );
      })}
    </Drawer.Navigator>
  );
};

export default SideNavigator;
