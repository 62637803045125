import styled from 'styled-components/native';
import {colours, fontSize, fontWeight, lineHeight, spacing} from './Style';
import {GetGlobalTheme} from '@src/tools/displayTools';

// Default Text Styles
const CustomText = styled.Text`
  color: ${colours[GetGlobalTheme()].grey800};
  font-family: 'Open Sans' !important;
`;
// Default Header Text Styles
const HeaderText = styled(CustomText)``;

// Default View Styles
const CustomView = styled.View``;

const Header1 = styled(HeaderText)`
  font-size: ${fontSize.h1};
  line-height: ${lineHeight.h1};
  font-weight: ${fontWeight.h1};
`;

const Header2 = styled(HeaderText)`
  font-size: ${fontSize.h2};
  line-height: ${lineHeight.h2};
  font-weight: ${fontWeight.h2};
`;

const Header3 = styled(HeaderText)`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.h3};
  font-weight: ${fontWeight.h3};
`;

const Header4 = styled(HeaderText)`
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.h4};
  font-weight: ${fontWeight.h4};
`;

const Header5 = styled(HeaderText)`
  font-size: ${fontSize.h5};
  line-height: ${lineHeight.h5};
  font-weight: ${fontWeight.h5};
`;

const Header6 = styled(HeaderText)`
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.h6};
  font-weight: ${fontWeight.h6};
`;

const ColumnContainer = styled(CustomView)`
  flex-direction: column;
`;

const RowContainer = styled(CustomView)`
  flex-direction: row;
`;

const TextNormal = styled(CustomText)`
  font-size: ${fontSize.body};
  line-height: ${lineHeight.body};
  font-weight: ${fontWeight.body};
`;

const TextNormalBold = styled(CustomText)`
  font-size: ${fontSize.bodyBold};
  line-height: ${lineHeight.bodyBold};
  font-weight: ${fontWeight.bodyBold};
`;

const TextSmall = styled(CustomText)`
  font-size: ${fontSize.bodySmall};
  line-height: ${lineHeight.bodySmall};
  font-weight: ${fontWeight.bodySmall};
`;

const TextSmallBold = styled(CustomText)`
  font-size: ${fontSize.bodySmallBold};
  line-height: ${lineHeight.bodySmallBold};
  font-weight: ${fontWeight.bodySmallBold};
`;

const ModalBackground = styled(ColumnContainer)`
  background-color: rgba(63, 63, 63, 0.4);
  width: 100%;
  height: 100%;
`;

const Header = styled(RowContainer)`
  align-items: center;
  padding: ${spacing.space16}px ${spacing.space24}px;
  width: 100%;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: ${colours[GetGlobalTheme()].grey400};
  padding: ${spacing.space24}px ${spacing.space16}px;
`;

const HeaderTextContainer = styled(RowContainer)`
  width: 100%;
  align-items: center;
`;

export {
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  TextNormal,
  TextNormalBold,
  TextSmall,
  TextSmallBold,
  ColumnContainer,
  RowContainer,
  ModalBackground,
  Header,
  HeaderTextContainer,
};
