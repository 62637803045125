import React, {Component} from 'react';
import {Modal, View} from 'react-native';
import Requests from '../../api/Requests';
import {FilterActions, FilterField, FilterFields, FilterView, FilterViewInner, TableContainer} from '../table/Table';
import styled, {css} from 'styled-components/native';
import {colours, spacing} from '../../styles/Style';
import {
  DoNavigate,
  GetDefaultBackBehaviour,
  GetGlobalTheme,
  goBack,
  IsHRiOS,
  IsHRWeb,
  IsManager,
  ScreenHeight,
  ScreenWidth,
  UsePagination,
  WindowElementType,
  WindowWrap,
} from '../../Tools/DisplayTools';
import {ActionButton} from '../button/ActionButton';
import {InspectWindow} from '../edit_elements/InspectWindow';
import EditField from '../../components/edit_elements/EditField';
import {translate} from '@utils/languageTools';
import {AddDateFilter, SetCurrentWindow2} from '../../Tools/WindowTools';
import {YesNoWindow} from '../status/YesNoWindow';
import EmpTimeTable from '../../components/status/EmpTimeTable';
import {browseColumns, headerFields, matrixFields_EST} from './columns/VacPlansColumns';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {logError} from '@utils/debug';
import {objectHasProperty} from '../../Tools/ObjectTools';
import {InputChangeEventProps, WindowRecord} from '@utils/types';
import {ListScreenState, RecordScreenState} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {ListScreen} from '@src/types/Record/listScreen';
import {isDesktopScreen} from '@src/tools/displayTools';

const Drawer = createDrawerNavigator();

const VacationInfo = styled.View`
  padding: ${spacing.small300}px;
`;
const VacationInfoText = styled.Text`
  margin-bottom: 10px;
`;

interface VacPlansListState extends ListScreenState {
  newVacPlanHelper: boolean;
  NewContract: string;
  NewGroup: string;
  px: number;
  py: number;
  NewStartDate: string;
  NewEndDate: string;
  NewContractVacDays: [];
}

export class VacPlansList extends ListScreen<{}, VacPlansListState> {
  constructor(props) {
    super(props);
    this.createNewVacPlan = this.createNewVacPlan.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.renderNewButton = this.renderNewButton.bind(this);
    //this.changePage = this.changePage.bind(this);
    this.table = 18;
    this.ScreenName = 'VacPlans';
    this.init = true;
    this.state = {
      activeRow: 0,
      data: [],
      newVacPlanHelper: false,
      NewContract: '',
      NewGroup: '',
      px: 0,
      py: 0,
      NewStartDate: '',
      NewEndDate: '',
      NewContractVacDays: [],
      update: false,
    };
    if (objectHasProperty(global.userData, 'contractnr')) {
      this.state.NewContract = global.userData.contractnr;
    }
    this.navigation = props.navigation;
    SetCurrentWindow2('VacPlans', 'SerNr', 'descending');
    AddDateFilter('VacPlans');
    //    this.setState({update: true})
    //this.displayBrowseList();
  }

  CheckNewPlanData = () => {
    let res = true;
    if (this.state.NewStartDate === '') {
      res = false;
    }
    if (this.state.NewEndDate === '') {
      res = false;
    }
    if (this.state.NewContract === '') {
      res = false;
    }
    if (this.state.NewGroup === '') {
      res = false;
    }
    return res;
  };

  createNewVacPlan = () => {
    let tdata;
    if (this.CheckNewPlanData() === false) {
      global.messageBox({
        showMessage: true,
        statusMessage: translate('FillMandFields'),
      });
      return;
    }
    if (global.userData.provider === 0) {
      tdata = {
        record: {
          ContractNr: this.state.NewContract,
          OffWorkGr: this.state.NewGroup,
          StartDate: this.state.NewStartDate,
          EndDate: this.state.NewEndDate,
        },
      };
    } else {
      tdata = {
        record: {
          rows: [
            {
              ContrSerNr: this.state.NewContract,
              AbsTypeCode: this.state.NewGroup,
              StartDate1: this.state.NewStartDate,
              EndDate1: this.state.NewEndDate,
            },
          ],
        },
      };
    }
    Requests.getNewRecord(this.table, tdata)
      .then((response) => {
        if (objectHasProperty(response, 'record')) {
          if (objectHasProperty(global.userData, 'contractnr')) {
            this.state.NewContract = global.userData.contractnr;
          }
          this.state.NewGroup = '';
          this.state.NewStartDate = '';
          this.state.NewEndDate = '';
          this.state.NewContractVacDays = '';
          this.ShowHideNewVacPlan();
          if (UsePagination() === false) {
            this.getBrowseListData();
          }
          this.rowClick(response.record.SerNr);
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  ShowHideNewVacPlan = () => {
    if (this.state.newVacPlanHelper) {
      global.PopEscapeActions();
      this.setState({newVacPlanHelper: false});
    } else {
      this.setState({newVacPlanHelper: true});
      global.PushEscapeActions(() => this.ShowHideNewVacPlan());
    }
  };

  isVacationGroup(group) {
    switch (global.userData.provider) {
      case 0:
        if (group.UpdateVac === '1' || group.UpdateVac2 === '1') {
          return true;
        }
        break;
      case 1:
        if (group.AbsTypeFlag === '0') {
          return true;
        }
        break;
    }
  }

  checkVacationDays = () => {
    let self = this;
    let foundf = false;
    if (
      this.state.NewGroup !== '' &&
      this.state.NewStartDate !== '' &&
      this.state.NewEndDate !== '' &&
      this.state.NewContract !== ''
    ) {
      this.state.NewGroup = this.state.NewGroup.toUpperCase();
      let group = global.pasteSpecialsVector['19_2'][this.state.NewGroup];
      if (group && this.isVacationGroup(group)) {
        foundf = true;
        Requests.doAction(
          'calculateoffwork',
          {},
          {
            sd: this.state.NewStartDate,
            ed: this.state.NewEndDate,
            offempcode: this.state.NewContract,
            group: this.state.NewGroup,
          }
        )
          .then((response) => {
            let tmp = [];
            tmp.push(
              <VacationInfo>
                <VacationInfoText>
                  {translate('VacationDaysonDate') + this.state.NewStartDate + ': ' + response.record.vacdays}
                </VacationInfoText>
                <VacationInfoText>{translate('VacationDays') + response.record.useddays}</VacationInfoText>
              </VacationInfo>
            );
            self.setState({NewContractVacDays: tmp});
          })
          .catch((error) => {
            logError(error);
          });
      }
    }
    if (foundf === false) {
      this.setState({NewContractVacDays: []});
    }
  };
  renderNewButton = () => {
    let self = this;
    let style = {};
    if (isDesktopScreen()) {
      style = {marginRight: '20px'};
    }
    let style2 = {};
    if (IsHRiOS()) {
      style2 = {
        left: -this.state.px,
        top: -this.state.py,
      };
    }
    return (
      <View
        key={'vacplans_new'}
        style={{flexDirection: 'row'}}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          self.setState({py: layout.y, px: layout.x});
        }}>
        {isDesktopScreen() && (
          <ActionButton
            onPress={() => DoNavigate(self, 'VacPlans', false, '', true, false, 'EmpPlan')}
            style={{marginRight: '0px'}}
            title={translate('ShowTimePlan')}
          />
        )}
        <ActionButton onPress={() => this.ShowHideNewVacPlan()} title={translate('AddNew')} />
        {this.state.newVacPlanHelper && (
          <FilterView style={style2}>
            <FilterViewInner>
              <FilterFields>
                <FilterField>
                  <EditField
                    title={translate('StartDate') + '*'}
                    name={'StartDate'}
                    value={this.state.NewStartDate}
                    editable={true}
                    width={'150px'}
                    type={WindowElementType.kInputTypeDate}
                    onChange={({value}: InputChangeEventProps) =>
                      self.setState({NewStartDate: value}, () => {
                        self.checkVacationDays();
                      })
                    }
                  />
                </FilterField>
                <FilterField>
                  <EditField
                    title={translate('EndDate') + '*'}
                    name={'EndDate'}
                    value={this.state.NewEndDate}
                    editable={true}
                    width={'150px'}
                    record={{StartDate: this.state.NewStartDate}}
                    linkedfield={'StartDate'}
                    type={WindowElementType.kInputTypeDate}
                    onChange={({value}: InputChangeEventProps) =>
                      self.setState({NewEndDate: value}, () => {
                        self.checkVacationDays();
                      })
                    }
                  />
                </FilterField>
                <FilterField style={{width: '100%'}}>
                  {global.userData.provider === 1 && (
                    <EditField
                      title={translate('Contract') + '*'}
                      name={'EmpCode'}
                      value={this.state.NewContract}
                      editable={IsManager()}
                      pasteSpecial={{
                        vcName: 1,
                        columns: [
                          {
                            name: translate('SerNr'),
                            width: '35%',
                            key: 'contractnr',
                            mainKey: 'ContractNr',
                          },
                          {
                            width: '35%',
                            name: translate('Name'),
                            key: 'firstname',
                          },
                          {
                            width: '30%',
                            name: '',
                            key: 'surname',
                          },
                        ],
                      }}
                      onChange={({value}: InputChangeEventProps) =>
                        self.setState({NewContract: value}, () => {
                          self.checkVacationDays();
                        })
                      }
                    />
                  )}
                  {global.userData.provider === 0 && (
                    <EditField
                      title={translate('Contract') + '*'}
                      name={'EmpCode'}
                      value={this.state.NewContract}
                      editable={true}
                      pasteSpecial={{
                        vcName: 1,
                        columns: [
                          {
                            name: translate('Code'),
                            width: '35%',
                            key: 'contractnr',
                            mainKey: 'ContractNr',
                          },
                          {
                            width: '65%',
                            name: translate('Name'),
                            key: 'empname',
                          },
                        ],
                      }}
                      onChange={({value}: InputChangeEventProps) =>
                        self.setState({NewContract: value}, () => {
                          self.checkVacationDays();
                        })
                      }
                    />
                  )}
                </FilterField>
                <FilterField style={{width: '100%'}}>
                  <EditField
                    title={translate('OffWorkGroup') + '*'}
                    name={'OffWorkGr'}
                    value={this.state.NewGroup}
                    pasteSpecial={{
                      vcName: 19,
                      columns: [
                        {
                          name: translate('Code'),
                          width: '35%',
                          key: 'Code',
                          mainkey: 'Code',
                        },
                        {
                          width: '65%',
                          name: translate('Name'),
                          key: global.userData.provider === 0 ? 'Description' : 'Name',
                        },
                      ],
                    }}
                    onChange={({value}: InputChangeEventProps) =>
                      self.setState({NewGroup: value}, () => {
                        self.checkVacationDays();
                      })
                    }
                  />
                </FilterField>
              </FilterFields>
              <FilterActions>
                <ActionButton style={style} onPress={() => this.ShowHideNewVacPlan()} title={translate('Cancel')} />
                <ActionButton onPress={() => this.createNewVacPlan()} title={translate('Create')} />
              </FilterActions>
              {this.state.NewContractVacDays}
            </FilterViewInner>
          </FilterView>
        )}
      </View>
    );
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <TableContainer
          table={this.table}
          update={this.state.update}
          data={this.state.data}
          browseColumns={browseColumns(this)}
          doClick={self.rowClick}
          rowID={'sernr'}
          title={translate(this.ScreenName)}
          width="900px"
          extraContent={this.renderNewButton}
        />
      </WindowWrap>
    );
  }
}

interface VacPlansRecordState extends RecordScreenState {
  message: [];
  NewContract: string;
  NewContractVacDays: [];
  createDocNumber: string;
  createDocStatus: number;
}

export class VacPlansRecord extends RecordScreen<{}, VacPlansRecordState> {
  constructor(props) {
    super(props);
    this.updateRecordField = this.updateRecordField.bind(this);
    this.updateRecordFieldRow = this.updateRecordFieldRow.bind(this);
    this.rowClick = this.rowClick.bind(this);
    this.cancelApproval = this.cancelApproval.bind(this);
    this.table = 18;
    this.ScreenName = 'VacPlans';
    this.state = {
      ...this.state,
      message: [],
      NewContract: '',
      NewContractVacDays: [],
      createDocStatus: 0,
      createDocNumber: '',
    };
    this.record = this.createNewRecord();
    this.navigation = props.navigation;
    this.CheckOpenRecord();
  }

  createNewRecord = () => {
    return new WindowRecord(this.table, global.userData.provider == 1, headerFields(this), matrixFields_EST(this));
  };

  afterRecordLoad = () => {
    let self = this;
    Requests.getTableData(2, {OffWorkPlanNr: this.id})
      .then((response) => {
        if (objectHasProperty(response.records[0], 'SerNr')) {
          self.setState({createDocStatus: 1, createDocNumber: response.records[0].SerNr});
        } else {
          self.setState({createDocStatus: 2});
        }
      })
      .catch(() => {});
  };

  updateRecordField = ({rowindex, fieldname, value}: InputChangeEventProps) => {
    let self = this;

    Requests.updateRecordData(this.table, {
      field: fieldname,
      rownr: rowindex,
      value: value,
      id: this.id,
    })
      .then((response) => {
        if (response.success) {
          if (self._isMounted) {
            self.record.applyNewValues(response.record);
            self.updateScreen();
            if (fieldname === 'OffWorkGr') {
              self.hasapprovals = -1;
              self.CanUseApprovalRules();
            }
          }
        } else {
          if (self._isMounted) {
            self.updateScreen();
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };
  updateRecordFieldRow = ({fieldname, value}: InputChangeEventProps) => {
    this.updateRecordField({rowindex: 0, fieldname: fieldname, value: value});
  };

  createOrderDoc = () => {
    let self = this;
    Requests.doAction('orderdocfromoffworkplan', {id: this.id})
      .then((response) => {
        if (response.newid !== '' && response.newid !== '-1') {
          DoNavigate(self, 'OrderDocs', true, response.newid);
        } else {
          if (response.newid === '-1') {
            global.messageBox({
              showMessage: true,
              statusMessage: translate('DocumentExists'),
            });
          }
        }
      })
      .catch((error) => {
        logError(error);
      });
  };

  render() {
    let self = this;
    return (
      <WindowWrap>
        <InspectWindow
          key={JSON.stringify(this.record)}
          title={translate('VacPlan')}
          window={this}
          headerFields={headerFields(this)}
          record={this.record}
          onClick={this.handleInputClick}
          allowDelete={true}
          allowPrint={IsHRWeb()}
          hasApprovals={this.hasapprovals === 1}
          okcheck={'Status'}
          backButton={() => goBack(self, self.ScreenName)}
        />
        {this.state.activeDelete && (
          <Modal transparent={true}>
            <YesNoWindow
              question={translate('RemoveQuestion')}
              yes={() => this.deleteRecordOld()}
              no={() => this.setState({activeDelete: false})}
            />
          </Modal>
        )}
      </WindowWrap>
    );
  }
}

// TODO: What to do with this component?
class VacPlansEmpPlan extends Component {
  ScreenName: string;
  table: number;
  _isMounted: boolean;
  navigation: any;

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.table = 18;
    this.ScreenName = 'VacPlans';
    this.state = {
      data: [],
      record: {},
      showempplan: false,
      NewContractVacDays: [],
    };
    this.navigation = props.navigation;
  }

  render() {
    return (
      <WindowWrap>
        <EmpTimeTable depcode={global.userData.deplist.split(',').pop()} empcode={''} table={this} />
      </WindowWrap>
    );
  }
}

function VacPlans() {
  return (
    <Drawer.Navigator
      style={{background: 'white'}}
      screenOptions={{headerShown: false}}
      backBehavior={GetDefaultBackBehaviour()}
      drawerStyle={{display: 'none'}}>
      <Drawer.Screen
        name="VacPlansList"
        component={VacPlansList}
        options={{
          title: translate('VacPlans'),
        }}
      />
      <Drawer.Screen
        name="VacPlansEmpPlan"
        component={VacPlansEmpPlan}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('VacPlanPlan'),
        }}
      />
      <Drawer.Screen
        name="VacPlansRecord"
        component={VacPlansRecord}
        backBehavior={GetDefaultBackBehaviour()}
        options={{
          title: translate('VacPlan'),
        }}
        getId={({params}) => params.id}
      />
    </Drawer.Navigator>
  );
}

export default VacPlans;
