import React, {useState} from 'react';
import {View} from 'react-native';
import {Tabs} from '@components/screens/tabSection/Tabs';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '@tools/displayTools';
import {TextNormalBold} from '@src/styles/primitiveComponents';

const ContainerStyle = {
  width: '100%',
  gap: spacing.space16,
};

const TabComponentContainerStyle = {
  backgroundColor: colours[GetGlobalTheme()].white,
  borderRadius: spacing.space12,
  padding: spacing.space4,
};

export type Tab = {
  title?: string;
  tabName: string;
  component: React.ReactElement;
  onSwitch?: () => void;
};

type TabSectionProps = {
  tabs: Tab[];
};

export const TabSection: React.FC<TabSectionProps> = ({tabs}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  function handleTabClick(tabID: number) {
    setActiveTabIndex(tabID);
  }

  function getListWindowContainerStyle(tabIndex: number) {
    const styleObj: React.CSSProperties = {
      padding: spacing.space16,
    };
    styleObj.display = tabIndex === activeTabIndex ? 'flex' : 'none';
    return styleObj;
  }

  return (
    <View style={ContainerStyle}>
      <Tabs activeTabIndex={activeTabIndex} tabs={tabs} onTabClick={(tabID) => handleTabClick(tabID)} />
      <View style={TabComponentContainerStyle}>
        {tabs.map((tab, index) => (
          <View key={index} style={getListWindowContainerStyle(index)}>
            {tab.title && <TextNormalBold> {tab.title} </TextNormalBold>}
            {tab.component}
          </View>
        ))}
      </View>
    </View>
  );
};
