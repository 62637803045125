import React from 'react';
import {Header3, RowContainer} from '@src/styles/primitiveComponents';

interface ProfileIconProps {
  onPress?: () => void;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const ProfileIconContainerStyle = (backgroundColor: string, customStyle?: React.CSSProperties) => ({
  width: '80px',
  height: '80px',
  borderRadius: '40px',
  backgroundColor: backgroundColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...customStyle,
});

export const ProfileIcon: React.FC<ProfileIconProps> = ({onPress, style, textStyle}) => {
  const defaultBackgroundColor = '808080';
  const defaultTextColor = '141414';

  const iconColour = global.userData.iconcolour || `${defaultBackgroundColor}_${defaultTextColor}`;
  const [backgroundColor, textColour] = iconColour.split('_').map((color) => `#${color}`);
  const initials = global.userData.initials;

  return (
    <RowContainer style={ProfileIconContainerStyle(backgroundColor, style)} onClick={onPress}>
      <Header3 style={{color: textColour, ...textStyle}}>{initials}</Header3>
    </RowContainer>
  );
};
