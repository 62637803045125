import React, {useEffect, useState} from 'react';
import {RecordList} from '@components/RecordList/RecordList';
import {translate} from '@utils/languageTools';
import {DoNavigate} from '@tools/displayTools';
import {BrowseColumn} from '@components/screens/types';
import {RecordScreen} from '@src/types/Record/recordScreen';
import {SetCurrentWindow2} from '@tools/windowTools';
import {RecordSummary, SummaryLine} from '@components/screens/RecordSummary';
import {ColumnContainer, RowContainer} from '@styles/primitiveComponents';
import {logError} from '@utils/debug';
import {objectHasProperty} from '@tools/objectTools';

const WindowWrapperStyle = {
  background: 'none',
  padding: 0,
  gap: 0,
};

const BodyStyle = {
  margin: 0,
};

const HeaderStyle = {
  margin: 0,
  padding: 0,
};

const RowStyle = {
  margin: 0,
  padding: 0,
};

const RecordSummaryContainerStyle = {
  justifyContent: 'end',
  width: '100%',
};

export type SummaryData = {
  totals: any;
  keys: string[];
  translatioKeys: string[];
  suffixKey: string;
};

export type CustomRecordData = {
  records: any;
  summaryData?: SummaryData;
};

export type MinimizedRecordListDefinitionProps = {
  window: RecordScreen<any, any>;
  recordData: CustomRecordData;
};

type MinimizedRecordListProps = {
  window: RecordScreen<any, any>;
  hasRecordSummary: boolean;
  recordData: CustomRecordData;
  tableName: string;
  windowName: string;
  sortKey: string;
  rowID: string;
  browseColumns: BrowseColumn[];
};

export const MinimizedRecordList: React.FC<MinimizedRecordListProps> = ({
  window,
  hasRecordSummary,
  recordData,
  tableName,
  windowName,
  sortKey,
  rowID,
  browseColumns,
}) => {
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    initializeCurrentWindow();
  }, []);

  function initializeCurrentWindow() {
    SetCurrentWindow2(windowName, sortKey, 'descending');
    setHasInitialized(true);
  }

  function handleRowClick(id: string) {
    DoNavigate(window, windowName, true, id);
  }

  function handleGetRecordSummary() {
    if (!objectHasProperty(recordData, 'summaryData')) {
      logError('Record data has no totals key but summary is still attempting to be rendered!');
      return;
    }

    return getRecordSummary();
  }

  function getRecordSummary(): SummaryLine[] {
    const summaryLinesArr: SummaryLine[] = [];
    const suffixKey = recordData.summaryData.suffixKey;
    const suffixValue = recordData.records.length > 0 ? recordData.records[0][suffixKey] : '';
    recordData.summaryData.keys.forEach((key, index) => {
      summaryLinesArr.push({
        title: translate(recordData.summaryData.translatioKeys[index]),
        value: `${recordData.summaryData.totals[key]} ${suffixValue}`,
      });
    });
    return summaryLinesArr;
  }

  if (!hasInitialized) return <></>;

  // @ts-ignore
  return (
    <ColumnContainer>
      <RecordList
        table={tableName}
        windowName={windowName}
        update={false}
        data={[]}
        records={recordData.records}
        browseColumns={browseColumns}
        hasSearch={false}
        hasPagination={false}
        doClick={(id) => handleRowClick(id)}
        rowID={rowID}
        title={translate(windowName)}
        wrapperStyle={WindowWrapperStyle}
        bodyStyle={BodyStyle}
        headerStyle={HeaderStyle}
        onlyAllowMainColumnClick={true}
        rowStyle={RowStyle}
        disableSorting={true}
      />
      {hasRecordSummary && recordData.records.length !== 0 && (
        // @ts-ignore
        <RowContainer style={RecordSummaryContainerStyle}>
          <RecordSummary SummaryLines={handleGetRecordSummary()} />
        </RowContainer>
      )}
    </ColumnContainer>
  );
};
