import React, {useState} from 'react';
import {colours, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../../Tools/DisplayTools';
import {ToolTipText, ToolTipTextProps} from '@components/design/ToolTipText';
import {ActionButton} from '@components/button/ActionButton';
import styled from 'styled-components/native';
import {TextNormal} from '@styles/primitiveComponents';
import {isMobileOrTabletScreen} from '@tools/displayTools';

const BubbleWrap = styled.View`
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 50px;
  margin-right: ${spacing.space4}px;
  padding: ${spacing.space4}px;
`;

const RemoveButtonIconStyle = {
  order: 2,
};

const RemoveButtonStyle = {
  backgroundColor: 'invisible',
  width: 14,
  height: 14,
  padding: 0,
  marginBottom: 0,
  marginTop: 0,
};

type PasteSpecialNameSingleEntryProps = {
  name: string;
  hasToolTip?: boolean;
  toolTip: ToolTipTextProps;
  hideRemoveOption: boolean;
  onCrossPress: (name: string) => void;
};

export const PasteSpecialNameSingleEntry: React.FC<PasteSpecialNameSingleEntryProps> = ({
  name,
  hasToolTip,
  toolTip,
  hideRemoveOption,
  onCrossPress,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  function getBackgroundColor() {
    if (isMobileOrTabletScreen() && !hideRemoveOption) {
      return colours[GetGlobalTheme()].infoLight;
    }
    return isHovering ? colours[GetGlobalTheme()].infoLight : colours[GetGlobalTheme()].white;
  }

  const BubbleWrapStyle: React.CSSProperties = {
    backgroundColor: getBackgroundColor(),
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 50,
    marginRight: 0,
    padding: spacing.space2,
  };

  function handleSetHoverState(isHovering: boolean) {
    if (hideRemoveOption) {
      return;
    }

    setIsHovering(isHovering);
  }

  return (
    <BubbleWrap
      style={BubbleWrapStyle}
      onMouseEnter={() => handleSetHoverState(true)}
      onMouseLeave={() => handleSetHoverState(false)}>
      {hasToolTip ? (
        <ToolTipText
          innerText={toolTip.innerText}
          toolTipText={toolTip.toolTipText}
          maxCharacters={toolTip.maxCharacters}
        />
      ) : (
        <TextNormal>{name}</TextNormal>
      )}

      <ActionButton
        key={'someuniquekey_actionbutton'}
        onPress={() => onCrossPress(name)}
        title={' '}
        iconName={'CrossIcon'}
        style={RemoveButtonStyle}
        iconStyle={RemoveButtonIconStyle}
        iconColor={colours[GetGlobalTheme()].primary}
        iconSize={hideRemoveOption ? 0 : 8}
      />
    </BubbleWrap>
  );
};
