import React, {useState} from 'react';
import {ActionButton} from '@components/button/ActionButton';
import {colours, iconSize, spacing} from '@styles/Style';
import {GetGlobalTheme} from '../../../../Tools/DisplayTools';
import styled, {css} from 'styled-components/native';
import {translate} from '@utils/languageTools';
import {ViewMode} from '@components/screens/types';
import {isDesktopScreen, isMobileOrTabletScreen} from '@tools/displayTools';
import {View} from 'react-native';
import {TileListItem} from '@utils/types';
import {RecordScreen} from '@src/types/Record/recordScreen';

const TileButtonOperationWrap = styled.View`
  border-top: 1px solid ${colours[GetGlobalTheme()].borderDefault};
`;

const SubActionContainer = styled.View<{direction: string}>`
  position: absolute;
  gap: ${spacing.space12}px;
  width: 100%;
  border-radius: ${spacing.space12}px;
  background-color: ${colours[GetGlobalTheme()].mainBG};
  padding: ${spacing.space12}px;
  shadow-color: ${colours[GetGlobalTheme()].borderDefault};
  shadow-offset: {
  width: 0px,
  height: 0px,
};
  shadow-radius: ${spacing.space16}px;

  ${(props) =>
    (props.direction === 'top' &&
      css`
        bottom: 100%;
      `) ||
    css`
      top: 100%;
    `}
`;

const SubActionButtonStyle = {
  backgroundColor: colours[GetGlobalTheme()].mainBG,
  borderWidth: '1px',
  borderColor: colours[GetGlobalTheme()].borderDefault,
  transition: 'all 2s',
};

type InspectWindowTabSectionProps = {
  headerFields: any;
  activeTile: number;
  isNewRecord: boolean;
  // eslint-disable-next-line no-unused-vars
  viewMode: ViewMode;
  window: RecordScreen<any, any>;
  onTabClick: (tabIndex: number) => void;
};

const desktopTileButtonSectionWidth = '297px';
const mobileTileButtonSectionWidth = '100%';

const horizontalScrollFadeColor = colours[GetGlobalTheme()].lightBlue100;

export const InspectWindowTabSection: React.FC<InspectWindowTabSectionProps> = ({
  headerFields,
  activeTile,
  isNewRecord,
  viewMode,
  window,
  onTabClick,
}) => {
  const [isOperationsMenuOpen, setIsOperationsMenuOpen] = useState(false);

  function getTileButtonContainerStyle(): React.CSSProperties {
    let TileButtonContainerStyle: React.CSSProperties = {
      gap: spacing.space12,
      flexDirection: 'column',
      padding: spacing.space16,
    };

    if (isMobileOrTabletScreen()) {
      TileButtonContainerStyle.flexDirection = 'row';
      TileButtonContainerStyle.padding = 0;
      TileButtonContainerStyle.paddingLeft = spacing.space8;
      TileButtonContainerStyle.paddingRight = spacing.space8;
      TileButtonContainerStyle.overflowX = 'scroll';
      TileButtonContainerStyle.background = `linear-gradient(to right, #00000000, #ffa50000, #ffff0000, #00800000, #0000ff00, #4b008200, ${horizontalScrollFadeColor})`;
    }

    return TileButtonContainerStyle;
  }

  function getTileButtonStyle(active: boolean): React.CSSProperties {
    let TileButtonStyle: React.CSSProperties = {
      background: colours[GetGlobalTheme()].grey100,
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing.space12,
    };

    if (isMobileOrTabletScreen()) {
      TileButtonStyle.background = 'none';
    }

    if (active) {
      if (isDesktopScreen()) {
        TileButtonStyle.border = '1px solid ' + colours[GetGlobalTheme()].lightBlue;
      }
      if (isMobileOrTabletScreen()) {
        TileButtonStyle.background = colours[GetGlobalTheme()].lightBlue100;
      }
    }

    return TileButtonStyle;
  }

  function getTileButtonTextStyle(active: boolean): React.CSSProperties {
    let style: React.CSSProperties = {};

    if (active) {
      style.color = colours[GetGlobalTheme()].lightBlue;
    } else {
      style.color = colours[GetGlobalTheme()].black;
    }
    return style;
  }

  function getTileButtonIconStyle(active: boolean): React.CSSProperties {
    let TileButtonIconStyle: React.CSSProperties = {};

    if (active) {
      TileButtonIconStyle.color = colours[GetGlobalTheme()].lightBlue;
    }

    return {transform: 'rotate(90deg)'};
  }

  function getTileButtonIconColor(active: boolean): string {
    if (active) {
      return colours[GetGlobalTheme()].lightBlue;
    } else {
      return colours[GetGlobalTheme()].black;
    }
  }

  function getTileButtonSectionStyle() {
    const TileButtonSectionStyle: React.CSSProperties = {
      gap: `${spacing.space40}px`,
      flexDirection: 'column' as const,
      width: desktopTileButtonSectionWidth,
      borderRadius: `${spacing.space12}px`,
      backgroundColor: colours[GetGlobalTheme()].white,
    };

    if (isMobileOrTabletScreen()) {
      TileButtonSectionStyle.width = mobileTileButtonSectionWidth;
      TileButtonSectionStyle.gap = 0;
      TileButtonSectionStyle.alignSelf = 'center';
    }
    return TileButtonSectionStyle;
  }

  function getOperationsMenuActions() {
    let subActions = [];
    for (let i = 0; i < headerFields.operationMenu.length; i++) {
      let opMenu = headerFields.operationMenu[i];
      subActions.push(
        <ActionButton
          title={opMenu.label}
          onPress={() => opMenu.onPress({inspectWindow: window})}
          hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
          style={SubActionButtonStyle}
          textStyle={{
            color: colours[GetGlobalTheme()].primary,
          }}
        />
      );
    }

    return subActions;
  }

  function getOperationsMenu(direction?: string) {
    let operationsMenu = [];

    if (!headerFields.operationMenu) {
      return operationsMenu;
    }

    if (isDesktopScreen()) {
      operationsMenu.push(
        <ActionButton
          title={translate('MoreActions')}
          onPress={() => setIsOperationsMenuOpen(!isOperationsMenuOpen)}
          iconName={'DropdownIcon'}
          iconSize={iconSize.iconSize}
          hoverStyle={{borderColor: colours[GetGlobalTheme()].primary}}
          iconStyle={{
            transform: isOperationsMenuOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
          iconColor={colours[GetGlobalTheme()].primary}
          style={{
            backgroundColor: colours[GetGlobalTheme()].mainBG,
            borderWidth: '1px',
            borderColor: isOperationsMenuOpen
              ? colours[GetGlobalTheme()].primary
              : colours[GetGlobalTheme()].borderDefault,
            transition: 'all 2s',
            display: viewMode === ViewMode.View ? 'flex' : 'none',
            margin: spacing.space16,
          }}
          textStyle={{
            color: colours[GetGlobalTheme()].primary,
          }}
        />
      );
    }

    if (isOperationsMenuOpen) {
      operationsMenu.push(<SubActionContainer direction={direction}>{getOperationsMenuActions()}</SubActionContainer>);
    }

    return operationsMenu;
  }

  function getTileTabButtons() {
    let tileTabButtons = [];
    let tiles: TileListItem[] = headerFields.sectionList.tiles;
    tiles.forEach((section) => {
      const active = section.tileNumber === activeTile;
      tileTabButtons.push(
        <ActionButton
          style={getTileButtonStyle(active)}
          textStyle={getTileButtonTextStyle(active)}
          iconStyle={getTileButtonIconStyle(active)}
          iconName={isMobileOrTabletScreen() ? '' : 'ArrowIcon'}
          iconColor={getTileButtonIconColor(active)}
          onPress={() => onTabClick(section.tileNumber)}
          title={section.name}
        />
      );
    });

    return tileTabButtons;
  }

  function getTileButtonSection() {
    const tileButtonSection = [];
    const tileTabButtons = getTileTabButtons();
    if (headerFields.sectionList.component) {
      tileButtonSection.push(headerFields.sectionList.component);
    }

    tileButtonSection.push(<View style={getTileButtonContainerStyle()}>{tileTabButtons}</View>);

    if (isNewRecord == false) {
      tileButtonSection.push(<TileButtonOperationWrap>{getOperationsMenu('top')}</TileButtonOperationWrap>);
    }
    return tileButtonSection;
  }

  return <View style={getTileButtonSectionStyle()}>{getTileButtonSection()}</View>;
};
